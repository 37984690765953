import styled from 'styled-components';

import MenuColumnContextMenuIcon from 'mdi-react/DotsHorizontalIcon';

import ContextMenuOrigin from '+components/ContextMenu';

import ButtonMixin from './ButtonMixin';

const ContextMenu = styled(ContextMenuOrigin)`
  ${ButtonMixin};
`;

const MenuColumnContextMenu = (props) => (
  <ContextMenu text={<MenuColumnContextMenuIcon size={16} />} {...props} />
);

export { MenuColumnContextMenuIcon };
export default MenuColumnContextMenu;
