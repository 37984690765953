import styled from 'styled-components';

import Group from './Group';
import GroupBody from './GroupBody';

export default styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
  width: 100%;
  overflow: hidden;

  & + ${GroupBody} {
    margin-top: 28px;
  }

  @media screen and (max-width: 1200px) {
    flex-wrap: wrap;

    ${Group} {
      flex: 1 1 100%;
    }
  }
`;
