import { css } from 'styled-components';

import { ScrollBarMixin } from '+theme/mixins/scrollBarMixin';

const LegendPositions = {
  left: 'left',
  right: 'right',
  top: 'top',
  bottom: 'bottom',
};

const LegendSizes = {
  horizontalMaxHeight: 42,
  verticalPadding: 22,
  horizontalPadding: 0,
  itemHeight: 21,
  chartSpacingRight: 10,
};

const getLegendPositionOptions = ({
  legendPosition,
  title,
  subtitle,
  pie = false,
}) => {
  switch (legendPosition) {
    case LegendPositions.left:
      return {
        layout: 'vertical',
        align: 'left',
        verticalAlign: 'middle',
        maxHeight: undefined,
        y: 0,
        padding: LegendSizes.verticalPadding,
        itemMarginTop: 4,
        itemMarginBottom: 4,
      };
    case LegendPositions.right:
      return {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle',
        maxHeight: undefined,
        y: 0,
        padding: LegendSizes.verticalPadding,
        itemMarginTop: 4,
        itemMarginBottom: 4,
      };
    case LegendPositions.top:
      return {
        layout: 'horizontal',
        align: 'left',
        verticalAlign: 'top',
        maxHeight: LegendSizes.horizontalMaxHeight,
        y: title || subtitle ? 10 * (pie ? 1 : -1) : 21,
        padding: LegendSizes.horizontalPadding,
        itemMarginTop: 4,
        itemMarginBottom: 4,
      };
    case LegendPositions.bottom:
    default:
      return {
        layout: 'horizontal',
        align: 'left',
        verticalAlign: 'bottom',
        maxHeight: LegendSizes.horizontalMaxHeight,
        y: 0,
        padding: LegendSizes.horizontalPadding,
        itemMarginTop: 4,
        itemMarginBottom: 4,
      };
  }
};

// We need these highcharts legend styles for better display of chart legend
// @see: https://netography.atlassian.net/browse/PORTAL-1472
const LegendMixin = css`
  .highcharts-legend {
    right: 0 !important;
    bottom: 0 !important;
    > div {
      clip: unset !important;
      > div {
        right: 0 !important;
        bottom: 0 !important;
        overflow-x: auto !important;
        ${ScrollBarMixin};
      }
    }
  }

  g.highcharts-legend g {
    display: none;
  }

  &.legend-bottom {
    .highcharts-legend > div {
      right: ${LegendSizes.chartSpacingRight}px !important;
      bottom: ${LegendSizes.chartSpacingRight}px !important;
    }
  }

  &.legend-top {
    .highcharts-legend > div {
      right: ${LegendSizes.chartSpacingRight}px !important;
      height: ${LegendSizes.horizontalMaxHeight}px !important;
      max-height: ${LegendSizes.horizontalMaxHeight}px !important;
    }
  }

  &.legend-right {
    .highcharts-legend > div {
      top: ${LegendSizes.verticalPadding}px !important;
      bottom: ${LegendSizes.verticalPadding}px !important;
      right: ${LegendSizes.chartSpacingRight}px !important;
    }

    .highcharts-legend > div > div div {
      margin-top: -${LegendSizes.itemHeight}px !important;
    }
  }

  &.legend-left {
    .highcharts-legend > div {
      top: ${LegendSizes.verticalPadding}px !important;
      bottom: ${LegendSizes.verticalPadding}px !important;
      width: 200px !important;
      max-width: 200px !important;
    }

    .highcharts-legend > div > div div {
      margin-top: -${LegendSizes.itemHeight}px !important;
    }
  }
`;

export { LegendPositions, LegendSizes, getLegendPositionOptions, LegendMixin };
