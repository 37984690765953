import { Category } from '../models/Category';
import { Dispatcher } from '../models/Dispatcher';
import { GeoJSONLayer } from './GeoJSONLayer';
import { Map } from './Map';
import { RenderLayer } from './RenderLayer';
import { SchemeLayer } from './SchemeLayer';

export class Engine extends Dispatcher {
  constructor(id, options = {}) {
    super();
    this._data = [];

    this._mapLayer = new Map(id, options);

    this._schemeLayer = new SchemeLayer(this.map, options);
    this._geoJsonLayer = new GeoJSONLayer(this.map, options);
    this._renderLayer = new RenderLayer(this.map, options);

    this._renderLayer.on('completed', (item) => {
      this._sendEvent('completed', item);
    });

    this._initDispatcher('completed');

    this._move = this._move.bind(this);
    this.map.on('move', this._move);
    this.map.on('zoom', this._move);
    this.map.on('moveend', this._move);

    this.resize = this.resize.bind(this);
    this.map.on('resize', this.resize);
  }

  get map() {
    return this._mapLayer.map;
  }

  _updatePointsCoords() {
    this._renderLayer.updatePointsCoords();
    this._schemeLayer.updatePointsCoords();
  }

  _move() {
    this._updatePointsCoords();
    this._renderLayer.moveCanvas();
    this._renderLayer.calcCanvasSize();
    this._schemeLayer.moveCanvas();
    this._schemeLayer.calcCanvasSize();

    // this._renderLayer.clearDirtyAlpha();
  }

  updateData(data) {
    this._renderLayer.updateData(data);
  }

  resize() {
    this._renderLayer.calcCanvasSize();
  }

  destroy() {
    if (this._mapLayer && this.map) {
      this.map.remove();
    }

    if (this._renderLayer) {
      this._renderLayer.destroy();
    }

    if (this._schemeLayer) {
      this._schemeLayer.destroy();
    }
  }

  reset() {
    Category.clearCache();
    this._renderLayer.reset();
    this._schemeLayer.reset();
  }
}
