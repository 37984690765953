import {
  BaseColumnFactory,
  MenuColumnFactory,
} from '+components/Table/Columns';
import { TimestampColumnFactory } from '+components/Table/Columns/TimestampColumnFactory';
import { SelectColumnFilter } from '+components/Table/Filters';

export const Columns = {
  id: 'id',
  original_id: 'original_id',
  action: 'action',
  description: 'description',
  timestamp: 'timestamp',
  class: 'class',
  source: 'source',
  subclass: 'subclass',
  user: 'user',
  customer: 'customer',
  menu: 'menu',
};

export const columnsCollection = ({ cxActionMenu }) => {
  const collection = {
    [Columns.id]: BaseColumnFactory({
      getCellProps: () => ({ style: { justifyContent: 'center' } }),
    }),
    [Columns.action]: BaseColumnFactory({
      getCellProps: () => ({ style: { justifyContent: 'center' } }),
      width: 100,
      Filter: SelectColumnFilter({
        optionLabel: (key) => (key === 'all' ? 'All' : key),
      }),
      filter: 'selectFilter',
    }),
    [Columns.description]: BaseColumnFactory({}),
    [Columns.timestamp]: TimestampColumnFactory({ width: 160 }),
    [Columns.class]: BaseColumnFactory({
      getCellProps: () => ({ style: { justifyContent: 'center' } }),
      width: 100,
      Filter: SelectColumnFilter({
        optionLabel: (key) => (key === 'all' ? 'All' : key),
      }),
      filter: 'selectFilter',
    }),
    [Columns.source]: BaseColumnFactory({
      getCellProps: () => ({ style: { justifyContent: 'center' } }),
      width: 100,
      Filter: SelectColumnFilter({
        optionLabel: (key) => (key === 'all' ? 'All' : key || '<empty>'),
      }),
      filter: 'selectFilter',
    }),
    [Columns.subclass]: BaseColumnFactory({
      getCellProps: () => ({ style: { justifyContent: 'center' } }),
      width: 100,
      Filter: SelectColumnFilter({
        optionLabel: (key) => (key === 'all' ? 'All' : key || '<empty>'),
      }),
      filter: 'selectFilter',
    }),
    [Columns.user]: BaseColumnFactory({
      getCellProps: () => ({ style: { justifyContent: 'center' } }),
      width: 200,
      Filter: SelectColumnFilter({
        optionLabel: (key) => (key === 'all' ? 'All' : key),
      }),
      filter: 'selectFilter',
    }),
    [Columns.original_id]: BaseColumnFactory({}),
    [Columns.customer]: BaseColumnFactory({
      accessor: 'customer',
      Header: 'Account',
      width: 160,
      Filter: SelectColumnFilter({
        optionLabel: (key) => (key === 'all' ? 'All' : key),
      }),
      filter: 'selectFilter',
    }),
    [Columns.menu]: MenuColumnFactory({ cxActionMenu }),
  };

  Object.keys(collection).forEach((key) => {
    if (!collection[key].accessor) {
      collection[key].accessor = key;
    }
  });

  return collection;
};
