import PropTypes from '+prop-types';
import { useRef } from 'react';
import { useForm } from 'react-final-form';
import { useDebounce } from 'react-use';

import FormField from '+components/form/FormField';
import MultiSelect from '+components/form/MultiSelect';

import createDescriptionLinks from './createDescriptionLinks';

const MultiSelectField = (props) => {
  const {
    input,
    label,
    helperText,
    meta: { touched, error, dirty, submitFailed },
    disabled,
    readOnly,
    required,
    errorPositionOver,
    parseDescriptionUrls,
    ...tail
  } = props;

  const form = useForm();

  const { value } = input;
  const invalid = error && (dirty || submitFailed) && touched;
  const lastValue = useRef(value);

  // Without this useDebounce form field does not run validation if element was removed by tag remove button
  useDebounce(
    () => {
      if (input.name && value !== lastValue.current) {
        form.mutators.touched(input.name, true);
      }
    },
    100,
    [input.name, value],
  );

  return (
    <FormField
      label={label}
      helperText={parseDescriptionUrls ? createDescriptionLinks(helperText) : helperText}
      error={error}
      invalid={invalid}
      disabled={disabled}
      required={required}
      errorPositionOver={errorPositionOver}
    >
      <MultiSelect
        {...tail}
        {...input}
        value={value}
        disabled={disabled}
        readOnly={readOnly}
        invalid={invalid}
      />
    </FormField>
  );
};

MultiSelectField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    dirty: PropTypes.bool,
    submitFailed: PropTypes.bool,
  }),
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  helperText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  errorPositionOver: PropTypes.bool,
  parseDescriptionUrls: PropTypes.bool,
};

MultiSelectField.defaultProps = {
  meta: null,
  label: null,
  helperText: null,
  disabled: false,
  readOnly: false,
  required: false,
  errorPositionOver: false,
  parseDescriptionUrls: false,
};

export default MultiSelectField;
