/**
 * Returns a set of feature overrides
 * @returns {Set<string>}
 */
const get = () => {
  const name = 'feature-override=';
  const decodedCookie = decodeURIComponent(document.cookie);
  let found = decodedCookie
    .split(';')
    .find((cookie) => cookie.trim().startsWith(name));

  found = found?.split?.('=')?.[1] || '';

  const features = found.split(',').filter(Boolean);

  return new Set(features);
};

/**
 * Sets a feature override
 * @param {string[]} flags
 * @returns {Set<string>}
 */
const add = (...flags) => {
  const features = get();

  flags.forEach((feature) => {
    features.add(feature);
  });

  document.cookie = `feature-override=${Array.from(features)}; Path=/`;

  return features;
};

/**
 * Removes a feature override
 * @param {string[]} flags
 * @returns {Set<string>}
 */
export const remove = (...flags) => {
  const features = get();

  flags.forEach((feature) => {
    features.delete(feature);
  });

  document.cookie = `feature-override=${Array.from(features)}; Path=/`;

  return features;
};

export default {
  get,
  add,
  remove,
  toString: () => [...get()].join(','),
};
