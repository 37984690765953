import { useEffect, useState } from 'react';

import { joinKeys } from '@/shared/utils';

import { UniversalCell } from '+components/Table/Cells';

const isObject = (value) => value != null && typeof value === 'object' && !Array.isArray(value);

const extractFields = (item, prefix = '') => {
  if (!isObject(item) || !Object.keys(item || {}).length) {
    return prefix ? [prefix] : [];
  }

  return Object.entries(item).reduce((acc, [key, value]) => {
    const path = joinKeys([prefix, key]);

    return [...acc, ...extractFields(value, path)];
  }, []);
};

const makeDefaultColumn = (accessor) => ({ accessor, Header: accessor, Cell: UniversalCell() });

const getKey = (accessor, id) => {
  if (typeof accessor === 'string') {
    return accessor;
  }

  return id ?? '';
};

const allowedTypes = ['string', 'number'];
const extractProp = (column) => {
  if (allowedTypes.includes(typeof column.Header)) {
    return column.Header;
  }

  if (allowedTypes.includes(typeof column.accessor)) {
    return column.accessor;
  }

  if (allowedTypes.includes(typeof column.id)) {
    return column.id;
  }

  return '';
};

const defaultSort = (a, b) => {
  const propA = extractProp(a);
  const propB = extractProp(b);

  if (propA < propB) {
    return -1;
  }

  return propA > propB ? 1 : 0;
};

/**
 * @callback GetColumns
 * @param {string[]} selectedColumns
 * @return {Object[]}
 */

/**
 * @callback CreateColumn
 * @param {string} accessor
 * @return {Object}
 */

/**
 * @callback SortColumn
 * @param {Object} columnA
 * @param {Object} columnB
 * @return {number}
 */

/**
 * Prepares available columns based on a data row, columnsDefs and selected columns by default
 * @param {Object} props
 * @param {Object} props.row - a data row.
 * @param {GetColumns} props.getColumns - a function to getting columns from selected columns array.
 * @param {string[]} [props.selectedColumns] - an array of ids of selected columns by default.
 * @param {string[]} [props.preparedColumns] - an array of ids of prepared columns.
 * @param {CreateColumn} [props.createColumn] - a function for creating column by its id.
 * @param {SortColumn} [props.sort] - a function for sorting list of available columns
 * @return {[Object[], Object[]]} - returns array:
 *  the first item is tableColumns that will be displayed by default,
 *  the second - available columns that you can select in Manage Layout
 */
export const useAvailableExtractor = (props) => {
  const {
    row,
    selectedColumns,
    getColumns,
    preparedColumns,
    subtractedFields,
    createColumn = makeDefaultColumn,
    sort = defaultSort,
  } = props;

  const [fields, setFields] = useState([]);
  const [tableColumns, setTableColumns] = useState(getColumns(selectedColumns));
  const [available, setAvailable] = useState([]);

  useEffect(
    () => {
      setFields((prev) => {
        const newFields = Array.from(new Set([
          ...prev,
          ...extractFields(row),
        ]).keys());

        if (prev && JSON.stringify(prev) === JSON.stringify(newFields)) {
          return prev;
        }

        return newFields;
      });
    },
    [row],
  );

  useEffect(
    () => {
      const baseColumns = getColumns(selectedColumns);

      let prepared = (preparedColumns || [])
        .filter((key) => !(
          selectedColumns.includes(key)
          || subtractedFields?.includes(key)
        ));

      if (prepared.length) {
        prepared = getColumns(prepared);
      }

      const exists = new Set([
        ...baseColumns.map(({ accessor, id }) => getKey(accessor, id)).filter(Boolean),
        ...prepared.map(({ accessor, id }) => getKey(accessor, id)).filter(Boolean),
      ]);

      const extra = fields.filter((key) => !(
        subtractedFields?.includes(key)
        || exists.has(key)
      ));

      prepared = [...prepared, ...extra.map(createColumn)];

      if (sort) {
        prepared.sort(sort);
      }

      setAvailable(prepared);
      setTableColumns(baseColumns);
    },
    [
      selectedColumns, fields, preparedColumns,
      getColumns, subtractedFields, createColumn,
    ],
  );

  return [tableColumns, available];
};
