import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { FORM_ERROR } from 'final-form';

import LeadPencilIcon from 'mdi-react/LeadPencilIcon';
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';

import PermissionModel from '@/models/Permission';
import RoutePaths from '@/models/RoutePaths';

import { selectors as customerSelectors } from '@/redux/api/customer';
import {
  actions as rolesActions,
  selectors as rolesSelectors,
} from '@/redux/api/roles';
import { selectors as profileSelectors } from '@/redux/api/user/profile';

import Button from '+components/Button';
import { lang } from '+components/charts/common/utils';
import ConfirmModal from '+components/ConfirmModal';
import { ActionsContainer } from '+components/Layout';
import Table from '+components/Table';
import { MenuColumnContextMenu } from '+components/Table/Columns';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import usePermissions from '+hooks/usePermissions';
import useRoles from '+hooks/useRoles';

import { getColumns } from './components/Columns';

const tableId = 'Roles_Table';

const sortBy = [{ id: 'system', desc: true }, { id: 'name' }];

const Roles = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const permissions = usePermissions(PermissionModel.Resources.role.value);
  const { error } = useSelector(rolesSelectors.getState);
  const { roles, isRolesFetching } = useRoles();
  const customer = useSelector(customerSelectors.getCurrentCustomer);
  const profile = useSelector(profileSelectors.getProfile);

  const [isProcessing, setIsProcessing] = useState(null);
  const [roleToDelete, setRoleToDelete] = useState(null);

  const canManageResellerParams = customer?.isReseller || customer?.shortname === 'default';

  useLoadingIndicator(isRolesFetching);

  const cxActionMenu = useCallback(
    (_, original) => {
      const [currentUserRole] = profile?.roles || [];
      const isCurrentUserRole = currentUserRole === original.id;

      const items = [
        {
          icon: <LeadPencilIcon />,
          text: 'Edit',
          onClick: () => {
            navigate(`${RoutePaths.roles}/${original.id}`);
          },
        },
        {
          icon: <TrashCanOutlineIcon />,
          text: 'Delete',
          disabled:
            !permissions?.delete || original.system || isCurrentUserRole,
          onClick: () => {
            setRoleToDelete(original);
          },
        },
      ];

      return (
        <MenuColumnContextMenu
          title={original.name}
          items={items}
          dataTracking="roles"
        />
      );
    },
    [permissions, profile?.roles],
  );

  const columns = useMemo(
    () => getColumns({ canManageResellerParams, cxActionMenu }),
    [canManageResellerParams, cxActionMenu],
  );

  const tableData = useMemo(
    () => {
      const rolesArr = Object.values(roles || {});
      if (canManageResellerParams) {
        return rolesArr;
      }
      return rolesArr.map((item) => ({
        ...item,
        permissions: item.permissions.filter(
          (el) => !PermissionModel.Resources[el.resource].resellersOnly,
        ),
      }));
    },
    [roles, canManageResellerParams],
  );

  const onRoleAdd = useCallback(
    () => {
      navigate(`${RoutePaths.roles}/add`);
    },
    [],
  );

  const onRoleDelete = useCallback(
    () => {
      dispatch(rolesActions.removeRole(roleToDelete.id));
      return new Promise((resolve) => {
        setIsProcessing({ resolve });
      });
    },
    [roleToDelete?.id],
  );

  useEffect(
    () => {
      if (isRolesFetching || !isProcessing) {
        return;
      }

      const { resolve } = isProcessing;

      if (error) {
        resolve({ [FORM_ERROR]: error });
        return;
      }

      setIsProcessing(null);
      setRoleToDelete(null);

      resolve();
    },
    [isRolesFetching, isProcessing, error],
  );

  return (
    <Fragment>
      <ActionsContainer>
        <Button
          disabled={!permissions?.create}
          onClick={onRoleAdd}
          testId="add-role-button"
        >
          Add Role
        </Button>
      </ActionsContainer>

      <Table
        id={tableId}
        columns={columns}
        data={tableData}
        sortBy={sortBy}
        noDataText={roles ? undefined : lang.loading}
        testId="roles-table"
      />

      {!!roleToDelete && (
        <ConfirmModal
          item={roleToDelete.name}
          onToggle={() => setRoleToDelete(null)}
          onConfirm={onRoleDelete}
          isDisabled={isRolesFetching}
          toggleOnConfirm={false}
          isOpen
          testId="delete-role-modal"
        />
      )}
    </Fragment>
  );
};

export default Roles;
