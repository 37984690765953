// get deepest original from row
export const getRowOriginal = (row) => {
  let original = row?.original;
  if (!original) {
    const subRows = row?.subRows;
    if (subRows?.length) {
      original = getRowOriginal(subRows[0]);
    }
  }
  return original;
};
