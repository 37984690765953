import { useLayoutEffect, useMemo, useRef } from 'react';

/**
 * Return memoized function, but it guarantees to run the latest passed callback.
 * @param {Function} callback
 * @returns {function(...[*]): *}
 */
export const useEvent = (callback) => {
  const fnRef = useRef();

  useLayoutEffect(
    () => {
      fnRef.current = callback;
    },
    [callback],
  );

  return useMemo(
    () => (...args) => fnRef.current?.(...args),
    [],
  );
};

export default useEvent;
