import styled from 'styled-components';

import CardBody from './CardBody';
import CardName from './CardName';

export default styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 ${(props) => props.$width || '100%'};
  overflow: hidden;
  height: 100%;

  ${CardName} + ${CardBody} {
    margin-top: 10px;
  }
`;
