import capitalize from 'lodash.capitalize';

import { SelectColumnFilter } from '+components/Table/Filters/SelectColumnFilter';

const defaultLabels = { true: 'TRUE', false: 'FALSE' };
const defaultFixedOptions = ['all', 'true', 'false'];

/**
 * @param {{ true: string, false: string }} [labels] - dictionary with to fields true and false,
 *  values of them are labels for select options, default `{ true: 'TRUE', false: 'FALSE' }`
 * @param {function(value: string): JSX.Element} [optionIcon]
 * @param fixedOptions
 * @return {function(*): JSX.Element}
 * @constructor
 */
export const BooleanColumnFilter = (
  labels = defaultLabels,
  optionIcon = null,
  fixedOptions = defaultFixedOptions,
) => {
  const optionLabel = (key) => labels[key] ?? capitalize(key);
  return SelectColumnFilter({ fixedOptions, optionIcon, optionLabel });
};
