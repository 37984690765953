import PropTypes from '+prop-types';
import { useState, useMemo, useEffect, useRef } from 'react';

import styled from 'styled-components';

import AddIcon from 'mdi-react/AddIcon';
import DeleteForeverIcon from 'mdi-react/DeleteForeverIcon';

import { Field, useFieldArray } from '+components/form/FinalForm';
import {
  Group,
  FieldContainer,
  Label,
  Description,
} from '+components/form/FormField';
import { normalizeSelectValue } from '+components/form/Normalizers';
import NqlTextField from '+components/form/NqlTextField';
import SelectField from '+components/form/SelectField';
import { validateRequired } from '+components/form/Validators';
import IconButtonOrigin from '+components/IconButton';
import { Col, LayoutSizes, Row } from '+components/Layout';
import useEvent from '+hooks/useEvent';
import { useVerifyNqlBeforeSend } from '+hooks/useVerifyNqlBeforeSend';

import { Config } from './utils';

const IconButton = styled(IconButtonOrigin)`
  visibility: ${(props) => (props.$hidden ? 'hidden' : 'visible')};
  pointer-events: ${(props) => (props.$hidden ? 'none' : 'auto')};
  margin-top: 4px;
`;

const FakeButton = styled.div`
  visibility: hidden;
  width: 32px;
  height: 32px;
`;

const severitiesOptions = [
  { value: 'low', label: 'Low' },
  { value: 'medium', label: 'Medium' },
  { value: 'high', label: 'High' },
];

const parse = (v) => v;

const ThresholdItem = (props) => {
  const {
    name,
    index,
    placeholder,
    fieldLength,
    maxLength,
    context,
    required,
    disabled,
    onAdd,
    onRemove,
  } = props;

  const { validateNql } = useVerifyNqlBeforeSend(context, name);

  const nqlValidate = useMemo(
    () => [required && validateRequired, validateNql],
    [required, validateNql],
  );

  const doRemove = useEvent(() => {
    onRemove?.(index);
  });

  return (
    <Row gap={LayoutSizes.groupGap} alignItems="flex-start" wrap="nowrap">
      <Col container={false} xs={4} item>
        <Field
          name={`${name}.severity`}
          component={SelectField}
          options={severitiesOptions}
          validate={validateRequired}
          parse={normalizeSelectValue}
          disabled={disabled}
          required={required}
        />
      </Col>

      <Col container={false} xs={8} item>
        <Field
          name={`${name}.threshold`}
          component={NqlTextField}
          context={context}
          placeholder={placeholder}
          validate={nqlValidate}
          // otherwise it returns undefined even if you will push ''
          // @see: https://final-form.org/docs/react-final-form/types/FieldProps#parse
          parse={parse}
          required={required}
          disabled={disabled}
          allowRecents={false}
        />
      </Col>

      {fieldLength > 1 && (
        <IconButton
          color="primary"
          title="Remove Threshold"
          disabled={disabled}
          onClick={doRemove}
        >
          <DeleteForeverIcon size={16} />
        </IconButton>
      )}

      {maxLength > 1 && (
        <IconButton
          color="primary"
          title="Add Threshold"
          $hidden={fieldLength !== index + 1}
          disabled={disabled || fieldLength === maxLength}
          onClick={onAdd}
        >
          <AddIcon size={16} />
        </IconButton>
      )}
    </Row>
  );
};

ThresholdItem.propTypes = {
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  placeholder: PropTypes.string,
  fieldLength: PropTypes.number.isRequired,
  maxLength: PropTypes.number.isRequired,
  context: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

ThresholdItem.defaultProps = {
  placeholder: undefined,
};

const getIsInvalid = (node) => {
  if (!node) {
    return false;
  }
  const errors = node.querySelectorAll('.form__form-group-error');
  return !!errors.length;
};

const RenderThresholds = (props) => {
  const {
    style,
    fields,
    label,
    helperText,
    placeholder,
    context,
    maxLength,
    required,
    disabled,
  } = props;

  const fieldsColRef = useRef();
  const { meta } = useFieldArray(fields.name, {});
  const [fieldInvalid, setFieldInvalid] = useState(false);
  const fieldLength = fields.value?.length || 0;
  const isRequired = required || fieldLength > 1;

  const groupStyle = useMemo(
    () => ({
      ...(style || {}),
      display: maxLength ? null : 'none',
    }),
    [style, maxLength],
  );

  const onAdd = useEvent(() => {
    fields.push(Config.defaultThresholds);
  });

  const onRemove = useEvent((index) => {
    fields.remove(index);
  });

  const refreshInvalid = useEvent(() => {
    const next = getIsInvalid(fieldsColRef.current);
    if (fieldInvalid !== next) {
      setFieldInvalid(next);
    }
  });

  useEffect(
    () => {
      if (!label) {
        return undefined;
      }

      const timer = setTimeout(refreshInvalid, 100);

      return () => {
        clearTimeout(timer);
      };
    },
    [label, fields.name, meta.error, meta.submitFailed, meta.dirty],
  );

  return (
    <Group id={fields.name} style={groupStyle}>
      {!!fieldLength && label && (
        <Label required={isRequired} invalid={fieldInvalid}>
          {label}
        </Label>
      )}

      <FieldContainer>
        <Row
          gap={LayoutSizes.groupGap}
          alignItems="flex-end"
          wrap="nowrap"
          style={{ height: '26px', fontSize: '13px', fontWeight: '600' }}
        >
          <Col container={false} xs={4} item>
            Severity
          </Col>
          <Col container={false} xs={8} item>
            Threshold
          </Col>
          {fieldLength > 1 && <FakeButton />}
          {maxLength > 1 && <FakeButton />}
        </Row>

        <Col gap="15px">
          {fields.map((name, index) => (
            <ThresholdItem
              key={name}
              name={name}
              index={index}
              placeholder={placeholder}
              fieldLength={fieldLength}
              maxLength={maxLength}
              context={context}
              required={isRequired}
              disabled={disabled}
              onAdd={onAdd}
              onRemove={onRemove}
            />
          ))}
        </Col>
      </FieldContainer>

      {!!fieldLength && helperText && <Description>{helperText}</Description>}
    </Group>
  );
};

RenderThresholds.propTypes = {
  style: PropTypes.shape(),
  fields: PropTypes.shape().isRequired,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  helperText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  placeholder: PropTypes.string,
  context: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

RenderThresholds.defaultProps = {
  style: null,
  label: '',
  helperText: '',
  placeholder: undefined,
  maxLength: Number.MAX_SAFE_INTEGER,
  required: false,
  disabled: false,
};

export default RenderThresholds;
