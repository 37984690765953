import PropTypes from '+prop-types';
import { useState, useCallback, useEffect, useMemo } from 'react';

import styled from 'styled-components';

import AddIcon from 'mdi-react/AddIcon';
import DeleteForeverIcon from 'mdi-react/DeleteForeverIcon';

import { ContextTypes } from '@/models/ContextTypes';

import { Field, useFieldArray } from '+components/form/FinalForm';
import {
  Group,
  FieldContainer,
  Label,
  Description,
} from '+components/form/FormField';
import MultiSelectField from '+components/form/MultiSelectField';
import { normalizeMultiSelectValue } from '+components/form/Normalizers';
import { validateRequired } from '+components/form/Validators';
import IconButtonOrigin from '+components/IconButton';
import { Col, LayoutSizes, Row } from '+components/Layout';

import { useTrackByOptions } from './useTrackByOptions';
import { Config } from './utils';

const IconButton = styled(IconButtonOrigin)`
  visibility: ${(props) => (props.$hidden ? 'hidden' : 'visible')};
  pointer-events: ${(props) => (props.$hidden ? 'none' : 'auto')};
  margin-top: 4px;
`;

const TrackByItem = (props) => {
  const {
    name,
    index,
    placeholder,
    fieldLength,
    maxLength,
    required,
    disabled,
    onAdd,
    onRemove,
    globalSelected,
    nonGlobalFieldSelected,
    fieldValues,
    context,
    addGlobal,
  } = props;

  const trackByOptions = useTrackByOptions(context, { addGlobal });

  const optionsToShow = useMemo(
    () => {
      if (globalSelected) {
        return [];
      }

      if (nonGlobalFieldSelected) {
        return (trackByOptions || []).filter(
          (option) => option.value !== 'global',
        );
      }

      return trackByOptions || [];
    },
    [trackByOptions, globalSelected, nonGlobalFieldSelected],
  );

  return (
    <Row gap={LayoutSizes.groupGap} alignItems="flex-start" wrap="nowrap">
      <Field
        name={name}
        component={MultiSelectField}
        placeholder={placeholder}
        options={optionsToShow}
        validate={[required && validateRequired]}
        parse={normalizeMultiSelectValue}
        disabled={disabled}
        required={required}
      />

      {fieldLength > 1 && (
        <IconButton
          color="primary"
          title="Remove Track"
          disabled={disabled}
          onClick={onRemove}
        >
          <DeleteForeverIcon size={16} />
        </IconButton>
      )}

      {maxLength > 1 && (
        <IconButton
          color="primary"
          title="Add Track"
          $hidden={fieldLength !== index + 1}
          disabled={
            disabled
            || fieldLength === maxLength
            || globalSelected
            || fieldValues?.length < 1
          }
          onClick={onAdd}
        >
          <AddIcon size={16} />
        </IconButton>
      )}
    </Row>
  );
};

TrackByItem.propTypes = {
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  placeholder: PropTypes.string,
  fieldLength: PropTypes.number.isRequired,
  maxLength: PropTypes.number.isRequired,
  required: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  globalSelected: PropTypes.bool.isRequired,
  nonGlobalFieldSelected: PropTypes.bool.isRequired,
  fieldValues: PropTypes.array.isRequired,
  addGlobal: PropTypes.bool.isRequired,
  context: PropTypes.oneOf([ContextTypes.flow, ContextTypes.dns]),
};

TrackByItem.defaultProps = {
  placeholder: undefined,
  context: ContextTypes.flow,
};

const RenderTrackBy = (props) => {
  const {
    fields,
    label,
    helperText,
    placeholder,
    maxLength,
    required,
    disabled,
    context,
    addGlobal,
  } = props;

  const { meta } = useFieldArray(fields.name, {});
  const [fieldInvalid, setFieldInvalid] = useState(false);
  const fieldLength = fields?.length;
  const isRequired = required || fieldLength > 1;

  const getFieldValues = useCallback(
    (i) => fields?.value?.[i],
    [fields],
  );

  const onAdd = useCallback(
    () => fields.push(Config.defaultTrackBy),
    [fields],
  );

  // true when 'global' is selected
  const globalSelected = useMemo(
    () => {
      let selected = false;
      fields?.value?.forEach((fieldArray) => {
        if (fieldArray.includes('global')) {
          selected = true;
        }
      });
      return selected;
    },
    [fields],
  );

  // true when any field other than 'global' is selected
  const nonGlobalFieldSelected = useMemo(
    () => {
      let selected = false;
      fields?.value?.forEach((fieldArray) => {
        if (fieldArray?.length > 0) {
          selected = true;
        }
      });
      return selected;
    },
    [fields],
  );

  const onRemove = useCallback(
    (index) => () => fields.remove(index),
    [fields],
  );

  useEffect(
    () => {
      if (fieldLength > maxLength) {
        fields.forEach((_, i) => {
          if (i > maxLength - 1 && !fields.value[i].hidden) {
            fields.remove(i);
          }
        });
      }
    },
    [fields, fieldLength, maxLength],
  );

  useEffect(
    () => {
      if (!label) {
        return;
      }
      const nqlEl = document.getElementById(fields.name);
      const errors = nqlEl?.getElementsByClassName('form__form-group-error');
      setFieldInvalid(!!errors?.length);
    },
    [label, fields.name, meta],
  );

  return (
    <Group id={fields.name} style={{ display: maxLength ? null : 'none' }}>
      {!!fieldLength && label && (
        <Label required={isRequired} invalid={fieldInvalid}>
          {label}
        </Label>
      )}

      <FieldContainer>
        <Col gap="15px">
          {fields.map((name, index) => (
            <TrackByItem
              key={name}
              name={name}
              index={index}
              placeholder={placeholder}
              fieldLength={fieldLength}
              maxLength={maxLength}
              required={isRequired}
              disabled={disabled}
              onAdd={onAdd}
              onRemove={onRemove(index)}
              globalSelected={globalSelected}
              nonGlobalFieldSelected={nonGlobalFieldSelected}
              fieldValues={getFieldValues(index)}
              context={context}
              addGlobal={addGlobal}
            />
          ))}
        </Col>
      </FieldContainer>

      {!!fieldLength && helperText && <Description>{helperText}</Description>}
    </Group>
  );
};

RenderTrackBy.propTypes = {
  fields: PropTypes.shape().isRequired,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  helperText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  context: PropTypes.oneOf([ContextTypes.flow, ContextTypes.dns]),
  addGlobal: PropTypes.bool,
};

RenderTrackBy.defaultProps = {
  label: '',
  helperText: '',
  placeholder: undefined,
  maxLength: Number.MAX_SAFE_INTEGER,
  required: false,
  disabled: false,
  context: ContextTypes.flow,
  addGlobal: false,
};

export default RenderTrackBy;
