import PropTypes from '+prop-types';
import { useSelector } from 'react-redux';

import { selectors } from '@/redux/api/rules';

import { HeaderSubheader } from '+components/Table/Cells/HeaderSubheaderCell';

const CategoryLabel = ({ name, hideDescription }) => {
  const category = useSelector(selectors.getCategoryByName(name));
  return (
    <HeaderSubheader
      header={name}
      subHeader={hideDescription ? null : category?.description}
    />
  );
};

CategoryLabel.propTypes = {
  name: PropTypes.string,
  hideDescription: PropTypes.bool,
};

CategoryLabel.defaultProps = {
  name: '',
  hideDescription: false,
};

export default CategoryLabel;
