import '@/scss/app.scss';
import { Provider } from 'react-redux';

import authClient from '@/middleware/authClient';
import { configureBackendClient } from '@/middleware/backendClient';
import { store } from '@/redux';
import AppRouter from '@/routers/AppRouter';

import AuthProvider from './components/AuthProvider';
import FontsReadyAwaiter from './components/FontsReadyAwaiter';

configureBackendClient(() => {
  authClient.logout();
});

const App = () => (
  <AuthProvider>
    <FontsReadyAwaiter>
      <Provider store={store}>
        <AppRouter />
      </Provider>
    </FontsReadyAwaiter>
  </AuthProvider>
);

export default App;
