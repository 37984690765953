import PropTypes from '+prop-types';
import { memo } from 'react';
import { areEqual } from 'react-window';

import DividerOrigin from '@mui/material/Divider';
import ListSubheaderOrigin from '@mui/material/ListSubheader';

import OptionOrigin from '+components/form/Select/components/Option';
import useEvent from '+hooks/useEvent';

import { separatorHeight, listPadding } from './getItemSize';

const Divider = memo(DividerOrigin, areEqual);
const ListSubheader = memo(ListSubheaderOrigin, areEqual);
const Option = memo(OptionOrigin, areEqual);

const fnOrOrigin = (fn) => fn?.origin || fn;

const Row = (props) => {
  const { data, index, style } = props;
  const { option, group, separator, key, ...renderProps } = data[index];
  const insideStyle = {
    ...style,
    top: style.top + listPadding,
  };

  // we need these memoization in order to avoid unnecessary re-renders
  const onClick = useEvent(fnOrOrigin(renderProps?.onClick));
  const onMouseOver = useEvent(fnOrOrigin(renderProps?.onMouseOver));
  const onTouchStart = useEvent(fnOrOrigin(renderProps?.onTouchStart));

  if (renderProps) {
    // to avoid recursive calls
    onClick.origin = onClick.origin || renderProps.onClick;
    renderProps.onClick = onClick;
    onMouseOver.origin = onMouseOver.origin || renderProps.onMouseOver;
    renderProps.onMouseOver = onMouseOver;
    onTouchStart.origin = onTouchStart.origin || renderProps.onTouchStart;
    renderProps.onTouchStart = onTouchStart;
  }

  if (group != null) {
    return (
      <ListSubheader
        key={key}
        style={insideStyle}
        disableSticky
        component="li"
        className="MuiAutocomplete-groupLabel virtualized"
      >
        {group}
      </ListSubheader>
    );
  }

  if (separator) {
    insideStyle.height = separatorHeight;
    return <Divider key={key} style={insideStyle} />;
  }

  return (
    <Option
      {...renderProps}
      key={key}
      option={option}
      style={insideStyle}
    />
  );
};

Row.displayName = 'Row';
Row.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape(),
      PropTypes.arrayOf(PropTypes.shape()),
    ]),
  ).isRequired,
  index: PropTypes.number.isRequired,
  style: PropTypes.shape().isRequired,
};

export default Row;
