import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectors as customerSelectors } from '@/redux/api/customer';

import { useCrumbs } from '+components/Breadcrumb';
import { getExportingFilename } from '+utils/getExportingFilename';

const useExportingFilename = (chartTitle, chartCustomer) => {
  const [crumbs = []] = useCrumbs();
  const customer = useSelector(customerSelectors.getCurrentCustomer);

  return useMemo(
    () => {
      const [lastCrumb = {}] = crumbs.slice(-1);
      const pageTitle = lastCrumb.title;
      const shortname = chartCustomer || customer?.shortname;
      return getExportingFilename({
        shortname: Array.isArray(shortname) ? shortname.join('-') : shortname,
        pageTitle,
        chartTitle,
      });
    },
    [customer?.shortname, crumbs, chartTitle, chartCustomer],
  );
};

export default useExportingFilename;
