import { ContextTypes } from '@/models/ContextTypes';

import {
  selectors as auditLogsSelectors,
  actions as auditLogsActions,
} from '@/redux/api/auditLogs';
import {
  selectors as blocksSelectors,
  actions as blocksActions,
} from '@/redux/api/blocks';
import {
  selectors as dnsSelectors,
  actions as dnsActions,
} from '@/redux/api/dns';
import {
  selectors as eventsSelectors,
  actions as eventsActions,
} from '@/redux/api/events';
import {
  selectors as flowsSelectors,
  actions as flowsActions,
} from '@/redux/api/flows';

export const selectorsMap = {
  [ContextTypes.flow]: flowsSelectors,
  [ContextTypes.dns]: dnsSelectors,
  [ContextTypes.alerts]: eventsSelectors,
  [ContextTypes.blocks]: blocksSelectors,
  [ContextTypes.audit]: auditLogsSelectors,
};

export const actionsMap = {
  [ContextTypes.flow]: flowsActions,
  [ContextTypes.dns]: dnsActions,
  [ContextTypes.alerts]: eventsActions,
  [ContextTypes.blocks]: blocksActions,
  [ContextTypes.audit]: auditLogsActions,
};
