import PropTypes from '+prop-types';

import FormField from '+components/form/FormField';
import Select from '+components/form/Select';

import createDescriptionLinks from './createDescriptionLinks';

const SelectField = (props) => {
  const {
    input,
    label,
    helperText,
    meta,
    disabled,
    readOnly,
    required,
    parseDescriptionUrls,
    ...tail
  } = props;

  const { touched, error, dirty, submitFailed } = meta || {};

  const { value } = input;
  const invalid = error && (dirty || submitFailed) && touched;

  return (
    <FormField
      label={label}
      helperText={parseDescriptionUrls ? createDescriptionLinks(helperText) : helperText}
      error={error}
      invalid={invalid}
      disabled={disabled}
      required={required}
    >
      <Select
        {...tail}
        {...input}
        value={value}
        disabled={disabled}
        readOnly={readOnly}
        invalid={invalid}
      />
    </FormField>
  );
};

SelectField.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    dirty: PropTypes.bool,
    submitFailed: PropTypes.bool,
  }),
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  helperText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  parseDescriptionUrls: PropTypes.bool,
};

SelectField.defaultProps = {
  meta: null,
  label: null,
  helperText: null,
  disabled: false,
  readOnly: false,
  required: false,
  parseDescriptionUrls: false,
};

export default SelectField;
