import styled from 'styled-components';

import Item from './Item';

export default styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: ${(props) => (props.$justifyFlexEnd ? 'flex-end' : undefined)};

  ${Item} + ${Item} {
    margin-top: 3px;
  }
`;
