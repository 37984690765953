import styled from 'styled-components';

const AdditionalFiltersSeparator = styled.div`
  flex-shrink: 0;
  align-self: center;
  width: ${({ $width }) => $width || 1}px;
  height: 100%;
  background: ${({ theme }) => theme.colorBackgroundSeparator};
`;

export default AdditionalFiltersSeparator;
