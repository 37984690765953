import styled from 'styled-components';

const EmphasizedCode = styled.code`
  background-color: ${(props) => props.theme.empCodeTagBackground};
  padding: 0 2px;
  border: 1px solid ${(props) => props.theme.empCodeTagBorder};
  border-radius: 2px;
`;

export default EmphasizedCode;
