import { NumberColumnFilter } from '+components/Table/Filters';

import { BaseColumnFactory } from './BaseColumnFactory';

/**
 * @param {Object} props
 * @param {string} [props.description=''] - field description
 * @param {string} [props.Header] - header text value
 * @param {string} [props.filter] - filter type
 * @param {function} [props.Filter] - filter function override
 * @param {function} [props.Cell] - cell data formatter
 * @param {function} [props.textAlign] - text alignment
 * @param {boolean} [props.genericCell] - use generic cell instead of UniversalCell in BaseColumnFactory, helpful to override formatting (i.e. thousand separators)
 * @return {Object}
 */
export const NumberColumnFactory = (props) => {
  const baseProps = { ...props };
  if (!baseProps.Filter) {
    baseProps.Filter = NumberColumnFilter;
  }
  if (!baseProps.filter) {
    baseProps.filter = 'numberFilter';
  }
  if (baseProps.genericCell && !baseProps.Cell) {
    baseProps.Cell = ({ value }) => value;
  }
  return {
    ...BaseColumnFactory(baseProps),
    getCellProps: () => ({
      style: { justifyContent: props.textAlign ?? 'flex-end' },
    }),
    sortType: 'number',
  };
};
