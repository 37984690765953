import { color as d3color } from 'd3-color';

const rand = (min, max) => min + Math.random() * (max - min);

export class Path {
  constructor({ source, target, speed, lineWidth, color }) {
    this._source = source;
    this._target = target;

    this._speed = (speed || 1) * 0.01;
    this._lineWidth = lineWidth || 1;
    this._color = d3color(color || 'rgba(255, 0, 0, 0.5)');

    this._position = 0;

    this._atTarget = false;

    this._tick = rand(-100, 100);

    this._calcParams();
  }

  /**
   * @private
   */
  _calcPath(sx, sy, tx, ty) {
    const width = Math.abs(sx - tx) * 0.5;

    // P = a + b + c
    const P = (this._width + this._height + this._diagonal) * 0.5;

    // S = √P * (P - a) * (P - b) * (P - c)
    const S = Math.sqrt(
      P * (P - this._width) * (P - this._height) * (P - this._diagonal),
    );

    const h = (2 * S) / this._diagonal;

    this._path = {
      cpx: Math.min(sx, tx) + width * (0.5 + Math.sin(this._tick)),
      cpy: Math.min(sy, ty) - h * (0.5 + Math.cos(this._tick)),
    };
  }

  /**
   * @private
   */
  _calcParams() {
    const sx = this._source.x;
    const sy = this._source.y;
    const tx = this._target.x;
    const ty = this._target.y;

    this._width = tx - sx;
    this._height = ty - sy;

    this._diagonal = Math.sqrt(
      this._width * this._width + this._height * this._height,
    );

    this._step = this._diagonal * this._speed;
    this._calcPath(sx, sy, tx, ty);
  }

  /**
   * @param ctx
   * @private
   */
  _drawPath(ctx) {
    if (this._atTarget) {
      return;
    }

    if (!this._atTarget && this._position >= this._diagonal * 1.5) {
      this._atTarget = true;
      return;
    }

    this._position += this._step;
    this._tick += 0.03;
    this._calcParams();

    const sx = this._source.x;
    const sy = this._source.y;
    const tx = this._target.x;
    const ty = this._target.y;

    ctx.save();

    ctx.lineCap = 'round';
    ctx.lineJoin = 'round';

    ctx.beginPath();
    ctx.strokeStyle = this._color;
    ctx.lineWidth = this._lineWidth;

    ctx.lineDashOffset = this._diagonal - this._position * 1.5;
    ctx.setLineDash([this._diagonal * 0.5 + this._position, 1e5]);

    ctx.moveTo(sx, sy);
    ctx.quadraticCurveTo(this._path.cpx, this._path.cpy, tx, ty);
    ctx.stroke();

    ctx.restore();
  }

  get atTarget() {
    return this._atTarget;
  }

  draw(ctx, canvasSize) {
    this._drawPath(ctx, canvasSize);
  }
}
