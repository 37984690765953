import { ContextIntegrations } from '@/models/integrations/ContextIntegrations';
import { ResponseIntegrations } from '@/models/integrations/ResponseIntegrations';
import LabelContextTypes from '@/models/LabelContextTypes';
import LabelTypes from '@/models/LabelTypes';

export const getContextType = (item) => {
  if (!item) {
    return null;
  }
  if (!item.hasdefault) {
    return LabelContextTypes.custom;
  }
  return item.default ? LabelContextTypes.system : LabelContextTypes.customized;
};

export const isUserContext = (type, item) => {
  if (type === LabelTypes.ip) {
    return item.context === 'name';
  }
  if (type === LabelTypes.port) {
    return item.default === false;
  }
  return false;
};

export const isProviderContext = (context, label) => context === 'provider'
  && (!!ResponseIntegrations[label] || ContextIntegrations[label]);
