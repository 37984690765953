import { useMemo } from 'react';

import { ContextTypes } from '@/models/ContextTypes';
import SourceTypes from '@/models/SourceTypes';

import useProviderClassifications from '+hooks/useProviderClassifications';

export const useSourceTypes = (context) => {
  const classifications = useProviderClassifications();

  return useMemo(
    () => {
      if (!classifications || context === ContextTypes.flow) {
        return Object.values(SourceTypes);
      }

      return Object.values(SourceTypes)
        .map((sourceType) => {
          if (sourceType.value === 'all') {
            return {
              ...sourceType,
            };
          }

          const classification = classifications[sourceType.value] || {};

          const found = Object.values(classification).some((item) => {
            return item[context]?.length;
          });

          if (!found) {
            return null;
          }

          return {
            ...sourceType,
            group: `Cloud Providers ${context} Types`,
          };
        })
        .filter(Boolean);
    },
    [context, classifications],
  );
};
