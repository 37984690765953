import PropTypes from '+prop-types';

import GenericLabel from './GenericLabel';

const OwnerAsLabel = ({ number, ...tail }) => (
  <GenericLabel {...tail} context={number} />
);

OwnerAsLabel.propTypes = {
  number: PropTypes.number,
};

OwnerAsLabel.defaultProps = {
  number: null,
};

export default OwnerAsLabel;
