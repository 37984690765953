import PropTypes from '+prop-types';
import { useContext, useMemo, useCallback, useEffect } from 'react';

import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';

import Alert from '+components/Alert';
import Button, { ButtonVariants } from '+components/Button';
import Form from '+components/form/Form';
import Description from '+components/form/FormField/components/Description';
import FieldContainer from '+components/form/FormField/components/FieldContainer';
import Group from '+components/form/FormField/components/Group';
import Label from '+components/form/FormField/components/Label';
import Toggle from '+components/form/Toggle';
import { Row, Col } from '+components/Layout';
import Table from '+components/Table';
import { MenuColumnButton } from '+components/Table/Columns';

import Context from '../Context';
import { Columns, getColumns } from './components/Columns';
import Container from './components/Container';
import OtpContainer from './components/OtpContainer';
import Separator from './components/Separator';
import SubTitle from './components/SubTitle';
import Title from './components/Title';

const otpDeviceTableId = 'Profile_OTP_Device_Table';
const otpDevicePreparedColumns = Object.values(Columns);

const SecuritySection = (props) => {
  const {
    onSendChangePasswordEmail,
    onOtpChange,
    onOtpDeviceAdd,
    onOtpDeviceDelete,
    onOtpDevices,
    onOtpEmailChange,
  } = props;

  const { customer, user, otpDevices, isFetching, isImpersonating } = useContext(Context);

  const otpDeviceConfigurationRequested = useMemo(
    () => (user.requiredActions || []).indexOf('CONFIGURE_TOTP') !== -1,
    [user.requiredActions],
  );

  const otpDevicesWithRequests = useMemo(
    () => (otpDeviceConfigurationRequested
      ? [
        ...(otpDevices || []),
        {
          id: 'new',
          userLabel: 'Pending...',
          createdDate: null,
          credentialData: {},
          type: 'otp',
        },
      ]
      : otpDevices || []),
    [otpDevices, otpDeviceConfigurationRequested],
  );

  const isIdp = user?.idp;
  const areControlsDisabled = isFetching || isImpersonating || isIdp;
  const isOtpDeviceDeleteDisabled = areControlsDisabled
    || (customer.isOtpRequired
      && otpDevices?.length === 1
      && !otpDeviceConfigurationRequested);
  const isAuthAppDisabled = areControlsDisabled
    || (user.otp && customer.isOtpRequired)
    || user.otpEmail;
  const isEmailDisabled = areControlsDisabled || user.otp;

  const cxActionMenu = useCallback(
    (_, original) => (
      <MenuColumnButton
        title="Delete"
        disabled={isOtpDeviceDeleteDisabled}
        onClick={() => onOtpDeviceDelete(original)}
      >
        <TrashCanOutlineIcon size={16} />
      </MenuColumnButton>
    ),
    [isOtpDeviceDeleteDisabled, onOtpDeviceDelete],
  );

  const columns = useMemo(
    () => getColumns(otpDevicePreparedColumns, { cxActionMenu }),
    [cxActionMenu],
  );

  useEffect(
    () => {
      if (user?.otp && onOtpDevices) {
        onOtpDevices();
      }
    },
    [user?.otp, onOtpDevices, otpDeviceConfigurationRequested],
  );

  const showOtpDevices = user.otp
    && otpDevicesWithRequests.length > (otpDeviceConfigurationRequested ? 1 : 0);

  return (
    <Container>
      {isImpersonating && (
        <Alert>
          Security settings cannot be changed by a masquerading users.
        </Alert>
      )}

      {isIdp && (
        <Alert>
          Security settings are controlled by a custom Single Sign-On (SSO).
        </Alert>
      )}

      {!isIdp && (
        <Form>
          <Title>Password</Title>

          <Group style={{ marginTop: '10px' }}>
            <Label disabled={areControlsDisabled}>Change Password</Label>
            <FieldContainer>
              <Button
                variant={ButtonVariants.outlined}
                disabled={areControlsDisabled}
                onClick={onSendChangePasswordEmail}
              >
                Reset your password
              </Button>
            </FieldContainer>
            <Description style={{ marginTop: '10px' }}>
              Click the button to receive an email with a link to reset your
              password.
            </Description>
          </Group>

          <Separator />

          <Title>Multi-Factor Authentication</Title>
          <SubTitle>Time-Based One-Time Password (TOTP) Methods</SubTitle>

          <Group style={{ marginTop: '10px' }}>
            <Label disabled={isAuthAppDisabled}>Authenticator App</Label>
            <FieldContainer>
              <Toggle
                checkedLabel="Enabled"
                checked={user.otp}
                onChange={onOtpChange}
                disabled={isAuthAppDisabled}
              />
            </FieldContainer>
            <Description>
              Once enabled, you will be prompted after your next login with a QR
              code to scan into an authenticator app, such as Google
              Authenticator.
            </Description>
          </Group>

          <OtpContainer>
            {otpDeviceConfigurationRequested && (
              <Alert severity="warning">
                Please logout and login again to configure your TOTP.
              </Alert>
            )}

            {user.otp && customer.isOtpRequired && (
              <Alert>
                You can not disable Two-Factor Authentication because it is
                required by your company administrator.
              </Alert>
            )}

            {showOtpDevices && (
              <Row>
                <Col xs={12} item>
                  {!otpDeviceConfigurationRequested && (
                    <Button
                      variant={ButtonVariants.outlined}
                      onClick={onOtpDeviceAdd}
                      disabled={areControlsDisabled}
                      style={{ marginBottom: '10px' }}
                    >
                      Add Another Device
                    </Button>
                  )}

                  <Table
                    id={otpDeviceTableId}
                    columns={columns}
                    data={otpDevicesWithRequests}
                    noDataText="No configured TOTP devices"
                    pagination={false}
                    showManagerLayout={false}
                    disableFilters
                    minRows={1}
                  />
                </Col>
              </Row>
            )}
          </OtpContainer>

          <Group style={{ marginTop: '10px' }}>
            <Label disabled={isEmailDisabled}>Email</Label>
            <FieldContainer>
              <Toggle
                checkedLabel="Enabled"
                checked={user.otpEmail}
                onChange={onOtpEmailChange}
                disabled={isEmailDisabled}
              />
            </FieldContainer>
            <Description>
              Enables you to authenticate using the (OTP) that is sent to your
              email address.
            </Description>
          </Group>
        </Form>
      )}
    </Container>
  );
};

SecuritySection.propTypes = {
  onSendChangePasswordEmail: PropTypes.func,
  onOtpChange: PropTypes.func,
  onOtpDeviceAdd: PropTypes.func,
  onOtpDeviceDelete: PropTypes.func,
  onOtpDevices: PropTypes.func,
  onOtpEmailChange: PropTypes.func,
};

SecuritySection.defaultProps = {
  onSendChangePasswordEmail: null,
  onOtpChange: null,
  onOtpDeviceAdd: null,
  onOtpDeviceDelete: null,
  onOtpDevices: null,
  onOtpEmailChange: null,
};

export default SecuritySection;
