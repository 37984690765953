import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
  padding-right: 10px;

  a:last-of-type div {
    border-bottom: 0;
  }
`;
