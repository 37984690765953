import PropTypes from '+prop-types';
import { Fragment, useCallback, useContext, useState } from 'react';

import styled from 'styled-components';

import FileDelimitedOutlineIcon from 'mdi-react/FileDelimitedOutlineIcon';

import { Menu, Separator, Provider as MenuProvider } from '+components/Menu';
import { ExportTypes } from '+components/Table/hooks/useExport';

import ActionsList, { ExportAction } from './ActionsList';
import { Icon } from './ActionsList/LayoutItem';
import ColumnsList from './ColumnsList';
import ContainerOrigin from './Container';
import Context from './Context';
import Filter from './Filter';
import Title from './Title';
import Trigger from './Trigger';

export { Provider, Consumer } from './Context';

const MenuStyled = styled(Menu)`
  padding: 0;
  max-width: 500px;
`;

const ExportActionTitle = styled.div`
  display: flex;
  font-size: 12px;
  align-items: center;
`;

const ExportActionContainer = styled(ContainerOrigin)`
  flex-direction: row;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
  &:only-child {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  gap: 5px;

  ${Icon} {
    margin-right: unset;
  }

  ${ExportActionTitle} {
    margin-left: -2px;
  }
`;

const ManageColumnHeader = ({ allColumns }) => {
  const {
    showManagerLayout,
    exportingCurrentView,
    exportingAllFields,
    exportData,
    isExporting,
  } = useContext(Context);

  const [filter, setFilter] = useState();

  const onExportDataClick = useCallback(
    (type) => () => exportData(type),
    [exportData],
  );

  return (
    <MenuProvider>
      <Trigger />
      <MenuStyled>
        <ExportActionContainer>
          <Icon>
            <FileDelimitedOutlineIcon size={14} />
          </Icon>
          <ExportActionTitle>
            {isExporting ? 'Exporting...' : 'Export CSV:'}
          </ExportActionTitle>
          <ExportAction
            title="Exports just the columns in the table"
            onClick={
              isExporting || !exportingCurrentView
                ? null
                : onExportDataClick(ExportTypes.csvCurrentView)
            }
            disabled={isExporting || !exportingCurrentView}
            $disabled={isExporting || !exportingCurrentView}
          >
            current view
          </ExportAction>
          |
          <ExportAction
            title="Exports all fields"
            onClick={
              isExporting || !exportingAllFields
                ? null
                : onExportDataClick(ExportTypes.csv)
            }
            disabled={isExporting || !exportingAllFields}
            $disabled={isExporting || !exportingAllFields}
          >
            all fields
          </ExportAction>
        </ExportActionContainer>
        {showManagerLayout && (
          <Fragment>
            <Separator />
            <Title />
            <Filter onChange={setFilter} />
            <ColumnsList allColumns={allColumns} filter={filter} />
            <Separator />
            <ActionsList />
          </Fragment>
        )}
      </MenuStyled>
    </MenuProvider>
  );
};

ManageColumnHeader.propTypes = {
  allColumns: PropTypes.arrayOf(PropTypes.shape()),
};

ManageColumnHeader.defaultProps = {
  allColumns: null,
};

export default ManageColumnHeader;
