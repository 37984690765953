export default {
  accent: 'hsl(195, 61%, 62%)',
  accentHover: 'hsl(195, 61%, 52%)',
  additional: 'hsl(0, 0%, 60%)',
  additionalHover: 'hsl(0, 0%, 50%)',
  yellow: 'hsl(48, 88%, 70%)',
  yellowHover: 'hsl(48, 88%, 60%)',
  yellow2: '#f6d874',
  violet: '#c88ffa',
  red: 'hsl(352, 100%, 64%)',
  redHover: 'hsl(352, 100%, 54%)',
  red2: '#FD4862',
  blue: 'hsl(195, 61%, 62%)',
  blueHover: 'hsl(195, 61%, 52%)',
  blue2: '#29b6f6',
  lightBlue: '#cce7ff',
  gray: '#787985',
  darkGray: '#646777',
  green: '#4ce1b6',

  lightBlueGray: '#B4BFD0',
  a8gray: '#a8a8a8',

  dark1: '#25252b',
  dark2: '#202124',
  dark3: '#2e2e33',
  dark4: '#33333a',

  light1: '#dee2e6',
  light2: '#ececef',
  light3: '#f2f4f7',
  light4: '#ffffff',

  // UI2020
  primary: '#61BBD9',
  white: '#FFFFFF',

  gray1: '#DFDFDF',
  gray2: '#A2A2A2',
  gray3: '#707070',
  gray4: '#54545A',
  gray5: '#35353B',
  gray6: '#2A2A2F',
  gray7: '#202124',
  gray8: '#16171A',

  // Chart & Alert Colors
  success: '#53E0B7',
  warning: '#F6D874',
  medium: '#FE9F43',
  danger: '#FD4862',
  burningOrange: '#FD7043',
  deco: '#CADB86',
  feijoa: '#A4DC95',
  algaeGreen: '#7EDEA5',
  turquoise: '#53E0B7',
  dodgerBlue: '#29B6F6',
  pictonBlue: '#42A5F5',
  indigo: '#5C6BC0',
  fuchsiaBlue: '#7E57C2',
  amethyst: '#AB47BC',
};
