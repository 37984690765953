import PropTypes from '+prop-types';
import { useSelector } from 'react-redux';

import styled from 'styled-components';

import OpenInNewIcon from 'mdi-react/OpenInNewIcon';

import { selectors as docsSelectors } from '@/redux/api/docs';

import { ndmUrlMappings } from '@/pages/Models/shared/utils';

import Tooltip from '+components/Tooltip';

const StyledOpenInNewIcon = styled(OpenInNewIcon)`
  height: 14px;
  vertical-align: middle;
  margin-bottom: 3px;
`;

/**
 *
 * DetectionNameLink component
 *
 * @param {string} detectionName
 */
const DetectionNameLink = ({ detectionName }) => {
  const detectionModelDoc = useSelector(
    docsSelectors.getDetectionModelDoc(detectionName),
  );
  const url = detectionModelDoc?.url || ndmUrlMappings[detectionName]?.url;
  return (
    <span>
      {!url ? (
        detectionName
      ) : (
        <Tooltip title="Learn more about this detection model" arrow={false}>
          <a href={url} target="_blank" rel="noopener noreferrer">
            {detectionName} <StyledOpenInNewIcon />
          </a>
        </Tooltip>
      )}
    </span>
  );
};

DetectionNameLink.propTypes = {
  detectionName: PropTypes.string.isRequired,
};

export default DetectionNameLink;
