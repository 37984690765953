import { BaseColumnFactory } from '+components/Table/Columns/BaseColumnFactory';
import { SelectColumnFilter } from '+components/Table/Filters';
import sortByHelper from '+utils/sortByHelper';

export const GeoColumnFactory = (props) => ({
  ...BaseColumnFactory(props),
  // realAccessor: props.field,
  realAccessor: [`${props.field}.countrycode`, `${props.field}.subdiso`],
  Filter: SelectColumnFilter({
    optionLabel: (key) => (key === 'all' ? 'All' : key),
    optionValueExtractor: (row, id) => row.values[id]?.countrycode || null,
    enableLikeFilter: true,
  }),
  filter: 'geoFilter',
  sortType: sortByHelper((value) => {
    const { countrycode, subdiso } = value || {};
    return `${countrycode ?? ''}${subdiso ?? ''}`;
  }),
});
