import { createGlobalStyle } from 'styled-components';

import { ScrollBarMixin } from '+theme/mixins/scrollBarMixin';

import form from './form';
import input from './input';
import scaffolding from './scaffolding';

export default createGlobalStyle`
  ${form}
  ${input}
  ${scaffolding}

  .MuiPickersPopper-root {
    .MuiPaper-root {
      background-color: transparent;
    }
  }

  .resizingOverrideCursor * {
    cursor: col-resize !important;
  }

  .force-disabled {
    pointer-events: none !important;
  }

  .withStyledScrollbar {
    ${ScrollBarMixin}
  }
`;
