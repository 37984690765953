import PropTypes from '+prop-types';
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import classNames from 'classnames';

import Button, { ButtonVariants } from '+components/Button';
import ButtonGroup from '+components/ButtonGroup';
import { Dropdown, DropdownItem } from '+components/Dropdown';
import { useFormState, useForm } from '+components/form/FinalForm';
import Form from '+components/form/Form';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import usePageTabsAndFormSync from '+hooks/usePageTabsAndFormSync';

import ActionsContainer from './ActionsContainer';
import Container from './Container';
import Description from './Description';
import FormContainer from './FormContainer';
import Image from './Image';
import LeftPanel from './LeftPanel';
import RightPanel from './RightPanel';
import Tab from './Tab';
import TabIcon from './TabIcon';
import TabsContainer from './TabsContainer';
import TabSeparator from './TabSeparator';
import TabTitle from './TabTitle';
import Title from './Title';

const FormBody = (props) => {
  const {
    className,
    material,
    horizontal,
    title,
    description,
    image,
    children,
    steps,
    activeStep,
    initialValues,
    resetter,
    additionalActions,
    cancelButtonText,
    confirmButtonText,
    setIsConfirmButtonClicked,
    secondaryButtonText,
    prevButtonText,
    nextButtonText,
    loading,
    disabled,
    resetOnChancel,
    handleSubmit,
    onCancel,
    onSecondary,
    onTabButtonClick,
    onPrevButtonClick,
    setNextStep,
    deleteButtonText,
    deleteButtonDisabled,
    deleteButtonHidden,
    onDelete,
  } = props;

  useLoadingIndicator(loading);
  usePageTabsAndFormSync();

  const { submitting, submitFailed } = useFormState({
    subscription: {
      submitting: true,
      submitFailed: true,
    },
  });
  const form = useForm();
  const formRef = useRef();

  const [showErrors, setShowErrors] = useState(false);

  const remainingRequiredSteps = useMemo(
    () => steps.filter(
      (step, index) => index > activeStep && !step.optional && !step.completed,
    ),
    [steps, activeStep],
  );

  const submit = useCallback(
    () => {
      formRef.current?.dispatchEvent(
        new Event('submit', {
          cancelable: true,
          bubbles: true,
        }),
      );
    },
    [],
  );

  const timer = useRef();
  const onTabClick = useCallback(
    (index) => () => {
      setIsConfirmButtonClicked();
      if (index > activeStep) {
        setNextStep(index);
        timer.current = setTimeout(() => {
          submit();
        }, 10);
        return;
      }

      onTabButtonClick(index);
    },
    [onTabButtonClick, activeStep, setIsConfirmButtonClicked],
  );

  useEffect(
    () => () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    },
    [],
  );

  const onCancelButtonClick = useCallback(
    (event) => {
      if (resetOnChancel) {
        setShowErrors(false);
        form.reset();
      }

      if (onCancel) {
        onCancel(event);
      }
    },
    [form, onCancel, resetOnChancel],
  );

  const onNextButtonClick = useCallback(
    () => {
      setIsConfirmButtonClicked(false);
    },
    [setIsConfirmButtonClicked],
  );

  const onSubmitClick = useCallback(
    () => {
      setIsConfirmButtonClicked(true);
    },
    [setIsConfirmButtonClicked],
  );

  // Initialize form if formValues are empty and initialValues are not
  useEffect(
    () => {
      form.initialize(initialValues);
    },
    [initialValues],
  );

  useEffect(
    () => {
      if (resetter) {
        form.reset();
      }
    },
    [resetter],
  );

  // Uncomment to debug form
  // useEffect(
  //   () => {
  //     form.setConfig('debug', console.log);
  //   },
  //   [],
  // );

  return (
    <Container
      className={classNames(className, 'wizard', {
        'wizard__with-tabs': steps.length > 1,
      })}
    >
      <LeftPanel className="wizard__left-panel">
        {steps.length > 1 && (
          <TabsContainer className="wizard__tabs">
            {steps.map((step) => (
              <Tab
                key={step.index}
                className={classNames('wizard__tab', {
                  'wizard__tab--active': step.active,
                  'wizard__tab--complete': step.complete,
                  'wizard__tab--disabled': step.disabled,
                })}
                type="button"
                invalid={showErrors || submitFailed}
                onClick={onTabClick(step.index)}
              >
                <TabIcon className="wizard__tab-icon" />
                {step.title && (
                  <TabTitle
                    className={classNames('wizard__tab-title', {
                      optional: step.optional,
                    })}
                  >
                    {step.title}
                  </TabTitle>
                )}
                {step.index !== steps.length - 1 && (
                  <TabSeparator className="wizard__tab-separator" />
                )}
              </Tab>
            ))}
          </TabsContainer>
        )}
        <FormContainer className="wizard__form-container">
          <Form
            className="wizard__form"
            material={material}
            horizontal={horizontal}
            onSubmit={handleSubmit}
            ref={formRef}
          >
            {children[activeStep]}

            <ActionsContainer className="wizard__actions">
              <ButtonGroup
                variant={ButtonVariants.contained}
                disabled={disabled || submitting}
              >
                <Button
                  type="submit"
                  disabled={remainingRequiredSteps.length !== 0}
                  onClick={onSubmitClick}
                  testId="confirm-button"
                >
                  {confirmButtonText}
                </Button>
                {additionalActions && (
                  <Dropdown>
                    {additionalActions.map(({ text, onClick }) => (
                      <DropdownItem key={text} onClick={onClick}>
                        {text}
                      </DropdownItem>
                    ))}
                  </Dropdown>
                )}
              </ButtonGroup>

              {secondaryButtonText && onSecondary && (
                <Button
                  variant={ButtonVariants.outlined}
                  disabled={disabled}
                  onClick={onSecondary}
                  testId="secondary-button"
                >
                  {secondaryButtonText}
                </Button>
              )}

              {steps.length > 1 && (
                <Fragment>
                  <Button
                    variant={ButtonVariants.outlined}
                    disabled={disabled || submitting || activeStep === 0}
                    onClick={onPrevButtonClick}
                    testId="prev-button"
                  >
                    {prevButtonText}
                  </Button>
                  <Button
                    variant={ButtonVariants.outlined}
                    disabled={disabled || submitting || activeStep === steps.length - 1}
                    type="submit"
                    onClick={onNextButtonClick}
                    testId="next-button"
                  >
                    {nextButtonText}
                  </Button>
                </Fragment>
              )}

              {cancelButtonText && (
                <Button
                  variant={ButtonVariants.outlined}
                  onClick={onCancelButtonClick}
                  disabled={submitting}
                  testId="cancel-button"
                >
                  {cancelButtonText}
                </Button>
              )}

              {!deleteButtonHidden && deleteButtonText && onDelete && (
                <Button
                  className="button-delete"
                  variant={ButtonVariants.outlined}
                  onClick={onDelete}
                  disabled={deleteButtonDisabled}
                >
                  {deleteButtonText}
                </Button>
              )}
            </ActionsContainer>
          </Form>
        </FormContainer>
      </LeftPanel>

      {(image || title || description) && (
        <RightPanel className="wizard__right-panel">
          {image && <Image className="wizard__image" image={image} />}
          {title && <Title className="wizard__title">{title}</Title>}
          {description && (
            <Description className="wizard__description">
              {description}
            </Description>
          )}
        </RightPanel>
      )}
    </Container>
  );
};

export const commonPropTypes = {
  /**
   * Override or extend the styles applied to the component.
   */
  className: PropTypes.string,
  /**
   * If true, form will have material-ui className.
   */
  material: PropTypes.bool,
  /**
   * If true, form will be displayed in horizontal style.
   */
  horizontal: PropTypes.bool,
  /**
   * The same initialValues object passed to reduxForm to initialize the form data.
   */
  initialValues: PropTypes.shape({}),
  /**
   * Prop to reset initialValues (if prop changed form values will be reset to initialValues).
   */
  // eslint-disable-next-line react/forbid-prop-types
  resetter: PropTypes.any,
  /**
   * Form title
   */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * Form description
   */
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * Form image url
   */
  image: PropTypes.string,
  /**
   * Form fields.
   */
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.func,
    PropTypes.element,
  ]).isRequired,
  /**
   * Additional actions that will be displayed as confirm button dropdown.
   */
  additionalActions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.node,
      onClick: PropTypes.func,
    }),
  ),
  /**
   * Text for the chancel button.
   */
  cancelButtonText: PropTypes.string,
  /**
   * Text for the confirm button.
   */
  confirmButtonText: PropTypes.string,
  /**
   * Text for the secondary button.
   */
  secondaryButtonText: PropTypes.string,
  /**
   * Text for the prev button.
   */
  prevButtonText: PropTypes.string,
  /**
   * Text for the next button.
   */
  nextButtonText: PropTypes.string,
  /**
   * If true, loading overlay will be active.
   */
  loading: PropTypes.bool,
  /**
   * Boolean to control the state confirm button.
   */
  disabled: PropTypes.bool,
  /**
   * If true, form will be reset to initial values when chancel button pressed.
   */
  resetOnChancel: PropTypes.bool,
  /**
   * A callback fired when cancel button clicked.
   */
  onCancel: PropTypes.func.isRequired,
  /**
   * A callback fired when secondary button clicked.
   */
  onSecondary: PropTypes.func,
  /**
   * Text for the delete button.
   */
  deleteButtonText: PropTypes.string,
  /**
   * If true, delete button will be disabled.
   */
  deleteButtonDisabled: PropTypes.bool,
  /**
   * If true, delete button will be hidden.
   */
  deleteButtonHidden: PropTypes.bool,
  /**
   * A callback fired when secondary button clicked.
   */
  onDelete: PropTypes.func,
};

export const commonDefaultProps = {
  className: undefined,
  material: false,
  horizontal: true,
  initialValues: {},
  resetter: null,
  title: undefined,
  description: undefined,
  image: undefined,
  additionalActions: undefined,
  cancelButtonText: 'Cancel',
  confirmButtonText: 'Save',
  secondaryButtonText: '',
  prevButtonText: 'Prev',
  nextButtonText: 'Next',
  loading: false,
  disabled: false,
  resetOnChancel: true,
  onSecondary: null,
  deleteButtonText: null,
  deleteButtonDisabled: false,
  deleteButtonHidden: true,
  onDelete: null,
};

FormBody.propTypes = {
  ...commonPropTypes,
  handleSubmit: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onTabButtonClick: PropTypes.func.isRequired,
  onPrevButtonClick: PropTypes.func.isRequired,
  setNextStep: PropTypes.func.isRequired,
  setIsConfirmButtonClicked: PropTypes.func.isRequired,
  activeStep: PropTypes.number,
};

FormBody.defaultProps = {
  ...commonDefaultProps,
  activeStep: 0,
};

export { ActionsContainer };
export default FormBody;
