import { css } from 'styled-components';

export default ({ theme }) => css`
  input,
  textarea {
    color: ${theme.colorText};
    border: 1px solid ${theme.colorFieldsBorder};
    background: ${theme.colorFieldBackground};

    &:focus,
    &:active,
    &:hover {
      outline: none;
      border-color: ${theme.primary};
    }

    &.invalid {
      border: 1px solid ${theme.colorFieldsBorderInvalid};
      background: ${theme.colorFieldBackgroundInvalid};
    }

    &[readonly] {
      background: transparent;
    }

    &[disabled] {
      border: 0 solid transparent;
      background: transparent;
      padding-left: unset;
      padding-right: unset;
      color: ${theme.colorTextSecondary};
      cursor: text;
      ::placeholder {
        font-size: 13px;
      }
      &[value=''] {
        cursor: default;
      }
      &:focus,
      &:active,
      &:hover {
        border: 0 solid transparent;
      }
    }
  }

  select {
    height: 32px;
    transition: border-color 0.3s;
    color: ${theme.colorText};
    border: 1px solid ${theme.colorFieldsBorder};
    background: ${theme.colorFieldBackground};

    &:focus,
    &:active,
    &:hover {
      outline: none;
      border-color: ${theme.primary};
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    caret-color: ${theme.colorText};
    -webkit-text-fill-color: ${theme.colorText};
    -webkit-box-shadow: 0 0 0 1000px ${theme.colorFieldBackground} inset;
    transition: background-color 5000s ease-in-out 0s;
  }
`;
