import PropTypes from '+prop-types';
import { useCallback } from 'react';

import { ColorTypes } from '@/models/ColorTypes';

import Button, { ButtonVariants } from '+components/Button';
import { MessageItem } from '+components/ConfirmModal';
import { Modal, ModalHeader, ModalBody, ModalFooter } from '+components/Modal';

const ModalResetCategory = (props) => {
  const { name, onConfirm, toggleModal, isOpen, isDisabled, testId } = props;

  const handle = useCallback(
    (e) => {
      e.preventDefault();

      if (onConfirm) {
        onConfirm(name);
      }
    },
    [onConfirm, name],
  );

  return (
    <Modal isOpen={isOpen} onClose={toggleModal} data-testid={testId}>
      <form className="material-form" onSubmit={handle}>
        <ModalHeader onClose={toggleModal}>Reset System Category?</ModalHeader>

        <ModalBody>
          <p>Are you sure you want to reset this category?</p>
          <p>
            <MessageItem>{name}</MessageItem>
          </p>
          <p>
            Resetting will clear the custom description for the category, and
            default back to the system defined description.
          </p>
          <p>This cannot be undone!</p>
        </ModalBody>

        <ModalFooter>
          <Button color={ColorTypes.danger} type="submit" disabled={isDisabled}>
            Confirm
          </Button>

          <Button variant={ButtonVariants.outlined} onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

ModalResetCategory.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
  name: PropTypes.string,
  testId: PropTypes.string,
};

ModalResetCategory.defaultProps = {
  name: null,
  isDisabled: false,
  testId: undefined,
};

export default ModalResetCategory;
