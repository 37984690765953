import PropTypes from '+prop-types';
import { forwardRef, memo } from 'react';
import { Link } from 'react-router-dom';

import styled, { css } from 'styled-components';

import OpenInNewIcon from 'mdi-react/OpenInNewIcon';

import { getRowOriginal } from '+components/Table/Columns/utils';
import TagOrigin from '+components/Tag';
import Tooltip from '+components/Tooltip';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-width: fit-content;
  height: 100%;
  overflow: hidden;
`;

const HeaderRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 5px;
`;

const headMixin = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const SubHeader = styled.span`
  ${headMixin};
  font-size: 80%;
  font-weight: 600;
  color: ${({ theme }) => theme.colorTextSecondary} !important;
  max-height: 40%;
`;

export const Header = styled.span`
  ${headMixin}
`;

const StyledOpenInNewIcon = styled(OpenInNewIcon)`
  height: 14px;
  vertical-align: middle;
  margin-bottom: 3px;
`;

const LinkStyled = styled(Link)`
  ${headMixin}
`;

const Tag = styled(TagOrigin)`
  padding: 0 4px;
  font-size: 8px;
  font-weight: 400;
  min-height: unset;
  line-height: 1em;
  border-radius: 2px !important;
`;

// eslint-disable-next-line react/prop-types
const TooltipWrapper = ({ children, title }) => (!title ? (
  children
) : (
  <Tooltip title={title} arrow={false}>
    {children}
  </Tooltip>
));

// eslint-disable-next-line react/prop-types
const LinkWrapper = forwardRef(({ url, children, external, ...tail }, fRef) => (external ? (
  <a
    {...tail}
    href={url}
    target="_blank"
    rel="noopener noreferrer"
    ref={fRef}
  >
    {children} <StyledOpenInNewIcon />
  </a>
) : (
  <LinkStyled {...tail} to={url} ref={fRef}>
    {children}
  </LinkStyled>
)));

export const HeaderSubheader = memo(
  ({ header, subHeader, headerUrl, isNew, newLabel, urlExternal, tooltip }) => {
    return (
      <Container>
        {!!header && (
          <HeaderRow>
            {isNew && (
              <Tag outlined={false} color="success">
                {newLabel || 'new'}
              </Tag>
            )}
            <TooltipWrapper title={tooltip}>
              {headerUrl ? (
                <LinkWrapper external={urlExternal} url={headerUrl}>
                  {header}
                </LinkWrapper>
              ) : (
                <Header>{header}</Header>
              )}
            </TooltipWrapper>
          </HeaderRow>
        )}
        {!!subHeader && <SubHeader>{subHeader}</SubHeader>}
      </Container>
    );
  },
);

HeaderSubheader.propTypes = {
  header: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.func,
  ]),
  subHeader: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.func,
  ]),
  headerUrl: PropTypes.string,
  isNew: PropTypes.bool,
  newLabel: PropTypes.string,
  urlExternal: PropTypes.bool,
  tooltip: PropTypes.children,
};
HeaderSubheader.defaultProps = {
  header: '',
  subHeader: '',
  headerUrl: '',
  isNew: false,
  newLabel: '',
  urlExternal: false,
  tooltip: null,
};

const getPropValue = (prop, nest) => prop && nest && nest[prop];

/**
 * @param {object} options
 * @param {string} options.propHeader - name of property for Header
 * @param {string} [options.propSubheader] - name of property for Subheader
 * @param {string} [options.propUrl] - name of property for Header Link
 * @param {boolean} [options.urlExternal] - is propUrl external link
 * @param {string} [options.newLabel] - text for new label
 * @param {string} [options.useValue] - use cell value instead of original
 * @param {function(object): boolean} [options.isNewFn=false] - function for testing: is new or not
 * @return {function({row: {original: *}}): JSX.Element}
 */
export const HeaderSubheaderCell = ({
  propHeader,
  propSubheader = '',
  propUrl = '',
  urlExternal = false,
  isNewFn = false,
  newLabel = '',
  useValue,
  tooltip = '',
}) => (props) => {
  // eslint-disable-next-line react/prop-types
  const { value, row } = props;
  const original = getRowOriginal(row);
  return (
    <HeaderSubheader
      header={getPropValue(propHeader, useValue ? value : original)}
      subHeader={getPropValue(propSubheader, useValue ? value : original)}
      headerUrl={getPropValue(propUrl, useValue ? value : original)}
      urlExternal={urlExternal}
      tooltip={typeof tooltip === 'function' ? tooltip(original, value) : tooltip}
      isNew={isNewFn && isNewFn(original, value)}
      newLabel={newLabel}
    />
  );
};
