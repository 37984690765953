import PropTypes from '+prop-types';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import PlusIcon from 'mdi-react/PlusIcon';

import { selectors as customerSelectors } from '@/redux/api/customer';
import { selectors as globalLoadingSelectors } from '@/redux/ui/globalLoading';

import { useCrumbs } from '+components/Breadcrumb';
import usePageTabs, { defaultNewTab } from '+hooks/usePageTabs';
import useUIProperty from '+hooks/useUIProperty';

import {
  sidebarLeftCollapsedWidth,
  sidebarLeftWidth,
} from '../shared/constants';
import AccountSettingsButton from './components/AccountSettingsButton';
import ButtonsGroupContainer from './components/ButtonsGroupContainer';
import EmptyWrapper from './components/EmptyWrapper';
import FullscreenButton from './components/FullscreenButton';
import Link from './components/Link';
import Logo from './components/Logo';
import NewTabButton from './components/NewTabButton';
import PageHeader from './components/PageHeader';
import PageTabContainer from './components/PageTabContainer';
import PageTabsContainer from './components/PageTabsContainer';
import PageTabSeparator from './components/PageTabSeparator';
import PropertiesTrayButton from './components/PropertiesTrayButton';
import Separator from './components/Separator';
import Spinner from './components/Spinner';
import SupportButton from './components/SupportButton';
import TopbarContainer from './components/TopbarContainer';

const Topbar = (props) => {
  const { $separatorWidth, collapse, ...tail } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const [crumbs = []] = useCrumbs();
  const [hideNav] = useUIProperty('hideNav');
  const [propertiesTray, setPropertiesTray] = useUIProperty(
    'propertiesTray',
    null,
  );

  const customer = useSelector(customerSelectors.getCurrentCustomer);
  const isLoading = useSelector(globalLoadingSelectors.isActive);

  const [pageTabs, activePageTab, pageTabMethods] = usePageTabs();
  const pinnedPageTabs = useMemo(
    () => pageTabs.filter((item) => item.pinned),
    [pageTabs],
  );
  const unpinnedPageTabs = useMemo(
    () => pageTabs.filter((item) => !item.pinned),
    [pageTabs],
  );

  const onTrayButtonClick = useCallback(
    () => {
      setPropertiesTray((prevValue) => ({
        ...prevValue,
        isOpen: !prevValue?.isOpen,
      }));
    },
    [],
  );

  const onPageTabClick = useCallback(
    (index) => () => pageTabMethods.changeActiveIndex(index),
    [],
  );

  const onPageTabAdd = useCallback(
    () => pageTabMethods.add(defaultNewTab, { force: true, toTheEnd: true }),
    [],
  );

  const onPageTabClose = useCallback(
    (index) => (e) => {
      // stop onClick event propagation
      e.stopPropagation();
      pageTabMethods.remove(index);
    },
    [],
  );

  const onPageTabCloseOthers = useCallback(
    (index) => () => pageTabMethods.removeOthers(index),
    [],
  );

  const onPageTabCloseToLeft = useCallback(
    (index) => () => pageTabMethods.removeToLeft(index),
    [],
  );

  const onPageTabCloseToRight = useCallback(
    (index) => () => pageTabMethods.removeToRight(index),
    [],
  );

  // const onPageTabCloseAll = useCallback(
  //   () => pageTabMethods.removeAll(),
  //   [],
  // );

  const onPageTabMoveLeft = useCallback(
    (index) => () => pageTabMethods.move(index, index - 1),
    [],
  );

  const onPageTabMoveRight = useCallback(
    (index) => () => pageTabMethods.move(index, index + 1),
    [],
  );

  const onPageTabMove = useCallback(
    (dragIndex, hoverIndex) => pageTabMethods.move(dragIndex, hoverIndex),
    [],
  );

  const onPageTabCopy = useCallback(
    (index) => () => pageTabMethods.copy(index),
    [],
  );

  const onPageTabPin = useCallback(
    (index) => (e) => {
      // stop onClick event propagation
      e.stopPropagation();
      pageTabMethods.pin(index);
    },
    [],
  );

  const onPageTabUnpin = useCallback(
    (index) => (e) => {
      // stop onClick event propagation
      e.stopPropagation();
      pageTabMethods.unpin(index);
    },
    [],
  );

  useEffect(
    () => {
      if (!customer?.shortname) {
        return;
      }

      if (pageTabs.length) {
        return;
      }

      // add current page data as a tab if no tabs found
      pageTabMethods.add({
        pathname: location.pathname,
        search: location.search,
      });
    },
    [
      customer?.shortname,
      pageTabs.length,
      location.pathname,
      location.search,
    ],
  );

  useEffect(
    () => {
      if (!customer?.shortname) {
        return;
      }

      if (!pageTabs.length) {
        return;
      }

      if (location.key !== 'default') {
        return;
      }

      // add current page data as a tab if it's very first portal load
      pageTabMethods.add({
        pathname: location.pathname,
        search: location.search,
      });
    },
    [
      customer?.shortname,
      pageTabs.length,
      location.key,
      location.pathname,
      location.search,
    ],
  );

  useEffect(
    () => {
      if (!customer?.shortname) {
        return;
      }

      // check for active tab and if not found - set last tab as active
      if (activePageTab) {
        return;
      }

      // find last active tab
      const pageTabsSorted = [...pageTabs].sort(
        (a, b) => (b.lastActive || 0) - (a.lastActive || 0),
      );
      const lastActiveTab = pageTabsSorted[0];

      pageTabMethods.changeActiveIndex(pageTabs.indexOf(lastActiveTab));
    },
    [customer?.shortname, pageTabs, activePageTab],
  );

  const lastNavigatePageTab = useRef(null);
  const locationRef = useRef(location);
  locationRef.current = location;
  useEffect(
    () => {
      if (!customer?.shortname) {
        return;
      }

      if (!activePageTab?.pathname) {
        return;
      }

      // we need it not to override the history
      // skip navigate if we are already on the same page and same tab
      const skipNavigate = locationRef.current.pathname === activePageTab.pathname
      && (locationRef.current.search || '') === (activePageTab.search || '')
      && lastNavigatePageTab.current?.id === activePageTab.id;
      if (skipNavigate) {
        return;
      }

      lastNavigatePageTab.current = activePageTab;

      navigate({
        pathname: activePageTab.pathname,
        search: activePageTab.search,
      });
    },
    [
      customer?.shortname,
      activePageTab?.id, // we need to update location on id change
      activePageTab?.pathname,
      activePageTab?.search,
    ],
  );

  useEffect(
    () => {
      if (!customer?.shortname) {
        return;
      }

      if (location.key === 'default') {
        return;
      }

      pageTabMethods.updateActive({
        pathname: location.pathname,
        search: location.search,
      });
    },
    [customer?.shortname, location.key, location.pathname, location.search],
  );

  const lastCrumb = crumbs[crumbs.length - 1];
  useEffect(
    () => {
      if (!customer?.shortname) {
        return;
      }

      if (lastCrumb?.key === 'default') {
        return;
      }

      pageTabMethods.updateActive({
        title: lastCrumb?.title,
      });
    },
    [customer?.shortname, lastCrumb?.key, lastCrumb?.title],
  );

  const LogoWrapper = hideNav ? EmptyWrapper : Link;
  const logoWrapperProps = hideNav ? {} : { to: '/' };

  return (
    <TopbarContainer {...tail} $separatorWidth={$separatorWidth}>
      <LogoWrapper {...logoWrapperProps}>
        <Logo
          $collapsed={collapse}
          $width={collapse ? sidebarLeftCollapsedWidth : sidebarLeftWidth}
          data-tracking="neto-logo"
          data-testid="logo"
        />
      </LogoWrapper>

      <Separator $width={$separatorWidth} />
      <PageTabsContainer>
        {pageTabs.map((item, index) => {
          const isClickAvailable = !item.active && pageTabs.length > 1;
          const isCloseAvailable = pageTabs.length > 1;
          const isCloseOthersAvailable = !item.pinned && unpinnedPageTabs.length > 1;
          // const isCloseAllAvailable = unpinnedPageTabs.length > 1;
          const isCloseToLeftAvailable = !item.pinned && index > pinnedPageTabs.length;
          const isCloseToRightAvailable = !item.pinned && index < pageTabs.length - 1;
          const isMoveLeftAvailable = item.pinned
            ? index > 0
            : index > pinnedPageTabs.length;
          const isMoveRightAvailable = item.pinned
            ? index < pinnedPageTabs.length - 1
            : index < pageTabs.length - 1;
          const isMoveAvailable = item.pinned
            ? pinnedPageTabs.length > 1
            : unpinnedPageTabs.length > 1;
          return (
            <PageTabContainer key={item.id}>
              <PageHeader
                id={item.id}
                index={index}
                title={item.title}
                active={item.active}
                pinned={item.pinned}
                created={item.created}
                onClick={isClickAvailable ? onPageTabClick(index) : null}
                onClose={isCloseAvailable ? onPageTabClose(index) : null}
                onCloseOthers={isCloseOthersAvailable ? onPageTabCloseOthers(index) : null}
                // onCloseAll={isCloseAllAvailable ? onPageTabCloseAll : null}
                onCloseToLeft={isCloseToLeftAvailable ? onPageTabCloseToLeft(index) : null}
                onCloseToRight={isCloseToRightAvailable ? onPageTabCloseToRight(index) : null}
                onMoveLeft={isMoveLeftAvailable ? onPageTabMoveLeft(index) : null}
                onMoveRight={isMoveRightAvailable ? onPageTabMoveRight(index) : null}
                onMove={isMoveAvailable ? onPageTabMove : null}
                onCopy={onPageTabCopy(index)}
                onPin={!item.pinned ? onPageTabPin(index) : null}
                onUnpin={item.pinned ? onPageTabUnpin(index) : null}
              />
              <PageTabSeparator
                $width={$separatorWidth}
                $nearActive={pageTabs[index]?.active || pageTabs[index + 1]?.active}
              />
            </PageTabContainer>
          );
        })}

        {!hideNav && (
          <NewTabButton size="small" title="New Tab" onClick={onPageTabAdd}>
            <PlusIcon size={16} />
          </NewTabButton>
        )}
      </PageTabsContainer>

      <div style={{ marginLeft: 'auto' }} />
      <Spinner $active={isLoading} testId="spinner" />

      <Separator $width={$separatorWidth} />
      <ButtonsGroupContainer>
        {!hideNav && <AccountSettingsButton />}
        <SupportButton />
        <FullscreenButton />
        {!hideNav && (
          <PropertiesTrayButton
            isOpen={!!propertiesTray?.isOpen}
            onClick={onTrayButtonClick}
          />
        )}
      </ButtonsGroupContainer>
    </TopbarContainer>
  );
};

Topbar.propTypes = {
  $separatorWidth: PropTypes.number.isRequired,
  collapse: PropTypes.bool.isRequired,
};

export default Topbar;
