import { call, put, select } from 'redux-saga/effects';

import RoleModel from '@/models/Role';

import {
  actions as profileActions,
  selectors as profileSelectors,
} from '@/redux/api/user/profile';
import { actions as toastActions } from '@/redux/toast';
import {
  createSlice,
  createSelector,
  startFetching,
  stopFetching,
  defaultReducers,
  takeLeading,
} from '@/redux/util';

import backendClient from '@/middleware/backendClient';

export const initialState = {
  isFetching: false,
  error: '',
  roles: null, // {},
  permissions: null, // {},
  createdId: null,
};

const apiPath = '/roles';

let api;

const initApi = () => {
  if (!api) {
    api = backendClient();
  }
};

const slice = createSlice({
  name: 'roles',
  initialState,

  reducers: {
    ...defaultReducers,
    fetchRoles: startFetching,
    fetchRolesSuccess(state, { payload: data }) {
      stopFetching(state);
      state.roles = (data || []).reduce((acc, item) => {
        acc[item.id] = RoleModel.createModel(item);
        return acc;
      }, {});
    },

    fetchRole: startFetching,
    fetchRoleSuccess(state, { payload: data }) {
      stopFetching(state);
      if (!state.roles) {
        state.roles = {};
      }
      state.roles[data.id] = RoleModel.createModel(data);
    },

    createRole: startFetching,
    createRoleSuccess(state, { payload: data }) {
      stopFetching(state);
      if (!state.roles) {
        state.roles = {};
      }
      state.roles[data.id] = RoleModel.createModel(data);
      state.createdId = data.id;
    },
    clearCreatedId(state) {
      state.createdId = null;
    },

    updateRole: startFetching,
    updateRoleSuccess(state, { payload: data }) {
      stopFetching(state);
      if (!state.roles) {
        state.roles = {};
      }
      state.roles[data.id] = RoleModel.createModel(data);
    },

    removeRole: startFetching,
    removeRoleSuccess(state, { payload: id }) {
      stopFetching(state);
      delete state.roles?.[id];
    },

    fetchRolePermissions: startFetching,
    fetchRolePermissionsSuccess(state, { payload: { id, data } }) {
      stopFetching(state);
      if (!state.permissions) {
        state.permissions = {};
      }
      state.permissions[id] = data;
    },

    skip: stopFetching,
  },

  sagas: (actions) => ({
    [actions.fetchRoles]: {
      taker: takeLeading(actions.skip),
      * saga() {
        initApi();

        try {
          const response = yield call(api.get, apiPath);
          yield put(actions.fetchRolesSuccess(response.data.data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching roles',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.fetchRole]: {
      * saga({ payload: id }) {
        initApi();

        try {
          const response = yield call(api.get, `${apiPath}/${id}`);
          yield put(actions.fetchRoleSuccess(response.data.data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching role',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.createRole]: {
      * saga({ payload: role }) {
        initApi();

        try {
          const response = yield call(api.post, apiPath, role);
          yield put(actions.createRoleSuccess(response.data.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Role has been created',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error creating role',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.updateRole]: {
      * saga({ payload: role }) {
        initApi();

        try {
          const response = yield call(api.put, `${apiPath}/${role.id}`, role);
          yield put(actions.updateRoleSuccess(response.data.data));
          const profile = yield select(profileSelectors.getProfile);
          if (profile?.roles?.[0] === role.id) {
            yield put(profileActions.clearPermissions());
          }
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Role has been updated',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error updating role',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.removeRole]: {
      * saga({ payload: id }) {
        initApi();

        try {
          const response = yield call(api.delete, `${apiPath}/${id}`);
          yield put(actions.removeRoleSuccess(id));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Role has been deleted',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error deleting role',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.fetchRolePermissions]: {
      * saga({ payload: id }) {
        initApi();

        try {
          const response = yield call(api.get, `${apiPath}/${id}/permissions`);
          yield put(
            actions.fetchRolePermissionsSuccess({
              id,
              data: response.data.data,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching role permissions',
              details: error.message,
            }),
          );
        }
      },
    },
  }),

  selectors: (getState) => ({
    isFetching: createSelector(
      [getState],
      (state) => state.isFetching,
    ),

    getError: createSelector(
      [getState],
      (state) => state.error,
    ),

    getRoles: createSelector(
      [getState],
      (state) => state.roles,
    ),

    getRole: (id) => createSelector(
      [getState],
      (state) => state.roles?.[id],
    ),

    getRolePermissions: (id) => createSelector(
      [getState],
      (state) => state.permissions?.[id],
    ),

    getCreatedId: createSelector(
      [getState],
      (state) => state.createdId,
    ),
  }),
});

export const { actions, selectors } = slice;

export default slice;
