import { TimePeriods } from '@/models/TimePeriods';

export const timeBounds = ({ period, from, to, realtime = true }) => {
  const timePeriod = TimePeriods[period?.type];
  const rate = Math.max(1, period?.value || 1);

  const start = timePeriod ? timePeriod.query * rate : Math.trunc(from);
  const end = timePeriod && realtime ? 0 : Math.trunc(to);
  const interval = timePeriod ? timePeriod.interval : undefined;

  return {
    start,
    end,
    interval,
    timePeriod,
  };
};
