import HelpCircleOutlineIcon from 'mdi-react/HelpCircleOutlineIcon';

import TopbarButton from '+components/TopbarButton';

const SupportButton = () => (
  <TopbarButton
    type="button"
    title="Support"
    id="zdLauncher"
    // onClick controlled by Pendo in environments >= develop
    data-tracking="support-button"
  >
    <HelpCircleOutlineIcon size={20} />
  </TopbarButton>
);

export default SupportButton;
