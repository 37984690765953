import { useMemo, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ContextTypes } from '@/models/ContextTypes';
import StatsRequest from '@/models/StatsRequest';
import { TimeDuration } from '@/models/TimePeriods';

import {
  actions as auditLogsActions,
  selectors as auditLogsSelectors,
} from '@/redux/api/stats/auditLogs';
import {
  actions as blocksActions,
  selectors as blocksSelectors,
} from '@/redux/api/stats/blocks';
import {
  actions as dnsActions,
  selectors as dnsSelectors,
} from '@/redux/api/stats/dns';
import {
  actions as eventsActions,
  selectors as eventsSelectors,
} from '@/redux/api/stats/events';
import {
  actions as flowActions,
  selectors as flowSelectors,
} from '@/redux/api/stats/flow';
import {
  actions as ipActions,
  selectors as ipSelectors,
} from '@/redux/api/stats/ip';

import usePollingHeartbeat from '+hooks/usePollingHeartbeat';
import useUIProperty from '+hooks/useUIProperty';

/**
 * Hook for REST API stats requests with polling heartbeat.
 *
 * @param {Object} params
 * @param {ContextTypes} params.context - context name.
 * @param {StatsRequest.Types} params.requestType - request type.
 * @param {Object} params.request - stats request.
 * @param {number} params.refresher - send new value here to for an extra call.
 * @param {boolean} params.stopRequest - if true, all requests will be stopped.
 * @param {boolean} [params.stopPollingHeartbeat] - if true, polling heartbeat will be stopped.
 * @param {function} [params.onRequest] - a callback fired when request called.
 * @param {function} [params.onCancel] - a callback fired when request canceled.
 * @param {boolean} [params.clearIfRequestChanged=true] - if true, all data will be cleared if request changed.
 *
 * @return {{series: Object, isFetching: boolean, pollingHeartbeat: number}}
 */
export const useStatsRequest = (params) => {
  const {
    context,
    requestType,
    request,
    refresher,
    stopRequest,
    stopPollingHeartbeat,
    onRequest,
    onCancel,
    clearIfRequestChanged = true,
  } = params;

  const dispatch = useDispatch();

  const [windowFocused] = useUIProperty('windowFocused', true);

  const [contextSelectors, contextActions] = useMemo(
    () => {
      switch (context) {
        case ContextTypes.alerts:
          return [eventsSelectors, eventsActions];

        case ContextTypes.audit:
          return [auditLogsSelectors, auditLogsActions];

        case ContextTypes.blocks:
          return [blocksSelectors, blocksActions];

        case ContextTypes.ip:
          return [ipSelectors, ipActions];

        case ContextTypes.dns:
          return [dnsSelectors, dnsActions];

        case ContextTypes.flow:
        default:
          return [flowSelectors, flowActions];
      }
    },
    [context],
  );

  const requestAction = useMemo(
    () => {
      switch (requestType) {
        case StatsRequest.Types.agg:
          return 'aggregateRequest';

        case StatsRequest.Types.heatmap:
          return 'heatmapRequest';

        case StatsRequest.Types.sankey:
          return 'sankeyRequest';

        case StatsRequest.Types.ts:
        default:
          return 'timeseriesRequest';
      }
    },
    [requestType],
  );

  const isFetching = useSelector(contextSelectors.isFetching);
  const isFetchingSeries = useSelector(
    contextSelectors.isFetchingSeries(request.seriesId),
  );
  const errorSeries = useSelector(
    contextSelectors.errorSeries(request.seriesId),
  );
  const series = useSelector(contextSelectors.seriesSelector(request.seriesId));

  const pollingHeartbeat = usePollingHeartbeat(
    series,
    requestType,
    // stop polling heartbeat if series is fetching or polling heartbeat is stopped
    stopPollingHeartbeat || isFetchingSeries,
    // min refresh interval is 5 minutes if there is an error or 1 minute otherwise
    errorSeries ? 5 * TimeDuration.minute : TimeDuration.minute,
  );

  const lastPollingHeartbeat = useRef(null);

  useEffect(
    () => {
    // clear polling heartbeat if request changed
      lastPollingHeartbeat.current = null;
    },
    [JSON.stringify(request), refresher],
  );

  useEffect(
    () => {
      const skip = stopRequest
      || !windowFocused
      || lastPollingHeartbeat.current === pollingHeartbeat;
      if (skip) {
        return undefined;
      }

      lastPollingHeartbeat.current = pollingHeartbeat;
      dispatch(contextActions[requestAction](request, request.seriesId));
      onRequest?.();

      return () => {
        dispatch(contextActions.cancel(request.seriesId));
        onCancel?.();
      };
    },
    [
      stopRequest,
      windowFocused,
      refresher,
      pollingHeartbeat,
      JSON.stringify(request),
      contextActions,
      onRequest,
      onCancel,
    ],
  );

  const lastState = useRef([request.seriesId, contextActions, request]);
  useEffect(
    () => () => {
      const [lastId, lastActions, lastRequest] = lastState.current || [];
      const isAllowed = lastId !== request.seriesId
        || lastActions !== contextActions
        || (clearIfRequestChanged && lastRequest !== request);

      lastState.current = [request.seriesId, contextActions, request];

      // WARNING: it will prevent clearing data even when a component is unmounted.
      if (isAllowed) {
        dispatch(contextActions.seriesClear({ seriesId: request.seriesId }));
      }
    },
    [JSON.stringify(request), contextActions],
  );

  return { series, isFetching, pollingHeartbeat };
};

export default useStatsRequest;
