import { Link as LinkOrigin } from 'react-router-dom';

import styled from 'styled-components';

const Link = styled(LinkOrigin)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default Link;
