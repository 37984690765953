import styled from 'styled-components';

import Item from './Item';

export default styled(Item)`
  padding-right: 10px;
  .topbar__link-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
`;
