/* eslint-disable max-len */
import PropTypes from '+prop-types';

const AwsRoute53Icon = ({ size }) => (
  <svg width={size} height={size} viewBox="0 0 256 310">
    <g>
      <path
        d="M73.871,282.05 L127.968,309.101 L133.252,305.159 L131.028,3.598 L127.968,0 L73.871,27.051 L73.871,282.05"
        fill="#9D5025"
      />
      <path
        d="M182.065,282.05 L127.968,309.101 L127.968,0 L182.065,27.051 L182.065,282.05"
        fill="#F68536"
      />
      <path
        d="M107.571,112.513 L88.587,115.277 L0,105.296 L0.375,104.313 L14.75,100 L16.279,100.866 L16.625,100 L107,110.563 L107.571,112.513"
        fill="#6B3A19"
      />
      <path
        d="M0,63.988 L16.279,55.844 L16.279,100.866 L0,105.296 L0,63.988"
        fill="#9D5025"
      />
      <path
        d="M256,131.063 L165.339,135.932 L148.364,134.768 L151.562,133.438 L239.937,128.375 L256,131.063"
        fill="#6B3A19"
      />
      <path
        d="M107.571,112.513 L16.279,100.866 L16.279,55.844 L107.571,77.258 L107.571,112.513"
        fill="#F68536"
      />
      <path
        d="M239.656,82.225 L148.364,97.916 L148.364,134.768 L239.656,129.29 L240.187,128.25 L240,83.063 L239.656,82.225"
        fill="#9D5025"
      />
      <path
        d="M256,131.063 L239.656,128.938 L239.656,82.225 L256,88.21 L256,131.063"
        fill="#F68536"
      />
    </g>
  </svg>
);

AwsRoute53Icon.propTypes = {
  size: PropTypes.number,
};

AwsRoute53Icon.defaultProps = {
  size: 24,
};

export { AwsRoute53Icon };
