import PropTypes from '+prop-types';
import { useMemo, useCallback, useState, useEffect } from 'react';

import classNames from 'classnames';
import styled from 'styled-components';

import { useMenuActions, useMenuState } from '+components/Menu';

const span = (value) => (['string', 'number'].includes(typeof value) ? <span>{value}</span> : value);

const Div = styled.div`
  color: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  ${(props) => props.$style}
`;

const Button = styled.button`
  border: none;
  background-color: inherit;
  cursor: pointer;
  display: inline-flex;
  margin: 0;
  padding: 0;

  color: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  ${(props) => props.$style}

  &:disabled {
    cursor: default;
  }

  .mdi-icon {
    vertical-align: middle;
  }
`;

const Trigger = (props) => {
  const { className, style, text, children, disabled, dataTracking, testId } = props;

  const { show } = useMenuState();
  const { showMenu, updateMenuProps } = useMenuActions();
  const [active, setActive] = useState(show);

  const Container = useMemo(
    () => (disabled ? Div : Button),
    [disabled],
  );

  const onClick = useCallback(
    (e) => {
      setActive(true);
      showMenu(e, { children });
    },
    [showMenu, children],
  );

  useEffect(
    () => {
      if (active) {
        updateMenuProps({ children });
      }
    },
    [updateMenuProps, children, active],
  );

  useEffect(
    () => {
      if (!show) {
        setActive(false);
      }
    },
    [show],
  );

  return (
    <Container
      $style={style}
      className={classNames(className, { active })}
      type={disabled ? undefined : 'button'}
      onClick={disabled ? undefined : onClick}
      data-tracking={dataTracking}
      data-testid={testId}
    >
      {span(text)}
    </Container>
  );
};

Trigger.propTypes = {
  children: PropTypes.children,
  className: PropTypes.string,
  style: PropTypes.shape(),
  text: PropTypes.children,
  disabled: PropTypes.bool,
  dataTracking: PropTypes.string,
  testId: PropTypes.string,
};

Trigger.defaultProps = {
  children: null,
  className: null,
  style: null,
  text: null,
  disabled: false,
  dataTracking: 'undefined-tracking',
  testId: null,
};

export default Trigger;
