import styled from 'styled-components';

const Tabs = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 200px;
  min-width: 200px;
  overflow: hidden;
  flex: 1 1 auto;
  border-radius: 4px;
  padding: 5px;
  gap: 13px;

  background: ${({ theme }) => theme.settingsTabsContainerBackground};
`;

export default Tabs;
