/* eslint-disable max-len */
import PropTypes from '+prop-types';
import { memo } from 'react';

const DeviceIcon = memo(({ size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 18 14.183"
  >
    <defs>
      <clipPath id="a">
        <path data-name="Rectangle 2321" fill="none" d="M0 0h18v14.183H0z" />
      </clipPath>
    </defs>
    <g data-name="Group 1089">
      <g data-name="Group 1086">
        <g data-name="Group 1085" clipPath="url(#a)">
          <path
            data-name="Path 744"
            d="M.472 5.186.461 8.949c0-1.258.829-2.515 2.478-3.473a13.324 13.324 0 0 1 12.073 0c1.681.97 2.521 2.247 2.517 3.522l.01-3.764c0-1.275-.836-2.551-2.517-3.522a13.324 13.324 0 0 0-12.073 0C1.3 2.67.475 3.927.471 5.185"
            fill="#fff"
          />
          <path
            data-name="Path 745"
            d="M15.022 1.713a13.324 13.324 0 0 0-12.073 0c-3.319 1.929-3.3 5.066.038 6.994a13.325 13.325 0 0 0 12.076 0c3.317-1.928 3.3-5.065-.041-6.994"
            fill="#16171a"
          />
          <path
            data-name="Path 746"
            d="M15.347 1.525a14.04 14.04 0 0 0-12.725 0C-.875 3.557-.858 6.864 2.665 8.9a14.047 14.047 0 0 0 12.728 0c3.5-2.032 3.477-5.339-.043-7.371M2.987 8.707C-.352 6.779-.369 3.642 2.949 1.713a13.325 13.325 0 0 1 12.073 0c3.34 1.929 3.358 5.066.041 6.994a13.325 13.325 0 0 1-12.076 0"
            fill="#dfdfdf"
          />
        </g>
      </g>
      <path
        data-name="Path 747"
        d="m4.124 2.317.654 1.431.452-.261.91-.525.452-.261Z"
        fill="#dfdfdf"
      />
      <path
        data-name="Path 748"
        d="m11.074 3.429.9.525 2.382-1.375-.9-.525Z"
        fill="#dfdfdf"
      />
      <path
        data-name="Path 749"
        d="m5.573 6.085.449.261.9.525.449.261.67-1.43Z"
        fill="#dfdfdf"
      />
      <path
        data-name="Path 750"
        d="m9.494 5.965 2.367 1.375.91-.525-2.367-1.376Z"
        fill="#dfdfdf"
      />
      <path
        data-name="Path 751"
        d="m11.409 7.601 2.468.384-.654-1.431-.452.261-.91.525Z"
        fill="#dfdfdf"
      />
      <path
        data-name="Path 752"
        d="m3.64 7.722.9.525 2.383-1.376-.9-.525Z"
        fill="#dfdfdf"
      />
      <path
        data-name="Path 753"
        d="m10.625 3.169-.669 1.43 2.471-.383-.449-.261-.9-.525Z"
        fill="#dfdfdf"
      />
      <path
        data-name="Path 754"
        d="m5.23 3.487 2.367 1.375.91-.525L6.14 2.962Z"
        fill="#dfdfdf"
      />
      <g data-name="Group 1088">
        <g data-name="Group 1087" clipPath="url(#a)">
          <path
            data-name="Path 755"
            d="M.011 5.185 0 8.948c0 1.343.881 2.688 2.651 3.711a14.047 14.047 0 0 0 12.728 0c1.737-1.01 2.606-2.333 2.61-3.659L18 5.236c0 1.326-.873 2.65-2.61 3.659a14.046 14.046 0 0 1-12.728 0C.891 7.873.007 6.528.011 5.185"
            fill="#a2a2a2"
          />
        </g>
      </g>
    </g>
  </svg>
));

DeviceIcon.propTypes = {
  size: PropTypes.number,
};

DeviceIcon.defaultProps = {
  size: 24,
};

export { DeviceIcon };
