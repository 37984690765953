import { useEffect } from 'react';

import { useFlags } from '@unleash/proxy-client-react';

import authClient from '@/middleware/authClient';

import featureOverridesCookie from '+utils/featureOverridesCookie';
import loader from '+utils/loader';

const features = new Set(['KCv23', 'DNS2']);

export const useFeatureOverrides = () => {
  const flags = useFlags();

  const packed = flags
    .filter((flag) => flag.variant.name === 'inHeader')
    .map((flag) => flag.name)
    .join(',');

  useEffect(
    () => {
      (async () => {
        const unpacked = packed.split(',');

        const exists = featureOverridesCookie.get();

        const toRemove = new Set([...exists]);

        const toAdd = new Set();

        unpacked.forEach((flag) => {
          if (toRemove.has(flag)) {
            toRemove.delete(flag);
            return;
          }

          if (features.has(flag)) {
            toAdd.add(flag);
          }
        });

        if (toRemove.size < 1 && toAdd.size < 1) {
          return;
        }

        loader
          .setMessage('Implementing features... (this may take a few seconds)')
          .show();

        featureOverridesCookie.remove(...toRemove);
        featureOverridesCookie.add(...toAdd);

        if (toAdd.has('KCv23') || toRemove.has('KCv23')) {
          await authClient.getClient().revokeTokens();
          Object.keys(localStorage).forEach((key) => {
            if (key.startsWith('oidc.') || key.startsWith('oidc:')) {
              localStorage.removeItem(key);
            }
          });
          Object.keys(sessionStorage).forEach((key) => {
            if (key.startsWith('oidc:')) {
              sessionStorage.removeItem(key);
            }
          });
        }

        setTimeout(() => {
          window.location.reload();
        }, 10);
      })();
    },
    [packed],
  );
};
