import styled from 'styled-components';

export default styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: ${(props) => (props.$bold ? 'bold' : 'normal')};
  height: 100%;
  width: 100%;
`;
