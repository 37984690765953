export const FilterOperator = {
  like: 'like',
  equal: '==',
  lessThen: '<',
  lessThenOrEqual: '<=',
  greaterThen: '>',
  greaterThenOrEqual: '>=',
};

export const createFilterValue = ({ value, operator }) => ({
  value,
  operator,
  toString() {
    return this.value;
  },
});
