import { VpcIcon } from '@/shared/img/icon';

export const SOURCETYPES = [
  {
    icon: <VpcIcon flowtype="device" size={18} />,
    label: 'Device',
  },
  {
    icon: <VpcIcon flowtype="aws" flowresource="kinesis" size={18} />,
    label: 'AWS Kinesis',
    flowtype: 'aws',
    flowresource: 'kinesis',
  },
  {
    icon: <VpcIcon flowtype="aws" flowresource="s3" size={18} />,
    label: 'AWS S3',
    flowtype: 'aws',
    flowresource: 's3',
  },
  {
    icon: <VpcIcon flowtype="azure" flowresource="blobstorage" size={18} />,
    label: 'Azure NSG',
    flowtype: 'azure',
    flowresource: 'blobstorage',
  },
  {
    icon: <VpcIcon flowtype="gcp" flowresource="pubsub" size={18} />,
    label: 'GCP',
    flowtype: 'gcp',
    flowresource: 'pubsub',
  },
  {
    icon: <VpcIcon flowtype="ibm" flowresource="objectstorage" size={18} />,
    label: 'IBM COS',
    flowtype: 'ibm',
    flowresource: 'objectstorage',
  },
  {
    icon: <VpcIcon flowtype="oracle" flowresource="objectstorage" size={18} />,
    label: 'Oracle COS',
    flowtype: 'oracle',
    flowresource: 'objectstorage',
  },
];
