/* eslint-disable react/prop-types */
import { geoValueToTextFormatter } from '+components/Table/Cells/formatters';

import CellWrapper from './CellWrapper';

const CellGeo = ({ field, value, original, hideUnderline }) => {
  const text = geoValueToTextFormatter(value);
  return (
    <CellWrapper
      customer={original?.customer}
      title={`${field} — ${text}`}
      field={field}
      value={value}
      hideUnderline={hideUnderline}
    >
      <span>{text}</span>
    </CellWrapper>
  );
};

export default CellGeo;
