import { useCallback } from 'react';
import { ensurePluginOrder } from 'react-table';

import TechnicalColumns from '+components/Table/ReactTable/TechnicalColumns';

const pluginName = 'useSortByColumnMethodsExtender';

const useInstance = (instance) => {
  const {
    flatHeaders,
    toggleSortBy,
    setSortBy,
    plugins,
    state: { groupBy, sortBy },
  } = instance;

  ensurePluginOrder(plugins, ['useSortBy'], pluginName);

  const toggleSortByExt = useCallback(
    (columnId, desc, multi) => {
      if (!groupBy?.length) {
        // Default behavior
        toggleSortBy(columnId, desc, multi);
        return;
      }

      const groupByExtended = [...groupBy, TechnicalColumns.totalColumn];
      // Split grouped and non-grouped columns sorting
      if (groupByExtended.includes(columnId)) {
        // If the column being toggled is in a groupBy column, just change the desc value
        const found = sortBy.find((item) => item.id === columnId);
        if (!found) {
          setSortBy([...sortBy, { id: columnId, desc }]);
          return;
        }
        setSortBy([
          ...sortBy.filter((item) => item.id !== columnId),
          { id: columnId, desc: found.desc ? undefined : true },
        ]);
        return;
      }

      const nextSortBy = multi
        ? sortBy
        : sortBy.filter(
          (item) => item.id === columnId || groupByExtended.includes(item.id),
        );
      const found = nextSortBy.find((item) => item.id === columnId);

      if (!found) {
        // If the column being toggled is not in the nextSortBy array, add it (first click on a column)
        setSortBy([...nextSortBy, { id: columnId, desc }]);
        return;
      }

      if (nextSortBy.length !== sortBy.length) {
        // In sortBy there were other non-groupBy columns
        // Set the column being toggled desc to false
        setSortBy([
          ...nextSortBy.filter((item) => item.id !== columnId),
          { id: columnId, desc: undefined },
        ]);
        return;
      }

      if (!found.desc) {
        // If the column being toggled is in the nextSortBy array and is not descending, set it to descending (second click on a column)
        setSortBy([
          ...nextSortBy.filter((item) => item.id !== columnId),
          { id: columnId, desc: true },
        ]);
        return;
      }

      // If the column being toggled is in the nextSortBy array and is descending, remove it (third click)
      setSortBy(nextSortBy.filter((item) => item.id !== columnId));
    },
    [toggleSortBy, groupBy, sortBy, setSortBy],
  );

  flatHeaders.forEach((column) => {
    if (column.canSort) {
      column.toggleSortBy = (desc, multi) => toggleSortByExt(column.id, desc, multi);
    }
  });
};

export const useSortByColumnMethodsExtender = (hooks) => {
  hooks.useInstance.push(useInstance);
};

useSortByColumnMethodsExtender.pluginName = pluginName;
