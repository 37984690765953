import NetSocket, { CLOSE_CODES } from '+utils/NetSocket';

let socket;

const websocketClient = () => {
  if (!socket) {
    socket = new NetSocket();
  }
  return socket;
};

export { CLOSE_CODES };

export default websocketClient;
