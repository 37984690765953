import { Fragment, useEffect, useMemo } from 'react';

import omit from 'lodash.omit';
import styled from 'styled-components';

import { LegendPositions } from '+components/charts/common/legend';
import { Field, useFormState, useForm } from '+components/form/FinalForm';
import { normalizeSelectValue } from '+components/form/Normalizers';
import SelectField from '+components/form/SelectField';
import { ToggleField } from '+components/form/Toggle';
import { validateRequired } from '+components/form/Validators';

import { widgets } from '../../shared/widgets';
import FieldsRowOrigin from '../components/FieldsRow';

const FieldsRow = styled(FieldsRowOrigin)`
  .form__form-group {
    width: unset;
  }
`;

const legendPositionOptions = Object.values(LegendPositions).map((item) => ({
  value: item,
  label: item,
}));

const WidgetDisplayFields = () => {
  const form = useForm();
  const { values: formValues } = useFormState({
    subscription: { values: true },
  });

  const series0 = formValues?.series?.[0];

  const widgetMeta = useMemo(
    () => widgets[series0?.display?.type] || {},
    [series0?.display?.type],
  );

  const formSettings = useMemo(
    () => ({
      isLegendEnabled: widgetMeta.isLegendEnabled,
      isMetricEnabled: widgetMeta.isMetricEnabled,
      isLegendPositionEnabled:
        formValues.settings?.showLegend && widgetMeta.isLegendPositionEnabled,
    }),
    [
      widgetMeta.isLegendEnabled,
      widgetMeta.isLegendPositionEnabled,
      widgetMeta.isMetricEnabled,
      formValues.settings?.showLegend,
    ],
  );

  /** * FIELDS VALUES NORMALIZERS ** */
  // settings.showLegend
  useEffect(
    () => {
    // clear display.showLegend if it's not allowed
      if (!formSettings.isLegendEnabled) {
        if ('showLegend' in formValues.settings) {
          form.change('settings', omit(formValues.settings, ['showLegend']));
        }
        return;
      }

      // change settings.showLegend to default if it's value not allowed
      const isShowLegendAllowed = formValues.settings?.showLegend != null;
      if (!isShowLegendAllowed) {
        form.change('settings.showLegend', true);
      }
    },
    [formSettings.isLegendEnabled, formValues.settings],
  );

  // settings.legendPosition
  useEffect(
    () => {
    // clear settings.legendPosition if it's not allowed
      if (!formSettings.isLegendPositionEnabled) {
        if ('legendPosition' in formValues.settings) {
          form.change('settings', omit(formValues.settings, ['legendPosition']));
        }
        return;
      }

      // change settings.legendPosition to default if it's value not allowed
      const isLegendPositionAllowed = formValues.settings?.legendPosition != null;
      if (!isLegendPositionAllowed) {
        form.change('settings.legendPosition', 'bottom');
      }
    },
    [formSettings.isLegendPositionEnabled, formValues.settings],
  );

  return (
    <Fragment>
      <FieldsRow>
        <Field
          name="settings.showTitle"
          label="Display Options"
          component={ToggleField}
          type="checkbox"
          checkedLabel="Show Title"
        />

        {formSettings.isMetricEnabled && (
          <Field
            name="settings.showMetric"
            component={ToggleField}
            type="checkbox"
            checkedLabel="Show Metric"
          />
        )}

        {formSettings.isLegendEnabled && (
          <Field
            name="settings.showLegend"
            component={ToggleField}
            type="checkbox"
            checkedLabel="Show Legend"
          />
        )}
      </FieldsRow>

      {formSettings.isLegendPositionEnabled && (
        <Field
          style={{ maxWidth: '50%' }}
          name="settings.legendPosition"
          label="Legend Position"
          component={SelectField}
          options={legendPositionOptions}
          validate={validateRequired}
          parse={normalizeSelectValue}
          required
        />
      )}
    </Fragment>
  );
};

export default WidgetDisplayFields;
