/* eslint-disable react/prop-types, camelcase, react/jsx-indent */
import { Fragment, useMemo } from 'react';
import { Link } from 'react-router-dom';

import styled from 'styled-components';

import EyeCircleIcon from 'mdi-react/EyeCircleIcon';
import EyeCircleOutlineIcon from 'mdi-react/EyeCircleOutlineIcon';

import RoleModel from '@/models/Role';
import RoutePaths from '@/models/RoutePaths';

import Avatar from '+components/Avatar';
import { formatPhoneNumberIntl } from '+components/form/PhoneNumberField';
import {
  BaseColumnFactory,
  MenuColumnFactory,
} from '+components/Table/Columns';
import { getRowOriginal } from '+components/Table/Columns/utils';
import {
  BooleanColumnFilter,
  SelectColumnFilter,
} from '+components/Table/Filters';
import Tag from '+components/Tag';
import Tooltip from '+components/Tooltip';
import dayjs, { DateFormat } from '+utils/dayjs';

const pictureFormatter = ({ value, row }) => {
  const original = getRowOriginal(row);
  return (
    <Avatar
      className="users__avatar-img"
      name={original?.name}
      src={value}
      $size="32px"
    />
  );
};

const roleFormatter = (roles, shortname) => ({ row, value = [] }) => {
  const original = getRowOriginal(row);
  const userRole = original?.app_metadata?.original
      && original?.app_metadata?.original === shortname
    ? original?.original_roles?.[0]
    : value?.[0];
  const role = roles?.[userRole];
  if (!role) {
    return userRole;
  }
  return (
    <Link to={`${RoutePaths.roles}/${role.id}`}>
      <Tag outlined={false} color={role.color}>
        {role.label}
      </Tag>
    </Link>
  );
};

const nameFormatter = ({ row }) => {
  const original = getRowOriginal(row);
  return (
    <Fragment>
      {original?.blocked && (
        <Fragment>
          <Tag color="danger">BLOCKED</Tag>{' '}
        </Fragment>
      )}
      {original?.name}
    </Fragment>
  );
};

const EmailContainer = styled.div`
  display: flex;
  align-items: center;
  .icon {
    margin-right: 4px;
    display: flex;
    align-items: center;
  }
`;
const emailFormatter = (shortname) => ({ row, value = null }) => {
  const original = getRowOriginal(row);
  return (
    <EmailContainer>
      {original?.app_metadata?.original
          && original?.app_metadata?.original === shortname && (
      // Add icon to masquerading users in their parent company
      // @see: https://netography.atlassian.net/browse/PORTAL-1386
        <Tooltip
          title={`Logged in to: ${original?.app_metadata?.shortname}`}
        >
          <span className="icon">
            <EyeCircleIcon size={16} />
          </span>
        </Tooltip>
      )}
      {original?.app_metadata?.original
          && original?.app_metadata?.original !== shortname && (
      // Add icon to masquerading users in child company
        <Tooltip title="Support User">
          <span className="icon">
            <EyeCircleOutlineIcon size={16} />
          </span>
        </Tooltip>
      )}
      {value && <a href={`mailto:${value}`}>{value}</a>}
    </EmailContainer>
  );
};

const phoneFormatter = ({ value = null }) => (value ? <a href={`tel:${value}`}>{formatPhoneNumberIntl(value)}</a> : null);

export const getColumns = ({
  cxActionMenu,
  shortname,
  showIdpColumn,
  roles,
}) => [
  BaseColumnFactory({
    accessor: 'user_metadata.picture',
    Header: '',
    ContextMenuHeader: 'Avatar',
    maxWidth: 42,
    minWidth: 42,

    disableFilters: true,
    disableSortBy: true,
    disableGroupBy: true,
    disableAggregators: true,
    disableDuplicateBy: true,

    getCellProps: () => ({ style: { textOverflow: '' } }),
    Cell: pictureFormatter,
  }),
  BaseColumnFactory({
    accessor: 'name',
    Header: 'Name',
    minWidth: 160,
    Cell: nameFormatter,
  }),
  BaseColumnFactory({
    accessor: 'user_metadata.job_title',
    Header: 'Job Title',
    minWidth: 160,
  }),
  BaseColumnFactory({
    accessor: 'email',
    Header: 'Email',
    minWidth: 200,
    Cell: emailFormatter(shortname),
  }),
  BaseColumnFactory({
    accessor: 'user_metadata.phone_number',
    Header: 'Phone Number',
    minWidth: 120,
    Cell: phoneFormatter,
  }),
  BaseColumnFactory({
    accessor: 'roles',
    Header: 'Role',
    minWidth: 180,
    getCellProps: () => ({ style: { justifyContent: 'center' } }),
    Cell: roleFormatter(roles, shortname),
    Filter: SelectColumnFilter({
      optionLabel: (key) => (key === 'all' ? 'All' : roles?.[key]?.label || key),
      sort: (a, b) => RoleModel.sortRoles(roles?.[a], roles?.[b]),
      selectProps: {
        groupBy: (option) => {
          if (option.value === 'all') {
            return '';
          }
          return roles?.[option.value]?.system ? 'System' : 'Custom';
        },
      },
    }),
    filter: 'selectFilter',
  }),
  BaseColumnFactory({
    accessor: 'last_login',
    Header: 'Last Login',
    minWidth: 150,
    maxWidth: 150,
    getCellProps: () => ({ style: { justifyContent: 'center' } }),
    Cell: (row) => (row.value ? dayjs(row.value).format(DateFormat.second) : 'Never'),
  }),
  ...(showIdpColumn
    ? [
      BaseColumnFactory({
        accessor: (data) => !!(data.idp || data.app_metadata?.useResellerSso),
        Header: 'SSO',
        minWidth: 80,
        maxWidth: 80,
        getCellProps: () => ({ style: { justifyContent: 'center' } }),
        disableResizing: true,
        sortType: 'boolean',
        Cell: ({ value }) => useMemo(
          () => (value ? (
            <Tag outlined={false} color="warning">
                    SSO
            </Tag>
          ) : null),
          [value],
        ),
        Filter: BooleanColumnFilter({
          true: 'SSO',
          false: 'Non SSO',
        }),
        filter: 'booleanFilter',
      }),
    ]
    : []),
  MenuColumnFactory({ cxActionMenu }),
];
