import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import get from 'lodash.get';
import omit from 'lodash.omit';
import some from 'lodash.some';
import useThrottleFn from 'react-use/lib/useThrottleFn';

import { selectors } from '@/redux/ui/socketControl';

/**
 * @typedef DelayOption
 * @property {Number} wait
 * @property {Array<String>} [props] throller or debounce renders on these props only.
 */

/**
 * @typedef RealtimeManagerOption
 * @property {DelayOption} [throttle]
 * @property {DelayOption} [debounce]
 * @property {boolean} [pauseOnSelectiveProps] If true, throttle or debounce props will be used to pause
 * @property {Array<String>} [pauseProps] If not using throttle props or debounce props, then use these props to pause
 */

/**
 * HOC for realtimeManager
 * @param Component
 * @param {RealtimeManagerOption} options
 *
 * @example
 *  export const MyRealtimeComponent = RealtimeManager(MyComponent, {
 *     throttle: {
 *       wait: 500,
 *       props: ['somePropertyThatUpdatesTooFast']
 *     }
 *   })
 *
 * @return {function(*=): *}
 */
export default (Component, options = {}) => {
  const wait = get(options, 'throttle.wait') || get(options, 'debounce.wait');
  const selectiveProps = get(options, 'throttle.props') || get(options, 'debounce.props');
  const pauseOnTheseProps = options.pauseProps || (options.pauseOnSelectiveProps && selectiveProps);

  const Realtime = (props) => {
    const { isPaused } = useSelector(selectors.getState);
    const oldProps = useRef(props);
    const [nextProps, setNextProps] = useState(props);

    useEffect(
      () => {
        if (isPaused && oldProps.current) {
          const newProps = omit(props, pauseOnTheseProps);
          const otherProps = Object.getOwnPropertyNames(newProps);
          const isChanged = some(
            otherProps,
            (key) => newProps[key] !== oldProps.current[key],
          );
          if (!isChanged) {
            return;
          }

          oldProps.current = {
            ...oldProps.current,
            ...newProps,
          };
          setNextProps(oldProps.current);
          return;
        }

        oldProps.current = props;
        setNextProps(props);
      },
      [isPaused, props],
    );

    const updatedProps = useMemo(
      () => omit(nextProps, selectiveProps),
      [nextProps],
    );

    // TODO add debounce
    const specialProps = useThrottleFn(
      (values) => {
        const keys = Object.getOwnPropertyNames(omit(values, selectiveProps));

        return omit(values, keys);
      },
      wait,
      [nextProps],
    );

    return useMemo(
      () => <Component {...specialProps} {...updatedProps} />,
      [specialProps, updatedProps],
    );
  };

  return Realtime;
};
