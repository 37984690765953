import PropTypes from '+prop-types';
import { Fragment, useCallback, useState } from 'react';

import styled from 'styled-components';

import BookmarkIcon from 'mdi-react/BookmarkIcon';
import BookmarkOutlineIcon from 'mdi-react/BookmarkOutlineIcon';
import CheckboxMultipleBlankIcon from 'mdi-react/CheckboxMultipleBlankIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import PencilIcon from 'mdi-react/PencilIcon';
import SdIcon from 'mdi-react/SdIcon';

import Icon from './Icon';
import ItemContainer from './ItemContainer';

const Action = styled.button.attrs(() => ({ type: 'button' }))`
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;
  color: inherit;
  line-height: 1em;
  opacity: ${({ $active }) => ($active ? 0.7 : 0)};
  transition: opacity 0.3s;

  ${ItemContainer}:hover & {
    opacity: ${({ $active }) => ($active ? 0.7 : 0.3)};

    &:active,
    &:hover {
      opacity: 1;
    }
  }
`;

const Input = styled.input`
  width: 0;
  flex: 1;
`;

const Title = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Space = styled.div`
  flex: 1 1 0;
  overflow: hidden;
`;

const LayoutItem = (props) => {
  const {
    item,
    isNew,
    needSave,
    select,
    save: _save,
    cancel: _cancel,
    updateTitle,
    duplicate,
    remove,
    selected,
    isReset,
    update,
    isEditable,
    isDuplicable,
    canBeDefault,
    toggleDefault,
  } = props;
  const [title, setTitle] = useState(item?.title || '');
  const [wasFocused, setWasFocused] = useState(false);
  const [editTitle, setEditTitle] = useState(isNew);

  const isDefaultItem = item?.isDefault;

  const onTitleChange = useCallback(
    (event) => {
      setTitle(event?.target?.value);
    },
    [],
  );

  const onFocus = useCallback(
    (event) => {
      if (wasFocused) {
        return;
      }

      setWasFocused(true);

      event?.target?.select();
    },
    [wasFocused],
  );

  const onInputRef = useCallback(
    (node) => {
      if (node) {
        node.focus();
      }
    },
    [],
  );

  const onTitleEditClick = useCallback(
    (event) => {
      event.stopPropagation();
      setEditTitle(true);
    },
    [],
  );

  const cancel = useCallback(
    () => {
      if (isNew) {
        _cancel();
      }

      setEditTitle(false);
    },
    [_cancel, isNew, editTitle],
  );

  const save = useCallback(
    () => {
      if (isNew) {
        _save(title);
        return;
      }

      if (!item) {
        return;
      }

      if (editTitle) {
        setEditTitle(false);
        if (item.title !== title) {
          updateTitle(item.id, title);
        }
        return;
      }

      update(item.id);
    },
    [_save, updateTitle, isNew, editTitle, item, title, update],
  );

  const onSaveClick = useCallback(
    (event) => {
      event.stopPropagation();
      save();
    },
    [save],
  );

  const onKeyDown = useCallback(
    (event) => {
      if (event.key === 'Escape') {
        event.preventDefault();
        cancel();
        return;
      }

      if (event.key === 'Enter') {
        event.preventDefault();
        save();
      }
    },
    [cancel, save],
  );

  const onDuplicateClick = useCallback(
    (event) => {
      event.stopPropagation();
      if (item?.id) {
        duplicate(item.id);
      }
    },
    [item?.id, duplicate],
  );

  const onRemoveClick = useCallback(
    (event) => {
      event.stopPropagation();
      if (item?.id) {
        remove(item?.id);
      }
    },
    [item?.id, remove],
  );

  const onClick = useCallback(
    () => {
      if (item?.id) {
        select(item.id);
      }
    },
    [item?.id, select],
  );

  const onSetDefaultClick = useCallback(
    () => {
      if (item?.id) {
        toggleDefault(item.id, true);
      }
    },
    [item?.id, toggleDefault],
  );

  const onUnsetDefaultClick = useCallback(
    () => {
      if (item?.id) {
        toggleDefault(item.id, false);
      }
    },
    [item?.id, toggleDefault],
  );

  return (
    <ItemContainer onClick={editTitle ? null : onClick} $selected={selected}>
      <Icon>
        {(editTitle || needSave) && isEditable && (
          <Action title="Save" $active onClick={onSaveClick}>
            <SdIcon size={14} />
          </Action>
        )}
        {!(editTitle || needSave) && isDefaultItem && (
          <BookmarkIcon size={14} />
        )}
      </Icon>
      {editTitle && (
        <Input
          value={title}
          onChange={onTitleChange}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          ref={onInputRef}
          maxLength={30}
          autoFocus
        />
      )}
      {!editTitle && (
        <Fragment>
          <Title title={item?.title}>{isReset ? 'Reset' : item?.title}</Title>
          <Space />
          {!isReset && (
            <Fragment>
              {!isDefaultItem && canBeDefault && (
                <Action title="Set as Default" onClick={onSetDefaultClick}>
                  <BookmarkIcon size={14} />
                </Action>
              )}
              {isDefaultItem && canBeDefault && (
                <Action title="Unset as Default" onClick={onUnsetDefaultClick}>
                  <BookmarkOutlineIcon size={14} />
                </Action>
              )}
              {isEditable && (
                <Action title="Edit title" onClick={onTitleEditClick}>
                  <PencilIcon size={12} />
                </Action>
              )}
              {isDuplicable && (
                <Action title="Duplicate" onClick={onDuplicateClick}>
                  <CheckboxMultipleBlankIcon size={12} />
                </Action>
              )}
              {isEditable && (
                <Action title="Remove" onClick={onRemoveClick}>
                  <DeleteIcon size={14} />
                </Action>
              )}
            </Fragment>
          )}
        </Fragment>
      )}
    </ItemContainer>
  );
};

LayoutItem.propTypes = {
  item: PropTypes.shape().isRequired,
  select: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  updateTitle: PropTypes.func.isRequired,
  duplicate: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,

  selected: PropTypes.bool,
  needSave: PropTypes.bool,
  canBeDefault: PropTypes.bool,
  isReset: PropTypes.bool,
  isNew: PropTypes.bool,
  isEditable: PropTypes.bool,
  isDuplicable: PropTypes.bool,
  cancel: PropTypes.func,
  toggleDefault: PropTypes.func,
};

LayoutItem.defaultProps = {
  selected: false,
  needSave: false,
  canBeDefault: false,
  isReset: false,
  isNew: false,
  isEditable: false,
  isDuplicable: false,
  cancel: null,
  toggleDefault: null,
};

export { ItemContainer, Icon, Action, Input, Title, Space };

export default LayoutItem;
