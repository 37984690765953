import styled, { css } from 'styled-components';

import { Row } from '+components/Layout';

const TitleContainer = styled(Row)`
  gap: 12px;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  min-height: 46px;
  max-height: 46px;
  padding: ${(props) => props.$padding};

  .mdi-icon {
    width: 16px;
    min-width: 16px;
    transition: transform 0.3s;
  }

  ${(props) => !props.$active
    && css`
      background-color: transparent;
    `}

  ${(props) => props.$active
    && css`
      background-color: ${props.theme.propertiesTrayActiveBackground};
      .mdi-icon {
        transform: rotate(90deg);
      }
    `}

  ${(props) => props.$disabled
    && css`
      cursor: default;
      pointer-events: none;
      .mdi-icon {
        display: none;
      }
    `}

  ${(props) => !props.$disabled
    && css`
      cursor: pointer;
      pointer-events: auto;
      :hover {
        background-color: ${props.theme.propertiesTrayActiveBackground};
      }
    `}
`;

export default TitleContainer;
