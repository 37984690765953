import { Info } from 'luxon';

import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';

// we can't use extending of AdapterLuxon class :(
function Adapter(props) {
  Object.assign(this, new AdapterLuxon(props));

  this.getWeekdays = () => {
    // workaround the fist day of week, because Luxon returns always Monday
    const days = Info.weekdaysFormat('narrow', { locale: this.locale });
    days.unshift(days.pop());

    return days;
  };

  this.getWeekArray = (date) => {
    const { days } = date
      .endOf('month')
      .endOf('week')
      .diff(date.startOf('month').startOf('week'), 'days')
      .toObject();

    const weeks = [];
    Array.from(
      { length: Math.ceil(days + 7) },
      (_, i) => i,
    )
      .map((day) => date
        .startOf('month')
        .startOf('week')
        .minus({ days: 1 }) // since we need the first day of week to be Sunday
        .plus({ days: day }))
      .forEach((v, i) => {
        if (i === 0 || (i % 7 === 0 && i > 6)) {
          weeks.push([v]);
          return;
        }

        weeks[weeks.length - 1].push(v);
      });

    return weeks.filter((week) => {
      // do not allow weeks with start or end outside of current month
      return week.some((weekDate) => weekDate.hasSame(date, 'month'));
    });
  };

  return this;
}

export default Adapter;
