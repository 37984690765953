import { call, put, take, race } from 'redux-saga/effects';

import { actions as responseIntegrationsActions } from '@/redux/api/integrations/response';
import { actions as toastActions } from '@/redux/toast';
import {
  createSlice,
  createSelector,
  startFetching,
  stopFetching,
  defaultReducers,
  takeLeading,
} from '@/redux/util';

import backendClient from '@/middleware/backendClient';

export const initialState = {
  isFetching: false,
  customError: null, // {}
  error: '',
  algorithms: null, // {}
  categories: null, // []
  rules: null, // {}
  triggers: null, // {}
  ccms: null, // {}
  addedAlgorithm: null,
};

const url = (path) => `/rule-engine${path}`;

let api;

const initApi = () => {
  if (!api) {
    api = backendClient();
  }
};

const slice = createSlice({
  name: 'rules',
  initialState,

  reducers: {
    ...defaultReducers,
    fetchDependencies: startFetching,
    fetchCategories: startFetching,
    addOrUpdateCategory: startFetching,
    deleteAllCategories: startFetching,
    deleteCategory: startFetching,
    bulkDeleteCategories: startFetching,

    addRule: startFetching,
    updateRule: startFetching,
    fetchRules: startFetching,
    fetchRule: startFetching,
    enableRule: startFetching,
    disableRule: startFetching,
    deleteRule: startFetching,
    bulkEnableRules: startFetching,
    bulkDisableRules: startFetching,
    bulkDeleteRules: startFetching,

    /** * Algorithms ** */

    fetchAlgorithms: () => {},
    _fetchAlgorithms: startFetching,
    successFetchAlgorithms(state, { payload: { data } }) {
      stopFetching(state);
      state.algorithms = (data || []).reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {});
    },

    fetchAlgorithm: startFetching,
    successFetchAlgorithm(state, { payload: { data: [item] = [] } = {} }) {
      stopFetching(state);
      if (!item) {
        return;
      }
      if (!state.algorithms) {
        state.algorithms = {};
      }
      state.algorithms[item.id] = item;
    },

    addAlgorithm: startFetching,
    successAddAlgorithm(state, { payload: { item, saveAddedAlgorithm } }) {
      stopFetching(state);
      if (!item) {
        return;
      }
      if (!state.algorithms) {
        state.algorithms = {};
      }
      if (saveAddedAlgorithm) {
        state.addedAlgorithm = item;
      }
      state.algorithms[item.id] = item;
    },

    updateAlgorithm: startFetching,
    successUpdateAlgorithm(state, { payload: { data: [item] = [] } = {} }) {
      stopFetching(state);
      if (!item) {
        return;
      }
      if (!state.algorithms) {
        state.algorithms = {};
      }
      state.algorithms[item.id] = item;

      if (!state.ccms) {
        state.ccms = {};
      }
      state.ccms[item.id] = item;
    },

    successBulkUpdateAlgorithms(state, { payload: { data: items } = {} }) {
      stopFetching(state);
      if (!items) {
        return;
      }
      if (!state.algorithms) {
        state.algorithms = {};
      }
      items.forEach((item) => {
        state.algorithms[item.id] = item;
      });
    },

    successDeleteAlgorithm(state, { payload: id }) {
      stopFetching(state);
      delete state.algorithms?.[id];
    },

    conflictDeleteAlgorithm(state, { payload: error }) {
      stopFetching(state);
      state.customError = error;
    },

    removeCustomError(state) {
      state.customError = null;
    },

    enableAlgorithm: startFetching,
    disableAlgorithm: startFetching,
    resetAlgorithm: startFetching,
    deleteAlgorithm: startFetching,
    bulkEnableAlgorithms: startFetching,
    bulkDisableAlgorithms: startFetching,
    bulkUpdateAlgorithms: startFetching,

    /** * Context ** */

    fetchCCMs: () => {},
    _fetchCCMs: startFetching,
    successFetchCCMs(state, { payload: { data } }) {
      stopFetching(state);
      state.ccms = (data || []).reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {});
    },

    fetchCCM: startFetching,
    successFetchCCM(state, { payload: { data: [item] = [] } = {} }) {
      stopFetching(state);
      if (!item) {
        return;
      }
      if (!state.ccms) {
        state.ccms = {};
      }
      state.ccms[item.id] = item;
    },

    addCCM: startFetching,
    successAddCCM(state, { payload: { data: [item] = [] } = {} }) {
      stopFetching(state);
      if (!item) {
        return;
      }
      if (!state.ccms) {
        state.ccms = {};
      }
      state.ccms[item.id] = item;
    },

    updateCCM: startFetching,
    successUpdateCCM(state, { payload: { data: [item] = [] } = {} }) {
      stopFetching(state);
      if (!item) {
        return;
      }
      if (!state.ccms) {
        state.ccms = {};
      }
      state.ccms[item.id] = item;
    },

    successBulkUpdateCCMs(state, { payload: { data: items } = {} }) {
      stopFetching(state);
      if (!items) {
        return;
      }
      if (!state.ccms) {
        state.ccms = {};
      }
      items.forEach((item) => {
        state.ccms[item.id] = item;
      });
    },

    successDeleteCCM(state, { payload: id }) {
      stopFetching(state);
      delete state.ccms?.[id];
    },

    conflictDeleteCCM(state, { payload: error }) {
      stopFetching(state);
      state.customError = error;
    },

    enableCCM: startFetching,
    disableCCM: startFetching,
    resetCCM: startFetching,
    deleteCCM: startFetching,
    bulkEnableCCMs: startFetching,
    bulkDisableCCMs: startFetching,
    bulkUpdateCCMs: startFetching,

    /** * Triggers ** */

    fetchTriggers: startFetching,
    fetchTriggersSuccess(state, { payload: data }) {
      stopFetching(state);
      state.triggers = data;
    },

    fetchTriggersByAlgorithm: startFetching,
    fetchTriggersByAlgorithmSuccess(state, { payload: { algorithm, data } }) {
      stopFetching(state);
      if (!state.triggers) {
        state.triggers = {};
      }
      state.triggers[algorithm] = data;
    },
    fetchConfiguredTriggersByAlgorithm: startFetching,
    fetchConfiguredTriggersByAlgorithmSuccess(
      state,
      { payload: { algorithm, data } },
    ) {
      stopFetching(state);
      if (!state.configuredTriggers) {
        state.configuredTriggers = {};
      }
      state.configuredTriggers[algorithm] = data.filter(
        (trigger) => trigger.state === 'user',
      );
    },

    deleteTriggersByAlgorithmAndObject: startFetching,
    deleteTriggersByAlgorithmAndObjectSuccess(
      state,
      { payload: { algorithm, object } },
    ) {
      stopFetching(state);
      if (!state.triggers) {
        state.triggers = {};
      }
      if (!state.configuredTriggers) {
        state.configuredTriggers = {};
      }
      if (state.triggers[algorithm]) {
        state.triggers[algorithm] = state.triggers[algorithm].filter(
          (el) => el.track !== object,
        );
      }
      if (state.configuredTriggers[algorithm]) {
        state.configuredTriggers[algorithm] = state.configuredTriggers[
          algorithm
        ].filter((el) => el.track !== object);
      }
    },
    bulkDeleteTriggers: startFetching,
    bulkDeleteTriggersSuccess(state, { payload: { algorithm, tracks } }) {
      stopFetching(state);
      if (!state.triggers) {
        state.triggers = {};
      }
      if (!state.configuredTriggers) {
        state.configuredTriggers = {};
      }

      if (state.triggers[algorithm]) {
        tracks?.forEach((track) => {
          state.triggers[algorithm] = state.triggers[algorithm].filter(
            (el) => el.track !== track,
          );
        });
      }
      if (state.configuredTriggers[algorithm]) {
        tracks?.forEach((track) => {
          state.configuredTriggers[algorithm] = state.configuredTriggers[
            algorithm
          ].filter((el) => el.track !== track);
        });
      }
    },
    updateTrigger: startFetching,
    updateTriggerSuccess(
      state,
      { payload: { algorithm, data, configuredOnly } },
    ) {
      stopFetching(state);
      const stateCopy = {
        ...(configuredOnly ? state.configuredTriggers : state.triggers),
      };
      const triggerIndex = stateCopy[algorithm].findIndex(
        (el) => el.track === data[0].track,
      );
      if (triggerIndex > -1) {
        // eslint-disable-next-line prefer-destructuring
        stateCopy[algorithm][triggerIndex] = data[0];
      } else {
        stateCopy[algorithm].push(data[0]);
      }
      if (configuredOnly) {
        state.configuredTriggers = stateCopy;
      } else {
        state.triggers = stateCopy;
      }
    },

    success: stopFetching,

    successCategories(state, { payload: { data } }) {
      stopFetching(state);
      state.categories = data.sort((a, b) => (a.description.toLowerCase() > b.description.toLowerCase() ? 1 : -1));
    },

    successCategoryAddOrUpdate(state, { payload: { data } }) {
      stopFetching(state);
      if (!data) {
        return;
      }
      const categories = (state.categories || []).reduce((acc, item) => {
        acc[item.name] = item;
        return acc;
      }, {});
      categories[data.name] = data;
      state.categories = Object.values(categories);
    },

    successRules(state, { payload: { data } }) {
      stopFetching(state);
      state.rules = (data || []).reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {});
    },

    successFetchRule(state, { payload: { data: [item] = [] } = {} }) {
      stopFetching(state);
      if (!item) {
        return;
      }
      if (!state.rules) {
        state.rules = {};
      }
      state.rules[item.id] = item;
    },

    successRuleAdd(state, { payload: { data: [item] = [] } = {} }) {
      stopFetching(state);
      if (!item) {
        return;
      }
      if (!state.rules) {
        state.rules = {};
      }
      state.rules[item.id] = item;
    },

    successRuleUpdate(state, { payload: { data: [item] = [] } = {} }) {
      stopFetching(state);
      if (!item) {
        return;
      }
      if (!state.rules) {
        state.rules = {};
      }
      state.rules[item.id] = item;
    },

    successBulkRuleUpdate(state, { payload: { data: rules } = {} }) {
      stopFetching(state);
      if (!rules || !Array.isArray(rules)) {
        return;
      }
      if (!state.rules) {
        state.rules = {};
      }
      rules.forEach((rule) => {
        state.rules[rule.id] = rule;
      });
    },

    successRuleDelete(state, { payload: id }) {
      stopFetching(state);
      delete state.rules?.[id];
    },

    successRuleBulkDelete(state, { payload: ids }) {
      stopFetching(state);
      ids.forEach((id) => {
        delete state.rules?.[id];
      });
    },

    bulkEnableModels: startFetching,
    bulkDisableModels: startFetching,

    skip: stopFetching,

    clearAddedAlgorithm(state) {
      state.addedAlgorithm = null;
    },
  },

  sagas: (actions) => ({
    [actions.fetchCategories]: {
      taker: takeLeading(actions.skip),
      * saga() {
        initApi();
        try {
          const { data } = yield call(api.get, url('/categories'));
          yield put(actions.successCategories(data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching categories',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.addOrUpdateCategory]: {
      * saga({ payload }) {
        initApi();
        try {
          const { name } = payload;
          const response = yield call(
            api.put,
            url(`/category/${name}`),
            payload,
          );
          yield put(actions.successCategoryAddOrUpdate(response.data));
          const message = response.data.meta?.new
            ? 'Category has been created'
            : 'Category has been updated';
          yield put(
            toastActions.successWithAuditLogVerification({
              message,
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error updating category',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.deleteAllCategories]: {
      * saga() {
        initApi();
        try {
          const response = yield call(api.delete, url('/categories'));
          // refetch, as some system:false categories could flip back to true upon delete
          yield put(actions.success());
          yield put(actions.fetchCategories());
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'All custom categories have been deleted',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error deleting categories',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.deleteCategory]: {
      * saga({ payload: category }) {
        initApi();
        try {
          const response = yield call(
            api.delete,
            url(`/category/${category.name}`),
          );
          // refetch, as some system:false categories could flip back to true upon delete
          yield put(actions.success());
          yield put(actions.fetchCategories());
          yield put(
            toastActions.successWithAuditLogVerification({
              message:
                category.system && !category.systemdefault
                  ? 'Category has been reset to default'
                  : 'Category has been deleted',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error deleting category',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.bulkDeleteCategories]: {
      * saga({ payload: categories }) {
        initApi();
        try {
          const response = yield call(api.put, url('/categories/bulkdelete'), {
            categories,
          });
          // refetch, as some system:false categories could flip back to true upon delete
          yield put(actions.success());
          yield put(actions.fetchCategories());
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Categories have been deleted',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error deleting categories',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.fetchRules]: {
      taker: takeLeading(actions.skip),
      * saga() {
        initApi();
        try {
          const { data } = yield call(api.get, url('/rules'));
          yield put(actions.successRules(data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching response policies',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.fetchRule]: {
      * saga({ payload: id }) {
        initApi();
        try {
          const { data } = yield call(api.get, url(`/rule/${id}`));
          yield put(actions.successFetchRule(data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching response policy',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.addRule]: {
      * saga({ payload }) {
        initApi();
        try {
          const response = yield call(api.post, url('/rule'), payload);
          yield put(actions.successRuleAdd(response.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Response policy has been created',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error creating response policy',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.updateRule]: {
      * saga({ payload }) {
        initApi();
        try {
          const { id } = payload;
          const response = yield call(api.put, url(`/rule/${id}`), payload);
          yield put(actions.successRuleUpdate(response.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Response policy has been updated',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error updating response policy',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.deleteRule]: {
      * saga({ payload: id }) {
        initApi();
        try {
          const response = yield call(api.delete, url(`/rule/${id}`));
          yield put(actions.successRuleDelete(id));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Response policy has been deleted',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error deleting response policy',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.bulkDeleteRules]: {
      * saga({ payload: ids }) {
        initApi();
        try {
          yield call(api.put, url('/rules/bulkdelete'), {
            ids,
          });
          yield put(actions.successRuleBulkDelete(ids));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Response policies deleted',
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error deleting response policies',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.enableRule]: {
      * saga({ payload: id }) {
        initApi();
        try {
          const response = yield call(api.put, url(`/rule/${id}/enable`));
          yield put(actions.successRuleUpdate(response.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Response policy has been updated',
              response,
              showWarningOnly: true,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error enabling response policy',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.bulkEnableRules]: {
      * saga({ payload: ids }) {
        initApi();
        try {
          const response = yield call(api.put, url('/rules/bulkenable'), {
            ids,
          });
          yield put(actions.successBulkRuleUpdate(response.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Response policies enabled',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error enabling response policiues',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.disableRule]: {
      * saga({ payload: id }) {
        initApi();
        try {
          const response = yield call(api.put, url(`/rule/${id}/disable`));
          yield put(actions.successRuleUpdate(response.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Response policy has been updated',
              response,
              showWarningOnly: true,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error disabling response policy',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.bulkDisableRules]: {
      * saga({ payload: ids }) {
        initApi();
        try {
          const response = yield call(api.put, url('/rules/bulkdisable'), {
            ids,
          });
          yield put(actions.successBulkRuleUpdate(response.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Response policies disabled',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error disabling response policies',
              details: error.message,
            }),
          );
        }
      },
    },

    /** * Algorithms ** */

    [actions._fetchAlgorithms]: {
      taker: takeLeading(actions.skip),
      * saga() {
        initApi();
        try {
          const { data } = yield call(api.get, url('/algorithms'));
          yield put(actions.successFetchAlgorithms(data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching detection models',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.fetchAlgorithm]: {
      * saga({ payload: id }) {
        initApi();
        try {
          const { data } = yield call(api.get, url(`/algorithm/${id}`));
          yield put(actions.success());
          yield put(actions.successFetchAlgorithm(data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching detection model',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.addAlgorithm]: {
      * saga({ payload: { saveAddedAlgorithm, silent = false, ...data } }) {
        initApi();
        try {
          const response = yield call(api.post, url('/algorithm'), data);
          yield put(
            actions.successAddAlgorithm({
              item: response.data.data[0],
              saveAddedAlgorithm,
            }),
          );
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Detection model has been created',
              response,
              showWarningOnly: silent,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error creating detection model',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.updateAlgorithm]: {
      * saga({ payload: { silent = false, ...data } }) {
        initApi();
        try {
          const { id } = data;
          delete data.id;
          const response = yield call(api.put, url(`/algorithm/${id}`), data);
          yield put(actions.successUpdateAlgorithm(response.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Detection model has been updated',
              response,
              showWarningOnly: silent,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error updating detection model',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.enableAlgorithm]: {
      * saga({ payload: id }) {
        initApi();
        try {
          const response = yield call(api.put, url(`/algorithm/${id}/enable`));
          yield put(actions.successUpdateAlgorithm(response.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Detection model has been enabled',
              response,
              showWarningOnly: true,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error enabling detection model',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.disableAlgorithm]: {
      * saga({ payload: id }) {
        initApi();
        try {
          const response = yield call(api.put, url(`/algorithm/${id}/disable`));
          yield put(actions.successUpdateAlgorithm(response.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Detection model has been disabled',
              response,
              showWarningOnly: true,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error disabling detection model',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.resetAlgorithm]: {
      * saga({ payload: id }) {
        initApi();
        try {
          const response = yield call(api.put, url(`/algorithm/${id}/reset`));
          yield put(actions.successUpdateAlgorithm(response.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Detection model has been reset',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error resetting detection model',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.deleteAlgorithm]: {
      * saga({ payload: id }) {
        initApi();
        try {
          const response = yield call(api.delete, url(`/algorithm/${id}`));
          yield put(actions.successDeleteAlgorithm(id));
          yield put(actions.removeCustomError());
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Detection model has been deleted',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(actions.conflictDeleteAlgorithm({ error, id }));
        }
      },
    },
    [actions.bulkEnableAlgorithms]: {
      * saga({ payload: ids }) {
        initApi();
        try {
          const response = yield call(api.put, url('/algorithms/bulkenable'), {
            ids,
          });

          yield put(actions.successBulkUpdateAlgorithms(response.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Detection models have been enabled',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error enabling detection models',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.bulkDisableAlgorithms]: {
      * saga({ payload: ids }) {
        initApi();
        try {
          const response = yield call(api.put, url('/algorithms/bulkdisable'), {
            ids,
          });

          yield put(actions.successBulkUpdateAlgorithms(response.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Detection models have been disabled',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error disabling detection models',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.bulkUpdateAlgorithms]: {
      * saga({ payload: algorithms }) {
        initApi();
        try {
          // const { id } = payload;
          // delete payload.id;
          const response = yield call(api.put, url('/algorithms/bulkupdate'), {
            algorithms,
          });
          yield put(actions.successBulkUpdateAlgorithms(response.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Detection models have been updated',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error updating detection models',
              details: error.message,
            }),
          );
        }
      },
    },

    /** * Context ** */

    [actions._fetchCCMs]: {
      taker: takeLeading(actions.skip),
      * saga() {
        initApi();
        try {
          const { data } = yield call(api.get, url('/ccms'));
          yield put(actions.successFetchCCMs(data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching detection models',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.fetchCCM]: {
      * saga({ payload: id }) {
        initApi();
        try {
          const { data } = yield call(api.get, url(`/ccm/${id}`));
          yield put(actions.success());
          yield put(actions.successFetchCCM(data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching detection model',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.addCCM]: {
      * saga({ payload }) {
        initApi();
        try {
          const response = yield call(api.post, url('/ccm'), payload);
          yield put(actions.successAddCCM(response.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Detection model has been created',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error creating detection model',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.updateCCM]: {
      * saga({ payload }) {
        initApi();
        try {
          const { id } = payload;
          delete payload.id;
          const response = yield call(api.put, url(`/ccm/${id}`), payload);
          yield put(actions.successUpdateCCM(response.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Detection model has been updated',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error updating detection model',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.enableCCM]: {
      * saga({ payload: id }) {
        initApi();
        try {
          const response = yield call(api.put, url(`/ccm/${id}/enable`));
          yield put(actions.successUpdateCCM(response.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Detection model has been enabled',
              response,
              showWarningOnly: true,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error enabling detection model',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.disableCCM]: {
      * saga({ payload: id }) {
        initApi();
        try {
          const response = yield call(api.put, url(`/ccm/${id}/disable`));
          yield put(actions.successUpdateCCM(response.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Detection model has been disabled',
              response,
              showWarningOnly: true,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error disabling detection model',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.resetCCM]: {
      * saga({ payload: id }) {
        initApi();
        try {
          const response = yield call(api.put, url(`/ccm/${id}/reset`));
          yield put(actions.successUpdateCCM(response.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Detection model has been reset',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error resetting detection model',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.deleteCCM]: {
      * saga({ payload: id }) {
        initApi();
        try {
          const response = yield call(api.delete, url(`/ccm/${id}`));
          yield put(actions.successDeleteCCM(id));
          yield put(actions.removeCustomError());
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Detection model has been deleted',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(actions.conflictDeleteCCM({ error, id }));
        }
      },
    },
    [actions.bulkEnableCCMs]: {
      * saga({ payload: ids }) {
        initApi();
        try {
          const response = yield call(api.put, url('/ccms/bulkenable'), {
            ids,
          });

          yield put(actions.successBulkUpdateCCMs(response.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Detection models have been enabled',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error enabling detection models',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.bulkDisableCCMs]: {
      * saga({ payload: ids }) {
        initApi();
        try {
          const response = yield call(api.put, url('/ccms/bulkdisable'), {
            ids,
          });

          yield put(actions.successBulkUpdateCCMs(response.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Detection models have been disabled',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error disabling detection models',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.bulkUpdateCCMs]: {
      * saga({ payload: ccms }) {
        initApi();
        try {
          const response = yield call(api.put, url('/ccms/bulkupdate'), {
            ccms,
          });
          yield put(actions.successBulkUpdateCCMs(response.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Detection model has been updated',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error updating detection model',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.fetchDependencies]: {
      taker: takeLeading(actions.skip),
      * saga() {
        try {
          yield put(actions.fetchCategories());
          yield put(actions.fetchAlgorithms());
          yield put(actions.fetchCCMs());
          yield put(responseIntegrationsActions.fetchPlugins());
          yield put(actions.success());
        } catch (error) {
          yield put(actions.fail(error));
        }
      },
    },

    [actions.fetchTriggers]: {
      taker: takeLeading(actions.skip),
      * saga() {
        initApi();
        try {
          const { data } = yield call(api.get, url('/triggers/algorithms'));
          yield put(actions.fetchTriggersSuccess(data.data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching triggers',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.fetchTriggersByAlgorithm]: {
      * saga({ payload: algorithm }) {
        initApi();
        try {
          const { data } = yield call(
            api.get,
            url(`/triggers/algorithms/${algorithm}`),
          );
          yield put(
            actions.fetchTriggersByAlgorithmSuccess({
              algorithm,
              data: data.data,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching triggers',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.fetchConfiguredTriggersByAlgorithm]: {
      * saga({ payload: algorithm }) {
        initApi();
        try {
          const { data } = yield call(
            api.get,
            url(`/triggers/algorithms/${algorithm}?configuredonly=true`),
          );
          yield put(
            actions.fetchConfiguredTriggersByAlgorithmSuccess({
              algorithm,
              data: data.data,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching triggers',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.deleteTriggersByAlgorithmAndObject]: {
      * saga({ payload: { algorithm, object } }) {
        initApi();
        try {
          yield call(
            api.delete,
            url(`/triggers/algorithms/${algorithm}/objects/${object}`),
          );
          yield put(
            actions.deleteTriggersByAlgorithmAndObjectSuccess({
              algorithm,
              object,
            }),
          );
          yield put(toastActions.success('Trigger has been deleted'));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error deleting trigger',
              details: error.message,
            }),
          );
        }
      },
    },
    [actions.bulkDeleteTriggers]: {
      * saga({ payload: { algorithm, tracks } }) {
        initApi();
        try {
          yield call(
            api.put,
            url(`/triggers/algorithms/${algorithm}/bulkdelete`),
            { tracks },
          );
          yield put(
            actions.bulkDeleteTriggersSuccess({
              algorithm,
              tracks,
            }),
          );
          yield put(
            toastActions.success(
              `Trigger${tracks.length > 1 ? 's' : ''} ${
                tracks.length > 1 ? 'have' : 'has'
              } been deleted`,
            ),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: `Error deleting trigger${tracks.length > 1 ? 's' : ''}`,
              details: error.message,
            }),
          );
        }
      },
    },
    // Update method will either create a new trigger if a new track (object) value is supplied
    // or it will update the trigger if an existing track value is supplied.
    [actions.updateTrigger]: {
      * saga({ payload: { algorithm, data, configuredOnly } }) {
        initApi();
        try {
          const response = yield call(api.put, url('/triggers'), data);
          yield put(
            actions.updateTriggerSuccess({
              algorithm,
              data: response.data.data,
              configuredOnly,
            }),
          );
          yield put(toastActions.success('Trigger has been updated'));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error updating trigger',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.bulkEnableModels]: {
      * saga({ payload: { algorithms, ccms } }) {
        initApi();
        try {
          if (!algorithms?.length && !ccms?.length) {
            yield put(actions.skip());
            return;
          }

          const responses = [];
          let algorithmResponse;
          let ccmResponse;

          if (algorithms?.length) {
            algorithmResponse = yield call(
              api.put,
              url('/algorithms/bulkenable'),
              {
                ids: algorithms,
              },
            );
          }
          if (ccms?.length) {
            ccmResponse = yield call(api.put, url('/ccms/bulkenable'), {
              ids: ccms,
            });
          }

          if (algorithmResponse) {
            yield put(
              actions.successBulkUpdateAlgorithms(algorithmResponse.data),
            );
            responses.push(algorithmResponse);
          }
          if (ccmResponse) {
            yield put(actions.successBulkUpdateCCMs(ccmResponse.data));
            responses.push(ccmResponse);
          }

          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Detection models have been enabled',
              response: responses,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error enabling detection models',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.bulkDisableModels]: {
      * saga({ payload: { algorithms, ccms } }) {
        initApi();
        try {
          if (!algorithms?.length && !ccms?.length) {
            yield put(actions.skip());
            return;
          }

          const responses = [];
          let algorithmResponse;
          let ccmResponse;

          if (algorithms?.length) {
            algorithmResponse = yield call(
              api.put,
              url('/algorithms/bulkdisable'),
              {
                ids: algorithms,
              },
            );
          }
          if (ccms?.length) {
            ccmResponse = yield call(api.put, url('/ccms/bulkdisable'), {
              ids: ccms,
            });
          }

          if (algorithmResponse) {
            yield put(
              actions.successBulkUpdateAlgorithms(algorithmResponse.data),
            );
            responses.push(algorithmResponse);
          }
          if (ccmResponse) {
            yield put(actions.successBulkUpdateCCMs(ccmResponse.data));
            responses.push(ccmResponse);
          }

          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Detection models have been disabled',
              response: responses,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error disabling detection models',
              details: error.message,
            }),
          );
        }
      },
    },

    rulesWatchers: {
      * watcher() {
        while (true) {
          const res = yield race({
            alg: take(actions.fetchAlgorithms),
            ccms: take(actions.fetchCCMs),
          });

          if (res.alg) {
            yield put(actions._fetchAlgorithms());
          }

          if (res.ccms) {
            yield put(actions._fetchCCMs());
          }
        }
      },
    },
  }),

  selectors: (getState) => ({
    isFetching: createSelector(
      [getState],
      (state) => state.isFetching,
    ),

    getError: createSelector(
      [getState],
      (state) => state.error,
    ),

    getCustomError: createSelector(
      [getState],
      (state) => state.customError,
    ),

    getRules: createSelector(
      [getState],
      ({ rules }) => rules,
    ),

    getRule: (id) => createSelector(
      [getState],
      ({ rules }) => rules?.[id],
    ),

    getAlgorithms: createSelector(
      [getState],
      ({ algorithms }) => algorithms,
    ),

    getAlgorithm: (id) => createSelector(
      [getState],
      ({ algorithms }) => algorithms?.[id],
    ),

    getAlgorithmByName: (name) => createSelector(
      [getState],
      ({ algorithms }) => Object.values(algorithms || {}).find((item) => item.name === name),
    ),

    getCCMs: createSelector(
      [getState],
      ({ ccms }) => ccms,
    ),

    getCCM: (id) => createSelector(
      [getState],
      ({ ccms }) => ccms?.[id],
    ),

    getCategories: createSelector(
      [getState],
      ({ categories }) => categories,
    ),

    getCategoryByName: (name) => createSelector(
      [getState],
      ({ categories }) => categories?.find((item) => item.name === name),
    ),

    getTriggers: createSelector(
      [getState],
      ({ triggers }) => triggers,
    ),

    getTriggersByAlgorithm: (algorithm) => createSelector(
      [getState],
      ({ triggers }) => triggers?.[algorithm],
    ),

    getConfiguredTriggersByAlgorithm: (algorithm) => createSelector(
      [getState],
      ({ configuredTriggers }) => configuredTriggers?.[algorithm],
    ),

    getAddedAlgorithm: createSelector(
      [getState],
      ({ addedAlgorithm }) => addedAlgorithm,
    ),
  }),
});

// For convenience
export const { actions, selectors } = slice;

export default slice;
