import styled from 'styled-components';

const MenuItem = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 6px;
  align-items: center;
  width: 122px;
  height: 26px;
  padding: 0 8px;

  background-color: ${({ theme }) => theme.propertiesTrayMenuItemBackground};
  border: 2px solid ${({ theme }) => theme.propertiesTrayMenuItemBorder};
  border-radius: 13px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  cursor: pointer;

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .mdi-icon {
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
  }
`;

export default MenuItem;
