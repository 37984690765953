import PropTypes from '+prop-types';
import { memo } from 'react';

import { cellPropsCompare, getProps } from '../utils';
import { Border, Borders, Td, Tr, TrGroup } from './Components';

const DefaultFakeCell = () => <span>&nbsp;</span>;

const BorderMemo = memo(Border, cellPropsCompare);

const FakeRowCell = memo(
  // eslint-disable-next-line react/prop-types
  ({ renderComp, ...tail }) => <Td {...tail}>{renderComp}</Td>,
  cellPropsCompare,
);

const FakeRow = (props) => {
  const {
    className,
    getCellProps,
    tableBodyProps,
    odd,
    headers,
    style,
    testId,
  } = props;

  return (
    <TrGroup {...(tableBodyProps || {})} style={style} data-testid={testId}>
      <Tr $padRow $odd={odd} className={className}>
        <Borders>
          {headers?.map((column) => {
            const cellProps = column.getHeaderProps(
              getProps([
                getCellProps,
                column.getCellProps,
                column.getBorderProps,
              ]),
            );
            return <BorderMemo {...cellProps} />;
          })}
        </Borders>

        {headers?.map((column) => {
          const renderComp = column.FakeCell ? (
            column.render('FakeCell')
          ) : (
            <DefaultFakeCell />
          );
          const cellProps = column.getHeaderProps(
            getProps([getCellProps, column.getCellProps]),
          );
          return <FakeRowCell {...cellProps} renderComp={renderComp} />;
        })}
      </Tr>
    </TrGroup>
  );
};

FakeRow.propTypes = {
  className: PropTypes.string,
  getCellProps: PropTypes.func,
  tableBodyProps: PropTypes.shape(),
  odd: PropTypes.bool,
  headers: PropTypes.arrayOf(PropTypes.shape()),
  style: PropTypes.shape(),
  testId: PropTypes.string,
};

FakeRow.defaultProps = {
  className: '',
  getCellProps: null,
  tableBodyProps: null,
  odd: false,
  headers: null,
  style: null,
  testId: null,
};

export default FakeRow;
