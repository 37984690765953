import PropTypes from '+prop-types';
import { Fragment, memo, useCallback, useEffect, useRef } from 'react';

import FilterVariantIcon from 'mdi-react/FilterVariantIcon';

import IconButton from '+components/IconButton';
import { Menu, useMenuActions, useMenuState } from '+components/Menu';
import {
  cellPropsCompare,
  tooltipDelay,
  getHeaderActionMenuPosition,
} from '+components/Table/ReactTable/utils';
import Tooltip from '+components/Tooltip';

import {
  headerSettingsMenuWidth,
  THeadSettings as THeadSettingsOrigin,
} from './Components';

const THeadSettings = memo(THeadSettingsOrigin, cellPropsCompare);

const HeaderSettings = (props) => {
  const {
    index,
    activeActionsColumnIndex,
    setActiveActionsColumnIndex,
    setContainerHover,
    Renderer,
  } = props;

  const menuState = useMenuState();
  const menuActions = useMenuActions();

  const iconRef = useRef(null);

  const onSettingsIconClick = useCallback(
    (event) => {
      event.stopPropagation();
      setActiveActionsColumnIndex(index);
      const { x: menuX, y: menuY } = getHeaderActionMenuPosition(
        iconRef,
        headerSettingsMenuWidth,
      );
      menuActions.showMenuXY(menuX, menuY);
    },
    [index],
  );

  const onSettingsMenuClose = useCallback(
    () => {
      setActiveActionsColumnIndex(null);
      setContainerHover(false);
    },
    [],
  );

  useEffect(
    () => {
      if (
        activeActionsColumnIndex !== index
      || !menuState.show
      || !iconRef.current
      ) {
        return undefined;
      }
      const menuReflow = () => {
        const { x: menuX, y: menuY } = getHeaderActionMenuPosition(
          iconRef,
          headerSettingsMenuWidth,
        );
        if (menuState.left !== menuX || menuState.top !== menuY) {
          menuActions.showMenuXY(menuX, menuY);
        }
      };
      document.addEventListener('scroll', menuReflow, true);
      return () => {
        document.removeEventListener('scroll', menuReflow, true);
      };
    },
    [activeActionsColumnIndex, index, menuState],
  );

  return (
    <Fragment>
      <Tooltip
        title="Apply column settings"
        placement="top"
        enterDelay={tooltipDelay}
        enterNextDelay={tooltipDelay}
        arrow={false}
      >
        <IconButton ref={iconRef} onClick={onSettingsIconClick}>
          <FilterVariantIcon size={16} />
        </IconButton>
      </Tooltip>

      <Menu onClose={onSettingsMenuClose} avoidScrollAction animated={false}>
        <THeadSettings>{Renderer}</THeadSettings>
      </Menu>
    </Fragment>
  );
};

HeaderSettings.propTypes = {
  index: PropTypes.number.isRequired,
  activeActionsColumnIndex: PropTypes.number,
  setActiveActionsColumnIndex: PropTypes.func.isRequired,
  setContainerHover: PropTypes.func.isRequired,
  Renderer: PropTypes.node.isRequired,
};

HeaderSettings.defaultProps = {
  activeActionsColumnIndex: null,
};

export default HeaderSettings;
