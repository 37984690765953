import { Fragment, useState, useMemo, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useToggle } from 'react-use';

import LeadPencilIcon from 'mdi-react/LeadPencilIcon';
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';

import { ContextTypes } from '@/models/ContextTypes';
import { DateTimeModes } from '@/models/DateTimeModes';
import PermissionModel from '@/models/Permission';

import {
  actions as allowlistActions,
  selectors as allowlistSelectors,
} from '@/redux/api/allowlists';

import Button, { ButtonVariants } from '+components/Button';
import { lang } from '+components/charts/common/utils';
import ConfirmModal from '+components/ConfirmModal';
import GlobalFiltersSetting from '+components/GlobalFilters/Setting';
import { ActionsContainer } from '+components/Layout';
import Table from '+components/Table';
import { MenuColumnContextMenu } from '+components/Table/Columns';
import useGlobalFilters from '+hooks/useGlobalFilters';
import useIpLabels from '+hooks/useIpLabels';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import usePermissions from '+hooks/usePermissions';

import { Columns, getColumns } from './components/Columns';

const tableId = 'RulesEngine_Whitelist_Table';
const preparedColumns = Object.values(Columns);

const RuleEngineWhitelists = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const permissions = usePermissions(PermissionModel.Resources.whitelist.value);
  const allowLists = useSelector(allowlistSelectors.getAllowlists);
  const isWhitelistsFetching = useSelector(allowlistSelectors.isFetching);

  const [filters] = useGlobalFilters();

  const { ipLabelsHash } = useIpLabels();

  const [allowlistToDelete, setAllowlistToDelete] = useState({});
  const [selected, setSelected] = useState([]);
  const [showBulkDeleteModal, toggleBulkDeleteModal] = useToggle(false);

  useLoadingIndicator(isWhitelistsFetching);

  const cxActionMenu = useCallback(
    (id, original) => {
      const items = [
        {
          icon: <LeadPencilIcon />,
          text: 'Edit',
          onClick: () => navigate(`${location.pathname}/${original.id}`),
        },
        {
          icon: <TrashCanOutlineIcon />,
          text: 'Delete',
          disabled: !permissions?.delete,
          onClick: () => setAllowlistToDelete(original),
        },
      ];
      return (
        <MenuColumnContextMenu
          title={original.name}
          items={items}
          dataTracking="allowlist"
        />
      );
    },
    [permissions, location.pathname],
  );

  const onConfirmBulkDelete = useCallback(
    () => {
      toggleBulkDeleteModal();
      if (!selected.length) {
        return;
      }

      dispatch(allowlistActions.bulkDeleteAllowlists(selected));
    },
    [selected],
  );

  const columns = useMemo(
    () => getColumns(preparedColumns, {
      cxActionMenu,
      labelContext: filters.labelContext,
    }),
    [cxActionMenu, filters.labelContext],
  );

  const tableData = useMemo(
    () => {
      if (!filters.labelContext.show) {
        return Object.values(allowLists || {});
      }
      return Object.values(allowLists || {}).map((item) => {
        const allowlistnames = (item.whitelist || []).map(
          (ip) => ipLabelsHash[ip]?.[filters.labelContext.ip],
        );
        return {
          ...item,
          allowlistnames,
        };
      });
    },
    [allowLists, ipLabelsHash, filters.labelContext],
  );

  const onWhitelistAdd = useCallback(
    () => {
      navigate(`${location.pathname}/add`);
    },
    [location.pathname],
  );

  const onWhitelistDeleteToggle = useCallback(
    () => setAllowlistToDelete({}),
    [],
  );

  const onWhitelistDeleteConfirm = useCallback(
    () => {
      dispatch(allowlistActions.deleteAllowlist(allowlistToDelete));
      setAllowlistToDelete({});
    },
    [allowlistToDelete],
  );

  const onSelectedRowsChange = useCallback(
    (selectedRowIds) => {
      setSelected((prev) => {
        const next = Object.entries(selectedRowIds || {})
          .map(([key, value]) => (value ? key : null))
          .filter(Boolean);

        if (!prev.length && !next.length) {
          return prev;
        }

        return next;
      });
    },
    [],
  );

  const getIsRowSelectorDisabled = useCallback(
    () => !permissions?.update,
    [permissions],
  );

  const allowListsLength = Object.keys(allowLists || {}).length;
  useEffect(
    () => {
      if (allowListsLength) {
        return;
      }
      dispatch(allowlistActions.fetchAllowlists());
    },
    [allowListsLength],
  );

  return (
    <Fragment>
      <GlobalFiltersSetting
        context={ContextTypes.flow}
        dateTimeMode={DateTimeModes.now}
        range={false}
        from={false}
        to={false}
        nql={false}
      />

      <ActionsContainer>
        <Button onClick={onWhitelistAdd} disabled={!permissions?.create}>
          Add Allow List
        </Button>

        <Button
          variant={ButtonVariants.outlined}
          onClick={toggleBulkDeleteModal}
          disabled={!permissions?.delete || !selected.length}
        >
          Delete Selected
        </Button>
      </ActionsContainer>

      <Table
        id={tableId}
        columns={columns}
        data={tableData}
        sortBy={[
          {
            id: 'name',
            desc: false,
          },
        ]}
        noDataText={allowLists ? undefined : lang.loading}
        getIsRowSelectorDisabled={getIsRowSelectorDisabled}
        onSelectedRowsChange={onSelectedRowsChange}
      />
      {showBulkDeleteModal && (
        <ConfirmModal
          item={`${selected.length} allow list${
            selected.length > 1 ? 's' : ''
          }`}
          confirmButtonText="delete"
          whyAsking=""
          onToggle={toggleBulkDeleteModal}
          onConfirm={onConfirmBulkDelete}
          toggleOnConfirm={false}
          isOpen
        />
      )}

      {!!Object.keys(allowlistToDelete || {}).length && (
        <ConfirmModal
          item={`${allowlistToDelete?.name} allow list`}
          onToggle={onWhitelistDeleteToggle}
          onConfirm={onWhitelistDeleteConfirm}
          isOpen
        />
      )}
    </Fragment>
  );
};

export default RuleEngineWhitelists;
