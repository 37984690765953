const MAX_FILTER_RECENT_LIST = 10;

export const convertNqlContextToModeTag = (nqlContext) => {
  if (nqlContext === 'alerts') {
    return 'alert';
  }
  if (nqlContext === 'blocks') {
    return 'block';
  }
  return nqlContext;
};

export const getNewRecentFiltersList = (nql, recentsRef, mode, customerShortname) => {
  const newRecents = [...recentsRef.current];
  if (!nql || nql.trim() === '') {
    return newRecents;
  }
  const customerIndex = newRecents.findIndex((recent) => recent.customer === customerShortname);
  const convertedMode = convertNqlContextToModeTag(mode);
  let recentQueries = [{ nql, mode: convertedMode }];
  if (customerIndex > -1) {
    recentQueries = [...newRecents[customerIndex].recentQueries];
    // handle duplicate queries
    recentQueries.forEach((query, index) => {
      if (query?.nql === nql && query.mode === convertedMode) {
        recentQueries.splice(index, 1);
      }
    });
    if (recentQueries.length >= MAX_FILTER_RECENT_LIST) {
      recentQueries = recentQueries.slice(0, MAX_FILTER_RECENT_LIST - 1);
    }
    recentQueries.unshift({ nql, mode: convertedMode });
    newRecents[customerIndex] = {
      customer: customerShortname,
      recentQueries,
    };
  } else {
    newRecents.push({
      customer: customerShortname,
      recentQueries,
    });
  }

  return newRecents;
};
