import { css } from 'styled-components';

export default ({ theme }) => css`
  .form {
    input,
    textarea {
      width: 100%;
      padding: 5px 7px;
      font-size: 12px;
      transition: border 0.3s;

      border: 1px solid ${theme.colorFieldsBorder};
      border-radius: 4px;
      color: ${theme.colorText};
      background: ${theme.colorFieldBackground};

      &::placeholder {
        opacity: 1;
        color: ${theme.colorTextSecondary};
      }
    }

    textarea {
      min-height: 32px;
    }
  }
`;
