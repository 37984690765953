/**
 * truncate an string to a given length with a given separator
 * @param {string} input - the string to truncate
 * @param {number} length - the length to truncate to
 * @param {string} [separator='…'] = the separator to use
 * @returns {string|*}
 *
 * @example
 * stringTruncate('this is a string', 11, '_-_'); // 'this_-_ring'
 */
export const stringTruncate = (input, length, separator) => {
  if (!input || typeof input !== 'string') {
    return input;
  }

  const textLen = input.length;
  const len = Number.isNaN(+length) ? Infinity : Math.max(+length || 0, 0);

  if (textLen <= len) {
    return input;
  }

  const fixedSeparator = separator ?? '…';
  const separatorLen = fixedSeparator.length;

  if (separatorLen >= len) {
    return separator;
  }

  const n = -0.5 * (len - textLen - separatorLen);
  const center = textLen * 0.5;

  const head = input.slice(0, center - n).trim();
  const tail = input.slice(textLen - center + n).trim();

  return `${head}${fixedSeparator}${tail}`;
};
