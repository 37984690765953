/* eslint-disable jsx-a11y/control-has-associated-label */
import PropTypes from '+prop-types';

import Field from '+components/form/FinalForm/Field';
import { normalizeSelectValue } from '+components/form/Normalizers';
import SelectField from '+components/form/SelectField';

const ip4Agg = Array.from(
  { length: 25 },
  (_, index) => ({
    value: index + 8,
    label: `/${index + 8}`,
  }),
).reverse();

const ip6Agg = Array.from(
  { length: 21 },
  (_, index) => ({
    value: 48 + index * 4,
    label: `/${48 + index * 4}`,
  }),
).reverse();
ip6Agg.splice(1, 0, { value: 127, label: '/127' });

const RenderIpAggregate = ({ disabled }) => (
  <table
    style={{
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: '0 5px',
    }}
  >
    <thead>
      <tr>
        <td />
        <td>srcip</td>
        <td> </td>
        <td>dstip</td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>IPv4</td>
        <td>
          <Field
            name="config.ipv4_srcipagg"
            component={SelectField}
            options={ip4Agg}
            parse={normalizeSelectValue}
            disabled={disabled}
          />
        </td>
        <td />
        <td>
          <Field
            name="config.ipv4_dstipagg"
            component={SelectField}
            options={ip4Agg}
            parse={normalizeSelectValue}
            disabled={disabled}
          />
        </td>
      </tr>
      <tr>
        <td>IPv6</td>
        <td>
          <Field
            name="config.ipv6_srcipagg"
            component={SelectField}
            options={ip6Agg}
            parse={normalizeSelectValue}
            disabled={disabled}
          />
        </td>
        <td />
        <td>
          <Field
            name="config.ipv6_dstipagg"
            component={SelectField}
            options={ip6Agg}
            parse={normalizeSelectValue}
            disabled={disabled}
          />
        </td>
      </tr>
    </tbody>
  </table>
);

RenderIpAggregate.propTypes = {
  disabled: PropTypes.bool,
};

RenderIpAggregate.defaultProps = {
  disabled: false,
};

export default RenderIpAggregate;
