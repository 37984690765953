import PropTypes from '+prop-types';
import { useMemo } from 'react';

import Flag from '+components/Flag';

import GenericLabel from './GenericLabel';

const GeoLabel = (props) => {
  const { children, countrycode, icon, ...tail } = props;

  const Icon = useMemo(
    () => {
      if (icon) {
        return icon;
      }
      return !countrycode ? null : <Flag countryCode={countrycode} style={{}} />;
    },
    [icon, countrycode],
  );

  return (
    <GenericLabel {...tail} icon={Icon}>
      {children}
    </GenericLabel>
  );
};

GeoLabel.propTypes = {
  countrycode: PropTypes.string,
  icon: PropTypes.children,
  children: PropTypes.children.isRequired,
};

GeoLabel.defaultProps = {
  countrycode: null,
  icon: null,
};

export default GeoLabel;
