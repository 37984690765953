import styled from 'styled-components';

import IconButton from '+components/IconButton';

const NewTabButton = styled(IconButton)`
  background-color: ${({ theme }) => theme.pageTabNewTabButtonBackground};
  color: ${({ theme }) => theme.pageTabNewTabButtonColor};

  :hover {
    background-color: ${({ theme }) => theme.pageTabNewTabButtonBackgroundHover};
    color: ${({ theme }) => theme.pageTabNewTabButtonColorHover};
  }
`;

export default NewTabButton;
