import PropTypes from '+prop-types';
import { useCallback, useRef } from 'react';

import classNames from 'classnames';
import styled from 'styled-components';

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow: hidden;
  padding: 0 4px;

  .mdi-icon {
    color: ${({ theme }) => theme.genericLabelIconColor};
  }
`;

const ContextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow: hidden;
  padding: 0 4px;
  font-size: 12px;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 4px;
  font-size: 12px;
  height: 100%;
  white-space: nowrap;

  .menu-trigger span {
    font-size: 12px;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  height: 21px;
  min-height: 21px;
  max-height: 21px;
  width: fit-content;
  min-width: fit-content;
  max-width: fit-content;
  overflow: hidden;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  border-color: ${({ theme }) => theme.genericLabelBorderColor};
  user-select: none;

  ${IconContainer} {
    background-color: ${({ theme }) => theme.genericLabelIconBackground};
  }
  ${ContextContainer} {
    background-color: ${({ theme }) => theme.genericLabelContextBackground};
  }
  ${BodyContainer} {
    background-color: ${({ theme }) => theme.genericLabelBodyBackground};
  }

  &.clickable {
    cursor: pointer;
    :hover {
      border-color: ${({ theme }) => theme.genericLabelBorderColorHover};
      ${IconContainer} {
        background-color: ${({ theme }) => theme.genericLabelIconBackgroundHover};
      }
      ${ContextContainer} {
        background-color: ${({ theme }) => theme.genericLabelContextBackgroundHover};
      }
      ${BodyContainer} {
        background-color: ${({ theme }) => theme.genericLabelBodyBackgroundHover};
      }
    }
  }
`;

const GenericLabel = (props) => {
  const {
    className,
    icon,
    context,
    clickable,
    children,
    onClick,
    onContextMenu,
    ...tail
  } = props;

  const bodyRef = useRef();

  const doClick = useCallback(
    (e) => {
      if (onClick) {
        onClick(e);
        return;
      }

      if (!clickable || !bodyRef.current?.firstChild) {
        return;
      }

      bodyRef.current.firstChild.click();
    },
    [clickable, onClick],
  );

  const doContextMenu = useCallback(
    (e) => {
      if (onContextMenu) {
        onContextMenu(e);
        return;
      }

      if (!clickable || !bodyRef.current?.firstChild) {
        return;
      }

      e.preventDefault();
      const event = new MouseEvent('contextmenu', {
        bubbles: true,
        clientX: e.clientX,
        clientY: e.clientY,
      });
      bodyRef.current.firstChild.dispatchEvent(event);
    },
    [clickable, onContextMenu],
  );

  return (
    <Container
      {...tail}
      className={classNames(className, { clickable })}
      onClick={doClick}
      onContextMenu={doContextMenu}
    >
      {icon && <IconContainer>{icon}</IconContainer>}
      {context && <ContextContainer>{context}</ContextContainer>}
      {children && <BodyContainer ref={bodyRef}>{children}</BodyContainer>}
    </Container>
  );
};

GenericLabel.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.children,
  context: PropTypes.children,
  children: PropTypes.children,
  clickable: PropTypes.bool,
  onClick: PropTypes.func,
  onContextMenu: PropTypes.func,
};

GenericLabel.defaultProps = {
  className: '',
  icon: null,
  context: null,
  children: null,
  clickable: false,
  onClick: null,
  onContextMenu: null,
};

export { Container, IconContainer, ContextContainer, BodyContainer };
export default GenericLabel;
