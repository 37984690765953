import styled from 'styled-components';

const TabGroupTitle = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 24px;
  overflow: hidden;
  padding: 0 10px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme }) => theme.settingsTabGroupTitle} !important;
`;

export default TabGroupTitle;
