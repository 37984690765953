import styled from 'styled-components';

const Table = styled.table`
  //font-size: 0.9em;
  //font-family: 'Source Code Pro', monospace;
  font-size: 12px;

  tr {
    vertical-align: top;
  }
`;

export default Table;
