/* eslint-disable max-len */
import PropTypes from '+prop-types';
import { memo } from 'react';

const AwsS3Icon = memo(({ size }) => (
  <svg
    width={size}
    height={size}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 36 36"
  >
    <g transform="translate(4 2)">
      <path
        d="M15.63 31.388l-7.135-2.56V18.373l7.135 2.43zm1.3 0l7.135-2.56V18.373l-7.135 2.432zm-7.7-13.8l7.2-2.033 6.696 2.16-6.696 2.273zm-2.092-.8L0 14.22V3.75l7.135 2.43zm1.307 0l7.135-2.56V3.75L8.443 6.192zm-7.7-13.8l7.2-2.043 6.696 2.16-6.696 2.273zm23.052 13.8l-7.135-2.56V3.75l7.135 2.43zm1.3 0l7.135-2.56V3.75l-7.135 2.43zm-7.7-13.8l7.2-2.033 6.696 2.16-6.696 2.273z"
        fill="#f90"
        fillRule="evenodd"
      />
    </g>
  </svg>
));

AwsS3Icon.propTypes = {
  size: PropTypes.number,
};

AwsS3Icon.defaultProps = {
  size: 24,
};

export { AwsS3Icon };
