/* eslint-disable max-len */
import PropTypes from '+prop-types';
import { memo } from 'react';

const SentinelOneIcon = memo(({ size }) => (
  <svg
    width={size}
    height={size}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 77.09 95.88"
  >
    <defs>
      <style>{'.cls-1{fill:#6b0aea;fill-rule:evenodd;}'}</style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="ART">
        <path
          className="cls-1"
          d="M32.08,0H45V77.25H32.08ZM48.13,95.88l12.91-8V21a32.21,32.21,0,0,0-12.91-5.72ZM16,87.92l12.92,8V15.32A32.19,32.19,0,0,0,16,21ZM64.17,3.67V86.48l6-3.72a15.3,15.3,0,0,0,6.89-13V30.65C77.09,19.37,64.17,3.67,64.17,3.67ZM0,69.73a15.27,15.27,0,0,0,6.89,13l6,3.72V3.67S0,19.37,0,30.65Z"
        />
      </g>
    </g>
  </svg>
));

SentinelOneIcon.propTypes = {
  size: PropTypes.number,
};

SentinelOneIcon.defaultProps = {
  size: 24,
};

export { SentinelOneIcon };
