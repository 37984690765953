import styled, { css } from 'styled-components';

import ScrollBarOrigin from '+components/ScrollBar';

const ScrollBar = styled(ScrollBarOrigin)`
  height: 100%;
  margin-bottom: ${(props) => `${props.$marginBottom || 0}px`};
  overflow-x: hidden;
  overflow-y: ${(props) => (props.$hide ? 'hidden' : 'auto')}};

  .simplebar-content {
    display: flex;
    flex-direction: column;
  }

  ${(props) => props.$wide
    && css`
      > .simplebar-track.simplebar-vertical {
        width: 12px;
      }
    `}
`;

export default ScrollBar;
