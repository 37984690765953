import styled from 'styled-components';

import IconButtonOrigin from '@mui/material/IconButton';

const IconButton = styled(IconButtonOrigin).attrs((props) => ({
  size: props.size || 'small',
  disableRipple: true,
}))`
  background-color: ${({ theme }) => theme.iconButtonBackground};
  color: ${({ theme }) => theme.iconButtonText};

  :hover {
    background-color: ${({ theme }) => theme.color(theme.iconButtonBackground).lighten(0.2)};
  }

  &.MuiIconButton-sizeSmall {
    width: 25px;
    height: 25px;
  }

  &.Mui-disabled {
    background-color: ${({ theme }) => theme.iconButtonBackgroundDisabled};
    color: ${({ theme }) => theme.iconButtonText};
  }
`;

export default IconButton;
