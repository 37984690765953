import { createContext, useContext, useReducer, useEffect } from 'react';

const BreadContext = createContext();
export const useCrumbs = () => useContext(BreadContext);

export const CrumbTypes = {
  ADD_CRUMB: 'ADD_CRUMB',
  REMOVE_CRUMB: 'REMOVE_CRUMB',
  UPDATE_LAST_CRUMB: 'UPDATE_LAST_CRUMB',
};

const notAllowedTitle = ['Home', '', 'Netography'];

const setTitle = (data) => {
  let { title } = data;
  title = notAllowedTitle.includes(title || '') ? '' : title;
  document.title = [title, 'Netography'].filter(Boolean).join(' • ');
};

// Create the reducer
const crumbsReducer = (state, action) => {
  switch (action.type) {
    case CrumbTypes.ADD_CRUMB:
      setTitle(action.payload);
      return [...state, action.payload];

    case CrumbTypes.UPDATE_LAST_CRUMB: {
      const lastCrumb = state.at(-1) || {};
      const data = {
        ...lastCrumb,
        ...action.payload,
      };

      setTitle(data);
      return [...state, data];
    }

    case CrumbTypes.REMOVE_CRUMB:
      return state.slice(0, state.length - 1);

    default:
      return state;
  }
};

/**
 * Context Provider for Bread Crumb components.
 */
const BreadProvider = (props) => {
  const contextValue = useReducer(crumbsReducer, []);

  return <BreadContext.Provider {...props} value={contextValue} />;
};

export const useBreadcrumbEffect = (payload, updateLast) => {
  const [crumbs, dispatch] = useCrumbs();

  useEffect(
    () => {
      const type = updateLast
        ? CrumbTypes.UPDATE_LAST_CRUMB
        : CrumbTypes.ADD_CRUMB;

      dispatch({ type, payload });

      return () => {
        dispatch({ type: CrumbTypes.REMOVE_CRUMB, payload });
      };
    },
    [payload],
  );

  return crumbs;
};

export default BreadProvider;
