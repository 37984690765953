import PropTypes from '+prop-types';
import { useMemo } from 'react';

import CellWrapper from './CellWrapper';

// Generic Context Menu for Protocols
const CellProtocol = (props) => {
  const { field, value, original, disabled } = props;

  const stream = useMemo(
    () => ({
      srcip: original?.srcip,
      srcport: original?.srcport,
      dstip: original?.dstip,
      dstport: original?.dstport,
      protocol: original?.protocol,
    }),
    [original],
  );

  return (
    <CellWrapper
      customer={original?.customer}
      field={field}
      value={value}
      stream={stream}
      disabled={disabled}
    >
      <span>{value}</span>
    </CellWrapper>
  );
};

CellProtocol.propTypes = {
  field: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  original: PropTypes.shape(),
  disabled: PropTypes.bool,
};

CellProtocol.defaultProps = {
  original: undefined,
  disabled: false,
};

export default CellProtocol;
