// given a path, object, and desired value, updates the object's value at the path to match the argument.
const setAttributeFromPath = (path, entity, value) => {
  const pathParts = path.split('.');
  return pathParts.reduce((acc, part, index) => {
    if (acc[part]) {
      if (index < pathParts.length - 1) {
        acc = acc[part];
      } else {
        acc[part] = value;
      }
    }
    return acc;
  }, entity);
};

// recursively searches an object to depth for any string value of 'true' or 'false', returns the paths and values in an array.
const getPathsOfStringBooleans = (obj, paths, currentPath) => {
  return Object.keys(obj).reduce((acc, curr) => {
    if (typeof obj[curr] === 'object') {
      acc.concat(getPathsOfStringBooleans(obj[curr], acc, `${curr}.`));
    } else if (obj[curr] === 'true' || obj[curr] === 'false') {
      acc.push({ path: `${currentPath}${curr}`, value: obj[curr] });
    }
    return acc;
  }, paths);
};

// recursively searches fields object to depth to find a field of toggle type that matches each of the supplied paths, coerce the value to a boolean
const findMatchingSelectorFieldsAndReplace = (
  fields,
  integrationData,
  matchPairs,
) => {
  let deepCopy = JSON.parse(JSON.stringify(integrationData));
  Object.keys(fields).forEach((field) => {
    if (typeof fields[field] === 'object' && field === 'fields') {
      findMatchingSelectorFieldsAndReplace(fields[field], deepCopy, matchPairs);
    } else {
      const match = matchPairs.find((pair) => pair.path === field);
      if (match && fields[field]?.type === 'toggle') {
        deepCopy = setAttributeFromPath(
          match.path,
          integrationData,
          match.value === 'true',
        );
      }
    }
  });
  return deepCopy;
};

const coerceBooleanTypes = (integrationData, fields) => {
  const stringBooleanPaths = getPathsOfStringBooleans(integrationData, [], '');
  return findMatchingSelectorFieldsAndReplace(
    { fields },
    integrationData,
    stringBooleanPaths,
  );
};

export default coerceBooleanTypes;
