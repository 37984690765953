import PropTypes from '+prop-types';

import styled from 'styled-components';

import { ColorTypes } from '@/models/ColorTypes';

import Button, { ButtonVariants } from '+components/Button';
import Modal, {
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
} from '+components/Modal';
import TableTabsSub from '+components/TableTabsSub';
import { TabContent } from '+components/Tabs';
import { ScrollBarMixin } from '+theme/mixins/scrollBarMixin';

const resizeEventDispatcher = () => window.dispatchEvent(new Event('resize'));

/**
 * Raw record dialog.
 */
const RecordModal = styled((props) => {
  const {
    className,
    title,
    data,
    confirmButtonText,
    confirmButtonColor,
    confirmButtonVariant,
    isOpen,
    onToggle,
  } = props;

  return (
    <Modal
      className={className}
      isOpen={isOpen}
      onClose={onToggle}
      // workaround for tabs indicator positioning bug
      // without this workaround tab indicator will be with a little left offset
      onEntered={resizeEventDispatcher}
    >
      <ModalHeader
        onClose={onToggle}
        capitalize={false}
      >
        {title}
      </ModalHeader>

      <ModalBody>
        <TableTabsSub rowData={data} />
      </ModalBody>

      <ModalFooter>
        <Button
          color={confirmButtonColor}
          variant={confirmButtonVariant}
          type="button"
          onClick={onToggle}
        >
          {confirmButtonText}
        </Button>
      </ModalFooter>
    </Modal>
  );
})`
  ${Container} {
    min-width: 500px;
    max-width: 1024px;
    width: 80%;
    overflow: hidden;
  }

  ${ModalBody} {
    overflow-y: hidden;
  }

  ${TabContent} {
    overflow: hidden;
    overflow-y: auto;
    max-height: calc(100vh - 240px);

    ${ScrollBarMixin}
  }
`;

RecordModal.propTypes = {
  className: PropTypes.string,
  /**
   * Modal title.
   */
  title: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.func,
    PropTypes.element,
  ]),
  /**
   * Modal data.
   */
  data: PropTypes.shape({}),
  /**
   * Text for the confirm button
   */
  confirmButtonText: PropTypes.string,
  /**
   * Confirm button color.
   */
  confirmButtonColor: PropTypes.oneOf(Object.values(ColorTypes)),
  /**
   * Confirm button variant.
   */
  confirmButtonVariant: PropTypes.oneOf(Object.values(ButtonVariants)),
  /**
   * Boolean to control the state of the popover.
   */
  isOpen: PropTypes.bool,
  /**
   * A callback fired when the modal toggle.
   */
  onToggle: PropTypes.func,
};

RecordModal.defaultProps = {
  className: '',
  title: 'Raw Record',
  data: {},
  confirmButtonText: 'Done',
  confirmButtonColor: ColorTypes.primary,
  confirmButtonVariant: ButtonVariants.contained,
  isOpen: false,
  onToggle: () => {},
};

export default RecordModal;
