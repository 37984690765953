import PropTypes from '+prop-types';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import styled from 'styled-components';

import PermissionModel from '@/models/Permission';

import { actions } from '@/redux/api/netclass';

import Button from '+components/Button';
import { Field, FinalForm } from '+components/form/FinalForm';
import MultiSelectField from '+components/form/MultiSelectField';
import { normalizeMultiSelectValue } from '+components/form/Normalizers';
import { ToggleField } from '+components/form/Toggle';
import { validateIpOrCidrs } from '+components/form/Validators';
import { ActionsContainer } from '+components/FormWizard';
import { Col, Row } from '+components/Layout';
import usePageTabsAndFormSync from '+hooks/usePageTabsAndFormSync';
import usePermissions from '+hooks/usePermissions';

const Form = styled.form`
  width: 80%;
  background-color: transparent;
  margin-bottom: 40px;
`;

const FormBody = (props) => {
  const { initialValues, handleSubmit, isFetching } = props;

  usePageTabsAndFormSync();

  const permissions = usePermissions(
    PermissionModel.Resources.network_classification.value,
  );

  const [networksOptions] = useState(initialValues?.data?.networks ?? []);

  const canManage = !isFetching && permissions?.update;

  return (
    <Form
      className="form form--horizontal wizard__form"
      onSubmit={handleSubmit}
    >
      <div className="card__title" style={{ marginLeft: '140px' }}>
        <h5 className="head">Internal Networks</h5>
        <h5 className="subhead">
          When matched, the flow will be flagged as internal.
        </h5>
      </div>

      <Row>
        <Col lg={7} item>
          <Field
            name="networks"
            component={MultiSelectField}
            options={networksOptions}
            parse={normalizeMultiSelectValue}
            validate={validateIpOrCidrs}
            allowCreate
            disabled={!canManage}
            label="IP Blocks"
            helperText="List of Classless Inter-Domain Routings (CIDRs)."
          />
        </Col>
      </Row>

      <Row>
        <Col lg={7} item>
          <Field
            name="privatenets"
            type="checkbox"
            component={ToggleField}
            checkedLabel="Enabled"
            label="Private Networks"
            helperText="Enable for auto inclusion of private ip space e.g. RFC1918."
            disabled={!canManage}
          />
        </Col>
      </Row>

      <ActionsContainer>
        <Button type="submit" disabled={!canManage}>
          Update
        </Button>
      </ActionsContainer>
    </Form>
  );
};

FormBody.propTypes = {
  initialValues: PropTypes.shape().isRequired,
  isFetching: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const NetClassForm = (props) => {
  const { netclassType, initialValues, isFetching } = props;

  const dispatch = useDispatch();

  const onSubmit = useCallback(
    (values) => {
      const params = {
        type: netclassType,
        networks: values.networks,
        privatenets: !!values.privatenets,
      };

      dispatch(actions.setNetclass(params));
    },
    [netclassType],
  );

  const fixedInitialValues = useMemo(
    () => initialValues || {},
    [initialValues],
  );

  return (
    <FinalForm
      onSubmit={onSubmit}
      initialValues={fixedInitialValues}
      component={FormBody}
      netclassType={netclassType}
      isFetching={isFetching}
    />
  );
};

NetClassForm.propTypes = {
  netclassType: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape(),
};

NetClassForm.defaultProps = {
  initialValues: null,
};

export default NetClassForm;
