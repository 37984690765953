import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  actions as trafficTopActions,
  selectors as trafficTopSelectors,
} from '@/redux/api/trafficTop';

const defaultNamespace = 'TrafficTop_data';

export const useTrafficTopData = (
  context,
  field,
  params,
  deps,
  namespace = defaultNamespace,
) => {
  const dispatch = useDispatch();

  const fields = useSelector(trafficTopSelectors.getFields(context));

  const data = useSelector(trafficTopSelectors.getData(context));

  useEffect(
    () => {
      const fieldInFields = fields.find((value) => value.field === field);

      if (fieldInFields === undefined) {
        return;
      }

      dispatch(trafficTopActions.clear());

      dispatch(
        trafficTopActions.search(
          {
            ...params,
            aggregate: [field],
            context,
          },
          namespace,
        ),
      );
    },
    [...deps, field, fields],
  );

  useEffect(
    () => () => {
      dispatch(trafficTopActions.cancel(namespace));
    },
    [],
  );

  return data;
};
