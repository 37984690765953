import PropTypes from '+prop-types';
import { memo } from 'react';

import styled from 'styled-components';

import CancelIcon from 'mdi-react/BlockHelperIcon';
import CellphoneMessageIcon from 'mdi-react/CellphoneMessageIcon';
import CloudTagsIcon from 'mdi-react/CloudTagsIcon';
import ConnectionIcon from 'mdi-react/ConnectionIcon';
import EmailOpenOutlineIcon from 'mdi-react/EmailOpenOutlineIcon';
import FileDelimitedOutlineIcon from 'mdi-react/FileDelimitedOutlineIcon';
import FileFindOutlineIcon from 'mdi-react/FileFindOutlineIcon';
import RouterIcon from 'mdi-react/RouterIcon';
import WebhookIcon from 'mdi-react/WebhookIcon';

import { ThemeTypes } from '@/models/ThemeTypes';

import usePortalSettingsValue from '+hooks/usePortalSettingsValue';

import { AwsIcon } from './AwsIcon';
import { AwsRoute53Icon } from './AwsRoute53Icon';
import { AzureIcon } from './AzureIcon';
import { BigPandaIcon } from './BigPandaIcon';
import { CrowdstrikeIcon } from './CrowdstrikeIcon';
import { GcpIcon } from './GcpIcon';
import { IbmIcon } from './IbmIcon';
import { Ns1Icon } from './Ns1Icon';
import { OracleIcon } from './OracleIcon';
import { PagerdutyIcon } from './PagerdutyIcon';
import { PantherIcon } from './PantherIcon';
import { RtbhIcon } from './RtbhIcon';
import { SentinelOneIcon } from './SentinelOneIcon';
import { SlackIcon } from './SlackIcon';
import { SplunkIcon } from './SplunkIcon';
import { SumoLogicIcon } from './SumoLogicIcon';
import { TeamsIcon } from './TeamsIcon';
import { TwilioIcon } from './TwilioIcon';
import { WizBoxIcon as WizIcon } from './WizIcon';

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(0 0 1px ${({ theme }) => theme.colorFieldBackground});
`;

const LogoImage = styled.img`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
`;

const IconMapping = {
  // Response plugins
  bigpanda: BigPandaIcon,
  bgp: RouterIcon,
  blocklist: CancelIcon,
  crowdstrike: CrowdstrikeIcon,
  crowdstrikediscover: CrowdstrikeIcon,
  email: EmailOpenOutlineIcon,
  flowspec: CloudTagsIcon,
  flowspec_custom: CloudTagsIcon,
  ns1: Ns1Icon,
  pagerduty: PagerdutyIcon,
  panther: PantherIcon,
  route53: AwsRoute53Icon,
  rtbh: RtbhIcon,
  slack: SlackIcon,
  sms: CellphoneMessageIcon,
  splunk: SplunkIcon,
  sumologic: SumoLogicIcon,
  syslog: FileFindOutlineIcon,
  twilio: TwilioIcon,
  webhook: WebhookIcon,
  teams: TeamsIcon,

  // Context plugins
  aws: AwsIcon,
  azure: AzureIcon,
  crowdstrikeFalcon: CrowdstrikeIcon,
  gcp: GcpIcon,
  ibm: IbmIcon,
  oracle: OracleIcon,
  s3: FileDelimitedOutlineIcon,
  sentinelone: SentinelOneIcon,
  wiz: WizIcon,
};

const PluginIcon = memo(({ name, size, logo }) => {
  const [theme] = usePortalSettingsValue('ui', 'theme', ThemeTypes.dark);
  const Icon = IconMapping[name];
  // if we recieve a logo from the manifest
  if (logo) {
    let imgsrc = logo.default;
    if (theme === ThemeTypes.light && logo.light) {
      imgsrc = logo.light;
    }
    if (theme === ThemeTypes.dark && logo.dark) {
      imgsrc = logo.dark;
    }
    return (
      <LogoImage
        src={imgsrc}
        size={size}
      />
    );
  }
  // otherwise use local logo for hardcoded integration
  if (Icon) {
    return (
      <IconWrapper>
        <Icon size={size} />
      </IconWrapper>
    );
  }

  return <ConnectionIcon size={size} />;
});

PluginIcon.propTypes = {
  name: PropTypes.string,
  size: PropTypes.number,
  logo: PropTypes.shape(),
};

PluginIcon.defaultProps = {
  name: null,
  size: 24,
  logo: null,
};

export { PluginIcon };
