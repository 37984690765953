import PropTypes from '+prop-types';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import ReloadIcon from 'mdi-react/ReloadIcon';

import { actions as dashboardsActions } from '@/redux/api/dashboards';
import { actions as toastActions } from '@/redux/toast';

import IconButton from '+components/IconButton';
import { Row, Col } from '+components/Layout';

const isOutdatedError = (error) => (error?.message || '').startsWith(
  'The dashboard has been updated by another user',
);

const OutdatedError = ({ dashboardId, error }) => {
  const dispatch = useDispatch();

  const onReload = useCallback(
    () => {
      dispatch(toastActions.clearAll());
      dispatch(dashboardsActions.updateLayoutError({}));
      dispatch(dashboardsActions.fetchDashboard({ id: dashboardId }));
    },
    [dashboardId],
  );

  return (
    <Row wrap="nowrap" gap="2px">
      <Col xs={12} item>
        {error.message}
      </Col>

      <Col
        xs
        item
        alignItems="center"
        justifyContent="center"
      >
        <IconButton size="medium" title="Reload" onClick={onReload}>
          <ReloadIcon size={16} />
        </IconButton>
      </Col>
    </Row>
  );
};

OutdatedError.propTypes = {
  dashboardId: PropTypes.string.isRequired,
  error: PropTypes.shape({
    message: PropTypes.string,
  }).isRequired,
};

const errorDetails = (dashboardId) => (error) => (isOutdatedError(error) ? (
  <OutdatedError dashboardId={dashboardId} error={error} />
) : (
  error.message
));

export default errorDetails;
