import styled from 'styled-components';

export default styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  padding: 0;
  font-size: 13px;
  font-weight: 600;
  background: transparent;
  width: 100%;
  
  pointer-events: none;

  --color: ${({ invalid, theme }) => theme[invalid ? 'danger' : 'primary']};
  
  &.wizard__tab--active .wizard__tab-icon,
  &.wizard__tab--complete .wizard__tab-icon,
  &.wizard__tab--complete .wizard__tab-separator {
    background: var(--color);
  }
  
  &.wizard__tab--active .wizard__tab-title,
  &.wizard__tab--complete .wizard__tab-title {
    color: var(--color) !important;
    * { color: var(--color) !important; }
  }
  
  &.wizard__tab--disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.4;
  }
`;
