import PropTypes from '+prop-types';
import { Fragment } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import ContentCopyIcon from 'mdi-react/ContentCopyIcon';

import Field from '+components/form/FinalForm/Field';
import Description from '+components/form/FormField/components/Description';
import Group from '+components/form/FormField/components/Group';
import Label from '+components/form/FormField/components/Label';
import { normalizeSelectValue } from '+components/form/Normalizers';
import Plaintext from '+components/form/Plaintext';
import RoleAuthFieldsSection from '+components/form/RoleAuthFieldSection';
import SelectField from '+components/form/SelectField';
import TextField from '+components/form/TextField';
import { validateRequired } from '+components/form/Validators';
import * as toast from '+components/toast';
import useProviderRegions from '+hooks/useProviderRegions';

import RegenerateKeyButton from './RegenerateKeyButton';

const onCopy = (type) => {
  toast.success(`${type} copied to clipboard!`);
};

const OracleCosFields = ({ vpc, disabled }) => {
  const regionOptions = useProviderRegions('oracle');
  return (
    <Fragment>
      <Field
        name="region"
        label="Region"
        component={SelectField}
        validate={validateRequired}
        options={regionOptions}
        parse={normalizeSelectValue}
        helperText="Location of the source"
        disabled={disabled}
        required
      />

      <Field
        name="bucket"
        label="Bucket"
        component={TextField}
        type="text"
        maxLength={255}
        autoComplete="new-password"
        validate={validateRequired}
        helperText="The COS bucket name"
        disabled={disabled}
        required
      />

      <Field
        name="prefix"
        label="Prefix"
        component={TextField}
        type="text"
        maxLength={255}
        autoComplete="new-password"
        style={{ width: '50%' }}
        helperText="Folder prefix"
        disabled={disabled}
      />
      <RoleAuthFieldsSection label="Authentication">
        <Field
          name="userid"
          label="User OCID"
          component={TextField}
          type="text"
          validate={validateRequired}
          helperText="Oracle assigns each user a unique ID called an Oracle Cloud ID (OCID)"
          disabled={disabled}
          required
        />

        <Field
          name="tenancy"
          label="Tenancy OCID"
          component={TextField}
          type="text"
          validate={validateRequired}
          helperText="Every Oracle Cloud Infrastructure resource has an Oracle-assigned unique ID called an Oracle Cloud Identifier (OCID)"
          disabled={disabled}
          required
        />

        {vpc.id && (
          <Fragment>
            <Group>
              <Label>Fingerprint</Label>
              <Plaintext monospace>{vpc.fingerprint}</Plaintext>
              {vpc.fingerprint && (
                <Description style={{ cursor: 'pointer' }}>
                  <CopyToClipboard
                    text={vpc.fingerprint}
                    onCopy={() => onCopy('Fingerprint')}
                  >
                    <span
                      className="form__form-group-description"
                      style={{ marginLeft: '0px' }}
                    >
                      <ContentCopyIcon size={16} /> Copy to clipboard
                    </span>
                  </CopyToClipboard>
                </Description>
              )}
            </Group>

            <Group>
              <Label>Public Key</Label>
              <Plaintext monospace>{vpc.publickey}</Plaintext>
              <Description>
                {vpc.publickey && (
                  <div style={{ cursor: 'pointer' }}>
                    <CopyToClipboard
                      text={vpc.publickey}
                      onCopy={() => onCopy('Public key')}
                    >
                      <span
                        className="form__form-group-description"
                        style={{ marginLeft: '0px' }}
                      >
                        <ContentCopyIcon size={16} /> Copy to clipboard
                      </span>
                    </CopyToClipboard>
                    <br />
                    <br />
                  </div>
                )}
                <div
                  className="form__form-group-description"
                  style={{ marginLeft: '0px' }}
                >
                  Auto-generated PEM public key for use in the Oracle Console
                </div>
              </Description>
            </Group>

            <Group>
              <Label />
              <Plaintext>
                <RegenerateKeyButton vpc={vpc} />
              </Plaintext>
            </Group>
          </Fragment>
        )}
      </RoleAuthFieldsSection>
    </Fragment>
  );
};

OracleCosFields.propTypes = {
  vpc: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    flowtype: PropTypes.string.isRequired,
    flowresource: PropTypes.string.isRequired,
    fingerprint: PropTypes.string,
    publickey: PropTypes.string,
  }),
  disabled: PropTypes.bool,
};

OracleCosFields.defaultProps = {
  vpc: {
    fingerprint: '',
    publickey: '',
  },
  disabled: false,
};

export default OracleCosFields;
