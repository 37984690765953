/**
 * Added 1, 2, 3 and etc to the passed name until new name isn't unique
 * @example
 * ```
 * const newName = incName('test', ['test', 'test 1', 'test 2']);
 * // test 3
 * ```
 *
 * @param {string} name
 * @param {string[]} existNames
 * @return {string}
 */
export const incName = (name, existNames) => {
  let num = 1;
  let next = name;

  while (existNames?.includes(next)) {
    next = `${name} ${num}`;
    num += 1;
  }

  return next;
};
