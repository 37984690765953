import styled from 'styled-components';

export default styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding-right: 10px;
  font-size: 13px;
  font-weight: bold;
`;
