import PropTypes from '+prop-types';
import {
  memo, Fragment, useEffect, useRef, useCallback, useMemo,
} from 'react';
import * as HSX from 'react-jsx-highmaps';
import { BrowserRouter, Link } from 'react-router-dom';

import geojson from '@highcharts/map-collection/custom/world-lowres.geo.json';
import classNames from 'classnames';
import { renderToStaticMarkup } from 'react-dom/server';
import styled from 'styled-components';

import RoutePaths from '@/models/RoutePaths';

import Flag from '+components/Flag';
import SeverityLabel from '+components/Labels/SeverityLabel';
import { timestampFormatter } from '+components/Table/Cells/formatters';
import '+utils/proj4-module';
import useExportingFilename from '+hooks/useExportingFilename';

import useDefaultPropsHSX from './common/defaultPropsHSX';
import { labelWithDataRenderer } from './common/formatters';
import { Highmaps, pointClickEvent } from './common/highcharts';
import NoData from './common/NoData';
import { lang } from './common/utils';

export const includeFields = [
  'ipinfo',
  'algorithm',
  'timestamp',
  'severity',
  'description',
  'summary',
  'id',
];

const colorIndexMap = {
  high: 'high',
  medium: 'medium',
  low: 'low',
};

const extractData = (data, severities) => {
  if (!data?.length) {
    return [];
  }

  const tempData = data.filter((event) => {
    // make sure we have some geo
    if (!event.ipinfo || !Array.isArray(event.ipinfo) || !event.ipinfo[0]) {
      return false;
    }

    if (
      !severities
      || (severities.low && severities.medium && severities.high)
    ) {
      return true;
    }

    // filter severities accordingly
    return (
      (severities.low && event.severity === 'low')
      || (severities.medium && event.severity === 'medium')
      || (severities.high && event.severity === 'high')
    );
  });

  // determine which IPs are relevent
  // looking for: srcip is set OR srcip in factors is set
  const srcips = {}; // de-dup srcips
  tempData.forEach((event) => {
    (event.ipinfo || [])
      .filter(({ srcip }) => srcip)
      .forEach(({ ip }) => {
        srcips[ip] = event;
      });
  });

  // create a map of geos by IP
  const geoByIp = {};
  const geoByCC = {};
  tempData.forEach((event) => {
    event.ipinfo.forEach((ipinfo) => {
      geoByIp[ipinfo.ip] = ipinfo;

      if (!(ipinfo.geo?.countrycode in geoByCC)) {
        geoByCC[ipinfo.geo?.countrycode] = {
          low: 0,
          medium: 0,
          high: 0,
        };
      }
      geoByCC[ipinfo.geo?.countrycode][event.severity] += 1;
    });
  });

  // get geos for each relevent IP.
  const geoByIpArr = [];
  Object.keys(srcips).forEach((srcip) => {
    const ipintel = geoByIp[srcip];
    geoByIpArr.push({
      name: srcips[srcip].algorithm,
      event: srcips[srcip],
      ipintel,
      lat: ipintel.geo?.location.lat,
      lon: ipintel.geo?.location.lon,
      country: ipintel.geo?.countrycode,
      colorIndex: colorIndexMap[srcips[srcip].severity] ?? colorIndexMap.low,
      className: 'highcharts-map-marker',
    });
  });

  // TODO: De-Dup array by lat/long combo
  const dedupKeys = ['lat', 'lon'];
  const geoByIpArrDeDuped = geoByIpArr.filter(
    (
      (s) => (o) => ((k) => !s.has(k) && s.add(k))(dedupKeys.map((k) => o[k]).join('|'))
    )(new Set()),
  );

  return geoByIpArrDeDuped.slice(0, 1000);
};

function dataLabelFormatter() {
  return labelWithDataRenderer({
    data: this.point.name,
  });
}

function pointFormatter() {
  // eslint-disable-line object-shorthand
  const { event, ipintel } = this;
  return renderToStaticMarkup(
    <Fragment>
      <span className="timestamp">{timestampFormatter(event.timestamp)}</span>
      <span className="severity">
        <SeverityLabel severity={event.severity} />
      </span>
      {ipintel.geo.countrycode && (
        <span className="countrycode">
          <Flag countryCode={ipintel.geo.countrycode} />
          <span>{ipintel.geo.city}</span>
          <span>{ipintel.geo.subdiso}</span>
          <span>{ipintel.geo.countrycode}</span>
        </span>
      )}
      <span className="description">{event.description}</span>
      <span className="summary">{event.summary}</span>
      <BrowserRouter>
        <span className="link">
          <Link
            to={`${RoutePaths.searchEvents}/${event.id}${
              event.customer ? `?customer=${event.customer}` : ''
            }`}
          >
            Event Details
          </Link>
        </span>
      </BrowserRouter>
    </Fragment>,
  );
}

const mapSeriesData = [];
const mapSeriesJoinBy = ['iso-a2', 'key'];

const EventWorldMap = styled((props) => {
  const {
    className,
    title,
    subtitle,
    series,
    severities,
    loading,
    width,
    height,
    exporting,
    hideTitle,
    hideSubtitle,
  } = props;

  const chartRef = useRef(null);

  const exportingFilename = useExportingFilename(title);

  const defaultProps = useDefaultPropsHSX({ exporting });

  const normalizedSeries = extractData(series, severities);

  const onChartCallback = useCallback(
    (chart) => {
      chartRef.current = chart;
    },
    [],
  );

  useEffect(
    () => {
      const chart = chartRef.current;
      if (chart && !chart?.fullscreen?.isOpen) {
        chart.setSize(width, height, false);
      }
    },
    [width, height],
  );

  useEffect(
    () => {
      const chart = chartRef.current;
      if (!chart?.container) {
        return undefined;
      }

      // Add mouseleave event (hide tooltips)
      const onMouseLeave = (event) => {
        const { target } = event;
        if (
          target.classList.contains('highcharts-container')
        || target.classList.contains('highcharts-tooltip')
        ) {
          chart.tooltip.hide(true);
        }
      };

      chart.container.addEventListener('mouseleave', onMouseLeave, true);

      return () => {
        if (chart?.container) {
          chart.container.removeEventListener('mouseleave', onMouseLeave, true);
        }
      };
    },
    [chartRef.current],
  );

  // Dynamically set export file name (for cases when chart title changing dynamically in widgets)
  // @see: https://api.highcharts.com/highcharts/exporting.filename
  // @see: https://www.highcharts.com/forum/viewtopic.php?t=31299
  useEffect(
    () => {
      const chart = chartRef.current;
      if (chart) {
        chart.options.exporting.filename = exportingFilename;
      }
    },
    [chartRef.current, exportingFilename],
  );

  // Memoize properties
  const dataLabelsProps = useMemo(
    () => ({
      enabled: true,
      useHTML: true,
      formatter: dataLabelFormatter,
    }),
    [],
  );

  const markerProps = useMemo(
    () => ({
      enabled: true,
      symbol: 'triangle-down',
    }),
    [],
  );

  const pointProps = useMemo(
    () => ({
      events: {
        click: pointClickEvent,
      },
    }),
    [],
  );

  const tooltipProps = useMemo(
    () => ({
      shadow: false,
      headerFormat: '',
      pointFormatter,
    }),
    [],
  );

  return (
    <HSX.HighmapsProvider Highcharts={Highmaps}>
      <HSX.HighchartsMapChart
        {...defaultProps}
        className={classNames(className, 'alerts-worldmap-chart', {
          'alerts-worldmap-chart__with-title': !!title && !hideTitle,
          short: width > 360 && height < 180,
          ultrashort: width <= 360 && height < 180,
        })}
        map={geojson}
        colorAxis={{
          min: 1,
          max: 1000,
        }}
        chart={{
          ...defaultProps.chart,
          panning: {
            enabled: true,
            type: 'xy',
          },
          alignTicks: false,
          events: {
            click() {
              this.tooltip.hide(true);
            },
          },
        }}
        callback={onChartCallback}
      >
        {!!title && !hideTitle && (
          <HSX.Title align="left" useHTML>
            {title}
          </HSX.Title>
        )}

        {!!subtitle && !hideSubtitle && (
          <HSX.Subtitle useHTML>{subtitle}</HSX.Subtitle>
        )}

        <HSX.Legend enabled={false} />

        <HSX.Tooltip
          useHTML
          borderRadius={8}
          shadow={false}
          animation={false}
        />

        <HSX.MapNavigation buttonOptions={{ alignTo: 'spacingBox' }}>
          <HSX.MapNavigation.ZoomIn />
          <HSX.MapNavigation.ZoomOut />
        </HSX.MapNavigation>

        <HSX.MapSeries
          name="Severity"
          enableMouseTracking={false}
          visible
          data={mapSeriesData}
          // Uncomment this if you want to colorize countries
          // data={geoBySeveritySeries}
          joinBy={mapSeriesJoinBy}
          nullColor={normalizedSeries.length ? '#ccc' : 'transparent'}
          borderColor={normalizedSeries.length ? '#eee' : '#767676'}
          animation={false}
          reflow={false}
          shadow={false}
        />

        {normalizedSeries.length ? (
          <HSX.MapPointSeries
            name="TDMs"
            data={normalizedSeries}
            type="mappoint"
            colorKey="clusterPointsAmount"
            dataLabels={dataLabelsProps}
            marker={markerProps}
            point={pointProps}
            tooltip={tooltipProps}
            animation={false}
            visible
          />
        ) : (
          // We need this fake series to display axis when there is no data
          // @see: https://netography.atlassian.net/browse/PORTAL-1336
          <NoData $plotBox={chartRef.current?.plotBox}>
            {loading ? lang.loading : lang.noData}
          </NoData>
        )}
      </HSX.HighchartsMapChart>
    </HSX.HighmapsProvider>
  );
})`
  position: relative;

  .highcharts-map-navigation {
    font-size: 1.3em !important;
    font-weight: bold !important;
    cursor: pointer !important;

    &.highcharts-zoom-in {
      text {
        transform: translateX(4px) !important;
      }
    }

    &.highcharts-zoom-out {
      text {
        transform: translateX(6px) !important;
      }
    }
  }

  .highcharts-mappoint-series .highcharts-point {
    cursor: pointer;
  }

  .highcharts-tooltip {
    pointer-events: auto; // we need this for links work in map tooltip
  }

  .highcharts-tooltip > span {
    display: flex;
    flex-direction: column;

    > span + span {
      margin-top: 8px;
    }

    span:empty {
      display: none;
    }

    .severity div {
      margin-left: unset;
    }

    .countrycode {
      display: flex;
      align-items: center;
      img + span,
      span + span {
        margin-left: 4px;
      }
    }

    .description {
      font-weight: bold;
    }

    .summary {
      white-space: break-spaces;
      margin-top: unset !important;
    }
  }

  .label-series__body {
    cursor: pointer;
  }
`;

EventWorldMap.propTypes = {
  /**
   * Override or extend the styles applied to the component.
   */
  className: PropTypes.string,
  /**
   * Chart title.
   */
  title: PropTypes.string,
  /**
   * Chart subtitle.
   */
  subtitle: PropTypes.string,
  /**
   * Chart series.
   */
  series: PropTypes.arrayOf(PropTypes.shape({})),
  /**
   * Which severities to display.
   */
  severities: PropTypes.shape({}),
  /**
   * If true, loading overlay will be active.
   */
  loading: PropTypes.bool,
  /**
   * Chart width.
   */
  width: PropTypes.number,
  /**
   * Chart height.
   */
  height: PropTypes.number,
  /**
   * If true, exporting mode on.
   */
  exporting: PropTypes.bool,
  /**
   * If true, chart title will be hidden.
   */
  hideTitle: PropTypes.bool,
  /**
   * If true, chart subtitle will be hidden.
   */
  hideSubtitle: PropTypes.bool,
};

EventWorldMap.defaultProps = {
  className: undefined,
  title: undefined,
  subtitle: undefined,
  series: [],
  severities: undefined,
  loading: false,
  width: undefined,
  height: undefined,
  exporting: true,
  hideTitle: false,
  hideSubtitle: false,
};

export default memo(EventWorldMap);
