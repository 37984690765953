/* eslint-disable react/jsx-indent */
import PropTypes from '+prop-types';
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useFlag } from '@unleash/proxy-client-react';
import styled from 'styled-components';

import PermissionModel from '@/models/Permission';
import RoutePaths from '@/models/RoutePaths';

import { selectors as responseIntegrationsSelectors } from '@/redux/api/integrations/response';
import {
  actions as rulesActions,
  selectors as rulesSelectors,
} from '@/redux/api/rules';

import ConfirmModal from '+components/ConfirmModal';
import EmphasizedCode from '+components/EmphasizedCode';
import CheckBox from '+components/form/CheckBox';
import { Field, FormSpy } from '+components/form/FinalForm';
import FieldContainer from '+components/form/FormField/components/FieldContainer';
import Group from '+components/form/FormField/components/Group';
import Label from '+components/form/FormField/components/Label';
import MultiSelectField from '+components/form/MultiSelectField';
import { normalizeMultiSelectValue } from '+components/form/Normalizers';
import TextField from '+components/form/TextField';
import { validateRequired } from '+components/form/Validators';
import FormWizard, { Step } from '+components/FormWizard';
import AlertTypeLabel from '+components/Labels/AlertTypeLabel';
import SeverityLabel from '+components/Labels/SeverityLabel';
import usePermissions from '+hooks/usePermissions';
import sortBySystemAndValue from '+utils/sortBySystemAndValue';

import { rulesUItoParams } from './Rules';

const sortByAdapterAndName = (a, b) => {
  if (a?.adapter < b?.adapter) {
    return -1;
  }

  if (a?.adapter !== b?.adapter) {
    return 1;
  }

  return (a?.name || '')
    .toLowerCase()
    .localeCompare((b?.name || '').toLowerCase());
};

const FieldContainerStyled = styled(FieldContainer)`
  align-items: center;
  gap: 5rem;
  label {
    min-width: 16ch;
    margin-bottom: 0;
  }
`;

const HelperTextContainer = styled.div`
  margin-top: 3px;
`;

const detectionCategoriesHelperText = 'Multiple Detection Categories match with an OR condition';
const detectionModelsHelperText = 'Multiple Detection Models match with an OR condition, however they match with Categories with an AND condition.';
const trackByHelperText = (
  <HelperTextContainer>
    Enter values that will match one of: [<EmphasizedCode>dstip</EmphasizedCode>
    ,<EmphasizedCode>flowsrcname</EmphasizedCode>,
    <EmphasizedCode>input</EmphasizedCode>,
    <EmphasizedCode>output</EmphasizedCode>,
    <EmphasizedCode>srcip</EmphasizedCode>
    ]. Leave empty to match everything.
  </HelperTextContainer>
);

const RuleForm = (props) => {
  const {
    mode,
    ruleId,
    initialValues,
    loading,
    updateCallback,
    createCallback,
  } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isDnsEnabled = useFlag('DNS');

  const rules = useSelector(rulesSelectors.getState);
  const { plugins } = useSelector(responseIntegrationsSelectors.getState);
  const permissions = usePermissions(
    PermissionModel.Resources.response_policy.value,
  );

  const [submitMode, setSubmitMode] = useState(mode);
  const [formValues, setFormValues] = useState({});
  // Disable Algorithms if Categories exist, and visa versa.
  const [isCategoriesDisabled, setCategoriesDisabled] = useState(false);
  const [isAlgorithmsDisabled, setAlgorithmsDisabled] = useState(false);
  // track_by option handling
  const [trackByOptions] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const canManage = submitMode === 'create' ? permissions?.create : permissions?.update;
  const canRemove = submitMode !== 'create' && permissions?.delete;

  const categories = useMemo(
    () => Object.values(rules.categories || {})
      .map((el) => ({
        ...el,
        value: el.name,
        label: el.name,
        description: el.description,
      }))
      .sort(sortBySystemAndValue),
    [rules.categories],
  );

  const algorithms = useMemo(
    () => Object.values(rules.algorithms || {})
      .map((el) => ({
        ...el,
        value: el.name,
        label: el.name,
      }))
      .sort(sortBySystemAndValue),
    [rules.algorithms],
  );

  const integrations = useMemo(
    () => Object.values(plugins || {})
      .map((el) => ({ ...el, value: el.id, label: el.name }))
      .sort(sortByAdapterAndName),
    [plugins],
  );

  const onSubmit = useCallback(
    (UIvalues) => {
      // converted flattened UI object to nested API object
      const values = rulesUItoParams(UIvalues);
      // console.log("values", values);
      if ('update' === submitMode) {
        updateCallback(values);
      } else {
        createCallback(values);
      }
    },
    [submitMode, updateCallback, createCallback],
  );

  const onCancel = useCallback(
    () => {
      navigate(`${RoutePaths.responsePolicies}`);
    },
    [],
  );

  const onCopy = useCallback(
    () => {
      setSubmitMode(submitMode === 'create' ? 'update' : 'create');
    },
    [submitMode],
  );

  const onDeleteModalToggle = useCallback(
    () => {
      setShowDeleteModal((prevValue) => !prevValue);
    },
    [],
  );

  const onDelete = useCallback(
    () => {
      dispatch(rulesActions.deleteRule(ruleId));
      onCancel();
    },
    [ruleId, onCancel],
  );

  const additionalActions = useMemo(
    () => (permissions?.create
      ? [
        {
          text: `Switch to ${
            submitMode === 'create' ? 'Update' : 'Create'
          }`,
          onClick: updateCallback ? onCopy : () => {},
        },
      ]
      : undefined),
    [permissions, submitMode, updateCallback, onCopy],
  );

  // load dependencies for the dropdown fields (algorithms, categories & plugins)
  useEffect(
    () => {
      dispatch(rulesActions.fetchDependencies());
    },
    [],
  );

  useEffect(
    () => {
      if ((formValues.UIalgorithms || []).length > 0) {
        setCategoriesDisabled(true);
        setAlgorithmsDisabled(false);
      } else {
        setCategoriesDisabled(false);
      }
    },
    [formValues.UIalgorithms],
  );

  useEffect(
    () => {
      if ((formValues.UIcategories || []).length > 0) {
        setCategoriesDisabled(false);
        setAlgorithmsDisabled(true);
      } else {
        setAlgorithmsDisabled(false);
      }
    },
    [formValues.UIcategories],
  );

  const timer = useRef();
  const onChange = useCallback(
    ({ values }) => {
      timer.current = setTimeout(() => {
        setFormValues(values);
      }, 10);
    },
    [],
  );

  useEffect(
    () => () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    },
    [],
  );

  return (
    <Fragment>
      <FormWizard
        initialValues={initialValues}
        onSubmit={onSubmit}
        onCancel={onCancel}
        additionalActions={props.updateCallback ? additionalActions : undefined}
        confirmButtonText={submitMode === 'create' ? 'Create' : 'Update'}
        loading={loading}
        disabled={!canManage}
        deleteButtonText="Delete Response Policy"
        onDelete={onDeleteModalToggle}
        deleteButtonHidden={submitMode === 'create'}
        deleteButtonDisabled={!canRemove}
      >
        <Step
          title={`${submitMode === 'create' ? 'Add' : 'Edit'} Response Policy`}
        >
          <FormSpy subscription={{ values: true }} onChange={onChange} />

          <Field
            name="name"
            label="Policy Name"
            component={TextField}
            type="text"
            autoComplete="new-password"
            style={{ minWidth: '100px', width: '100%', maxWidth: '200px' }}
            validate={validateRequired}
            disabled={!canManage}
            required
          />

          <Group>
            <FieldContainer>
              <Field
                name="enabled"
                label="Enabled"
                type="checkbox"
                component={CheckBox}
                disabled={!canManage}
              />
            </FieldContainer>
          </Group>

          <Field
            name="description"
            label="Description"
            component={TextField}
            type="text"
            maxLength={255}
            autoComplete="new-password"
            disabled={!canManage}
          />

          <Field
            name="UIcategories"
            label="Detection Categories"
            component={MultiSelectField}
            options={categories}
            groupBy={(item) => (item.system ? 'System Categories' : 'Custom Categories')}
            helperText={detectionCategoriesHelperText}
            parse={normalizeMultiSelectValue}
            disabled={!canManage || isCategoriesDisabled}
          />

          <Field
            name="UIalgorithms"
            label={`${isDnsEnabled ? '' : 'Network '}Detection Models`}
            component={MultiSelectField}
            options={algorithms}
            groupBy={(item) => (item.system
              ? 'System Detection Models'
              : 'Custom Detection Models')}
            helperText={detectionModelsHelperText}
            parse={normalizeMultiSelectValue}
            disabled={!canManage || isAlgorithmsDisabled}
          />

          <Field
            name="UItrack"
            label="Track By"
            component={MultiSelectField}
            options={trackByOptions}
            helperText={trackByHelperText}
            parse={normalizeMultiSelectValue}
            disabled={!canManage}
            allowCreate
          />

          <Field
            name="UIplugins"
            label="Integrations"
            component={MultiSelectField}
            options={integrations}
            groupBy={(item) => item.adapter}
            parse={normalizeMultiSelectValue}
            disabled={!canManage}
          />

          <Group style={{ alignItems: 'center' }}>
            <Label>Severities</Label>
            <FieldContainerStyled row>
              <Field
                name="UIseverity-high"
                component={CheckBox}
                type="checkbox"
                label={<SeverityLabel severity="high" />}
                disabled={!canManage}
              />

              <Field
                name="UIseverity-medium"
                label={<SeverityLabel severity="medium" />}
                component={CheckBox}
                type="checkbox"
                disabled={!canManage}
              />

              <Field
                name="UIseverity-low"
                label={<SeverityLabel severity="low" />}
                component={CheckBox}
                type="checkbox"
                disabled={!canManage}
              />
            </FieldContainerStyled>
          </Group>

          <Group style={{ alignItems: 'center' }}>
            <Label>Alert Types</Label>
            <FieldContainerStyled row>
              <Field
                name="UIalerttype-start"
                label={<AlertTypeLabel alerttype="start" active />}
                component={CheckBox}
                type="checkbox"
                disabled={!canManage}
              />

              <Field
                name="UIalerttype-ongoing"
                label={<AlertTypeLabel alerttype="ongoing" active />}
                component={CheckBox}
                type="checkbox"
                disabled={!canManage}
              />

              <Field
                name="UIalerttype-end"
                label={<AlertTypeLabel alerttype="end" active />}
                component={CheckBox}
                type="checkbox"
                disabled={!canManage}
              />
            </FieldContainerStyled>
          </Group>
        </Step>
      </FormWizard>

      {showDeleteModal && (
        <ConfirmModal
          item={initialValues?.name}
          onToggle={onDeleteModalToggle}
          onConfirm={onDelete}
          isOpen
        />
      )}
    </Fragment>
  );
};

RuleForm.propTypes = {
  createCallback: PropTypes.func,
  updateCallback: PropTypes.func,
  mode: PropTypes.oneOf(['create', 'update']),
  initialValues: PropTypes.shape({
    name: PropTypes.string,
    UIplugins: PropTypes.arrayOf(PropTypes.string),
    'UIseverity-high': PropTypes.bool,
    'UIseverity-medium': PropTypes.bool,
    'UIseverity-low': PropTypes.bool,
    'UIalerttype-start': PropTypes.bool,
    'UIalerttype-ongoing': PropTypes.bool,
    'UIalerttype-end': PropTypes.bool,
    enabled: PropTypes.bool,
  }),
  loading: PropTypes.bool,
  ruleId: PropTypes.string,
};

RuleForm.defaultProps = {
  mode: 'create',
  createCallback: () => {},
  updateCallback: null, // null so we can switch the UI accordingly
  initialValues: {},
  loading: false,
  ruleId: null,
};

export default RuleForm;
