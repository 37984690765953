import PropTypes from '+prop-types';
import { Fragment } from 'react';

import styled from 'styled-components';

import CheckBox from '+components/form/CheckBox';
import Field from '+components/form/FinalForm/Field';
import FieldContainer from '+components/form/FormField/components/FieldContainer';
import Group from '+components/form/FormField/components/Group';
import Label from '+components/form/FormField/components/Label';
import { normalizeSlackChannelNameValue } from '+components/form/Normalizers';
import TextField from '+components/form/TextField';
import { validateRequired, validateUrl } from '+components/form/Validators';

const Dash = styled.span`
  position: absolute;
  top: 4px;
  left: 130px;
`;

const SlackFields = ({ disabled }) => (
  <Fragment>
    <Field
      name="name"
      label="Name"
      component={TextField}
      type="text"
      maxLength={255}
      autoComplete="new-password"
      validate={validateRequired}
      style={{ width: '50%' }}
      disabled={disabled}
      required
    />

    <Field
      name="description"
      label="Description"
      component={TextField}
      type="text"
      maxLength={255}
      autoComplete="new-password"
      disabled={disabled}
    />

    <Field
      name="config.url"
      label="URL"
      component={TextField}
      type="text"
      autoComplete="new-password"
      validate={[validateRequired, validateUrl]}
      disabled={disabled}
      required
    />

    <Group>
      <Label disabled={disabled} required>
        Channel
      </Label>
      <FieldContainer row>
        <Dash>#</Dash>
        <Field
          name="config.channel"
          component={TextField}
          type="text"
          style={{ width: '50%' }}
          autoComplete="new-password"
          validate={validateRequired}
          parse={normalizeSlackChannelNameValue}
          disabled={disabled}
          required
        />
      </FieldContainer>
    </Group>

    <Group>
      <FieldContainer>
        <Field
          name="config.short"
          label="Display messages in a shorter style"
          component={CheckBox}
          type="checkbox"
          disabled={disabled}
        />
      </FieldContainer>
    </Group>
  </Fragment>
);

SlackFields.propTypes = {
  disabled: PropTypes.bool,
};

SlackFields.defaultProps = {
  disabled: false,
};

export default SlackFields;
