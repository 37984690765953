import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import dayjs from 'dayjs';

import { selectors as customerSelectors } from '@/redux/api/customer';
import {
  actions as deviceActions,
  selectors as deviceSelectors,
} from '@/redux/api/device';
import {
  actions as vpcActions,
  selectors as vpcSelectors,
} from '@/redux/api/vpc';

import useCustomerSubscriptionTypes from '+hooks/useCustomerSubscriptionTypes';

// Date at which new trials created will have the # of devices enforced.
const MAX_SOURCES_ENFORCEMENT_DATE = dayjs('2022-09-21T00:00:00.000Z');

const useMaxSources = () => {
  const dispatch = useDispatch();
  const customer = useSelector(customerSelectors.getCurrentCustomer);
  const deviceData = useSelector(deviceSelectors.getDevices);
  const vpcData = useSelector(vpcSelectors.getVpcs);

  const customerSubscriptionTypes = useCustomerSubscriptionTypes();

  useEffect(
    () => {
      dispatch(deviceActions.devicesStatus());
      dispatch(deviceActions.devicesFetch());
      dispatch(vpcActions.fetchVpcs());
    },
    [],
  );

  return useMemo(
    () => {
      const customerSubscriptionTypeMeta = customerSubscriptionTypes[customer?.subscriptionType];
      const enforceMaxSources = customerSubscriptionTypeMeta?.trafficSources != null
      && dayjs(customer?.created).isAfter(MAX_SOURCES_ENFORCEMENT_DATE);
      const currentSources = Object.keys(vpcData || {}).length + Object.keys(deviceData || {}).length;
      const sourcesRemaining = Math.max(
        0,
        (customerSubscriptionTypeMeta?.trafficSources || 0) - currentSources,
      );
      return {
        enforceMaxSources,
        sourcesRemaining,
        maxDownsample: customerSubscriptionTypeMeta?.downsample,
      };
    },
    [
      customerSubscriptionTypes,
      customer?.subscriptionType,
      vpcData,
      deviceData,
    ],
  );
};

export default useMaxSources;
