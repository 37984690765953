import { Fragment, useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch } from 'react-router-dom';
import { useToggle } from 'react-use';

import LeadPencilIcon from 'mdi-react/LeadPencilIcon';
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';
import UndoIcon from 'mdi-react/UndoIcon';

import LabelContextTypes from '@/models/LabelContextTypes';
import PermissionModel from '@/models/Permission';
import RoutePaths from '@/models/RoutePaths';

import { selectors as customerSelectors } from '@/redux/api/customer';
import {
  actions as portLabelsActions,
  splitId,
} from '@/redux/api/labels/ports';

import Button, { ButtonVariants } from '+components/Button';
import ConfirmModal from '+components/ConfirmModal';
import { ActionsContainer } from '+components/Layout';
import NoDataPage from '+components/NoDataPage';
import Table from '+components/Table';
import { MenuColumnContextMenu } from '+components/Table/Columns';
import {
  createFilterValue,
  FilterOperator,
} from '+components/Table/FilterTypeFactories';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import usePermissions from '+hooks/usePermissions';
import usePortLabels from '+hooks/usePortLabels';
import { getContextType } from '+utils/labels';

import { Columns, getColumns } from './components/columns';
import ImportCsvForm from './components/ImportCsvForm';
import PortLabelForm from './components/PortLabelForm';

const tableId = 'PortLabels';
const sortBy = [{ id: 'port', desc: false }];
const defaultPortLabel = {
  port: '',
  protocol: '',
  context: 'name',
  labels: [],
};

const PortLabels = () => {
  const dispatch = useDispatch();

  const matchPort = useMatch(`${RoutePaths.labelsPort}/:port`);
  const matchProtocol = useMatch(`${RoutePaths.labelsPort}/protocol/:protocol`);
  const matchLabel = useMatch(`${RoutePaths.labelsIp}/label/:label`);

  const customer = useSelector(customerSelectors.getCurrentCustomer);
  const { portLabels, isFetchingPortLabels } = usePortLabels({
    fetchAll: true,
  });
  const permissions = usePermissions(PermissionModel.Resources.label.value);

  const [showImportCsvModal, toggleImportCsvModal] = useToggle(false);
  const [portLabelToManage, setPortLabelToManage] = useState(null);
  const [portLabelToReset, setPortLabelToReset] = useState(null);
  const [portLabelToDelete, setPortLabelToDelete] = useState(null);
  const [showBulkDeleteModal, toggleBulkDeleteModal] = useToggle(false);

  const [selected, setSelected] = useState([]);

  useLoadingIndicator(isFetchingPortLabels);

  const onPortLabelVisibilityToggle = useCallback(
    (row, show) => {
      if (show) {
        dispatch(portLabelsActions.showPortLabel(row));
      } else {
        dispatch(portLabelsActions.hidePortLabel(row));
      }
    },
    [],
  );

  const cxActionMenu = useCallback(
    (id, original) => {
      const contextType = getContextType(original);

      const items = [
        {
          icon: <LeadPencilIcon />,
          text: 'Edit',
          onClick: () => setPortLabelToManage(original),
        },
        {
          icon: <TrashCanOutlineIcon />,
          text: 'Delete',
          disabled:
            !permissions?.delete || contextType !== LabelContextTypes.custom,
          onClick: () => setPortLabelToDelete(original),
        },
        contextType === LabelContextTypes.customized && {
          icon: <UndoIcon />,
          text: 'Reset Customization',
          disabled: !permissions?.update,
          onClick: () => setPortLabelToReset(original),
        },
      ].filter(Boolean);

      return (
        <MenuColumnContextMenu
          title={original.port}
          items={items}
          dataTracking="port-label"
        />
      );
    },
    [permissions],
  );

  const tableColumns = useMemo(
    () => {
      const preparedColumns = Object.values(Columns);
      return getColumns(preparedColumns, {
        onVisibilityToggle: onPortLabelVisibilityToggle,
        permissions,
        cxActionMenu,
      });
    },
    [permissions, onPortLabelVisibilityToggle, cxActionMenu],
  );

  const tableFilters = useMemo(
    () => {
      const result = [];
      if (matchPort?.params?.port) {
        result.push({
          id: 'port',
          value: createFilterValue({
            value: matchPort?.params?.port,
            operator: FilterOperator.equal,
          }),
        });
      }
      if (matchProtocol?.params?.protocol) {
        result.push({
          id: 'protocol',
          value: createFilterValue({
            value: matchProtocol?.params?.protocol,
            operator: FilterOperator.equal,
          }),
        });
      }
      if (matchLabel?.params?.label) {
        result.push({
          id: 'labels',
          value: createFilterValue({
            value: matchLabel?.params?.label,
            operator: FilterOperator.like,
          }),
        });
      }
      return result;
    },
    [
      matchPort?.params?.port,
      matchProtocol?.params?.protocol,
      matchLabel?.params?.label,
    ],
  );

  const tableData = useMemo(
    () => Object.values(portLabels || {}).filter((item) => {
      if (!item.customer) {
        return true;
      }
      return item.customer === customer?.shortname;
    }),
    [portLabels, customer?.shortname],
  );

  const onPortLabelImportSubmit = useCallback(
    (values) => {
      toggleImportCsvModal();
      dispatch(portLabelsActions.bulkUploadFile(values));
    },
    [],
  );

  const onPortLabelAddToggle = useCallback(
    (event) => {
      if (!event) {
        return;
      }
      setPortLabelToManage((prevValue) => (prevValue ? null : defaultPortLabel));
    },
    [defaultPortLabel],
  );

  const onPortLabelAddSubmit = useCallback(
    (values) => {
      setPortLabelToManage(null);

      if (values.id) {
        dispatch(portLabelsActions.updatePortLabel(values));
      } else {
        dispatch(portLabelsActions.createPortLabel(values));
      }
    },
    [],
  );

  const onPortLabelResetToggle = useCallback(
    () => {
      setPortLabelToReset((prevValue) => (prevValue ? null : portLabelToManage));
    },
    [portLabelToManage],
  );

  const onPortLabelResetSubmit = useCallback(
    () => {
      dispatch(portLabelsActions.resetPortLabel(portLabelToReset));
      setPortLabelToReset(null);
      setPortLabelToManage(null);
    },
    [portLabelToReset],
  );

  const onPortLabelDeleteToggle = useCallback(
    () => {
      setPortLabelToDelete((prevValue) => (prevValue ? null : portLabelToManage));
    },
    [portLabelToManage],
  );

  const onPortLabelDeleteSubmit = useCallback(
    () => {
      dispatch(portLabelsActions.removePortLabel(portLabelToDelete));
      setPortLabelToDelete(null);
      setPortLabelToManage(null);
    },
    [portLabelToDelete],
  );

  const onDeleteAllSelection = useCallback(
    () => {
      toggleBulkDeleteModal();
      const removeData = selected.map((item) => {
        const { port, protocol } = splitId(item);
        return { port, protocol };
      });
      if (removeData.length) {
        dispatch(portLabelsActions.bulkRemovePortLabel(removeData));
      }
    },
    [selected],
  );

  const onSelectedRowsChange = useCallback(
    (selectedRowIds) => {
      setSelected((prev) => {
        const next = Object.entries(selectedRowIds || {})
          .map(([key, value]) => (value ? key : null))
          .filter(Boolean);

        if (!prev.length && !next.length) {
          return prev;
        }

        return next;
      });
    },
    [],
  );

  const getIsRowSelectorDisabled = useCallback(
    () => !permissions?.delete,
    [permissions],
  );

  return (
    <Fragment>
      <ActionsContainer>
        <Button onClick={onPortLabelAddToggle} disabled={!permissions?.create}>
          Add Label
        </Button>

        <Button
          variant={ButtonVariants.outlined}
          onClick={toggleImportCsvModal}
          disabled={!permissions?.create}
        >
          Import CSV
        </Button>

        <Button
          variant={ButtonVariants.outlined}
          onClick={toggleBulkDeleteModal}
          disabled={!permissions?.delete || !selected.length}
        >
          Delete/Reset Selected
        </Button>
      </ActionsContainer>

      {!tableData.length && (
        <NoDataPage
          image="/assets/graphics-ip_labels-bw.png"
          imageOffsetY={5}
          noDataText="No port labels created"
          actionButtonText="Add Port Labels"
          loading={isFetchingPortLabels}
        />
      )}

      {!!tableData.length && (
        <Table
          id={tableId}
          columns={tableColumns}
          data={tableData}
          sortBy={sortBy}
          filters={tableFilters}
          getIsRowSelectorDisabled={getIsRowSelectorDisabled}
          onSelectedRowsChange={onSelectedRowsChange}
        />
      )}

      {showImportCsvModal && (
        <ImportCsvForm
          initialValues={{}}
          onToggle={toggleImportCsvModal}
          onSubmit={onPortLabelImportSubmit}
          isOpen
        />
      )}

      {!!portLabelToManage && (
        <PortLabelForm
          initialValues={portLabelToManage}
          onToggle={onPortLabelAddToggle}
          onSubmit={onPortLabelAddSubmit}
          onDelete={
            getContextType(portLabelToManage) === LabelContextTypes.customized
              ? onPortLabelResetToggle
              : onPortLabelDeleteToggle
          }
          onVisibilityToggle={onPortLabelVisibilityToggle}
          isOpen
        />
      )}

      {!!portLabelToReset && (
        <ConfirmModal
          item={`labels for ${portLabelToReset.port}`}
          confirmButtonText="Reset"
          whyAsking="This will undo any customization for this system label and restore the system default values."
          onToggle={onPortLabelResetToggle}
          onConfirm={onPortLabelResetSubmit}
          toggleOnConfirm={false}
          isOpen
        />
      )}

      {!!portLabelToDelete && (
        <ConfirmModal
          item={`labels for ${portLabelToDelete.port}`}
          onToggle={onPortLabelDeleteToggle}
          onConfirm={onPortLabelDeleteSubmit}
          toggleOnConfirm={false}
          isOpen
        />
      )}

      {showBulkDeleteModal && (
        <ConfirmModal
          item={`${selected.length} Port label${
            selected.length > 1 ? 's' : ''
          }`}
          confirmButtonText="delete/reset"
          onToggle={toggleBulkDeleteModal}
          onConfirm={onDeleteAllSelection}
          toggleOnConfirm={false}
          isOpen
        />
      )}
    </Fragment>
  );
};

export { PortLabelForm };

export default PortLabels;
