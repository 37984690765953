import PropTypes from '+prop-types';
import { useMemo } from 'react';

import classNames from 'classnames';

import Body from './components/Body';
import Container from './components/Container';
import Image from './components/Image';
import Text from './components/Text';
import TextContainer from './components/TextContainer';

/**
 * Component to display no data message for no data pages
 */
const NoDataPage = (props) => {
  const {
    image,
    imageOffsetY,
    noDataText,
    actionButtonText,
    loading,
  } = props;

  const normalizedNoDataText = useMemo(
    () => (noDataText && noDataText.substr(-1) !== '.' ? `${noDataText}.` : noDataText),
    [noDataText],
  );

  return (
    <Container className={classNames({ 'no-data-page__loading': loading })}>
      <Body>
        {image && (
          <Image
            className="no-data-page__image"
            image={image}
            imageOffsetY={imageOffsetY}
          />
        )}
        {(noDataText || actionButtonText) && (
          <TextContainer className="no-data-page__text-container">
            {normalizedNoDataText && <Text>{normalizedNoDataText}</Text>}
            {actionButtonText && <Text>Click the <b>{actionButtonText}</b> button to get started.</Text>}
          </TextContainer>
        )}
      </Body>
    </Container>
  );
};

NoDataPage.propTypes = {
  /**
   * No data page image url
   */
  image: PropTypes.string,
  /**
   * Image Y offset
   */
  imageOffsetY: PropTypes.number,
  /**
   * No data page text
   */
  noDataText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  /**
   * Action button text
   */
  actionButtonText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  /**
   * If true, loading style will be active.
   */
  loading: PropTypes.bool,
};

NoDataPage.defaultProps = {
  image: undefined,
  imageOffsetY: undefined,
  noDataText: undefined,
  actionButtonText: undefined,
  loading: false,
};

export default NoDataPage;
