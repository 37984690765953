/* eslint-disable jsx-a11y/label-has-for */
import PropTypes from '+prop-types';
import { PureComponent } from 'react';

import classNames from 'classnames';

import CheckIcon from 'mdi-react/CheckIcon';
import CloseIcon from 'mdi-react/CloseIcon';

// TODO: rewrite to functional component

class RadioButtonField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    defaultChecked: PropTypes.bool,
    radioValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disabled: PropTypes.bool,
    className: PropTypes.string,
  };

  static defaultProps = {
    label: '',
    defaultChecked: false,
    radioValue: '',
    disabled: false,
    className: '',
    value: '',
  };

  componentDidMount() {
    if (this.props.defaultChecked) {
      this.props.onChange(this.props.radioValue);
    }
  }

  onChange = () => {
    this.props.onChange(this.props.radioValue);
  };

  render() {
    const { disabled, className, id, name, label, radioValue, value } = this.props;
    const RadioButtonClass = classNames({
      'radio-btn': true,
      disabled,
    });

    return (
      <label
        htmlFor={id}
        className={`${RadioButtonClass}${
          className ? ` radio-btn--${className}` : ''
        }`}
      >
        <input
          id={id}
          className="radio-btn__radio"
          name={name}
          type="radio"
          onChange={this.onChange}
          checked={value === radioValue}
          disabled={disabled}
        />
        <span className="radio-btn__radio-custom" />
        {className === 'button' ? (
          <span className="radio-btn__label-svg">
            <CheckIcon className="radio-btn__label-check" />
            <CloseIcon className="radio-btn__label-uncheck" />
          </span>
        ) : (
          ''
        )}
        <span className="radio-btn__label">{label}</span>
      </label>
    );
  }
}

const renderRadioButtonField = (props) => (
  <RadioButtonField
    {...props.input}
    id={props.id}
    label={props.label}
    defaultChecked={props.defaultChecked}
    disabled={props.disabled}
    radioValue={props.radioValue}
    className={props.className}
  />
);

renderRadioButtonField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  radioValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

renderRadioButtonField.defaultProps = {
  label: '',
  defaultChecked: false,
  disabled: false,
  radioValue: '',
  className: '',
};

export default renderRadioButtonField;
