import PropTypes from '+prop-types';

import DropdownProvider, {
  Context as DropdownContext,
} from './components/Context';
import DropdownItem, {
  Header as DropdownItemHeader,
  Divider as DropdownItemDivider,
  Checkbox as DropdownItemCheckbox,
  Label as DropdownItemLabel,
  Description as DropdownItemDescription,
} from './components/Item';
import DropdownMenu from './components/Menu';
import DropdownToggle from './components/Toggle';

const Dropdown = (props) => {
  const { children, disabled, caption, menuProps, ...tail } = props;

  return (
    <DropdownProvider>
      <DropdownToggle {...tail} disabled={disabled}>
        {caption}
      </DropdownToggle>
      <DropdownMenu {...menuProps}>{children}</DropdownMenu>
    </DropdownProvider>
  );
};

Dropdown.propTypes = {
  caption: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
  ]),
  disabled: PropTypes.bool,
  children: PropTypes.children.isRequired,
  menuProps: PropTypes.object,
};

Dropdown.defaultProps = {
  caption: null,
  disabled: false,
  menuProps: undefined,
};

export {
  DropdownContext,
  DropdownProvider,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  DropdownItemHeader,
  DropdownItemDivider,
  DropdownItemCheckbox,
  DropdownItemLabel,
  DropdownItemDescription,
  Dropdown,
};
