import PropTypes from '+prop-types';
import { forwardRef, useCallback } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useDispatch } from 'react-redux';

import classNames from 'classnames';

import ClipboardTextIcon from 'mdi-react/ClipboardTextIcon';

import { actions as toastActions } from '@/redux/toast';

import IconButton from '+components/IconButton';

import ChildContainer from './components/ChildContainer';
import Container from './components/Container';

const CopyText = forwardRef((props, ref) => {
  const {
    className,
    title,
    text,
    children,
    iconSize,
    reverse,
    onCopy,
    ...otherProps
  } = props;

  const dispatch = useDispatch();

  const onCopyToClipboard = useCallback(
    (...args) => {
      const copyMsg = 'Copied';
      dispatch(toastActions.info(copyMsg));
      if (onCopy) {
        onCopy(...args);
      }
    },
    [],
  );

  return (
    <Container
      {...otherProps}
      ref={ref}
      className={classNames(className, 'copy-to-clipboard', { reverse })}
    >
      <ChildContainer className="copy-to-clipboard__content">
        {children}
      </ChildContainer>
      <CopyToClipboard
        text={text}
        onCopy={onCopyToClipboard}
      >
        <IconButton
          className="copy-to-clipboard__button"
          color="primary"
          title={title}
          onClick={() => {}}
        >
          <ClipboardTextIcon size={iconSize} />
        </IconButton>
      </CopyToClipboard>
    </Container>
  );
});

CopyText.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.func,
    PropTypes.element,
  ]),
  iconSize: PropTypes.number,
  reverse: PropTypes.bool,
  onCopy: PropTypes.func,
};

CopyText.defaultProps = {
  className: null,
  title: 'Click to copy to clipboard',
  children: null,
  iconSize: 16,
  reverse: false,
  onCopy: null,
};

export default CopyText;
