/* eslint-disable react/prop-types */
import PropTypes from '+prop-types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useToggle } from 'react-use';

import DateUtils from '@date-io/luxon';
import { useFlag } from '@unleash/proxy-client-react';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import styled, { css } from 'styled-components';

import {
  LocalizationProvider,
  DateTimePicker as DateTimePickerOrigin,
} from '@mui/x-date-pickers';

import CalendarClockIcon from 'mdi-react/CalendarClockIcon';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import HistoryIcon from 'mdi-react/HistoryIcon';
import UpdateIcon from 'mdi-react/UpdateIcon';

import FeatureFlags from '@/models/FeatureFlags';
import SettingCategories from '@/models/SettingCategories';

import { selectors as customerSelectors } from '@/redux/api/customer';
import { selectors as profileSelectors } from '@/redux/api/user/profile';

import FormField from '+components/form/FormField';
import InputTextOrigin, {
  InputAdornment,
  ClearButton,
  ClearIcon,
} from '+components/form/InputText';
import IconButtonOrigin from '+components/IconButton';
import Tooltip from '+components/Tooltip';
import usePortalSettingsValue from '+hooks/usePortalSettingsValue';
import dayjs, { DateFormat } from '+utils/dayjs';

import Adapter from './Adapter';
import Toolbar from './Toolbar';
import { ViewTypes } from './ViewTypes';

const utils = new DateUtils();

// order of showing views.
const defaultViews = [
  ViewTypes.day,
  ViewTypes.hours,
  ViewTypes.minutes,
  ViewTypes.seconds,
];

const InputText = styled(InputTextOrigin)`
  .MuiInputAdornment-root {
    padding-right: 3px;
    gap: 3px;
  }

  ${(props) => (props.$isMinOrNow
    ? css`
          .MuiInputAdornment-root button:not(.min-now-btn) {
            background-color: ${({ theme }) => theme
      .color(theme.iconButtonInsideComponentsBackground)
      .alpha(0.3)} !important;
          }
        `
    : css`
          .MuiInputAdornment-root button.min-now-btn {
            background-color: ${({ theme }) => theme
      .color(theme.iconButtonInsideComponentsBackground)
      .alpha(0.3)} !important;
          }
        `)}
`;

const IconButton = styled(IconButtonOrigin)`
  padding: unset !important;
  width: 26px;
  height: 26px;
  color: ${({ theme }) => theme.iconButtonInsideComponentsText} !important;
  background-color: ${({ theme }) => theme.iconButtonInsideComponentsBackground} !important;

  :hover {
    background-color: ${({ theme }) => theme
    .color(theme.iconButtonInsideComponentsBackground)
    .lighten(0.2)} !important;
  }

  :disabled {
    color: unset !important;
    background-color: unset !important;
  }
`;

const leftArrowIcon = <ChevronLeftIcon size={24} />;
const rightArrowIcon = <ChevronRightIcon size={24} />;

/**
 * @param value
 * @param {DateTime} min
 * @param {DateTime} max
 * @returns {DateTime|null}
 */
const normalizeDate = (value, min, max) => {
  let date = value && utils.date(value);
  if (!utils?.isValid?.(date)) {
    return null;
  }
  date = utils?.isValid?.(max)
    ? utils.date(new Date(Math.min(+date, +max)))
    : date;
  date = utils?.isValid?.(min)
    ? utils.date(new Date(Math.max(+date, +min)))
    : date;
  return date;
};

const dayjsToLuxon = (value) => {
  const date = value && dayjs(value);
  if (!date?.isValid?.()) {
    return null;
  }
  return DateTime.fromMillis(+date);
};

const randMilliseconds = (value) => value?.set?.({
  millisecond: Math.floor(Math.random() * 1e3),
});

const isEqualToFormatted = (date, format, expected) => date && utils.formatByString(date, format) === expected;

const formats = {
  [DateFormat.day]: 'yyyy-MM-dd',
  [DateFormat.minute]: 'yyyy-MM-dd HH:mm',
};

const getPlaceholder = (ampm) => `yyyy-mm-dd hh:mm:ss${ampm ? ' (a|p)m' : ''}`;

/**
 * DateTimePicker component
 */
const DateTimePicker = (props) => {
  const {
    min,
    max,
    value,
    ampm,
    invalid,
    views,
    autoClose,
    name,
    id,
    placeholder,
    className,
    minButton,
    minPlaceholder,
    isMin,
    nowButton,
    isNow,
    readOnly,
    showClearButton,
    onChange,
    onMinToggle,
    onNowToggle,
    ...tail
  } = props;

  const isRolesUiSettingsEnabled = useFlag(FeatureFlags.rolesUiSettings);

  const [anchorEl, setAnchorEl] = useState();
  const [open, toggleOpen] = useToggle(false);
  const [isMinToggled, setIsMinToggled] = useState(isMin);
  const [isNowToggled, setIsNowToggled] = useState(isNow);
  const popperNode = useRef();

  const retention = useSelector(customerSelectors.getRetention);
  const profile = useSelector(profileSelectors.getProfile);
  const [userRoleUiSettings] = usePortalSettingsValue(
    SettingCategories.ui,
    `${profile?.roles?.[0]}:settings`,
    {},
  );

  const [isTimeFormat12h] = usePortalSettingsValue(
    SettingCategories.ui,
    'isTimeFormat12h',
    false,
  );

  const fixedAmpm = useMemo(
    () => ampm ?? isTimeFormat12h,
    [ampm, isTimeFormat12h],
  );

  const isSecondsShown = views.includes(ViewTypes.seconds);

  const dateTimeLimit = useMemo(
    () => {
      if (isRolesUiSettingsEnabled && userRoleUiSettings?.dateTimeLimit) {
        return Math.min(userRoleUiSettings.dateTimeLimit, retention);
      }
      return retention;
    },
    [retention, isRolesUiSettingsEnabled, userRoleUiSettings?.dateTimeLimit],
  );

  const fixedFormat = useMemo(
    () => {
      if (isMinToggled) {
        return minPlaceholder
          ? `'${minPlaceholder}'`
          : `'-${dateTimeLimit} DAYS'`;
      }

      if (isNowToggled) {
        return "'NOW'";
      }

      const secondsPart = isSecondsShown ? ':ss' : '';

      return fixedAmpm
        ? `${formats[DateFormat.day]} hh:mm${secondsPart} a`
        : `${formats[DateFormat.minute]}${secondsPart}`;
    },
    [
      dateTimeLimit,
      fixedAmpm,
      isMinToggled,
      isNowToggled,
      isSecondsShown,
      minPlaceholder,
    ],
  );

  const fixedMask = useMemo(
    () => {
      if (isMinToggled) {
        return minPlaceholder || `-${dateTimeLimit} DAYS`;
      }

      if (isNowToggled) {
        return 'NOW';
      }

      const secondsPart = isSecondsShown ? ':__' : '';

      return fixedAmpm
        ? `____-__-__ __:__${secondsPart} _M`
        : `____-__-__ __:__${secondsPart}`;
    },
    [
      dateTimeLimit,
      fixedAmpm,
      isMinToggled,
      isNowToggled,
      isSecondsShown,
      minPlaceholder,
    ],
  );

  const fixedMin = useMemo(
    () => (min == null ? undefined : dayjsToLuxon(dayjs(min))),
    [min],
  );

  const fixedMax = useMemo(
    () => (max == null ? undefined : dayjsToLuxon(dayjs(max))),
    [max],
  );

  const fixedValue = useMemo(
    () => normalizeDate(dayjsToLuxon(value), fixedMin, fixedMax),
    [fixedMin, fixedMax, value],
  );

  const oldDate = useRef(value);
  const [innerValue, setInnerValue] = useState(fixedValue);
  const [needForceUpdate, setNeedForceUpdate] = useState(false);

  const doChange = useCallback(
    (newDate) => {
      const date = normalizeDate(newDate, fixedMin, fixedMax);
      const isValid = utils.isValid(date);
      setInnerValue(isValid ? date : null);
      onChange(isValid ? utils.toJsDate(date) : null);
    },
    [onChange, fixedMin, fixedMax, name],
  );

  const doClear = useCallback(
    () => {
      doChange(null);
    },
    [onChange],
  );

  useEffect(
    () => {
      setInnerValue((prev) => (+prev !== +fixedValue ? dayjsToLuxon(fixedValue) : prev));
    },
    [fixedValue, name],
  );

  const onMinButtonClick = useCallback(
    () => {
      setNeedForceUpdate(true);
      setIsMinToggled(!isMinToggled);
      onMinToggle(!isMinToggled);
    },
    [onMinToggle, isMinToggled],
  );

  const onNowButtonClick = useCallback(
    () => {
      setNeedForceUpdate(true);
      setIsNowToggled(!isNowToggled);
      onNowToggle(!isNowToggled);
    },
    [onNowToggle, isNowToggled],
  );

  const onCalendarButtonClick = useCallback(
    () => {
      if (isMinToggled) {
        onMinButtonClick();
        return;
      }
      if (isNowToggled) {
        onNowButtonClick();
        return;
      }
      toggleOpen();
    },
    [isMinToggled, isNowToggled, onMinButtonClick, onNowButtonClick],
  );

  useEffect(
    () => {
      if (isMinToggled !== isMin) {
        setIsMinToggled(isMin);
      }
    },
    [isMin, isMinToggled],
  );

  useEffect(
    () => {
      if (isNowToggled !== isNow) {
        setIsNowToggled(isNow);
      }
    },
    [isNow, isNowToggled],
  );

  useEffect(
    () => {
      if (needForceUpdate) {
        setNeedForceUpdate(false);
        setInnerValue((prevValue) => (prevValue ? randMilliseconds(prevValue) : null));
      }
    },
    [needForceUpdate],
  );

  const EndAdornment = useCallback(
    ({ value: localValue, disabled: localDisabled = false }) => !localDisabled
      && !readOnly && (
      <InputAdornment position="end">
        {showClearButton && !!localValue && (
          <ClearButton tabIndex={-1} size="medium" onClick={doClear}>
            <ClearIcon size={14} />
          </ClearButton>
        )}
        <IconButton
          size="medium"
          onClick={onCalendarButtonClick}
          data-tracking={`date-time-picker-${name}`}
        >
          <CalendarClockIcon size={18} />
        </IconButton>
        {minButton && (
          <Tooltip
            title={retention === dateTimeLimit ? 'Full Retention' : 'Full Limit'}
            arrow={false}
          >
            <IconButton
              className="min-now-btn"
              size="medium"
              onClick={onMinButtonClick}
              data-tracking="date-time-full-retention"
            >
              <HistoryIcon size={18} />
            </IconButton>
          </Tooltip>
        )}
        {nowButton && (
          <Tooltip title="Now" arrow={false}>
            <IconButton
              className="min-now-btn"
              size="medium"
              onClick={onNowButtonClick}
              data-tracking="date-time-now"
            >
              <UpdateIcon size={18} />
            </IconButton>
          </Tooltip>
        )}
      </InputAdornment>
    ),
    [
      minButton,
      isMinToggled,
      onMinButtonClick,
      nowButton,
      isNowToggled,
      onNowButtonClick,
      // tail.disabled,
      readOnly,
      showClearButton,
      onCalendarButtonClick,
      doClear,
      retention,
      dateTimeLimit,
    ],
  );

  const renderInput = useCallback(
    ({ InputProps, ...inputProps }) => {
      const _placeholder = inputProps.inputProps.placeholder || getPlaceholder(fixedAmpm);
      // eslint-disable-next-line no-nested-ternary
      const _value = needForceUpdate
        ? innerValue
          ? utils.formatByString(innerValue, fixedFormat)
          : ''
        : inputProps.inputProps.value;
      const _onBlur = (event) => {
        const isEqual = isEqualToFormatted(
          innerValue,
          fixedFormat,
          inputProps.inputProps.value,
        );
        if (!isEqual) {
          setNeedForceUpdate(true);
        }
        inputProps.inputProps.onBlur?.(event);
      };
      return (
        <InputText
          {...inputProps}
          ref={setAnchorEl}
          className={classNames(className, { readOnly })}
          variant="outlined"
          $invalid={invalid}
          $isMinOrNow={!inputProps.disabled && (isMinToggled || isNowToggled)}
          inputProps={{
            ...inputProps.inputProps,
            placeholder: _placeholder,
            value: _value,
            onBlur: _onBlur,
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          InputProps={{
            readOnly,
            endAdornment: (
              <EndAdornment value={_value} disabled={inputProps.disabled} />
            ),
          }}
        />
      );
    },
    [
      invalid,
      className,
      EndAdornment,
      readOnly,
      isMinToggled,
      isNowToggled,
      fixedFormat,
      innerValue,
      needForceUpdate,
    ],
  );

  const popperRef = useCallback(
    (node) => {
      popperNode.current = node;
    },
    [],
  );

  const popperProps = useMemo(
    () => ({
      ...(anchorEl && { anchorEl }),
      popperRef,
      'data-super': 1,
      className: 'dateTimePickerStyle',
    }),
    [anchorEl],
  );

  const inputProps = useMemo(
    () => ({ id, name, ...(placeholder && { placeholder }) }),
    [id, name, placeholder],
  );

  const onClose = useCallback(
    () => {
      toggleOpen(false);
    },
    [],
  );

  const onToolbarActions = useCallback(
    (event) => {
      if (event.detail?.type === 'cancel') {
        doChange(oldDate.current && dayjsToLuxon(oldDate.current));
        toggleOpen(false);
        return;
      }

      if (event.detail?.type === 'apply') {
        toggleOpen(false);
      }
    },
    [doChange],
  );

  useEffect(
    () => {
      if (!open) {
        return undefined;
      }

      oldDate.current = value;

      document.addEventListener('toolbarActionButton', onToolbarActions, true);

      return () => {
        document.removeEventListener(
          'toolbarActionButton',
          onToolbarActions,
          true,
        );
      };
    },
    [open],
  );

  return (
    <LocalizationProvider dateAdapter={Adapter}>
      <DateTimePickerOrigin
        disableMaskedInput
        ignoreInvalidInputs
        showToolbar
        {...tail}
        renderInput={renderInput}
        inputProps={inputProps}
        error={invalid}
        ampm={fixedAmpm}
        inputFormat={fixedFormat}
        mask={fixedMask}
        views={views}
        value={innerValue}
        PopperProps={popperProps}
        ToolbarComponent={Toolbar}
        leftArrowIcon={leftArrowIcon}
        rightArrowIcon={rightArrowIcon}
        minDateTime={fixedMin}
        maxDateTime={fixedMax}
        open={open}
        disabled={tail.disabled}
        readOnly={readOnly || isMinToggled || isNowToggled}
        onClose={onClose}
        onChange={doChange}
      />
    </LocalizationProvider>
  );
};

DateTimePicker.propTypes = {
  /**
   * min date
   */
  min: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  /**
   * max date
   */
  max: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  /**
   * current value
   */
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  /**
   * is AmPm mode
   */
  ampm: PropTypes.bool,
  /**
   * Invalid value
   */
  invalid: PropTypes.bool,
  /**
   * Read only value
   */
  readOnly: PropTypes.bool,
  /**
   * Order of views switching
   */
  views: PropTypes.arrayOf(PropTypes.oneOf(Object.values(ViewTypes))),
  /**
   * auto close when select a value in the last view
   */
  autoClose: PropTypes.bool,
  /**
   * add min time button
   */
  minButton: PropTypes.bool,
  /**
   * min time input placeholder
   */
  minPlaceholder: PropTypes.string,
  /**
   * True if the min time button is toggled
   */
  isMin: PropTypes.bool,
  /**
   * add current time button
   */
  nowButton: PropTypes.bool,
  /**
   * True if the now time button is toggled
   */
  isNow: PropTypes.bool,
  /**
   * Input placeholder
   */
  placeholder: PropTypes.string,
  /**
   * Show clear button
   */
  showClearButton: PropTypes.bool,
  /**
   * On change function
   */
  onChange: PropTypes.func.isRequired,
  /**
   * Min button click handler
   */
  onMinToggle: PropTypes.func,
  /**
   * Now button click handler
   */
  onNowToggle: PropTypes.func,
};

DateTimePicker.defaultProps = {
  min: null,
  max: null,
  value: null,
  ampm: null,
  invalid: false,
  readOnly: false,
  views: defaultViews,
  autoClose: true,
  minButton: false,
  minPlaceholder: undefined,
  isMin: false,
  nowButton: false,
  isNow: false,
  placeholder: '',
  showClearButton: false,
  onMinToggle: () => {},
  onNowToggle: () => {},
};

const DateTimePickerField = (props) => {
  const {
    className,
    input: { value, onChange, ...restInput },
    label,
    helperText,
    meta: { touched, error, dirty, submitFailed },
    disabled,
    readOnly,
    required,
    defaultValue,
    errorPositionOver,
    ...tail
  } = props;

  const invalid = error && (dirty || submitFailed) && touched;

  return (
    <FormField
      label={label}
      helperText={helperText}
      error={error}
      invalid={invalid}
      disabled={disabled}
      required={required}
      errorPositionOver={errorPositionOver}
    >
      <DateTimePicker
        invalid={invalid}
        {...tail}
        {...restInput}
        className={className}
        value={value || defaultValue}
        disabled={disabled}
        readOnly={readOnly}
        onChange={onChange}
      />
    </FormField>
  );
};

export { ViewTypes as DateTimePickerViewTypes, DateTimePickerField };

export default DateTimePicker;
