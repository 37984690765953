import { CustomType } from '@/models/CustomType';
import { DateTimeModes } from '@/models/DateTimeModes';
import { TimePeriods } from '@/models/TimePeriods';

import dayjs, { DateFormat } from '+utils/dayjs';
import { timeBounds } from '+utils/timeBounds';

const getFromAndToDate = (props) => {
  let { from, to } = props;
  const { period, dateTimeMode, onlyRealtime } = props;

  const { start, end } = timeBounds(props);

  let format = DateFormat.minute;

  const { fullFormat = DateFormat.minute } = TimePeriods[period?.period] || {};

  switch (period.type) {
    case CustomType:
      from = start;
      to = end;
      break;
    default:
      format = fullFormat;
      break;
  }

  let fromDate;
  let toDate;

  let mode = dateTimeMode;

  if (onlyRealtime) {
    mode = DateTimeModes.realtime;
  }

  switch (mode) {
    case DateTimeModes.now:
      fromDate = dayjs(from).format(format);
      toDate = 'REAL-TIME*';
      break;
    case DateTimeModes.realtime:
      fromDate = '';
      toDate = 'REAL-TIME';
      break;
    default:
      fromDate = dayjs(from).format(format);
      toDate = dayjs(to).format(format);
      break;
  }

  return `${fromDate} ⟶ ${toDate}`;
};

export default getFromAndToDate;
