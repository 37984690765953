import { config } from '@/config';

export const homeUrl = `${window.location.protocol}//${
  window.location.hostname
}${window.location.port ? `:${window.location.port}` : ''}`;

export const getSsoLoginUrl = (shortname) => `${homeUrl}/sso/${shortname}`;

export const getSsoLogoutUrl = (shortname) => `${homeUrl}/auth/logout?returnTo=${encodeURIComponent(
  getSsoLoginUrl(shortname),
)}`;

export const getIdpLoginUrl = (shortname) => {
  const kcServer = config.keycloak.serverUrl;
  const kcRealm = config.keycloak.realm;
  const kcClientId = config.keycloak.clientId;
  // eslint-disable-next-line max-len
  return `${kcServer}/realms/${kcRealm}/protocol/openid-connect/auth?client_id=${kcClientId}&redirect_uri=${encodeURIComponent(
    homeUrl,
  )}&response_mode=fragment&response_type=code&scope=openid&kc_idp_hint=${shortname}`;
};

export const getMetadataXmlPublicUrl = (shortname) => `${config.appBackendUrlRoot}/api/v1/sso/${shortname}/metadata.xml`;

export default {
  getSsoLoginUrl,
  getSsoLogoutUrl,
  getIdpLoginUrl,
  getMetadataXmlPublicUrl,
};
