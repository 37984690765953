import PropTypes from '+prop-types';
import { Fragment, useMemo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useFlag } from '@unleash/proxy-client-react';

import { ContextTypes } from '@/models/ContextTypes';
import PermissionModel from '@/models/Permission';
import RoutePaths from '@/models/RoutePaths';

import {
  actions as deviceActions,
  selectors as deviceSelectors,
} from '@/redux/api/device';
import {
  actions as vpcActions,
  selectors as vpcSelectors,
} from '@/redux/api/vpc';

import ConfirmModal from '+components/ConfirmModal';
import { Field } from '+components/form/FinalForm';
import Description from '+components/form/FormField/components/Description';
import Group from '+components/form/FormField/components/Group';
import Label from '+components/form/FormField/components/Label';
import MultiSelectField from '+components/form/MultiSelectField';
import { normalizeMultiSelectValue } from '+components/form/Normalizers';
import Plaintext from '+components/form/Plaintext';
import TextField from '+components/form/TextField';
import { ToggleField } from '+components/form/Toggle';
import {
  validateNumber,
  validateRequired,
  validateSampleRate,
} from '+components/form/Validators';
import FormWizard, { Step } from '+components/FormWizard';
import UniversalField from '+components/UniversalField';
import useMaxSources from '+hooks/useMaxSources';
import usePermissions from '+hooks/usePermissions';

import AwsKinesisFields from './providerFields/AwsKinesisFields';
import AwsS3Fields from './providerFields/AwsS3Fields';
import AzureNsgFields from './providerFields/AzureNsgFields';
import GcpPubsup from './providerFields/GcpPubsubFields';
import IbmCosFields from './providerFields/IbmCosFields';
import OracleCosFields from './providerFields/OracleCosFields';
import { vpcUItoParams, formatVpc } from './utils';

const unique = (arr) => arr.reduce((acc, el) => (acc.includes(el) ? acc : [...acc, el]), []);

const VpcForm = (props) => {
  const { mode, initialValues, loading, updateCallback, createCallback } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isDnsContext = initialValues.traffictype === ContextTypes.dns;
  const isDnsEnabled = useFlag('DNS');

  const permissions = usePermissions(
    PermissionModel.Resources.cloud_provider.value,
  );
  const vpcTags = useSelector(vpcSelectors.getVpcTags);
  const { tags: deviceTags } = useSelector(deviceSelectors.getState);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { maxDownsample } = useMaxSources();

  const adapter = initialValues.flowtype
    ? `${initialValues.flowtype}/${initialValues.flowresource}`
    : null;

  const canManage = initialValues.id
    ? permissions?.update
    : permissions?.create;
  const canRemove = initialValues.id && permissions?.delete;

  const normalizedTags = useMemo(
    () => {
      const flowTags = [...(deviceTags || []), ...(vpcTags || [])];
      return unique(flowTags.map((row) => row?.tag).filter((tag) => !!tag));
    },
    [deviceTags, vpcTags],
  );

  const providerFields = useMemo(
    () => {
      switch (adapter) {
        case 'aws/s3':
          return <AwsS3Fields disabled={!canManage} isDnsContext />;
        case 'aws/kinesis':
          return <AwsKinesisFields disabled={!canManage} />;
        case 'azure/blobstorage':
          return <AzureNsgFields disabled={!canManage} />;
        case 'gcp/pubsub':
          return <GcpPubsup disabled={!canManage} isDnsContext />;
        case 'ibm/objectstorage':
          return <IbmCosFields disabled={!canManage} />;
        case 'oracle/objectstorage':
          return <OracleCosFields vpc={initialValues} disabled={!canManage} />;
        default:
          return null;
      }
    },
    [adapter, initialValues, canManage],
  );

  const onSubmit = useCallback(
    (UIvalues) => {
      // converted flattenend UI object to nested API object
      const values = vpcUItoParams(UIvalues);
      if (mode === 'update') {
        updateCallback(values);
      } else {
        createCallback(values);
      }
    },
    [mode, updateCallback, createCallback],
  );

  const onCancel = useCallback(
    () => {
      navigate(`${RoutePaths.sources}`);
    },
    [],
  );

  const onDeleteModalToggle = useCallback(
    () => {
      setShowDeleteModal((prevValue) => !prevValue);
    },
    [],
  );

  const onDelete = useCallback(
    () => {
      dispatch(vpcActions.vpcDelete(initialValues.id));
      onCancel();
    },
    [initialValues, onCancel],
  );

  useEffect(
    () => {
      dispatch(vpcActions.fetchTags());
    },
    [],
  );

  useEffect(
    () => {
      dispatch(deviceActions.fetchTags());
    },
    [],
  );

  return (
    <Fragment>
      <FormWizard
        initialValues={initialValues}
        title={mode === 'create' && 'Add Cloud Provider'}
        description={
          mode === 'create' && (
            <Fragment>
              {/* eslint-disable-next-line max-len */}
              {isDnsContext
                ? `Netography collects cloud DNS query logs from Amazon Web Services and Google Cloud that
              ingests query data directly into the Netography Security Platform.`
                : `Netography collects cloud flow logs from Amazon Web Services,
              Microsoft Azure, Google Cloud, IBM Cloud and Oracle Cloud that
              ingests flow data directly into the Netography Security Platform.`}
            </Fragment>
          )
        }
        image="/assets/graphic-cloud-providers.png"
        onSubmit={onSubmit}
        onCancel={onCancel}
        loading={loading}
        disabled={loading || !canManage}
        deleteButtonText="Delete Cloud Provider"
        onDelete={onDeleteModalToggle}
        deleteButtonHidden={!initialValues.id}
        deleteButtonDisabled={!canRemove}
      >
        <Step>
          <Group>
            <Label>Provider</Label>
            <Plaintext wrappedText={false}>
              {formatVpc(adapter)}
              {isDnsEnabled && (
                <UniversalField
                  field="algo_record_type"
                  value={initialValues?.traffictype || ContextTypes.flow}
                  disabled
                />
              )}
            </Plaintext>
          </Group>

          <Field
            name="name"
            label="Name"
            component={TextField}
            type="text"
            maxLength={255}
            autoComplete="new-password"
            validate={validateRequired}
            disabled={!canManage}
            required
          />

          <Group>
            <Field
              name="enabled"
              label="Polling"
              type="checkbox"
              checkedLabel="Enabled"
              uncheckedLabel="Disabled"
              uppercaseLabels={false}
              component={ToggleField}
              disabled={!canManage}
            />
            <Description>
              Whether to poll the Cloud Provider for samples.
            </Description>
          </Group>

          {adapter !== 'gcp/pubsub' && (
            <Field
              name="samplerate"
              label="Down Sample"
              component={TextField}
              type="number"
              min={1}
              max={65535}
              style={{ width: '200px' }}
              validate={[validateRequired, validateNumber, validateSampleRate]}
              helperText={`Allow 1 in N ${
                isDnsContext ? 'queries' : 'flows'
              } received to be processed and recorded.`}
              disabled={!canManage || maxDownsample != null}
              required
            />
          )}

          <Field
            name="tags"
            label="Tags"
            options={normalizedTags}
            component={MultiSelectField}
            parse={normalizeMultiSelectValue}
            helperText={`One or many tags to assign to every ${
              isDnsContext ? 'query' : 'flow'
            } from this VPC.`}
            disabled={!canManage}
            allowCreate
          />

          {providerFields}
        </Step>
      </FormWizard>

      {showDeleteModal && (
        <ConfirmModal
          item={initialValues?.name}
          onToggle={onDeleteModalToggle}
          onConfirm={onDelete}
          isOpen
        />
      )}
    </Fragment>
  );
};

VpcForm.propTypes = {
  createCallback: PropTypes.func,
  updateCallback: PropTypes.func,
  mode: PropTypes.oneOf(['create', 'update']),
  initialValues: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    enabled: PropTypes.bool,
    flowtype: PropTypes.string,
    flowresource: PropTypes.string,
    traffictype: PropTypes.string,
  }),
  loading: PropTypes.bool,
};

VpcForm.defaultProps = {
  mode: 'create',
  createCallback: () => {},
  updateCallback: null, // null so we can switch the UI accordingly
  initialValues: {},
  loading: false,
};

export default VpcForm;
