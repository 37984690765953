import isFunction from 'lodash.isfunction';

/**
 * Generates an equality callback to use with React.memo. The equality function
 * will only return false when a non-ignored prop has changed.
 * @param ignoredProps - props to ignore in equality check
 * @return {memoIgnoreProps~arePropsEqual} - equality checking function for React.memo
 */
export const memoIgnoreProps = (ignoredProps) => {
  const ignoredSet = new Set(ignoredProps);
  return (prevProps, nextProps) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const prop in nextProps) {
      if (!ignoredSet.has(prop) && prevProps[prop] !== nextProps[prop]) {
        return false;
      }
    }
    return true;
  };
};

/**
 * Pass node on to ref
 * @param ref ref to preserve
 * @param node node to pass into ref
 */
export const preserveRef = (ref, node) => {
  if (!ref) {
    return;
  }

  if (isFunction(ref)) {
    ref(node);
    return;
  }

  ref.current = node;
};

const preserve = (node) => (ref) => preserveRef(ref, node);

/**
 * combineRefs when multiple refs need to subscribe to the HTML el
 * ex: <div ref={combineRefs(ref1, ref2)} />
 * @param  {...any} refs
 */
export const combineRefs = (...refs) => (target) => {
  refs.forEach(preserve(target));
};
