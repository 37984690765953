import PropTypes from '+prop-types';

import { Col, LayoutSizes, Row } from '+components/Layout';

import TransformTextField from '../TransformTextField';
import FieldLabel from './FieldLabel';
import MethodSelector from './MethodSelector';

const LowerRowRegex = (props) => {
  const { disabled, data, onChange } = props;
  return (
    <Col gap="6px">
      <Row gap={LayoutSizes.groupGap}>
        <Col item xs={2.5}>
          <FieldLabel $disabled={disabled}>Method</FieldLabel>
        </Col>
        <Col item xs={3.75}>
          <FieldLabel $disabled={disabled}>Match</FieldLabel>
        </Col>
        <Col item xs={3.75}>
          <FieldLabel $disabled={disabled}>Replace</FieldLabel>
        </Col>
      </Row>

      <Row gap={LayoutSizes.groupGap}>
        <Col item xs={2.5}>
          <MethodSelector {...props} />
        </Col>
        <Col item xs={3.75}>
          <TransformTextField
            disabled={disabled}
            defaultValue={data.match || ''}
            onChange={(_, value) => onChange('regex', { ...data, match: value })}
          />
        </Col>
        <Col item xs={3.75}>
          <TransformTextField
            disabled={disabled}
            defaultValue={data.replace || ''}
            onChange={(_, value) => onChange('regex', { ...data, replace: value })}
          />
        </Col>
      </Row>
    </Col>
  );
};

LowerRowRegex.propTypes = {
  disabled: PropTypes.bool.isRequired,
  data: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
};
export default LowerRowRegex;
