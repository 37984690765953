import PropTypes from '+prop-types';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import capitalize from 'lodash.capitalize';
import styled from 'styled-components';

import AlertDecagramIcon from 'mdi-react/AlertDecagramIcon';
import InformationIcon from 'mdi-react/InformationIcon';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';
import TextBoxIcon from 'mdi-react/TextBoxIcon';
import TimerIcon from 'mdi-react/TimerIcon';

import RoutePaths from '@/models/RoutePaths';

import {
  actions as cvesActions,
  selectors as cvesSelectors,
} from '@/redux/api/cves';
import { actions } from '@/redux/api/labels/ips';

import Button from '+components/Button';
import { Field, FinalForm } from '+components/form/FinalForm';
import FormOrigin from '+components/form/Form';
import TextField from '+components/form/TextField';
import { validateCve, validateRequired } from '+components/form/Validators';
import GlobalLink from '+components/GlobalLink';
import SeverityLabel from '+components/Labels/SeverityLabel';
import { LayoutSizes, LayoutTypes, Col, Row } from '+components/Layout';
import Table from '+components/Table';
import { timestampFormatter } from '+components/Table/Cells/formatters';
import useIpLabels from '+hooks/useIpLabels';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import usePageTabsAndFormSync from '+hooks/usePageTabsAndFormSync';
import { DateFormat } from '+utils/dayjs';

import { Columns, getColumns } from './components/columns';

const Form = styled(FormOrigin)`
  flex-direction: row !important;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;

  .form__form-group {
    width: 25em;
    margin-bottom: unset;
  }
`;

const FormBody = ({ handleSubmit, isFetching }) => {
  usePageTabsAndFormSync();

  return (
    <Form onSubmit={handleSubmit}>
      <Field
        name="id"
        type="text"
        component={TextField}
        validate={[validateRequired, validateCve]}
        required
      />
      <Button
        style={{ marginLeft: '10px' }}
        type="submit"
        disabled={isFetching}
        data-tracking="cve-lookup-search-button"
      >
        Search
      </Button>
    </Form>
  );
};

FormBody.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

const sortBy = [{ id: 'ip', desc: false }];
const cveContext = 'cve';
const cveDataContexts = [
  Columns.cvss_rating,
  Columns.cvss_score,
  Columns.vuln_count,
];

const columns = getColumns(Object.values(Columns));

const CveLookup = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const params = useMemo(
    () => {
      const search = new URLSearchParams(location.search);
      return { id: search.get('id') };
    },
    [location.search],
  );

  const initialValues = useMemo(
    () => ({ id: params.id || 'CVE-' }),
    [params.id],
  );

  const { ipLabels, isFetchingIpLabels } = useIpLabels();

  const cve = useSelector(cvesSelectors.getCveById(params.id));
  const isFetchingCve = useSelector(cvesSelectors.isFetching);

  useLoadingIndicator(isFetchingCve || isFetchingIpLabels);

  const ipHash = useMemo(
    () => (!cve?.id
      ? {}
      : ipLabels.reduce((acc, item) => {
        if (item.context !== cveContext || !item.labels.includes(cve?.id)) {
          return acc;
        }
        acc[item.ip] = item.ip;
        return acc;
      }, {})),
    [ipLabels, cveContext, cve?.id],
  );

  const tableData = useMemo(
    () => {
      const data = ipLabels.reduce((acc, item) => {
        if (!ipHash[item.ip] || !cveDataContexts.includes(item.context)) {
          return acc;
        }
        let record = acc[item.ip];
        if (!record) {
          record = { ip: item.ip };
          acc[item.ip] = record;
        }
        // eslint-disable-next-line prefer-destructuring
        record[item.context] = item.labels[0];
        return acc;
      }, {});
      return Object.values(data);
    },
    [ipLabels, ipHash, cveDataContexts],
  );

  const onCveFullDetails = useCallback(
    (values) => {
      navigate(`${RoutePaths.cveLookup}?id=${values.id}`);
    },
    [],
  );

  useEffect(
    () => {
      if (cve?.id) {
        [cveContext, ...cveDataContexts].forEach((context) => {
          dispatch(
            actions.fetchIpLabelsByContext(
              { context },
              `fetchIpLabelsByContext - ${context}`,
            ),
          );
        });
      }
    },
    [cve?.id, cveContext, cveDataContexts],
  );

  useEffect(
    () => {
      if (params.id) {
        dispatch(cvesActions.fetchCveById(params.id));
      }
    },
    // we need location.key to force re-fetching data when user navigates to the same page
    [params.id, location.key],
  );

  const globalLink = `https://nvd.nist.gov/vuln/detail/${cve?.id}`;
  const cvssMetricV31 = cve?.metrics?.cvssMetricV31?.[0] || {};
  const cvssMetricV2 = cve?.metrics?.cvssMetricV2?.[0] || {};

  return (
    <Col gap={LayoutSizes.groupGap}>
      <Row>
        <FinalForm
          initialValues={initialValues}
          isFetching={isFetchingCve}
          component={FormBody}
          onSubmit={onCveFullDetails}
          keepDirtyOnReinitialize
        />
      </Row>

      <Row gap={LayoutSizes.groupGap}>
        <Col $type={LayoutTypes.card}>
          <Row $type={LayoutTypes.title}>
            <TextBoxIcon size={16} />
            <span>Overview</span>
          </Row>

          <Row $type={LayoutTypes.field}>
            <Col $type={LayoutTypes.fieldValue} xs>
              {cve?.descriptions.find((item) => item.lang === 'en')?.value
                || ''}
            </Col>
          </Row>
        </Col>

        <Col $type={LayoutTypes.card} lg={4}>
          <Row $type={LayoutTypes.title}>
            <TimerIcon size={16} />
            <span>Quick Info</span>
          </Row>

          <Row $type={LayoutTypes.field}>
            <Col $type={LayoutTypes.fieldName}>CVE Dictionary Entry:</Col>
            <Col $type={LayoutTypes.fieldValue}>
              {cve?.id && (
                <GlobalLink
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '5px',
                  }}
                  href={globalLink}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {cve?.id}
                  <OpenInNewIcon size={14} />
                </GlobalLink>
              )}
            </Col>
          </Row>

          <Row $type={LayoutTypes.field}>
            <Col $type={LayoutTypes.fieldName}>NVD Published Date:</Col>
            <Col $type={LayoutTypes.fieldValue}>
              {timestampFormatter(new Date(cve?.published), DateFormat.day)}
            </Col>
          </Row>

          <Row $type={LayoutTypes.field}>
            <Col $type={LayoutTypes.fieldName}>NVD Last Modified:</Col>
            <Col $type={LayoutTypes.fieldValue} justifyContent="center">
              {timestampFormatter(new Date(cve?.lastModified), DateFormat.day)}
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gap={LayoutSizes.groupGap}>
        <Col $type={LayoutTypes.card}>
          <Row $type={LayoutTypes.title}>
            <AlertDecagramIcon size={16} />
            <span>CVSS v3.1 Severity and Metrics</span>
          </Row>

          <Row $type={LayoutTypes.field}>
            <Col $type={LayoutTypes.fieldName} lg={4}>
              Base Score:
            </Col>
            <Col $type={LayoutTypes.fieldValue}>
              {cvssMetricV31.cvssData?.baseSeverity && (
                <SeverityLabel
                  severity={cvssMetricV31.cvssData?.baseSeverity?.toLowerCase()}
                  label={`${cvssMetricV31.cvssData?.baseScore} ${cvssMetricV31.cvssData?.baseSeverity}`}
                />
              )}
            </Col>
          </Row>

          <Row $type={LayoutTypes.field}>
            <Col $type={LayoutTypes.fieldName} lg={4}>
              Vector:
            </Col>
            <Col $type={LayoutTypes.fieldValue}>
              {cvssMetricV31.cvssData?.vectorString}
            </Col>
          </Row>

          <Row $type={LayoutTypes.field}>
            <Col $type={LayoutTypes.fieldName} lg={4}>
              Impact Score:
            </Col>
            <Col $type={LayoutTypes.fieldValue}>
              {cvssMetricV31.impactScore}
            </Col>
          </Row>

          <Row $type={LayoutTypes.field}>
            <Col $type={LayoutTypes.fieldName} lg={4}>
              Exploitability Score:
            </Col>
            <Col $type={LayoutTypes.fieldValue}>
              {cvssMetricV31.exploitabilityScore}
            </Col>
          </Row>

          <Row $type={LayoutTypes.field}>
            <Col $type={LayoutTypes.fieldName} lg={4}>
              Attack Vector (AV):
            </Col>
            <Col $type={LayoutTypes.fieldValue}>
              {capitalize(cvssMetricV31.cvssData?.attackVector)}
            </Col>
          </Row>

          <Row $type={LayoutTypes.field}>
            <Col $type={LayoutTypes.fieldName} lg={4}>
              Attack Complexity (AC):
            </Col>
            <Col $type={LayoutTypes.fieldValue}>
              {capitalize(cvssMetricV31.cvssData?.attackComplexity)}
            </Col>
          </Row>

          <Row $type={LayoutTypes.field}>
            <Col $type={LayoutTypes.fieldName} lg={4}>
              Privileges Required (PR):
            </Col>
            <Col $type={LayoutTypes.fieldValue}>
              {capitalize(cvssMetricV31.cvssData?.privilegesRequired)}
            </Col>
          </Row>

          <Row $type={LayoutTypes.field}>
            <Col $type={LayoutTypes.fieldName} lg={4}>
              User Interaction (UI):
            </Col>
            <Col $type={LayoutTypes.fieldValue}>
              {capitalize(cvssMetricV31.cvssData?.userInteraction)}
            </Col>
          </Row>

          <Row $type={LayoutTypes.field}>
            <Col $type={LayoutTypes.fieldName} lg={4}>
              Scope (S):
            </Col>
            <Col $type={LayoutTypes.fieldValue}>
              {capitalize(cvssMetricV31.cvssData?.scope)}
            </Col>
          </Row>

          <Row $type={LayoutTypes.field}>
            <Col $type={LayoutTypes.fieldName} lg={4}>
              Confidentiality (C):
            </Col>
            <Col $type={LayoutTypes.fieldValue}>
              {capitalize(cvssMetricV31.cvssData?.confidentialityImpact)}
            </Col>
          </Row>

          <Row $type={LayoutTypes.field}>
            <Col $type={LayoutTypes.fieldName} lg={4}>
              Integrity (I):
            </Col>
            <Col $type={LayoutTypes.fieldValue}>
              {capitalize(cvssMetricV31.cvssData?.integrityImpact)}
            </Col>
          </Row>

          <Row $type={LayoutTypes.field}>
            <Col $type={LayoutTypes.fieldName} lg={4}>
              Availability (A):
            </Col>
            <Col $type={LayoutTypes.fieldValue}>
              {capitalize(cvssMetricV31.cvssData?.availabilityImpact)}
            </Col>
          </Row>
        </Col>

        <Col $type={LayoutTypes.card} lg={4}>
          <Row $type={LayoutTypes.title}>
            <AlertDecagramIcon size={16} />
            <span>CVSS v2.0 Severity and Metrics</span>
          </Row>

          <Row $type={LayoutTypes.field}>
            <Col $type={LayoutTypes.fieldName} lg={4}>
              Base Score:
            </Col>
            <Col $type={LayoutTypes.fieldValue}>
              {cvssMetricV2.baseSeverity && (
                <SeverityLabel
                  severity={cvssMetricV2.baseSeverity?.toLowerCase()}
                  label={`${cvssMetricV2.cvssData?.baseScore} ${cvssMetricV2.baseSeverity}`}
                />
              )}
            </Col>
          </Row>

          <Row $type={LayoutTypes.field}>
            <Col $type={LayoutTypes.fieldName} lg={4}>
              Vector:
            </Col>
            <Col $type={LayoutTypes.fieldValue}>
              {cvssMetricV2.cvssData?.vectorString}
            </Col>
          </Row>

          <Row $type={LayoutTypes.field}>
            <Col $type={LayoutTypes.fieldName} lg={4}>
              Impact Score:
            </Col>
            <Col $type={LayoutTypes.fieldValue}>{cvssMetricV2.impactScore}</Col>
          </Row>

          <Row $type={LayoutTypes.field}>
            <Col $type={LayoutTypes.fieldName} lg={4}>
              Exploitability Score:
            </Col>
            <Col $type={LayoutTypes.fieldValue}>
              {cvssMetricV2.exploitabilityScore}
            </Col>
          </Row>

          <Row $type={LayoutTypes.field}>
            <Col $type={LayoutTypes.fieldName} lg={4}>
              Access Vector (AV):
            </Col>
            <Col $type={LayoutTypes.fieldValue}>
              {capitalize(cvssMetricV2.cvssData?.accessVector)}
            </Col>
          </Row>

          <Row $type={LayoutTypes.field}>
            <Col $type={LayoutTypes.fieldName} lg={4}>
              Access Complexity (AC):
            </Col>
            <Col $type={LayoutTypes.fieldValue}>
              {capitalize(cvssMetricV2.cvssData?.accessComplexity)}
            </Col>
          </Row>

          <Row $type={LayoutTypes.field}>
            <Col $type={LayoutTypes.fieldName} lg={4}>
              Authentication (AU):
            </Col>
            <Col $type={LayoutTypes.fieldValue}>
              {capitalize(cvssMetricV2.cvssData?.authentication)}
            </Col>
          </Row>

          <Row $type={LayoutTypes.field}>
            <Col $type={LayoutTypes.fieldName} lg={4}>
              Confidentiality (C):
            </Col>
            <Col $type={LayoutTypes.fieldValue}>
              {capitalize(cvssMetricV2.cvssData?.confidentialityImpact)}
            </Col>
          </Row>

          <Row $type={LayoutTypes.field}>
            <Col $type={LayoutTypes.fieldName} lg={4}>
              Integrity (I):
            </Col>
            <Col $type={LayoutTypes.fieldValue}>
              {capitalize(cvssMetricV2.cvssData?.integrityImpact)}
            </Col>
          </Row>

          <Row $type={LayoutTypes.field}>
            <Col $type={LayoutTypes.fieldName} lg={4}>
              Availability (A):
            </Col>
            <Col $type={LayoutTypes.fieldValue}>
              {capitalize(cvssMetricV2.cvssData?.availabilityImpact)}
            </Col>
          </Row>
        </Col>

        <Col $type={LayoutTypes.card} lg={4}>
          <Row $type={LayoutTypes.title}>
            <InformationIcon size={16} />
            <span>IPs labeled with this CVE</span>
          </Row>

          <Row>
            <Col sm={12} item container={false}>
              <Table
                id="CVE_Details_Ip_Table"
                data={tableData}
                columns={columns}
                sortBy={sortBy}
                pageSize={10}
                minRows={10}
                fillWithEmptyRows
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <small>
          This product uses data from the NVD API but is not endorsed or
          certified by the NVD.
        </small>
      </Row>
    </Col>
  );
};

export default CveLookup;
