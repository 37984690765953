import { ContextTypes } from '@/models/ContextTypes';

export const getRecordModalTitle = (context, item) => {
  if (!item) {
    return '';
  }

  let recordType = '';
  switch (context) {
    case ContextTypes.flow:
      recordType = 'Flow Record';
      break;
    case ContextTypes.dns:
      recordType = 'DNS Record';
      break;
    case ContextTypes.alerts:
      recordType = 'Event Record';
      break;
    case ContextTypes.blocks:
      recordType = 'Block Record';
      break;
    case ContextTypes.audit:
      recordType = 'Audit Log Record';
      break;
    default:
      break;
  }
  return `${recordType} — ${item?.id}`;
};
