import styled from 'styled-components';

const Separator = styled.div`
  width: 30px;
  height: ${({ $width }) => $width}px;
  background: ${({ theme }) => theme.colorSeparator};
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: ${({ $marginLeft }) => $marginLeft}px;
`;

export default Separator;
