import styled from 'styled-components';

import { TabsContainer as TabsContainerOrigin } from '+components/Tabs';

const TabsContainer = styled(TabsContainerOrigin)`
  height: 100%;
  max-height: 100%;
  overflow: hidden;
`;

export default TabsContainer;
