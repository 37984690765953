/* eslint-disable react/prop-types */
import { cloneElement, useMemo } from 'react';

import { useFlag } from '@unleash/proxy-client-react';
import styled from 'styled-components';

import ContentCopyIcon from 'mdi-react/ContentCopyIcon';
import MagnifyIcon from 'mdi-react/MagnifyIcon';

import { ContextTypes } from '@/models/ContextTypes';
import SourceTypes from '@/models/SourceTypes';

import Tag from '+components/Tag';
import { UnderlineMixin } from '+theme/mixins/underlineMixin';
import { getSearchUrl } from '+utils/getSearchUrl';

import CellWrapper from './CellWrapper';

const SearchNqlContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 11px;

  .label {
    overflow: hidden;
    height: 21px;
    min-width: 60px;
    max-width: 60px;
    padding: unset;
  }

  > * + * {
    margin-left: 5px;
  }
`;

const SearchNql = styled.span`
  font-family: 'Courier New', Courier, monospace;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: wrap;

  ${UnderlineMixin}
`;

const CellNqlSearch = ({ value, type, original, disabled, context }) => {
  const isDnsEnabled = useFlag('DNS');

  const additionalMenuOptions = useMemo(
    () => (!value
      ? []
      : [
        {
          // fake menu option
          icon: <MagnifyIcon />,
          text: 'Follow Stream',
          disabled: true,
        },
        {
          url: getSearchUrl({
            context: ContextTypes.flow,
            nql: value,
            customer: original?.customer,
          }),
          icon: <MagnifyIcon />,
          text: 'Search Flow',
          disabled: context && context !== ContextTypes.flow,
        },
        {
          // fake menu option
          icon: <MagnifyIcon />,
          text: 'Search Events',
          disabled: true,
        },
        {
          // fake menu option
          icon: <MagnifyIcon />,
          text: 'Search Blocks',
          disabled: true,
        },
        isDnsEnabled && {
          url: getSearchUrl({
            context,
            nql: value,
            customer: original?.customer,
          }),
          icon: <MagnifyIcon />,
          text: 'Search DNS',
          disabled: context !== ContextTypes.dns,
        },
        {
          icon: <ContentCopyIcon />,
          text: 'Copy NQL',
          copyToClipboard: value,
        },
      ]
    ).filter(Boolean),
    [value],
  );

  return (
    <CellWrapper
      customer={original?.customer}
      title="Flow NQL"
      additionalMenuOptions={additionalMenuOptions}
      disabled={disabled || !value}
    >
      {(type || value) && (
        <SearchNqlContainer>
          {type && (
            <Tag>
              {SourceTypes[type]?.icon
                ? cloneElement(SourceTypes[type]?.icon, { size: 18 })
                : type}
            </Tag>
          )}
          {value && (
            <SearchNql title={value} disabled={disabled}>
              {value}
            </SearchNql>
          )}
        </SearchNqlContainer>
      )}
    </CellWrapper>
  );
};

export default CellNqlSearch;
