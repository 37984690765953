import {
  MenuColumnFactory,
  NumberColumnFactory,
  TimestampColumnFactory,
} from '+components/Table/Columns';
import { SelectColumnFilter } from '+components/Table/Filters';
import dayjs, { DateFormat } from '+utils/dayjs';

const numberColumnSize = { width: 120 };
const numberCell = ({ value }) => (value ? Number.parseFloat(value?.toFixed(3)) : 'n/a');

const getStrategyColumn = (strategyField) => {
  if (strategyField) {
    return NumberColumnFactory({
      accessor: strategyField?.toLowerCase(),
      Header: strategyField?.charAt(0).toUpperCase() + strategyField.slice(1),
      ...numberColumnSize,
      Cell: numberCell,
    });
  }
  return undefined;
};

export const getColumns = ({ cxActionMenu, strategyField }) => {
  const columns = [
    TimestampColumnFactory({
      accessor: 'timestamp',
      Cell: ({ value }) => dayjs(value).format(DateFormat.minute),
      width: 160,
    }),
    {
      accessor: 'operation',
      Header: 'Operation',
      minWidth: 50,
      maxWidth: 200,
      Cell: (row) => `${row.value}`.toLowerCase(),
      Filter: SelectColumnFilter({
        optionLabel: (key) => (key === 'all' ? 'All' : key.toLowerCase()),
      }),
      filter: 'selectFilter',
    },
    NumberColumnFactory({
      accessor: 'deviation',
      Header: 'Deviation',
      ...numberColumnSize,
      Cell: numberCell,
    }),
    NumberColumnFactory({
      accessor: ({ threshold }) => threshold.find((thresh) => thresh.level === 'low')?.value,
      Header: 'Low',
      ...numberColumnSize,
      Cell: numberCell,
    }),
    NumberColumnFactory({
      accessor: ({ threshold }) => threshold.find((thresh) => thresh.level === 'medium')?.value,
      Header: 'Medium',
      ...numberColumnSize,
      Cell: numberCell,
    }),
    NumberColumnFactory({
      accessor: ({ threshold }) => threshold.find((thresh) => thresh.level === 'high')?.value,
      Header: 'High',
      ...numberColumnSize,
      Cell: numberCell,
    }),

    MenuColumnFactory({ cxActionMenu }),
  ];
  const strategyColumn = getStrategyColumn(strategyField);
  if (strategyColumn) {
    columns.splice(2, 0, strategyColumn);
  }
  return columns;
};
