import { Route, Routes } from 'react-router-dom';

import FlowTags from '@/pages/FlowTags';
import FlowTagAdd from '@/pages/FlowTags/components/FlowTagAdd';
import FlowTagEdit from '@/pages/FlowTags/components/FlowTagEdit';

import { CrumbRoute } from '+components/Breadcrumb';

const FlowTagsRoutes = () => (
  <Routes>
    <Route
      path="add"
      element={<CrumbRoute title="Add Flow Tag" component={FlowTagAdd} />}
    />
    <Route path=":flowtagid/*" element={<FlowTagEdit />} />
    <Route path="*" element={<FlowTags />} />
  </Routes>
);

export default FlowTagsRoutes;
