import PropTypes from '+prop-types';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import styled from 'styled-components';

import PermissionModel from '@/models/Permission';

import { actions as responseIntegrationsActions } from '@/redux/api/integrations/response';

import Alert from '+components/Alert';
import Button, { ButtonVariants } from '+components/Button';
import { useFormState } from '+components/form/FinalForm';
import { Group, Label } from '+components/form/FormField';
import Plaintext from '+components/form/Plaintext';
import FormWizard, { Step } from '+components/FormWizard';
import { PluginLabel } from '+components/Labels/PluginLabel';
import { ActionsContainer } from '+components/Layout';
import * as toast from '+components/toast';
import useEvent from '+hooks/useEvent';
import usePermissions from '+hooks/usePermissions';

import NoData from '../shared/NoData';
import { uiToParams } from './components/utils';

const InlineAlert = styled(Alert)`
  width: calc(100% - 140px);
  margin-left: 20px;
  white-space: pre-line;
`;

const FormBody = (props) => {
  const { model, mode, disableSubmit, disabled, errorMessage } = props;

  const dispatch = useDispatch();
  const permissions = usePermissions(
    PermissionModel.Resources.integration.value,
  );

  const { values: formValues, errors: formErrors } = useFormState({
    subscription: { values: true, errors: true },
  });
  const hasTemplate = !!formValues.template;

  const Fields = useMemo(
    () => model.component,
    [model.component],
  );

  const onTestClick = useEvent(() => {
    if (Object.keys(formErrors).length) {
      toast.info(
        'Please ensure all required fields are provided before testing the integration',
      );
    } else {
      const integrationToTest = uiToParams(model.name, formValues);
      dispatch(responseIntegrationsActions.testPlugin(integrationToTest));
    }
  });

  const getCustomMsg = () => {
    switch (model.name) {
      case 'email':
        return 'The email format has been customized.';
      case 'webhook':
        return 'Custom json mappings have been applied.';
      default:
        return 'Custom template applied.';
    }
  };

  return !Fields ? null : (
    <Fragment>
      {(mode === 'edit' || mode === 'add') && (
        <ActionsContainer>
          <Button
            variant={ButtonVariants.outlined}
            onClick={onTestClick}
            disabled={!permissions?.create}
          >
            Test Integration
          </Button>
        </ActionsContainer>
      )}

      {mode === 'edit' && hasTemplate && (
        <Group>
          <Label />
          <InlineAlert severity="info" className="inlineAlert">
            {getCustomMsg()} Please contact{' '}
            <a
              href="https://support.netography.com"
              target="_blank"
              rel="noreferrer noopener"
            >
              support
            </a>{' '}
            to request changes.
          </InlineAlert>
        </Group>
      )}
      {errorMessage && (
        <Group>
          <Label />
          <InlineAlert severity="error">{`${errorMessage}`}</InlineAlert>
        </Group>
      )}

      <Group>
        <Label>Provider</Label>
        <Plaintext>
          <PluginLabel adapter={model.name} title={model.title} size={14} />
        </Plaintext>
      </Group>

      <Fields mode={mode} disableSubmit={disableSubmit} disabled={disabled} />
    </Fragment>
  );
};

FormBody.propTypes = {
  mode: PropTypes.string.isRequired,
  model: PropTypes.shape({
    group: PropTypes.string,
    title: PropTypes.string,
    name: PropTypes.string,
    component: PropTypes.children,
  }).isRequired,
  disableSubmit: PropTypes.func,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
};

FormBody.defaultProps = {
  disableSubmit: false,
  disabled: false,
  errorMessage: null,
};

const Form = (props) => {
  const {
    mode,
    model,
    initialValues,
    isFetching,
    confirmButtonText,
    deleteButtonText,
    deleteButtonHidden,
    onSubmit,
    onCancel,
    onDelete,
    errorMessage,
  } = props;

  const [submitDisabled, setSubmitDisabled] = useState(false);

  const permissions = usePermissions(
    PermissionModel.Resources.integration.value,
  );
  const canManage = mode === 'add' ? permissions?.create : permissions?.update;
  const canRemove = mode !== 'add' && permissions?.delete;
  const disabled = !canManage || isFetching || submitDisabled;

  const doSubmit = useCallback(
    (values) => {
      setSubmitDisabled(false);
      onSubmit(values);
    },
    [onSubmit],
  );

  const doCancel = useCallback(
    () => {
      setSubmitDisabled(false);
      onCancel();
    },
    [onCancel],
  );

  return !model.component ? (
    <NoData>No Plugin Form Component Found</NoData>
  ) : (
    <FormWizard
      item={model.name}
      initialValues={initialValues}
      loading={isFetching}
      disabled={disabled}
      confirmButtonText={confirmButtonText}
      deleteButtonText={deleteButtonText}
      onDelete={onDelete}
      deleteButtonHidden={deleteButtonHidden}
      deleteButtonDisabled={!canRemove || isFetching}
      onSubmit={doSubmit}
      onCancel={doCancel}
    >
      <Step>
        <FormBody
          model={model}
          mode={mode}
          disableSubmit={setSubmitDisabled}
          disabled={disabled}
          errorMessage={errorMessage}
        />
      </Step>
    </FormWizard>
  );
};

Form.propTypes = {
  mode: PropTypes.string.isRequired,
  model: PropTypes.shape({
    group: PropTypes.string,
    title: PropTypes.string,
    name: PropTypes.string,
    component: PropTypes.children,
  }).isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  isFetching: PropTypes.bool.isRequired,
  confirmButtonText: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  deleteButtonText: PropTypes.string,
  deleteButtonHidden: PropTypes.bool,
  onDelete: PropTypes.func,
  errorMessage: PropTypes.string,
};

Form.defaultProps = {
  deleteButtonText: null,
  deleteButtonHidden: true,
  onDelete: null,
  errorMessage: null,
};

export default Form;
