import styled from 'styled-components';

export default styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border: 1px solid ${({ theme }) => theme.colorFieldsBorderInvalid};
  border-radius: 4px;
  font-size: 13px;
  font-weight: 600;
`;
