import { Fragment, useMemo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useToggle } from 'react-use';

import PermissionModel from '@/models/Permission';
import RoutePaths from '@/models/RoutePaths';

import {
  actions as allowlistActions,
  selectors as allowlistSelectors,
} from '@/redux/api/allowlists';

import { Breadcrumb } from '+components/Breadcrumb';
import Button, { ButtonVariants } from '+components/Button';
import ConfirmModal from '+components/ConfirmModal';
import EditPageAuditLogTabs from '+components/EditPageAuditLogTabs';
import { Field } from '+components/form/FinalForm';
import { Group, Label, FieldContainer } from '+components/form/FormField';
import TableField from '+components/form/TableField';
import TextField from '+components/form/TextField';
import { validateRequired } from '+components/form/Validators';
import FormWizard, { Step } from '+components/FormWizard';
import { ActionsContainer, Col, Row } from '+components/Layout';
import { UniversalCell } from '+components/Table/Cells';
import {
  BaseColumnFactory,
  MenuColumnFactory,
} from '+components/Table/Columns';
import useIpLabels from '+hooks/useIpLabels';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import usePermissions from '+hooks/usePermissions';
import useUIProperty from '+hooks/useUIProperty';

import AddIndividuallyForm from './AddIndividuallyForm';
import ImportCsvForm from './ImportCsvForm';

const ColumnTypes = {
  ip: 'ip',
  ipname: 'ipname',
  menu: 'menu',
};

const ColumnCollection = {
  [ColumnTypes.ip]: BaseColumnFactory({
    accessor: ColumnTypes.ip,
    Header: 'IP',
    Cell: (cell) => UniversalCell(ColumnTypes.ip)({
      ...cell,
      options: {
        fetchLabels: true,
      },
    }),
  }),
  [ColumnTypes.ipname]: BaseColumnFactory({
    accessor: ColumnTypes.ipname,
    Header: 'Labels',
    Cell: UniversalCell(ColumnTypes.ipname),
  }),
  [ColumnTypes.menu]: MenuColumnFactory,
};

const defaultAllowlist = {
  name: '',
  description: '',
  whitelist: [],
};

const sortBy = [
  {
    id: 'ip',
    desc: false,
  },
];

const WhitelistForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routeParams = useParams();
  const isAdding = routeParams?.id === 'add';
  const id = isAdding ? undefined : routeParams?.id;

  const permissions = usePermissions(PermissionModel.Resources.whitelist.value);
  const allowlist = useSelector(allowlistSelectors.getAllowlist(id));
  const isWhitelistsFetching = useSelector(allowlistSelectors.isFetching);

  const { ipLabelsHash } = useIpLabels();

  const [showAddIndividuallyModal, toggleAddIndividuallyModal] = useToggle(false);
  const [showImportCsvModal, toggleImportCsvModal] = useToggle(false);
  const [showDeleteModal, setShowDeleteModal] = useToggle(false);

  const canManage = allowlist?.id ? permissions?.update : permissions?.create;
  const canRemove = allowlist?.id && permissions?.delete;

  useLoadingIndicator(isWhitelistsFetching);

  const notFound = useMemo(
    () => !isWhitelistsFetching && id && allowlist?.id !== id,
    [isWhitelistsFetching, id, allowlist?.id],
  );

  const format = useCallback(
    (value) => (value || []).map((ip) => ({
      [ColumnTypes.ip]: ip,
      [`${ColumnTypes.ip}name`]: ipLabelsHash[ip]?.name,
    })),
    [ipLabelsHash],
  );

  const onCancel = useCallback(
    () => {
      navigate(`${RoutePaths.allowlists}`);
    },
    [],
  );

  const onSubmit = useCallback(
    (values) => {
      if (values.id) {
        dispatch(allowlistActions.updateAllowlist(values));
      } else {
        dispatch(allowlistActions.createAllowlist(values));
      }
      onCancel();
    },
    [onCancel],
  );

  const onDeleteModalToggle = useCallback(
    () => {
      setShowDeleteModal((prevValue) => !prevValue);
    },
    [],
  );

  const onDelete = useCallback(
    () => {
      dispatch(allowlistActions.deleteAllowlist(allowlist));
      onCancel();
    },
    [allowlist, onCancel],
  );

  useEffect(
    () => {
      if (!id || allowlist?.id === id) {
        return undefined;
      }
      dispatch(allowlistActions.fetchAllowlists());
      return () => {
        dispatch(allowlistActions.cancel());
      };
    },
    [id, allowlist],
  );

  const [, setMasqueradeUrl] = useUIProperty('masqueradeUrl');
  useEffect(
    () => {
      if (!isAdding) {
        setMasqueradeUrl(`${RoutePaths.allowlists}`);
      }
      return () => {
        setMasqueradeUrl(null);
      };
    },
    [isAdding],
  );

  const title = `${isAdding ? 'Add' : 'Edit'} Allow List`;

  return (
    <Fragment>
      <ActionsContainer>
        {/* <div style={{ width: '125px' }} /> */}

        <Button disabled={!canManage} onClick={toggleAddIndividuallyModal}>
          Add IP Individually
        </Button>

        <Button
          variant={ButtonVariants.outlined}
          disabled={!canManage}
          onClick={toggleImportCsvModal}
        >
          Import CSV
        </Button>
      </ActionsContainer>

      <EditPageAuditLogTabs
        showTabs={!isAdding}
        auditNqlQuery={`class == whitelist && original_id == ${allowlist?.id}`}
        breadcrumb={<Breadcrumb title={title} />}
      >
        <Fragment>
          {notFound && (
            <Row>
              <Col alignItems="center" style={{ margin: '5% 0' }}>
                <h3>Allow List not found</h3>
                <Link to={`${RoutePaths.allowlists}`}>
                  Go to Manage Allow List page
                </Link>
              </Col>
            </Row>
          )}

          {!notFound && (
            <FormWizard
              initialValues={allowlist || defaultAllowlist}
              onSubmit={onSubmit}
              onCancel={onCancel}
              loading={isWhitelistsFetching}
              disabled={isWhitelistsFetching || !canManage}
              deleteButtonText="Delete Allow List"
              onDelete={onDeleteModalToggle}
              deleteButtonHidden={!allowlist?.id}
              deleteButtonDisabled={!canRemove}
            >
              <Step>
                <Row gap="10px" wrap="nowrap" alignItems="center">
                  <Col
                    className="card__title"
                    container={false}
                    item
                    style={{ marginLeft: '140px' }}
                  >
                    <h5 className="head">Manage Allow List</h5>
                  </Col>
                </Row>

                <Group>
                  <Label>Allow List</Label>
                  <FieldContainer>
                    <Col gap="8px">
                      <Row
                        gap="10px"
                        alignItems="flex-end"
                        wrap="nowrap"
                        style={{
                          height: '22px',
                          lineHeight: '12px',
                          fontSize: '13px',
                          fontWeight: '600',
                        }}
                      >
                        <Col container={false} xs={4} item>
                          Name*
                        </Col>
                        <Col container={false} xs={8} item>
                          Description
                        </Col>
                      </Row>

                      <Row gap="10px" alignItems="flex-start" wrap="nowrap">
                        <Col container={false} xs={4} item>
                          <Field
                            name="name"
                            component={TextField}
                            validate={validateRequired}
                            disabled={!canManage}
                            required
                          />
                        </Col>
                        <Col container={false} xs={8} item>
                          <Field
                            name="description"
                            component={TextField}
                            disabled={!canManage}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </FieldContainer>
                </Group>

                <Group>
                  <Label required>IP Addresses</Label>
                  <FieldContainer>
                    <Row>
                      <Col container={false} item xs={12}>
                        <Field
                          name="whitelist"
                          component={TableField}
                          sortBy={sortBy}
                          columnTypes={ColumnTypes}
                          columnCollection={ColumnCollection}
                          noDataText="No IP Addresses set"
                          showManagerLayout={false}
                          pageSize={10}
                          format={format}
                          parse={(value) => (value || []).map((el) => el.ip)}
                          validate={validateRequired}
                          disabled={!canManage}
                          required
                        />
                      </Col>
                    </Row>
                  </FieldContainer>
                </Group>

                {showAddIndividuallyModal && (
                  <AddIndividuallyForm
                    initialValues={{}}
                    onToggle={toggleAddIndividuallyModal}
                    isOpen
                  />
                )}

                {showImportCsvModal && (
                  <ImportCsvForm
                    initialValues={{}}
                    onToggle={toggleImportCsvModal}
                    isOpen
                  />
                )}
              </Step>
            </FormWizard>
          )}

          {showDeleteModal && (
            <ConfirmModal
              item={`${allowlist?.name} allow list`}
              onToggle={onDeleteModalToggle}
              onConfirm={onDelete}
              isOpen
            />
          )}
        </Fragment>
      </EditPageAuditLogTabs>
    </Fragment>
  );
};

export default WhitelistForm;
