import PropTypes from '+prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'react-use';

import isEqual from 'lodash.isequal';
import isFunction from 'lodash.isfunction';
import upperFirst from 'lodash.upperfirst';

import EqualIcon from 'mdi-react/EqualIcon';
import MagnifyIcon from 'mdi-react/MagnifyIcon';

import {
  createFilterValue,
  FilterOperator,
} from '+components/Table/FilterTypeFactories';

import { Container, Input, Row, Select } from './Components';

const allValue = 'all';

const defaultOptionValueExtractor = (row, id) => row.values[id];
const defaultOptionIcon = () => null;
const defaultOptionLabel = (value) => upperFirst(value || '') || '<empty>';

const prevOrNext = (prev, next) => {
  if (
    prev?.length !== next?.length
    || JSON.stringify(prev || []) !== JSON.stringify(next || [])
  ) {
    return next;
  }

  return prev;
};

const findDeep = (options, value) => {
  let item;
  options.forEach((option) => {
    if (item) {
      return;
    }

    if (option.options) {
      item = findDeep(option.options, value);
      return;
    }

    if (option.value === value) {
      item = option;
    }
  });

  return item;
};

/**
 * @param {Object} [properties]
 * @param {function(row: {}, id: string): any} [properties.optionValueExtractor] - callback for extracting options values, default: `(row, id) => row.values[id]`
 * @param {function(value: string): string} [properties.optionLabel] - callback for rendering title of option, default: `(value) => upperFirst(value)`
 * @param {function(value: string): JSX.Element} [properties.optionIcon] - callback for rendering icon of option, default: `(value) => null`
 * @param {boolean|Function} [properties.sort] - values should be sort, default: true
 * @param {string} [properties.defaultValue] - provides default value for filter selector
 * @param {string[]} [properties.fixedOptions] - array of fixed options
 * @param {Object} [properties.selectProps] - props for `Select` component
 * @param {boolean} [properties.enableLikeFilter] - enable like filter
 * @return {function(*): JSX.Element}
 */
export const SelectColumnFilter = (properties) => {
  const {
    optionValueExtractor,
    optionIcon,
    optionLabel,
    defaultValue,
    sort = true,
    fixedOptions = null,
    selectProps,
    enableLikeFilter = false,
  } = properties || {};
  const fnOptionValueExtractor = isFunction(optionValueExtractor)
    ? optionValueExtractor
    : defaultOptionValueExtractor;
  const fnOptionIcon = isFunction(optionIcon) ? optionIcon : defaultOptionIcon;
  const fnOptionLabel = isFunction(optionLabel)
    ? optionLabel
    : defaultOptionLabel;

  const Component = (props) => {
    const {
      preFilteredRows,
      column: { id, filterValue, setFilter },
    } = props;

    const [valuesForOptions, setValuesForOptions] = useState([allValue]);
    const [localValue, setLocalValue] = useState({
      value: undefined,
      operator: undefined,
    });

    useEffect(
      () => {
        if (defaultValue) {
          setFilter(defaultValue);
        }
      },
      [],
    );

    const options = useMemo(
      () => valuesForOptions.map((value) => {
        if (value?.value != null || value?.options) {
          return value;
        }

        return {
          value,
          icon: fnOptionIcon(value),
          label: fnOptionLabel(value),
        };
      }),
      [valuesForOptions, fnOptionIcon, fnOptionLabel],
    );

    const onSelectChange = useCallback(
      (nextOperator) => (item) => {
        const nextValue = item?.value ?? allValue;
        setLocalValue({
          value: nextValue,
          operator:
            nextValue == null || nextValue === '' ? undefined : nextOperator,
        });
      },
      [],
    );

    const onTextChange = useCallback(
      (nextOperator) => (event) => {
        const nextValue = event?.target?.value;
        setLocalValue({
          value: nextValue,
          operator:
            nextValue == null || nextValue === '' ? undefined : nextOperator,
        });
      },
      [],
    );

    const selectValue = useMemo(
      () => {
        if (localValue.operator !== FilterOperator.equal) {
          return allValue;
        }
        return findDeep(options, localValue.value ?? allValue);
      },
      [localValue, options],
    );

    const textValue = useMemo(
      () => (localValue.operator === FilterOperator.like
        ? localValue.value ?? ''
        : ''),
      [localValue],
    );

    useEffect(
      () => {
        if (Array.isArray(fixedOptions)) {
          setValuesForOptions((prev) => prevOrNext(prev, fixedOptions));
          return;
        }

        const hash = preFilteredRows
          .flatMap((row) => fnOptionValueExtractor(row, id))
          .reduce((acc, item) => {
            const value = item?.value ?? item;
            if (value == null || acc[value] != null) {
              return acc;
            }
            acc[value] = item;
            return acc;
          }, {});

        if (filterValue?.value && filterValue.value !== allValue) {
          const isFilterValueInSet = hash[filterValue.value] != null;
          if (!isFilterValueInSet) {
            hash[filterValue.value] = filterValue.value;
          }
        }

        delete hash[allValue];

        let result = Object.values(hash);
        if (sort) {
          result = isFunction(sort) ? result.sort(sort) : result.sort();
        }
        result.unshift(allValue);

        setValuesForOptions((prev) => prevOrNext(prev, result));
      },
      [
        preFilteredRows,
        fnOptionValueExtractor,
        id,
        sort,
        fixedOptions,
        filterValue,
      ],
    );

    useEffect(
      () => {
        setLocalValue((prevValue) => {
          const nextValue = {
            value: filterValue?.value ?? undefined,
            operator: filterValue?.operator ?? undefined,
          };
          return isEqual(prevValue, nextValue) ? prevValue : nextValue;
        });
      },
      [filterValue],
    );

    useDebounce(
      () => {
        if (localValue?.value == null || localValue?.value === '') {
          setFilter(undefined);
          return;
        }
        setFilter(createFilterValue(localValue));
      },
      300,
      [localValue],
    );

    return (
      <Container>
        {enableLikeFilter && (
          <Row>
            <MagnifyIcon size={24} />
            <Input
              type="text"
              value={textValue}
              onChange={onTextChange(FilterOperator.like)}
            />
          </Row>
        )}

        <Row>
          <EqualIcon size={24} />
          <Select
            {...(selectProps || {})}
            options={options}
            value={selectValue}
            onChange={onSelectChange(FilterOperator.equal)}
          />
        </Row>
      </Container>
    );
  };

  Component.propTypes = {
    preFilteredRows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    column: PropTypes.shape().isRequired,
    setFilter: PropTypes.func.isRequired,
  };

  return Component;
};
