import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: ${({ $width }) => $width}px;
  min-width: ${({ $width }) => $width}px;
  border-left: ${({ $separatorWidth }) => $separatorWidth}px solid
    ${({ theme }) => theme.colorBackgroundSeparator};
`;

export default Container;
