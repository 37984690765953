import PropTypes from '+prop-types';
import { Fragment, useRef, useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import classNames from 'classnames';
import styled from 'styled-components';

import Collapse from '@mui/material/Collapse';

import ChevronRightIcon from 'mdi-react/ChevronRightIcon';

import {
  sidebarLeftCollapsedMenuLeftOffset,
  sidebarLeftCollapsedMenuWidth,
} from '@/pages/Layout/shared/constants';

import ScrollBar from '+components/ScrollBar';
import childrenMap from '+utils/childrenMap';

const loadFromStorage = (name) => localStorage.getItem(name);
const saveToStorage = (name, value) => localStorage.setItem(name, value);

const Group = styled((props) => {
  const { className, icon, title, collapse, children } = props;

  const location = useLocation();
  const childContainerRef = useRef(null);
  const [id] = useState(btoa(title));
  const [active, setActive] = useState(false);
  const [opened, setOpened] = useState(loadFromStorage(id) === 'true');

  const onGroupMouseEnter = useCallback(
    () => {
      const { current: childContainerElement } = childContainerRef;
      if (!collapse || !childContainerElement) {
        return;
      }

      const [childElement] = childContainerElement.getElementsByClassName('simplebar-content');
      if (!childElement) {
        return;
      }

      childContainerElement.removeAttribute('style');
      const { top: childTop } = childContainerElement.getBoundingClientRect();
      const { height: childHeight } = childElement.getBoundingClientRect();
      const { innerHeight: windowHeight } = window;
      const marginBottom = 16;
      if (childTop + childHeight + marginBottom > windowHeight) {
        const groupItemHeight = 49;
        const groupItemTitleHeight = 30;
        const menuPaddingY = 30;
        const height = windowHeight
        - childTop
        + groupItemHeight
        - menuPaddingY
        - groupItemTitleHeight
        - marginBottom;
        childContainerElement.style.height = `${height}px`;
      }
    },
    [collapse, childContainerRef],
  );

  const onGroupMouseLeave = useCallback(
    () => {
      const { current: childContainerElement } = childContainerRef;
      if (!childContainerElement) {
        return;
      }

      childContainerElement.removeAttribute('style');
    },
    [childContainerRef],
  );

  const onHeaderClick = useCallback(
    () => {
      if (collapse) {
        return;
      }
      setOpened((prevValue) => {
        saveToStorage(id, !prevValue);
        return !prevValue;
      });
    },
    [collapse, id],
  );

  useEffect(
    () => {
      if (!children) {
        return;
      }

      let _active = false;

      childrenMap(children, (childProps) => {
        const { route, links } = childProps;
        if (route && location.pathname.includes(route)) {
          _active = true;
        }
        if (
          links
        && links.findIndex((datum) => datum[1] === location.pathname) !== -1
        ) {
          _active = true;
        }
      });

      setActive(_active);
      setOpened((prevValue) => _active || prevValue);
    },
    [children, location.pathname],
  );

  let content = (
    <Fragment>
      {collapse && <span className="sidebar__group-title">{title}</span>}
      {children}
    </Fragment>
  );

  if (collapse) {
    content = (
      <ScrollBar style={{ height: '100%', overflowX: 'hidden' }}>
        {content}
      </ScrollBar>
    );
  }

  return (
    <div
      className={classNames(className, 'sidebar__group', {
        'sidebar__group--active': active,
        'sidebar__group--collapsed': collapse,
        'sidebar__group--opened': opened,
      })}
      onMouseEnter={onGroupMouseEnter}
      onMouseLeave={onGroupMouseLeave}
    >
      <button
        className="sidebar__group-header"
        type="button"
        onClick={onHeaderClick}
      >
        <span className="sidebar__group-icon">{icon}</span>
        <span className="sidebar__group-title">{title}</span>
        <span className="sidebar__group-expand-icon">
          <ChevronRightIcon />
        </span>
      </button>
      <Collapse className="sidebar__group-child-wrap" in={collapse || opened}>
        <div ref={childContainerRef} className="sidebar__group-child">
          {content}
        </div>
      </Collapse>
    </div>
  );
})`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 13px 6px 0 5px;
  padding: 0;
  z-index: 9999999;

  .sidebar__group-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    cursor: pointer;
    height: 30px;
    padding: 11px 4px 11px 13px;
    overflow: hidden;
    border: none;
    border-radius: 15px;
    background-color: ${({ theme }) => theme.sideBarGroupItemBackground};
    gap: 4px;

    &:hover {
      background-color: ${({ theme }) => theme.sideBarGroupItemHoverBackground};
    }

    .sidebar__group-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      .mdi-icon {
        width: 15px;
        color: ${({ theme }) => theme.colorSideBarLeftGroupIcon};
      }
    }

    .sidebar__group-title {
      font-size: 13px;
      font-weight: bold;
      text-transform: uppercase;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: left;
      overflow: hidden;
      color: ${({ theme }) => theme.colorSideBarLeftGroupTitle};
      line-height: 100%;
    }

    .sidebar__group-expand-icon {
      width: 16px;
      display: flex;
      align-items: center;
      margin-left: auto;
      .mdi-icon {
        width: 16px;
        color: ${({ theme }) => theme.colorSideBarLeftGroupIcon};
        transition: transform 0.3s;
      }
    }
  }

  .sidebar__group-child {
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-top: 4px;
  }

  &.sidebar__group--opened {
    .sidebar__group-expand-icon .mdi-icon {
      transform: rotate(90deg);
    }
  }

  &.sidebar__group--collapsed {
    .sidebar__group-header {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      padding: unset;
      cursor: pointer;
      overflow: visible;
      justify-content: center;
    }

    .sidebar__group-icon .mdi-icon {
      width: 26px;
      height: 26px;
      color: ${({ theme }) => theme.colorText};
    }

    .sidebar__group-title {
      display: none;
    }

    .sidebar__group-expand-icon {
      display: none;
    }

    .sidebar__group-child {
      display: none;
      position: absolute;
      top: 0;
      left: ${sidebarLeftCollapsedMenuLeftOffset}px;
      width: ${sidebarLeftCollapsedMenuWidth}px;
      margin: unset;
      padding: 9px 5px;
      border: ${({ $separatorWidth }) => $separatorWidth}px solid
        ${({ theme }) => theme.colorBackgroundSeparator};
      box-shadow: 3px 3px 6px ${({ theme }) => theme.colorBackgroundSeparator};
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      background-color: ${({ theme }) => theme.colorBackground};
      overflow: hidden;

      .simplebar-content {
        display: flex;
        flex-direction: column;
        gap: 2px;
      }

      .sidebar__group-title {
        display: flex;
        cursor: default;
        margin-bottom: 5px;
        padding-left: 15px;
        text-transform: uppercase;
        color: ${({ theme }) => theme.colorTextSecondary};
      }

      .sidebar__category {
        .sidebar__category-header {
          cursor: default;
          :hover {
            background-color: unset;
          }
        }

        .sidebar__category-title {
          text-transform: uppercase;
          white-space: nowrap;
          text-overflow: ellipsis;
          color: ${({ theme }) => theme.colorTextSecondary};
        }

        .sidebar__category-expand-icon .mdi-icon {
          display: none;
        }
      }

      .sidebar__category + .sidebar__category {
        margin-top: 4px;
      }

      .sidebar__link {
        padding-left: 15px;
      }
    }

    &.sidebar__group--active {
      .sidebar__group-icon .mdi-icon {
        color: ${({ theme }) => theme.primary};
      }
    }

    &:hover {
      width: 100%;
      .sidebar__group-child {
        display: unset;
      }
    }
  }
`;

Group.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.shape({}),
  title: PropTypes.string.isRequired,
  collapse: PropTypes.bool,
};

Group.defaultProps = {
  className: '',
  icon: null,
  collapse: false,
};

export default Group;
