import styled from 'styled-components';

export default styled.span`
  line-height: 16px;
  color: ${({ theme }) => theme.formWizardStepTitleBackground} !important;
  text-align: left;
  * {
    color: ${({ theme }) => theme.formWizardStepTitleBackground} !important;
  }

  pointer-events: all;

  &.optional {
    font-style: italic;
    :after {
      content: ' (optional)';
    }
  }
`;
