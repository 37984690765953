/* eslint-disable max-len */
import PropTypes from '+prop-types';
import { memo } from 'react';

const OracleIcon = memo(({ size }) => (
  <svg
    width={size}
    height={size}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    fill="#C74634"
  >
    <g transform="translate(0 7)">
      <path d="M9.9,20.1c-5.5,0-9.9-4.4-9.9-9.9c0-5.5,4.4-9.9,9.9-9.9h11.6c5.5,0,9.9,4.4,9.9,9.9c0,5.5-4.4,9.9-9.9,9.9H9.9 M21.2,16.6c3.6,0,6.4-2.9,6.4-6.4c0-3.6-2.9-6.4-6.4-6.4h-11c-3.6,0-6.4,2.9-6.4,6.4s2.9,6.4,6.4,6.4H21.2" />
    </g>
  </svg>
));

OracleIcon.propTypes = {
  size: PropTypes.number,
};

OracleIcon.defaultProps = {
  size: 24,
};

export { OracleIcon };
