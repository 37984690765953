import { DefaultColumnAggregator } from '+components/Table/Aggregators';
import { DefaultColumnFilter } from '+components/Table/Filters';

import DefaultCell from './DefaultCell';

// TODO: Move to BaseColumnFactory?
export default () => ({
  minWidth: 20,
  width: 'auto',
  Header: ({ column }) => {
    if (column.placeholderOf) {
      return '';
    }

    if (typeof column.accessor === 'string') {
      return column.accessor;
    }

    return column.id;
  },
  ContextMenuHeader: '',
  Cell: DefaultCell,
  Aggregator: DefaultColumnAggregator(),
  Filter: DefaultColumnFilter,
  filter: 'defaultFilter',
  sortType: 'string',
});
