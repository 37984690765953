import RGL, { WidthProvider } from 'react-grid-layout';

import styled from 'styled-components';

export default styled(WidthProvider(RGL))`
  .react-grid-layout {
    position: relative;
    margin-bottom: 40px;
  }

  .react-grid-item {
    transition: none;
    font-family: 'Source Sans Pro', sans-serif;

    .highcharts-exporting-group,
    .highcharts-map-navigation,
    [class^='SeriesSpiralChart'] [class^='Menu__TriggerButton'],
    [class^='ForceDirected'] [class^='Menu__TriggerButton'],
    .gauge-with-sparkline-chart [class^='Menu__TriggerButton'],
    .value-with-sparkline-chart [class^='Menu__TriggerButton'] {
      visibility: hidden;
    }
  }

  .react-grid-item.resizing {
    z-index: 1;
    will-change: width, height;
  }

  .react-grid-item.react-draggable-dragging {
    transition: none;
    z-index: 3;
    will-change: transform;
  }

  .react-grid-item.react-grid-placeholder {
    background: ${({ theme }) => theme.primary};
    opacity: 0.2;
    transition-duration: 100ms;
    z-index: 2;
    user-select: none;
    border-radius: 4px;
  }

  .react-grid-item > .react-resizable-handle {
    z-index: 99;
    display: ${({ isResizable }) => (isResizable ? undefined : 'none')};
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 0;
    right: 0;
    cursor: se-resize;
  }

  .react-grid-item > .react-resizable-handle::after {
    display: ${({ isResizable }) => (isResizable ? undefined : 'none')};
    content: '';
    position: absolute;
    right: 10px;
    bottom: 10px;
    width: 6px;
    height: 6px;
    border-right: 2px solid #54545a;
    border-bottom: 2px solid #54545a;
    visibility: hidden;
  }

  .react-grid-item:hover {
    .highcharts-exporting-group,
    .highcharts-map-navigation,
    [class^='SeriesSpiralChart'] [class^='Menu__TriggerButton'],
    [class^='ForceDirected'] [class^='Menu__TriggerButton'],
    .gauge-with-sparkline-chart [class^='Menu__TriggerButton'],
    .value-with-sparkline-chart [class^='Menu__TriggerButton'],
    > .react-resizable-handle::after {
      visibility: visible;
    }
  }
`;
