import PropTypes from '+prop-types';
import { Fragment, useEffect, useMemo } from 'react';

import { useFlag } from '@unleash/proxy-client-react';
import capitalize from 'lodash.capitalize';

import { WidgetCategories } from '@/models/WidgetCategories';

import ButtonGroupField from '+components/form/ButtonGroupField';
import { Field, useForm, useFormState } from '+components/form/FinalForm';
import { normalizeSelectValue } from '+components/form/Normalizers';
import SelectField from '+components/form/SelectField';
import { validateRequired } from '+components/form/Validators';

import { widgets } from '../../../../shared/widgets';

const ChartTypeFields = (props) => {
  const { name, index } = props;
  const isDnsEnabled = useFlag('DNS');

  const contextOptions = useMemo(
    () => [
      { value: WidgetCategories.flow, label: 'Flow' },
      isDnsEnabled && { value: WidgetCategories.dns, label: 'DNS' },
      { value: WidgetCategories.alerts, label: 'Events' },
      { value: WidgetCategories.blocks, label: 'Blocks' },
      { value: WidgetCategories.audit, label: 'Audit Logs' },
      { value: WidgetCategories.other, label: 'Other' },
    ].filter(Boolean),
    [isDnsEnabled],
  );

  const form = useForm();
  const { values: formValues } = useFormState({
    subscription: { values: true },
  });
  const series0Values = formValues.series[0];
  const seriesValues = formValues.series[index];

  /** * OPTIONS ** */
  // Chart Type Options
  const chartTypeOptions = useMemo(
    () => {
      const widget0Meta = widgets[series0Values?.display?.type] || {};
      return Object.values(widgets)
        .reduce((acc, item) => {
          if (
            item.excludeContext?.includes(seriesValues.context)
          || item.disabled
          ) {
            return acc;
          }
          if (index > 0 && !widget0Meta.canBeCombinedWith?.includes(item.value)) {
            return acc;
          }
          acc.push({
            value: item.value,
            icon: item.icon,
            label: item.label.replace(/\w+/g, capitalize),
          });
          return acc;
        }, [])
        .sort((a, b) => a.label.localeCompare(b.label));
    },
    [seriesValues.context, series0Values, index],
  );

  /** * FIELDS VALUES NORMALIZERS ** */
  // Chart Type Field
  useEffect(
    () => {
      if (!chartTypeOptions.length) {
        return;
      }
      // change widget type to default if it's value not allowed
      const isWidgetTypeAllowed = seriesValues.display?.type != null
      && chartTypeOptions.some((el) => el.value === seriesValues.display?.type);
      if (!isWidgetTypeAllowed) {
        form.change(`${name}.display.type`, chartTypeOptions[0].value);
      }
    },
    [name, chartTypeOptions, seriesValues.display?.type],
  );

  return (
    <Fragment>
      <Field
        name={`${name}.context`}
        label="Category"
        options={contextOptions}
        component={ButtonGroupField}
        validate={validateRequired}
        required
      />

      <Field
        name={`${name}.display.type`}
        label="Widget type"
        component={SelectField}
        options={chartTypeOptions}
        validate={validateRequired}
        parse={normalizeSelectValue}
        required
      />
    </Fragment>
  );
};

ChartTypeFields.propTypes = {
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};

export default ChartTypeFields;
