import styled from 'styled-components';

import FieldsSectionOrigin from '+components/form/FieldsSection';

const FieldsSection = styled(FieldsSectionOrigin)`
  gap: 15px;

  .form__form-group {
    margin-bottom: unset;
  }

  :not(:first-of-type) {
    margin-top: 25px;
  }

  .form__form-group + .form__form-group {
    margin-left: unset;
    margin-bottom: unset;
  }

  .fields-section-label {
    margin-left: 140px;
    margin-bottom: unset;
    text-transform: uppercase;
    font-weight: bold;
  }

  .form__form-group-field {
    align-self: unset !important;
  }
`;
export default FieldsSection;
