import { call, put } from 'redux-saga/effects';

import { actions as toastActions } from '@/redux/toast';
import {
  createSlice,
  createSelector,
  startFetching,
  stopFetching,
  defaultReducers,
} from '@/redux/util';

import backendClient from '@/middleware/backendClient';

export const initialState = {
  isFetching: false,
  error: '',
  automatons: {},
  thresholdValues: {},
};

const apiPath = '/thresholder';

let api;

const initApi = () => {
  if (!api) {
    api = backendClient();
  }
};

const slice = createSlice({
  name: 'thresholder',
  initialState,

  reducers: {
    ...defaultReducers,
    fetchAutomaton: startFetching,
    fetchAutomatonSuccess(state, { payload: automaton }) {
      stopFetching(state);
      state.allFetched = true;
      if (automaton?.algorithm) {
        state.automatons[automaton.algorithm] = automaton;
      }
    },
    updateAutomaton: startFetching,
    updateAutomatonSuccess(state, { payload: automaton }) {
      stopFetching(state);
      if (!automaton) {
        return;
      }
      if (!state.automatons) {
        state.automatons = {};
      }
      state.automatons[automaton.algorithm] = automaton;
    },
    removeAutomaton: startFetching,
    removeAutomatonSuccess(state, { payload: name }) {
      stopFetching(state);
      delete state.automatons[name];
    },
    fetchValuesByGroup: startFetching,
    fetchValuesByGroupSuccess(state, { payload: { data, modelName, group } }) {
      stopFetching(state);
      state.allFetched = true;

      state.thresholdValues[`${modelName}${group}`] = data;
    },
  },

  sagas: (actions) => ({
    [actions.fetchAutomaton]: {
      * saga({ payload: detectionModel }) {
        initApi();
        try {
          const response = yield call(
            api.get,
            `${apiPath}/automaton/${detectionModel.name}`,
          );
          yield put(actions.fetchAutomatonSuccess(response.data.data[0]));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching threshold automaton',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.updateAutomaton]: {
      * saga({ payload }) {
        initApi();
        const {
          silent = false,
          afterCreateMode = false,
          afterUpdateMode = false,
          ...config
        } = payload;
        try {
          const response = yield call(api.post, `${apiPath}/automaton`, config);
          yield put(actions.updateAutomatonSuccess(response.data.data));
          if (afterCreateMode || afterUpdateMode) {
            yield put(
              toastActions.successWithAuditLogVerification({
                message: afterCreateMode
                  ? 'Detection model has been created with auto threshold config'
                  : 'Detection model and auto threshold config have been updated',
                response,
                showWarningOnly: silent,
              }),
            );
            return;
          }
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Auto threshold config updated',
              response,
              showWarningOnly: silent,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          if (afterCreateMode || afterUpdateMode) {
            yield put(
              toastActions.warn({
                message: afterCreateMode
                  ? 'Detection model has been created successfully but there was an error adding the auto threshold config'
                  : 'Detection model has been updated successfully but there was an error updating the auto threshold config',
                details: error.message,
              }),
            );
            return;
          }
          yield put(
            toastActions.error({
              message: 'Error updating auto threshold config',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.removeAutomaton]: {
      * saga({ payload: { afterUpdateMode = false, ...algorithm } }) {
        initApi();
        try {
          const response = yield call(
            api.delete,
            `${apiPath}/automaton/${algorithm.name}`,
          );

          yield put(actions.removeAutomatonSuccess(algorithm.name));
          if (afterUpdateMode) {
            yield put(
              toastActions.successWithAuditLogVerification({
                message:
                  'Detection model has been updated and auto threshold config removed',
                response,
              }),
            );
            return;
          }
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Auto threshold config removed',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          if (afterUpdateMode) {
            yield put(
              toastActions.successWithAuditLogVerification({
                message:
                  'Detection model has been updated but there was an issue removing the auto threshold config',
                details: error.message,
              }),
            );
            return;
          }
          yield put(
            toastActions.error({
              message: 'Error removing auto threshold configuration',
              details: error.message,
            }),
          );
        }
      },
    },
    [actions.fetchValuesByGroup]: {
      * saga({ payload: { modelName, group, to, from } }) {
        initApi();
        try {
          const response = yield call(
            api.get,
            `${apiPath}/threshold/${modelName}/trackby/${group}?to=${to}&from=${from}`,
          );
          yield put(
            actions.fetchValuesByGroupSuccess({
              data: response.data.data,
              modelName,
              group,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching auto threshold data',
              details: error.message,
            }),
          );
        }
      },
    },
  }),

  selectors: (getState) => ({
    isFetching: createSelector(
      [getState],
      (state) => state.isFetching,
    ),

    getError: createSelector(
      [getState],
      (state) => state.error,
    ),

    getAutomaton: (algorithmName) => createSelector(
      [getState],
      (state) => state?.automatons?.[algorithmName],
    ),
    getThresholdValues: (algorithmName, group) => createSelector(
      [getState],
      (state) => state?.thresholdValues?.[`${algorithmName}${group}`],
    ),
  }),
});

export const { actions, selectors } = slice;

export default slice;
