import styled from 'styled-components';

export default styled.div`
  margin-left: 140px;
  width: 720px;
  
  :empty {
    display: none;
  }
`;
