import { useEffect, useMemo, useRef } from 'react';

import { cleanObject } from '+utils/cleanObject';

const emptyFn = () => {};

const isEqual = (a, b) => JSON.stringify(a) === JSON.stringify(b);

/**
 * @param {object} props
 * @param {string} props.id
 * @param {string} props.lastId
 * @param {object} props.defaultPreset
 * @param {object} props.presets
 * @param {object} props.prepared
 * @param {object} props.empty
 * @param {function} props.saveSettings
 */
export const useSilentSettingSave = (props) => {
  const { id, lastId, defaultPreset, presets, prepared, empty, saveSettings } = props;

  const emptyState = useMemo(
    () => cleanObject(empty.current),
    [JSON.stringify(empty.current)],
  );

  const prevState = useMemo(
    () => cleanObject(presets?.[lastId]?.value || prepared || {}),
    [lastId, JSON.stringify(presets), JSON.stringify(prepared)],
  );

  const newState = useMemo(
    () => cleanObject(defaultPreset),
    [JSON.stringify(defaultPreset)],
  );

  // TODO experiment with silently saving
  // saving layout until a user doesn't save it
  const saveDefault = useRef(emptyFn);
  useEffect(
    () => {
      saveDefault.current = emptyFn;

      if (!id) {
        return undefined;
      }

      if (isEqual(prevState, newState)) {
        return undefined;
      }

      const needRemove = isEqual(emptyState, newState);

      if (needRemove && !prepared) {
        return undefined;
      }

      saveDefault.current = () => {
        saveSettings({
          lastId,
          ...(!needRemove && { prepared: newState }),
        });
      };

      window.addEventListener('beforeunload', saveDefault.current, true);

      return () => {
        window.removeEventListener('beforeunload', saveDefault.current, true);
      };
    },
    [id, lastId, emptyState, prevState, newState, JSON.stringify(prepared)],
  );

  useEffect(
    () => () => {
      saveDefault.current();
    },
    [],
  );
};
