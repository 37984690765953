import PropTypes from '+prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions, selectors } from '@/redux/api/rules';

import Form from './Form';

const ModalAddCategory = (props) => {
  const { isOpen, onSuccess, toggleModal, ...tail } = props;

  const dispatch = useDispatch();
  const { isFetching, error } = useSelector(selectors.getState);
  const [isProcess, setIsProcess] = useState(false);

  const onSubmit = useCallback(
    (values) => {
      dispatch(actions.addOrUpdateCategory(values));
      setIsProcess(true);
    },
    [],
  );

  useEffect(
    () => {
      if (!isProcess || isFetching) {
        return;
      }

      if (!error) {
        onSuccess();
      }

      setIsProcess(false);
    },
    [error, isFetching, isProcess, onSuccess],
  );

  return (
    <Form
      {...tail}
      mode="add"
      isOpen={isOpen}
      successCallback={onSubmit}
      toggleModal={toggleModal}
    />
  );
};

ModalAddCategory.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default ModalAddCategory;
