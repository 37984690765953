import { color as d3color } from 'd3-color';
import * as PIXI from 'pixi.js-legacy';

import { colorStringToHex } from './colorStringToHex';

const textureHash = {};

/**
 * Creates or returns from cache a Texture object with a circle shape.
 * @param {string} color - color of circle
 * @param {number} radius - radius of circle
 * @returns {GlobalMixins.Texture|*}
 */
export const circleTexture = (color, radius) => {
  const key = `circle_${color}_${radius}`;
  if (textureHash[key]?.baseTexture) {
    return textureHash[key];
  }

  const texture = new PIXI.Graphics()
    .beginFill(colorStringToHex(color))
    .drawCircle(0, 0, radius)
    .generateCanvasTexture();

  textureHash[key] = texture;

  return texture;
};

/**
 * Creates or returns from cache a Texture object with a rectangle shape.
 * @param {string} color - color of rectangle
 * @param {number} width - width of rectangle
 * @param {number} height - height of rectangle
 * @param {number} rx - radius of rectangle corners
 * @param {string} [keySuffix] - suffix for key in order to customize the key
 * @returns {GlobalMixins.Texture|*}
 */
export const roundedRectTexture = (color, width, height, rx, keySuffix) => {
  const key = `bound_${color}_${keySuffix}`;
  if (textureHash[key]?.baseTexture) {
    return textureHash[key];
  }

  const texture = new PIXI.Graphics()
    .beginFill(colorStringToHex(color), 1)
    .drawRoundedRect(0, 0, width, height, rx)
    .endFill()
    .generateCanvasTexture();

  textureHash[color] = texture;

  return texture;
};

/**
 * Creates or returns from cache a Texture object with a rectangle shape.
 * @param {string} color - color of rectangle
 * @param {number} quality - quality of the texture, size in pixels of rectangle.
 * @returns {GlobalMixins.Texture|*}
 */
export const particleTexture = (color, quality) => {
  const key = `particle_${color}_${quality}`;
  if (textureHash[key]?.baseTexture) {
    return textureHash[key];
  }

  const tempCanvas = document.createElement('canvas');

  const w = quality;
  const h = quality;

  tempCanvas.width = w;
  tempCanvas.height = h;

  const imgCtx = tempCanvas.getContext('2d');
  const gradient = imgCtx.createRadialGradient(
    w / 2,
    h / 2,
    0,
    w / 2,
    h / 2,
    w / 2,
  );

  const { r, g, b } = d3color(color);

  gradient.addColorStop(0, `rgba(${[r, g, b, 1]})`);
  gradient.addColorStop(0.2, `rgba(${[r, g, b, 0.4]})`);
  gradient.addColorStop(1, `rgba(${[r, g, b, 0]})`);

  imgCtx.fillStyle = gradient;
  imgCtx.fillRect(0, 0, w, h);

  const texture = PIXI.Texture.from(tempCanvas);

  textureHash[key] = texture;

  return texture;
};

/**
 * Remove all textures from cache.
 */
export const clearTextures = () => {
  Object.keys(textureHash).forEach((key) => {
    textureHash[key].destroy(true);
  });
};
