import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions as vpcActions, selectors as vpcSelectors } from '@/redux/api/vpc';
/**
 * @return {[*, function]}
 */
export default (provider) => {
  const dispatch = useDispatch();
  const regions = useSelector(vpcSelectors.getVpcRegionByProvider(provider));
  useEffect(
    () => {
      if (!regions) {
        dispatch(vpcActions.fetchVpcRegion());
      }
    },
    [regions],
  );
  return regions;
};
