import PropTypes from '+prop-types';
import { useMemo } from 'react';

import { ContextTypes } from '@/models/ContextTypes';

import FormWizard, { Step } from '+components/FormWizard';
import { useVerifyNqlBeforeSend } from '+hooks/useVerifyNqlBeforeSend';

const CommonForm = (props) => {
  const {
    mode,
    initialValuesMap,
    initialContext,
    isDefaultCustomer,
    disabledTrafficType,
    loading,
    permissions,
    additionalActions,
    confirmButtonText,
    onCancel,
    onSubmit,
    FormBody,
    ...tail
  } = props;

  const { isValidatingNql } = useVerifyNqlBeforeSend();

  const initialValues = useMemo(
    () => initialValuesMap[initialContext] || {},
    [initialValuesMap, initialContext],
  );

  const canManage = initialValues.id
    ? permissions?.update
    : permissions?.create;

  return (
    <FormWizard
      {...tail}
      initialValues={initialValues}
      onSubmit={onSubmit}
      onCancel={onCancel}
      additionalActions={additionalActions}
      confirmButtonText={confirmButtonText}
      loading={loading}
      disabled={loading || isValidatingNql || !canManage}
      validateOnBlur
    >
      <Step>
        <FormBody
          mode={mode}
          canManage={!loading && !!canManage}
          initialValuesMap={initialValuesMap}
          isDefaultCustomer={isDefaultCustomer}
          disabledTrafficType={disabledTrafficType}
        />
      </Step>
    </FormWizard>
  );
};

const initialValuesProps = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  track_by: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
});

CommonForm.propTypes = {
  FormBody: PropTypes.component.isRequired,
  mode: PropTypes.oneOf(['create', 'update']),
  initialValuesMap: PropTypes.shape({
    [ContextTypes.flow]: initialValuesProps,
    [ContextTypes.dns]: initialValuesProps,
  }),
  initialContext: PropTypes.oneOf([ContextTypes.flow, ContextTypes.dns]),
  isDefaultCustomer: PropTypes.bool,
  loading: PropTypes.bool,
  permissions: PropTypes.shape(),
  additionalActions: PropTypes.arrayOf(PropTypes.shape({})),
  confirmButtonText: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  disabledTrafficType: PropTypes.bool,
};

CommonForm.defaultProps = {
  mode: 'create',
  initialValuesMap: {},
  initialContext: ContextTypes.flow,
  isDefaultCustomer: false,
  loading: false,
  permissions: null,
  additionalActions: null,
  confirmButtonText: 'Create',
  onCancel: () => {},
  onSubmit: () => {},
  disabledTrafficType: false,
};

export default CommonForm;
