/**
 * This file defines `filterTypes` for the table,
 * which will be used as filter property like
 * {
 *   filter: 'nameOfMethod`
 * }
 */

import { convertValueToDayjs } from '+components/Table/Cells/formatters';
import makeArr from '+utils/makeArr';

import { FilterOperator } from '../FilterTypeFactories';
import {
  autoRemoveByOperator,
  autoRemoveIfAll,
  autoRemoveIfEmpty,
} from './utils';

/**
 * Filter for SelectColumnFilter
 */
export const selectFilter = (rows, [id], filterValue) => {
  if (autoRemoveByOperator(filterValue)) {
    return rows;
  }

  const normalizedFilterValue = String(filterValue.value).toLowerCase();

  let filterFn;
  switch (filterValue.operator) {
    case FilterOperator.equal:
      filterFn = ({ values: { [id]: value } }) => {
        const valueArr = makeArr(value);
        return valueArr.some((item) => {
          const itemArr = makeArr(item);
          return itemArr.some(
            (el) => String(el ?? '').toLowerCase() === normalizedFilterValue,
          );
        });
      };
      break;
    case FilterOperator.like:
    default:
      filterFn = ({ values: { [id]: value } }) => {
        const valueArr = makeArr(value);
        return valueArr.some((item) => {
          const itemArr = String(item ?? '').toLowerCase();
          return itemArr.includes(normalizedFilterValue);
        });
      };
      break;
  }

  return rows.filter(filterFn);
};
selectFilter.autoRemove = autoRemoveByOperator;

/**
 * Filter for SelectColumnFilter/BooleanColumnFilter
 */
export const booleanFilter = (rows, [id], filterValue) => {
  if (autoRemoveIfAll(filterValue)) {
    return rows;
  }

  const filterValueBoolean = filterValue.value === 'true';
  return rows.filter(({ values }) => (filterValueBoolean ? values[id] : !values[id]));
};
booleanFilter.autoRemove = autoRemoveIfAll;

/**
 * Filter that supports regExp
 */
export const regExpFilter = (rows, [id], filterValue) => {
  if (autoRemoveIfEmpty(filterValue)) {
    return rows;
  }

  const reg = new RegExp(String(filterValue), 'i');
  return rows.filter(({ values }) => reg.test(String(values[id] ?? '')));
};
regExpFilter.autoRemove = autoRemoveIfEmpty;

/** * Filters With Operators ** */
/**
 * Default filter:
 * - supports like and equal operators
 * - supports arrays
 * - converts all values to string
 */
export const defaultFilter = (rows, [id], filterValue) => {
  if (autoRemoveIfEmpty(filterValue)) {
    return rows;
  }

  const normalizedFilterValue = String(filterValue.value).toLowerCase();

  let filterFn;
  switch (filterValue.operator) {
    case FilterOperator.equal:
      filterFn = ({ values: { [id]: value } }) => {
        const valueArr = makeArr(value);
        return valueArr.some((item) => {
          const itemArr = makeArr(item);
          return itemArr.some(
            (el) => String(el ?? '').toLowerCase() === normalizedFilterValue,
          );
        });
      };
      break;
    case FilterOperator.like:
    default:
      filterFn = ({ values: { [id]: value } }) => {
        const valueArr = makeArr(value);
        return valueArr.some((item) => {
          const itemArr = String(item ?? '').toLowerCase();
          return itemArr.includes(normalizedFilterValue);
        });
      };
      break;
  }

  return rows.filter(filterFn);
};
defaultFilter.autoRemove = autoRemoveIfEmpty;

/**
 * Filter for numbers
 * - supports greaterThen, greaterThenOrEqual, lessThen, lessThenOrEqual and equal operators
 * - converts all values to number
 * - returns empty array if filter value is not a number
 */
export const numberFilter = (rows, [id], filterValue) => {
  if (autoRemoveIfEmpty(filterValue)) {
    return rows;
  }

  const parsedValue = parseFloat(filterValue.value);
  if (Number.isNaN(parsedValue)) {
    return [];
  }

  let filterFn;
  switch (filterValue.operator) {
    case FilterOperator.greaterThen:
      filterFn = ({ values: { [id]: value } }) => {
        const valueArr = makeArr(value);
        return valueArr.some((item) => item > parsedValue);
      };
      break;
    case FilterOperator.greaterThenOrEqual:
      filterFn = ({ values: { [id]: value } }) => {
        const valueArr = makeArr(value);
        return valueArr.some((item) => item >= parsedValue);
      };
      break;
    case FilterOperator.lessThen:
      filterFn = ({ values: { [id]: value } }) => {
        const valueArr = makeArr(value);
        return valueArr.some((item) => item < parsedValue);
      };
      break;
    case FilterOperator.lessThenOrEqual:
      filterFn = ({ values: { [id]: value } }) => {
        const valueArr = makeArr(value);
        return valueArr.some((item) => item <= parsedValue);
      };
      break;
    case FilterOperator.equal:
    default:
      filterFn = ({ values: { [id]: value } }) => {
        const valueArr = makeArr(value);
        return valueArr.some((item) => item === parsedValue);
      };
      break;
  }

  return rows.filter(filterFn);
};
numberFilter.autoRemove = autoRemoveIfEmpty;

/**
 * Filter for geo data
 * - supports like and equal operators
 * - converts all values to string
 */
export const geoFilter = (rows, [id], filterValue) => {
  if (autoRemoveByOperator(filterValue)) {
    return rows;
  }

  const normalizedFilterValue = String(filterValue.value).toLowerCase();

  let filterFn;
  switch (filterValue.operator) {
    case FilterOperator.equal:
      filterFn = ({ values: { [id]: value } }) => {
        const valueArr = [value.countrycode, value.subdiso];
        return valueArr.some((item) => {
          const itemArr = makeArr(item);
          return itemArr.some(
            (el) => String(el ?? '').toLowerCase() === normalizedFilterValue,
          );
        });
      };
      break;
    case FilterOperator.like:
    default:
      filterFn = ({ values: { [id]: value } }) => {
        const valueArr = [value.countrycode, value.subdiso];
        return valueArr.some((item) => {
          const itemArr = String(item ?? '').toLowerCase();
          return itemArr.includes(normalizedFilterValue);
        });
      };
      break;
  }

  return rows.filter(filterFn);
};
geoFilter.autoRemove = autoRemoveByOperator;

/**
 * Filter for timestamp
 */
export const timestampFilter = (rows, [id], filterValue) => {
  if (autoRemoveIfEmpty(filterValue)) {
    return rows;
  }

  const parsedValue = +convertValueToDayjs(filterValue.value);
  if (Number.isNaN(parsedValue)) {
    return [];
  }

  let filterFn;
  switch (filterValue.operator) {
    case FilterOperator.greaterThen:
      filterFn = ({ values: { [id]: value } }) => +convertValueToDayjs(value) > parsedValue;
      break;
    case FilterOperator.greaterThenOrEqual:
      filterFn = ({ values: { [id]: value } }) => +convertValueToDayjs(value) >= parsedValue;
      break;
    case FilterOperator.lessThen:
      filterFn = ({ values: { [id]: value } }) => +convertValueToDayjs(value) < parsedValue;
      break;
    case FilterOperator.lessThenOrEqual:
      filterFn = ({ values: { [id]: value } }) => +convertValueToDayjs(value) <= parsedValue;
      break;
    case FilterOperator.equal:
    default:
      filterFn = ({ values: { [id]: value } }) => +convertValueToDayjs(value) === parsedValue;
      break;
  }

  return rows.filter(filterFn);
};
timestampFilter.autoRemove = autoRemoveIfEmpty;
