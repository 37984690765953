import PropTypes from '+prop-types';
import { useMemo } from 'react';
import ReactMarkdown from 'react-markdown';

import { findAndReplace } from 'mdast-util-find-and-replace';
import remarkBreaks from 'remark-breaks';
import remarkGfm from 'remark-gfm';
import styled from 'styled-components';

import ScrollBarOrigin from '+components/ScrollBar/smooth';

import ButtonLink from './ButtonLink';

const reg = /@\[(.*?)]/g;

const replacer = (match, value) => {
  return {
    type: 'nql',
    value: match,
    data: {
      hName: 'nql',
      hChildren: [{ type: 'text', value }],
    },
  };
};

const remarkNQL = () => (tree) => {
  findAndReplace(tree, reg, replacer);
  return tree;
};

const ScrollBar = styled(ScrollBarOrigin)`
  max-height: 240px;
`;

const Container = styled.div`
  padding: 8px;
  width: 258px;
  line-height: 1.9rem;

  & p, div, span, a {
    font-size: 1.1rem;
    margin: 0;
  }

  & h1, h2, h3 {
    margin: 0.35em 0;
    line-height: 100%;
    font-size: 1.1rem;
    text-transform: uppercase;
    color: rgba(${({ theme }) => (theme.name === 'dark' ? [255, 255, 255] : [0, 0, 0])}, 0.5);
    font-weight: bold;
    letter-spacing: 0.1rem;
  }

  & ul, ol {
    padding-inline-start: 20px;
  }

  & table {
    margin: 6px 0;
  }

  & table, th, td {
    border: 1px solid #4c4c4c;
  }

  & th, td {
    padding: 2px 5px;
    font-size: 1.1rem;
  }
`;

// eslint-disable-next-line react/prop-types
const NqlLink = ({ children, onNQLPress }) => (
  <ButtonLink
    type="button"
    onClick={(evt) => onNQLPress(children[0], evt)}
  >
    { children }
  </ButtonLink>
);

const getNqlLink = (onNQLPress) => (props) => (
  <NqlLink
    {...props}
    onNQLPress={onNQLPress}
  />
);

const DocumentationPanel = ({ sourceDoc, onNQLPress, ...rest }) => {
  const mdRenderers = useMemo(
    () => ({
      nql: getNqlLink(onNQLPress),
    }),
    [onNQLPress],
  );

  return (
    <ScrollBar>
      <Container {...rest}>
        <ReactMarkdown
          linkTarget="_blank"
          components={mdRenderers}
          remarkPlugins={[remarkBreaks, remarkNQL, remarkGfm]}
        >
          {sourceDoc}
        </ReactMarkdown>
      </Container>
    </ScrollBar>
  );
};

DocumentationPanel.propTypes = {
  sourceDoc: PropTypes.string,
  onNQLPress: PropTypes.func,
};

DocumentationPanel.defaultProps = {
  sourceDoc: '',
  onNQLPress: null,
};

export default DocumentationPanel;
