import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocalStorage } from 'react-use';

import isEqual from 'lodash.isequal';
import omit from 'lodash.omit';

import RoutePaths from '@/models/RoutePaths';

import { selectors as customerSelectors } from '@/redux/api/customer';

import useEvent from '+hooks/useEvent';
import usePageTabsActionType, {
  PageTabsActionType,
} from '+hooks/usePageTabsActionType';
import useUIProperty from '+hooks/useUIProperty';
import { makeId } from '+utils';
import { cleanObject } from '+utils/cleanObject';

const pageTabsStorageKey = 'netography:pageTabs';
const pageTabsInitialValue = {};

const defaultNewTab = {
  title: 'Home',
  pathname: `${RoutePaths.home}`,
};

const prepareData = (data) => {
  return typeof data === 'string' ? { pathname: data } : cleanObject(data);
};

const makeNewTab = (data) => ({
  id: makeId(),
  title: '', // 'New Tab',
  pathname: '',
  search: '',
  formValues: null,
  active: false,
  pinned: false,
  created: Date.now(),
  lastActive: null,
  ...data,
});

const metaKeys = ['pathname', 'search', 'formValues'];

const usePageTabs = () => {
  const customer = useSelector(customerSelectors.getCurrentCustomer);

  const [, setPageTabsActionType] = usePageTabsActionType();

  const [pageTabs, setPageTabs] = useUIProperty(
    pageTabsStorageKey,
    pageTabsInitialValue,
  );

  const [pageTabsLocalStorage, setPageTabsLocalStorage] = useLocalStorage(
    pageTabsStorageKey,
    pageTabsInitialValue,
  );

  const all = useMemo(
    () => pageTabs?.[customer?.shortname] || [],
    [pageTabs, customer?.shortname],
  );

  const active = useMemo(
    () => all.find((item) => item.active),
    [all],
  );

  const onChange = useEvent((next) => {
    if (!customer?.shortname) {
      return;
    }
    if (isEqual(all, next)) {
      return;
    }
    setPageTabs({
      ...pageTabs,
      [customer?.shortname]: next,
    });
    setPageTabsLocalStorage({
      ...pageTabs,
      [customer?.shortname]: next,
    });
  });

  const onChangeActiveIndex = useEvent((index) => {
    if (!all[index]) {
      return;
    }

    const next = all.map((item, i) => ({
      ...item,
      active: i === index,
      lastActive: i === index ? Date.now() : item.lastActive,
    }));
    onChange(next);
    setPageTabsActionType(PageTabsActionType.changeActiveIndex);
  });

  const onAdd = useEvent((data, { force = false, toTheEnd = false } = {}) => {
    const preparedData = prepareData(data);

    if (!force) {
      const existIndex = all.findIndex(
        (item) => item.pathname === preparedData.pathname
          && (item.search || '') === (preparedData.search || ''),
      );
      if (existIndex !== -1) {
        onChangeActiveIndex(existIndex);
        return;
      }
    }

    const next = all.map((item) => ({
      ...item,
      active: false,
    }));

    const newTab = {
      ...makeNewTab(preparedData),
      active: true,
      lastActive: Date.now(),
    };

    if (toTheEnd) {
      // put to the end
      next.push(newTab);
    } else {
      // put after active tab
      const activeIndex = all.findIndex((item) => item.active);
      next.splice(activeIndex + 1, 0, newTab);
    }

    onChange(next);
    setPageTabsActionType(PageTabsActionType.add);
  });

  const onUpdateActive = useEvent((data) => {
    const preparedData = prepareData(data);
    const next = all.map((item) => {
      if (!item.active) {
        return item;
      }
      const needToOmitMeta = preparedData.pathname && item.pathname !== preparedData.pathname;
      return {
        ...omit(item, needToOmitMeta ? metaKeys : []),
        ...preparedData,
      };
    });
    onChange(next);
    setPageTabsActionType(PageTabsActionType.update);
  });

  const onRemove = useEvent((index) => {
    if (!all[index]) {
      return;
    }

    // if (!force && all[index].pinned) {
    //   const next = [...all];
    //   next[index] = {
    //     ...makeNewTab(prepareData(defaultNewTab)),
    //     pinned: true,
    //   };
    //   onChange(next);
    //   setPageTabsActionType(PageTabsActionType.remove);
    //   return;
    // }

    const next = all.filter((item, i) => i !== index);

    if (!next.length) {
      const preparedData = prepareData(defaultNewTab);
      next.push(makeNewTab(preparedData));
    }

    onChange(next);
    setPageTabsActionType(PageTabsActionType.remove);
  });

  const onRemoveOthers = useEvent((index) => {
    if (!all[index] || all.length < 2) {
      return;
    }

    const next = all.filter((item, i) => item.pinned || i === index);
    onChange(next);
    setPageTabsActionType(PageTabsActionType.remove);
  });

  const onRemoveToLeft = useEvent((index) => {
    if (!all[index] || all.length < 2) {
      return;
    }

    const next = all.filter((item, i) => item.pinned || i >= index);
    onChange(next);
    setPageTabsActionType(PageTabsActionType.remove);
  });

  const onRemoveToRight = useEvent((index) => {
    if (!all[index] || all.length < 2) {
      return;
    }

    const next = all.filter((item, i) => item.pinned || i <= index);
    onChange(next);
    setPageTabsActionType(PageTabsActionType.remove);
  });

  const onRemoveActive = useEvent(() => {
    const index = all.findIndex((item) => item.active);
    onRemove(index);
  });

  const onRemoveAll = useEvent(() => {
    const next = all.filter((item) => item.pinned);
    if (!next.length) {
      const preparedData = prepareData(defaultNewTab);
      next.push(makeNewTab(preparedData));
    }
    onChange(next);
    setPageTabsActionType(PageTabsActionType.remove);
  });

  const onMove = useEvent((oldIndex, newIndex) => {
    if (!all[oldIndex] || !all[newIndex]) {
      return;
    }

    if ((all[oldIndex].pinned || false) !== (all[newIndex].pinned || false)) {
      return;
    }

    const next = [...all];
    const moveItem = next[oldIndex];
    next.splice(oldIndex, 1);
    next.splice(newIndex, 0, moveItem);
    onChange(next);
    setPageTabsActionType(PageTabsActionType.move);
  });

  const onCopy = useEvent((index) => {
    if (!all[index]) {
      return;
    }

    const next = [...all];
    const copyItem = {
      ...next[index],
      id: makeId(),
      formValues: null,
      active: false,
      pinned: false,
      created: Date.now(),
      lastActive: null,
    };
    let copyIndex;
    if (next[index].pinned) {
      // find last pinned tab index
      const pinnedIndex = next.findIndex((item) => !item.pinned);
      copyIndex = pinnedIndex !== -1 ? pinnedIndex : next.length;
    } else {
      copyIndex = index + 1;
    }
    next.splice(copyIndex, 0, copyItem);
    onChange(next);
    setPageTabsActionType(PageTabsActionType.add);
  });

  const onPin = useEvent((index) => {
    if (!all[index]) {
      return;
    }

    const pinned = all.filter((item) => item.pinned);
    const pinnedIndex = pinned.findIndex((item) => item.id === all[index].id);
    if (pinnedIndex !== -1) {
      return;
    }
    pinned.push({
      ...all[index],
      pinned: true,
    });
    const unpinned = all.filter(
      (item) => !item.pinned && item.id !== all[index].id,
    );
    const next = [...pinned, ...unpinned];
    onChange(next);
    setPageTabsActionType(PageTabsActionType.pin);
  });

  const onUnpin = useEvent((index) => {
    if (!all[index]) {
      return;
    }

    const unpinned = all.filter((item) => !item.pinned);
    const unpinnedIndex = unpinned.findIndex(
      (item) => item.id === all[index].id,
    );
    if (unpinnedIndex !== -1) {
      return;
    }
    unpinned.unshift({
      ...all[index],
      pinned: false,
    });
    const pinned = all.filter(
      (item) => item.pinned && item.id !== all[index].id,
    );
    const next = [...pinned, ...unpinned];
    onChange(next);
    setPageTabsActionType(PageTabsActionType.unpin);
  });

  useEffect(
    () => {
      if (pageTabs === pageTabsInitialValue) {
        setPageTabs(pageTabsLocalStorage);
      }
    },
    [pageTabs, pageTabsLocalStorage],
  );

  return useMemo(
    () => {
      return [
        all,
        active,
        {
          add: onAdd,
          updateActive: onUpdateActive,
          remove: onRemove,
          removeOthers: onRemoveOthers,
          removeToLeft: onRemoveToLeft,
          removeToRight: onRemoveToRight,
          removeActive: onRemoveActive,
          removeAll: onRemoveAll,
          move: onMove,
          copy: onCopy,
          changeActiveIndex: onChangeActiveIndex,
          pin: onPin,
          unpin: onUnpin,
        },
      ];
    },
    [
      all,
      active,
      onAdd,
      onUpdateActive,
      onRemove,
      onRemoveOthers,
      onRemoveToLeft,
      onRemoveToRight,
      onRemoveActive,
      onRemoveAll,
      onMove,
      onCopy,
      onChangeActiveIndex,
      onPin,
      onUnpin,
    ],
  );
};

export { defaultNewTab };
export default usePageTabs;
