import { select, call, put, delay } from 'redux-saga/effects';

import { actions as toastActions } from '@/redux/toast';
import {
  createSlice,
  createSelector,
  startFetching,
  stopFetching,
  defaultReducers,
  takeLeading,
  awaiters,
} from '@/redux/util';

import backendClient from '@/middleware/backendClient';

export const initialState = {
  isFetching: false,
  isAllFetched: false,
  error: '',
  protocols: null, // []
  contexts: [],
  portLabels: {},
  portLabelsHash: {},
  portLabelsFetched: {},
  newPortLabel: null, // {}
};

const apiPath = '/labels/ports';

let api;

const initApi = () => {
  if (!api) {
    api = backendClient();
  }
};

export const makeId = (item) => `${item.port}/${item.protocol}/${item.context}${
  item.customer ? `/${item.customer}` : ''
}`;
export const splitId = (id) => {
  const [port, protocol, context, customer] = id.split('/');
  return {
    port: parseInt(port, 10),
    protocol,
    context,
    customer,
  };
};

const removeFromHash = (hash, item) => {
  const record = hash[item.port];
  if (!record) {
    return;
  }

  delete record[`${item.protocol}${item.customer ? `/${item.customer}` : ''}`];

  if (Object.keys(record).length === 0) {
    delete hash[item.port];
  }
};

const updateHash = (hash, item) => {
  let record = hash[item.port];

  if (record && item.hide) {
    removeFromHash(hash, item);
    return;
  }

  if (!record) {
    record = {};
    hash[item.port] = record;
  }

  record[`${item.protocol}${item.customer ? `/${item.customer}` : ''}`] = item.labels;
};

const slice = createSlice({
  name: 'portLabels',
  initialState,

  reducers: {
    ...defaultReducers,

    fetchProtocols: startFetching,
    fetchProtocolsSuccess(state, { payload: protocols }) {
      stopFetching(state);
      state.protocols = (protocols || []).map((item) => {
        item.value = item.name;
        item.label = item.name;
        return item;
      });
    },

    fetchContexts: startFetching,
    fetchContextsSuccess(state, { payload: contexts }) {
      stopFetching(state);
      state.contexts = contexts;
    },

    fetchPortLabels: startFetching,
    fetchPortLabelsSuccess(state, { payload: portLabels }) {
      stopFetching(state);
      // order of those functions is important because we need to save sub-account labels
      // before they are overwritten by main account labels
      const subAccountPortLabels = Object.values(state.portLabels).filter(
        (item) => !!item.customer,
      );

      state.isAllFetched = true;
      state.portLabels = {};
      state.portLabelsHash = {};
      state.portLabelsFetched = {};

      portLabels.push(...subAccountPortLabels);
      portLabels.forEach((portLabel) => {
        const id = makeId(portLabel);
        portLabel.id = id;
        state.portLabels[id] = portLabel;
        updateHash(state.portLabelsHash, portLabel);
        state.portLabelsFetched[
          `${portLabel.port}${
            portLabel.customer ? `/${portLabel.customer}` : ''
          }`
        ] = true;
      });
    },

    fetchPortLabelsByProtocol: startFetching,
    fetchPortLabelsByProtocolSuccess(state, { payload: { data: portLabels } }) {
      stopFetching(state);
      portLabels.forEach((portLabel) => {
        const id = makeId(portLabel);
        portLabel.id = id;
        state.portLabels[id] = portLabel;
        updateHash(state.portLabelsHash, portLabel);
      });
    },

    fetchPortLabelsByPort: startFetching,
    fetchPortLabelsByPortSuccess(
      state,
      { payload: { port, customer, data: portLabels } },
    ) {
      stopFetching(state);
      portLabels.forEach((portLabel) => {
        const id = makeId(portLabel);
        portLabel.id = id;
        state.portLabels[id] = portLabel;
        updateHash(state.portLabelsHash, portLabel);
      });
      state.portLabelsFetched[
        `${port}${customer ? `/${customer}` : ''}`
      ] = true;
    },

    createPortLabel: startFetching,
    createPortLabelSuccess(state, { payload: portLabel }) {
      stopFetching(state);
      const id = makeId(portLabel);
      portLabel.id = id;
      state.portLabels[id] = portLabel;
      state.newPortLabel = portLabel;
      updateHash(state.portLabelsHash, portLabel);
      state.portLabelsFetched[portLabel.port] = true;
    },

    updatePortLabel: startFetching,
    updatePortLabelSuccess(state, { payload: portLabel }) {
      stopFetching(state);
      const id = makeId(portLabel);
      portLabel.id = id;
      state.portLabels[id] = portLabel;
      updateHash(state.portLabelsHash, portLabel);
      state.portLabelsFetched[portLabel.port] = true;
    },

    showPortLabel: startFetching,
    showPortLabelSuccess(state, { payload: portLabel }) {
      stopFetching(state);
      const id = makeId(portLabel);
      portLabel.id = id;
      state.portLabels[id] = portLabel;
      updateHash(state.portLabelsHash, portLabel);
      state.portLabelsFetched[portLabel.port] = true;
    },

    hidePortLabel: startFetching,
    hidePortLabelSuccess(state, { payload: portLabel }) {
      stopFetching(state);
      const id = makeId(portLabel);
      portLabel.id = id;
      state.portLabels[id] = portLabel;
      updateHash(state.portLabelsHash, portLabel);
      state.portLabelsFetched[portLabel.port] = true;
    },

    resetPortLabel: startFetching,
    resetPortLabelSuccess(state, { payload: portLabel }) {
      stopFetching(state);
      const id = makeId(portLabel);
      portLabel.id = id;
      state.portLabels[id] = portLabel;
      updateHash(state.portLabelsHash, portLabel);
      state.portLabelsFetched[portLabel.port] = true;
    },

    removePortLabel: startFetching,
    removePortLabelSuccess(state, { payload: portLabel }) {
      stopFetching(state);
      const id = makeId(portLabel);
      delete state.portLabels[id];
      removeFromHash(state.portLabelsHash, portLabel);
      delete state.portLabelsFetched[portLabel.port];
    },

    bulkUploadPortLabel: startFetching,
    bulkUploadPortLabelSuccess(state, { payload: updatedLabels }) {
      stopFetching(state);
      updatedLabels.forEach((portLabel) => {
        const id = makeId(portLabel);
        portLabel.id = id;
        state.portLabels[id] = portLabel;
        state.newPortLabel = portLabel;
        updateHash(state.portLabelsHash, portLabel);
        state.portLabelsFetched[portLabel.port] = true;
      });
    },

    bulkUploadFile: startFetching,
    bulkUploadFileSuccess(state, { payload: updatedLabels }) {
      stopFetching(state);
      updatedLabels.forEach((portLabel) => {
        const id = makeId(portLabel);
        portLabel.id = id;
        state.portLabels[id] = portLabel;
        updateHash(state.portLabelsHash, portLabel);
        state.portLabelsFetched[portLabel.port] = true;
      });
    },

    bulkRemovePortLabel: startFetching,
    bulkRemovePortLabelSuccess: stopFetching,

    clearIsAllFetched(state) {
      state.isAllFetched = false;
    },

    removeNewPortLabel(state) {
      state.newPortLabel = null;
    },

    skip: stopFetching,
  },

  sagas: (actions, selectors) => ({
    [actions.fetchProtocols]: {
      taker: takeLeading(actions.skip),
      * saga() {
        initApi();

        try {
          const response = yield call(api.get, `${apiPath}/protocols`);
          yield put(actions.fetchProtocolsSuccess(response.data.data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching protocols',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.fetchContexts]: {
      taker: takeLeading(actions.skip),
      * saga() {
        initApi();

        try {
          const response = yield call(api.get, `${apiPath}/contexts`);
          yield put(actions.fetchContextsSuccess(response.data.data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching port label contexts',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.fetchPortLabels]: {
      taker: takeLeading(actions.skip),
      * saga() {
        initApi();

        try {
          const isAllFetched = yield select(selectors.isAllFetched);
          if (isAllFetched) {
            yield put(actions.skip());
            return;
          }
          const response = yield call(api.get, apiPath);
          yield put(actions.fetchPortLabelsSuccess(response.data.data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching port labels',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.fetchPortLabelsByProtocol]: {
      * saga({ payload: { silent = true, protocol } }) {
        initApi();

        // const fullPath = apiPath;
        const path = `${apiPath}/all/${encodeURIComponent(protocol)}`;
        const portLabelsFetched = yield select(
          selectors.getPortLabelsFetchedByProtocol(protocol),
        );
        // const skip = portLabelsFetched || awaiters.has(path) || awaiters.has(fullPath);
        const skip = portLabelsFetched || awaiters.has(path);
        if (skip) {
          yield put(actions.skip());
          return;
        }

        awaiters.add(path);

        try {
          const response = yield call(api.get, path);
          yield put(
            actions.fetchPortLabelsByProtocolSuccess({
              protocol,
              data: response.data.data,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          if (!silent) {
            yield put(
              toastActions.error({
                message: 'Error fetching port labels',
                details: error.message,
              }),
            );
          }
        }

        awaiters.delete(path);
      },
    },

    [actions.fetchPortLabelsByPort]: {
      * saga({ payload: { silent = true, port, customer } }) {
        initApi();

        // const fullPath = apiPath;
        let path = `${apiPath}/${port}`;
        if (customer) {
          path += `?customer=${customer}`;
        }
        const portLabelsFetched = yield select(
          selectors.getPortLabelsFetchedByPort(port, customer),
        );
        // const skip = portLabelsFetched || awaiters.has(path) || awaiters.has(fullPath);
        const skip = portLabelsFetched || awaiters.has(path);
        if (skip) {
          yield put(actions.skip());
          return;
        }

        awaiters.add(path);

        try {
          const response = yield call(api.get, path);
          yield put(
            actions.fetchPortLabelsByPortSuccess({
              port,
              customer,
              data: response.data.data,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          if (!silent) {
            yield put(
              toastActions.error({
                message: 'Error fetching port label',
                details: error.message,
              }),
            );
          }
        }

        awaiters.delete(path);
      },
    },

    [actions.createPortLabel]: {
      * saga({ payload: { silent = false, ...portLabel } }) {
        initApi();

        try {
          const response = yield call(api.post, apiPath, portLabel);
          yield put(actions.createPortLabelSuccess(response.data.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Port label has been created',
              response,
              showWarningOnly: silent,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error creating port label',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.updatePortLabel]: {
      * saga({ payload: { silent = false, ...portLabel } }) {
        initApi();

        try {
          const response = yield call(api.put, apiPath, portLabel);
          yield put(actions.updatePortLabelSuccess(response.data.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Port label has been updated',
              response,
              showWarningOnly: silent,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error updating port label',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.showPortLabel]: {
      * saga({ payload: { silent = true, ...portLabel } }) {
        initApi();

        try {
          const response = yield call(
            api.put,
            `${apiPath}/${portLabel.port}/${encodeURIComponent(
              portLabel.protocol,
            )}/show`,
          );
          yield put(actions.showPortLabelSuccess(response.data.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Default port label has been enabled',
              response,
              showWarningOnly: silent,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error updating port label',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.hidePortLabel]: {
      * saga({ payload: { silent = true, ...portLabel } }) {
        initApi();

        try {
          const response = yield call(
            api.put,
            `${apiPath}/${portLabel.port}/${encodeURIComponent(
              portLabel.protocol,
            )}/hide`,
          );
          yield put(actions.hidePortLabelSuccess(response.data.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Default port label has been disabled',
              response,
              showWarningOnly: silent,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error updating port label',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.resetPortLabel]: {
      * saga({ payload: { silent = true, ...portLabel } }) {
        initApi();

        try {
          const response = yield call(
            api.put,
            `${apiPath}/${portLabel.port}/${encodeURIComponent(
              portLabel.protocol,
            )}/reset`,
          );
          yield put(actions.resetPortLabelSuccess(response.data.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Default port label has been restored',
              response,
              showWarningOnly: silent,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error resetting port label',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.removePortLabel]: {
      * saga({ payload: portLabel }) {
        initApi();

        try {
          const response = yield call(
            api.delete,
            `${apiPath}/${portLabel.port}/${encodeURIComponent(
              portLabel.protocol,
            )}`,
          );
          const message = 'Port label has been deleted';
          yield put(actions.removePortLabelSuccess(portLabel));
          yield put(
            toastActions.successWithAuditLogVerification({
              message,
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error deleting port label',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.bulkUploadPortLabel]: {
      * saga({ payload: { silent = false, data: portLabels } }) {
        initApi();

        try {
          const response = yield call(api.put, `${apiPath}/bulk`, portLabels);
          const updatedLabels = response.data.data;
          yield put(actions.bulkUploadPortLabelSuccess(updatedLabels));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: `Created/Updated ${updatedLabels.length} Port Labels`,
              response,
              showWarningOnly: silent,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error importing port labels',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.bulkUploadFile]: {
      * saga({ payload: { silent = false, file } }) {
        initApi();

        try {
          const formData = new FormData();
          if (file) {
            formData.append('file', file);
          }
          const response = yield call(api.put, `${apiPath}/upload`, formData);
          const updatedLabels = response.data.data;
          yield put(actions.bulkUploadFileSuccess(updatedLabels));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: `Created/Updated ${updatedLabels.length} Port Labels`,
              response,
              showWarningOnly: silent,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error importing port labels',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.bulkRemovePortLabel]: {
      * saga({ payload: data }) {
        initApi();

        try {
          const response = yield call(api.delete, `${apiPath}/bulk`, { data });
          yield put(actions.bulkRemovePortLabelSuccess());
          yield put(actions.clearIsAllFetched());
          yield put(actions.fetchPortLabels());
          yield delay(2000); // 1s
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Port labels removed/reset',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error removing/resetting port labels',
              details: error.message,
            }),
          );
        }
      },
    },
  }),

  selectors: (getState) => ({
    isFetching: createSelector(
      [getState],
      (state) => state.isFetching,
    ),

    isAllFetched: createSelector(
      [getState],
      (state) => state.isAllFetched,
    ),

    getNewPortLabel: createSelector(
      [getState],
      (state) => state.newPortLabel,
    ),

    getProtocols: createSelector(
      [getState],
      (state) => state.protocols,
    ),

    getContexts: createSelector(
      [getState],
      (state) => state.contexts,
    ),

    getPortLabels: createSelector(
      [getState],
      (state) => state.portLabels,
    ),

    getPortLabelsHash: createSelector(
      [getState],
      (state) => state.portLabelsHash,
    ),

    getPortLabelsHashByPort: (port) => createSelector(
      [getState],
      (state) => state.portLabelsHash?.[port],
    ),

    getPortLabelsFetchedByProtocol: (protocol) => createSelector(
      [getState],
      (state) => state.portLabelsFetched?.[protocol],
    ),

    getPortLabelsFetchedByPort: (port, customer) => createSelector(
      [getState],
      (state) => state.portLabelsFetched?.[`${port}${customer ? `/${customer}` : ''}`],
    ),
  }),
});

export const { actions, selectors } = slice;

export default slice;
