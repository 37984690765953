import { call, put, select } from 'redux-saga/effects';
// import cloneDeep from 'lodash.clonedeep';

import { actions as toastActions } from '@/redux/toast';
import {
  createSlice,
  startFetching,
  stopFetching,
  createSelector,
  defaultReducers,
  takeLeading,
} from '@/redux/util';

import backendClient from '@/middleware/backendClient';
// import { actions as bgpActions } from '@/redux/api/bgp';

export const initialState = {
  isFetching: false,
  isTesting: false,
  isFetchingTags: false,
  isSubmitting: false, // for modals
  submitSucceeded: false, // for modals
  data: {},
  statuses: {},
  idByName: {},
  error: null,
  testResult: {},
  tags: [],
};

let api;

const initApi = () => {
  if (!api) {
    api = backendClient();
  }
};

const startSubmit = (state) => {
  startFetching(state);

  state.submitSucceeded = false;
  state.isSubmitting = true;
};

const submitSuccess = (state) => {
  stopFetching(state);

  state.submitSucceeded = true;
  state.isSubmitting = false;
};

const slice = createSlice({
  name: 'devices',
  initialState,

  reducers: {
    ...defaultReducers,
    devicesFetch: startFetching,
    unknownDevicesFetch: startFetching,
    deviceFetchById: startFetching,

    deviceAdd: startSubmit,
    deviceUpdate: startSubmit,
    deviceDelete: startFetching,
    bulkDeleteDevices: startFetching,
    devicesStatus: startFetching,

    fetchTags: (state) => {
      state.isFetchingTags = true;
      state.error = '';
    },

    fetchTagsSuccess(state, { payload: { data } }) {
      state.isFetchingTags = false;
      state.tags = data;
    },

    success(state, { payload: { data } }) {
      stopFetching(state);

      const byName = {};

      if (data) {
        state.data = data.reduce((acc, item) => {
          acc[item.id] = item;

          byName[item.name] = item.id;

          return acc;
        }, {});
      }

      state.idByName = byName;
    },

    unknownDevicesSuccess(state, { payload: { data } }) {
      stopFetching(state);
      if (data) {
        state.unknownDevices = data;
      }
    },

    byIdSuccess(
      state,
      { payload: { data: [data] } },
    ) {
      stopFetching(state);
      state.data[data.id] = data;
      state.idByName[data.name] = data.id;
    },

    addSuccess(
      state,
      { payload: { data: [data] } },
    ) {
      submitSuccess(state);
      state.data[data.id] = data;
      state.idByName[data.name] = data.id;
    },

    updateSuccess(
      state,
      { payload: { data: [data] } },
    ) {
      submitSuccess(state);
      state.data[data.id] = data;
      state.idByName[data.name] = data.id;
    },

    deleteSuccess(state, { payload: deviceId }) {
      stopFetching(state);

      const { name = null } = state.data[deviceId] || {};
      if (name) {
        delete state.idByName[name];
      }
      delete state.data[deviceId];
      delete state.statuses[deviceId];
    },

    bulkDeleteSuccess(state, { payload: devices }) {
      stopFetching(state);

      devices.forEach((device) => {
        const { name = null } = state.data[device.id] || {};
        if (name) {
          delete state.idByName[name];
        }
        delete state.data[device.id];
        delete state.statuses[device.id];
      });
    },

    resetSubmit(state) {
      state.submitSucceeded = false;
      state.isSubmitting = false;
    },

    submitFail(state, error) {
      stopFetching(state);
      state.submitSucceeded = false;
      state.isSubmitting = false;
      state.error = error;
    },

    devicesStatusDone: (state, { payload: { data = [] } = {} }) => {
      stopFetching(state);
      const { statuses = {} } = state;

      if (Array.isArray(data) && data.length) {
        data.forEach((device) => {
          statuses[device.id] = device;
        });
      }

      state.statuses = statuses;
    },

    skip: stopFetching,
  },

  sagas: (actions) => ({
    [actions.devicesFetch]: {
      taker: takeLeading(actions.skip),
      * saga() {
        initApi();

        try {
          const { data } = yield call(api.get, '/devices');
          yield put(actions.success(data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching devices',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.unknownDevicesFetch]: {
      taker: takeLeading(actions.skip),
      * saga() {
        initApi();

        try {
          const { data } = yield call(api.get, '/devices/unknown');
          yield put(actions.unknownDevicesSuccess(data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching devices',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.deviceFetchById]: {
      * saga({ payload: deviceId }) {
        initApi();

        try {
          let id = deviceId;
          if (id && Number.isNaN(+id)) {
            const state = yield select(slice.selectors.getState);
            id = state.idByName[id];

            if (!id) {
              const { data } = yield call(api.get, '/devices');
              yield put(actions.success(data));

              return;
            }
          }

          const { data } = yield call(api.get, `/device/${id}`);
          yield put(actions.byIdSuccess(data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching device',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.deviceAdd]: {
      * saga({ payload }) {
        initApi();

        // // Extract BGP keys if they exist
        // const device = cloneDeep(payload);
        // let deviceid;
        // const bgpKeyRegex = /^bgp_/;
        // const bgpEntries = Object.entries(device || {}).filter(([key]) => bgpKeyRegex.test(key));
        // const bgpPayload = bgpEntries.reduce((acc, [key, val]) => {
        //   // Delete BGP keys from device payload
        //   delete device[key];
        //   return {
        //     ...acc,
        //     [key.replace(bgpKeyRegex, '')]: val,
        //   };
        // }, {});

        try {
          const response = yield call(api.post, '/device', payload);
          yield put(actions.addSuccess(response.data));
          yield put(actions.fetchTags());
          yield put(actions.resetSubmit());
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Device has been created',
              response,
            }),
          );
          // deviceid = response.data?.data?.id;
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error creating device',
              details: error.message,
            }),
          );
        }

        // // If there is a BGP config, create BGP neighbor
        // if (bgpEntries.length && deviceid) {
        //   yield put(bgpActions.addNeighbor({
        //     ...bgpPayload,
        //     deviceid,
        //     silent: true,
        //   }));
        // }
      },
    },

    [actions.deviceUpdate]: {
      * saga({ payload: { id, ...params } }) {
        initApi();

        try {
          const response = yield call(api.put, `/device/${id}`, params);
          yield put(actions.updateSuccess(response.data));
          yield put(actions.fetchTags());
          yield put(actions.resetSubmit());
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Device has been updated',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error updating device',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.deviceDelete]: {
      * saga({ payload: deviceId }) {
        initApi();

        try {
          const response = yield call(api.delete, `/device/${deviceId}`);
          yield put(actions.deleteSuccess(deviceId));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Device has been removed',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error deleting device',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.bulkDeleteDevices]: {
      * saga({ payload: devices }) {
        initApi();

        try {
          const response = yield call(api.put, '/devices/bulkdelete', {
            devices,
          });
          yield put(actions.bulkDeleteSuccess(devices));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Devices have been removed',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error deleting devices',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.devicesStatus]: {
      taker: takeLeading(actions.skip),
      * saga() {
        initApi();

        try {
          const { data } = yield call(api.get, '/devices/status');
          yield put(actions.devicesStatusDone(data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Could not retrieve device statuses',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.fetchTags]: {
      taker: takeLeading(actions.skip),
      * saga() {
        initApi();

        try {
          const response = yield call(api.get, '/tags/device?terse=0');
          const { data } = response;
          yield put(actions.fetchTagsSuccess(data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Could not retrieve device tags',
              details: error.message,
            }),
          );
        }
      },
    },
  }),

  selectors: (getState) => ({
    isFetching: createSelector(
      [getState],
      (state) => state.isFetching,
    ),

    getDevices: createSelector(
      [getState],
      (state) => state.data,
    ),

    getDeviceById: (id) => createSelector(
      [getState],
      ({ data, idByName }) => {
        let deviceId = id;
        if (deviceId && Number.isNaN(+deviceId)) {
          deviceId = idByName?.[id];
        }

        return data?.[deviceId];
      },
    ),

    getStatusById: (id) => createSelector(
      [getState],
      ({ statuses, idByName }) => {
        let deviceId = id;
        if (deviceId && Number.isNaN(+deviceId)) {
          deviceId = idByName?.[id];
        }

        return statuses?.[deviceId];
      },
    ),
  }),
});

// For convenience
export const { actions, selectors } = slice;

export default slice;
