const parseFilter = (filter = '') => {
  const items = Array.isArray(filter) ? filter : [filter];

  return items.reduce((acc, item) => {
    const key = item.split('|');
    acc[key.shift()] = key.join('|');
    return acc;
  }, {});
};

export const getSearchParams = (search = '') => {
  const params = new URLSearchParams(search);

  return Array.from(params.keys()).reduce((acc, key) => {
    let value = params.get(key);
    if (key === 'filter') {
      value = parseFilter(params.getAll(key));
    }

    acc[key] = value;
    return acc;
  }, {});
};

export default getSearchParams;
