import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 40%;
  min-width: 140px;
  max-width: 520px;
  margin: 0 20px 0 120px;
  
  :empty {
    display: none;
  }
`;
