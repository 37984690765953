import { MenuColumnFactory } from '+components/Table/Columns';
import TechnicalColumns from '+components/Table/ReactTable/TechnicalColumns';

const visibleColumns = (columns) => {
  const menuColumn = columns.find((col) => col.id === TechnicalColumns.menu);

  if (menuColumn) {
    return columns;
  }

  const column = MenuColumnFactory({ cxActionMenu: () => null });

  return [column, ...columns];
};

export const useMenuColumn = (hooks) => {
  hooks.visibleColumns.push(visibleColumns);
};

useMenuColumn.pluginName = 'useMenuColumn';
