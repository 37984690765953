import { Fragment, useMemo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation, useMeasure, useToggle } from 'react-use';

import { useFlag } from '@unleash/proxy-client-react';

import { ContextTypes } from '@/models/ContextTypes';
import { DateTimeModes } from '@/models/DateTimeModes';
import PermissionModel from '@/models/Permission';
import RoutePaths from '@/models/RoutePaths';

import {
  actions as bgpActions,
  selectors as bgpSelectors,
} from '@/redux/api/bgp';
import {
  actions as deviceActions,
  selectors as deviceSelectors,
} from '@/redux/api/device';
import {
  actions as vpcActions,
  selectors as vpcSelectors,
} from '@/redux/api/vpc';

import Button, { ButtonVariants } from '+components/Button';
import ConfirmModal from '+components/ConfirmModal';
import { Dropdown, DropdownItem } from '+components/Dropdown';
import GlobalFiltersSetting from '+components/GlobalFilters/Setting';
import { ActionsContainer, Row, Col } from '+components/Layout';
import NoDataPage from '+components/NoDataPage';
import { getRowOriginal } from '+components/Table/Columns/utils';
import Tag from '+components/Tag';
import * as toast from '+components/toast';
import useGlobalFiltersProperty from '+hooks/useGlobalFiltersProperty';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import useMaxSources from '+hooks/useMaxSources';
import usePermissions from '+hooks/usePermissions';
import getSearchParams from '+utils/getSearchParams';

import SourcesColChart from './components/SourcesColChart';
import SourcesLineChart from './components/SourcesLineChart';
import SourcesTable from './components/Table';
import { excludeMetrics } from './utils';

const allowedContexts = new Set([ContextTypes.flow, ContextTypes.dns]);

const getSelected = (selected, data) => selected.map((id) => data[id]).filter(Boolean);

const FlowSources = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { search = '' } = useLocation();
  const isDnsEnabled = useFlag('DNS');
  const [context] = useGlobalFiltersProperty('context', ContextTypes.flow);

  const [lineChartMeasureRef, { width: lineChartWidth }] = useMeasure();
  const [topChartMeasureRef, { width: topChartWidth }] = useMeasure();

  const { data: deviceData, isFetching: isDeviceFetching } = useSelector(
    deviceSelectors.getState,
  );
  const { isFetching: isBgpFetching } = useSelector(bgpSelectors.getState);
  const { isFetching: isVpcFetching } = useSelector(vpcSelectors.getState);
  const vpcData = useSelector(vpcSelectors.getVpcs);
  const bgpEnabledDevices = useSelector(bgpSelectors.devicesHaveNeighbors);
  const devicePermissions = usePermissions(
    PermissionModel.Resources.device.value,
  );
  const cloudPermissions = usePermissions(
    PermissionModel.Resources.cloud_provider.value,
  );
  const { enforceMaxSources, sourcesRemaining } = useMaxSources();
  const [selected, setSelected] = useState([]);
  const [showBulkEnableModal, toggleBulkEnableModal] = useToggle(false);
  const [showBulkDisableModal, toggleBulkDisableModal] = useToggle(false);
  const [showBulkDeleteModal, toggleBulkDeleteModal] = useToggle(false);

  const isDataLoading = isDeviceFetching || isBgpFetching || isVpcFetching;

  useLoadingIndicator(isDataLoading);

  const params = useMemo(
    () => getSearchParams(search),
    [search],
  );

  const sources = useMemo(
    () => Object.values({
      ...deviceData,
      ...vpcData,
    }).map((source) => ({
      ...source,
      bgpenabled: bgpEnabledDevices[source.id],
    })),
    [deviceData, vpcData, bgpEnabledDevices],
  );

  const [selectedDevices, selectedVpcs] = useMemo(
    () => [getSelected(selected, deviceData), getSelected(selected, vpcData)],
    [selected, deviceData, vpcData],
  );

  const onConfirmBulkEnable = useCallback(
    () => {
      toggleBulkEnableModal();
      const disabledVpcs = selectedVpcs.filter((vpc) => !vpc.enabled);

      if (!disabledVpcs.length) {
        toast.info(
          `All selected cloud ${
            isDnsEnabled ? 'traffic' : 'flow'
          } sources are already enabled`,
        );
      } else {
        dispatch(vpcActions.bulkEnableVpcs({ vpcs: disabledVpcs }));
      }
    },
    [selectedVpcs, isDnsEnabled],
  );

  const onConfirmBulkDisable = useCallback(
    () => {
      toggleBulkDisableModal();
      const enabledVpcs = selectedVpcs.filter((vpc) => vpc.enabled);

      if (!enabledVpcs.length) {
        toast.info(
          `All selected cloud ${
            isDnsEnabled ? 'traffic' : 'flow'
          } sources are already disabled`,
        );
      } else {
        dispatch(vpcActions.bulkDisableVpcs({ vpcs: enabledVpcs }));
      }
    },
    [selectedVpcs, isDnsEnabled],
  );

  const onConfirmBulkDelete = useCallback(
    () => {
      toggleBulkDeleteModal();

      if (selectedVpcs.length > 0) {
        dispatch(vpcActions.bulkDeleteVpcs(selectedVpcs));
      }

      if (selectedDevices.length > 0) {
        dispatch(deviceActions.bulkDeleteDevices(selectedDevices));
      }
    },
    [selectedDevices, selectedVpcs],
  );

  const onAddClick = useCallback(
    () => navigate(`${RoutePaths.sources}/add`),
    [],
  );

  const onSelectedRowsChange = useCallback(
    (selectedRowIds) => {
      setSelected((prev) => {
        const next = Object.entries(selectedRowIds || {})
          .map(([key, value]) => (value ? key : null))
          .filter(Boolean);

        if (!prev.length && !next.length) {
          return prev;
        }

        return next;
      });
    },
    [],
  );

  const getIsRowSelectorDisabled = useCallback(
    (row) => {
      const original = getRowOriginal(row);
      if (!original?.flowtype) {
        return !devicePermissions?.delete;
      }

      return !cloudPermissions?.update && !cloudPermissions?.delete;
    },
    [devicePermissions, cloudPermissions],
  );

  useEffect(
    () => {
      dispatch(deviceActions.devicesStatus());
      dispatch(deviceActions.devicesFetch());
      dispatch(vpcActions.fetchVpcs());
      dispatch(bgpActions.fetchNeighbors());
    },
    [],
  );

  return (
    <Fragment>
      <GlobalFiltersSetting
        context={ContextTypes.flow}
        dateTimeMode={DateTimeModes.now}
        excludeMetrics={excludeMetrics}
        nql
        metric={allowedContexts.has(context)}
      />

      <ActionsContainer>
        <Button
          onClick={onAddClick}
          disabled={
            (!devicePermissions?.create && !cloudPermissions?.create)
            || (enforceMaxSources && sourcesRemaining <= 0)
          }
        >
          Add {isDnsEnabled ? 'Traffic' : 'Flow'} Source
        </Button>

        {sources.length > 0 && (
          <Dropdown
            caption="Update Selected"
            variant={ButtonVariants.outlined}
            disabled={!selected.length}
          >
            <DropdownItem header>Cloud Providers</DropdownItem>
            <DropdownItem
              onClick={toggleBulkEnableModal}
              disabled={!cloudPermissions?.update || !selectedVpcs.length}
              short
            >
              Enable
            </DropdownItem>
            <DropdownItem
              onClick={toggleBulkDisableModal}
              disabled={!cloudPermissions?.update || !selectedVpcs.length}
              short
            >
              Disable
            </DropdownItem>
            <DropdownItem header>
              {isDnsEnabled ? 'Traffic' : 'Flow'} Sources
            </DropdownItem>
            <DropdownItem
              onClick={toggleBulkDeleteModal}
              disabled={!devicePermissions?.delete || !cloudPermissions?.delete}
              short
            >
              Delete
            </DropdownItem>
          </Dropdown>
        )}

        {enforceMaxSources && (
          <Tag color="secondary" outlined={false}>
            {sourcesRemaining} Remaining
          </Tag>
        )}
      </ActionsContainer>

      {sources.length === 0 && (
        <NoDataPage
          image="/assets/graphic-devices-bw.png"
          imageOffsetY={20}
          noDataText={`No ${isDnsEnabled ? 'Traffic' : 'Flow'} Sources set`}
          actionButtonText="Add Source"
          loading={isDataLoading}
        />
      )}

      {sources.length > 0 && (
        <Fragment>
          <Row columnSpacing={1} style={{ height: 300, overflow: 'hidden' }}>
            <Col
              ref={lineChartMeasureRef}
              xs={8}
              item
              container={false}
            >
              <SourcesLineChart
                {...params}
                sources={sources}
                excludeMetrics={excludeMetrics}
                width={lineChartWidth}
                height={300}
              />
            </Col>

            <Col
              ref={topChartMeasureRef}
              xs={4}
              item
              container={false}
            >
              <SourcesColChart
                {...params}
                sources={sources}
                excludeMetrics={excludeMetrics}
                width={topChartWidth}
                height={300}
              />
            </Col>
          </Row>

          <SourcesTable
            {...params}
            permissions={{ devicePermissions, cloudPermissions }}
            sources={sources}
            getIsRowSelectorDisabled={getIsRowSelectorDisabled}
            onSelectedRowsChange={onSelectedRowsChange}
          />
        </Fragment>
      )}
      {showBulkEnableModal && (
        <ConfirmModal
          item={`${selectedVpcs.length} Cloud ${
            isDnsEnabled ? 'Traffic' : 'Flow'
          } Source${selectedVpcs.length > 1 ? 's' : 'y'}`}
          confirmButtonText="enable"
          confirmButtonColor="primary"
          whyAsking=""
          onToggle={toggleBulkEnableModal}
          onConfirm={onConfirmBulkEnable}
          toggleOnConfirm={false}
          isOpen
        />
      )}

      {showBulkDisableModal && (
        <ConfirmModal
          item={`${selectedVpcs.length} Cloud ${
            isDnsEnabled ? 'Traffic' : 'Flow'
          } Source${selectedVpcs.length > 1 ? 's' : ''}`}
          confirmButtonText="disable"
          confirmButtonColor="primary"
          whyAsking=""
          onToggle={toggleBulkDisableModal}
          onConfirm={onConfirmBulkDisable}
          toggleOnConfirm={false}
          isOpen
        />
      )}
      {showBulkDeleteModal && (
        <ConfirmModal
          item={`${selected.length} ${
            isDnsEnabled ? 'Traffic' : 'Flow'
          } Source${selected.length > 1 ? 's' : ''}`}
          confirmButtonText="delete"
          whyAsking=""
          onToggle={toggleBulkDeleteModal}
          onConfirm={onConfirmBulkDelete}
          toggleOnConfirm={false}
          isOpen
        />
      )}
    </Fragment>
  );
};

export default FlowSources;
