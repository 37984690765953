import PropTypes from '+prop-types';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToggle } from 'react-use';

import LeadPencilIcon from 'mdi-react/LeadPencilIcon';
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';
import UndoIcon from 'mdi-react/UndoIcon';

import {
  actions as rulesActions,
  selectors as rulesSelectors,
} from '@/redux/api/rules';

import ModalResetCategory from '@/pages/DetectionCategories/components/modals/Reset';

import Button, { ButtonVariants } from '+components/Button';
import ConfirmModal from '+components/ConfirmModal';
import { ActionsContainer } from '+components/Layout';
import Table from '+components/Table';
import { MenuColumnContextMenu } from '+components/Table/Columns';
import { getRowOriginal } from '+components/Table/Columns/utils';

import { getColumns, Columns } from './Columns';
import ModalAddCategory from './modals/Add';
import ModalEditCategory from './modals/Edit';

const sortBy = [
  {
    id: 'name',
    desc: false,
  },
];
const getRowId = (row) => row.name;

const CommonTable = (props) => {
  const { rows, permissions, isDefaultCustomer, noDataText } = props;
  const dispatch = useDispatch();
  const { isFetching, error } = useSelector(rulesSelectors.getState);

  const [modalAdd, setModalAdd] = useState(false);
  const [editModal, setEditModal] = useState(null);
  const [deleteModal, setDeleteModal] = useState(null);
  const [resetModal, setResetModal] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const [selected, setSelected] = useState([]);
  const [showBulkDeleteModal, toggleBulkDeleteModal] = useToggle(false);

  const toggleEditModal = useCallback(
    (params) => setEditModal(
      params?.name
        ? {
          show: true,
          params,
          isDeletable: params.name && (!params.system || isDefaultCustomer),
          isResettable:
                params.name && params.system && !params.systemdefault,
        }
        : null,
    ),
    [isDefaultCustomer],
  );

  const toggleDeleteModal = useCallback(
    (params) => setDeleteModal(
      params?.name
        ? {
          show: true,
          params,
        }
        : null,
    ),
    [],
  );

  const toggleResetModal = useCallback(
    (params) => setResetModal(
      params?.name
        ? {
          show: true,
          params,
        }
        : null,
    ),
    [],
  );

  const cxActionMenu = useCallback(
    (id, item) => {
      const isDeletable = !item.system || isDefaultCustomer;
      const isResettable = item.system && !item.systemdefault;
      const items = [
        {
          icon: <LeadPencilIcon />,
          text: 'Edit',
          onClick: () => {
            toggleEditModal(item);
          },
        },
        {
          icon: <TrashCanOutlineIcon />,
          text: 'Delete',
          disabled: !isDeletable || !permissions?.delete,
          onClick: () => {
            toggleDeleteModal(item);
          },
        },
        isResettable && {
          icon: <UndoIcon />,
          text: 'Reset Customization',
          disabled: !permissions?.update,
          onClick: () => {
            toggleResetModal(item);
          },
        },
      ].filter(Boolean);
      return (
        <MenuColumnContextMenu
          title={item.name}
          items={items}
          dataTracking="detection-category"
        />
      );
    },
    [
      permissions,
      toggleEditModal,
      toggleDeleteModal,
      toggleResetModal,
      isDefaultCustomer,
    ],
  );

  const columns = useMemo(
    () => getColumns(Object.values(Columns), { cxActionMenu }),
    [cxActionMenu],
  );

  const onEditSuccess = useCallback(
    () => setEditModal(null),
    [],
  );

  const onDeleteConfirm = useCallback(
    () => {
      if (!deleteModal?.params?.name) {
        return;
      }

      dispatch(rulesActions.deleteCategory(deleteModal.params));
      setIsDeleting(true);
    },
    [deleteModal?.params],
  );

  const onBulkDelete = useCallback(
    () => {
      toggleBulkDeleteModal();
      if (!selected.length) {
        return;
      }
      dispatch(rulesActions.bulkDeleteCategories(selected));
    },
    [selected],
  );

  const onResetConfirm = useCallback(
    () => {
      if (!resetModal?.params?.name) {
        return;
      }

      dispatch(rulesActions.deleteCategory(resetModal.params));
      toggleResetModal();
      toggleEditModal();
    },
    [resetModal?.params, toggleResetModal, toggleEditModal],
  );

  const toggleModalAdd = useCallback(
    () => setModalAdd((prev) => !prev),
    [],
  );

  const onSelectedRowsChange = useCallback(
    (selectedRowIds) => {
      setSelected((prev) => {
        const next = Object.entries(selectedRowIds || {})
          .map(([key, value]) => (value ? key : null))
          .filter(Boolean);

        if (!prev.length && !next.length) {
          return prev;
        }

        return next;
      });
    },
    [],
  );

  const getIsRowSelectorDisabled = useCallback(
    (row) => {
      const original = getRowOriginal(row);
      const isDeletable = !original.system || isDefaultCustomer;
      const isResettable = original.system && !original.systemdefault;
      if (isDeletable) {
        return !permissions?.delete;
      }
      if (isResettable) {
        return !permissions?.update;
      }
      return true;
    },
    [permissions, isDefaultCustomer],
  );

  useEffect(
    () => {
      if (isFetching || !isDeleting) {
        return;
      }

      setIsDeleting(false);

      if (!error) {
        toggleDeleteModal();
        toggleEditModal();
      }
    },
    [isFetching, isDeleting, error, toggleDeleteModal, toggleEditModal],
  );

  return (
    <Fragment>
      <ActionsContainer>
        <Button
          disabled={!permissions?.create}
          onClick={toggleModalAdd}
          testId="add-detection-category-button"
        >
          Add/Update Category
        </Button>

        <Button
          variant={ButtonVariants.outlined}
          onClick={toggleBulkDeleteModal}
          disabled={!permissions?.delete || !selected.length}
          testId="delete-detection-category-button"
        >
          Delete/Reset Selected
        </Button>
      </ActionsContainer>

      <Table
        id="RulesEngine_Categories_Table"
        columns={columns}
        data={rows}
        getRowId={getRowId}
        sortBy={sortBy}
        noDataText={noDataText}
        getIsRowSelectorDisabled={getIsRowSelectorDisabled}
        onSelectedRowsChange={onSelectedRowsChange}
        autoResetSelectedRows
        testId="detection-categories-table"
      />

      {editModal?.show && (
        <ModalEditCategory
          successCallback={onEditSuccess}
          toggleModal={toggleEditModal}
          initialValues={editModal.params}
          permissions={permissions}
          deleteButtonText={
            editModal.isDeletable
              ? 'Delete Category'
              : 'Reset Category Customization'
          }
          onDelete={
            editModal.isDeletable
              ? () => toggleDeleteModal(editModal.params)
              : () => toggleResetModal(editModal.params)
          }
          deleteButtonHidden={!editModal.isDeletable && !editModal.isResettable}
          deleteButtonDisabled={editModal.isDeletable ? !permissions?.delete : !permissions?.update}
          isOpen
          testId="edit-detection-category-modal"
        />
      )}

      {deleteModal?.show && (
        <ConfirmModal
          item={deleteModal.params?.name || null}
          isDisabled={isFetching}
          cancelButtonDisabled={isFetching}
          onConfirm={onDeleteConfirm}
          toggleOnConfirm={false}
          onToggle={toggleDeleteModal}
          isOpen
          testId="delete-detection-category-modal"
        />
      )}

      {resetModal?.show && (
        <ModalResetCategory
          isDisabled={isFetching}
          toggleModal={toggleResetModal}
          name={resetModal.params.name || null}
          onConfirm={onResetConfirm}
          isOpen
          testId="reset-detection-category-modal"
        />
      )}
      {modalAdd && (
        <ModalAddCategory
          isOpen={modalAdd}
          onSuccess={toggleModalAdd}
          toggleModal={toggleModalAdd}
          testId="add-detection-category-modal"
        />
      )}
      {showBulkDeleteModal && (
        <ConfirmModal
          item={`${selected.length} Detection Categor${
            selected.length > 1 ? 'ies' : 'y'
          }`}
          confirmButtonText="delete/reset"
          whyAsking=""
          onToggle={toggleBulkDeleteModal}
          onConfirm={onBulkDelete}
          toggleOnConfirm={false}
          isOpen
        />
      )}
    </Fragment>
  );
};

CommonTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  permissions: PropTypes.shape(),
  isDefaultCustomer: PropTypes.bool,
  noDataText: PropTypes.string,
};

CommonTable.defaultProps = {
  permissions: null,
  isDefaultCustomer: false,
  noDataText: undefined,
};

export default CommonTable;
