import isFunction from 'lodash.isfunction';

/**
 * @param {{} | function({}): {}} columnsCollection
 * @return {function(string[], {}=): {}[]}
 */
export const getColumnsHelper = (columnsCollection) => (columns, props) => {
  const collection = isFunction(columnsCollection)
    ? columnsCollection(props)
    : columnsCollection;

  return columns
    .map((key) => {
      const column = collection[key];
      if (!column) {
        return false;
      }

      const item = isFunction(column) ? column(props) : column;

      return {
        ...item,
        id: item.id ?? key,
        accessor: item.accessor ?? key,
      };
    })
    .filter(Boolean);
};

export default getColumnsHelper;
