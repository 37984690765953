import { call, put } from 'redux-saga/effects';

import { actions as toastActions } from '@/redux/toast';
import {
  defaultReducers,
  createSlice,
  createSelector,
  startFetching,
  stopFetching,
  takeLeading,
} from '@/redux/util';

import backendClient from '@/middleware/backendClient';

export const initialState = {
  isFetching: false,
  isSubmitting: false, // for modals
  submitSucceeded: false, // for modals
  error: null,
  neighbors: {},
  addresslocal: {},
  addressremote: {},
  deviceid: {},
  stats: {
    prefixrate: 0,
    prefixsessioncount: 0,
    prefixtotalcount: 0,
  },
};

let api;

const initApi = () => {
  if (!api) {
    api = backendClient();
  }
};

const startSubmit = (state) => {
  startFetching(state);

  state.submitSucceeded = false;
  state.isSubmitting = true;
};

const submitSuccess = (state) => {
  stopFetching(state);

  state.submitSucceeded = true;
  state.isSubmitting = false;
};

const basePath = '/bgp';

const mapStateByItem = (state, item) => {
  const { id, addresslocal, addressremote, deviceid } = item;

  const local = state.addresslocal[addresslocal] || [];
  if (!local.includes(id)) {
    local.push(id);
  }
  state.addresslocal[addresslocal] = local;

  const remote = state.addressremote[addressremote] || [];
  if (!remote.includes(id)) {
    remote.push(id);
  }
  state.addressremote[addressremote] = remote;

  if (deviceid) {
    const device = state.deviceid[deviceid] || [];
    if (!device.includes(id)) {
      device.push(id);
    }
    state.deviceid[deviceid] = device;
  }
};

const isEmpty = (obj) => obj.constructor === Object && Reflect.ownKeys(obj).length === 0;

const slice = createSlice({
  name: 'bgp',
  initialState,

  reducers: {
    ...defaultReducers,
    fetchNeighbors: startFetching,
    fetchDeviceNeighbors: startFetching,
    fetchDependencies: startFetching,
    fetchById: startFetching,
    fetchNeighborsByDeviceId: startFetching,
    addNeighbor: startSubmit,
    updateNeighbor: startSubmit,
    enableNeighbor: startSubmit,
    disableNeighbor: startSubmit,
    deleteNeighbor: startSubmit,

    fetching(state) {
      state.isFetching = true;
    },

    successNeighbors(state, { payload: { data } }) {
      stopFetching(state);

      // stats are not being provided if there are no neighbors.  protect ourselves.
      if (data.meta.count) {
        state.stats = data.meta.stats;
      }

      const results = data.data || [];

      // Map local and remote addresses to find BGP neighbors for a device
      results.forEach((item) => {
        mapStateByItem(state, item);
      });

      state.neighbors = results.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {});
    },

    successById(state, { payload: { data: [item] = [] } = {} }) {
      stopFetching(state);
      if (item) {
        state.neighbors[item.id] = item;
        mapStateByItem(state, item);
      }
    },

    successAdd(state, { payload: { data: [item] = [] } = {} }) {
      submitSuccess(state);
      if (item) {
        state.neighbors[item.id] = item;
        mapStateByItem(state, item);
      }
    },

    successUpdate(state, { payload: { data: [item] = [] } = {} }) {
      submitSuccess(state);
      if (item) {
        state.neighbors[item.id] = item;
        mapStateByItem(state, item);
      }
    },

    successDelete(state, { payload: id }) {
      submitSuccess(state);
      delete state.neighbors[id];
      delete state.addresslocal[id];
      delete state.addressremote[id];
      delete state.deviceid[id];
    },

    submitFail(state, error) {
      stopFetching(state);
      state.submitSucceeded = false;
      state.isSubmitting = false;
      state.error = error;
    },

    resetSubmit(state) {
      state.submitSucceeded = false;
      state.isSubmitting = false;
    },

    skip: stopFetching,
  },

  sagas: (actions) => ({
    [actions.fetchNeighbors]: {
      taker: takeLeading(actions.skip),
      * saga({ payload }) {
        initApi();

        let url = `${basePath}/neighbors`;
        if (payload?.params) {
          const search = new URLSearchParams();
          Object.entries(payload.params).forEach(([name, value]) => {
            search.append(name, `${value}`);
          });
          url = `${url}?${search}`;
        }

        try {
          const data = yield call(api.get, url);
          yield put(actions.successNeighbors(data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching BGP neighbors',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.fetchDependencies]: {
      taker: takeLeading(actions.skip),
      * saga() {
        try {
          yield put(actions.fetchNeighbors());
        } catch (error) {
          yield put(actions.fail(error));
        }
      },
    },

    [actions.fetchById]: {
      * saga({ payload: id }) {
        initApi();
        try {
          const { data } = yield call(api.get, `${basePath}/neighbor/${id}`);
          yield put(actions.successById(data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching BGP neighbor',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.fetchNeighborsByDeviceId]: {
      * saga({ payload: id }) {
        initApi();
        try {
          const { data } = yield call(
            api.get,
            `${basePath}/neighbors/devices/${id}`,
          );
          yield put(actions.successById(data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching BGP neighbors by device Id',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.addNeighbor]: {
      * saga({ payload: { silent = false, ...neighbor } }) {
        initApi();
        try {
          const response = yield call(
            api.post,
            `${basePath}/neighbor`,
            neighbor,
          );
          yield put(actions.successAdd(response.data));
          yield put(actions.resetSubmit());
          if (!silent) {
            yield put(
              toastActions.successWithAuditLogVerification({
                message: 'BGP neighbor has been added',
                response,
              }),
            );
          }
        } catch (error) {
          yield put(actions.submitFail(error.message));
          yield put(
            toastActions.error({
              message: 'Error creating BGP neighbor',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.updateNeighbor]: {
      * saga({ payload }) {
        initApi();
        try {
          const { id } = payload;
          // delete payload.id;
          const response = yield call(
            api.put,
            `${basePath}/neighbor/${id}`,
            payload,
          );
          yield put(actions.successUpdate(response.data));
          yield put(actions.resetSubmit());
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'BGP neighbor has been updated',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.submitFail(error.message));
          yield put(
            toastActions.error({
              message: 'Error updating BGP neighbor',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.deleteNeighbor]: {
      * saga({ payload: id }) {
        initApi();
        try {
          const response = yield call(api.delete, `${basePath}/neighbor/${id}`);
          yield put(actions.successDelete(id));
          yield put(actions.resetSubmit());
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'BGP neighbor has been deleted',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.submitFail(error.message));
          yield put(
            toastActions.error({
              message: 'Error deleting BGP neighbor',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.enableNeighbor]: {
      * saga({ payload: id }) {
        initApi();
        try {
          const response = yield call(
            api.put,
            `${basePath}/neighbor/${id}/enable`,
          );
          yield put(actions.successUpdate(response.data));
          yield put(actions.resetSubmit());
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'BGP neighbor has been enabled',
              response,
              showWarningOnly: true,
            }),
          );
        } catch (error) {
          yield put(actions.submitFail(error.message));
          yield put(
            toastActions.error({
              message: 'Error enabling BGP state',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.disableNeighbor]: {
      * saga({ payload: id }) {
        initApi();
        try {
          const response = yield call(
            api.put,
            `${basePath}/neighbor/${id}/disable`,
          );
          yield put(actions.successUpdate(response.data));
          yield put(actions.resetSubmit());
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'BGP neighbor has been disabled',
              response,
              showWarningOnly: true,
            }),
          );
        } catch (error) {
          yield put(actions.submitFail(error.message));
          yield put(
            toastActions.error({
              message: 'Error disabling BGP state',
              details: error.message,
            }),
          );
        }
      },
    },
  }),
  selectors: (selector) => {
    const getNeighbor = (items) => (id) => items?.[id] || {};

    const getNeighbors = createSelector(
      [selector],
      (state) => state.neighbors,
    );

    return {
      getStats: createSelector(
        [selector],
        (state) => state.stats,
      ),
      getNeighbors,
      getNeighborsByDeviceId: (deviceId) => createSelector(
        [selector, getNeighbors],
        ({ deviceid }, neighbors) => (deviceid[deviceId] || [])
          .map(getNeighbor(neighbors))
          .filter((item) => !isEmpty(item)),
      ),
      devicesHaveNeighbors: createSelector(
        [selector],
        ({ deviceid }) => Object.entries(deviceid).reduce(
          (acc, [key, neighbors]) => ({
            ...acc,
            [key]: !!neighbors?.length,
          }),
          {},
        ),
      ),
    };
  },
});

// For convenience
export const { actions, selectors } = slice;

export default slice;
