import styled from 'styled-components';

import DateTimePickerOrigin from '+components/form/DateTimePicker';
import InputTextOrigin from '+components/form/InputText';
import NumberInputOrigin from '+components/form/NumberInput';
import SelectOrigin from '+components/form/Select';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  > .mdi-icon {
    width: 24px;
    height: 24px;
  }
`;

const Input = styled(InputTextOrigin).attrs({
  showClearButton: true,
})`
  width: 100%;
`;

const NumberInput = styled(NumberInputOrigin).attrs({
  showClearButton: true,
})`
  width: 100%;
`;

const Select = styled(SelectOrigin).attrs((props) => ({
  ...props,
  showClearButton: props.value?.value && props.value?.value !== 'all',
}))`
  width: 100%;
`;

const DateTimePicker = styled(DateTimePickerOrigin).attrs({
  placeholder: ' ',
  disableMaskedInput: false,
  ignoreInvalidInputs: false,
  showClearButton: true,
})`
  width: 100%;
`;

export { Container, Row, Input, NumberInput, Select, DateTimePicker };
