import PropTypes from '+prop-types';
import { forwardRef, useMemo, useRef, useState } from 'react';
import { useDebounce } from 'react-use';

import isObject from 'lodash.isobject';

import { WidgetTypes } from '@/models/WidgetTypes';

import { makeId } from '+utils/general';
import makeArr from '+utils/makeArr';

import {
  GridLayout,
  WidgetContainer,
  defaultCols,
  defaultRowHeight,
} from '../../../Dashboard';
import { dashboardPropTypes } from '../../../shared/propTypes';
import { widgetUiToParams } from '../../../shared/utils';
import { widgets } from '../../../shared/widgets';
import Widget from '../../../Widget';
import PreviewContainer from './components/PreviewContainer';
import Title from './components/Title';

// all values are chosen as a result of experiments
const cols = Math.round(defaultCols / 2);
const rowHeight = Math.round(defaultRowHeight * 1.5);
const maxHeight = 10;

const WidgetPreview = forwardRef((props, ref) => {
  const { dashboard, formValues, formErrors, formValidating } = props;

  const firstLoad = useRef(true);

  const [widgetId] = useState(`preview_${makeId()}`);
  const [widget, setWidget] = useState(null);

  const widgetType = widget?.series?.[0]?.display?.type;

  const widgetMeta = useMemo(
    () => widgets[widgetType] || {},
    [widgetType],
  );

  const widgetLayout = useMemo(
    () => {
    // use current widget layout props if widget exists
    // or use default if it's a new widget
      const layout = formValues.id && dashboard.layout
        ? dashboard.layout.find((item) => item.i === formValues.id)
        : widgetMeta.defaultLayout;

      return [
        {
          ...layout,
          i: widgetId,
          x: 0,
          y: 0,
          // w: Math.min(cols, layout?.w),
          w: cols,
          h: Math.min(maxHeight, layout?.h),
        },
      ];
    },
    [widgetMeta.defaultLayout, dashboard.layout, formValues.id, widgetId],
  );

  useDebounce(
    () => {
      if (!Object.keys(formValues).length || formValidating) {
        return;
      }

      // skip title and display fields errors but other fields errors are critical
      const { title, display, series, ...otherFields } = formErrors;

      const errorsArr = [
        ...(series || []).flatMap((item) => Object.values(item || {})),
        ...Object.values(otherFields),
      ];

      const hasErrors = widgetType !== WidgetTypes.Markdown
        && errorsArr.some((item) => {
          const arr = makeArr(item);
          const arrOfErrors = arr
            .reduce((acc, el) => {
              const errors = isObject(el) ? Object.values(el) : el;
              return Array.isArray(errors)
                ? [...acc, ...errors]
                : [...acc, errors];
            }, [])
            .filter(Boolean);
          return !!arrOfErrors.length;
        });

      if (!hasErrors) {
        setWidget(
          widgetUiToParams({
            ...formValues,
            id: widgetId,
          }),
        );
        firstLoad.current = false;
      }
    },
    firstLoad.current ? 0 : 1000,
    [formValues, formErrors, widgetId, widgetType, formValidating],
  );

  return (
    <PreviewContainer ref={ref}>
      <Title>Widget Preview:</Title>
      {!!widget && (
        <GridLayout
          layout={widgetLayout}
          cols={cols}
          rowHeight={rowHeight}
          containerPadding={[0, 0]}
          preventCollision={false}
          isDraggable={false}
          isResizable={false}
        >
          <WidgetContainer key={widget.id} id={widget.id}>
            <Widget dashboard={dashboard} widget={widget} readOnly />
          </WidgetContainer>
        </GridLayout>
      )}
    </PreviewContainer>
  );
});

WidgetPreview.propTypes = {
  dashboard: PropTypes.shape(dashboardPropTypes).isRequired,
  formValues: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  formErrors: PropTypes.shape({
    title: PropTypes.string,
    display: PropTypes.shape({}),
    series: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  formValidating: PropTypes.bool.isRequired,
};

export default WidgetPreview;
