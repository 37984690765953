import PropTypes from '+prop-types';
import { useMemo } from 'react';

import { Td } from '+components/Table/ReactTable/components/Components';
import { getProps } from '+components/Table/ReactTable/utils';

const RowCell = (props) => {
  const {
    cell,
    getCellProps,
    expander,
    getToggleRowExpandedProps,
    renderIndex,
  } = props;

  const isExpander = cell.column.expander && expander;
  const isColumnExpander = cell.column.expanderColumn && expander;

  const cellProps = cell.getCellProps(
    getProps([
      isExpander ? getToggleRowExpandedProps() : {},
      getCellProps,
      cell.column.getCellProps,
      { $expander: isExpander || isColumnExpander },
    ]),
  );

  // TODO: Rethink this place (something is wrong here)
  if (!isExpander && !isColumnExpander) {
    delete cellProps.onClick;
    delete cellProps.style.cursor;
  }

  const styleJson = JSON.stringify(cellProps);

  const renderComp = useMemo(
    () => {
      let render;
      switch (true) {
        case !!cell.isAggregated:
          render = cell.column.expander
            ? cell.render('Cell')
            : cell.render('Aggregated');
          break;
        case cell.isPlaceholder:
          render = cell.column.Placeholder ? cell.render('Placeholder') : null;
          break;
        case cell.isGrouped:
        default:
          render = cell.render('Cell');
          break;
      }

      return render;
    },
    [
      cell.column.dependsOnIndex ? renderIndex : -1,
      cell.value,
      cell.isAggregated,
      cell.isPlaceholder,
      cell.isGrouped,
      cell.row.isExpanded,
      cell.row.isSelected,
      cell.row.isSelectorDisabled,
      cell.row.original,
      cell.column.Cell,
      cell.column.Placeholder,
      cell.column.Aggregated,
      cell.column.expander,
    ],
  );

  return useMemo(
    () => <Td {...cellProps}>{renderComp}</Td>,
    [styleJson, renderComp],
  );
};

RowCell.propTypes = {
  cell: PropTypes.shape().isRequired,
  expander: PropTypes.bool.isRequired,
  getToggleRowExpandedProps: PropTypes.func.isRequired,
  getCellProps: PropTypes.func,
};

RowCell.defaultProps = {
  getCellProps: null,
};

export default RowCell;
