/* eslint-disable react/jsx-closing-bracket-location */
import PropTypes from '+prop-types';
import { useCallback, useMemo, useEffect, Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useResolvedPath,
} from 'react-router-dom';

import styled from 'styled-components';

import { ContextTypes } from '@/models/ContextTypes';

import {
  actions as searchActions,
  selectors as searchSelectors,
} from '@/redux/api/search';

import { AuditLogTable } from '+components/ContextTables';
import { Col, Row } from '+components/Layout';
import { TabsContainer, Tabs, Tab } from '+components/Tabs';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import { makeId } from '+utils/general';

const FIVE_YEARS_IN_SECONDS = -157680000;
const end = 0; // 0 to search all records up to current time
const start = FIVE_YEARS_IN_SECONDS;

const RoutesContainer = styled.div`
  margin-top: 20px;
`;

const defaultTab = {
  value: 'settings',
  label: 'Settings',
};

const EditPageAuditLogTabs = ({
  auditNqlQuery,
  showTabs,
  breadcrumb,
  children,
  additionalTabs,
  defaultTabLabel,
}) => {
  const { pathname: parentPath } = useResolvedPath('.');
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchId] = useState(makeId());

  const { isFetching: isFetchingSearch } = useSelector(
    searchSelectors.getState,
  );
  const searchData = useSelector(searchSelectors.getSearchResult(searchId));

  const logsTab = useMemo(
    () => ({
      value: 'logs',
      label: 'Audit Logs',
      element: (
        <AuditLogTable
          data={searchData}
          noDataText={isFetchingSearch ? 'Loading...' : undefined}
        />
      ),
    }),
    [searchData, isFetchingSearch],
  );

  const tabs = useMemo(
    () => [...additionalTabs, logsTab],
    [additionalTabs, logsTab],
  );

  const currentTab = useMemo(
    () => {
      const matchingTab = tabs?.find((tab) => location.pathname.startsWith(`${parentPath}/${tab.value}`));
      return matchingTab ?? defaultTab;
    },
    [location.pathname],
  );

  const onTabChange = useCallback(
    (_, value) => {
      if (value === defaultTab.value) {
        navigate(parentPath);
      } else {
        navigate(`${parentPath}/${value}`);
      }
    },
    [parentPath, navigate],
  );

  useEffect(
    () => {
      const searchParams = {
        id: searchId,
        context: ContextTypes.audit,
        start,
        end,
        search: [auditNqlQuery],
        sort: {
          field: 'timestamp',
          order: 'desc',
        },
      };

      if (currentTab.value === logsTab.value) {
        dispatch(searchActions.search(searchParams, searchId));
      }

      return () => {
        dispatch(searchActions.cancel(searchId));
      };
    },
    [searchId, currentTab, auditNqlQuery],
  );

  useLoadingIndicator(isFetchingSearch);

  const breadcrumbLabel = currentTab?.value === defaultTab.value
    ? defaultTabLabel || defaultTab.label
    : currentTab?.label;

  return (
    <Row spacing={1}>
      <Col xs={12} item container={false}>
        {breadcrumb && breadcrumb instanceof Function
          ? breadcrumb(breadcrumbLabel)
          : breadcrumb}
        {showTabs && (
          <Fragment>
            <TabsContainer>
              <Tabs
                value={currentTab?.value || defaultTab.value}
                onChange={onTabChange}
              >
                <Tab
                  label={defaultTabLabel || defaultTab.label}
                  value={defaultTab.value}
                />
                {tabs?.map((tab) => (
                  <Tab key={tab.value} label={tab.label} value={tab.value} />
                ))}
              </Tabs>
            </TabsContainer>
            <RoutesContainer>
              <Routes>
                <Route index element={children} />
                {tabs?.map((tab) => (
                  <Route
                    key={tab.value}
                    path={`${tab.value}/*`}
                    element={tab.element}
                  />
                ))}

                <Route
                  path="*"
                  element={<Navigate to={parentPath} replace />}
                />
              </Routes>
            </RoutesContainer>
          </Fragment>
        )}
        {!showTabs && children}
      </Col>
    </Row>
  );
};

EditPageAuditLogTabs.propTypes = {
  breadcrumb: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  auditNqlQuery: PropTypes.string.isRequired,
  showTabs: PropTypes.bool,
  children: PropTypes.children.isRequired,
  defaultTabLabel: PropTypes.string,
  // additionalTabs expects array of objects: {value: "", label: "", element: <JSX/>}
  additionalTabs: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      element: PropTypes.children.isRequired,
    }),
  ),
};

EditPageAuditLogTabs.defaultProps = {
  showTabs: true,
  breadcrumb: null,
  additionalTabs: [],
  defaultTabLabel: null,
};

export default EditPageAuditLogTabs;
