import PropTypes from '+prop-types';
import { Fragment, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import RoutePaths from '@/models/RoutePaths';

import { actions as bgpActions, selectors } from '@/redux/api/bgp';

import Alert from '+components/Alert';
import Field from '+components/form/FinalForm/Field';
import Description from '+components/form/FormField/components/Description';
import FieldContainer from '+components/form/FormField/components/FieldContainer';
import Group from '+components/form/FormField/components/Group';
import Label from '+components/form/FormField/components/Label';
import MultiSelectField from '+components/form/MultiSelectField';
import { normalizeMultiSelectValue } from '+components/form/Normalizers';
import NumberField from '+components/form/NumberField';
import TextField from '+components/form/TextField';
import {
  validateRequired,
  validateNumber,
  validateCombinedWhitelist,
  minLocalprefValue,
  maxLocalprefValue,
} from '+components/form/Validators';
import useAllowlists from '+hooks/useAllowlists';

import { MAX_JS_DIGITS } from '.';
import { FACTORS } from '../../factors';
import AlertContainer from '../components/AlertContainer';
import RenderIpAggregate from '../components/RenderIpAggregate';

const customSort = (a, b) => {
  if (a.addressremote < b.addressremote) {
    return -1;
  }
  if (a.addressremote > b.addressremote) {
    return 1;
  }
  return 0;
};

const factors = FACTORS;

const FlowspecTrafficFields = (props) => {
  const { mode, disableSubmit } = props;

  const dispatch = useDispatch();
  const { isFetching, neighbors: neighborsHash } = useSelector(
    selectors.getState,
  );
  const { allowlists } = useAllowlists();

  const allowlistOptions = useMemo(
    () => Object.values(allowlists || {}).map(({ id, name, description }) => ({
      value: `id:${id}`,
      label: name,
      description,
    })),
    [allowlists],
  );

  const neighbors = useMemo(
    () => Object.values(neighborsHash || {})
      .sort(customSort)
      .map((neighbor) => ({
        label: neighbor.description,
        value: neighbor.id,
      })),
    [neighborsHash],
  );

  useEffect(
    () => {
      disableSubmit(isFetching || (mode === 'add' && neighbors.length === 0));
    },
    [disableSubmit, isFetching, mode, neighbors.length],
  );

  useEffect(
    () => {
      dispatch(
        bgpActions.fetchNeighbors({
          params: {
            capability: 'ipv4-unicast,ipv6-unicast',
          },
        }),
      );
    },
    [],
  );

  const disabled = props.disabled || (mode === 'add' && neighbors.length === 0);

  return (
    <Fragment>
      {mode === 'add' && neighbors.length === 0 && (
        <AlertContainer>
          <Alert severity="error">
            <b>Devices with unicast BGP neighbors are required.</b>
            <br />
            Prior to creating a Flowspec plugin, you will need to configure at
            least 1 device with a unicast BGP neighbor.
            <br />
            <Link to={{ pathname: `${RoutePaths.sources}` }}>
              Manage Devices
            </Link>
          </Alert>
        </AlertContainer>
      )}

      <Field
        name="name"
        label="Name"
        component={TextField}
        type="text"
        maxLength={255}
        autoComplete="new-password"
        validate={validateRequired}
        style={{ width: '50%' }}
        disabled={disabled}
        required
      />

      <Field
        name="description"
        label="Description"
        component={TextField}
        type="text"
        maxLength={255}
        autoComplete="new-password"
        disabled={disabled}
      />

      <Field
        name="config.neighbors"
        label="Neighbors"
        component={MultiSelectField}
        options={neighbors}
        helperText="IPv4/v6 unicast BGP neighbors"
        parse={normalizeMultiSelectValue}
        validate={validateRequired}
        disabled={disabled}
        required
      />

      <Field
        name="config.localpref"
        label="Local Preference"
        component={NumberField}
        autoComplete="new-password"
        min={0}
        max={4294967295}
        validate={[
          validateRequired,
          validateNumber,
          minLocalprefValue,
          maxLocalprefValue,
        ]}
        helperText="Used to choose the exit path for an autonomous system. Default 100"
        style={{ width: '50%' }}
        disabled={disabled}
        required
      />

      <Field
        name="config.rule"
        label="Rule"
        component={TextField}
        type="text"
        autoComplete="new-password"
        validate={validateRequired}
        helperText={(
          <Fragment>
            Custom flowspec token language
            <br />
            e.g. <code>match destination DSTIP then discard</code>
          </Fragment>
        )}
        disabled={disabled}
        required
      />

      <Field
        name="config.factors"
        label="Factors"
        component={MultiSelectField}
        options={factors}
        parse={normalizeMultiSelectValue}
        validate={validateRequired}
        disabled={disabled}
        required
      />

      <Field
        name="config.expiration"
        label="Expiration"
        component={NumberField}
        min={0}
        max={MAX_JS_DIGITS}
        autoComplete="new-password"
        helperText="Number of seconds the blocklist will remain active"
        style={{ width: '50%' }}
        disabled={disabled}
      />

      <Field
        name="config.max"
        label="Max"
        component={NumberField}
        min={0}
        max={MAX_JS_DIGITS}
        autoComplete="new-password"
        helperText="Limit on number of blocks"
        style={{ width: '50%' }}
        disabled={disabled}
      />

      <Field
        name="config.combinedWhitelist"
        label="Allow List"
        helperText="List of IP or IP/CIDR addresses"
        component={MultiSelectField}
        options={allowlistOptions}
        allowCreate
        parse={normalizeMultiSelectValue}
        validate={validateCombinedWhitelist}
        disabled={disabled}
      />

      <Group>
        <Label>Aggregate</Label>
        <FieldContainer>
          <RenderIpAggregate disabled={disabled} />
        </FieldContainer>
        <Description>Aggregate IP addresses by mask length</Description>
      </Group>
    </Fragment>
  );
};

FlowspecTrafficFields.propTypes = {
  mode: PropTypes.string.isRequired,
  disableSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

FlowspecTrafficFields.defaultProps = {
  disabled: false,
};

export default FlowspecTrafficFields;
