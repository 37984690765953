import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  actions as vpcActions,
  selectors as vpcSelectors,
} from '@/redux/api/vpc';

export default () => {
  const dispatch = useDispatch();
  const classifications = useSelector(vpcSelectors.getVpcClassifications);

  useEffect(
    () => {
      if (!classifications) {
        dispatch(vpcActions.fetchVpcClassification());
      }
    },
    [classifications],
  );

  return classifications;
};
