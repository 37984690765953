const AggregatorTypes = {
  none: 'none',
  unique: 'unique',
  count: 'count',
  sum: 'sum',
  min: 'min',
  max: 'max',
  average: 'average',
  median: 'median',
};

export default AggregatorTypes;
