/* eslint-disable camelcase */
import { ContextTypes } from '@/models/ContextTypes';

import makeArr from '+utils/makeArr';

const matchThresholdMethods = /\w+\((?:\s*,?\s*\w+)*\s*\)/gim;

export const Config = {
  // defaults
  defaultSearchBySearch: '',
  defaultSearchBy: { type: 'all', search: [''] },
  defaultThresholds: { severity: 'medium', threshold: '' },
  defaultTrackBy: [],
  defaultContextTrackBy: {
    [ContextTypes.flow]: ['dstip'],
    [ContextTypes.dns]: ['srcip'],
  },
  defaultDiscards: '',
  // max
  maxSearchBy: 7, // synthetic ui limit
  maxSearchBySearch: 5, // synthetic ui limit
  maxThresholds: 6,
  maxTrackBy: 6, // synthetic ui limit
  maxDiscards: 50, // synthetic ui limit
  maxNameLength: 50,
  maxDescriptionLength: 120,
};

export const cadenceOptions = {
  daily: { value: '1d', label: 'Daily' },
  weekly: { value: '7d', label: 'Weekly' },
  monthly: { value: '30d', label: 'Monthly' },
};

export const strategyOptions = {
  average: { value: 'average', label: 'average' },
  min: { value: 'min', label: 'min' },
  max: { value: 'max', label: 'max' },
};

// TODO: API2021 Remove it after DB convertation
export const trackFromDdToUiConverter = (track) => (Array.isArray(track) ? track : track.fields);

export const paramsToUi = (params) => {
  if (!params) {
    return {};
  }

  const values = { ...params };

  if (!params.search_by?.length) {
    values.search_by = [Config.defaultSearchBy];
  } else {
    values.search_by = values.search_by.map((item) => ({
      ...item,
      search: makeArr(item.search),
    }));
  }

  if (!params.thresholds?.length) {
    values.thresholds = [Config.defaultThresholds];
  }

  if (!params.track_by?.length) {
    values.track_by = [Config.defaultTrackBy];
  } else {
    values.track_by = params.track_by.map(trackFromDdToUiConverter);
  }

  if (params.action?.length) {
    const dstip = params.action.find(({ field }) => field === 'dstip');
    if (dstip) {
      values.dstipContext = dstip.context;
      values.dstipLabels = dstip.labels;
    }
    const srcip = params.action.find(({ field }) => field === 'srcip');
    if (srcip) {
      values.srcipContext = srcip.context;
      values.srcipLabels = srcip.labels;
    }
  }

  if (!params.discards?.length) {
    values.discards = [Config.defaultDiscards];
  }

  if (
    params.autoThresholdData?.data_window
    && typeof params.autoThresholdData?.data_window === 'string'
  ) {
    values.autoThresholdData.data_window = parseInt(
      params.autoThresholdData.data_window?.replace(/[^-\d]/g, ''),
      10,
    );
  }

  if (
    params.autoThresholdData?.data_lookback
    && typeof params.autoThresholdData?.data_lookback === 'string'
  ) {
    values.autoThresholdData.data_lookback = parseInt(
      params.autoThresholdData.data_lookback?.replace(/[^-\d]/g, ''),
      10,
    );
  }

  return values;
};

export const uiToAutoThresholdParams = (values) => {
  const valuesData = values.autoThresholdData;
  const data = { ...valuesData };

  // need to handle parsing here in addition to final form parse methods because of this bug:
  // https://github.com/final-form/react-final-form/issues/431
  // if form values are not changed from default, parse() methods will not be applied.

  data.data_interval = valuesData?.data_interval?.value ?? valuesData?.data_interval;
  data.strategy = valuesData?.strategy?.value ?? valuesData?.strategy;
  data.data_lookback = `${valuesData?.data_lookback}d`;
  data.data_window = `${valuesData?.data_window}h`;
  data.algorithm = values?.name;
  data.track_by = values?.track_by[0];

  // update interval is currently locked at 10 minutes
  data.update_interval = '10m';

  // currently UI does not support filters.
  data.filters = [];

  // we need to pull methods from thresholds and concat to array
  // e.g. avg(bitsxrate) >= 20000000 || avg(packetsxrate) >= 20000
  // becomes ['avg(bitsxrate)', 'avg(packetsxrate)']

  data.thresholds = [
    ...new Set(
      values?.thresholds.reduce(
        (acc, curr) => [
          ...acc,
          ...(curr.threshold.replace(/ /g, '').match(matchThresholdMethods)
            || []),
        ],
        [],
      ),
    ),
  ];

  return data;
};

export const uiToParams = (values) => {
  const params = { ...values };
  if (params.rollupperiod && params.rollupperiod >= 0) {
    params.rollupperiod = parseInt(params.rollupperiod, 10);
  } else {
    params.rollupperiod = null;
  }

  if (params.expiration && params.expiration >= 0) {
    params.expiration = parseInt(params.expiration, 10);
  } else {
    params.expiration = null;
  }

  if (params.updateinterval && params.updateinterval >= 0) {
    params.updateinterval = parseInt(params.updateinterval, 10);
  } else {
    params.updateinterval = 0;
  }

  if (params.ndm_score_threat && params.ndm_score_threat >= 0) {
    params.ndm_score_threat = parseInt(params.ndm_score_threat, 10);
  } else {
    params.ndm_score_threat = 0;
  }

  if (params.ndm_score_confidence && params.ndm_score_confidence >= 0) {
    params.ndm_score_confidence = parseInt(params.ndm_score_confidence, 10);
  } else {
    params.ndm_score_confidence = 0;
  }

  if (params.track_by[0]?.length && params.algo_type === 'CDM') {
    const action = [];
    if (params.track_by[0].indexOf('dstip') > -1) {
      const contextLabel = {
        type: 'label',
        field: 'dstip',
        context: params.dstipContext?.toString(),
        labels: params.dstipLabels,
      };
      action.push(contextLabel);
    }
    if (params.track_by[0].indexOf('srcip') > -1) {
      const contextLabel = {
        type: 'label',
        field: 'srcip',
        context: params.srcipContext?.toString(),
        labels: params.srcipLabels,
      };
      action.push(contextLabel);
    }
    delete params.srcipContext;
    delete params.srcipLabels;
    delete params.dstipContext;
    delete params.dstipLabels;
    params.action = action;
  }

  params.search_by = (params.search_by || []).filter(
    (item) => !!item.search.filter((el) => !!el?.trim()).length,
  );

  params.thresholds = (params.thresholds || []).filter(
    (item) => !!item.threshold?.trim(),
  );
  params.discards = (params.discards || []).filter((item) => !!item?.trim());

  // delete ui-only property
  delete params.autoThresholdEnabled;

  // delete auto-threshold object, doesn't get sent to rule-engine
  delete params.autoThresholdData;

  return params;
};

export const parseChartMetricFromOperation = (operation) => {
  const countFields = [
    'cnt',
    'count',
    'avg',
    'average',
    'div',
    'divide',
    'min',
    'max',
    'product',
    'multiply',
  ];
  if (operation) {
    if (countFields.some((field) => operation.includes(field))) {
      return 'counts';
    }
    if (operation.includes('bitsrate') || operation.includes('bitsxrate')) {
      return 'bitrate';
    }
    if (operation.includes('bits')) {
      return 'bits';
    }
    if (
      operation.includes('packetsrate')
      || operation.includes('packetsxrate')
    ) {
      return 'packetrate';
    }
    if (operation.includes('packet')) {
      return 'packets';
    }
  }
  return 'counts';
};

export const ndmUrlMappings = {
  ackflood: {
    url: 'https://docs.netography.com/netography-docs/docs/denial-of-service#ackflood',
  },
  chargenreflect: {
    url: 'https://docs.netography.com/netography-docs/docs/denial-of-service#chargenreflect',
  },
  cldapreflect: {
    url: 'https://docs.netography.com/netography-docs/docs/denial-of-service#cldapreflect',
  },
  codreflection: {
    url: 'https://docs.netography.com/netography-docs/docs/denial-of-service#codreflection',
  },
  dns_amplification_participation: {
    url: 'https://docs.netography.com/netography-docs/docs/denial-of-service#dns_amplification_participation',
  },
  dnsattack: {
    url: 'https://docs.netography.com/netography-docs/docs/denial-of-service#dnsattack',
  },
  dnsreflection: {
    url: 'https://docs.netography.com/netography-docs/docs/denial-of-service#dnsreflection',
  },
  fin_flood: {
    url: 'https://docs.netography.com/netography-docs/docs/denial-of-service#fin_flood',
  },
  icmpflood: {
    url: 'https://docs.netography.com/netography-docs/docs/denial-of-service#icmpflood',
  },
  memcachereflection: {
    url: 'https://docs.netography.com/netography-docs/docs/denial-of-service#memcachereflection',
  },
  mssqlreflection: {
    url: 'https://docs.netography.com/netography-docs/docs/denial-of-service#mssqlreflection',
  },
  netbiosreflect: {
    url: 'https://docs.netography.com/netography-docs/docs/denial-of-service#netbiosreflect',
  },
  ntpreflect: {
    url: 'https://docs.netography.com/netography-docs/docs/denial-of-service#ntpreflect',
  },
  psh_flood: {
    url: 'https://docs.netography.com/netography-docs/docs/denial-of-service#psh_flood',
  },
  rdp_brute_force: {
    url: 'https://docs.netography.com/netography-docs/docs/denial-of-service#rdp_brute_force',
  },
  ripreflection: {
    url: 'https://docs.netography.com/netography-docs/docs/denial-of-service#ripreferlection',
  },
  rstflood: {
    url: 'https://docs.netography.com/netography-docs/docs/denial-of-service#rstflood',
  },
  snmpreflection: {
    url: 'https://docs.netography.com/netography-docs/docs/denial-of-service#snmpreflection',
  },
  srcdsreflection: {
    url: 'https://docs.netography.com/netography-docs/docs/denial-of-service#srcdsreflection',
  },
  ssdpreflect: {
    url: 'https://docs.netography.com/netography-docs/docs/denial-of-service#ssdpreflect',
  },
  sshbrute: {
    url: 'https://docs.netography.com/netography-docs/docs/denial-of-service#sshbrute',
  },
  sunrpcreflection: {
    url: 'https://docs.netography.com/netography-docs/docs/denial-of-service#sunrpcreflection',
  },
  synflood: {
    url: 'https://docs.netography.com/netography-docs/docs/denial-of-service#synflood',
  },
  urg_flood: {
    url: 'https://docs.netography.com/netography-docs/docs/denial-of-service#urg_flood',
  },
  '6in4tunnel': {
    url: 'https://docs.netography.com/netography-docs/docs/informational#6in4tunnel',
  },
  alltcpflags: {
    url: 'https://docs.netography.com/netography-docs/docs/informational#alltcpflags',
  },
  badprotocol: {
    url: 'https://docs.netography.com/netography-docs/docs/informational#badprotocol',
  },
  communication_to_copc_countries: {
    url: 'https://docs.netography.com/netography-docs/docs/informational#communication_to_copc_countries',
  },
  ip_options_abuse: {
    url: 'https://docs.netography.com/netography-docs/docs/informational#ip_options_abuse',
  },
  ipmi: {
    url: 'https://docs.netography.com/netography-docs/docs/informational#ipmi',
  },
  largeicmp: {
    url: 'https://docs.netography.com/netography-docs/docs/informational#largeicmp',
  },
  malformedip: {
    url: 'https://docs.netography.com/netography-docs/docs/informational#malformedip',
  },
  synfin: {
    url: 'https://docs.netography.com/netography-docs/docs/informational#synfin',
  },
  tcp_dnstunneling: {
    url: 'https://docs.netography.com/netography-docs/docs/informational#tcp_dnstunneling',
  },
  tcpfrag: {
    url: 'https://docs.netography.com/netography-docs/docs/informational#tcpfrag',
  },
  tcpnull: {
    url: 'https://docs.netography.com/netography-docs/docs/informational#tcpnull',
  },
  udpfrag: {
    url: 'https://docs.netography.com/netography-docs/docs/informational#udpfrag',
  },
  azure_registered_open_ports: {
    url: 'https://docs.netography.com/netography-docs/docs/misconfiguration#azure_registered_open_ports',
  },
  external_access_of_smb: {
    url: 'https://docs.netography.com/netography-docs/docs/misconfiguration#external_access_of_smb',
  },
  external_kerberos_access: {
    url: 'https://docs.netography.com/netography-docs/docs/misconfiguration#external_kerberos_access',
  },
  external_ldap_access: {
    url: 'https://docs.netography.com/netography-docs/docs/misconfiguration#external_ldap_access',
  },
  external_printing_connections: {
    url: 'https://docs.netography.com/netography-docs/docs/misconfiguration#external_printing_connections',
  },
  external_snmp_sweep: {
    url: 'https://docs.netography.com/netography-docs/docs/misconfiguration#external_snmp_sweep',
  },
  inbound_established_non_http: {
    url: 'https://docs.netography.com/netography-docs/docs/misconfiguration#inbound_established_non_http',
  },
  internal_socks5_proxy: {
    url: 'https://docs.netography.com/netography-docs/docs/misconfiguration#internal_socks5_proxy',
  },
  msrdp: {
    url: 'https://docs.netography.com/netography-docs/docs/misconfiguration#msrdp',
  },
  outbound_database_exfil: {
    url: 'https://docs.netography.com/netography-docs/docs/misconfiguration#outbound_database_exfil',
  },
  outbound_ftp_traffic: {
    url: 'https://docs.netography.com/netography-docs/docs/misconfiguration#outbound_ftp_traffic',
  },
  outbound_imap_traffic: {
    url: 'https://docs.netography.com/netography-docs/docs/misconfiguration#outbound_imap_traffic',
  },
  outbound_ldap_spike: {
    url: 'https://docs.netography.com/netography-docs/docs/misconfiguration#outbound_ldap_spike',
  },
  outbound_pop3_traffic: {
    url: 'https://docs.netography.com/netography-docs/docs/misconfiguration#outbound_pop3_traffic',
  },
  outbound_rejected_traffic: {
    url: 'https://docs.netography.com/netography-docs/docs/misconfiguration#outbound_rejected_traffic',
  },
  outbound_smb_spike: {
    url: 'https://docs.netography.com/netography-docs/docs/misconfiguration#outbound_smb_spike',
  },
  outbound_smb_traffic: {
    url: 'https://docs.netography.com/netography-docs/docs/misconfiguration#outbound_smb_traffic',
  },
  outbound_snmp_sweep: {
    url: 'https://docs.netography.com/netography-docs/docs/misconfiguration#outbound_snmp_sweep',
  },
  outbound_telnet_traffic: {
    url: 'https://docs.netography.com/netography-docs/docs/misconfiguration#outbound_telnet_traffic',
  },
  outbound_tor_connection: {
    url: 'https://docs.netography.com/netography-docs/docs/misconfiguration#outbound_tor_connection',
  },
  rdp_external_internal: {
    url: 'https://docs.netography.com/netography-docs/docs/misconfiguration#rdp_external_internal',
  },
  ssh_external_internal: {
    url: 'https://docs.netography.com/netography-docs/docs/misconfiguration#ssh_external_internal',
  },
  anydesk_usage: {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#anydesk_usage',
  },
  bitcoin_mining: {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#bitcoin_mining',
  },
  bittorrent: {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#bittorrent',
  },
  connectwise_usage: {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#connectwise_usage',
  },
  dcplusplus: {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#dcplusplus',
  },
  etherium_mining: {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#etherium_mining',
  },
  external_socks5_proxy: {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#external_socks5_proxy',
  },
  'file-sharing_4shared_detection': {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#file-sharing_4shared_detection',
  },
  'file-sharing_apple-icloud': {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#file-sharing_apple-icloud',
  },
  'file-sharing_box-net_detection': {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#file-sharing_box-net_detection',
  },
  'file-sharing_dropbox_detection': {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#file-sharing_dropbox_detection',
  },
  'file-sharing_filefactory': {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#file-sharing_filefactory',
  },
  'file-sharing_idrive_detection': {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#file-sharing_idrive_detection',
  },
  'file-sharing_mediafire_detection': {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#file-sharing_mediafire_detection',
  },
  'file-sharing_mega-service': {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#file-sharing_mega-service',
  },
  'file-sharing_microsoft-onedrive': {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#file-sharing_microsoft-onedrive',
  },
  'file-sharing_sharefile_detection': {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#file-sharing_sharefile_detection',
  },
  'file-sharing_syncplicity': {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#file-sharing_syncplicity',
  },
  'file-sharing_syncthing_detection': {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#file-sharing_syncthing_detection',
  },
  'file-sharing_usenetserver': {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#file-sharing_usenetserver',
  },
  'file-sharing_ushareit_detection': {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#file-sharing_ushareit_detection',
  },
  'file-sharing_wetransfer': {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#file-sharing_wetransfer',
  },
  gotoresolve_usage: {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#gotoresolve_usage',
  },
  ipfs_usage: {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#ipfs_usage',
  },
  irctraffic: {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#irctraffic',
  },
  'messaging_apple-push': {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#messaging_apple-push',
  },
  messaging_discord: {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#messaging_discord',
  },
  messaging_disqus: {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#messaging_disqus',
  },
  'messaging_facebook-messenger': {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#messaging_facebook-messenger',
  },
  'messaging_google-chat': {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#messaging_google-chat',
  },
  messaging_icq: {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#messaging_icq',
  },
  messaging_infobip: {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#messaging_infobip',
  },
  messaging_jpush: {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#messaging_jpush',
  },
  messaging_kakaotalk: {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#messaging_kakaotalk',
  },
  messaging_kik: {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#messaging_kik',
  },
  messaging_messagebird: {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#messaging_messagebird',
  },
  'messaging_meta-messaging': {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#messaging_meta-messaging',
  },
  messaging_pushover: {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#messaging_pushover',
  },
  'messaging_rocket-chat': {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#messaging_rocket-chat',
  },
  'messaging_samsung-push': {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#messaging_samsung-push',
  },
  messaging_signal: {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#messaging_signal',
  },
  messaging_sinch: {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#messaging_sinch',
  },
  messaging_snapchat: {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#messaging_snapchat',
  },
  'messaging_stream-io': {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#messaging_stream-io',
  },
  messaging_telegram: {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#messaging_telegram',
  },
  messaging_threema: {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#messaging_threema',
  },
  messaging_wechat: {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#messaging_wechat',
  },
  messaging_whatsapp: {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#messaging_whatsapp',
  },
  messaging_zalo: {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#messaging_zalo',
  },
  octoshape: {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#octoshape',
  },
  social_discourse_detection: {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#social_discourse_detection',
  },
  social_instagram_detection: {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#social_instagram_detection',
  },
  social_linkedin_detection: {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#social_linkedin_detection',
  },
  social_meta_detection: {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#social_meta_detection',
  },
  social_okcupid_detection: {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#social_okcupid_detection',
  },
  social_reddit_detection: {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#social_reddit_detection',
  },
  social_tiktok_detection: {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#social_tiktok_detection',
  },
  social_tinder_detection: {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#social_tinder_detection',
  },
  social_twitter_detection: {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#social_twitter_detection',
  },
  teamviewer_inside_to_outside: {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#teamviewer_inside_to_outside',
  },
  teamviewer_out_to_inside: {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#teamviewer_out_to_inside',
  },
  teamviewer_usage: {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#teamviewer_usage',
  },
  third_party_vpn_usage: {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#third_party_vpn_usage',
  },
  vpn_usage_internal_external: {
    url: 'https://docs.netography.com/netography-docs/docs/operational-governance#vpn_usage_internal_external',
  },
  agenttesla_detection: {
    url: 'https://docs.netography.com/netography-docs/docs/post-compromise#agenttesla_detection',
  },
  amadey_detection: {
    url: 'https://docs.netography.com/netography-docs/docs/post-compromise#amadey_detection',
  },
  coinminer_detection: {
    url: 'https://docs.netography.com/netography-docs/docs/post-compromise#coinminer_detection',
  },
  connect_out_to_bad_rep: {
    url: 'https://docs.netography.com/netography-docs/docs/post-compromise#connect_out_to_bad_rep',
  },
  'cve-2023-23560_lexmark_inbound': {
    url: 'https://docs.netography.com/netography-docs/docs/post-compromise#cve-2023-23560_lexmark_inbound',
  },
  'cve-2023-23560_lexmark_internal': {
    url: 'https://docs.netography.com/netography-docs/docs/post-compromise#cve-2023-23560_lexmark_internal',
  },
  'dlp-china': {
    url: 'https://docs.netography.com/netography-docs/docs/post-compromise#dlp-china',
  },
  'dlp-russia': {
    url: 'https://docs.netography.com/netography-docs/docs/post-compromise#dlp-russia',
  },
  dnstunneling: {
    url: 'https://docs.netography.com/netography-docs/docs/post-compromise#dnstunneling',
  },
  formbook_detection: {
    url: 'https://docs.netography.com/netography-docs/docs/post-compromise#formbook_detection',
  },
  hajime_detection: {
    url: 'https://docs.netography.com/netography-docs/docs/post-compromise#hajime_detection',
  },
  internal_snmp_sweep: {
    url: 'https://docs.netography.com/netography-docs/docs/post-compromise#internal_snmp_sweep',
  },
  ip_lookup_attempt: {
    url: 'https://docs.netography.com/netography-docs/docs/post-compromise#ip_lookup_attempt',
  },
  ldap_scanning_inside_to_outside: {
    url: 'https://docs.netography.com/netography-docs/docs/post-compromise#ldap_scanning_inside_to_outside',
  },
  mirai_detection: {
    url: 'https://docs.netography.com/netography-docs/docs/post-compromise#mirai_detection',
  },
  mozi_attempt: {
    url: 'https://docs.netography.com/netography-docs/docs/post-compromise#mozi_attempt',
  },
  mozi_detection: {
    url: 'https://docs.netography.com/netography-docs/docs/post-compromise#mozi_detection',
  },
  neto_scanner_outbound: {
    url: 'https://docs.netography.com/netography-docs/docs/post-compromise#neto_scanner_outbound',
  },
  njrat_detection: {
    url: 'https://docs.netography.com/netography-docs/docs/post-compromise#njrat_detection',
  },
  'ping_scan_int-ext': {
    url: 'https://docs.netography.com/netography-docs/docs/post-compromise#ping_scan_int-ext',
  },
  port_1433_scanning_outbound: {
    url: 'https://docs.netography.com/netography-docs/docs/post-compromise#port_1433_scanning_outbound',
  },
  port_445_scanning_outbound: {
    url: 'https://docs.netography.com/netography-docs/docs/post-compromise#port_445_scanning_outbound',
  },
  port_62078_scanning_outbound: {
    url: 'https://docs.netography.com/netography-docs/docs/post-compromise#port_62078_scanning_outbound',
  },
  port_8443_scanning_outbound: {
    url: 'https://docs.netography.com/netography-docs/docs/post-compromise#port_8443_scanning_outbound',
  },
  rdp_internal_external: {
    url: 'https://docs.netography.com/netography-docs/docs/post-compromise#rdp_internal_external',
  },
  rdp_scanning_inside_to_outside: {
    url: 'https://docs.netography.com/netography-docs/docs/post-compromise#rdp_scanning_inside_to_outside',
  },
  recordbreaker_detection: {
    url: 'https://docs.netography.com/netography-docs/docs/post-compromise#recordbreaker_detection',
  },
  sinkhole_detection: {
    url: 'https://docs.netography.com/netography-docs/docs/post-compromise#sinkhole_detection',
  },
  sshbrute_response: {
    url: 'https://docs.netography.com/netography-docs/docs/post-compromise#sshbrute_response',
  },
  suspected_port_abuse_internal: {
    url: 'https://docs.netography.com/netography-docs/docs/post-compromise#suspected_port_abuse_internal',
  },
  synscan_internal_external: {
    url: 'https://docs.netography.com/netography-docs/docs/post-compromise#synscan_internal_external',
  },
  torrent_usage_detection: {
    url: 'https://docs.netography.com/netography-docs/docs/post-compromise#torrent_usage_detection',
  },
  uncommon_icmp_reject: {
    url: 'https://docs.netography.com/netography-docs/docs/post-compromise#uncommon_icmp_reject',
  },
  censys_scanning: {
    url: 'https://docs.netography.com/netography-docs/docs/recon#censys_scanning',
  },
  connscan: {
    url: 'https://docs.netography.com/netography-docs/docs/recon#connscan',
  },
  'cve-2015-4852_port_scan_internal': {
    url: 'https://docs.netography.com/netography-docs/docs/recon#cve-2015-4852_port_scan_internal',
  },
  'cve-2018-0171_port_scan_inbound': {
    url: 'https://docs.netography.com/netography-docs/docs/recon#cve-2018-0171_port_scan_inbound',
  },
  'cve-2018-0171_port_scan_internal': {
    url: 'https://docs.netography.com/netography-docs/docs/recon#cve-2018-0171_port_scan_internal',
  },
  'cve-2022-20821_port_scan_inbound': {
    url: 'https://docs.netography.com/netography-docs/docs/recon#cve-2022-20821_port_scan_inbound',
  },
  'cve-2022-20821_port_scan_interna': {
    url: 'https://docs.netography.com/netography-docs/docs/recon#cve-2022-20821_port_scan_interna',
  },
  'cve-2022-47966_port_scan': {
    url: 'https://docs.netography.com/netography-docs/docs/recon#cve-2022-47966_port_scan',
  },
  'cve-2023-27532_port_scan': {
    url: 'https://docs.netography.com/netography-docs/docs/recon#cve-2023-27532_port_scan',
  },
  esxi_internal_slp_scan: {
    url: 'https://docs.netography.com/netography-docs/docs/recon#esxi_internal_slp_scan',
  },
  icmpscan: {
    url: 'https://docs.netography.com/netography-docs/docs/recon#icmpscan',
  },
  inbound_snmp_sweep: {
    url: 'https://docs.netography.com/netography-docs/docs/recon#inbound_snmp_sweep',
  },
  'internal_scan_tcp-rst-ack': {
    url: 'https://docs.netography.com/netography-docs/docs/recon#internal_scan_tcp-rst-ack',
  },
  knownbotnet: {
    url: 'https://docs.netography.com/netography-docs/docs/recon#knownbotnet',
  },
  knownddos: {
    url: 'https://docs.netography.com/netography-docs/docs/recon#knownddos',
  },
  knownmobilethreat: {
    url: 'https://docs.netography.com/netography-docs/docs/recon#knownmobilethreat',
  },
  knownphisher: {
    url: 'https://docs.netography.com/netography-docs/docs/recon#knownphisher',
  },
  knownproxy: {
    url: 'https://docs.netography.com/netography-docs/docs/recon#knownproxy',
  },
  knownscanner: {
    url: 'https://docs.netography.com/netography-docs/docs/recon#knownscanner',
  },
  knownspamsrc: {
    url: 'https://docs.netography.com/netography-docs/docs/recon#knownspamsrc',
  },
  knowntorproxy: {
    url: 'https://docs.netography.com/netography-docs/docs/recon#knowntorproxy',
  },
  knownwebattack: {
    url: 'https://docs.netography.com/netography-docs/docs/recon#knownwebattack',
  },
  knownwinexp: {
    url: 'https://docs.netography.com/netography-docs/docs/recon#knownwinexp',
  },
  ldap_scanning_internal: {
    url: 'https://docs.netography.com/netography-docs/docs/recon#ldap_scanning_internal',
  },
  ldap_scanning_outside_to_inside: {
    url: 'https://docs.netography.com/netography-docs/docs/recon#ldap_scanning_outside_to_inside',
  },
  nmapfingerprint: {
    url: 'https://docs.netography.com/netography-docs/docs/recon#nmapfingerprint',
  },
  non_service_port_scanner: {
    url: 'https://docs.netography.com/netography-docs/docs/recon#non_service_port_scanner',
  },
  'ping_scan_ext-int': {
    url: 'https://docs.netography.com/netography-docs/docs/recon#ping_scan_ext-int',
  },
  'ping_scan_int-int': {
    url: 'https://docs.netography.com/netography-docs/docs/recon#ping_scan_int-int',
  },
  port_1433_scanning_internal: {
    url: 'https://docs.netography.com/netography-docs/docs/recon#port_1433_scanning_internal',
  },
  port_445_scanning_internal: {
    url: 'https://docs.netography.com/netography-docs/docs/recon#port_445_scanning_internal',
  },
  port_8443_scanning_internal: {
    url: 'https://docs.netography.com/netography-docs/docs/recon#port_8443_scanning_internal',
  },
  portscan: {
    url: 'https://docs.netography.com/netography-docs/docs/recon#portscan',
  },
  qualys_scanning: {
    url: 'https://docs.netography.com/netography-docs/docs/recon#qualys_scanning',
  },
  rdp_scanning_internal: {
    url: 'https://docs.netography.com/netography-docs/docs/recon#rdp_scanning_internal',
  },
  rdp_scanning_outside_to_inside: {
    url: 'https://docs.netography.com/netography-docs/docs/recon#rdp_scanning_outside_to_inside',
  },
  remote_access_itar: {
    url: 'https://docs.netography.com/netography-docs/docs/recon#remote_access_itar',
  },
  rpc_authentication_attack: {
    url: 'https://docs.netography.com/netography-docs/docs/recon#rpc_authentication_attack',
  },
  rstscan: {
    url: 'https://docs.netography.com/netography-docs/docs/recon#rstscan',
  },
  scanner_rwth_aachen_univ: {
    url: 'https://docs.netography.com/netography-docs/docs/recon#scanner_rwth_aachen_univ',
  },
  shadowserver_scanning: {
    url: 'https://docs.netography.com/netography-docs/docs/recon#shadowserver_scanning',
  },
  shodan_scanners: {
    url: 'https://docs.netography.com/netography-docs/docs/recon#shodan_scanners',
  },
  suspected_port_abuse_external: {
    url: 'https://docs.netography.com/netography-docs/docs/recon#suspected_port_abuse_external',
  },
  synscan: {
    url: 'https://docs.netography.com/netography-docs/docs/recon#synscan',
  },
  synscan_external_internal: {
    url: 'https://docs.netography.com/netography-docs/docs/recon#synscan_external_internal',
  },
  synscan_internal_internal: {
    url: 'https://docs.netography.com/netography-docs/docs/recon#synscan_internal_internal',
  },
  teamviewer_scanning_internal: {
    url: 'https://docs.netography.com/netography-docs/docs/recon#teamviewer_scanning_internal',
  },
  udp_port_sweep_attempt: {
    url: 'https://docs.netography.com/netography-docs/docs/recon#udp_port_sweep_attempt',
  },
  vnc_scanning_internal: {
    url: 'https://docs.netography.com/netography-docs/docs/recon#vnc_scanning_internal',
  },
  vnc_scanning_outside_to_inside: {
    url: 'https://docs.netography.com/netography-docs/docs/recon#vnc_scanning_outside_to_inside',
  },
  xmastree: {
    url: 'https://docs.netography.com/netography-docs/docs/recon#xmastree',
  },
  wkpsrcdst: {
    url: 'https://docs.netography.com/docs/post-compromise#wkpsrcdst',
  },
};
