import PropTypes from '+prop-types';
import { Fragment } from 'react';

import CheckBoxField from '+components/form/CheckBox';
import Field from '+components/form/FinalForm/Field';
import Description from '+components/form/FormField/components/Description';
import FieldContainer from '+components/form/FormField/components/FieldContainer';
import Group from '+components/form/FormField/components/Group';
import Label from '+components/form/FormField/components/Label';
import MultiSelectField from '+components/form/MultiSelectField';
import { normalizeMultiSelectValue } from '+components/form/Normalizers';
import { TextField } from '+components/form/TextField';
import {
  validateRequired,
  validateNumber,
  validateSampleRate,
  validateDownSampleRate,
  validateIps,
} from '+components/form/Validators';

const GeneralFields = ({ tags, disabled, maxDownsample }) => (
  <Fragment>
    <div className="card__title" style={{ marginLeft: '140px' }}>
      <h5 className="head">General Information</h5>
      <h5 className="subhead">Main information about device.</h5>
    </div>

    <Field
      name="name"
      label="Device Name"
      component={TextField}
      validate={validateRequired}
      maxLength={100}
      helperText="Name of the device. Recommend using a FQDN, e.g. router1.site.company.com"
      disabled={disabled}
      required
    />

    <Field
      name="samplerate"
      label="Sample Rate"
      component={TextField}
      type="number"
      validate={[validateRequired, validateNumber, validateSampleRate]}
      style={{ width: '35%' }}
      helperText="Sample rate of flows this device will send. Should match the configuration the device has for sending flow, and typically the value is meant as 1 in N flows"
      disabled={disabled}
      required
    />

    <Field
      name="downsample"
      label="Down Sample"
      component={TextField}
      type="number"
      validate={[validateNumber, validateDownSampleRate]}
      style={{ width: '35%' }}
      helperText="Additional downsample of the flow received from the devices. Must be 0 or > 1"
      disabled={disabled || maxDownsample != null}
    />

    <Field
      name="ips"
      label="IP Addresses"
      component={TextField}
      type="textarea"
      validate={[validateRequired, validateIps]}
      helperText="Comma-separated list of IPs that this device sends flows from"
      disabled={disabled}
      required
    />

    <Group>
      <Label>Payload</Label>
      <FieldContainer>
        <Field
          name="payload"
          label="Store sample"
          type="checkbox"
          component={CheckBoxField}
          disabled={disabled}
        />
      </FieldContainer>
      <Description>
        Whether to store payload sample or not (<b>sFlow only</b>)
      </Description>
    </Group>

    <Group>
      <Label>Post NAT</Label>
      <FieldContainer>
        <Field
          name="postnatsrc"
          label="SRC Translation"
          component={CheckBoxField}
          type="checkbox"
          disabled={disabled}
        />
      </FieldContainer>
      <Description>
        If available, replace post NAT source ip / port with pre NAT source ip /
        port.
      </Description>
    </Group>

    <Group>
      <Label />
      <FieldContainer>
        <Field
          name="postnatdst"
          label="DST Translation"
          component={CheckBoxField}
          type="checkbox"
          disabled={disabled}
        />
      </FieldContainer>
      <Description>
        If available, replace post NAT destination ip / port with pre NAT
        destination ip / port
      </Description>
    </Group>

    <div className="card__title" style={{ marginLeft: '140px' }}>
      <h5 className="head">Metadata Information</h5>
      <h5 className="subhead">
        Settings which enable expanded statistics, including searching and
        grouping.
      </h5>
    </div>

    <Field
      name="site"
      label="Site"
      component={TextField}
      type="text"
      helperText="The site identifier for this device"
      disabled={disabled}
    />

    <Field
      name="tags"
      label="Tags"
      options={tags}
      component={MultiSelectField}
      helperText="One or many tags to assign to every flow from this device."
      parse={normalizeMultiSelectValue}
      disabled={disabled}
      allowCreate
    />
  </Fragment>
);

GeneralFields.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  maxDownsample: PropTypes.number,
};

GeneralFields.defaultProps = {
  tags: [],
  disabled: false,
  maxDownsample: null,
};

export default GeneralFields;
