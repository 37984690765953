import PropTypes from '+prop-types';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import styled from 'styled-components';

import Collapse from '@mui/material/Collapse';

import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';

import SettingCategories from '@/models/SettingCategories';

import {
  actions as customerActions,
  MAX_RECENT_CUSTOMERS,
  selectors as customerSelectors,
} from '@/redux/api/customer';
import {
  actions as profileActions,
  selectors as profileSelectors,
} from '@/redux/api/user/profile';

import InputText from '+components/form/InputText';
import ScrollBar from '+components/ScrollBar/smooth';
import Spinner from '+components/Spinner';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import usePortalSettingsValue from '+hooks/usePortalSettingsValue';
import useRoles from '+hooks/useRoles';
import useUIProperty from '+hooks/useUIProperty';

import Body from './components/Body';
import CollapseMain from './components/Collapse';
import DescriptionWrapper from './components/DescriptionWrapper';
import Item from './components/Item';
import ItemFilter from './components/ItemFilter';
import ItemGroup from './components/ItemGroup';
import ItemNoData from './components/ItemNoData';
import ItemWithIcon from './components/ItemWithIcon';
import Separator from './components/Separator';

const BodyScrollBar = styled(ScrollBar)`
  max-height: 80vh;
`;

const CustomersScrollBar = styled(ScrollBar)`
  max-height: 240px;
`;

const MIN_CUSTOMERS_TO_SHOW_SEARCH = 10;

const Groups = {
  recent: 'recent',
  current: 'current',
  all: 'all',
};

const Menu = (props) => {
  const {
    description,
    isOpen,
    collapse,
    disabled,
    isFetching,
    onItemClick,
    onUnimpersonateClick,
    onLogoutClick,
    $separatorWidth,
  } = props;

  const dispatch = useDispatch();
  const location = useLocation();

  const [masqueradeUrl] = useUIProperty(
    'masqueradeUrl',
    `${location.pathname}${location.search || ''}`,
  );

  const [customersRecent, setCustomersRecent] = usePortalSettingsValue(
    SettingCategories.recent,
    'customers',
    {},
  );

  const customer = useSelector(customerSelectors.getCurrentCustomer);
  const customers = useSelector(customerSelectors.getCustomersForTopBarMenu);
  const areAllForTopBarMenuFetched = useSelector(
    customerSelectors.areAllForTopBarMenuFetched,
  );

  const { roles } = useRoles();
  const profile = useSelector(profileSelectors.getProfile);
  const canUserMasquerade = !!roles?.[profile?.roles?.[0]]?.canMasquerade;
  const originalShortname = profile?.app_metadata?.original || profile?.app_metadata?.shortname;

  const [guest] = useUIProperty('guest');
  const [isUnderCovered] = useUIProperty('underCover');
  const [isImpersonating] = useUIProperty('impersonating');

  const [filterAll, setFilterAll] = useState('');
  const [filterCurrent, setFilterCurrent] = useState('');

  const [expandedGroup, setExpandedGroup] = useState(null);

  useLoadingIndicator(isFetching);

  const customersAll = useMemo(
    () => {
      if (!canUserMasquerade) {
        return [];
      }
      return Object.values(customers || {})
        .reduce((acc, item) => {
          if (item.shortname === originalShortname) {
            return acc;
          }
          acc.push({
            shortname: item.shortname,
            organization: item.organization,
            reseller_login_enabled: !!item.meta?.reseller_login_enabled,
          });
          return acc;
        }, [])
        .sort((a, b) => a.shortname.localeCompare(b.shortname));
    },
    [canUserMasquerade, customers, originalShortname],
  );

  const customersAllFiltered = useMemo(
    () => {
      if (!filterAll) {
        return customersAll;
      }
      const filterValue = filterAll.toLowerCase();
      return customersAll.filter(
        (item) => item.shortname.toLowerCase().includes(filterValue)
        || item.organization.toLowerCase().includes(filterValue),
      );
    },
    [customersAll, filterAll],
  );

  const customersCurrent = useMemo(
    () => {
      if (!canUserMasquerade) {
        return [];
      }
      if (customer?.shortname === originalShortname) {
        return [];
      }
      return Object.values(customers || {})
        .reduce((acc, item) => {
          if (item.parent !== customer?.shortname) {
            return acc;
          }
          acc.push({
            shortname: item.shortname,
            organization: item.organization,
            reseller_login_enabled: !!item.meta?.reseller_login_enabled,
          });
          return acc;
        }, [])
        .sort((a, b) => a.shortname.localeCompare(b.shortname));
    },
    [canUserMasquerade, customers, customer?.shortname, originalShortname],
  );

  const customersCurrentFiltered = useMemo(
    () => {
      if (!filterCurrent) {
        return customersCurrent;
      }
      const filterValue = filterCurrent.toLowerCase();
      return customersCurrent.filter(
        (item) => item.shortname.toLowerCase().includes(filterValue)
        || item.organization.toLowerCase().includes(filterValue),
      );
    },
    [customersCurrent, filterCurrent],
  );

  const customersRecentFiltered = useMemo(
    () => {
      if (!canUserMasquerade) {
        return [];
      }
      return (customersRecent[originalShortname] || [])
        .reduce((acc, item) => {
          const record = customers?.[item.shortname];
          if (!record) {
            return acc;
          }
          acc.push({
            ...item,
            organization: record.organization,
            reseller_login_enabled: !!record.meta?.reseller_login_enabled,
          });
          return acc;
        }, [])
        .slice(0, MAX_RECENT_CUSTOMERS);
    },
    [canUserMasquerade, customersRecent, originalShortname, customers],
  );

  const onLoginToCustomer = useCallback(
    (shortname, addToShortname) => () => {
      const nextRecent = [...(customersRecent[addToShortname] || [])];
      const index = nextRecent.findIndex(
        (item) => item.shortname === shortname,
      );
      if (index === -1) {
        nextRecent.unshift({ shortname, timestamp: Date.now() });
      } else {
        nextRecent.splice(index, 1);
        nextRecent.unshift({ shortname, timestamp: Date.now() });
      }
      setCustomersRecent({
        ...customersRecent,
        [addToShortname]: nextRecent.slice(0, MAX_RECENT_CUSTOMERS),
      });
      dispatch(
        profileActions.loginToCustomer({
          shortname,
          masqueradeUrl,
        }),
      );
      onItemClick?.();
    },
    [
      customer?.shortname,
      customers,
      customersRecent,
      masqueradeUrl,
      onItemClick,
    ],
  );

  const onLogoutFromCustomer = useCallback(
    () => {
      dispatch(
        profileActions.logoutFromCustomer({
          masqueradeUrl,
        }),
      );
      onItemClick?.();
    },
    [masqueradeUrl, onItemClick],
  );

  const onFilterAllChange = useCallback(
    (event) => {
      setFilterAll(event?.target?.value);
    },
    [],
  );

  const onFilterCurrentChange = useCallback(
    (event) => {
      setFilterCurrent(event?.target?.value);
    },
    [],
  );

  const onGroupToggle = useCallback(
    (value) => () => {
      setExpandedGroup((prevValue) => (prevValue === value ? null : value));
    },
    [],
  );

  const showAll = !guest && !disabled && !!customersAll.length;
  const showCurrent = !guest && !disabled && !!customersCurrent.length;
  const showRecent = !guest
    && !disabled
    && customersAll.length > 10
    && !!customersRecentFiltered.length;

  useEffect(
    () => {
      if (isUnderCovered && showCurrent) {
        setExpandedGroup(Groups.current);
        return;
      }
      if (!isUnderCovered && showRecent) {
        setExpandedGroup(Groups.recent);
        return;
      }
      if (!isUnderCovered && showAll) {
        setExpandedGroup(Groups.all);
        return;
      }
      setExpandedGroup(null);
    },
    [isOpen, isUnderCovered, showAll, showRecent, showCurrent],
  );

  useEffect(
    () => {
      const skip = areAllForTopBarMenuFetched
      || !originalShortname
      || (!customer?.isReseller && customer?.shortname === originalShortname);
      if (skip || guest || disabled) {
        return undefined;
      }
      const namespace = 'fetch_customers_for_top_bar_menu';
      dispatch(customerActions.fetchForTopBarMenu(originalShortname, namespace));
      return () => {
        dispatch(customerActions.cancel(namespace));
      };
    },
    [
      areAllForTopBarMenuFetched,
      customer?.isReseller,
      customer?.shortname,
      originalShortname,
      disabled,
      guest,
    ],
  );

  return (
    <CollapseMain
      in={isOpen}
      $separatorWidth={$separatorWidth}
      $collapse={collapse}
      timeout={collapse ? 0 : undefined}
    >
      <BodyScrollBar>
        <Body>
          {description && (
            <Fragment>
              <DescriptionWrapper $separatorWidth={$separatorWidth}>
                {description}
              </DescriptionWrapper>
              <Separator $width={$separatorWidth} />
            </Fragment>
          )}

          {showRecent && (
            <Fragment>
              <ItemGroup
                title={(
                  <Fragment>
                    Recent Accounts
                    {expandedGroup === Groups.recent ? (
                      <ChevronDownIcon size={16} />
                    ) : (
                      <ChevronRightIcon size={16} />
                    )}
                  </Fragment>
                )}
                onClick={onGroupToggle(Groups.recent)}
                $expandable
              />

              <Collapse in={expandedGroup === Groups.recent}>
                <CustomersScrollBar>
                  {customersRecentFiltered.map((item) => (
                    <Item
                      key={item.shortname}
                      title={item.shortname}
                      description={item.organization}
                      disabled={
                        !(
                          item.reseller_login_enabled
                          || originalShortname === 'netography'
                        )
                      }
                      onClick={onLoginToCustomer(
                        item.shortname,
                        originalShortname,
                      )}
                    />
                  ))}
                </CustomersScrollBar>
              </Collapse>
            </Fragment>
          )}

          {showCurrent && (
            <Fragment>
              <ItemGroup
                title={(
                  <Fragment>
                    {customer?.shortname} Accounts
                    {expandedGroup === Groups.current ? (
                      <ChevronDownIcon size={16} />
                    ) : (
                      <ChevronRightIcon size={16} />
                    )}
                  </Fragment>
                )}
                onClick={onGroupToggle(Groups.current)}
                $expandable
              />

              <Collapse in={expandedGroup === Groups.current}>
                <CustomersScrollBar>
                  {customersCurrent.length > MIN_CUSTOMERS_TO_SHOW_SEARCH && (
                    <ItemFilter
                      title={(
                        <InputText
                          placeholder="Search Accounts"
                          value={filterCurrent}
                          onChange={onFilterCurrentChange}
                          showClearButton
                        />
                      )}
                    />
                  )}

                  {customersCurrentFiltered.map((item) => (
                    <Item
                      key={item.shortname}
                      title={item.shortname}
                      description={item.organization}
                      disabled={
                        !(
                          item.reseller_login_enabled
                          || originalShortname === 'netography'
                        )
                      }
                      onClick={onLoginToCustomer(
                        item.shortname,
                        originalShortname,
                      )}
                    />
                  ))}

                  {!!customersCurrent.length
                    && !customersCurrentFiltered.length && (
                    <ItemNoData title="No accounts found" />
                  )}
                </CustomersScrollBar>
              </Collapse>
            </Fragment>
          )}

          {showAll && (
            <Fragment>
              <ItemGroup
                title={(
                  <Fragment>
                    {showCurrent ? 'My' : 'All'} Accounts
                    {expandedGroup === Groups.all ? (
                      <ChevronDownIcon size={16} />
                    ) : (
                      <ChevronRightIcon size={16} />
                    )}
                  </Fragment>
                )}
                onClick={onGroupToggle(Groups.all)}
                $expandable
              />

              <Collapse in={expandedGroup === Groups.all}>
                <CustomersScrollBar>
                  {customersAll.length > MIN_CUSTOMERS_TO_SHOW_SEARCH && (
                    <ItemFilter
                      title={(
                        <InputText
                          placeholder="Search Accounts"
                          value={filterAll}
                          onChange={onFilterAllChange}
                          showClearButton
                        />
                      )}
                    />
                  )}

                  {customersAllFiltered.map((item) => (
                    <Item
                      key={item.shortname}
                      title={item.shortname}
                      description={item.organization}
                      disabled={
                        !(
                          item.reseller_login_enabled
                          || originalShortname === 'netography'
                        )
                      }
                      onClick={onLoginToCustomer(
                        item.shortname,
                        originalShortname,
                      )}
                    />
                  ))}

                  {!!customersAll.length && !customersAllFiltered.length && (
                    <ItemNoData title="No accounts found" />
                  )}
                </CustomersScrollBar>
              </Collapse>
            </Fragment>
          )}

          {(showRecent || showCurrent || showAll) && (
            <Separator $width={$separatorWidth} />
          )}

          {isImpersonating && (
            <ItemWithIcon
              title={(
                <Fragment>
                  Revert Masquerade
                  {isFetching && <Spinner $size={16} />}
                </Fragment>
              )}
              path={location.pathname}
              onClick={onUnimpersonateClick}
            />
          )}

          {isUnderCovered && (
            <ItemWithIcon
              title={(
                <Fragment>
                  Return to {profile.app_metadata.original}
                  {isFetching && <Spinner $size={16} />}
                </Fragment>
              )}
              onClick={onLogoutFromCustomer}
              testId="log-out-from-customer"
            />
          )}

          {!guest && (
            <Item
              title="Log Out"
              path={location.pathname}
              onClick={onLogoutClick}
              testId="log-out"
            />
          )}
        </Body>
      </BodyScrollBar>
    </CollapseMain>
  );
};

Menu.propTypes = {
  description: PropTypes.children,
  isOpen: PropTypes.bool,
  collapse: PropTypes.bool,
  disabled: PropTypes.bool,
  isFetching: PropTypes.bool,
  onItemClick: PropTypes.func,
  onUnimpersonateClick: PropTypes.func.isRequired,
  onLogoutClick: PropTypes.func.isRequired,
  $separatorWidth: PropTypes.number.isRequired,
};

Menu.defaultProps = {
  description: null,
  isOpen: false,
  collapse: false,
  disabled: false,
  isFetching: false,
  onItemClick: null,
};

export default Menu;
