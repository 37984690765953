import styled, { css } from 'styled-components';

import Collapse from '@mui/material/Collapse';

import {
  sidebarLeftCollapsedMenuLeftOffset,
  sidebarLeftCollapsedMenuWidth,
} from '@/pages/Layout/shared/constants';

export default styled(Collapse)`
  padding: 0;
  background-color: ${({ theme }) => theme.topbarMenuBackground};
  border-bottom: ${({ $separatorWidth }) => $separatorWidth}px solid
    ${({ theme }) => theme.colorBackgroundSeparator};
  overflow: hidden;

  ${({ $collapse }) => $collapse
    && css`
      position: absolute;
      top: 0;
      left: ${sidebarLeftCollapsedMenuLeftOffset + 5}px;
      width: ${sidebarLeftCollapsedMenuWidth}px;
      margin: unset;
      z-index: 9999999;

      border-left: ${({ $separatorWidth }) => $separatorWidth}px solid;
      border-right: ${({ $separatorWidth }) => $separatorWidth}px solid;
      border-bottom: ${({ $separatorWidth }) => $separatorWidth}px solid;
      border-color: ${({ theme }) => theme.colorBackgroundSeparator};
      box-shadow: 3px 3px 6px ${({ theme }) => theme.colorBackgroundSeparator};
      border-bottom-right-radius: 4px;
    `}
`;
