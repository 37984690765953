import { matchPath } from 'react-router-dom';
import { useLocation } from 'react-use';

import { getIdpLoginUrl } from '@/shared/utils';

import useGoogleTagManager from '+hooks/useGoogleTagManager';

import FullMode from './components/FullMode';
import GuestMode from './components/GuestMode';

const AppRouter = () => {
  const { pathname } = useLocation();
  const guestMatch = matchPath('/g/*', pathname);
  const ssoRouteMatch = matchPath('/sso/:shortname', pathname);

  useGoogleTagManager();

  if (ssoRouteMatch) {
    const { shortname } = ssoRouteMatch.params;
    window.location.href = getIdpLoginUrl(shortname);
    return null;
  }

  return guestMatch ? <GuestMode /> : <FullMode />;
};

export default AppRouter;
