import PropTypes from '+prop-types';
import { Fragment, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMeasure } from 'react-use';

import { ContextTypes } from '@/models/ContextTypes';
import { DateTimeModes } from '@/models/DateTimeModes';
import { DateTimeSpans } from '@/models/DateTimeSpans';
import FieldsSeparator from '@/models/FieldsSeparator';
import { isCardMetric } from '@/models/MetricSettings';
import StatsRequest from '@/models/StatsRequest';

import {
  actions as dnsActions,
  selectors as dnsSelectors,
} from '@/redux/api/stats/dns';
import {
  actions as flowActions,
  selectors as flowSelectors,
} from '@/redux/api/stats/flow';
import { selectors as socketControlSelectors } from '@/redux/ui/socketControl';

import {
  getWidgetFields,
  makeName,
  getRequestInterval,
} from '@/pages/Dashboards/shared/requests';
import { maxTsFieldCount } from '@/pages/Dashboards/shared/widgets';

import BarChart from '+components/charts/BarChart';
import {
  getSeriesColorIndexFormatter,
  getSeriesFields,
} from '+components/charts/common/formatters';
import { lang } from '+components/charts/common/utils';
import TimeseriesChart from '+components/charts/TimeseriesChart';
import { Field, useFormState, useForm } from '+components/form/FinalForm';
import {
  normalizeMultiSelectValue,
  normalizeSelectValue,
} from '+components/form/Normalizers';
import AdditionalFiltersDropdownField, {
  AdditionalFiltersDropdownCaptureContainer,
  AdditionalFiltersDropdownCaptureLabel,
  AdditionalFiltersDropdownCaptureValue,
} from '+components/GlobalFilters/Panel/components/AdditionalFiltersDropdownField';
import AdditionalFiltersMultiDropdownField, {
  AdditionalFiltersMultiDropdownCaptureContainer,
  AdditionalFiltersMultiDropdownCaptureLabel,
  AdditionalFiltersMultiDropdownCaptureValue,
  AdditionalFiltersMultiDropdownShowMore,
} from '+components/GlobalFilters/Panel/components/AdditionalFiltersMultiDropdownField';
import AdditionalFiltersRowItem from '+components/GlobalFilters/Panel/components/AdditionalFiltersRowItem';
import AdditionalFiltersSeparator from '+components/GlobalFilters/Panel/components/AdditionalFiltersSeparator';
import GlobalFiltersPortal from '+components/GlobalFilters/Portal';
import GlobalFiltersSetting from '+components/GlobalFilters/Setting';
import { Row, Col } from '+components/Layout';
import useGlobalFilters from '+hooks/useGlobalFilters';
import useLastAllowedContext from '+hooks/useLastAllowedContext';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import useStatsRequest from '+hooks/useStatsRequest';
import { makeId } from '+utils/general';
import { timeBounds } from '+utils/timeBounds';

import TrafficMinerTable from './components/Table';

const namespace = makeId();

const fieldLabel = 'Field';
const sizeLabel = 'Series';
const intervalLabel = 'Interval';
const scaleLabel = 'Scale';

const intervalOptions = [
  { value: 'auto', label: 'auto' },
  { value: '5m', label: '5 minutes' },
  { value: '10m', label: '10 minutes' },
  { value: '30m', label: '30 minutes' },
  { value: '1h', label: '1 hour' },
  { value: '1d', label: '1 day' },
];

const scaleOptions = [
  { value: 'linear', label: 'linear' },
  { value: 'logarithmic', label: 'logarithmic' },
];

const defaultFieldValue = {
  [ContextTypes.flow]: ['dstip'],
  [ContextTypes.dns]: ['query.tld'],
};

const defaultSizeValue = {
  [ContextTypes.flow]: 10,
  [ContextTypes.dns]: 10,
};

const defaultIntervalValue = {
  [ContextTypes.flow]: intervalOptions[0].value,
  [ContextTypes.dns]: intervalOptions[0].value,
};

const defaultScaleValue = {
  [ContextTypes.flow]: scaleOptions[0].value,
  [ContextTypes.dns]: scaleOptions[0].value,
};

scaleOptions.unshift({
  value: scaleLabel,
  label: scaleLabel,
  header: true,
});

intervalOptions.unshift({
  value: intervalLabel,
  label: intervalLabel,
  header: true,
});

const excludeContexts = new Set([
  ContextTypes.alerts,
  ContextTypes.blocks,
  ContextTypes.traffic,
]);
const excludeMetrics = ['counts'];
const sumLabels = ['packets', 'bits', 'flows', 'answers', 'queries'];

const height = 300;

const GlobalFiltersFields = (props) => {
  const form = useForm();
  const { values } = useFormState({ subscription: { values: true } });

  const [{ context }] = useGlobalFilters();
  const fieldKey = `traffic_miner_field_${context}`;
  const sizeKey = `traffic_miner_size_${context}`;
  const intervalKey = `traffic_miner_interval_${context}`;
  const scaleKey = `traffic_miner_scale_${context}`;

  const {
    metric,
    [fieldKey]: field = defaultFieldValue[context],
    [sizeKey]: size = defaultSizeValue[context],
    [intervalKey]: interval = defaultIntervalValue[context],
    [scaleKey]: scale = defaultScaleValue[context],
  } = values;

  const fieldOptions = useMemo(
    () => [
      {
        value: fieldLabel,
        label: fieldLabel,
        header: true,
      },
      ...props.fieldOptions,
    ],
    [props.fieldOptions],
  );

  const sizeOptions = useMemo(
    () => {
      const sizes = [];
      for (let i = 1; i <= 50; i += 1) {
        sizes.push({ value: i, label: `top ${i}` });
      }
      sizes.unshift({
        value: 0,
        label: 'aggregate',
        disabled: isCardMetric(metric),
      });
      sizes.unshift({
        value: sizeLabel,
        label: sizeLabel,
        header: true,
      });
      return sizes;
    },
    [metric],
  );

  useEffect(
    () => {
      form.batch(() => {
        if (!field?.length) {
          form.change(sizeKey, sizeOptions[1].value);
          return;
        }

        if (field?.length && !size) {
          form.change(sizeKey, defaultSizeValue[context]);
        }
      });
    },
    [field, context],
  );

  useEffect(
    () => {
      form.batch(() => {
        if (!size) {
          form.change(fieldKey, []);
          return;
        }

        if (size && !field.length) {
          form.change(fieldKey, defaultFieldValue[context]);
        }
      });
    },
    [size, context],
  );

  useEffect(
    () => {
      if (!isCardMetric(metric)) {
        return;
      }

      form.batch(() => {
        if (field.length === 0) {
          form.change(fieldKey, defaultFieldValue[context]);
        }

        if (field.length > 1) {
          form.change(fieldKey, [field[0]]);
        }

        if (size === 0) {
          form.change(sizeKey, defaultSizeValue[context]);
        }
      });
    },
    [metric, field, size, context],
  );

  return (
    <Fragment>
      <AdditionalFiltersRowItem>
        <Field
          component={
            isCardMetric(metric)
              ? AdditionalFiltersDropdownField
              : AdditionalFiltersMultiDropdownField
          }
          limit={2}
          name={fieldKey}
          options={fieldOptions}
          // we need to keep multiSelect parser because we are expecting array in value
          parse={normalizeMultiSelectValue}
          caption={
            isCardMetric(metric) ? (
              <AdditionalFiltersDropdownCaptureContainer>
                <AdditionalFiltersDropdownCaptureLabel>
                  {fieldLabel}
                </AdditionalFiltersDropdownCaptureLabel>
                <AdditionalFiltersDropdownCaptureValue>
                  {field?.[0] || 'None'}
                </AdditionalFiltersDropdownCaptureValue>
              </AdditionalFiltersDropdownCaptureContainer>
            ) : (
              <AdditionalFiltersMultiDropdownCaptureContainer>
                <AdditionalFiltersMultiDropdownCaptureLabel>
                  {fieldLabel}
                </AdditionalFiltersMultiDropdownCaptureLabel>
                <AdditionalFiltersMultiDropdownCaptureValue>
                  {field?.[0] || 'None'}
                  {field?.length > 1 && (
                    <AdditionalFiltersMultiDropdownShowMore>
                      +{field?.length - 1} more
                    </AdditionalFiltersMultiDropdownShowMore>
                  )}
                </AdditionalFiltersMultiDropdownCaptureValue>
              </AdditionalFiltersMultiDropdownCaptureContainer>
            )
          }
          data-tracking="filter-row-field"
        />
      </AdditionalFiltersRowItem>

      <AdditionalFiltersSeparator />

      <AdditionalFiltersRowItem>
        <Field
          component={AdditionalFiltersDropdownField}
          name={sizeKey}
          options={sizeOptions}
          parse={normalizeSelectValue}
          caption={(
            <AdditionalFiltersDropdownCaptureContainer>
              <AdditionalFiltersDropdownCaptureLabel>
                {sizeLabel}
              </AdditionalFiltersDropdownCaptureLabel>
              <AdditionalFiltersDropdownCaptureValue>
                {size}
              </AdditionalFiltersDropdownCaptureValue>
            </AdditionalFiltersDropdownCaptureContainer>
          )}
          data-tracking="filter-row-series"
        />
      </AdditionalFiltersRowItem>

      <AdditionalFiltersSeparator />

      <AdditionalFiltersRowItem>
        <Field
          component={AdditionalFiltersDropdownField}
          name={intervalKey}
          options={intervalOptions}
          parse={normalizeSelectValue}
          caption={(
            <AdditionalFiltersDropdownCaptureContainer>
              <AdditionalFiltersDropdownCaptureLabel>
                {intervalLabel}
              </AdditionalFiltersDropdownCaptureLabel>
              <AdditionalFiltersDropdownCaptureValue>
                {interval}
              </AdditionalFiltersDropdownCaptureValue>
            </AdditionalFiltersDropdownCaptureContainer>
          )}
          data-tracking="filter-row-interval"
        />
      </AdditionalFiltersRowItem>

      <AdditionalFiltersSeparator />

      <AdditionalFiltersRowItem>
        <Field
          component={AdditionalFiltersDropdownField}
          name={scaleKey}
          options={scaleOptions}
          parse={normalizeSelectValue}
          caption={(
            <AdditionalFiltersDropdownCaptureContainer>
              <AdditionalFiltersDropdownCaptureLabel>
                {scaleLabel}
              </AdditionalFiltersDropdownCaptureLabel>
              <AdditionalFiltersDropdownCaptureValue>
                {scale}
              </AdditionalFiltersDropdownCaptureValue>
            </AdditionalFiltersDropdownCaptureContainer>
          )}
          data-tracking="filter-row-scale"
        />
      </AdditionalFiltersRowItem>

      <AdditionalFiltersSeparator />
    </Fragment>
  );
};

GlobalFiltersFields.propTypes = {
  fieldOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

const TrafficMiner = () => {
  const dispatch = useDispatch();

  const [lineChartMeasureRef, { width: lineChartWidth }] = useMeasure();
  const [topChartMeasureRef, { width: topChartWidth }] = useMeasure();

  const availableFields = {
    [ContextTypes.flow]: useSelector(flowSelectors.getFields),
    [ContextTypes.dns]: useSelector(dnsSelectors.getFields),
  };

  const isSocketPaused = useSelector(socketControlSelectors.isPaused);

  const context = useLastAllowedContext({
    excludeContexts,
    defaultContext: ContextTypes.flow,
  });

  const [{ context: selectedContext }] = useGlobalFilters();
  const [filters] = useGlobalFilters(context);

  const fieldKey = `traffic_miner_field_${context}`;
  const sizeKey = `traffic_miner_size_${context}`;
  const intervalKey = `traffic_miner_interval_${context}`;
  const scaleKey = `traffic_miner_scale_${context}`;

  const {
    [fieldKey]: fields = defaultFieldValue[context],
    [sizeKey]: size = defaultSizeValue[context],
    [intervalKey]: interval = defaultIntervalValue[context],
    [scaleKey]: scale = defaultScaleValue[context],
  } = filters;
  const { start, end } = timeBounds({ ...filters, realtime: !isSocketPaused });

  const portalInitialValues = useMemo(
    () => ({
      [fieldKey]: fields,
      [sizeKey]: size,
      [intervalKey]: interval,
      [scaleKey]: scale,
    }),
    [fields, scale, size, interval],
  );

  const isFiltered = useMemo(
    () => !(
      size === defaultSizeValue[context]
        && interval === defaultIntervalValue[context]
        && scale === defaultScaleValue[context]
        && fields
        && [...fields].join('__') === defaultFieldValue[context]?.join('__')
    ),
    [context, fields, scale, size, interval],
  );

  const tsChartRequest = useMemo(
    () => {
      const intervalLocal = getRequestInterval(start, end, interval);
      const request = {
        seriesId: namespace,
        params: {
          start,
          end,
          series: [
            {
              context,
              metric: filters.metric,
              ...(size && {
                size,
                fields_separator: FieldsSeparator,
                field: fields,
              }),
              ...StatsRequest.makeSearch({
                search: filters.nql,
                intersect: filters.intersect,
              }),
              summary: true,
              percentiles: true,
              ...(intervalLocal && { interval: intervalLocal }),
            },
          ],
          customers: filters.customers,
        },
      };
      if (request.params.series[0].field) {
        request.params.series[0].field = getWidgetFields({
          labelContext: filters.labelContext,
          context: request.params.series[0].context,
          metric: request.params.series[0].metric,
          fields: request.params.series[0].field,
          maxFieldCount: maxTsFieldCount,
        });
      }
      request.params.series[0].name = makeName(
        request.params.series[0].metric,
        request.params.series[0].field,
      );
      return request;
    },
    [
      namespace,
      start,
      end,
      size,
      interval,
      fields,
      filters.metric,
      filters.nql,
      filters.intersect,
      filters.labelContext,
      filters.customers,
    ],
  );

  const { series: tsChartSeries, isFetching: isTsStatsFetching } = useStatsRequest({
    context,
    requestType: StatsRequest.Types.ts,
    request: tsChartRequest,
    stopRequest:
        excludeMetrics.includes(filters.metric)
        || (isCardMetric(filters.metric) && (fields.length !== 1 || size === 0)),
  });

  useLoadingIndicator(isTsStatsFetching);

  const aggChartSeries = useMemo(
    () => [
      {
        data: (tsChartSeries || []).map((item) => [item.label, item.avg]),
        series: tsChartSeries?.[0]?.series,
        interval: tsChartSeries?.[0]?.interval,
        metric: tsChartSeries?.[0]?.metric,
      },
    ],
    [tsChartSeries],
  );

  const tableFields = useMemo(
    () => (size ? getSeriesFields(tsChartSeries?.[0]) || fields : ['name']),
    [tsChartSeries?.[0], fields, size],
  );

  const tableData = useMemo(
    () => (!size
      ? tsChartSeries || []
      : (tsChartSeries || []).map((item) => ({
        ...item,
        ...tableFields.reduce((acc, fieldName, i) => {
          let fieldValue = item.name.split(FieldsSeparator)[i];
          if (isCardMetric(filters.metric)) {
            [fieldValue] = fieldValue.split('-');
          }
          if (fieldName.startsWith('label.')) {
            fieldValue = fieldValue ? fieldValue.split(', ') : [];
          }
          return {
            ...acc,
            [fieldName]: fieldValue,
          };
        }, {}),
      }))),
    [tsChartSeries, filters.metric, tableFields],
  );

  const fieldOptions = useMemo(
    () => {
      const tempFields = availableFields[context];
      return tempFields
        ? tempFields.map((item) => ({
          value: item.field,
          label: item.field,
          description: item.description,
        }))
        : [];
    },
    [availableFields[context], context],
  );

  const seriesColorIndexFormatter = useMemo(
    () => getSeriesColorIndexFormatter({
      metric: filters.metric,
      fields,
    }),
    [JSON.stringify(fields), filters.metric],
  );

  useEffect(
    () => {
      switch (context) {
        case ContextTypes.dns:
          if (availableFields[ContextTypes.dns].length) {
            return;
          }
          dispatch(dnsActions.fetchFields());
          break;
        default:
          if (availableFields[ContextTypes.flow].length) {
            return;
          }
          dispatch(flowActions.fetchFields());
          break;
      }
    },
    [availableFields],
  );

  const prefixTitle = portalInitialValues[sizeKey]
    ? `top ${portalInitialValues[sizeKey]}`
    : 'aggregate';

  const title = portalInitialValues[fieldKey]
    && `${prefixTitle} ${portalInitialValues[fieldKey].join(', ')} by ${
      filters.metric
    } ${sumLabels.includes(filters.metric) ? '(sum)' : '(avg)'}`;

  const isAllowedContext = !excludeContexts.has(selectedContext);

  return (
    <Fragment>
      <GlobalFiltersSetting
        metric={isAllowedContext}
        nql
        context={context}
        dateTimeMode={DateTimeModes.now}
        excludeMetrics={excludeMetrics}
        dateTimeSpan={DateTimeSpans.second}
        customers
      />

      {isAllowedContext && (
        <GlobalFiltersPortal
          initialValues={portalInitialValues}
          isFiltered={isFiltered}
        >
          <GlobalFiltersFields fieldOptions={fieldOptions} />
        </GlobalFiltersPortal>
      )}

      <Row columnSpacing={1} style={{ height, overflow: 'hidden' }}>
        <Col
          ref={lineChartMeasureRef}
          xs={8}
          item
          container={false}
        >
          <TimeseriesChart
            title={title}
            context={context}
            fields={fields}
            series={tsChartSeries}
            scale={scale}
            width={lineChartWidth}
            height={height}
            seriesColorIndexFormatter={seriesColorIndexFormatter}
            loading={!tsChartSeries}
            stacked="normal"
          />
        </Col>

        <Col
          ref={topChartMeasureRef}
          xs={4}
          item
          container={false}
        >
          <BarChart
            context={context}
            title={title}
            fields={fields}
            series={aggChartSeries?.[0]}
            metric={filters.metric}
            width={topChartWidth}
            height={height}
            seriesColorIndexFormatter={seriesColorIndexFormatter}
            loading={!aggChartSeries}
            type="column"
          />
        </Col>
      </Row>
      <TrafficMinerTable
        fields={tableFields}
        series={tableData}
        noDataText={tableData ? undefined : lang.loading}
        context={context}
      />
    </Fragment>
  );
};

export default TrafficMiner;
