// Use the fullscreen element for container if in fullscreen mode, otherwise just the document's body
// @see: https://github.com/mui/material-ui/issues/15618
// @see: https://netography.atlassian.net/browse/PORTAL-1590
const getBodyContainer = () => {
  // We need to skip fullscreenElement if it's equal to document.documentElement (whole HTML tag)
  // otherwise we will loos styles in modal components
  // Test case:
  // 1. Go to `Manage Dashboards` page.
  // 2. Turn on Fullscreen by button on Topbar.
  // 3. Open Edit Dashboard modal.
  // 4. Without this check you will see that styles are loos.
  if (document.fullscreenElement && document.fullscreenElement !== document.documentElement) {
    return document.fullscreenElement;
  }
  return document.body;
};

export default getBodyContainer;
