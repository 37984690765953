import styled from 'styled-components';

import { ScrollBarMixin } from '+theme/mixins/scrollBarMixin';

export default styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.toastMessageColor};
  max-width: 278px;
  max-height: 85px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  ${ScrollBarMixin};
`;
