import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: column;

  > .fields-section {
    margin-left: 140px;
    width: 720px;
  }
`;
