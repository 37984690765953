import PropTypes from '+prop-types';

import { FlagProvider, useFlagsStatus } from '@unleash/proxy-client-react';

import { config } from '@/config';
import authClient from '@/middleware/authClient';

import featureOverridesCookie from '+utils/featureOverridesCookie';
import featureSessionCookie from '+utils/featureSessionCookie';
import loader from '+utils/loader';

const propTypes = {
  children: PropTypes.children.isRequired,
};

const Wrapper = ({ children }) => {
  const { flagsReady, flagsError } = useFlagsStatus();

  if (!flagsReady && !flagsError) {
    loader.setMessage('Loading features... 80%').show();
    return null;
  }

  if (flagsError) {
    // eslint-disable-next-line no-console
    console.error('Error fetching flags', flagsError);
  }

  return children;
};
Wrapper.propTypes = propTypes;

const prodEnvs = new Set(['production', 'staging']);

const unleashConfig = {
  url: `${config.appBackendUrlRoot}/features`,
  refreshInterval: 60,
  clientKey: 'not-use',
  appName: 'portal-frontend',
  environment: prodEnvs.has(config.environment) ? 'production' : 'development',
  secure: {
    toString: () => {
      const token = authClient.getToken();
      return token ? 'true' : 'false';
    },
  },
  customHeaders: {
    'X-Neto-Feature-Session': featureSessionCookie(),
    'X-Neto-Feature-Override': {
      toString() {
        return `${featureOverridesCookie}`;
      },
    },
    Authorization: {
      toString: () => {
        const token = authClient.getToken();
        return token ? `Bearer ${token}` : undefined;
      },
    },
    GuestCode: {
      toString: () => {
        const { code } = authClient.getGuest();
        return code || undefined;
      },
    },
  },
};

const FeaturesProvider = ({ children }) => (
  <FlagProvider config={unleashConfig}>
    <Wrapper>{children}</Wrapper>
  </FlagProvider>
);
FeaturesProvider.propTypes = propTypes;

export default FeaturesProvider;
