import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
  background-color: ${({ theme }) => theme.colorFieldBackground};
  border-radius: 4px;

  .form__form-group {
    margin-bottom: unset !important;
  }

  .form__form-group-label {
    max-height: 24px;
  }
`;
