import { ContextIntegrations } from '@/models/integrations/ContextIntegrations';

import AwsFields from './fields/context/Aws';
import AzureFields from './fields/context/Azure';
import GetCrowdstrikeFields from './fields/context/Crowdstrike';
import GcpFields from './fields/context/Gcp';
import IbmFields from './fields/context/Ibm';
import OracleFields from './fields/context/Oracle';
import S3Fields from './fields/context/S3';
import SentinelOneFields from './fields/context/SentinelOne';
import WizFields from './fields/context/Wiz';

/**
 * Defines components for context integrations.
 */
export const ContextIntegrationModels = {
  [ContextIntegrations.azure]: {
    component: AzureFields,
  },
  [ContextIntegrations.aws]: {
    component: AwsFields,
  },
  [ContextIntegrations.crowdstrike]: {
    component: GetCrowdstrikeFields('crowdstrike'),
  },
  [ContextIntegrations.crowdstrikediscover]: {
    component: GetCrowdstrikeFields('crowdstrikediscover'),
  },
  [ContextIntegrations.gcp]: {
    component: GcpFields,
  },
  [ContextIntegrations.ibm]: {
    component: IbmFields,
  },
  [ContextIntegrations.oracle]: {
    component: OracleFields,
  },
  [ContextIntegrations.sentinelone]: {
    component: SentinelOneFields,
  },
  [ContextIntegrations.s3]: {
    component: S3Fields,
  },
  [ContextIntegrations.wiz]: {
    component: WizFields,
  },
};

/**
 * Assign all props of the context integration to the model.
 */
Object.entries(ContextIntegrationModels).forEach(([key, value]) => {
  Object.assign(value, ContextIntegrations[key]);
});
