/* eslint-disable react/no-multi-comp */
import PropTypes from '+prop-types';
import { Fragment, Component as ReactComponet, forwardRef } from 'react';

import styled from 'styled-components';

import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon';

import Button from '+components/Button';

const Centered = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ErrorCallToActionContainer = styled(Centered)`
  padding: 20px;
  height: 160px;
  justify-content: space-around;

  .error-text {
    font-size: 18px;
  }
`;

/**
 * On exceptions will mount a try again button to attempt to remount the crashed
 * component.
 */
export class TryAgainBoundary extends ReactComponet {
  static propTypes = {
    useFragment: PropTypes.bool,
    children: PropTypes.children.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    resetKey: PropTypes.any,
  };

  static defaultProps = {
    /** Use a fragment for the error boundary container instead of a div */
    useFragment: false,
    resetKey: undefined,
  };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    // TODO: error reporting?
    // eslint-disable-next-line no-console
    console.warn('Error Boundary triggered on:', error);
    return { errored: true };
  }

  static getDerivedStateFromProps(props, state) {
    if (state.prevResetKey !== props.resetKey) {
      return {
        errored: false,
        prevResetKey: props.resetKey,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      errored: false,
      // eslint-disable-next-line react/no-unused-state
      prevResetKey: undefined,
    };
  }

  _tryAgain = () => {
    this.setState({ errored: false });
  };

  render() {
    const { children, useFragment, ...rest } = this.props;

    if (this.state.errored) {
      const contents = (
        <ErrorCallToActionContainer>
          <span className="error-text">Something went wrong</span>
          <Button
            startIcon={<ArrowLeftIcon size={16} />}
            onClick={this._tryAgain}
          >
            Go Back
          </Button>
        </ErrorCallToActionContainer>
      );

      return this.props.useFragment ? (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <Fragment>{contents}</Fragment>
      ) : (
        <Centered {...rest}>{contents}</Centered>
      );
    }
    return this.props.children;
  }
}

export const withTryAgainBoundary = (Component, tryAgainProps = {}) => {
  return forwardRef((props, ref) => (
    <TryAgainBoundary ref={ref} {...tryAgainProps}>
      <Component {...props} />
    </TryAgainBoundary>
  ));
};
