export default {
  dstas: {
    name: 'Destination ASNs',
    style: 'info',
  },
  dstport: {
    name: 'Destination Port',
    style: 'info',
  },
  dstnets: {
    name: 'Destination CIDRs',
    style: 'info',
  },
  dstowneras: {
    name: 'Destination IP ASNs',
    style: 'info',
  },
  inputaliasregex: {
    name: 'Input Alias',
    style: 'light',
  },
  inputnameregex: {
    name: 'Input Name',
    style: 'light',
  },
  outputaliasregex: {
    name: 'Output Alias',
    style: 'light',
  },
  outputnameregex: {
    name: 'Output Name',
    style: 'light',
  },
  protocol: {
    name: 'Protocol',
    style: 'warning',
  },
  srcas: {
    name: 'Source ASNs',
    style: 'primary',
  },
  srcnets: {
    name: 'Source CIDRs',
    style: 'primary',
  },
  srcowneras: {
    name: 'Source IP ASNs',
    style: 'primary',
  },
  srcport: {
    name: 'Source Port',
    style: 'primary',
  },
  tcpflagsint: {
    name: 'TCP Flags',
    style: 'warning',
  },
};
