import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useToggle } from 'react-use';

import RoutePaths from '@/models/RoutePaths';

import {
  actions as responseIntegrationsActions,
  selectors as responseIntegrationsSelectors,
} from '@/redux/api/integrations/response';

import { Breadcrumb } from '+components/Breadcrumb';
import ConfirmModal from '+components/ConfirmModal';
import EditPageAuditLogTabs from '+components/EditPageAuditLogTabs';
import { PluginLabel } from '+components/Labels/PluginLabel';
import usePageTabs from '+hooks/usePageTabs';
import useUIProperty from '+hooks/useUIProperty';

import NoData from '../shared/NoData';
import { ResponseIntegrationModels } from '../shared/ResponseIntegrationModels';
import { paramsToUi, uiToParams } from './components/utils';
import Form from './Form';

const LabeledBreadCrumb = (label) => (
  <Breadcrumb title={`Edit Response Integration ${label}`} />
);

const rootPath = `${RoutePaths.integrationsResponse}`;

const Edit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [, activePageTab] = usePageTabs();

  const { id } = useParams();
  const integration = useSelector(
    responseIntegrationsSelectors.getPluginById(id),
  );
  const { isFetching, error } = useSelector(
    responseIntegrationsSelectors.getState,
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showDelete, toggleShowDelete] = useToggle(false);

  const model = useMemo(
    () => ResponseIntegrationModels[integration?.adapter],
    [integration?.adapter],
  );

  const initialValues = useMemo(
    () => paramsToUi(integration?.adapter, integration),
    [integration],
  );

  const onSubmit = useCallback(
    (values) => {
      setIsSubmitting(activePageTab?.id);
      const data = uiToParams(integration?.adapter, values);
      dispatch(
        responseIntegrationsActions.updatePlugin(
          { ...data, id },
          'ndr_integrations_update_plugin',
        ),
      );
    },
    [integration?.adapter, id, activePageTab?.id],
  );

  const onCancel = useCallback(
    () => {
      navigate(`${RoutePaths.integrationsResponse}`);
    },
    [],
  );

  const onDeleteConfirm = useCallback(
    () => {
      dispatch(
        responseIntegrationsActions.deletePlugin(
          integration?.id,
          'ndr_integrations_delete_plugin',
        ),
      );
      onCancel();
    },
    [integration?.id, onCancel],
  );

  useEffect(
    () => {
      if (!isSubmitting || isFetching) {
        return;
      }

      if (isSubmitting !== activePageTab?.id) {
        return;
      }

      if (!error) {
        onCancel();
      } else {
        setIsSubmitting(false);
      }
    },
    [isSubmitting, isFetching, error, activePageTab?.id, onCancel],
  );

  useEffect(
    () => {
      if (integration || !id) {
        return;
      }
      dispatch(responseIntegrationsActions.fetchPlugin(id));
    },
    [integration, id],
  );

  const [, setMasqueradeUrl] = useUIProperty('masqueradeUrl');
  useEffect(
    () => {
      setMasqueradeUrl(rootPath);
      return () => {
        setMasqueradeUrl(null);
      };
    },
    [rootPath],
  );

  return !model ? (
    <NoData>No Plugin Found</NoData>
  ) : (
    <EditPageAuditLogTabs
      auditNqlQuery={`class == integration && original_id == ${integration.id}`}
      breadcrumb={LabeledBreadCrumb}
    >
      <Form
        mode="edit"
        model={model}
        initialValues={initialValues}
        isFetching={isFetching}
        confirmButtonText="Update"
        deleteButtonText="Delete Integration"
        deleteButtonHidden={!integration?.id}
        onDelete={() => toggleShowDelete(true)}
        onSubmit={onSubmit}
        onCancel={onCancel}
        errorMessage={
          integration?.config?.check_error
            ? `Error: ${integration?.config?.check_error}`
            : null
        }
      />

      {showDelete && (
        <ConfirmModal
          item={(
            <PluginLabel
              style={{ marginLeft: '5px' }}
              adapter={integration?.adapter}
              title={model.title}
              size={14}
            />
          )}
          onConfirm={onDeleteConfirm}
          toggleOnConfirm={false}
          isDisabled={isFetching}
          cancelButtonDisabled={isFetching}
          onToggle={toggleShowDelete}
          isOpen
        />
      )}
    </EditPageAuditLogTabs>
  );
};

export default Edit;
