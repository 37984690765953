import PropTypes from '+prop-types';
import { Fragment, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useToggle } from 'react-use';

import { actions as actionsVpcs } from '@/redux/api/vpc';

import Button from '+components/Button';

import RegenerateKeyModal from './RegenerateKeyModal';

const RegenerateKeyButton = ({ vpc }) => {
  const dispatch = useDispatch();

  const [showModal, toggleModal] = useToggle(false);

  const onModalConfirm = useCallback(
    () => {
      dispatch(
        actionsVpcs.regenerateKeys({
          id: vpc.id,
          flowtype: vpc.flowtype,
          flowresource: vpc.flowresource,
        }),
      );
    },
    [vpc.id, vpc.flowtype, vpc.flowresource],
  );

  const onClick = useCallback(
    (event) => {
      event.preventDefault();
      toggleModal(true);
    },
    [vpc.id, vpc.flowtype, vpc.flowresource],
  );

  return (
    <Fragment>
      <Button onClick={onClick}>Regenerate Key</Button>
      <RegenerateKeyModal
        isOpen={showModal}
        onConfirm={onModalConfirm}
        onToggle={toggleModal}
        toggleOnConfirm
      />
    </Fragment>
  );
};

RegenerateKeyButton.propTypes = {
  vpc: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    flowtype: PropTypes.string.isRequired,
    flowresource: PropTypes.string.isRequired,
  }).isRequired,
};

export default RegenerateKeyButton;
