/* eslint-disable react/no-array-index-key */
import PropTypes from '+prop-types';
import { useMemo } from 'react';

import { ContextTypes } from '@/models/ContextTypes';

import { LayoutSizes, Row, Col, LayoutTypes } from '+components/Layout';

import ThresholdChart, { ThresholdFuncs } from './ThresholdChart';
import useThresholdsParse from './useThresholdsParse';

const size = 4;
const fakeColStyle = {
  background: 'transparent',
};

const disallowedFns = [ThresholdFuncs.divide, ThresholdFuncs.multiply];

const getColorIndex = (index) => (index >= 6 ? index + 1 : index);

const ThresholdCharts = (props) => {
  const { event } = props;

  const context = event.traffic_type || ContextTypes.flow;

  const thresholdContext = context === ContextTypes.dns
    ? ContextTypes.thresholdDns
    : ContextTypes.thresholdFlow;

  const thresholds = useThresholdsParse(
    event?.threshold,
    thresholdContext,
    event?.id,
  );

  const uniqueThresholds = useMemo(
    () => Object.values(
      (thresholds || []).reduce((acc, threshold) => {
        const { Expr } = threshold;

        if (!acc[Expr]) {
          acc[Expr] = threshold;
        }

        return acc;
      }, {}),
    ).filter((threshold) => !disallowedFns.includes(threshold.Func)),
    [thresholds],
  );

  const groups = useMemo(
    () => uniqueThresholds.reduce((acc, item, index) => {
      if (index >= acc.length * size) {
        acc.push([]);
      }

      acc.at(-1).push(item);

      return acc;
    }, []),
    [uniqueThresholds],
  );

  return !uniqueThresholds?.length || !event.start
    ? null
    : groups.map((group, index) => (
      <Row key={index} gap={LayoutSizes.groupGap}>
        {group.map((threshold, cIndex) => (
          <ThresholdChart
            key={threshold.Expr}
            event={event}
            threshold={threshold}
            colorIndex={getColorIndex(index * size + cIndex)}
          />
        ))}
        {!!index
            && Array.from({ length: size - group.length }).map((_, cIndex) => (
              <Col key={cIndex} $type={LayoutTypes.card} style={fakeColStyle} />
            ))}
      </Row>
    ));
};

ThresholdCharts.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.string,
    traffic_type: PropTypes.string,
    threshold: PropTypes.string,
    search: PropTypes.arrayOf(PropTypes.string),
    start: PropTypes.number,
    end: PropTypes.number,
  }),
};
ThresholdCharts.defaultProps = {
  event: null,
};

export default ThresholdCharts;
