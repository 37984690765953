import PropTypes from '+prop-types';
import { Fragment } from 'react';

import FileDocumentAlertIcon from 'mdi-react/FileDocumentAlertIcon';

import { MenuColumnButton } from '+components/Table/Columns';

const VpcDetailsContextMenu = (props) => {
  const { onDetails, item } = props;

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <Fragment>
      {item?.error?.length > 0 && (
        <MenuColumnButton
          title="Error Message Details"
          onClick={() => {
            onDetails(item);
          }}
        >
          <FileDocumentAlertIcon size={16} />
        </MenuColumnButton>
      )}
    </Fragment>
  );
};

VpcDetailsContextMenu.propTypes = {
  onDetails: PropTypes.func.isRequired,
  item: PropTypes.shape().isRequired,
};

VpcDetailsContextMenu.defaultProps = {};

export default VpcDetailsContextMenu;
