import PropTypes from '+prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useDebounce } from 'react-use';

import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px 5px;
`;

const Input = styled.input`
  width: 0;
  flex: 1;
  border-radius: 3px;
`;

const Filter = ({ onChange }) => {
  const [filter, setFilter] = useState('');
  const [search, setSearch] = useState('');

  const onChangeFilter = useCallback(
    (event) => {
      setFilter(event?.target?.value);
    },
    [],
  );

  useDebounce(
    () => setSearch(filter),
    300,
    [filter],
  );

  useEffect(
    () => onChange(search),
    [search],
  );

  return (
    <Container>
      <Input
        value={ filter }
        onChange={ onChangeFilter }
      />
    </Container>
  );
};

Filter.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default Filter;
