import PropTypes from '+prop-types';
import { forwardRef, useRef, useEffect, useState, useCallback } from 'react';
import { useToggle } from 'react-use';

import produce from 'immer';
import styled from 'styled-components';

import Portal from '+components/Portal';

import { useMenuState, useMenuActions } from '../context';

const MenuItemsWrapper = styled.div`
  margin-bottom: -1px;
  padding: 5px 0;
  background: ${({ theme }) => theme.contextMenuBackground};
  color: ${({ theme }) => theme.contextMenuColor};
`;

const PanelWrapper = styled.div`
  background: ${({ theme }) => theme.colorBackgroundBodySecondary || 'white'};
  height: inherit;
`;

const ClickBlocker = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9998;
`;

const ThemedMenu = styled.div.attrs(({ theme: { className } }) => ({
  className,
}))`
  z-index: 9999;
  top: 0;
  left: ${({ $left }) => $left};
  position: ${({ $inside }) => ($inside ? 'absolute' : 'fixed')};
  display: flex;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.contextMenuBorderColor};
  border: ${({ $border }) => ($border ? null : 0)};
  border-radius: ${({ $borderRadius }) => ($borderRadius ? '4px' : null)};
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
`;

export const Menu = forwardRef(
  (
    {
      children,
      className = undefined,
      panelContent = undefined,
      // eslint-disable-next-line no-shadow
      animated = false,
      hideOnClickOutside = true,
      border,
      borderRadius,
      avoidMouseDownAction,
      avoidScrollAction,
      onOpen,
      onClose,
      cssLeft,
    },
    ref,
  ) => {
    const state = useMenuState();
    const actions = useMenuActions();
    const [menuEl, setMenuEl] = useState(null);
    const isOpened = useRef(false);

    const { props: { inside, onClose: inStateOnClose, onOpen: inStateOnOpen } = {} } = state;

    useEffect(
      () => {
        if (!state.show) {
          return undefined;
        }

        const handleResize = (event) => {
          if (event.isTrusted) {
            actions.hideMenu();
          }
        };

        const handleKeyDown = (event) => {
          if (event.key === 'Escape') {
            actions.hideMenu();
          }
        };

        window.addEventListener('resize', handleResize, true);
        document.addEventListener('contextmenu', actions.hideMenu, true);
        if (!avoidMouseDownAction) {
          document.addEventListener('mousedown', actions.hideMenu, true);
        }
        if (!avoidScrollAction) {
          document.addEventListener('scroll', actions.hideMenu, true);
        }
        document.addEventListener('keydown', handleKeyDown, true);

        return () => {
          window.removeEventListener('resize', handleResize, true);
          document.removeEventListener('contextmenu', actions.hideMenu, true);
          document.removeEventListener('mousedown', actions.hideMenu, true);
          document.removeEventListener('scroll', actions.hideMenu, true);
          document.removeEventListener('keydown', handleKeyDown, true);
        };
      },
      [state.show, avoidMouseDownAction, avoidScrollAction],
    );

    // Position context menu so it doesn't flow out of screen
    useEffect(
      () => {
        if (!state.show || !menuEl) {
          return;
        }

        const { innerWidth: windowWidth, innerHeight: windowHeight } = window;
        const { offsetWidth: menuWidth, offsetHeight: menuHeight } = menuEl;

        actions.updateMenu(
          produce((draft) => {
            const { direction } = draft.props;

            if (direction === 'left') {
              draft.left -= menuWidth;
              if (draft.left < 6) {
                draft.left = 6;
              }

              if (
                draft.top + menuHeight > windowHeight
              && draft.top > menuHeight
              ) {
                draft.top -= menuHeight;
              }
              return;
            }

            if (draft.left + menuWidth > windowWidth && draft.left > menuWidth) {
              draft.left -= menuWidth;
            }

            if (draft.top + menuHeight > windowHeight && draft.top > menuHeight) {
              draft.top -= menuHeight;
            }
          }),
        );
      },
      [state.show, menuEl],
    );

    const onMouseEnter = useCallback(
      () => {
        document.removeEventListener('mousedown', actions.hideMenu, true);
        document.removeEventListener('contextmenu', actions.hideMenu, true);
      },
      [],
    );

    const onMouseLeave = useCallback(
      () => {
        if (!state.show) {
          return;
        }

        if (!avoidMouseDownAction) {
          document.addEventListener('mousedown', actions.hideMenu, true);
        }
        document.addEventListener('contextmenu', actions.hideMenu, true);
      },
      [state.show, avoidMouseDownAction],
    );

    const menuItems = typeof children === 'function' ? children(state) : children;

    const [opacity, setOpacity] = useState(+!animated);
    const timer = useRef(null);

    useEffect(
      () => {
        if (state.show && !isOpened.current) {
          isOpened.current = true;
          onOpen?.(state);
          inStateOnOpen?.(state);
          return undefined;
        }

        if (!state.show && isOpened.current) {
          isOpened.current = false;
          onClose?.();
          inStateOnClose?.();

          timer.current = setTimeout(() => setOpacity(0), 200);

          return () => {
            clearTimeout(timer.current);
          };
        }

        return undefined;
      },
      [state.show, onOpen, onClose, inStateOnClose, inStateOnOpen],
    );

    const [shown, toggleShown] = useToggle(state.show);
    useEffect(
      () => {
        if (state.show) {
          toggleShown(true);

          timer.current = setTimeout(() => setOpacity(1), 10);

          return () => {
            clearTimeout(timer.current);
          };
        }

        const handle = () => {
          toggleShown(false);
        };

        if (!animated) {
          handle();
          return undefined;
        }

        menuEl?.addEventListener('transitionend', handle);

        return () => {
          menuEl?.removeEventListener('transitionend', handle);
        };
      },
      [state.show, menuEl, animated],
    );

    const menu = shown ? (
      <ThemedMenu
        $inside={inside}
        ref={setMenuEl}
        $border={border}
        $borderRadius={borderRadius}
        style={{
          transform: `translate3d(${state.left}px,${state.top}px,0)`,
          opacity,
          pointerEvents: opacity ? null : 'none',
        }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        $left={cssLeft}
        data-testid="menu"
      >
        <MenuItemsWrapper className={className} ref={ref}>
          {menuItems}
        </MenuItemsWrapper>
        {panelContent ? <PanelWrapper>{panelContent}</PanelWrapper> : null}
      </ThemedMenu>
    ) : null;

    if (!menu) {
      return null;
    }

    return inside ? (
      menu
    ) : (
      <Portal>
        {hideOnClickOutside && state.show && (
          <ClickBlocker onClick={actions.hideMenu} />
        )}
        {menu}
      </Portal>
    );
  },
);

Menu.displayName = 'Menu';

Menu.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.node, PropTypes.func])),
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
  className: PropTypes.string,
  panelContent: PropTypes.shape({}),
  animated: PropTypes.bool,
  hideOnClickOutside: PropTypes.bool,
  border: PropTypes.bool,
  borderRadius: PropTypes.bool,
  avoidMouseDownAction: PropTypes.bool,
  avoidScrollAction: PropTypes.bool,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  cssLeft: PropTypes.string,
};

Menu.defaultProps = {
  className: null,
  panelContent: null,
  animated: false,
  hideOnClickOutside: true,
  border: true,
  borderRadius: true,
  avoidMouseDownAction: false,
  avoidScrollAction: false,
  onClose: null,
  onOpen: null,
  cssLeft: '0',
};
