import styled, { css } from 'styled-components';

export default styled.div`
  flex-shrink: 0;
  width: ${({ $width }) => $width}px;
  height: 80%;
  background-image: url(${({ theme }) => theme.logoSvgUrl});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: width 0.3s ease-in-out 0.1s,
    background-position-x 0.1s ease-out 0.3s;
  ${({ $collapsed }) => $collapsed
    && css`
      background-position-x: -23px;
      transition: width 0.3s ease-in-out 0.1s, background-position-x 0.3s;
    `}
`;
