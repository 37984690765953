export const SettingCategories = {
  system: 'system',
  ui: 'ui',
  dashboard: 'dashboard',
  recent: 'recent',
  socket: 'socket',
  tables: 'tables',
};

export default SettingCategories;
