import { css } from 'styled-components';

import text from './text';

export default ({
  textColor,
  background,
  borderColor,
  textColorDisabled,
  backgroundDisabled,
  borderColorDisabled,
}) => css`
  ${text({
    textColor,
    background,
    borderColor,
    textColorDisabled,
    backgroundDisabled,
    borderColorDisabled,
  })};
  padding: unset;
  line-height: unset;
  text-transform: unset;
  text-decoration: underline;
  border: unset;
`;
