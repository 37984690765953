/**
 * @enum {string}
 */
export const ColorTypes = {
  primary: 'primary',
  secondary: 'secondary',
  danger: 'danger',
  error: 'error',
  success: 'success',
  info: 'info',
  warning: 'warning',
};

export default ColorTypes;
