import PropTypes from '+prop-types';
import { useMemo } from 'react';

import CellWrapper from './CellWrapper';

// Generic Context Menu for IP Address Names
const CellIpLabel = (props) => {
  const {
    field,
    value,
    ipFieldName,
    ipFieldValue,
    original,
    useDataValueInPropertiesTray,
    disabled,
  } = props;

  const propertiesTrayData = useMemo(
    () => {
      if (!useDataValueInPropertiesTray) {
        return {
          customer: original?.customer,
          field,
          value,
        };
      }
      const stream = {
        srcip: original?.srcip,
        srcport: original?.srcport,
        dstip: original?.dstip,
        dstport: original?.dstport,
        protocol: original?.protocol,
      };
      return {
        customer: original?.customer,
        field: ipFieldName,
        value: ipFieldValue,
        stream,
      };
    },
    [
      useDataValueInPropertiesTray,
      field,
      value,
      ipFieldName,
      ipFieldValue,
      original,
    ],
  );

  return (
    <CellWrapper {...propertiesTrayData} disabled={disabled} hideUnderline>
      <span>{value}</span>
    </CellWrapper>
  );
};

CellIpLabel.propTypes = {
  field: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  ipFieldName: PropTypes.string,
  ipFieldValue: PropTypes.string,
  original: PropTypes.shape(),
  useDataValueInPropertiesTray: PropTypes.bool,
  disabled: PropTypes.bool,
};

CellIpLabel.defaultProps = {
  ipFieldName: undefined,
  ipFieldValue: undefined,
  original: undefined,
  useDataValueInPropertiesTray: false,
  disabled: false,
};

export default CellIpLabel;
