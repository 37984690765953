import { useCallback, useEffect, useRef, useState } from 'react';

export const useSessionStorage = (key, initialDefaultValue) => {
  const [state, setState] = useState(() => {
    try {
      const value = sessionStorage.getItem(key);
      return value ? JSON.parse(value) : initialDefaultValue;
    } catch (e) {
      return initialDefaultValue;
    }
  });
  const isChanger = useRef(false);
  const eventName = `sessionStorage_${key}`;

  const sendEvent = useCallback(
    (value) => {
      isChanger.current = true;
      document.dispatchEvent(new CustomEvent(eventName, { detail: value }));
    },
    [eventName],
  );

  const remove = useCallback(
    () => {
      try {
        sessionStorage.removeItem(key);
        setState(null);
        sendEvent(null);
      } catch (e) {
        // do nothing
      }
    },
    [],
  );

  const change = useCallback(
    (value) => {
      if (value == null) {
        remove();
        return;
      }

      try {
        sessionStorage.setItem(key, JSON.stringify(value));
        setState(value);
        sendEvent(value);
      } catch (e) {
        // do nothing
      }
    },
    [],
  );

  useEffect(
    () => {
      const handler = (event) => {
        if (isChanger.current) {
          isChanger.current = false;
          return;
        }

        setState(event.detail);
      };
      document.addEventListener(eventName, handler);

      return () => {
        isChanger.current = false;
        document.removeEventListener(eventName, handler);
      };
    },
    [eventName],
  );

  return [state, change, remove];
};

export default useSessionStorage;
