import { Fragment, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ContextTypes } from '@/models/ContextTypes';
import { DateTimeModes } from '@/models/DateTimeModes';
import StatsRequest from '@/models/StatsRequest';

import { selectors as trafficTopSelectors } from '@/redux/api/trafficTop';
import { selectors as socketControlSelectors } from '@/redux/ui/socketControl';

import { useTrafficTopData } from '@/pages/TrafficTop/useTrafficTopData';
import { useTrafficTopFields } from '@/pages/TrafficTop/useTrafficTopFields';

import { lang } from '+components/charts/common/utils';
import { Field, useFormState } from '+components/form/FinalForm';
import { normalizeSelectValue } from '+components/form/Normalizers';
import AdditionalFiltersDropdownField, {
  AdditionalFiltersDropdownCaptureContainer,
  AdditionalFiltersDropdownCaptureLabel,
  AdditionalFiltersDropdownCaptureValue,
} from '+components/GlobalFilters/Panel/components/AdditionalFiltersDropdownField';
import AdditionalFiltersRowItem from '+components/GlobalFilters/Panel/components/AdditionalFiltersRowItem';
import AdditionalFiltersSeparator from '+components/GlobalFilters/Panel/components/AdditionalFiltersSeparator';
import GlobalFiltersPortal from '+components/GlobalFilters/Portal';
import GlobalFiltersSetting from '+components/GlobalFilters/Setting';
import useGlobalFilters from '+hooks/useGlobalFilters';
import useGlobalFiltersProperty from '+hooks/useGlobalFiltersProperty';
import useLastAllowedContext from '+hooks/useLastAllowedContext';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import { timeBounds } from '+utils';

import TrafficTopDNSTable from './components/TrafficTopDNSTable';
import TrafficTopFlowTable from './components/TrafficTopFlowTable';
import TrafficTopTrafficTable from './components/TrafficTopTrafficTable';

const excludeContexts = new Set([ContextTypes.alerts, ContextTypes.blocks]);

const fieldLabel = 'Aggregate by';
const defaultFieldValue = {
  [ContextTypes.flow]: 'dstip',
  [ContextTypes.dns]: 'query.tld',
  [ContextTypes.traffic]: 'srcip',
};

const TableComponents = {
  [ContextTypes.flow]: TrafficTopFlowTable,
  [ContextTypes.dns]: TrafficTopDNSTable,
  [ContextTypes.traffic]: TrafficTopTrafficTable,
};

const GlobalFiltersFields = () => {
  const context = useLastAllowedContext({
    excludeContexts,
    defaultContext: ContextTypes.flow,
  });

  const fieldKey = `traffic_top_field_${context}`;

  const { values } = useFormState({ subscription: { values: true } });

  const { [fieldKey]: field = defaultFieldValue[context] } = values;

  const fields = useTrafficTopFields(context);

  const fieldsOptions = useMemo(
    () => {
      const options = (fields || []).reduce((acc, item) => {
        acc.push({
          value: item.field,
          label: item.field,
          description: item.description,
        });

        return acc;
      }, []);

      options.unshift({
        value: fieldLabel,
        label: fieldLabel,
        header: true,
      });
      return options;
    },
    [fields, context],
  );

  return (
    <Fragment>
      <AdditionalFiltersRowItem>
        <Field
          component={AdditionalFiltersDropdownField}
          name={fieldKey}
          options={fieldsOptions}
          parse={normalizeSelectValue}
          caption={(
            <AdditionalFiltersDropdownCaptureContainer>
              <AdditionalFiltersDropdownCaptureLabel>
                {fieldLabel}
              </AdditionalFiltersDropdownCaptureLabel>
              <AdditionalFiltersDropdownCaptureValue>
                {field}
              </AdditionalFiltersDropdownCaptureValue>
            </AdditionalFiltersDropdownCaptureContainer>
          )}
          data-tracking="filter-row-aggregate-by"
        />
      </AdditionalFiltersRowItem>

      <AdditionalFiltersSeparator />
    </Fragment>
  );
};

export default () => {
  const [{ context: selectedContext }] = useGlobalFilters();

  const context = useLastAllowedContext({
    excludeContexts,
    defaultContext: ContextTypes.flow,
  });

  const [filters] = useGlobalFilters(context);

  const fieldKey = `traffic_top_field_${context}`;

  const [field] = useGlobalFiltersProperty(
    fieldKey,
    defaultFieldValue[context],
  );
  const isFiltered = field !== defaultFieldValue[context];
  const portalInitialValues = useMemo(
    () => ({ [fieldKey]: field }),
    [field],
  );

  const isFetching = useSelector(trafficTopSelectors.isFetching);

  useLoadingIndicator(isFetching);

  const isSocketPaused = useSelector(socketControlSelectors.isPaused);
  const { start, end } = timeBounds({ ...filters, realtime: !isSocketPaused });

  const data = useTrafficTopData(
    context,
    field,
    {
      size: 1000,
      start,
      end,
      ...StatsRequest.makeSearch({
        search: filters.nql,
        intersect: filters.intersect,
      }),
      customers: filters.customers,
    },
    [start, end, filters.nql, filters.intersect, filters.customers],
  );

  const TableComponent = useMemo(
    () => TableComponents[context],
    [context],
  );

  return (
    <Fragment>
      <GlobalFiltersSetting
        nql
        context={context}
        dateTimeMode={DateTimeModes.now}
        customers
      />

      {!excludeContexts.has(selectedContext) && (
        <GlobalFiltersPortal
          isFiltered={isFiltered}
          initialValues={portalInitialValues}
        >
          <GlobalFiltersFields />
        </GlobalFiltersPortal>
      )}

      <TableComponent
        field={field}
        data={data}
        noDataText={data ? undefined : lang.loading}
      />
    </Fragment>
  );
};
