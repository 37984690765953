/* eslint-disable react/prop-types */
import { forwardRef } from 'react';

import styled, { css } from 'styled-components';

import GridOrigin from '@mui/material/Grid';

import { CardMixin } from './CardMixin';
import { LayoutSizes } from './LayoutSizes';
import { LayoutTypes } from './LayoutTypes';

export const Grid = styled(
  forwardRef((props, ref) => {
    const { type, role, ...rest } = props;

    let extraProps = {};

    switch (true) {
      case type === LayoutTypes.field && role === 'row':
        extraProps = {
          gap: LayoutSizes.fieldGap,
          wrap: 'nowrap',
        };
        break;
      case type === LayoutTypes.card && role === 'column':
        extraProps = {
          gap: LayoutSizes.cardGap,
          lg: true,
          item: true,
        };
        break;
      case type === LayoutTypes.fieldName && role === 'column':
        extraProps = {
          xs: LayoutSizes.fieldNameXs,
          item: true,
          container: false,
          wrap: 'nowrap',
        };
        break;
      case type === LayoutTypes.fieldValue && role === 'column':
        extraProps = {
          xs: LayoutSizes.fieldValueXs,
          item: true,
          container: false,
        };
        break;

      case type === LayoutTypes.fieldDescription && role === 'column':
        extraProps = {
          item: true,
          container: false,
        };
        break;
      default:
        break;
    }

    return (
      <GridOrigin
        ref={ref}
        {...extraProps}
        {...rest}
        data-layout={role}
        data-layout-role={type}
      />
    );
  }),
).attrs((props) => ({
  ...props,
  type: props.$type,
}))`
  ${(props) => props.$type === LayoutTypes.card && CardMixin}

  ${(props) => props.$type === LayoutTypes.title
    && css`
      font-weight: 700;
      width: 100%;
      border-bottom: 1px solid ${props.theme.cardTitleBorderColor};
      align-items: center;
      gap: 10px;
    `}

  ${(props) => props.$type === LayoutTypes.fieldValue
    && css`
      overflow: hidden;
      font-weight: 600;
      * {
        font-weight: 600;
      }
      :empty:before {
        content: '—';
      }
    `}
  
  ${(props) => props.$type === LayoutTypes.fieldDescription
    && css`
      color: ${props.theme.colorTextSecondary} !important;
    `}
`;

export default Grid;
