import PropTypes from '+prop-types';
import { useCallback } from 'react';

import { ColorTypes } from '@/models/ColorTypes';

import Button, { ButtonVariants } from '+components/Button';
import { Modal, ModalHeader, ModalBody, ModalFooter } from '+components/Modal';

const ModalDeleteDevice = (props) => {
  const { isOpen, toggleModal, isDisabled, onConfirm } = props;

  const onClick = useCallback(
    (event) => {
      event.preventDefault();

      if (onConfirm) {
        onConfirm();
      }
    },
    [onConfirm],
  );

  return (
    <Modal isOpen={isOpen} onClose={toggleModal}>
      <form className="material-form" onSubmit={onClick}>
        <ModalHeader onClose={toggleModal}>
          Reset All Custom Categories?
        </ModalHeader>

        <ModalBody>
          <p>
            This will remove all custom categories from the system, and remove
            those categories from any defined detection models.
          </p>
          <p>This cannot be undone!</p>
        </ModalBody>

        <ModalFooter>
          <Button color={ColorTypes.danger} type="submit" disabled={isDisabled}>
            Confirm
          </Button>

          <Button variant={ButtonVariants.outlined} onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

ModalDeleteDevice.propTypes = {
  isDisabled: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

ModalDeleteDevice.defaultProps = {
  isDisabled: false,
};

export default ModalDeleteDevice;
