/* eslint-disable react/prop-types */
import {
  HeaderSubheaderCell,
  HeaderSubheader as HeaderSubheaderOriginalCell,
} from '+components/Table/Cells';
import {
  BaseColumnFactory,
  MenuColumnFactory,
  NumberColumnFactory,
  TimestampColumnFactory,
} from '+components/Table/Columns';
import { getRowOriginal } from '+components/Table/Columns/utils';
import {
  BooleanColumnFilter,
  SelectColumnFilter,
} from '+components/Table/Filters';
import {
  autoRemoveIfAll,
  someOfFieldsFilter,
  withAutoRemove,
} from '+components/Table/FilterTypeFactories';
import Tag from '+components/Tag';
import dayjs, { DateFormat } from '+utils/dayjs';
import { getColumnsHelper } from '+utils/getColumnsHelper';
import makeArr from '+utils/makeArr';

const dateFormatter = ({ value }) => dayjs(value).format(DateFormat.minute);
export const Columns = {
  organization: 'organization',
  parent: 'parent',
  directCustomers: 'directCustomers',
  subCustomers: 'subCustomers',
  type: 'type',
  subscriptionType: 'subscriptionType',
  ingest: 'ingest',
  retention: 'retention',
  rollupRetention: 'rollupRetention',
  flowSources: 'flowSources',
  created: 'created',
  menu: 'menu',
  fps: 'fps',
};

const columnsCollection = ({
  cxActionMenu,
  customerTypes,
  customerSubscriptionTypes,
}) => ({
  [Columns.organization]: BaseColumnFactory({
    accessor: 'organization',
    Header: 'Organization',
    minWidth: 180,
    Cell: HeaderSubheaderCell({
      propHeader: 'organization',
      propSubheader: 'shortname',
    }),
    realAccessor: ['organization', 'shortname'],
    filter: someOfFieldsFilter(['organization', 'shortname']),
  }),
  [Columns.parent]: BaseColumnFactory({
    accessor: 'parent',
    Header: 'Reseller',
    minWidth: 100,
    maxWidth: 200,
    getCellProps: () => ({ style: { justifyContent: 'center' } }),
    Cell: ({ row }) => {
      const original = getRowOriginal(row);
      const parentTree = original.node?.split('+');
      return (
        <HeaderSubheaderOriginalCell
          header={original.parent}
          subHeader={
            parentTree?.length > 2
              ? parentTree
                .filter((item) => ![original.shortname].includes(item))
                .join(' > ')
              : undefined
          }
        />
      );
    },
    Filter: SelectColumnFilter({
      optionLabel: (key) => (key === 'all' ? 'All' : key),
    }),
    filter: withAutoRemove((rows, _, filterValue) => {
      if (autoRemoveIfAll(filterValue)) {
        return rows;
      }

      const normalizedFilterValue = String(filterValue).toLowerCase();
      return rows.filter(
        ({ original }) => original.shortname !== normalizedFilterValue
          && ['parent', 'node'].some((field) => makeArr(original[field]).some((fieldValue) => String(fieldValue ?? '')
            .toLowerCase()
            .includes(normalizedFilterValue))),
      );
    }, autoRemoveIfAll),
  }),
  [Columns.directCustomers]: NumberColumnFactory({
    accessor: 'directCustomers',
    Header: '# Direct Customers',
    minWidth: 120,
    maxWidth: 120,
  }),
  [Columns.subCustomers]: NumberColumnFactory({
    accessor: 'subCustomers',
    Header: '# Sub Customers',
    minWidth: 120,
    maxWidth: 120,
  }),
  [Columns.type]: BaseColumnFactory({
    accessor: 'type',
    Header: 'Type',
    minWidth: 90,
    maxWidth: 90,
    Cell: ({ value }) => customerTypes[value]?.label || 'Unknown',
    Filter: SelectColumnFilter({
      optionLabel: (key) => (key === 'all' ? 'All' : customerTypes[key]?.label || 'Unknown'),
    }),
    filter: 'selectFilter',
  }),
  [Columns.subscriptionType]: BaseColumnFactory({
    accessor: 'subscriptionType',
    Header: 'Subscription',
    minWidth: 100,
    maxWidth: 100,
    Cell: ({ value }) => customerSubscriptionTypes[value]?.label || 'Unknown',
    Filter: SelectColumnFilter({
      optionLabel: (key) => (key === 'all'
        ? 'All'
        : customerSubscriptionTypes[key]?.label || 'Unknown'),
    }),
    filter: 'selectFilter',
  }),
  [Columns.ingest]: BaseColumnFactory({
    accessor: 'ingest',
    Header: 'Ingest IP & Port',
    minWidth: 140,
    getCellProps: () => ({ style: { justifyContent: 'center' } }),
    Cell: ({ value }) => <Tag color="info">{value}</Tag>,
    filter: someOfFieldsFilter(['ingestIp', 'ingestPort']),
  }),
  [Columns.retention]: NumberColumnFactory({
    accessor: 'retention',
    Header: 'Data Retention',
    Filter: SelectColumnFilter({
      sort: (a, b) => a - b,
    }),
    filter: 'selectFilter',
    minWidth: 120,
    maxWidth: 120,
  }),
  [Columns.rollupRetention]: NumberColumnFactory({
    accessor: 'rollupRetention',
    Header: 'Rollup Retention',
    Filter: SelectColumnFilter({
      sort: (a, b) => a - b,
    }),
    filter: 'selectFilter',
    minWidth: 120,
    maxWidth: 120,
  }),
  [Columns.flowSources]: NumberColumnFactory({
    accessor: ({ devices, vpcs }) => devices + vpcs,
    Header: '# Traffic Sources',
    Cell: ({ value }) => value ?? 'N/A',
    Filter: BooleanColumnFilter({
      true: 'Has Sources',
      false: 'No Sources',
    }),
    filter: withAutoRemove((rows, _, filterValue) => {
      if (autoRemoveIfAll(filterValue)) {
        return rows;
      }

      const filterValueBoolean = filterValue.value === 'true';
      return rows.filter(({ values: { flowSources } }) => (filterValueBoolean ? flowSources > 0 : flowSources < 1));
    }, autoRemoveIfAll),
    minWidth: 120,
    maxWidth: 120,
  }),
  [Columns.fps]: NumberColumnFactory({
    accessor: 'fps',
    Header: 'Flows/Second',
    minWidth: 120,
    maxWidth: 120,
  }),
  [Columns.created]: TimestampColumnFactory({
    accessor: 'created',
    Header: 'Created Date',
    minWidth: 130,
    maxWidth: 130,
    Cell: dateFormatter,
  }),
  [Columns.menu]: MenuColumnFactory({ cxActionMenu }),
});

export const getColumns = getColumnsHelper(columnsCollection);
