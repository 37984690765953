import get from 'lodash.get';

import { UniversalCell } from '+components/Table/Cells';
import { BaseColumnFactory } from '+components/Table/Columns/BaseColumnFactory';
import { LabelOrPortColumnFilter } from '+components/Table/Filters';
import { labelOrPortFieldsFilter } from '+components/Table/FilterTypeFactories';
import { compareNumberAndString } from '+components/Table/ReactTable/sortTypes';
import sortByHelper from '+utils/sortByHelper';

import { getRowOriginal } from './utils';

const Cell = (props) => {
  const { cell, dataFieldName, labelFieldName, showLabel, fetchLabels } = props;

  const original = getRowOriginal(cell?.row) ?? {};
  const dataFieldValue = get(original, dataFieldName);
  const labelFieldValue = get(original, labelFieldName);

  return showLabel && labelFieldValue?.length
    ? UniversalCell(labelFieldName)({
      ...cell,
      value: labelFieldValue,
      options: { useDataValueInPropertiesTray: true },
    })
    : UniversalCell(dataFieldName)({
      ...cell,
      value: dataFieldValue,
      options: {
        showAsLabel: true,
        fetchLabels: showLabel && fetchLabels && !labelFieldValue,
      },
    });
};

export const LabelOrPortColumnFactory = ({
  dataFieldName,
  labelFieldName,
  showLabel,
  fetchLabels,
  ...tail
}) => ({
  ...BaseColumnFactory(tail),
  accessor: dataFieldName,
  realAccessor: [dataFieldName, labelFieldName],
  Cell: (cell) => (
    <Cell
      cell={cell}
      dataFieldName={dataFieldName}
      labelFieldName={labelFieldName}
      showLabel={showLabel}
      fetchLabels={fetchLabels}
    />
  ),
  Filter: LabelOrPortColumnFilter,
  filter: labelOrPortFieldsFilter(
    showLabel ? { dataFieldName, labelFieldName } : { dataFieldName },
  ),
  sortType: sortByHelper((_, row) => {
    const original = getRowOriginal(row) ?? {};
    if (showLabel) {
      const labelFieldValue = get(original, labelFieldName);
      if (labelFieldValue?.length) {
        return [...labelFieldValue].sort().join('');
      }
    }
    return get(original, dataFieldName);
  }, compareNumberAndString),
});
