export const rulesParamsToUi = (values) => {
  const UIvalues = {};

  if (values && Object.keys(values).length > 0) {
    UIvalues.name = values.name;
    UIvalues.description = values.description;
    UIvalues.enabled = values.enabled ?? true;
    UIvalues['UIseverity-high'] = false;
    UIvalues['UIseverity-medium'] = false;
    UIvalues['UIseverity-low'] = false;
    UIvalues['UIalerttype-start'] = false;
    UIvalues['UIalerttype-ongoing'] = false;
    UIvalues['UIalerttype-end'] = false;

    if (values.config) {
      if (values.config.severities.includes('all')) {
        UIvalues['UIseverity-high'] = true;
        UIvalues['UIseverity-medium'] = true;
        UIvalues['UIseverity-low'] = true;
      } else {
        if (values.config.severities.includes('high')) {
          UIvalues['UIseverity-high'] = true;
        }
        if (values.config.severities.includes('medium')) {
          UIvalues['UIseverity-medium'] = true;
        }
        if (values.config.severities.includes('low')) {
          UIvalues['UIseverity-low'] = true;
        }
      }

      if (values.config.alerttypes.includes('all')) {
        UIvalues['UIalerttype-start'] = true;
        UIvalues['UIalerttype-ongoing'] = true;
        UIvalues['UIalerttype-end'] = true;
      } else {
        if (values.config.alerttypes.includes('start')) {
          UIvalues['UIalerttype-start'] = true;
        }
        if (values.config.alerttypes.includes('ongoing')) {
          UIvalues['UIalerttype-ongoing'] = true;
        }
        if (values.config.alerttypes.includes('end')) {
          UIvalues['UIalerttype-end'] = true;
        }
      }

      UIvalues.UIcategories = [];
      if (
        values.config.categories.length > 0
        && !values.config.categories.includes('all')
      ) {
        UIvalues.UIcategories = values.config.categories;
      }

      UIvalues.UIalgorithms = [];
      if (
        values.config.algorithms.length > 0
        && !values.config.algorithms.includes('all')
      ) {
        UIvalues.UIalgorithms = values.config.algorithms;
      }

      UIvalues.UItrack = [];
      if (
        Array.isArray(values.config.tracks)
        && values.config.tracks.length > 0
        && !values.config.tracks.includes('all')
      ) {
        UIvalues.UItrack = values.config.tracks;
      }
    }

    // a Plugin is required
    UIvalues.UIplugins = (values.plugins || []).map((el) => el.id);
  }

  return UIvalues;
};

export const rulesUItoParams = (UIvalues) => {
  const values = {
    name: UIvalues.name,
    description: UIvalues.description,
    type: 'alert',
    enabled: UIvalues.enabled ?? true,
    config: {},
  };

  if (
    UIvalues['UIseverity-high']
    && UIvalues['UIseverity-medium']
    && UIvalues['UIseverity-low']
  ) {
    values.config.severities = ['all'];
  } else {
    values.config.severities = [];
    if (UIvalues['UIseverity-high']) {
      values.config.severities.push('high');
    }
    if (UIvalues['UIseverity-medium']) {
      values.config.severities.push('medium');
    }
    if (UIvalues['UIseverity-low']) {
      values.config.severities.push('low');
    }
  }

  if (
    UIvalues['UIalerttype-start']
    && UIvalues['UIalerttype-ongoing']
    && UIvalues['UIalerttype-end']
  ) {
    values.config.alerttypes = ['all'];
  } else {
    values.config.alerttypes = [];
    if (UIvalues['UIalerttype-start']) {
      values.config.alerttypes.push('start');
    }
    if (UIvalues['UIalerttype-ongoing']) {
      values.config.alerttypes.push('ongoing');
    }
    if (UIvalues['UIalerttype-end']) {
      values.config.alerttypes.push('end');
    }
  }

  values.config.algorithms = ['all'];
  if (
    UIvalues.UIalgorithms
    && UIvalues.UIalgorithms.length > 0
    && !UIvalues.UIalgorithms.includes('all')
  ) {
    values.config.algorithms = UIvalues.UIalgorithms;
  }

  values.config.categories = ['all'];
  if (
    UIvalues.UIcategories
    && UIvalues.UIcategories.length > 0
    && !UIvalues.UIcategories.includes('all')
  ) {
    values.config.categories = UIvalues.UIcategories;
  }

  values.config.tracks = ['all'];
  if (
    UIvalues.UItrack
    && UIvalues.UItrack.length > 0
    && !UIvalues.UItrack.includes('all')
  ) {
    values.config.tracks = UIvalues.UItrack;
  }
  values.config.objects = Object.assign([], values.config.tracks); // v1 backwards compatability - DEPCREATED

  values.plugins = [];
  if (UIvalues.UIplugins && UIvalues.UIplugins.length) {
    values.plugins = UIvalues.UIplugins;
  }

  // console.log('rulesUItoParams', values);
  return values;
};
