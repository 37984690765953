import styled from 'styled-components';

const HeatmapContainer = styled.div`
  display: content;
  position: relative;
  min-height: 300px;
  .color-axis {
    display: none;
  }

  .highcharts-series-0,
  .highcharts-data-label {
    cursor: ${(props) => (props.$clickable ? 'pointer' : 'default')};
    pointer-events: ${(props) => (props.$clickable ? 'auto' : 'none')};
  }

  .highcharts-point.highcharts-null-point {
    cursor: default;
  }

  .highcharts-data-labels text {
    fill: black !important;
    font-weight: normal !important;
  }
  .highcharts-alternate-grid:not(#fake_id) {
    fill: ${({ theme }) => theme.table.colorTrOddBackground};
  }
  .highcharts-plot-band:not(#fake_id),
  .highcharts-pane:not(#fake_id) {
    fill-opacity: 1;
    stroke-width: 0;
  }
  .highcharts-data-label {
    font-size: 13px;
  }
  .highcharts-axis-labels text {
    fill: #a2a2a2 !important;
    font-size: 11px;
  }
  .highcharts-title {
    margin-top: 8px !important;
  }
  .highcharts-null-point {
    fill: transparent !important;
  }
`;

export default HeatmapContainer;
