import PropTypes from '+prop-types';
import { Fragment } from 'react';

import styled from 'styled-components';

import { FlowMetricSettings } from '@/models/MetricSettings';

import * as Menu from '+components/Menu';
import {
  CellIp,
  ShowMoreWrapper,
  UniversalCell,
} from '+components/Table/Cells';

const tdStyle = {
  // textAlign: 'right',
  paddingLeft: '5px',
};

const Labels = styled.div`
  display: flex;
  overflow: hidden;
  max-width: 300px;
  min-width: 80px;
`;

const Tooltip = ({
  labelContext,
  ipLabels,
  portLabels,
  onMenuOpen,
  onMenuClose,
  ...rest
}) => (
  <Fragment>
    <Menu.TriggerMenu onOpen={onMenuOpen} onClose={onMenuClose} />
    <table style={{ width: '100%' }}>
      <tbody>
        {rest.dfx != null ? (
          <tr>
            <td colSpan={2}>
              <i>Right click to unpin</i>
              <br />
            </td>
          </tr>
        ) : null}
        {rest.ips ? (
          <tr>
            <td>ip{rest.ips.size > 1 ? 's' : ''}:</td>
            <td style={tdStyle}>
              <Labels>
                <ShowMoreWrapper>
                  {Array.from(rest.ips).map((val) => (
                    <CellIp
                      key={val}
                      field="ip"
                      value={val}
                      original={{
                        customer: rest.customer,
                      }}
                    />
                  ))}
                </ShowMoreWrapper>
              </Labels>
            </td>
          </tr>
        ) : null}
        {ipLabels?.length ? (
          <tr>
            <td>label.ip.{labelContext.ip}:</td>
            <td style={tdStyle}>
              <Labels>
                {UniversalCell(`label.ip.${labelContext.ip}`)({
                  row: {
                    original: {
                      ...rest,
                      ...(rest.type !== 'label' && { srcip: rest.ip }),
                    },
                  },
                  value: { src: ipLabels },
                })}
              </Labels>
            </td>
          </tr>
        ) : null}
        {rest.port ? (
          <tr>
            <td>port:</td>
            <td style={tdStyle}>
              {UniversalCell('port')({
                row: { original: rest },
                value: rest.port,
              })}
            </td>
          </tr>
        ) : null}
        {portLabels?.length ? (
          <tr>
            <td>label.port.{labelContext.port}:</td>
            <td style={tdStyle}>
              <Labels>
                {UniversalCell(`label.port.${labelContext.port}`)({
                  row: { original: { ...rest, srcport: rest.port } },
                  value: { src: portLabels },
                })}
              </Labels>
            </td>
          </tr>
        ) : null}
        {rest.flowsrcip ? (
          <tr>
            <td>flowsrcip:</td>
            <td style={tdStyle}>
              {UniversalCell('flowsrcip')({
                row: { original: { ...rest, flowsrcname: null } },
                value: rest.flowsrcip,
              })}
            </td>
          </tr>
        ) : null}
        {rest.flowsrcname ? (
          <tr>
            <td>flowsrcname:</td>
            <td style={tdStyle}>
              {rest.customer !== null
                ? rest.flowsrcname
                : UniversalCell('flowsrcname')({
                  row: { original: rest },
                  value: rest.flowsrcname,
                })}
            </td>
          </tr>
        ) : null}
        {rest.bits !== null ? (
          <tr>
            <td>{FlowMetricSettings.bits.chartTitle} (bits):</td>
            <td style={tdStyle}>
              {FlowMetricSettings.bits.yAxisFormatter(rest.bits)}
            </td>
          </tr>
        ) : null}
        {rest.customer !== null ? (
          <tr>
            <td>Account:</td>
            <td style={tdStyle}>{rest.customer}</td>
          </tr>
        ) : null}
      </tbody>
    </table>
  </Fragment>
);

Tooltip.propTypes = {
  flowsrcname: PropTypes.string,
  flowsrcip: PropTypes.string,
  labelContext: PropTypes.shape({
    ip: PropTypes.string,
    port: PropTypes.string,
  }),
  ipLabels: PropTypes.arrayOf(PropTypes.string),
  portLabels: PropTypes.arrayOf(PropTypes.string),

  onMenuOpen: PropTypes.func,
  onMenuClose: PropTypes.func,
};

Tooltip.defaultProps = {
  flowsrcname: null,
  flowsrcip: null,

  labelContext: {},
  ipLabels: null,
  portLabels: null,

  onMenuOpen: null,
  onMenuClose: null,
};

export default Menu.withMenu(Tooltip);
