import styled from 'styled-components';

const TopbarContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  min-height: 40px;
  max-height: 40px;

  background: ${({ theme }) => theme.topbarBackground};
  border-bottom: ${({ $separatorWidth }) => $separatorWidth}px solid
    ${({ theme }) => theme.colorBackgroundSeparator};
`;

export default TopbarContainer;
