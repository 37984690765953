import PropTypes from '+prop-types';

import classNames from 'classnames';

const FieldContainer = ({ className, row, ...tail }) => (
  <div
    {...tail}
    className={classNames(className, 'form__form-group-field', { row })}
  />
);

FieldContainer.propTypes = {
  /**
   * Override or extend the styles applied to the component.
   */
  className: PropTypes.string,
  /**
   * If true, childes will be displayed in row.
   */
  row: PropTypes.bool,
};

FieldContainer.defaultProps = {
  className: null,
  row: false,
};

export default FieldContainer;
