import PropTypes from '+prop-types';
import { forwardRef, useCallback } from 'react';

import styled from 'styled-components';

import InputAdornment from '@mui/material/InputAdornment';
import TextFieldOrigin from '@mui/material/TextField';

import ClearIcon from 'mdi-react/CloseIcon';

import IconButtonOrigin from '+components/IconButton';

const ClearButton = styled(IconButtonOrigin)`
  padding: unset !important;
  width: 24px;
  height: 100%;
  color: ${({ theme }) => theme.iconButtonInsideComponentsText} !important;
  background: unset !important;

  :hover {
    background: unset !important;
  }

  :disabled {
    color: unset !important;
    background-color: unset !important;
  }
`;

export const StyledTextField = styled(TextFieldOrigin)`
  font-size: 1em;

  .MuiInputAdornment-root {
    border-left: unset !important;
  }

  .Mui-disabled .MuiInputAdornment-root {
    display: none !important;
  }

  .MuiFormControl-root& {
    display: block;
  }

  fieldset,
  .MuiFormHelperText-contained {
    display: none;
  }

  .MuiInputBase-root {
    background: ${({ theme }) => theme.colorFieldBackground};
    border-radius: 4px;
    border: 1px solid
      ${({ $invalid, theme }) => ($invalid ? theme.colorFieldsBorderInvalid : theme.colorFieldsBorder)};
    box-shadow: none;
    overflow: hidden;
    padding-right: 0;

    transition: border-color 0.3s ease-in-out;

    display: flex;
    height: 32px;

    .MuiInputAdornment-root {
      margin-left: 0;
      height: 100%;
      max-height: 100%;
      width: unset;

      border-left: 1px solid
        ${({ $invalid, theme }) => ($invalid ? theme.colorFieldsBorderInvalid : theme.colorFieldsBorder)};
      transition: border-color 0.3s ease-in-out;
      overflow: hidden;

      justify-content: center;
      align-items: center;
      //min-width: 3em;
      //max-width: 6em;

      display: flex;

      > div {
        overflow: hidden;
        flex: 1 1 0;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 3em;
      }
    }

    &:hover:not(.Mui-disabled),
    &:focus-within:not(.Mui-disabled) {
      border-color: ${({ $invalid, theme }) => ($invalid ? theme.colorFieldsBorderInvalid : theme.primary)};

      .MuiInputAdornment-root {
        border-color: ${({ $invalid, theme }) => ($invalid ? theme.colorFieldsBorderInvalid : theme.primary)};
      }
    }

    &.readOnly {
      background: transparent;
    }

    &.Mui-disabled {
      border: 0 solid transparent;
      background: transparent;

      .MuiInputBase-input.Mui-disabled {
        padding-left: unset;
        padding-right: unset;
        color: ${({ theme }) => theme.colorTextSecondary};
        -webkit-text-fill-color: unset;
        ::placeholder {
          opacity: 1;
        }
        &[value=''] {
          cursor: default;
        }
      }
    }
  }

  .MuiIconButton-root {
    padding: 0.8em;
    color: ${({ theme }) => theme.colorText};
    transition: background-color 0.3s ease-in-out;
    margin: 0;

    &:hover {
      background-color: ${({ theme }) => theme.colorHover};
    }

    &.Mui-disabled {
      color: ${({ theme }) => theme.colorText};
    }
  }

  .MuiSvgIcon-root {
    font-size: 1.3em;
  }

  .MuiOutlinedInput-adornedEnd {
    font-size: inherit;
    padding: 0;
  }

  .MuiOutlinedInput-input {
    background: transparent;
    font-size: 12px;
    padding: ${({ $showClear }) => ($showClear ? '0 0 0 7px' : '0 7px')};
    display: block;
    height: 100%;
    width: unset;
    -webkit-flex: 1 1 0;
    -ms-flex: 1 1 0;
    flex: 1 1 0;
    color: ${({ theme }) => theme.colorText};
    min-width: 11.5em;
    &::placeholder {
      opacity: 1;
      color: ${({ theme }) => theme.colorTextSecondary};
    }
  }

  .MuiOutlinedInput-input:not(#fake_id_for_input) {
    border: 1px solid transparent;
  }
`;

const InputText = forwardRef((props, ref) => {
  const {
    value,
    disabled,
    readOnly,
    showClearButton,
    InputProps,
    onChange,
    ...tail
  } = props;

  const showClear = !disabled && !readOnly && showClearButton && value;

  const onClear = useCallback(
    (e) => {
      e.stopPropagation();
      onChange(null);
    },
    [onChange],
  );

  const EndAdornment = useCallback(
    () => {
      if (!showClear) {
        return null;
      }
      return (
        <InputAdornment position="end">
          <ClearButton
            tabIndex={-1}
            size="medium"
            onClick={onClear}
            data-testid="clear-button"
          >
            <ClearIcon size={14} />
          </ClearButton>
        </InputAdornment>
      );
    },
    [showClear, onClear],
  );

  return (
    <StyledTextField
      {...tail}
      ref={ref}
      value={value}
      disabled={disabled}
      InputProps={{
        endAdornment: <EndAdornment />,
        readOnly,
        ...InputProps,
      }}
      $showClear={showClear}
      onChange={onChange}
    />
  );
});

InputText.propTypes = {
  value: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  showClearButton: PropTypes.bool,
  InputProps: PropTypes.object,
  onChange: PropTypes.func,
};

InputText.defaultProps = {
  value: '',
  disabled: false,
  readOnly: false,
  showClearButton: false,
  InputProps: {},
  onChange: null,
};

export { InputAdornment, ClearButton, ClearIcon };
export default InputText;
