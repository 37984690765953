import styled from 'styled-components';

import { propsSelectors as globalFiltersTheme } from '+theme/slices/globalFilters';

const MainRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  padding: 3px 15px;
  height: 47px;
  max-height: 47px;
  width: 100%;
  flex-shrink: 0;
  background: ${globalFiltersTheme.mainRowBackground};
  border-bottom: ${({ $separatorWidth }) => $separatorWidth}px solid
    ${({ theme }) => theme.colorBackgroundSeparator};
`;

export default MainRow;
