import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import RoutePaths from '@/models/RoutePaths';

import { actions, selectors } from '@/redux/api/rules';

import usePageTabs from '+hooks/usePageTabs';

import RuleForm from './Form';
import { rulesParamsToUi } from './Rules';

// convert nested API object to flattenened UI object
const initialValues = rulesParamsToUi({
  config: {
    alerttypes: ['all'],
    algorithms: ['all'],
    categories: ['all'],
    severities: ['all'],
    tracks: ['all'],
  },
});

const RuleAdd = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [, activePageTab] = usePageTabs();

  // track if we have intercepted a submit and if we are waiting on the API/dispatch to complete
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { isFetching, error } = useSelector(selectors.getState);

  const onSubmit = useCallback(
    (values) => {
      setIsSubmitting(activePageTab?.id);
      dispatch(actions.addRule(values));
    },
    [activePageTab?.id],
  );

  useEffect(
    () => {
      if (!isSubmitting || isFetching) {
        return;
      }

      if (isSubmitting !== activePageTab?.id) {
        return;
      }

      if (!error) {
        navigate(`${RoutePaths.responsePolicies}`);
      } else {
        setIsSubmitting(false);
      }
    },
    [isSubmitting, isFetching, error, activePageTab?.id],
  );

  return (
    <RuleForm
      mode="create"
      initialValues={initialValues}
      createCallback={onSubmit}
      loading={!!isSubmitting && isFetching}
    />
  );
};

export default RuleAdd;
