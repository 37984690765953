import { css } from 'styled-components';

const FontMixin = css`
  font-family: 'Source Sans Pro', sans-serif !important;
  font-size: 13px !important;
  font-style: normal !important;
  font-weight: 400 !important;
`;

export default FontMixin;
