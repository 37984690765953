import { clearTimeoutRAF, setTimeoutRAF } from './setTimeoutRAF';

/**
 * @typedef {Function} ThrottleWrapper
 * @property {function} cancel - to cancel rAF.
 */

/**
 * implementation of easy throttling function based on rAF.
 * @param {function} fn - function to execute
 * @param {number} delay - delay in ms
 * @returns {ThrottleWrapper} - function to cancel throttling
 */
export const throttleRAF = (fn, delay) => {
  let timer = null;

  let firstTime = true;
  let params;

  const runner = () => {
    timer = null;
    fn(...params);
  };

  const wrapper = (...args) => {
    params = args;

    if (firstTime) {
      firstTime = false;
      runner();
      return;
    }

    if (timer) {
      return;
    }

    timer = setTimeoutRAF(runner, delay);
  };

  wrapper.cancel = () => {
    clearTimeoutRAF(timer);
  };

  return wrapper;
};
