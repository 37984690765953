import { useMemo } from 'react';
import { NavLink } from 'react-router-dom';

import RoutePaths from '@/models/RoutePaths';

import getEventTableFilterValues from '+components/ContextTables/EventTable/getEventTableFilterValues';
import { ShowMoreWrapper, UniversalCell } from '+components/Table/Cells';
import {
  MenuColumnFactory,
  NumberColumnFactory,
  MenuColumnLink,
  BaseColumnFactory,
} from '+components/Table/Columns';
import { getRowOriginal } from '+components/Table/Columns/utils';
import { SelectColumnFilter } from '+components/Table/Filters';
import { getColumnsHelper } from '+utils/getColumnsHelper';

export const Columns = {
  algorithm: 'algorithm',
  categories: 'categories',
  count: 'count',
  severity: 'severity',
  customer: 'customer',
  menu: 'menu',
};

export const columnsCollection = ({ algorithms, categories, userFilters }) => ({
  [Columns.algorithm]: BaseColumnFactory({
    accessor: Columns.algorithm,
    Header: 'tdm.name',
    Cell: UniversalCell(Columns.algorithm),
    ...getEventTableFilterValues(Columns.algorithm, { algorithms }),
  }),
  [Columns.categories]: BaseColumnFactory({
    accessor: Columns.categories,
    getCellProps: () => ({ style: { whiteSpace: 'unset' } }),
    Cell: UniversalCell(Columns.categories),
    ...getEventTableFilterValues(Columns.categories, { categories }),
  }),
  [Columns.severity]: BaseColumnFactory({
    accessor: Columns.severity,
    width: 220,
    Cell: UniversalCell(Columns.severity),
    Filter: SelectColumnFilter({
      fixedOptions: ['all', 'high', 'medium', 'low'],
    }),
    filter: 'selectFilter',
  }),
  [Columns.count]: NumberColumnFactory({
    accessor: Columns.count,
    Header: 'total count',
    Cell: ({ row }) => useMemo(
      () => {
        const original = getRowOriginal(row);
        const search = new URLSearchParams();
        search.set('algorithm', original.algorithm);
        if (userFilters.isCustomPeriod) {
          search.set('from', userFilters.start);
          search.set('to', userFilters.end);
        }
        const url = `${RoutePaths.events}/list?${search.toString()}`;
        return (
          <NavLink to={url} title={`Filter by ${original.algorithm} algorithm`}>
            {original.count}
          </NavLink>
        );
      },
      [row, userFilters.start, userFilters.end],
    ),
    width: 100,
  }),
  [Columns.customer]: BaseColumnFactory({
    accessor: 'customer',
    Header: 'Account',
    width: 160,
    Cell: ({ value }) => useMemo(
      () => (
        <ShowMoreWrapper>
          {value.map((item) => (
            <span key={item}>{item}</span>
          ))}
        </ShowMoreWrapper>
      ),
      [value],
    ),
    Filter: SelectColumnFilter({
      optionLabel: (key) => (key === 'all' ? 'All' : key),
    }),
    filter: 'selectFilter',
  }),
  [Columns.menu]: MenuColumnFactory({
    cxActionMenu: (_, original) => {
      const search = new URLSearchParams();
      search.set('algorithm', original.algorithm);
      if (userFilters.isCustomPeriod) {
        search.set('from', userFilters.start);
        search.set('to', userFilters.end);
      }
      const url = `${RoutePaths.events}/list?${search.toString()}`;
      return (
        <MenuColumnLink
          to={url}
          title={`Filter by ${original.algorithm} algorithm`}
        />
      );
    },
  }),
});

export const getColumns = getColumnsHelper(columnsCollection);
