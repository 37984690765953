import { isLabelField } from '@/models/FieldTypes';
import * as PropertiesTray from '@/models/PropertiesTray';

import { getSeriesValues, unknown } from '+components/charts/common/formatters';

const getPropertiesTrayData = ({
  context,
  fields,
  item,
  labelContext = {},
}) => {
  // TODO: why not just use getSeriesFields? not all sharts (like bar) support that?
  // const fields = getSeriesFields(item);
  // aggregated data
  if (!fields?.length) {
    return [
      {
        value: item.name,
      },
    ];
  }

  // card metrics
  if (item.name.startsWith('unique-')) {
    return [
      {
        value: 'unique',
      },
    ];
  }

  const values = getSeriesValues(item);
  // if there is 'customer' field in the fields, then we need to save customer to const and skipFieldIndexes
  const customerFieldIndex = fields.findIndex((field) => field === 'customer');
  let customer;
  if (customerFieldIndex !== -1) {
    customer = values[customerFieldIndex];
  }

  const skipFieldNames = fields.reduce((acc, field) => {
    switch (field) {
      case 'srcip': {
        acc[`label.ip.${labelContext.ip}.src`] = labelContext.show;
        acc.srcipname = labelContext.show && labelContext.ip === 'name';
        return acc;
      }

      case 'dstip': {
        acc[`label.ip.${labelContext.ip}.dst`] = labelContext.show;
        acc.dstipname = labelContext.show && labelContext.ip === 'name';
        return acc;
      }

      case 'ipinfo.ip': {
        acc['ipinfo.ipname'] = labelContext.show && labelContext.ip === 'name';
        return acc;
      }

      case 'srcport': {
        acc[`label.port.${labelContext.port}.src`] = labelContext.show;
        acc.srcportname = labelContext.show && labelContext.port === 'name';
        return acc;
      }

      case 'dstport': {
        acc[`label.port.${labelContext.port}.dst`] = labelContext.show;
        acc.dstportname = labelContext.show && labelContext.port === 'name';
        return acc;
      }

      case 'srcports': {
        acc.srcportnames = labelContext.show && labelContext.port === 'name';
        return acc;
      }

      case 'dstports': {
        acc.dstportnames = labelContext.show && labelContext.port === 'name';
        return acc;
      }

      case 'srcas.number': {
        acc['srcas.org'] = true;
        return acc;
      }

      case 'srcowneras.number': {
        acc['srcowneras.org'] = true;
        return acc;
      }

      case 'dstas.number': {
        acc['dstas.org'] = true;
        return acc;
      }

      case 'dstowneras.number': {
        acc['dstowneras.org'] = true;
        return acc;
      }

      case 'customer':
        acc.customer = true;
        return acc;

      default:
        return acc;
    }
  }, {});

  return fields.reduce((acc, field, fieldIndex) => {
    if (skipFieldNames[field]) {
      return acc;
    }

    if (isLabelField(field)) {
      const [firstLabel] = values[fieldIndex].split(', ');
      acc.push({
        dataType: PropertiesTray.DataTypes.field,
        context,
        field,
        value: firstLabel === unknown ? null : firstLabel,
        customer,
      });
      return acc;
    }

    acc.push({
      dataType: PropertiesTray.DataTypes.field,
      context,
      field,
      value: values[fieldIndex],
      customer,
    });

    return acc;
  }, []);
};

export default getPropertiesTrayData;
