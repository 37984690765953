import PropTypes from '+prop-types';
import { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import capitalize from 'lodash.capitalize';

import { ContextTypes } from '@/models/ContextTypes';
import StatsRequest from '@/models/StatsRequest';

import { actions as filtersActions } from '@/redux/globalFilters';
import { selectors as socketControlSelectors } from '@/redux/ui/socketControl';

import TimeseriesChart from '+components/charts/TimeseriesChart';
import useGlobalFilters from '+hooks/useGlobalFilters';
import useLastAllowedContext from '+hooks/useLastAllowedContext';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import useStatsRequest from '+hooks/useStatsRequest';
import getMetricFieldName from '+utils/getMetricFieldName';
import { timeBounds } from '+utils/timeBounds';

const chartType = 'vpc';
const fields = {
  [ContextTypes.flow]: ['flowsrcname'],
  [ContextTypes.dns]: ['datasrc'],
};
const allowedContexts = new Set([ContextTypes.flow, ContextTypes.dns]);

const SourcesLineChart = (props) => {
  const {
    sources,
    width,
    height,
    metric: searchMetric,
    excludeMetrics,
    nqlQuery,
    size,
    context: strictContext,
  } = props;

  const dispatch = useDispatch();

  const isSocketPaused = useSelector(socketControlSelectors.isPaused);

  const selectedContext = useLastAllowedContext({
    allowedContexts,
    defaultContext: ContextTypes.flow,
  });

  const context = strictContext || selectedContext;

  const namespace = useMemo(
    () => `sources-line-chart-${context}`,
    [context],
  );

  const [filters] = useGlobalFilters(context);
  const { start, end } = timeBounds({ ...filters, realtime: !isSocketPaused });
  const request = useMemo(
    () => ({
      seriesId: namespace,
      params: {
        start,
        end,
        series: [
          {
            metric: filters.metric,
            size,
            name: chartType,
            field: fields[context],
            ...StatsRequest.makeSearch({
              search: filters.nql,
              andSearch: nqlQuery,
              intersect: filters.intersect,
            }),
          },
        ],
      },
    }),
    [
      namespace,
      start,
      end,
      filters.metric,
      filters.nql,
      filters.intersect,
      nqlQuery,
      size,
      context,
    ],
  );

  const { series, isFetching: isStatsFetching } = useStatsRequest({
    context,
    requestType: StatsRequest.Types.ts,
    request,
    stopRequest: excludeMetrics.includes(filters.metric) || !sources?.length,
  });

  const title = useMemo(
    () => (series === undefined
      ? ''
      : `Top ${size} by ${capitalize(filters.metric)}`),
    [series, size, filters.metric],
  );

  useLoadingIndicator(isStatsFetching);

  useEffect(
    () => {
      if (searchMetric) {
        dispatch(
          filtersActions.changeFilter({
            [getMetricFieldName(ContextTypes.flow)]: searchMetric,
          }),
        );
      }
    },
    [searchMetric],
  );

  return (
    <TimeseriesChart
      title={title}
      fields={fields[context]}
      series={series}
      width={width}
      height={height}
      loading={!series}
      stacked="normal"
    />
  );
};

SourcesLineChart.propTypes = {
  sources: PropTypes.arrayOf(PropTypes.shape({})),
  width: PropTypes.number,
  height: PropTypes.number,
  filter: PropTypes.shape({}),
  metric: PropTypes.string,
  excludeMetrics: PropTypes.arrayOf(PropTypes.string),
  nqlQuery: PropTypes.string,
  size: PropTypes.number,
  context: PropTypes.oneOf([...allowedContexts]),
};

SourcesLineChart.defaultProps = {
  sources: [],
  width: undefined,
  height: 300,
  filter: {},
  metric: '',
  excludeMetrics: [],
  nqlQuery: '',
  size: 10,
  context: undefined,
};

export default SourcesLineChart;
