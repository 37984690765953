import styled from 'styled-components';

import { Tabs as TabsOrigin } from '+components/Tabs';

const Tabs = styled(TabsOrigin)`
  .MuiTabs-flexContainer:before {
    content: '';
    width: ${({ $padding }) => $padding};
    border-bottom: 2px solid ${({ theme }) => theme.tabsBorderColor};
    margin-left: ${({ $padding }) => `-${$padding}`};
    padding-right: ${({ $padding }) => `calc(${$padding} * 2)`};
  }
`;

export default Tabs;
