import PropTypes from '+prop-types';

import { useFlag } from '@unleash/proxy-client-react';

const FeatureFlag = (props) => {
  const { flag, invert, children } = props;
  let isEnabled = useFlag(flag);

  if (invert) {
    isEnabled = !isEnabled;
  }

  return isEnabled ? children : null;
};

FeatureFlag.propTypes = {
  flag: PropTypes.string.isRequired,
  children: PropTypes.children.isRequired,
  invert: PropTypes.bool,
};
FeatureFlag.defaultProps = {
  invert: false,
};

export default FeatureFlag;
