import PropTypes from '+prop-types';
import { memo, useRef } from 'react';

import { StyledTextField } from '../InputText';

const TransformTextField = (props) => {
  const { fieldKey, defaultValue, onChange, disabled } = props;

  const ref = useRef('');

  return (
    <StyledTextField
      disabled={disabled}
      inputRef={ref}
      defaultValue={defaultValue}
      onChange={() => onChange(fieldKey, ref.current.value)}
    />
  );
};

TransformTextField.propTypes = {
  fieldKey: PropTypes.string,
  defaultValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

TransformTextField.defaultProps = {
  fieldKey: '',
};

export default memo(TransformTextField);
