export const UnixTimestampFields = [
  'created', // device
  'updated', // detection model
  'firstseen', // ip intel
  'lastseen', // device, ip intel
  'lastupdate',
  'lastupdated', // device
  'learnedat', // device
  'start', // event, flow
  'end', // event, flow
  'timestamp', // flow
  'lastAccess', // user sessions
  'expiration', // blocks
  'srcfirstseen', // blocks
  'dstfirstseen', // blocks
  'srclastseen', // blocks
  'dstlastseen', // blocks
  'flowrtime', // flow
  'rtime', // dns
];
