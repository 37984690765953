import capitalize from 'lodash.capitalize';

import { ContextTypes } from '@/models/ContextTypes';

/**
 * Returns metric field name for globalFilters based on context
 * @param {ContextTypes} context
 * @return {string}
 */
export default (context) => {
  if (!ContextTypes[context]) {
    throw new Error('context should be one of ContextTypes');
  }

  return `metric${capitalize(context)}`;
};
