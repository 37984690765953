import TechnicalColumns from './ReactTable/TechnicalColumns';

export const baseRowHeight = 45;

export const defaultRowHeight = baseRowHeight + 1;

export const validMinRows = [5, 10, 20, 25, 50, 100];

export const emptySettings = {};

export const technicalColumns = Object.values(TechnicalColumns);
