import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.chartBackground};
`;
