import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectors as customerSelectors } from '@/redux/api/customer';
import {
  actions as usersActions,
  selectors as usersSelectors,
} from '@/redux/api/user';
import {
  actions as profileActions,
  selectors as profileSelectors,
} from '@/redux/api/user/profile';

import UserProfile from '+components/UserProfile';

const Profile = () => {
  const dispatch = useDispatch();

  const profile = useSelector(profileSelectors.getProfile);
  const otpDevices = useSelector(profileSelectors.getOtpDevices);
  const sessions = useSelector(profileSelectors.getSessions);

  const isCurrentCustomerFetching = useSelector(customerSelectors.isFetching);
  const isProfileFetching = useSelector(profileSelectors.isFetching);
  const isUsersFetching = useSelector(usersSelectors.isFetching);

  const isFetching = isCurrentCustomerFetching || isProfileFetching || isUsersFetching;

  const onSendVerificationEmail = useCallback(
    () => {
      dispatch(usersActions.sendVerificationEmail(profile?.id));
    },
    [profile?.id],
  );

  const onSendChangePasswordEmail = useCallback(
    () => {
      dispatch(usersActions.sendChangePasswordEmail(profile?.id));
    },
    [profile?.id],
  );

  const onUpdate = useCallback(
    (values) => {
      dispatch(profileActions.requestProfileUpdate(values));
    },
    [],
  );

  const onSessions = useCallback(
    () => {
      dispatch(profileActions.requestSessions());
    },
    [],
  );

  const onOtpDevices = useCallback(
    () => {
      dispatch(profileActions.requestOtpDevices());
    },
    [],
  );

  const onOtpEnable = useCallback(
    () => {
      dispatch(profileActions.enableOtp());
    },
    [],
  );

  const onOtpDisable = useCallback(
    () => {
      dispatch(profileActions.disableOtp());
    },
    [],
  );

  const onOtpEmailEnable = useCallback(
    () => {
      dispatch(profileActions.toggleOtpEmail(true));
    },
    [],
  );

  const onOtpEmailDisable = useCallback(
    () => {
      dispatch(profileActions.toggleOtpEmail(false));
    },
    [],
  );

  const onOtpDeviceAdd = useCallback(
    () => {
      dispatch(profileActions.requestOtpDeviceConfiguration());
    },
    [],
  );

  const onOtpDeviceDelete = useCallback(
    (deviceId) => {
      if (deviceId === 'new') {
        dispatch(profileActions.cancelOtpDeviceConfigurationRequest());
      } else {
        dispatch(profileActions.deleteOtpDevice(deviceId));
      }
    },
    [],
  );

  useEffect(
    () => {
      dispatch(profileActions.requestProfile());
    },
    ['hot'],
  );

  return (
    <UserProfile
      user={profile}
      otpDevices={otpDevices}
      sessions={sessions}
      isFetching={isFetching}
      onSendVerificationEmail={onSendVerificationEmail}
      onSendChangePasswordEmail={onSendChangePasswordEmail}
      onSessions={onSessions}
      onOtpDevices={onOtpDevices}
      onOtpEnable={onOtpEnable}
      onOtpDisable={onOtpDisable}
      onOtpEmailEnable={onOtpEmailEnable}
      onOtpEmailDisable={onOtpEmailDisable}
      onOtpDeviceAdd={onOtpDeviceAdd}
      onOtpDeviceDelete={onOtpDeviceDelete}
      onUpdate={onUpdate}
      profileMode
    />
  );
};

export default Profile;
