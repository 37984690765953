/* eslint-disable react/prop-types */
import { memo, useCallback, useMemo } from 'react';

import isFunction from 'lodash.isfunction';
import styled from 'styled-components';

import { CheckBox } from '+components/form/CheckBox';
import { BooleanColumnFilter } from '+components/Table/Filters';
import {
  autoRemoveIfAll,
  withAutoRemove,
} from '+components/Table/FilterTypeFactories';
import TechnicalColumns from '+components/Table/ReactTable/TechnicalColumns';
import Tooltip from '+components/Tooltip';
import useEvent from '+hooks/useEvent';

const StyledCheckBox = memo(styled(CheckBox)`
  justify-content: center;
  width: 18px;
`);

const Header = memo((props) => {
  const { disabled, onToggle, checked } = props;

  const onChange = useCallback(
    () => {
      onToggle();
    },
    [onToggle],
  );

  return (
    <StyledCheckBox checked={checked} disabled={disabled} onChange={onChange} />
  );
});

const Cell = memo((props) => {
  const { original, onToggle, checked, disabled, getTooltip } = props;

  const tooltip = isFunction(getTooltip) ? getTooltip(original, checked) : '';

  const onChange = useEvent((event) => {
    onToggle(event.target.checked);
  });

  const checkBox = useMemo(
    () => (
      <StyledCheckBox
        checked={checked}
        disabled={disabled}
        onChange={onChange}
      />
    ),
    [checked, disabled, onChange],
  );

  return !tooltip ? (
    checkBox
  ) : (
    <Tooltip title={tooltip}>
      <div>{checkBox}</div>
    </Tooltip>
  );
});

const filter = withAutoRemove((rows, _, filterValue) => {
  if (autoRemoveIfAll(filterValue)) {
    return rows;
  }

  const filterValueBoolean = `${filterValue}` === 'true';

  return rows.filter(
    ({ isSelected, isSomeSelected }) => isSelected === filterValueBoolean
      || (filterValueBoolean && isSomeSelected === filterValueBoolean),
  );
}, autoRemoveIfAll);

const initCheckBoxColumn = (options) => {
  const { getTooltip, width, filterLabels } = options;

  Object.assign(options, {
    accessor: 'id',
    Header: ({
      selectByPage,
      isAllRowsSelected,
      isAllPageRowsSelected,
      isAllRowsSelectorDisabled,
      isAllPageRowsSelectorDisabled,
      toggleAllPageRowsSelected,
      toggleAllRowsSelected,
    }) => (
      <Header
        onToggle={selectByPage ? toggleAllPageRowsSelected : toggleAllRowsSelected}
        checked={selectByPage ? isAllPageRowsSelected : isAllRowsSelected}
        disabled={
          selectByPage
            ? isAllPageRowsSelectorDisabled
            : isAllRowsSelectorDisabled
        }
      />
    ),
    width: width || 60,
    maxWidth: width || 60,
    getCellProps: () => ({ style: { justifyContent: 'center' } }),
    Cell: ({ row: { original, isSelected, isSelectorDisabled, toggleRowSelected } }) => (
      <Cell
        original={original}
        onToggle={toggleRowSelected}
        checked={isSelected}
        disabled={isSelectorDisabled}
        getTooltip={getTooltip}
      />
    ),
    // If you want to hide checkbox in Aggregated (grouped) view, uncomment this line
    // Aggregated: () => '',
    Filter: BooleanColumnFilter(
      filterLabels ?? {
        true: 'Checked',
        false: 'Unchecked',
      },
    ),
    filter,

    disableGroupBy: true,
    disableAggregators: true,
    disableDuplicateBy: true,
    disableResizing: true,
    disableSortBy: true,
    disableDragAndDrop: true,
    disableContextMenu: true,
    disableHide: true,
    disableTooltip: true,
    disableExport: true,
    isRowSelectorInitialized: true,
  });
};

const allColumns = (columns) => {
  const rowSelector = columns.find(
    (item) => item.id === TechnicalColumns.rowSelector,
  );

  if (rowSelector && !rowSelector.isRowSelectorInitialized) {
    initCheckBoxColumn(rowSelector);
  }

  return columns;
};

export const useRowSelectorColumn = (hooks) => {
  hooks.allColumns.push(allColumns);
};
