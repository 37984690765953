import { lazy } from 'react';

import { WidgetTypes } from '@/models/WidgetTypes';

import { widgets } from '../../shared/widgets';

const AttackSurfaceSeriesFields = lazy(() => import('./series/AttackSurfaceSeriesFields'));
const DefaultSeriesFields = lazy(() => import('./series/DefaultSeriesFields'));
const MarkdownSeriesFields = lazy(() => import('./series/MarkdownSeriesFields'));

Object.keys(widgets).forEach((key) => {
  switch (key) {
    case WidgetTypes.Markdown:
      widgets[key].SeriesFields = MarkdownSeriesFields;
      return;
    case WidgetTypes.AttackSurface:
      widgets[key].SeriesFields = AttackSurfaceSeriesFields;
      return;
    default:
      break;
  }

  widgets[key].SeriesFields = DefaultSeriesFields;
});

export default widgets;
