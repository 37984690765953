import { Link } from 'react-router-dom';

import classNames from 'classnames';
import styled from 'styled-components';

import NotFoundImage from '@/shared/img/404/neto-404-bg2021.svg';

import Button from '+components/Button';

const NotFound404 = styled(({ className }) => (
  <div className={classNames(className, 'not-found')}>
    <img
      className="not-found__image"
      src={NotFoundImage}
      alt="Page Not Found"
    />
    <h3 className="not-found__info">Page Not Found</h3>
    <Button className="not-found__btn" component={Link} to="/">
      Home
    </Button>
  </div>
))`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 106px);
  gap: 20px;

  .not-found__image {
    max-width: 500px;
    width: 100%;
  }

  .not-found__info {
    font-size: 20px;
  }
`;

export default NotFound404;
