import PropTypes from '+prop-types';

import { ContextTypes } from '@/models/ContextTypes';

import { DnsTable } from '+components/ContextTables';
import SearchPage from '+components/SearchPage';

const tableSortBy = [
  {
    id: 'timestamp',
    desc: true,
  },
];

const requestSort = {
  field: 'timestamp',
  order: 'desc',
};

const Table = ({ data = [], ...tail }) => (
  <DnsTable
    {...tail}
    id="Search_DnsSearch_Table"
    tableData={data}
    sortBy={tableSortBy}
    noDataText={tail.loading ? 'Searching...' : undefined}
  />
);

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
};

Table.defaultProps = {
  data: [],
};

const Search = ({ allowNlp }) => (
  <SearchPage
    nqlContext={ContextTypes.dns}
    nqlPlaceholder="query.type == AAAA"
    resultRenderer={Table}
    sort={requestSort}
    allowNlp={allowNlp}
  />
);

Search.propTypes = {
  allowNlp: PropTypes.bool,
};

Search.defaultProps = {
  allowNlp: false,
};

export default Search;
