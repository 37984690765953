import PropTypes from '+prop-types';
import { memo, useMemo } from 'react';

import styled from 'styled-components';

import { canBeExpanded, cellPropsCompare, getProps } from '../utils';
import { Border, Borders, Tr, TrGroup } from './Components';
import RowCell from './RowCell';

const SubComponentContainer = styled.div.attrs(() => ({
  className: 'subcomponent container-fluid',
}))`
  background: rgba(255, 255, 255, 0.025);
  padding: 0;
`;

const BorderMemo = memo(Border, cellPropsCompare);

const Row = (props) => {
  const {
    className,
    row,
    odd,
    getRowProps,
    tableBodyProps,
    SubComponent: SubComponentProp,
    expandSingleSubRow,
    getCellProps,
    style,
    index,
    testId,
  } = props;

  const { key: _, ...rowProps } = useMemo(
    () => row.getRowProps(getProps([getRowProps, { $odd: odd, className }])),
    [row.getRowProps, getRowProps, odd, className],
  );

  const SubComponent = useMemo(
    () => {
      if (!row.isExpanded || !SubComponentProp) {
        return null;
      }
      const column = row.expandedColumnId
      && row.allCells.find((cell) => cell.column.id === row.expandedColumnId)
        ?.column;
      return column?.SubComponent || SubComponentProp;
    },
    [SubComponentProp, row.isExpanded, row.expandedColumnId, row.allCells],
  );

  return !row ? null : (
    <TrGroup {...(tableBodyProps || {})} style={style} data-testid={testId}>
      <Tr {...rowProps}>
        <Borders>
          {row.cells.map((cell) => {
            const cellProps = cell.getCellProps(
              getProps([cell.column.getBorderProps]),
            );
            return <BorderMemo {...cellProps} />;
          })}
        </Borders>

        {row.cells.map((cell) => {
          const { key } = cell.getCellProps();
          const expander = !!(
            SubComponentProp || canBeExpanded(row.subRows, expandSingleSubRow)
          );
          return (
            <RowCell
              key={key}
              cell={cell}
              expander={expander}
              getCellProps={getCellProps}
              getToggleRowExpandedProps={row.getToggleRowExpandedProps}
              renderIndex={index}
            />
          );
        })}
      </Tr>

      {!row.isGrouped && row.isExpanded && SubComponent && (
        <SubComponentContainer>
          <SubComponent {...row} />
        </SubComponentContainer>
      )}
    </TrGroup>
  );
};

Row.propTypes = {
  className: PropTypes.string,
  row: PropTypes.shape().isRequired,
  odd: PropTypes.bool,
  getRowProps: PropTypes.func,
  getCellProps: PropTypes.func,
  tableBodyProps: PropTypes.shape(),
  SubComponent: PropTypes.children,
  expandSingleSubRow: PropTypes.bool,
  style: PropTypes.shape(),
  index: PropTypes.number.isRequired,
  testId: PropTypes.string,
};

Row.defaultProps = {
  className: '',
  odd: false,
  getRowProps: null,
  getCellProps: null,
  tableBodyProps: null,
  SubComponent: null,
  expandSingleSubRow: null,
  style: null,
  testId: null,
};

export default Row;
