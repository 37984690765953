import { forwardRef } from 'react';

import Select, { propTypes, defaultProps } from '+components/form/Select';

/**
 * The MultiSelect is a form component that displays a list of options and allows for multiple selections from this list.
 */
const MultiSelect = forwardRef((props, ref) => (
  <Select
    {...props}
    ref={ref}
    disableCloseOnSelect
    filterSelectedOptions
    multiple
  />
));

MultiSelect.displayName = 'MultiSelect';
MultiSelect.propTypes = propTypes;
MultiSelect.defaultProps = defaultProps;

export default MultiSelect;
