import PropTypes from '+prop-types';

import { ContextTypes } from '@/models/ContextTypes';

import { EventTable } from '+components/ContextTables';
import SearchPage from '+components/SearchPage';

const tableSortBy = [
  {
    id: 'timestamp',
    desc: true,
  },
];

const requestSort = {
  field: 'timestamp',
  order: 'desc',
};

const Table = ({ data = [], ...tail }) => (
  <EventTable
    {...tail}
    id="Search_AlertsSearch_Table"
    data={data}
    sortBy={tableSortBy}
    noDataText={tail.loading ? 'Searching...' : undefined}
  />
);

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
};

Table.defaultProps = {
  data: [],
};

const EventsSearch = () => (
  <SearchPage
    nqlContext={ContextTypes.alerts}
    nqlPlaceholder="categories == iprep"
    resultRenderer={Table}
    sort={requestSort}
  />
);

export default EventsSearch;
