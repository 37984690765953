import styled, { css } from 'styled-components';

import ItemWithIcon from './ItemWithIcon';

export default styled(ItemWithIcon)`
  .topbar__link-title {
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
    color: ${({ theme }) => theme.topbarMenuTextColorSecondary} !important;
  }

  ${(props) => !props.$expandable
    && css`
      pointer-events: none;
      .mdi-icon {
        display: none;
      }
    `}
`;
