import PropTypes from '+prop-types';
import { useMemo } from 'react';

import CellWrapper from './CellWrapper';

// Generic Context Menu for Flow Keys
const CellFlowKey = (props) => {
  const { field, value, original } = props;

  const stream = useMemo(
    () => ({
      srcip: original?.srcip,
      srcport: original?.srcport,
      dstip: original?.dstip,
      dstport: original?.dstport,
      protocol: original?.protocol,
    }),
    [original],
  );

  return (
    <CellWrapper
      customer={original?.customer}
      field={field}
      value={value}
      stream={stream}
    >
      <span>{value}</span>
    </CellWrapper>
  );
};

CellFlowKey.propTypes = {
  field: PropTypes.string.isRequired,
  value: PropTypes.any,
  original: PropTypes.shape(),
};

CellFlowKey.defaultProps = {
  value: undefined,
  original: undefined,
};

export default CellFlowKey;
