import { ContextTypes } from '@/models/ContextTypes';

import { createThemeSlice } from '../util';

const themeSlice = createThemeSlice('contextTypes', (choose) => ({
  [ContextTypes.flow]: '#42a5f5',
  [ContextTypes.dns]: '#f6d874',
  [ContextTypes.alerts]: '#ab47bc',
  [ContextTypes.blocks]: '#fd4862',
  [ContextTypes.traffic]: '#a4dc95',
  trafficTypeColorText: choose({
    light: '#19181B',
    dark: '#16171A',
  }),
}));

export const { selector, selectors, propsSelectors } = themeSlice;
export default themeSlice;
