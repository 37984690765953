import cloneDeep from 'lodash.clonedeep';

const allSubRowsHaveOneElement = (row) => {
  if (row.subRows.length > 1) {
    return false;
  }
  if (!row.subRows.length) {
    return true;
  }
  return allSubRowsHaveOneElement(row.subRows[0]);
};

const getDeepestSubRow = (row) => {
  if (!row.subRows.length) {
    return row;
  }
  return getDeepestSubRow(row.subRows[0]);
};

const consolidateSubRows = (row) => {
  if (allSubRowsHaveOneElement(row)) {
    const deepestSubRow = getDeepestSubRow(row);
    row.original = cloneDeep(deepestSubRow.original);
    row.values = cloneDeep(deepestSubRow.values);
    row.subRows = [];
    row.leafRows = [];
    row.isGrouped = false;
    return row;
  }

  if (row.subRows) {
    return row.subRows.map((subRow) => consolidateSubRows(subRow));
  }

  return row;
};

const useInstance = (instance) => {
  const {
    groupedRows,
    state: { groupBy },
  } = instance;

  if (!groupBy?.length) {
    return;
  }

  Object.assign(instance, {
    groupedRows: groupedRows.map((row) => consolidateSubRows(row)),
  });
};

export const useGroupByConsolidateOneItemsGroups = (hooks) => {
  hooks.useInstance.push(useInstance);
};

useGroupByConsolidateOneItemsGroups.pluginName = 'useGroupByConsolidateOneItemsGroups';
