import { useCallback, useEffect, useRef, useState } from 'react';

import isEqual from 'lodash.isequal';
import isFunction from 'lodash.isfunction';

const useDelayedState = (initialState, delay = 10) => {
  const [state, setState] = useState(initialState);

  const timerRef = useRef();

  const setDelayedState = useCallback(
    (value) => {
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        setState((prev) => {
          const next = isFunction(value) ? value(prev) : value;
          return isEqual(prev, next) ? prev : next;
        });
      }, delay);
    },
    [delay],
  );

  useEffect(
    () => () => {
      clearTimeout(timerRef.current);
    },
    [],
  );

  return [state, setDelayedState];
};

export default useDelayedState;
