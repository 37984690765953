import PropTypes from '+prop-types';
import { useEffect, memo, Fragment } from 'react';

import { cellPropsCompare, getProps } from '../utils';
import {
  THeadHeader as THeadHeaderOrigin,
  THeadHeaderGroup as THeadHeaderGroupOrigin,
  Tr as TrOrigin,
} from './Components';
import HeaderCell, { propTypes, defaultProps } from './HeaderCell';

const THeadHeader = memo(THeadHeaderOrigin, cellPropsCompare);
const THeadHeaderGroup = memo(THeadHeaderGroupOrigin, cellPropsCompare);
const Tr = memo(TrOrigin, cellPropsCompare);

const Header = (props) => {
  const {
    headerGroups,
    getHeaderProps,
    getHeaderGroupProps,
    getFilterProps,
    isResizing,
    isGrouped,
    ...tail
  } = props;

  const [lastHeaderGroup] = headerGroups.slice(-1);

  useEffect(
    () => {
      if (isResizing) {
        document.documentElement.classList.add('resizingOverrideCursor');
      } else {
        document.documentElement.classList.remove('resizingOverrideCursor');
      }
    },
    [isResizing],
  );

  return (
    <Fragment>
      {headerGroups.map((headerGroup) => {
        const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps(
          getProps([getHeaderGroupProps]),
        );
        const Component = headerGroup === lastHeaderGroup ? THeadHeader : THeadHeaderGroup;
        return (
          <Component key={key} style={tail.style}>
            <Tr {...headerGroupProps}>
              {headerGroup.headers.map((column, index) => {
                const draggable = !isResizing && headerGroup === lastHeaderGroup;
                const FilterRenderer = lastHeaderGroup?.headers[index]?.render('Filter');
                const AggregatorRenderer = lastHeaderGroup?.headers[index]?.render('Aggregator');
                return (
                  <HeaderCell
                    key={column.id}
                    index={index}
                    count={headerGroup.headers.length}
                    column={column}
                    draggable={draggable}
                    getHeaderProps={getHeaderProps}
                    FilterRenderer={FilterRenderer}
                    AggregatorRenderer={AggregatorRenderer}
                    isGrouped={isGrouped}
                    {...tail}
                  />
                );
              })}
            </Tr>
          </Component>
        );
      })}
    </Fragment>
  );
};

Header.propTypes = {
  ...propTypes,
  headerGroups: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getHeaderProps: PropTypes.func,
  getHeaderGroupProps: PropTypes.func,
  getFilterProps: PropTypes.func,
  isResizing: PropTypes.bool,
  isGrouped: PropTypes.bool,
};

Header.defaultProps = {
  ...defaultProps,
  getHeaderProps: null,
  getHeaderGroupProps: null,
  getFilterProps: null,
  isResizing: false,
  isGrouped: false,
};

export default Header;
