/* eslint-disable max-len */
import PropTypes from '+prop-types';
import { memo } from 'react';

const AwsKinesisIcon = memo(({ size }) => (
  <svg
    width={size}
    height={size}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
  >
    <path d="M35.5 51.895l26.526-12.2-26.526-1.1-26.526 1.1z" fill="#fcbf91" />
    <path d="M8.965 17.207v18.72h31.903V6.616L35.6 3.96z" fill="#9d5125" />
    <path
      d="M35.6 67.96l26.526-18.818-26.6-3.887-26.56 3.887z"
      fill="#fcbf91"
    />
    <g fill="#9d5125">
      <path d="M35.6 17.24v18.688h14.024v-19.95L45.5 14.324z" />
      <path d="M45.5 23.36v12.567h10.85V22.972l-3.1-.907z" />
      <path d="M53.24 21.03v14.9h8.777l.097-14.964-2.656-1.07z" />
    </g>
    <path
      d="M40.868 35.928V6.616L35.6 3.96v31.968zm8.745 0v-19.95L45.5 14.324v21.603zm6.737 0V22.972l-3.1-.907v13.862zm3.1 0V19.895l2.656 1.07V35.93zm2.568 3.757v5.603L35.5 51.895V42.34zm.097 14.963v-5.506L35.5 58.535v9.425z"
      fill="#f58532"
    />
    <path
      d="M8.965 54.713v-5.57L35.5 58.47v9.5zm0-9.425v-5.603L35.5 42.34v9.555z"
      fill="#9d5125"
    />
  </svg>
));

AwsKinesisIcon.propTypes = {
  size: PropTypes.number,
};

AwsKinesisIcon.defaultProps = {
  size: 24,
};

export { AwsKinesisIcon };
