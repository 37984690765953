import { color as d3color } from 'd3-color';
import * as PIXI from 'pixi.js-legacy';

const colorHash = {};

/**
 * Converts a color string to number in hexadecimal
 * @param {string} color - color string, e.g. red, #ffffff, rgb(0, 0, 0), etc.
 * @returns {number}
 *
 * @example
 * colorStringToHex('hsl(0, 100%, 50%)'); // 0xff0000
 */
export const colorStringToHex = (color) => {
  const fixedColor = color || '#000';

  if (colorHash[fixedColor]) {
    return colorHash[fixedColor];
  }

  const hex = PIXI.utils.string2hex(d3color(fixedColor).formatHex());
  colorHash[color] = hex;
  return hex;
};
