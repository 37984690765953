import PropTypes from '+prop-types';
import { Fragment, useMemo } from 'react';

import classNames from 'classnames';

import MenuContent, { propTypes, defaultProps } from './components/MenuContent';
import Trigger from './components/Trigger';

const ContextMenu = (props) => {
  const {
    className,
    text,
    style,
    items,
    title,
    useSingleItemAsTrigger,
    dataTracking,
    ...rest
  } = props;

  const showFirstItemAsTrigger = useMemo(
    () => useSingleItemAsTrigger
      && (items || []).filter((el) => !el.separator).length === 1,
    [useSingleItemAsTrigger, items],
  );

  const MenuContainer = useMemo(
    () => (showFirstItemAsTrigger ? Fragment : Trigger),
    [showFirstItemAsTrigger],
  );

  const menuContainerProps = useMemo(
    () => (showFirstItemAsTrigger
      ? {}
      : {
        className: classNames(className, 'menu-trigger'),
        style,
        text,
        disabled: (items || []).length === 0,
        dataTracking: `${dataTracking}-row-menu`,
        testId: 'menu-trigger',
      }),
    [className, showFirstItemAsTrigger, style, text, items, dataTracking],
  );

  return (
    <MenuContainer {...menuContainerProps}>
      <MenuContent
        {...rest}
        items={items}
        showFirstItemAsTrigger={showFirstItemAsTrigger}
      />
    </MenuContainer>
  );
};

ContextMenu.displayName = 'ContextMenu';

ContextMenu.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  style: PropTypes.shape({}),
  /**
   * If true and menu has only one item then icon of
   * this item will be displayed and called instead trigger
   */
  useSingleItemAsTrigger: PropTypes.bool,
  dataTracking: PropTypes.string,
  ...propTypes,
};

ContextMenu.defaultProps = {
  text: '',
  style: null,
  useSingleItemAsTrigger: false,
  dataTracking: 'undefined',
  ...defaultProps,
};

export { MenuContent };

export default ContextMenu;
