import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: ${(props) => props.$width + props.$separatorWidth}px;
  height: 100%;
  background-color: ${({ theme }) => theme.colorBackground};
  transition: width 0.3s ease-in-out 0.1s;
  overflow-y: ${({ $collapse }) => ($collapse ? null : 'auto')};
  border-right: ${({ $separatorWidth }) => $separatorWidth}px solid
    ${({ theme }) => theme.colorBackgroundSeparator};
`;
