import PropTypes from '+prop-types';
import { Fragment } from 'react';

import { ContextTypes } from '@/models/ContextTypes';

import {
  getPropertiesList,
  SubDataColumn,
  SubDataRow,
  SubDataValue,
  SubDetails,
} from '+components/SubDetails';
import UniversalField from '+components/UniversalField';

export { getRowHeight } from '+components/SubDetails';

const overview = [
  {
    field: 'id',
    label: 'Event ID',
  },
  {
    field: 'severity',
    label: 'severity',
  },
  {
    field: 'summary',
    label: 'summary',
  },
  {
    field: 'alerttype',
    label: 'alerttype',
  },
  {
    field: 'start',
    label: 'start',
  },
  {
    field: 'end',
    label: 'end',
  },
  {
    field: 'duration',
    label: 'duration',
  },
];

const traffic = [
  {
    field: 'traffic_type',
    label: 'Type',
  },
  {
    field: 'flowsrcnames',
    label: 'Sources',
  },
  {
    field: 'srcipcount',
    label: '# of SRC IPs',
  },
  {
    field: 'srcportcount',
    label: '# of SRC ports',
  },
  {
    field: 'dstipcount',
    label: '# of DST IPs',
  },
  {
    field: 'dstportcount',
    label: '# of DST ports',
  },
  {
    field: 'input',
    label: 'input',
  },
];

const detections = [
  {
    field: 'tdm',
    label: 'Model',
  },
  {
    field: 'rules',
    label: 'Response Policies',
  },
];

const DataBody = ({ record }) => (
  <Fragment>
    <SubDataColumn title="Overview">
      {getPropertiesList(record, overview)}
    </SubDataColumn>
    <SubDataColumn title="Traffic">
      {getPropertiesList(record, traffic)}
    </SubDataColumn>
    <SubDataColumn title="IP Overview">
      {(record.srcips || []).map((ip) => (
        <SubDataRow key={ip}>
          <SubDataRow>srcip:</SubDataRow>
          <SubDataValue>
            <UniversalField field="srcip" value={ip} original={record} />
          </SubDataValue>
        </SubDataRow>
      ))}
      {(record.dstips || []).map((ip) => (
        <SubDataRow key={ip}>
          <SubDataRow>dstip:</SubDataRow>
          <SubDataValue>
            <UniversalField field="dstip" value={ip} original={record} />
          </SubDataValue>
        </SubDataRow>
      ))}
    </SubDataColumn>
    <SubDataColumn title="Detections">
      {getPropertiesList(record, detections)}
      <SubDataRow>
        <SubDataRow>Integrations:</SubDataRow>
        <SubDataValue>
          <UniversalField
            field="rules.plugins"
            value={(record.rules || []).flatMap((rule) => rule.plugins)}
            original={record}
          />
        </SubDataValue>
      </SubDataRow>
    </SubDataColumn>
  </Fragment>
);

DataBody.propTypes = {
  record: PropTypes.shape().isRequired,
};

const SubEventDetails = (props) => (
  <SubDetails
    context={ContextTypes.alerts}
    rowData={props}
    Component={DataBody}
  />
);

export default SubEventDetails;
