import { useCallback, useMemo, useRef } from 'react';

import usePortalSettings from '+hooks/usePortalSettings';
import useUIProperty from '+hooks/useUIProperty';

/**
 * @param category
 * @param property
 * @param [defaultValue]
 * @return {[*, Function]}
 */
export default (category, property, defaultValue = null) => {
  const [guest] = useUIProperty('guest');

  const [settings, save, remove] = usePortalSettings();
  const value = useRef();

  const memorizedDefaultValue = useMemo(
    () => defaultValue,
    [JSON.stringify(defaultValue)],
  );

  const selector = useCallback(
    (data) => data?.[category]?.[property],
    [category, property],
  );

  const change = useCallback(
    // do not set newCategory and newProperty props if you want to keep the current category and property
    // use it in very rare cases
    (newValue, isDefault, newCategory, newProperty) => {
      if (guest) {
        return;
      }

      if (value.current == null && newValue == null) {
        return;
      }

      const setting = {
        ...value.current,
        category: newCategory || category,
        property: newProperty || property,
        value: newValue,
      };

      if (newValue == null) {
        remove(setting);
      } else {
        save(setting, !!isDefault);
      }
    },
    [category, property, guest],
  );

  value.current = selector(settings);

  return [
    value.current?.value ?? memorizedDefaultValue,
    change,
    settings?.isFetching,
  ];
};
