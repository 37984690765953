import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectors, actions } from '@/redux/api/netclass';

import { Row, Col } from '+components/Layout';
import useLoadingIndicator from '+hooks/useLoadingIndicator';

import NetClassForm from './NetClassForm';

const NetworkClassifications = () => {
  const dispatch = useDispatch();
  const { netclasses, isFetching } = useSelector(selectors.getState);

  useLoadingIndicator(isFetching);

  useEffect(
    () => {
      dispatch(actions.fetchNetclasses());
    },
    [],
  );

  return (
    <Row>
      <Col xl={10}>
        <NetClassForm
          netclassType="internal"
          initialValues={netclasses.internal}
          isFetching={isFetching}
        />
      </Col>
    </Row>
  );
};

export default NetworkClassifications;
