import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectors as customerSelectors } from '@/redux/api/customer';
import { actions, selectors } from '@/redux/api/labels/ips';

import makeArr from '+utils/makeArr';

/**
 * Returns ip labels.
 * @param {Object?} options
 * @param {boolean?} options.fetchAll
 * @param {string|string[]?} options.fetchExact
 * @return {Object}
 */
export const useIpLabels = (options) => {
  const { fetchAll = false, fetchExact = null } = options || {};

  const dispatch = useDispatch();

  const customer = useSelector(customerSelectors.getCurrentCustomer);
  const ipLabels = useSelector(selectors.getIpLabels);
  const ipLabelsHash = useSelector(selectors.getIpLabelsHash);
  const ipLabelsIndexes = useSelector(selectors.getIpLabelsIndexes);
  const isFetchingIpLabels = useSelector(selectors.isFetching);

  useEffect(
    () => {
      const isSubAccountRequest = options?.customer && options?.customer !== customer?.shortname;
      const fetchExactArr = makeArr(fetchExact).filter(Boolean);
      fetchExactArr.forEach((ip) => {
        if (isSubAccountRequest) {
          const namespace = `useIpLabels - ${ip} - ${options?.customer}`;
          dispatch(
            actions.fetchIpLabelsByIp(
              { ip, customer: options?.customer },
              namespace,
            ),
          );
        } else {
          const namespace = `useIpLabels - ${ip}`;
          dispatch(actions.fetchIpLabelsByIp({ ip }, namespace));
        }
      });
    },
    [fetchExact, options?.customer, customer?.shortname],
  );

  useEffect(
    () => {
      if (!fetchAll) {
        return undefined;
      }
      const namespace = 'useIpLabels';
      dispatch(actions.fetchIpLabels(null, namespace));
      return () => {
        dispatch(actions.cancel(namespace));
      };
    },
    [fetchAll],
  );

  return { ipLabels, ipLabelsHash, ipLabelsIndexes, isFetchingIpLabels };
};

export default useIpLabels;
