import PropTypes from '+prop-types';

import Field from '+components/form/FinalForm/Field';
import Group from '+components/form/FormField/components/Group';
import Label from '+components/form/FormField/components/Label';
import Plaintext from '+components/form/Plaintext';
import TextField from '+components/form/TextField';
import { validateRequired } from '+components/form/Validators';
import FormModal from '+components/FormModal';

const CategoryForm = (props) => {
  const {
    mode,
    initialValues,
    callingApi,
    toggleModal,
    successCallback,
    isOpen,
    disabled,
    ...tail
  } = props;

  return (
    <FormModal
      {...tail}
      mode={mode}
      item="category"
      isOpen={isOpen}
      initialValues={initialValues}
      loading={callingApi}
      disabled={disabled || callingApi}
      onSubmit={successCallback}
      onToggle={toggleModal}
    >
      <Field
        name="adapter"
        component={TextField}
        type="hidden"
        disabled={disabled}
      />

      {mode === 'edit' && (
        <Group>
          <Label>Name</Label>
          <Plaintext>{initialValues.name}</Plaintext>
        </Group>
      )}

      {mode === 'add' && (
        <Field
          name="name"
          label="Category"
          component={TextField}
          type="text"
          maxLength={96}
          autoComplete="new-password"
          validate={validateRequired}
          disabled={disabled}
          required
        />
      )}

      <Field
        name="description"
        label="Description"
        component={TextField}
        type="text"
        maxLength={255}
        autoComplete="new-password"
        disabled={disabled}
      />
    </FormModal>
  );
};

CategoryForm.propTypes = {
  mode: PropTypes.oneOf(['add', 'edit']),
  isOpen: PropTypes.bool,
  initialValues: PropTypes.shape({
    name: PropTypes.string,
  }),
  callingApi: PropTypes.bool,
  successCallback: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

CategoryForm.defaultProps = {
  mode: 'add',
  isOpen: false,
  initialValues: {},
  callingApi: false,
  disabled: false,
};

export default CategoryForm;
