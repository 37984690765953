import classNames from 'classnames';
import styled from 'styled-components';

const AlertTypeLabel = styled(({ className, alerttype, active }) => (
  <div className={classNames(className, alerttype, { active: !!active })}>
    <span className="text">{alerttype}</span>
    {/* <span className="icon">&#11044;</span> */}
  </div>
))`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 4px;

  // &.start.active {
  //   .icon {
  //     color: ${({ theme }) => theme.alertTypeStartLabelColor} !important;
  //   }
  // }
  //
  // &.ongoing.active {
  //   .icon {
  //     color: ${({ theme }) => theme.alertTypeOngoingLabelColor} !important;
  //   }
  // }
  //
  // &.end.active {
  //   .icon {
  //     color: ${({ theme }) => theme.alertTypeEndLabelColor} !important;
  //   }
  // }

  .text {
    text-transform: uppercase;
  }
`;

export default AlertTypeLabel;
