import L from 'leaflet';

export class Canvas extends L.Layer {
  onAdd(map) {
    this._map = map;
    this._canvas = document.createElement('canvas');
    this._canvas.innerText = 'This browser does not support Canvas';
    this._ctx = this._canvas.getContext('2d');

    this.calcSize();

    const animated = this._map.options.zoomAnimation && L.Browser.any3d;
    L.DomUtil.addClass(this._canvas, 'leaflet-layer');
    L.DomUtil.addClass(
      this._canvas,
      `leaflet-zoom-${animated ? 'animated' : 'hide'}`,
    );

    map.getPanes().overlayPane.appendChild(this._canvas);

    this._translate = [0, 0];

    return this;
  }

  onRemove(map) {
    map.getPanes().overlayPane.removeChild(this._canvas);

    this._canvas = null;
    return this;
  }

  calcSize() {
    this._size = this._map.getSize();
    this._width = this._size.x;
    this._height = this._size.y;

    if (this._canvas) {
      this._canvas.width = this._size.x;
      this._canvas.height = this._size.y;
    }
  }

  move() {
    const topLeft = this._map.containerPointToLayerPoint([0, 0]);
    L.DomUtil.setTransform(this._canvas, topLeft);
    this._translate = [-topLeft.x, -topLeft.y];
  }

  hide() {
    L.DomUtil.addClass(this._canvas, 'canvas-hidden');
  }

  show() {
    L.DomUtil.removeClass(this._canvas, 'canvas-hidden');
  }

  get canvas() {
    return this._canvas;
  }

  get ctx() {
    return this._ctx;
  }

  get width() {
    return this._width;
  }

  get height() {
    return this._height;
  }
}
