import styled from 'styled-components';

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;

  .form__form-group {
    margin-bottom: unset;
    width: unset;
  }
`;

export default FormContainer;
