import { Dispatcher } from '../models/Dispatcher';
import { Canvas } from './Canvas';

export class CanvasLayer extends Dispatcher {
  constructor(map, { render } = {}) {
    super();

    this._map = map;
    this._data = [];

    this._options = {
      clearDirtyAlpha: true,
      alpha: 0.9,
      globalCompositeOperation: 'source-over',
      ...render,
    };

    this._canvas = new Canvas();
    this._canvas.addTo(this._map);

    this._canvasHidden = new Canvas();
    this._canvasHidden.addTo(this._map);
    this._canvasHidden.hide();

    this._draw = this._draw.bind(this);
    this._draw();
  }

  _clearDirtyAlpha() {
    const { ctx } = this._canvasHidden;

    ctx.globalAlpha = this._options.alpha || 0.9;
    // if (!Math.trunc(performance.now() % 1000)) {
    //   ctx.globalAlpha = 0;
    // }
  }

  _draw() {
    if (this._destroyed) {
      return;
    }

    const { canvas, ctx, width, height } = this._canvas;
    const { canvas: canvasHidden, ctx: ctxHidden } = this._canvasHidden;
    const { clearDirtyAlpha, clearAlpha, alpha } = this._options;

    const needDraw = this._needRedraw();

    if (needDraw) {
      this._clearSteps = 100;
    } else {
      this._clearSteps -= 1;
    }

    if (needDraw || this._clearSteps > 0) {
      if (clearDirtyAlpha) {
        ctxHidden.filter = `opacity(${alpha * 100}%)`;
        this._clearDirtyAlpha();

        ctxHidden.clearRect(0, 0, width, height);
        ctxHidden.drawImage(canvas, 0, 0);
        ctx.clearRect(0, 0, width, height);
        ctx.drawImage(canvasHidden, 0, 0);
      } else {
        ctx.globalCompositeOperation = 'destination-out';
        ctx.fillStyle = `rgba(0, 0, 0, ${clearAlpha || 0.15})`;
        ctx.fillRect(0, 0, width, height);
      }
    }

    ctx.globalCompositeOperation = this._options.globalCompositeOperation;

    this._doDraw();
  }

  // eslint-disable-next-line class-methods-use-this
  _doDraw() {
    // need to implement'
  }

  // eslint-disable-next-line class-methods-use-this
  _doDestroy() {
    // need to implement
  }

  // eslint-disable-next-line class-methods-use-this
  _needRedraw() {
    return true;
  }

  calcCanvasSize() {
    this._canvas.calcSize();
    this._canvasHidden.calcSize();
  }

  moveCanvas() {
    this._canvas.move();
    this._canvasHidden.move();
  }

  clearCanvas() {
    const { ctx, width, height } = this._canvas;
    const { ctx: ctxHidden } = this._canvasHidden;

    ctx.clearRect(0, 0, width, height);
    ctxHidden.clearRect(0, 0, width, height);
  }

  showCanvas() {
    this._canvas.show();
  }

  hideCanvas() {
    this._canvas.hide();
  }

  destroy() {
    if (this._destroyed) {
      return;
    }

    this._destroyed = true;
    this._doDestroy();
  }
}
