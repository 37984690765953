import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px 0 5px 40px;
  
  .profile__role {
    margin-top: 15px;
  }
  
  .profile__email {
    margin-top: 5px;
  }
  
  .profile__blocked-label {
    margin-top: 5px;
  }
  
  .avatar {
    margin-bottom: 15px;
  }
`;
