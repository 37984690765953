import { Fragment } from 'react';

import get from 'lodash.get';

import UniversalField from '+components/UniversalField';

import { SubDataProperty } from './components/SubDataProperty';
import { SubDataRow } from './components/SubDataRow';
import { SubDataValue } from './components/SubDataValue';

/**
 * @typedef {object} Property
 * @property {string} label - The label of the property
 * @property {string} field - The field of the property, can be a dot notation
 */

/**
 * @param {object} record
 * @param {Property[]} properties
 * @returns {JSX.Element}
 */
export const getPropertiesList = (record, properties) => (
  <Fragment>
    {properties.map((property) => (
      <SubDataRow key={property.label}>
        <SubDataProperty>{property.label}:</SubDataProperty>
        <SubDataValue>
          <UniversalField
            field={property.field}
            original={record}
            value={get(record, property.field)}
          />
        </SubDataValue>
      </SubDataRow>
    ))}
  </Fragment>
);
