import { PluginTypes } from './PluginTypes';

/**
 * Context integrations
 * Alphabetical order by title, not adapter
 */
export const ContextIntegrations = {
  azure: {
    title: 'Microsoft Azure',
  },
  aws: {
    title: 'Amazon Web Services',
  },
  crowdstrike: {
    title: 'CrowdStrike Falcon Protect',
  },
  crowdstrikediscover: {
    title: 'CrowdStrike Falcon Discover',
  },
  gcp: {
    title: 'Google Cloud Provider',
  },
  ibm: {
    title: 'IBM Cloud',
  },
  oracle: {
    title: 'Oracle Cloud Infrastructure',
  },
  sentinelone: {
    title: 'SentinelOne',
  },
  s3: {
    title: 'CSV via S3',
  },
  wiz: {
    title: 'Wiz',
    minInterval: 86400, // minimum interval for Wiz is restricted to 24 hours
    disabled: false,
  },
};

Object.entries(ContextIntegrations).forEach(([key, value]) => {
  value.name = key;
  value.type = PluginTypes.context;
  value.toString = () => value.name;
});
