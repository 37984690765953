import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions as searchActions, selectors as searchSelectors } from '@/redux/api/search';

/**
 * Hook for search requests meta (fields).
 *
 * @param {Object} params
 * @param {boolean} params.fields - if true, fields will be fetched
 * @param {string} params.context
 *
 * @return {{fields: Object}}
 */
export const useSearchMeta = (params) => {
  const { fields: fetchFields, context } = params;

  const dispatch = useDispatch();

  const fields = useSelector(searchSelectors.getFields(context));

  const fieldsLength = fields?.length;
  useEffect(
    () => {
      if (fetchFields && !fieldsLength) {
        dispatch(searchActions.fetchFields(context));
      }
    },
    [fetchFields, fieldsLength, context],
  );

  return { fields };
};

export default useSearchMeta;
