import styled from 'styled-components';

export default styled.div`
  display: flex;
  gap: 40px;

  .wizard {
    width: 720px;
    min-width: 720px;
    max-width: 720px;
  }

  .wizard__left-panel {
    width: 100%;
  }
`;
