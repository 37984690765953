import { ContextTypes } from '@/models/ContextTypes';
import RoutePaths from '@/models/RoutePaths';

import { makeId } from '+utils/general';

export const getSearchUrl = ({ context, from, to, nql, customer }) => {
  const search = new URLSearchParams();
  if (from) {
    search.append('from', from);
  }
  if (to) {
    search.append('to', to);
  }
  if (nql) {
    search.append('nql', nql);
  }
  if (customer) {
    search.append('customers', customer);
  }
  search.append('reqid', makeId());
  switch (context) {
    case ContextTypes.flow:
      return `${RoutePaths.searchFlow}?${search.toString()}`;
    case ContextTypes.dns:
      return `${RoutePaths.searchDns}?${search.toString()}`;
    case ContextTypes.alerts:
      return `${RoutePaths.searchEvents}?${search.toString()}`;
    case ContextTypes.blocks:
      return `${RoutePaths.searchBlocks}?${search.toString()}`;
    case ContextTypes.audit:
      return `${RoutePaths.searchAuditLogs}?${search.toString()}`;
    case ContextTypes.traffic:
      return `${RoutePaths.searchTraffic}?${search.toString()}`;
    default:
      return null;
  }
};
