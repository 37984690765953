import PropTypes from '+prop-types';

import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  margin-bottom: 20px;

  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }
`;

const Filter = styled.div`
  display: flex;
  align-items: center;

  span {
    color: ${({ theme }) => theme.colorText} !important;
  }

  a,
  button {
    color: ${({ theme }) => theme.colorTextSecondary} !important;
    border: 1px solid ${({ theme }) => theme.colorTextSecondary};
    border-radius: 3px;
    font-size: 80%;
    font-weight: 600;
    text-transform: uppercase;
    padding: 0 10px;
    margin-left: 8px;
    display: inline;
    cursor: pointer;
    background-color: transparent;
    height: 20px;

    span {
      color: ${({ theme }) => theme.colorTextSecondary} !important;
      font-size: 70%;
      margin-left: 5px;
      position: relative;
      top: -1px;
    }
  }
`;

const Filters = (props) => {
  const {
    algorithm,
    ip,
    time,
    show,
    onAlgorithmClick,
    onTimeClick,
    onIpClick,
    disabled,
  } = props;

  return !show ? null : (
    <Container disabled={disabled}>
      <span>Filter:</span>{' '}
      <Filter>
        {algorithm && (
          <button type="button" onClick={onAlgorithmClick}>
            {algorithm} <span>x</span>
          </button>
        )}
        {ip && (
          <button type="button" onClick={onIpClick}>
            {ip} <span>x</span>
          </button>
        )}
        {time && (
          <button type="button" onClick={onTimeClick}>
            {time} <span>x</span>
          </button>
        )}
      </Filter>
    </Container>
  );
};

Filters.propTypes = {
  algorithm: PropTypes.string,
  ip: PropTypes.string,
  time: PropTypes.string,
  show: PropTypes.bool,
  onAlgorithmClick: PropTypes.func,
  onTimeClick: PropTypes.func,
  onIpClick: PropTypes.func,
  disabled: PropTypes.bool,
};

Filters.defaultProps = {
  algorithm: null,
  ip: null,
  time: null,
  show: false,
  onAlgorithmClick: null,
  onTimeClick: null,
  onIpClick: null,
  disabled: false,
};

export default Filters;
