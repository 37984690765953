import PropTypes from '+prop-types';
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useToggle } from 'react-use';

import { ContextTypes } from '@/models/ContextTypes';
import PermissionModel from '@/models/Permission';
import RoutePaths from '@/models/RoutePaths';
import SettingCategories from '@/models/SettingCategories';
import StatsRequest from '@/models/StatsRequest';
import { TimeDuration } from '@/models/TimePeriods';

import {
  actions as dashboardsActions,
  selectors as dashboardsSelectors,
} from '@/redux/api/dashboards';
import {
  actions as ipLabelsActions,
  selectors as ipLabelsSelectors,
} from '@/redux/api/labels/ips';
import {
  actions as usersActions,
  selectors as usersSelectors,
} from '@/redux/api/user';
import { selectors as profileSelectors } from '@/redux/api/user/profile';
import { selectors as globalFiltersSelectors } from '@/redux/globalFilters';

import { Breadcrumb } from '+components/Breadcrumb';
import Button from '+components/Button';
import ConfirmModal from '+components/ConfirmModal';
import ButtonGroupField from '+components/form/ButtonGroupField';
import {
  Field,
  FORM_ERROR,
  FormSpy,
  useFormState,
} from '+components/form/FinalForm';
import { FieldContainer, Group, Label } from '+components/form/FormField';
import MultiSelectField from '+components/form/MultiSelectField';
import {
  normalizeMultiSelectValue,
  normalizeNumber,
  normalizeSelectValue,
} from '+components/form/Normalizers';
import NqlTextField from '+components/form/NqlTextField';
import SelectField from '+components/form/SelectField';
import TextField from '+components/form/TextField';
import { validateEmails, validateRequired } from '+components/form/Validators';
import FormWizard, { Step } from '+components/FormWizard';
import { ActionsContainer } from '+components/Layout';
import { useAllMetricsAndFields, useVerifyNqlBeforeSend } from '+hooks';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import usePermissions from '+hooks/usePermissions';
import usePortalSettingsValue from '+hooks/usePortalSettingsValue';
import dayjs from '+utils/dayjs';
import getIntersectFieldName from '+utils/getIntersectFieldName';
import getMetricFieldName from '+utils/getMetricFieldName';
import getNqlFieldName from '+utils/getNqlFieldName';

import FieldsRow from './components/FieldsRow';
import FieldsSection from './components/FieldsSection';
import {
  contextOptions,
  dashboardPeriodsOptions,
  formatCronToDayOfMonth,
  formatCronToDayOfWeek,
  formatCronToFrequency,
  formatCronToTime,
  formatLabelContext,
  parseDayOfMonthToCron,
  parseDayOfWeekToCron,
  parseFrequencyToCron,
  parseLabelContext,
  parseTimeToCron,
  schedulePeriodOptions,
  schedulePeriods,
  timeInterval,
} from './components/utils';

const FormBody = ({ disabled }) => {
  const dispatch = useDispatch();

  const users = useSelector(usersSelectors.getUsers);

  const [isTimeFormat12h] = usePortalSettingsValue(
    SettingCategories.ui,
    'isTimeFormat12h',
    false,
  );
  const timeFormat = isTimeFormat12h ? 'hh:mm A' : 'HH:mm';

  const { values: formValues } = useFormState({
    subscription: { values: true },
  });

  const maxNqlQueries = StatsRequest.StatsConfig[
    formValues.globalFilters?.context ?? ContextTypes.flow
  ]?.maxNqlQueries ?? 1;

  const { validateNql } = useVerifyNqlBeforeSend(
    formValues.globalFilters?.context,
    formValues.globalFilters?.context,
  );

  const nqlDocTemplateConfig = useMemo(
    () => ({ showIntersectable: maxNqlQueries > 1 }),
    [maxNqlQueries],
  );

  const { allMetrics } = useAllMetricsAndFields(
    formValues.globalFilters?.context,
    { metrics: true },
  );

  const metricsOptions = useMemo(
    () => (allMetrics[formValues.globalFilters?.context] || []).map((el) => ({
      value: el.metric || el.field,
      label: el.metric || el.field,
      description: el.description,
    })),
    [allMetrics, formValues.globalFilters?.context],
  );

  const labelContexts = useSelector(ipLabelsSelectors.getContexts);
  useEffect(
    () => {
      if (!labelContexts?.length) {
        dispatch(ipLabelsActions.fetchContexts());
      }
    },
    [labelContexts?.length],
  );
  const labelContextsOptions = useMemo(
    () => {
      const options = (labelContexts || [])
        .reduce((acc, item) => {
          if (!item.alias && item.value !== 'name') {
            acc.push({ value: item.value, label: item.value });
          }
          return acc;
        }, [])
        .sort((a, b) => a.value.localeCompare(b.value));
      options.unshift(
        ...[
          { value: false, label: 'None' },
          { separator: true },
          { value: 'name', label: 'name' },
        ],
      );
      return options;
    },
    [labelContexts],
  );

  const dayOfWeekOptions = useMemo(
    () => {
      const options = [];
      let start = dayjs().startOf('week');
      const end = dayjs().startOf('week').add(7, 'day');
      while (start.isBefore(end)) {
        options.push({
          value: `${start.day()}`,
          label: start.format('dddd'),
        });
        start = start.add(1, 'day');
      }
      return options;
    },
    [],
  );

  const daysOfMonthOptions = useMemo(
    () => {
      const options = [];
      let start = dayjs().startOf('month');
      const end = dayjs().startOf('month').add(1, 'month');
      while (start.isBefore(end)) {
        options.push({
          value: `${start.date()}`,
          label: `${start.format('Do')} day of month`,
        });
        start = start.add(1, 'day');
      }
      return options;
    },
    [],
  );

  const timeOptions = useMemo(
    () => {
      const options = [];
      let start = dayjs().startOf('day');
      const end = dayjs().startOf('day').add(1, 'day');
      while (start.isBefore(end)) {
        options.push({
          value: start.format(timeFormat),
          label: start.format(timeFormat),
        });
        start = start.add(timeInterval, 'minute');
      }
      return options;
    },
    [timeFormat],
  );

  const recipientsOptions = useMemo(
    () => Object.values(users || {}).map(({ email, name }) => ({
      value: email,
      label: email,
      description: name,
    })),
    [users],
  );

  useEffect(
    () => {
      if (!users?.length) {
        dispatch(usersActions.requestUsers());
      }
    },
    [users?.length],
  );

  return (
    <Fragment>
      <FieldsSection label="Schedule Settings">
        <Fragment>
          <Field
            name="name"
            component={TextField}
            label="Name"
            validate={[validateRequired]}
            disabled={disabled}
            required
          />

          <FieldsRow>
            <Label>Frequency</Label>

            <FieldsRow
              className="form--label-on-top"
              style={{ width: 'calc(100% - 140px)' }}
            >
              <Field
                name="cron"
                component={SelectField}
                label="Repeat"
                options={schedulePeriodOptions}
                format={formatCronToFrequency}
                parse={({ value }) => parseFrequencyToCron(formValues.cron, value)}
                validate={[validateRequired]}
                disabled={disabled}
                required
              />

              {formatCronToFrequency(formValues.cron)
                === schedulePeriods.weekly && (
                <Field
                  name="cron"
                  component={SelectField}
                  label="On"
                  options={dayOfWeekOptions}
                  format={formatCronToDayOfWeek}
                  parse={({ value }) => parseDayOfWeekToCron(formValues.cron, value)}
                  validate={[validateRequired]}
                  disabled={disabled}
                  required
                />
              )}

              {formatCronToFrequency(formValues.cron)
                === schedulePeriods.monthly && (
                <Field
                  name="cron"
                  component={SelectField}
                  label="On"
                  options={daysOfMonthOptions}
                  format={formatCronToDayOfMonth}
                  parse={({ value }) => parseDayOfMonthToCron(formValues.cron, value)}
                  validate={[validateRequired]}
                  disabled={disabled}
                  required
                />
              )}

              <Field
                name="cron"
                component={SelectField}
                label="At"
                options={timeOptions}
                format={(value) => formatCronToTime(value, timeFormat)}
                parse={({ value }) => parseTimeToCron(formValues.cron, value)}
                validate={[validateRequired]}
                disabled={disabled}
                required
              />
            </FieldsRow>
          </FieldsRow>

          <Field
            name="recipients"
            component={MultiSelectField}
            label="Recipients"
            options={recipientsOptions}
            parse={normalizeMultiSelectValue}
            validate={[validateRequired, validateEmails]}
            disabled={disabled}
            required
            allowCreate
          />
        </Fragment>
      </FieldsSection>

      <FieldsSection label="Dashboard Settings">
        <FieldsRow>
          <Field
            name="globalFilters.period.value"
            component={TextField}
            label="Time Frame"
            type="number"
            min={1}
            validate={validateRequired}
            parse={normalizeNumber}
            disabled={disabled}
            required
          />
          <Field
            name="globalFilters.period.type"
            component={SelectField}
            options={dashboardPeriodsOptions}
            validate={validateRequired}
            parse={normalizeSelectValue}
            disabled={disabled}
            required
          />
        </FieldsRow>

        <Field
          component={SelectField}
          name="globalFilters.labelContext"
          label="Display Labels"
          options={labelContextsOptions}
          validate={validateRequired}
          format={formatLabelContext}
          parse={({ value }) => parseLabelContext(formValues.globalFilters?.labelContext, value)}
          disabled={disabled}
          required
        />

        <Field
          name="globalFilters.context"
          label=" "
          component={ButtonGroupField}
          options={contextOptions}
          size="small"
          validate={validateRequired}
          isEqual={() => true}
          disabled={disabled}
        />

        <Group>
          <Label>NQL</Label>
          <FieldContainer>
            <Field
              name={`globalFilters.${getNqlFieldName(
                formValues.globalFilters?.context ?? ContextTypes.flow,
              )}[0]`}
              component={NqlTextField}
              placeholder=""
              context={formValues.globalFilters?.context ?? ContextTypes.flow}
              maxLength={maxNqlQueries}
              docTemplateConfig={nqlDocTemplateConfig}
              validate={validateNql}
              parse={(v) => v}
              disabled={disabled}
            />
          </FieldContainer>
        </Group>

        <Field
          name={`globalFilters.${getMetricFieldName(
            formValues.globalFilters?.context ?? ContextTypes.flow,
          )}`}
          label="Metric"
          component={SelectField}
          options={metricsOptions}
          validate={validateRequired}
          parse={normalizeSelectValue}
          disabled={disabled}
          required
        />
      </FieldsSection>
    </Fragment>
  );
};

FormBody.propTypes = {
  disabled: PropTypes.bool,
};

FormBody.defaultProps = {
  disabled: false,
};

const DashboardScheduleForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const error = useSelector(dashboardsSelectors.getError);
  const isFetching = useSelector(dashboardsSelectors.isFetching);
  const dashboard = useSelector(
    dashboardsSelectors.getDashboard(params.dashboardId),
  );
  const schedule = useSelector(dashboardsSelectors.getSchedule(dashboard?.id));
  const profile = useSelector(profileSelectors.getProfile);
  const globalFilters = useSelector(globalFiltersSelectors.getFilters);

  const permissions = usePermissions(PermissionModel.Resources.dashboard.value);

  const [formHasErrors, setFormHasErrors] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [showDeleteModal, toggleDeleteModal] = useToggle(false);
  const [isProcessing, setIsProcessing] = useState(null);

  useLoadingIndicator(isFetching);

  const initialValues = useMemo(
    () => {
      if (schedule?.id) {
        return {
          id: schedule.id,
          cron: schedule.cron,
          recipients: schedule.data.recipients,
          name: schedule.data.name,
          globalFilters: {
            ...schedule.data.globalFilters,
            context: ContextTypes.flow,
          },
        };
      }

      const start = dayjs();
      const remainder = timeInterval - (start.minute() % timeInterval);
      const dateTime = dayjs(start).add(remainder, 'minutes');
      return {
        id: '',
        cron: `${dateTime.minute()} ${dateTime.hour()} * * *`,
        recipients: [profile.email],
        name: dashboard?.title || '',
        globalFilters: {
          context: ContextTypes.flow,
          period:
          globalFilters.period.type === 'custom'
            ? {
              type: TimeDuration.hour,
              value: 4,
            }
            : {
              type: globalFilters.period.type,
              value: globalFilters.period.value,
            },
          labelContext: globalFilters.labelContext,
          ...[
            ContextTypes.flow,
            ContextTypes.alerts,
            ContextTypes.blocks,
            ContextTypes.dns,
          ].reduce((acc, context) => {
            const nqlField = getNqlFieldName(context);
            const intersectField = getIntersectFieldName(context);
            const metricField = getMetricFieldName(context);

            return {
              ...acc,
              [nqlField]: globalFilters[nqlField] || [],
              [intersectField]: globalFilters[intersectField] || [],
              [metricField]: globalFilters[metricField] || '',
            };
          }, {}),
        },
      };
    },
    [schedule, dashboard, profile, globalFilters],
  );

  const onBackToDashboard = useCallback(
    () => {
      navigate(`${RoutePaths.dashboards}/${params.dashboardId}`);
    },
    [params.dashboardId],
  );

  const onScheduleCreate = useCallback(
    (data) => {
      dispatch(
        dashboardsActions.createSchedule({
          id: dashboard.id,
          data,
        }),
      );

      return new Promise((resolve) => {
        setIsProcessing({ resolve });
      });
    },
    [dashboard?.id],
  );

  const onScheduleUpdate = useCallback(
    (data) => {
      dispatch(
        dashboardsActions.updateSchedule({
          id: dashboard.id,
          data,
        }),
      );

      return new Promise((resolve) => {
        setIsProcessing({ resolve });
      });
    },
    [dashboard?.id],
  );

  const onScheduleDelete = useCallback(
    () => {
      toggleDeleteModal(false);
      dispatch(dashboardsActions.removeSchedule(dashboard.id));
      onBackToDashboard();
    },
    [dashboard?.id, onBackToDashboard],
  );

  const onTestSchedule = useCallback(
    () => {
      const data = {
        cron: formValues.cron,
        recipients: formValues.recipients,
        name: formValues.name,
        period: formatCronToFrequency(formValues.cron),
        globalFilters: { ...formValues.globalFilters },
      };
      delete data.globalFilters.context;
      dispatch(
        dashboardsActions.testSchedule({
          id: dashboard.id,
          data,
        }),
      );
    },
    [formValues],
  );

  const onSubmit = useCallback(
    (values) => {
      const normalizedValue = {
        cron: values.cron,
        recipients: values.recipients,
        name: values.name,
        period: formatCronToFrequency(values.cron),
        globalFilters: { ...values.globalFilters },
      };
      delete normalizedValue.globalFilters.context;
      return values.id
        ? onScheduleUpdate(normalizedValue)
        : onScheduleCreate(normalizedValue);
    },
    [onScheduleCreate, onScheduleUpdate],
  );

  const doSubmit = useCallback(
    async (values) => {
      const errors = await onSubmit(values);
      return errors ? { [FORM_ERROR]: errors } : null;
    },
    [onSubmit],
  );

  const timer = useRef();
  const onChange = useCallback(
    ({ values, hasValidationErrors }) => {
      timer.current = setTimeout(() => {
        setFormHasErrors(hasValidationErrors);
        setFormValues(values);
      }, 10);
    },
    [],
  );
  useEffect(
    () => () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    },
    [],
  );

  useEffect(
    () => {
      if (isFetching || !isProcessing) {
        return;
      }

      const { resolve } = isProcessing;

      if (error) {
        resolve({ [FORM_ERROR]: error });
        return;
      }

      setIsProcessing(null);
      onBackToDashboard();

      resolve();
    },
    [isFetching, isProcessing, error, onBackToDashboard],
  );

  useEffect(
    () => {
      if (!dashboard?.id && params.dashboardId) {
        dispatch(dashboardsActions.fetchDashboard({ id: params.dashboardId }));
      }
    },
    [dashboard?.id, params.dashboardId],
  );

  useEffect(
    () => {
      if (dashboard?.id) {
        dispatch(dashboardsActions.fetchSchedule(dashboard.id));
      }
    },
    [dashboard?.id],
  );

  const title = `${dashboard?.title} Schedule`;

  return !permissions?.update || !dashboard ? null : (
    <Fragment>
      <Breadcrumb title={title} />

      <ActionsContainer>
        <Button onClick={onTestSchedule} disabled={formHasErrors || isFetching}>
          Send Now
        </Button>
      </ActionsContainer>

      <FormWizard
        mode={schedule?.id ? 'edit' : 'add'}
        initialValues={initialValues}
        deleteButtonText="Delete Schedule"
        deleteButtonHidden={!schedule?.id}
        loading={isFetching}
        disabled={isFetching}
        onSubmit={doSubmit}
        onCancel={onBackToDashboard}
        onDelete={toggleDeleteModal}
        validateOnBlur
      >
        <Step>
          <FormSpy
            subscription={{ hasValidationErrors: true, values: true }}
            onChange={onChange}
          />

          <FormBody disabled={isFetching} />
        </Step>
      </FormWizard>

      {showDeleteModal && (
        <ConfirmModal
          item={title}
          onToggle={toggleDeleteModal}
          onConfirm={onScheduleDelete}
          toggleOnConfirm={false}
          isOpen
        />
      )}
    </Fragment>
  );
};

export default DashboardScheduleForm;
