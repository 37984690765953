import styled from 'styled-components';

import { Container as TContainer } from '+components/Table/ReactTable/components/Components';

import Row from './Row';

export default styled.div`
  display: flex;
  flex-direction: column;

  .form + ${Row} {
    margin-top: 15px;
  }

  ${Row} + ${Row} {
    margin-top: 10px;
  }

  ${TContainer} {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
`;
