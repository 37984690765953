import PropTypes from '+prop-types';
import { NavLink } from 'react-router-dom';

import styled from 'styled-components';

import MenuColumnLinkIcon from 'mdi-react/ArrowRightIcon';

import ButtonMixin from './ButtonMixin';

const MenuColumnIconContainer = styled.div`
  ${ButtonMixin};
`;

const MenuColumnLink = ({ to, title, dataTracking }) => (
  <NavLink to={to} title={title} data-tracking={dataTracking}>
    <MenuColumnIconContainer>
      <MenuColumnLinkIcon size={16} />
    </MenuColumnIconContainer>
  </NavLink>
);

MenuColumnLink.propTypes = {
  to: PropTypes.string.isRequired,
  title: PropTypes.string,
  dataTracking: PropTypes.string,
};

MenuColumnLink.defaultProps = {
  title: null,
  dataTracking: undefined,
};

export { MenuColumnLinkIcon };
export default MenuColumnLink;
