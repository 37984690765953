import PropTypes from '+prop-types';
import { Fragment } from 'react';

import classNames from 'classnames';
import styled from 'styled-components';

import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import ChevronUpIcon from 'mdi-react/ChevronUpIcon';

import Avatar from '+components/Avatar';

import Description from './Description';
import Shortname from './Shortname';
import UserName from './UserName';

const Container = styled.button`
  display: flex;
  align-items: center;
  height: 47px;
  width: 100%;
  cursor: pointer;
  position: relative;
  border-radius: 0;
  border: none;
  box-shadow: none;
  padding: 0 10px 0 5px;
  background-color: ${({ theme }) => theme.profileBackground};
  border-bottom: ${({ $separatorWidth }) => $separatorWidth}px solid
    ${({ theme }) => theme.colorBackgroundSeparator};
  gap: 6px;

  :focus {
    outline: none;
  }

  :hover {
    background-color: ${({ theme }) => theme.topbarMenuBackgroundHover};
  }

  .mdi-icon {
    margin-left: auto;
    color: ${({ theme }) => theme.colorSideBarLeftGroupIcon};
  }

  &.disabled {
    cursor: unset;
    pointer-events: none;
    :hover,
    :active,
    :focus-within {
      background-color: unset;
    }
  }
`;

const Button = (props) => {
  const {
    $separatorWidth,
    className,
    description,
    username,
    picture,
    isOpen,
    collapse,
    disabled,
    onClick,
  } = props;

  return (
    <Container
      $separatorWidth={$separatorWidth}
      className={classNames(className, { disabled })}
      type={disabled ? undefined : 'button'}
      onClick={disabled ? undefined : onClick}
      disabled={disabled}
    >
      <Avatar
        name={username}
        src={picture}
        $size={collapse ? '40px' : '26px'}
      />

      {description}

      {!collapse && (
        <Fragment>
          {!disabled && isOpen && <ChevronUpIcon size={16} />}
          {!disabled && !isOpen && <ChevronDownIcon size={16} />}
        </Fragment>
      )}
    </Container>
  );
};

Button.propTypes = {
  $separatorWidth: PropTypes.number.isRequired,
  className: PropTypes.string,
  description: PropTypes.children,
  username: PropTypes.string,
  picture: PropTypes.string,
  isOpen: PropTypes.bool,
  collapse: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  className: '',
  description: null,
  username: '',
  picture: '',
  isOpen: false,
  collapse: false,
  disabled: false,
  onClick: null,
};

export { Description, UserName, Shortname };

export default Button;
