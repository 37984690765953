/**
 * CheckboxColumn factory.
 * @param {object?} options - options for the column, any other not declared options will be passed to the column
 * @param {number} [options.width] - width of the column
 * @param {{ true: string | JSX.Element, false: string | JSX.Element }} [options.filterLabels]
 * @param {function(any): string} [options.getTooltip]
 * @returns {object} - column definition
 */
export const RowSelectorColumnFactory = (options) => {
  const { width = 60, filterLabels, getTooltip, ...rest } = options || {};

  return {
    ...rest,
    id: 'rowSelector',
    filterLabels,
    getTooltip,
    width,
    createdOnFactory: true,
  };
};
