import PropTypes from '+prop-types';
import { cloneElement } from 'react';

const Option = ({ option, ...tail }) => (
  <li {...tail}>
    <div className="MuiAutocomplete-option__body">
      {option?.icon && (
        <span className="MuiAutocomplete-option__icon">
          {cloneElement(option.icon, { size: 16 })}
        </span>
      )}
      {(option?.label || option?.value) && (
        <span
          className="MuiAutocomplete-option__label"
          title={option.label || option.value}
        >
          {option.label || option.value}
        </span>
      )}
      {option?.description && (
        <span
          className="MuiAutocomplete-option__description"
          title={option.description}
        >
          {option.description}
        </span>
      )}
    </div>
  </li>
);

Option.propTypes = {
  option: PropTypes.shape({
    icon: PropTypes.element,
    value: PropTypes.any, // eslint-disable-line react/forbid-prop-types
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
};

export default Option;
