import styled from 'styled-components';

import { Row as RowOrigin } from './Row';

export const ActionsContainer = styled(RowOrigin)`
  width: unset;
  min-height: 32px;
  gap: 12px;
  margin-bottom: 15px;
  z-index: 9999;
  align-items: center;
  :empty {
    display: none;
  }
`;
