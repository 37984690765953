import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import AccountIcon from 'mdi-react/AccountIcon';
import AccountKeyIcon from 'mdi-react/AccountKeyIcon';
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';

import PermissionModel from '@/models/Permission';
import RoleModel from '@/models/Role';
import RoutePaths from '@/models/RoutePaths';

import {
  actions as customerActions,
  selectors as customerSelectors,
} from '@/redux/api/customer';
import {
  actions as usersActions,
  selectors as usersSelectors,
} from '@/redux/api/user';
import { selectors as profileSelectors } from '@/redux/api/user/profile';

import Button from '+components/Button';
import { lang } from '+components/charts/common/utils';
import ConfirmModal from '+components/ConfirmModal';
import { ActionsContainer } from '+components/Layout';
import Table from '+components/Table';
import { MenuColumnContextMenu } from '+components/Table/Columns';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import usePermissions from '+hooks/usePermissions';
import useRoles from '+hooks/useRoles';

import AddUserModal from './components/AddUserModal';
import { getColumns } from './components/Columns';
import UserListContainer from './components/UserListContainer';

const usersTableId = 'Settings_user_UserList';
const sortBy = [{ id: 'name' }];

const Users = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const customer = useSelector(customerSelectors.getCurrentCustomer);
  const samlProvider = useSelector(customerSelectors.getSamlProvider);
  const profile = useSelector(profileSelectors.getProfile);
  const users = useSelector(usersSelectors.getUsers);
  const isCustomerFetching = useSelector(customerSelectors.isFetching);
  const isProfileFetching = useSelector(profileSelectors.isFetching);
  const isUsersFetching = useSelector(usersSelectors.isFetching);

  const permissionsUsers = usePermissions(PermissionModel.Resources.user.value);
  const permissionsCustomers = usePermissions(
    PermissionModel.Resources.account.value,
  );
  const { roles, isRolesFetching } = useRoles();

  const [userAddModalOpened, setUserAddModalOpened] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  const isFetching = isCustomerFetching
    || isProfileFetching
    || isUsersFetching
    || isRolesFetching;
  const canAddUsers = permissionsUsers?.create && !isFetching;

  useLoadingIndicator(isFetching);

  const fixedUsers = useMemo(
    () => Object.values(users || {}),
    [users],
  );

  const onUserAddToggle = useCallback(
    () => {
      setUserAddModalOpened((prevValue) => !prevValue);
    },
    [],
  );

  const onUserAddConfirm = useCallback(
    (values) => {
      setUserAddModalOpened(false);
      dispatch(usersActions.createUser(values));
    },
    [],
  );

  const onUserDeleteToggle = useCallback(
    (user) => {
      setUserToDelete((prevValue) => (!prevValue ? user : null));
    },
    [],
  );

  const onUserDeleteConfirm = useCallback(
    () => {
      dispatch(usersActions.deleteUser(userToDelete.id));
    },
    [userToDelete?.id],
  );

  const onUserImpersonate = useCallback(
    (user) => {
      dispatch(usersActions.impersonate(user.id));
    },
    [],
  );

  const cxActionMenu = useCallback(
    (_, original) => {
      const isSelf = original.id === profile.id;
      const canRemove = original
        && (!original?.app_metadata?.original
          || original?.app_metadata?.original === customer.shortname)
        && !isSelf
        && permissionsUsers?.delete;

      const menuItems = [
        {
          icon: <AccountIcon />,
          text: 'Details',
          onClick: () => {
            navigate(`${RoutePaths.users}/${original.id}`);
          },
        },
        !permissionsCustomers?.update
          && false && {
          // Remove this `&& false` if we want to have ability to impersonate users
          // @see: https://netography.atlassian.net/browse/PORTAL-1289
          icon: <AccountKeyIcon />,
          text: 'Login As',
          disabled:
              isSelf || original.roles.includes(RoleModel.Roles.app_readonly),
          onClick: () => {
            onUserImpersonate(original);
          },
        },
        {
          icon: <TrashCanOutlineIcon />,
          text: 'Delete',
          disabled: !canRemove,
          onClick: () => {
            onUserDeleteToggle(original);
          },
        },
      ].filter(Boolean);

      return (
        <MenuColumnContextMenu
          title={original.name}
          items={menuItems}
          dataTracking="user"
        />
      );
    },
    [
      permissionsUsers,
      permissionsCustomers,
      profile?.id,
      onUserDeleteToggle,
      customer.shortname,
    ],
  );

  const columns = useMemo(
    () => getColumns({
      cxActionMenu,
      shortname: customer.shortname,
      showIdpColumn: !!samlProvider?.alias,
      roles,
    }),
    [cxActionMenu, customer.shortname, samlProvider?.alias, roles],
  );

  useEffect(
    () => {
      if (!samlProvider?.alias) {
        dispatch(customerActions.requestSamlProvider());
      }
    },
    [samlProvider?.alias],
  );

  useEffect(
    () => {
      dispatch(usersActions.requestUsers());
    },
    ['hot'],
  );

  return (
    <UserListContainer>
      <ActionsContainer>
        <Button
          disabled={!canAddUsers}
          onClick={onUserAddToggle}
          testId="add-user-button"
        >
          Add User
        </Button>
      </ActionsContainer>

      <Table
        id={usersTableId}
        columns={columns}
        data={fixedUsers}
        sortBy={sortBy}
        noDataText={users ? undefined : lang.loading}
        testId="users-table"
      />

      {userAddModalOpened && (
        <AddUserModal
          roles={roles}
          onToggle={onUserAddToggle}
          onSubmit={onUserAddConfirm}
          testId="add-user-modal"
        />
      )}

      {userToDelete && (
        <ConfirmModal
          item={userToDelete.email}
          onToggle={onUserDeleteToggle}
          onConfirm={onUserDeleteConfirm}
          isOpen
          testId="delete-user-modal"
        />
      )}
    </UserListContainer>
  );
};

export default Users;
