import { Category } from './Category';
import { Source } from './Source';
import { Target } from './Target';

export class Event {
  constructor({
    id,
    timestamp,
    categoryName,
    flowsrcname,

    sourceLat,
    sourceLng,
    sourceLand,
    sourceCountry,

    targetLat,
    targetLng,
    targetLand,
    targetCountry,

    data,
  }) {
    this.id = id
      || btoa(
        timestamp
          + categoryName
          + sourceLat
          + sourceLng
          + targetLat
          + targetLng,
      );

    this.timestamp = timestamp || Date.now();

    this.category = Category.create(categoryName, flowsrcname, data.customer);

    this.source = new Source({
      lat: sourceLat,
      lng: sourceLng,
      land: sourceLand,
      country: sourceCountry,
    });

    this.target = new Target({
      lat: targetLat,
      lng: targetLng,
      land: targetLand,
      country: targetCountry,
    });

    this.data = data;
  }

  get options() {
    return this.category.options;
  }

  get style() {
    return this.category.style;
  }
}
