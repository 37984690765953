export const EventSeverity = {
  none: {
    id: 'none',
    title: '',
    category: '',
    color: null,
    toString() {
      return this.id;
    },
  },
  low: {
    id: 'low',
    title: 'Low',
    category: 'Alert',
    order: 1,
    colorIndex: 'low',
    toString() {
      return this.id;
    },
  },
  medium: {
    id: 'medium',
    shortTitle: 'Med',
    title: 'Medium',
    category: 'Alert',
    order: 3,
    colorIndex: 'medium',
    toString() {
      return this.id;
    },
  },
  high: {
    id: 'high',
    title: 'High',
    category: 'Alert',
    order: 4,
    colorIndex: 'high',
    toString() {
      return this.id;
    },
  },
};
