import PropTypes from '+prop-types';
import { useMemo } from 'react';

import { UniversalCell } from '+components/Table/Cells';

const UniversalField = (props) => {
  const { field, original, options, disabled, ...rest } = props;

  const Component = useMemo(
    () => UniversalCell(field),
    [field],
  );

  const row = useMemo(
    () => (original ? { original } : undefined),
    [original],
  );

  const mergedOptions = useMemo(
    () => ({ ...(options || {}), disabled }),
    [options, disabled],
  );

  return <Component row={row} {...rest} options={mergedOptions} />;
};

UniversalField.propTypes = {
  field: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  original: PropTypes.shape(),
  options: PropTypes.shape(),
  value: PropTypes.any,
};
UniversalField.defaultProps = {
  disabled: undefined,
  original: undefined,
  options: undefined,
  value: undefined,
};

export default UniversalField;
