import { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInterval } from 'react-use';

import { useFlag } from '@unleash/proxy-client-react';

import { CustomType } from '@/models/CustomType';
import { DateTimeModes } from '@/models/DateTimeModes';
import FeatureFlags from '@/models/FeatureFlags';
import SettingCategories from '@/models/SettingCategories';

import { selectors as customerSelectors } from '@/redux/api/customer';
import { selectors as profileSelectors } from '@/redux/api/user/profile';
import {
  actions as globalFiltersActions,
  selectors as globalFiltersSelectors,
} from '@/redux/globalFilters';
import { selectors as uiSelectors } from '@/redux/globalFilters/ui';
import {
  actions as socketControlActions,
  selectors as socketControlSelectors,
} from '@/redux/ui/socketControl';

import usePortalSettingsValue from '+hooks/usePortalSettingsValue';
import useUIProperty from '+hooks/useUIProperty';

const withRealTimeController = (Component) => (props) => {
  const dispatch = useDispatch();
  const [windowFocused] = useUIProperty('windowFocused', true);
  const allFilters = useSelector(globalFiltersSelectors.getFilters);
  const updateEvery = useSelector(uiSelectors.getUpdateEvery);
  const available = useSelector(uiSelectors.getAvailable);
  const isPaused = useSelector(socketControlSelectors.isPaused);
  const retention = useSelector(customerSelectors.getRetention);

  const isRolesUiSettingsEnabled = useFlag(FeatureFlags.rolesUiSettings);
  const profile = useSelector(profileSelectors.getProfile);
  const [userRoleUiSettings] = usePortalSettingsValue(
    SettingCategories.ui,
    `${profile?.roles?.[0]}:settings`,
    {},
  );

  const time = useRef(null);

  const isRange = (allFilters.dateTimeMode === DateTimeModes.range
      || allFilters.period.type === CustomType)
    && !allFilters.startIsMin
    && !allFilters.endIsNow;

  time.current = !available || isRange || isPaused
    ? null
    : Math.max(+updateEvery, 0) || 60e3;

  const dateTimeLimit = useMemo(
    () => {
      if (isRolesUiSettingsEnabled && userRoleUiSettings?.dateTimeLimit) {
        return Math.min(userRoleUiSettings.dateTimeLimit, retention);
      }
      return retention;
    },
    [retention, isRolesUiSettingsEnabled, userRoleUiSettings?.dateTimeLimit],
  );

  useInterval(() => {
    dispatch(globalFiltersActions.refreshDateTime(dateTimeLimit));
  }, time.current);

  useEffect(
    () => {
      if (!isPaused && time.current && windowFocused) {
        dispatch(globalFiltersActions.refreshDateTime(dateTimeLimit));
      }
    },
    [
      isPaused,
      dateTimeLimit,
      allFilters.dateTimeMode,
      allFilters.period.type,
      allFilters.startIsMin,
      allFilters.endIsNow,
      windowFocused,
    ],
  );

  useEffect(
    () => {
      if (available && !isRange) {
        dispatch(socketControlActions.show());
      }

      return () => {
        dispatch(socketControlActions.hide());
      };
    },
    [available, allFilters.dateTimeMode, allFilters.period.type],
  );

  return useMemo(
    () => <Component {...props} />,
    Object.values(props),
  );
};

export default withRealTimeController;
