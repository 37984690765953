import PropTypes from '+prop-types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import FinalForm from '+components/form/FinalForm';
import childrenMap from '+utils/childrenMap';

import FormBody, { commonDefaultProps, commonPropTypes, ActionsContainer } from './components/FormBody';
import Step from './components/Step';

const defaultTabProps = {
  index: 0,
  title: '',
  active: false,
};

/**
 * Wizard helps to separate a single form into separate pages of inputs.
 */
const FormWizard = (props) => {
  const {
    initialValues,
    onSubmit,
    children,
    disabled,
    onCancel,
    resetOnChancel,
    ...tail
  } = props;

  const [activeStep, setActiveStep] = useState(0);
  const [nextStep, setNextStep] = useState(0);

  const isConfirmButton = useRef(false);

  const steps = useMemo(
    () => {
      if (!children) {
        return [defaultTabProps];
      }

      const result = [];

      childrenMap(children, (childProps, index) => {
        result.push({
          ...defaultTabProps,
          ...childProps,
          index,
          active: index === activeStep,
          complete: index < activeStep,
          disabled,
        });
      });

      return result;
    },
    [children, activeStep, disabled],
  );

  const normalizedChildren = useMemo(
    () => childrenMap(children || [], (childProps, index) => ({
      key: index,
      ...childProps,
      active: index === activeStep,
      disabled,
    })),
    [children, activeStep, disabled],
  );

  const onTabButtonClick = useCallback(
    (index) => setActiveStep(index),
    [],
  );

  const onPrevButtonClick = useCallback(
    () => setActiveStep((prev) => Math.max(prev - 1, 0)),
    [],
  );

  const onNextButtonClick = useCallback(
    () => setActiveStep((prev) => Math.min(prev + 1, steps.length - 1)),
    [steps.length],
  );

  const emitCancel = useCallback(
    (event) => {
      if (resetOnChancel) {
        setActiveStep(0);
      }

      if (onCancel) {
        onCancel(event);
      }
    },
    [onCancel, resetOnChancel],
  );

  const onFormSubmit = useCallback(
    (values) => {
      if (isConfirmButton.current || activeStep === steps.length - 1) {
        return onSubmit?.(values);
      }

      if (nextStep) {
        setActiveStep(nextStep);
        setNextStep(0);
        return null;
      }

      onNextButtonClick();

      return null;
    },
    [
      onSubmit, onNextButtonClick,
      activeStep, steps.length,
      nextStep,
    ],
  );

  const setIsConfirmButtonClicked = useCallback(
    (value) => {
      isConfirmButton.current = !!value;
    },
    [],
  );

  useEffect(
    () => {
      if (activeStep > normalizedChildren.length - 1) {
        setActiveStep(Math.max(0, normalizedChildren.length - 1));
      }
    },
    [normalizedChildren.length, activeStep],
  );

  return (
    <FinalForm
      keepDirtyOnReinitialize
      initialValues={initialValues}
      {...tail}
      resetOnChancel={resetOnChancel}
      onCancel={emitCancel}
      steps={steps}
      activeStep={activeStep}
      disabled={disabled}
      onSubmit={onFormSubmit}
      component={FormBody}
      setIsConfirmButtonClicked={setIsConfirmButtonClicked}
      onTabButtonClick={onTabButtonClick}
      onPrevButtonClick={onPrevButtonClick}
      setNextStep={setNextStep}
    >
      {normalizedChildren}
    </FinalForm>
  );
};

FormWizard.propTypes = {
  ...commonPropTypes,
  /**
   * A callback fired when confirm button clicked.
   */
  onSubmit: PropTypes.func.isRequired,
};

FormWizard.defaultProps = commonDefaultProps;

export {
  Step,
  FormWizard,
  ActionsContainer,
};

export default FormWizard;
