import more from 'highcharts/highcharts-more';
import Highmaps from 'highcharts/highmaps';
import Highcharts from 'highcharts/highstock';
import boost from 'highcharts/modules/boost';
import applyDrilldown from 'highcharts/modules/drilldown';
import exportData from 'highcharts/modules/export-data';
import exporting from 'highcharts/modules/exporting';
import heatmapModule from 'highcharts/modules/heatmap';
import addNoDataModule from 'highcharts/modules/no-data-to-display';
import offlineExporting from 'highcharts/modules/offline-exporting';
import sankey from 'highcharts/modules/sankey';
import solidGauge from 'highcharts/modules/solid-gauge';
import treegraph from 'highcharts/modules/treegraph';
import treemap from 'highcharts/modules/treemap';

import dayjs, { DateFormat } from '+utils/dayjs';

import { lang } from './utils';

// DateTime formatter for exported CSV data for Histogram chart
// We need it for fixing timestamp in exported CSV data
// @see: https://gitlab.com/netography/portal/-/issues/1042
// and: https://gitlab.com/netography/portal/-/issues/1045
const getDataRows = (H) => {
  H.wrap(
    H.Chart.prototype,
    'getDataRows',
    function (proceed, multiLevelHeaders) {
      // TODO need to check after this bug is fixed: https://github.com/highcharts/highcharts/issues/19552
      this.series.forEach((series) => {
        if (series.type !== 'treegraph') {
          return;
        }

        series.options.data = series.data.map((point) => ({
          ...point.options,
        }));
      });

      const rows = proceed.call(this, multiLevelHeaders);

      if (this.options.chart.__type === 'treegraph') {
        rows.forEach((row, index) => {
          if (index) {
            row[0] = row.x;
          }
        });
      }

      if (this.options.chart.__type === 'histogram-chart') {
        const { categories } = this.xAxis[0];
        rows.forEach((row, i) => {
          // Row with index == 0 is for header
          if (i > 0) {
            row.x = +dayjs.unix(categories[i - 1]);
          }
        });
      }

      if (this.options.chart.__type === 'sparkline-chart') {
        rows[0][0] = 'DateTime';
      }

      if (rows[0][0] === 'DateTime') {
        const tzName = dayjs().format('z');
        rows[0][0] = `DateTime (${tzName})`;
        rows.forEach((row, i) => {
          // Row with index == 0 is for header
          if (i > 0) {
            row[0] = dayjs(row.x).format(DateFormat.second);
          }
        });
      }

      // eslint-disable-next-line max-len
      // rows[0][*] can be "<span class="label-series-group"><span class="label-series"><span class="label-series__body">lechnertech</span></span><span class="label-series"><span class="label-series__body">23.126.137.65</span></span></span>"
      // we need to extract all label-series__body , so it should be "lechnertech, 23.126.137.65"
      rows[0].forEach((row, i) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = row;
        const labelSeries = tempDiv.querySelectorAll('.label-series__body');
        const labelSeriesArray = Array.from(labelSeries);
        if (!labelSeriesArray.length) {
          rows[0][i] = row;
        } else {
          rows[0][i] = labelSeriesArray.map((el) => el.textContent).join(', ');
        }
      });

      return rows;
    },
  );
};

const setData = (H) => {
  H.wrap(H.Series.prototype, 'setData', function (proceed, ...args) {
    if (this.type === 'heatmap') {
      // FIXED: TypeError: Cannot assign to read only property 'value' of object '#<Object>'
      this.data?.forEach((point) => {
        point.options = { ...point.options };
      });
    }

    return proceed.apply(this, args);
  });
};

// TODO can be removed after this bug is fixed: https://github.com/highcharts/highcharts/issues/19552
const getSVG = (H) => {
  H.wrap(H.Chart.prototype, 'getSVG', function (proceed, chartOptions) {
    this.series.forEach((series) => {
      if (series.type !== 'treegraph') {
        return;
      }

      series.userOptions.data = series.data.map((point) => ({
        ...point.options,
      }));
    });
    return proceed.call(this, chartOptions);
  });
};

// @see https://github.com/highcharts/highcharts/issues/13222#issuecomment-704922133
const fullscreenEventListener = (H) => {
  if (!H.Fullscreen) {
    return;
  }

  const { addEvent, wrap } = H;

  H.Fullscreen.prototype.open = function () {
    const fullscreen = this;
    const { chart } = fullscreen;
    const originalWidth = chart.chartWidth;
    const originalHeight = chart.chartHeight;

    // eslint-disable-next-line no-restricted-globals
    chart.setSize(screen.width, screen.height, false);
    // @see https://github.com/highcharts/highcharts/issues/13220
    chart.pointer.chartPosition = null;

    fullscreen.originalWidth = originalWidth;
    fullscreen.originalHeight = originalHeight;

    // Handle exitFullscreen() method when user clicks 'Escape' button.
    if (fullscreen.browserProps) {
      fullscreen.unbindFullscreenEvent = addEvent(
        chart.container.ownerDocument, // chart's document
        fullscreen.browserProps.fullscreenChange,
        () => {
          // Handle lack of async of browser's fullScreenChange event.
          if (fullscreen.isOpen) {
            fullscreen.isOpen = false;
            fullscreen.close();
            chart.setSize(originalWidth, originalHeight, false);
            chart.pointer.chartPosition = null;
          } else {
            fullscreen.isOpen = true;
            fullscreen.setButtonText();
          }
        },
      );
      const promise = chart.renderTo[fullscreen.browserProps.requestFullscreen]();
      if (promise) {
        // No dot notation because of IE8 compatibility
        promise.catch(() => {
          // eslint-disable-next-line no-alert
          alert('Full screen is not supported inside a frame.');
        });
      }
      addEvent(chart, 'destroy', fullscreen.unbindFullscreenEvent);
    }
  };

  wrap(H.Fullscreen.prototype, 'close', function (proceed) {
    // eslint-disable-next-line prefer-rest-params
    proceed.apply(this, Array.prototype.slice.call(arguments, 1));
    const fullscreen = this;
    const { chart } = fullscreen;
    chart.setSize(fullscreen.originalWidth, fullscreen.originalHeight, false);
    chart.pointer.chartPosition = null;
  });
};

const runPointActions = (H) => {
  // Workaround for TypeError: Cannot read properties of null (reading 'noSharedTooltip')
  H.wrap(H.Pointer.prototype, 'getHoverData', function (proceed, ...args) {
    try {
      return proceed.apply(this, args);
    } catch (err) {
      // console.log(err);
      return null;
    }
  });

  // Workaround for TypeError: Cannot read properties of null (reading 'noSharedTooltip')
  H.wrap(H.Pointer.prototype, 'runPointActions', function (proceed, ...args) {
    // if (Number.isNaN(e.chartX) || Number.isNaN(e.chartY)) {
    //   return;
    // }
    try {
      proceed.apply(this, args);
    } catch (err) {
      // console.log(err);
    }
  });

  // Workaround for Error: <rect> attribute width: Expected length, "NaN"
  H.wrap(H.Tooltip.prototype, 'drawTracker', function (proceed, ...args) {
    const points = this.shared ? this.chart.hoverPoints : this.chart.hoverPoint;
    if (!points?.length) {
      return;
    }

    const anchorPos = this.getAnchor(points);
    if (
      Number.isNaN(anchorPos[0])
      || Number.isNaN(anchorPos[1])
      || Number.isNaN(this.label.translateX)
      || Number.isNaN(this.label.translateY)
      || Number.isNaN(this.chart.plotLeft)
      || Number.isNaN(this.chart.plotTop)
    ) {
      return;
    }

    proceed.apply(this, args);
  });
};

const fixMouseDown = (H) => {
  const { addEvent } = H;

  H.wrap(H.Chart.prototype, 'init', function (proceed, ...args) {
    // Run the original proceed method
    proceed.apply(this, args);

    const { tooltip } = this;

    addEvent(document, 'keydown', (event) => {
      const { key } = event;

      if (['Escape', 'Esc'].includes(key)) {
        event.preventDefault();
        event.stopPropagation();

        tooltip.hide(true);
      }
    });
  });
};

export function pointClickEvent(event) {
  const tooltip = this.series?.chart?.tooltip;
  const { __clickedPoint } = tooltip;

  if (__clickedPoint === this) {
    tooltip.hide(true);
    return;
  }

  tooltip.refresh(this, event, true);
  tooltip.__clickedPoint = this;
}

// persist the tooltip
// @see https://www.highcharts.com/forum/viewtopic.php?t=40511
const persistTooltip = (H) => {
  H.wrap(H.Tooltip.prototype, 'hide', function (proceed, hide, ...args) {
    if (hide) {
      proceed.apply(this, [hide, ...args]);
      this.__clickedPoint = null;
    }
  });

  H.wrap(H.Tooltip.prototype, 'refresh', function (proceed, ...args) {
    const [, , click] = args;
    if (click) {
      proceed.apply(this, args);
    }
  });
};

const legendContextMenuEventListeners = (H) => {
  H.wrap(
    H.Legend.prototype,
    'setItemEvents',
    function (proceed, item, legendItem, useHTML, itemStyle, itemHiddenStyle) {
      proceed.call(this, item, legendItem, useHTML, itemStyle, itemHiddenStyle);
      (useHTML ? legendItem : item.legendGroup).on('contextmenu', (e) => {
        // item?.series?.userOptions for pie chart
        (
          item?.userOptions || item?.series?.userOptions
        )?.__onLegendItemContextMenu?.(e);
      });
    },
  );
};

const nodeContextMenuEventListenersSankey = (H) => {
  H.wrap(H.seriesTypes.sankey.prototype, 'drawDataLabels', function (proceed) {
    proceed.call(this);
    this.nodes.forEach((node) => {
      node.dataLabel.on('contextmenu', (e) => {
        node.series?.userOptions?.__onNodeContextMenu?.(e, node);
      });
    });
  });
};

const fns = [
  more,
  addNoDataModule,
  exporting,
  exportData,
  offlineExporting,
  fullscreenEventListener,
  legendContextMenuEventListeners,
  getDataRows,
  getSVG,
];

if (typeof Highcharts === 'object') {
  fns.forEach((fn) => fn(Highcharts));

  boost(Highcharts);
  heatmapModule(Highcharts);
  sankey(Highcharts);
  nodeContextMenuEventListenersSankey(Highcharts);
  solidGauge(Highcharts);
  applyDrilldown(Highcharts);
  treemap(Highcharts);
  treegraph(Highcharts);

  setData(Highcharts);
  runPointActions(Highcharts);

  // resetZoom lang not working without this workaround
  Highcharts.setOptions({
    lang: {
      resetZoom: lang.resetZoom,
    },
  });

  /** * multiple chart ** */
  // Workaround for multiple chart shared tooltip
  // We do not need to share tooltip in case of bar or column series
  Highcharts.seriesTypes.bar.prototype.noSharedTooltip = true;
  Highcharts.seriesTypes.column.prototype.noSharedTooltip = true;

  /** * scatter chart ** */

  // Workaround for scatter plot shared tooltip
  // @see: https://stackoverflow.com/questions/29603719/highcharts-shared-tooltip-for-line-series-and-scatter-plot-not-working
  Highcharts.seriesTypes.scatter.prototype.noSharedTooltip = false;

  const symbolsUnicode = {
    circle: '●',
    diamond: '♦',
    square: '■',
    triangle: '▲',
    'triangle-down': '▼',
  };

  const symbolSizes = {
    circle: '13px',
    diamond: '13px',
    square: '13px',
    triangle: '10px',
    'triangle-down': '10px',
  };

  // display symbols in the tooltip
  // @see https://stackoverflow.com/questions/25973920/how-to-display-highchart-series-line-marker-symbol-from-tooltip-formatter
  Highcharts.addEvent(Highcharts.Series, 'afterInit', function () {
    this.symbolUnicode = symbolsUnicode[this.symbol] || '●';

    this.symbolSize = symbolSizes[this.symbol] || '13px';
  });
}

if (typeof Highmaps === 'object') {
  fns.forEach((fn) => fn(Highmaps));

  fixMouseDown(Highmaps);

  persistTooltip(Highmaps);

  const { normalize } = Highcharts.Pointer.prototype;

  // fix for bug https://netography.atlassian.net/browse/PORTAL-1666
  Object.assign(Highmaps.Pointer.prototype, {
    normalize(e, chartPosition) {
      const { chart } = this;
      e = normalize.call(this, e, chartPosition);
      if (chart && chart.mapView) {
        const lonLat = Number.isNaN(e.chartX) || Number.isNaN(e.chartY)
          ? null
          : chart.mapView.pixelsToLonLat({
            x: e.chartX - chart.plotLeft,
            y: e.chartY - chart.plotTop,
          });

        if (lonLat) {
          Object.assign(e, lonLat);
        }
      }
      return e;
    },
  });
}

// Add allowed attributes
if (typeof Highmaps === 'object') {
  Highmaps.AST.allowedAttributes.push('data-srcip');
  Highmaps.AST.allowedAttributes.push('data-dstip');
  Highmaps.AST.allowedAttributes.push('data-iptype');
  Highmaps.AST.allowedAttributes.push('data-customer');
}

export { Highcharts, Highmaps };
