export const config = {
  environment: import.meta.env.MODE,
  appBackendUrlRoot: import.meta.env.VITE_APP_BACKEND_URL_ROOT,
  appWebsocketUrlRoot: import.meta.env.VITE_APP_WEBSOCKET_URL_ROOT,

  keycloak: {
    realm: import.meta.env.VITE_APP_KEYCLOAK_REALM,
    serverUrl: import.meta.env.VITE_APP_KEYCLOAK_SERVER_URL,
    clientId: import.meta.env.VITE_APP_KEYCLOAK_CLIENT_ID,
  },

  gtm: {
    gtmId: import.meta.env.VITE_APP_GTM_ID,
    auth: import.meta.env.VITE_APP_GTM_AUTH,
    preview: import.meta.env.VITE_APP_GTM_PREVIEW,
  },
};
