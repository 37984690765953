import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import RoutePaths from '@/models/RoutePaths';

import GenericLabel from '+components/Labels/GenericLabel';
import { ShowMoreWrapper } from '+components/Table/Cells';
import {
  BaseColumnFactory,
  MenuColumnFactory,
  StringsArrayColumnsFactory,
} from '+components/Table/Columns';
import { makeId } from '+utils';
import { getColumnsHelper } from '+utils/getColumnsHelper';

export const Columns = {
  name: 'name',
  period: 'period',
  recipients: 'recipients',
  dashboard: 'dashboard',
  description: 'description',
  groups: 'groups',
  menu: 'menu',
};

const columnsCollection = ({ dashboardsMeta, cxActionMenu }) => ({
  [Columns.name]: BaseColumnFactory({
    accessor: 'data.name',
    Header: 'Name',
  }),
  [Columns.period]: BaseColumnFactory({
    accessor: 'data.period',
    Header: 'Period',
  }),
  [Columns.recipients]: BaseColumnFactory({
    accessor: 'data.recipients',
    Header: 'Recipients',
    Cell: ({ value }) => (value || []).join(', '),
  }),
  // link to dashboard
  [Columns.dashboard]: BaseColumnFactory({
    accessor: 'data.dashboard',
    Header: 'Dashboard',
    // eslint-disable-next-line react/prop-types
    Cell: ({ value }) => (
      <Link to={`${RoutePaths.dashboards}/${value}`}>
        {dashboardsMeta?.[value]?.title}
      </Link>
    ),
  }),
  [Columns.description]: BaseColumnFactory({
    accessor: 'data.dashboard',
    Header: 'Description',
    // eslint-disable-next-line react/prop-types
    Cell: ({ value }) => dashboardsMeta?.[value]?.description,
  }),
  [Columns.groups]: StringsArrayColumnsFactory({
    accessor: (data) => dashboardsMeta?.[data.data.dashboard]?.groups || [],
    Header: 'Groups',
    width: 260,
    Cell: ({ value }) => useMemo(
      () => (
        <ShowMoreWrapper>
          {value.map((val) => (
            <GenericLabel
              key={`${val}_${makeId()}`}
              field=""
              value={val}
              clickable={false}
              context={val}
            />
          ))}
        </ShowMoreWrapper>
      ),
      [value],
    ),
  }),
  [Columns.menu]: MenuColumnFactory({ cxActionMenu }),
});

export const getColumns = getColumnsHelper(columnsCollection);
