import { isIP } from 'is-ip';

import { ContextTypes } from '@/models/ContextTypes';

import nqlLang from '+utils/nqlLang';

const getNqlWithAdditionalNql = (field, value, additionalNql) => nqlLang.and(
  Array.isArray(field)
    ? nqlLang.or(...field.map((fld) => nqlLang.equal(fld, value)))
    : nqlLang.equal(field, value),
  additionalNql,
);

/**
 * Returns the NQL for a given field name
 * @param {string} context
 * @param {string} field
 * @param value
 */
export default ({ context, field, value }) => {
  if (!field || value == null || value === '') {
    return {
      flowNql: '',
      dnsNql: '',
      eventNql: '',
      blockNql: '',
    };
  }

  /** * IP LABELS ** */
  const [, , labelContext] = field.startsWith('label.ip')
    ? field.split('.')
    : [];
  const isNameCtx = labelContext === 'name';

  // label.ip.*
  if (/^label\.ip\.('*'|[a-zA-Z0-9\-_]+)$/g.test(field)) {
    return {
      flowNql: getNqlWithAdditionalNql(field, value),
      dnsNql: getNqlWithAdditionalNql(field, value),
      eventNql: !isNameCtx
        ? ''
        : getNqlWithAdditionalNql('ipinfo.ipname', value),
      blockNql: !isNameCtx
        ? ''
        : getNqlWithAdditionalNql(['srcipname', 'dstipname'], value),
    };
  }

  // label.ip.*.src
  if (/^label\.ip\.('*'|[a-zA-Z0-9\-_]+)\.src$/g.test(field)) {
    return {
      flowNql: getNqlWithAdditionalNql(field, value),
      dnsNql: getNqlWithAdditionalNql(field, value),
      eventNql: !isNameCtx
        ? ''
        : getNqlWithAdditionalNql(
          'ipinfo.ipname',
          value,
          nqlLang.equal('ipinfo.srcip', true),
        ),
      blockNql: !isNameCtx ? '' : getNqlWithAdditionalNql('srcipname', value),
    };
  }

  // label.ip.*.dst
  if (/^label\.ip\.('*'|[a-zA-Z0-9\-_]+)\.dst$/g.test(field)) {
    return {
      flowNql: getNqlWithAdditionalNql(field, value),
      dnsNql: getNqlWithAdditionalNql(field, value),
      eventNql: !isNameCtx
        ? ''
        : getNqlWithAdditionalNql(
          'ipinfo.ipname',
          value,
          nqlLang.equal('ipinfo.dstip', true),
        ),
      blockNql: !isNameCtx ? '' : getNqlWithAdditionalNql('dstipname', value),
    };
  }
  /** * IP LABELS ** */

  /** * PORT LABELS ** */
  // label.port.*
  if (/^label\.port\.('*'|[a-zA-Z0-9\-_]+)$/g.test(field)) {
    return {
      flowNql: getNqlWithAdditionalNql(field, value),
      dnsNql: getNqlWithAdditionalNql(field, value),
      eventNql: getNqlWithAdditionalNql(
        ['srcportnames', 'dstportnames'],
        value,
      ),
      blockNql: getNqlWithAdditionalNql(['srcportname', 'dstportname'], value),
    };
  }

  // label.port.*.src
  if (/^label\.port\.('*'|[a-zA-Z0-9\-_]+)\.src$/g.test(field)) {
    return {
      flowNql: getNqlWithAdditionalNql(field, value),
      dnsNql: getNqlWithAdditionalNql(field, value),
      eventNql: getNqlWithAdditionalNql('srcportnames', value),
      blockNql: getNqlWithAdditionalNql('srcportname', value),
    };
  }

  // label.port.*.dst
  if (/^label\.port\.('*'|[a-zA-Z0-9\-_]+)\.dst$/g.test(field)) {
    return {
      flowNql: getNqlWithAdditionalNql(field, value),
      dnsNql: getNqlWithAdditionalNql(field, value),
      eventNql: getNqlWithAdditionalNql('dstportnames', value),
      blockNql: getNqlWithAdditionalNql('dstportname', value),
    };
  }
  /** * PORT LABELS ** */

  let flowNql = '';
  let dnsNql = '';
  let eventNql = '';
  let blockNql = '';

  switch (field) {
    case 'algorithm':
    case 'algorithms':
      eventNql = getNqlWithAdditionalNql('algorithm', value);
      blockNql = getNqlWithAdditionalNql('algorithms', value);
      break;
    case 'category':
    case 'categories':
      eventNql = getNqlWithAdditionalNql('categories', value);
      break;
    case 'protocol':
      flowNql = getNqlWithAdditionalNql(field, value);
      dnsNql = flowNql;
      blockNql = getNqlWithAdditionalNql(field, value);
      break;
    case 'protocolint':
      flowNql = getNqlWithAdditionalNql(field, value);
      break;
    case 'datasrc':
    case 'flowsrcname':
    case 'flowsrcnames':
      flowNql = getNqlWithAdditionalNql('flowsrcname', value);
      eventNql = getNqlWithAdditionalNql('flowsrcnames', value);
      dnsNql = getNqlWithAdditionalNql('datasrc', value);
      break;
    case 'dstowneras.org':
    case 'dstowneras.number':
    case 'srcowneras.org':
    case 'srcowneras.number':
      flowNql = getNqlWithAdditionalNql(field, value);
      break;
    case 'srcas':
    case 'dstas':
    case 'dstowneras':
    case 'srcowneras': {
      const mode = field.startsWith('src') ? 'src' : 'dst';
      flowNql = nqlLang.equal(`${field}.number`, value?.number);
      eventNql = nqlLang.and(
        nqlLang.equal('ipinfo.as.number', value?.number),
        nqlLang.equal(`ipinfo.${mode}ip`, true),
      );
      blockNql = nqlLang.equal(`${mode}as.number`, value?.number);
      break;
    }
    case 'plugin':
      eventNql = getNqlWithAdditionalNql('rules.plugins.name', value);
      blockNql = getNqlWithAdditionalNql('plugin.name', value);
      break;
    case 'rules.name':
      eventNql = getNqlWithAdditionalNql(field, value);
      blockNql = getNqlWithAdditionalNql(field, value);
      break;
    case 'severity':
      eventNql = getNqlWithAdditionalNql(field, value);
      break;
    case 'site':
    case 'sites':
      flowNql = getNqlWithAdditionalNql('site', value);
      dnsNql = flowNql;
      eventNql = getNqlWithAdditionalNql('sites', value);
      break;
    case 'tag':
    case 'tags':
      flowNql = getNqlWithAdditionalNql('tags', value);
      eventNql = getNqlWithAdditionalNql('tags', value);
      break;
    case 'srciprep.categories':
      flowNql = getNqlWithAdditionalNql('srciprep.categories', value);
      eventNql = getNqlWithAdditionalNql(
        'ipinfo.iprep.categories',
        value,
        nqlLang.equal('ipinfo.srcip', true),
      );
      blockNql = getNqlWithAdditionalNql('srciprep.categories', value);
      break;
    case 'dstiprep.categories':
      flowNql = getNqlWithAdditionalNql('dstiprep.categories', value);
      eventNql = getNqlWithAdditionalNql(
        'ipinfo.iprep.categories',
        value,
        nqlLang.equal('ipinfo.dstip', true),
      );
      blockNql = getNqlWithAdditionalNql('dstiprep.categories', value);
      break;
    case 'ipinfo.iprep.categories':
      flowNql = getNqlWithAdditionalNql(
        ['srciprep.categories', 'dstiprep.categories'],
        value,
      );
      eventNql = getNqlWithAdditionalNql('ipinfo.iprep.categories', value);
      blockNql = getNqlWithAdditionalNql(
        ['srciprep.categories', 'dstiprep.categories'],
        value,
      );
      break;
    case 'tcpflagsint':
      flowNql = getNqlWithAdditionalNql('site', value);
      break;
    /** * GEO ** */
    case 'srcgeo':
      flowNql = nqlLang.and(
        nqlLang.equal('srcgeo.countrycode', value?.countrycode),
        nqlLang.equal('srcgeo.subdiso', value?.subdiso),
      );
      eventNql = nqlLang.and(
        nqlLang.equal('ipinfo.geo.countrycode', value?.countrycode),
        nqlLang.equal('ipinfo.geo.subdiso', value?.subdiso),
        nqlLang.equal('ipinfo.srcip', true),
      );
      blockNql = nqlLang.and(
        nqlLang.equal('srcgeo.countrycode', value?.countrycode),
        nqlLang.equal('srcgeo.subdiso', value?.subdiso),
      );
      break;
    case 'dstgeo':
      flowNql = nqlLang.and(
        nqlLang.equal('dstgeo.countrycode', value?.countrycode),
        nqlLang.equal('dstgeo.subdiso', value?.subdiso),
      );
      eventNql = nqlLang.and(
        nqlLang.equal('ipinfo.geo.countrycode', value?.countrycode),
        nqlLang.equal('ipinfo.geo.subdiso', value?.subdiso),
        nqlLang.equal('ipinfo.dstip', true),
      );
      blockNql = nqlLang.and(
        nqlLang.equal('dstgeo.countrycode', value?.countrycode),
        nqlLang.equal('dstgeo.subdiso', value?.subdiso),
      );
      break;
    case 'geo': // event details page
      flowNql = nqlLang.and(
        nqlLang.equal('geo.countrycode', value?.countrycode),
        nqlLang.equal('geo.subdiso', value?.subdiso),
      );
      eventNql = nqlLang.and(
        nqlLang.equal('ipinfo.geo.countrycode', value?.countrycode),
        nqlLang.equal('ipinfo.geo.subdiso', value?.subdiso),
      );
      blockNql = nqlLang.or(
        nqlLang.and(
          nqlLang.equal('srcgeo.countrycode', value?.countrycode),
          nqlLang.equal('srcgeo.subdiso', value?.subdiso),
        ),
        nqlLang.and(
          nqlLang.equal('dstgeo.countrycode', value?.countrycode),
          nqlLang.equal('dstgeo.subdiso', value?.subdiso),
        ),
      );
      break;
    case 'srcgeo.countrycode':
      flowNql = getNqlWithAdditionalNql('srcgeo.countrycode', value);
      eventNql = getNqlWithAdditionalNql(
        'ipinfo.geo.countrycode',
        value,
        nqlLang.equal('ipinfo.srcip', true),
      );
      blockNql = getNqlWithAdditionalNql('srcgeo.countrycode', value);
      break;
    case 'dstgeo.countrycode':
      flowNql = getNqlWithAdditionalNql('dstgeo.countrycode', value);
      eventNql = getNqlWithAdditionalNql(
        'ipinfo.geo.countrycode',
        value,
        nqlLang.equal('ipinfo.dstip', true),
      );
      blockNql = getNqlWithAdditionalNql('dstgeo.countrycode', value);
      break;
    case 'geo.continentcode':
      flowNql = getNqlWithAdditionalNql('geo.continentcode', value);
      eventNql = getNqlWithAdditionalNql('ipinfo.geo.continentcode', value);
      blockNql = getNqlWithAdditionalNql(
        ['srcgeo.continentcode', 'dstgeo.continentcode'],
        value,
      );
      break;
    case 'geo.countrycode':
      flowNql = getNqlWithAdditionalNql('geo.countrycode', value);
      eventNql = getNqlWithAdditionalNql('ipinfo.geo.countrycode', value);
      blockNql = getNqlWithAdditionalNql(
        ['srcgeo.countrycode', 'dstgeo.countrycode'],
        value,
      );
      break;
    case 'geo.subdiso':
      flowNql = getNqlWithAdditionalNql('geo.subdiso', value);
      eventNql = getNqlWithAdditionalNql('ipinfo.geo.subdiso', value);
      blockNql = getNqlWithAdditionalNql(
        ['srcgeo.subdiso', 'dstgeo.subdiso'],
        value,
      );
      break;
    case 'geo.subdivisionb':
      flowNql = '';
      eventNql = getNqlWithAdditionalNql('ipinfo.geo.subdivisionb', value);
      blockNql = getNqlWithAdditionalNql(
        ['srcgeo.subdivisionb', 'dstgeo.subdivisionb'],
        value,
      );
      break;
    case 'geo.city':
      flowNql = '';
      eventNql = getNqlWithAdditionalNql('ipinfo.geo.city', value);
      blockNql = getNqlWithAdditionalNql(['srcgeo.city', 'dstgeo.city'], value);
      break;
    case 'geo.postal':
      flowNql = '';
      eventNql = getNqlWithAdditionalNql('ipinfo.geo.postal', value);
      blockNql = getNqlWithAdditionalNql(
        ['srcgeo.postal', 'dstgeo.postal'],
        value,
      );
      break;
    case 'geo.location':
      flowNql = nqlLang.or(
        nqlLang.and(
          nqlLang.equal('srcgeo.location.lat', value?.lat),
          nqlLang.equal('srcgeo.location.lon', value?.lon),
        ),
        nqlLang.and(
          nqlLang.equal('dstgeo.location.lat', value?.lat),
          nqlLang.equal('dstgeo.location.lon', value?.lon),
        ),
      );
      eventNql = nqlLang.and(
        nqlLang.equal('ipinfo.geo.location.lat', value?.lat),
        nqlLang.equal('ipinfo.geo.location.lon', value?.lon),
      );
      blockNql = nqlLang.or(
        nqlLang.and(
          nqlLang.equal('srcgeo.location.lat', value?.lat),
          nqlLang.equal('srcgeo.location.lon', value?.lon),
        ),
        nqlLang.and(
          nqlLang.equal('dstgeo.location.lat', value?.lat),
          nqlLang.equal('dstgeo.location.lon', value?.lon),
        ),
      );
      break;
    /** * GEO ** */
    /** * IP ** */
    case 'srcip':
    case 'srcips':
      flowNql = getNqlWithAdditionalNql('srcip', value);
      dnsNql = flowNql;
      eventNql = getNqlWithAdditionalNql(
        'ipinfo.ip',
        value,
        nqlLang.equal('ipinfo.srcip', true),
      );
      blockNql = getNqlWithAdditionalNql('srcip', value);
      break;
    case 'flowsrcip':
      flowNql = getNqlWithAdditionalNql(field, value);
      eventNql = '';
      blockNql = '';
      break;
    case 'dstip':
    case 'dstips':
      flowNql = getNqlWithAdditionalNql('dstip', value);
      eventNql = getNqlWithAdditionalNql(
        'ipinfo.ip',
        value,
        nqlLang.equal('ipinfo.dstip', true),
      );
      blockNql = getNqlWithAdditionalNql('dstip', value);
      break;
    case 'nexthop':
      flowNql = getNqlWithAdditionalNql(field, value);
      eventNql = '';
      blockNql = '';
      break;
    case 'ipinfo.ip':
      flowNql = '';
      eventNql = getNqlWithAdditionalNql(field, value);
      blockNql = '';
      break;
    case 'ip':
    case 'ipintell':
    case 'addresslocal':
    case 'addressremote':
      flowNql = getNqlWithAdditionalNql(['srcip', 'dstip'], value);
      eventNql = getNqlWithAdditionalNql('ipinfo.ip', value);
      blockNql = getNqlWithAdditionalNql(['srcip', 'dstip'], value);
      break;
    /** * IP ** */
    /** * IP LABELS ** */
    case 'srcname': // deprecated field
    case 'srcipname':
    case 'srcipnames':
      flowNql = getNqlWithAdditionalNql('label.ip.all.src', value);
      eventNql = getNqlWithAdditionalNql(
        'ipinfo.ipname',
        value,
        nqlLang.equal('ipinfo.srcip', true),
      );
      blockNql = getNqlWithAdditionalNql('srcipname', value);
      break;
    case 'dstname': // deprecated field
    case 'dstipname':
    case 'dstipnames':
      flowNql = getNqlWithAdditionalNql('label.ip.all.dst', value);
      eventNql = getNqlWithAdditionalNql(
        'ipinfo.ipname',
        value,
        nqlLang.equal('ipinfo.dstip', true),
      );
      blockNql = getNqlWithAdditionalNql('dstipname', value);
      break;
    case 'label.ip':
    case 'ipinfo.ipname':
    case 'ipname':
    case 'addresslocalname':
    case 'addressremotename':
    case 'ipAddressname':
      flowNql = getNqlWithAdditionalNql('label.ip', value);
      eventNql = getNqlWithAdditionalNql('ipinfo.ipname', value);
      blockNql = getNqlWithAdditionalNql(['srcipname', 'dstipname'], value);
      break;
    case 'nexthopname':
      flowNql = getNqlWithAdditionalNql(field, value);
      eventNql = '';
      blockNql = '';
      break;
    /** * IP LABELS ** */
    /** * PORT ** */
    case 'srcport':
    case 'srcports':
      flowNql = getNqlWithAdditionalNql('srcport', value);
      dnsNql = flowNql;
      eventNql = getNqlWithAdditionalNql('srcports', value);
      blockNql = getNqlWithAdditionalNql('srcport', value);
      break;
    case 'dstport':
    case 'dstports':
      flowNql = getNqlWithAdditionalNql('dstport', value);
      eventNql = getNqlWithAdditionalNql('dstports', value);
      blockNql = getNqlWithAdditionalNql('dstport', value);
      break;
    case 'port':
      flowNql = getNqlWithAdditionalNql(['srcport', 'dstport'], value);
      eventNql = getNqlWithAdditionalNql(['srcports', 'dstports'], value);
      blockNql = getNqlWithAdditionalNql(['srcport', 'dstport'], value);
      break;
    /** * PORT ** */
    /** * PORT LABELS ** */
    case 'srcportname':
    case 'srcportnames':
      flowNql = getNqlWithAdditionalNql('label.port.all.src', value);
      eventNql = getNqlWithAdditionalNql('srcportnames', value);
      blockNql = getNqlWithAdditionalNql('srcportname', value);
      break;
    case 'dstportname':
    case 'dstportnames':
      flowNql = getNqlWithAdditionalNql('label.port.all.dst', value);
      eventNql = getNqlWithAdditionalNql('dstportnames', value);
      blockNql = getNqlWithAdditionalNql('dstportname', value);
      break;
    case 'label.port':
    case 'portname':
      flowNql = getNqlWithAdditionalNql('label.port', value);
      eventNql = getNqlWithAdditionalNql(
        ['srcportnames', 'dstportnames'],
        value,
      );
      blockNql = getNqlWithAdditionalNql(['srcportname', 'dstportname'], value);
      break;
    case 'answers.class':
    case 'query.class':
    case 'answers.type':
    case 'query.type':
      dnsNql = getNqlWithAdditionalNql(field, value);
      break;
    case 'answers.rdata':
      dnsNql = getNqlWithAdditionalNql(field, value);
      if (isIP(value)) {
        flowNql = getNqlWithAdditionalNql(['srcip', 'dstip'], value);
        eventNql = getNqlWithAdditionalNql('ipinfo.ip', value);
        blockNql = getNqlWithAdditionalNql(['srcip', 'dstip'], value);
      }
      break;
    /** * PORT LABELS ** */
    default:
      if (context === ContextTypes.flow) {
        flowNql = getNqlWithAdditionalNql(field, value);
      }
      if (context === ContextTypes.alerts) {
        eventNql = getNqlWithAdditionalNql(field, value);
      }
      if (context === ContextTypes.blocks) {
        blockNql = getNqlWithAdditionalNql(field, value);
      }
      if (context === ContextTypes.dns) {
        dnsNql = getNqlWithAdditionalNql(field, value);
      }
      break;
  }

  return {
    flowNql,
    dnsNql,
    eventNql,
    blockNql,
  };
};
