import PropTypes from '+prop-types';
import { Fragment } from 'react';

import Field from '+components/form/FinalForm/Field';
import { normalizeSelectValue } from '+components/form/Normalizers';
import RoleAuthFieldsSection from '+components/form/RoleAuthFieldSection';
import SelectField from '+components/form/SelectField';
import TextField from '+components/form/TextField';
import { validateRequired } from '+components/form/Validators';
import useProviderRegions from '+hooks/useProviderRegions';

const IbmCosFields = ({ disabled }) => {
  const regionOptions = useProviderRegions('ibm');
  return (
    <Fragment>
      <Field
        name="region"
        label="Region"
        component={SelectField}
        validate={validateRequired}
        options={regionOptions}
        parse={normalizeSelectValue}
        helperText="Location of the source"
        disabled={disabled}
        required
      />

      <Field
        name="bucket"
        label="Bucket"
        component={TextField}
        type="text"
        maxLength={255}
        autoComplete="new-password"
        validate={validateRequired}
        helperText="The COS bucket name"
        disabled={disabled}
        required
      />

      <Field
        name="prefix"
        label="Prefix"
        component={TextField}
        type="text"
        maxLength={255}
        autoComplete="new-password"
        style={{ width: '50%' }}
        helperText="Optional folder prefix"
        disabled={disabled}
      />
      <RoleAuthFieldsSection label="Authentication">
        <Field
          name="apikey"
          label="API Key"
          component={TextField}
          type="password"
          autoComplete="new-password"
          validate={validateRequired}
          helperText="The API key that is associated for the Service ID"
          disabled={disabled}
          required
        />

        <Field
          name="serviceinstanceid"
          label="Service Instance ID"
          component={TextField}
          type="text"
          validate={validateRequired}
          helperText="Unique identifier for the instance of Object Storage the credential accesses. This is also referred to as a service credential"
          disabled={disabled}
          required
        />
      </RoleAuthFieldsSection>
    </Fragment>
  );
};

IbmCosFields.propTypes = {
  disabled: PropTypes.bool,
};

IbmCosFields.defaultProps = {
  disabled: false,
};

export default IbmCosFields;
