import PropTypes from '+prop-types';
import { Fragment } from 'react';

import CheckBox from '+components/form/CheckBox';
import Field from '+components/form/FinalForm/Field';
import Description from '+components/form/FormField/components/Description';
import FieldContainer from '+components/form/FormField/components/FieldContainer';
import Group from '+components/form/FormField/components/Group';
import { normalizeSelectValue } from '+components/form/Normalizers';
import SelectField from '+components/form/SelectField';
import TextField from '+components/form/TextField';
import { validateRequired, validateNumber } from '+components/form/Validators';

const facilityOptions = [
  { value: 'LOG_KERN', label: 'LOG_KERN' },
  { value: 'LOG_USER', label: 'LOG_USER' },
  { value: 'LOG_MAIL', label: 'LOG_MAIL' },
  { value: 'LOG_DAEMON', label: 'LOG_DAEMON' },
  { value: 'LOG_AUTH', label: 'LOG_AUTH' },
  { value: 'LOG_SYSLOG', label: 'LOG_SYSLOG' },
  { value: 'LOG_LPR', label: 'LOG_LPR' },
  { value: 'LOG_NEWS', label: 'LOG_NEWS' },
  { value: 'LOG_UUCP', label: 'LOG_UUCP' },
  { value: 'LOG_CRON', label: 'LOG_CRON' },
  { value: 'LOG_AUTHPRIV', label: 'LOG_AUTHPRIV' },
  { value: 'LOG_FTP', label: 'LOG_FTP' },
  { value: 'LOG_LOCAL0', label: 'LOG_LOCAL0' },
  { value: 'LOG_LOCAL1', label: 'LOG_LOCAL1' },
  { value: 'LOG_LOCAL2', label: 'LOG_LOCAL2' },
  { value: 'LOG_LOCAL3', label: 'LOG_LOCAL3' },
  { value: 'LOG_LOCAL4', label: 'LOG_LOCAL4' },
  { value: 'LOG_LOCAL5', label: 'LOG_LOCAL5' },
  { value: 'LOG_LOCAL6', label: 'LOG_LOCAL6' },
  { value: 'LOG_LOCAL7', label: 'LOG_LOCAL7' },
];

const protocolOptions = [
  { value: 'udp', label: 'UDP' },
  { value: 'tcp', label: 'TCP' },
];

// As of PORTAL-11 release, we're only supporting CEF and CEE output formats
const outputOptions = [
  { value: 'CEF', label: 'CEF' },
  { value: 'CEE', label: 'CEE' },
  { value: 'DEFAULT', label: 'DEFAULT' },
  { value: 'LEEFv1', label: 'LEEFv1' },
  { value: 'LEEFv2', label: 'LEEFv2' },
  // { value: 'custom', label: 'CUSTOM' },
];

const PluginSyslog = ({ disabled }) => (
  <Fragment>
    <Field
      name="name"
      label="Name"
      component={TextField}
      type="text"
      disabled={disabled}
      required
      validate={validateRequired}
    />
    <Field
      name="description"
      label="Description"
      component={TextField}
      type="text"
      disabled={disabled}
    />
    <Field
      name="config.host"
      label="Host"
      component={TextField}
      type="text"
      disabled={disabled}
      required
      validate={validateRequired}
    />
    <Group>
      <FieldContainer>
        <Field
          name="config.skipsslverification"
          label="Skip SSL Verification"
          component={CheckBox}
          type="checkbox"
          disabled={disabled}
        />
      </FieldContainer>
      <Description>
        Don’t check the server certificate against the available certificate
        authorities. Also don’t require the URL host name to match the common
        name presented by the certificate
      </Description>
    </Group>
    <Field
      name="config.port"
      label="Port"
      component={TextField}
      type="number"
      disabled={disabled}
      required
      validate={[validateNumber, validateRequired]}
      style={{ width: '50%' }}
    />
    <Field
      name="config.facility"
      label="Facility"
      component={SelectField}
      options={facilityOptions}
      parse={normalizeSelectValue}
      disabled={disabled}
      required
      validate={validateRequired}
      style={{ width: '50%' }}
    />
    <Field
      name="config.protocol"
      label="Protocol"
      component={SelectField}
      options={protocolOptions}
      parse={normalizeSelectValue}
      disabled={disabled}
      required
      validate={validateRequired}
      style={{ width: '50%' }}
    />
    <Field
      name="config.tag"
      label="Syslog Tag"
      component={TextField}
      type="text"
      disabled={disabled}
      required
      validate={validateRequired}
      style={{ width: '50%' }}
      helperText="Dot separated “application tag” that will be included in messages"
    />
    <Field
      name="config.output"
      label="Output"
      component={SelectField}
      options={outputOptions}
      parse={normalizeSelectValue}
      disabled={disabled}
      required
      validate={validateRequired}
      style={{ width: '50%' }}
    />
  </Fragment>
);

PluginSyslog.propTypes = {
  disabled: PropTypes.bool,
};

PluginSyslog.defaultProps = {
  disabled: false,
};

export default PluginSyslog;
