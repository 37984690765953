import L from 'leaflet';

// TODO: Remove me after update to leaflet 1.9.0
// It's workaround to hide "listener not found" warning when passing maxBounds option to map
// @see: https://github.com/Leaflet/Leaflet/issues/7951
// @see: https://github.com/PaulLeCam/react-leaflet/issues/138
// eslint-disable-next-line no-console
const consoleWarningOrigin = console.warn.bind(console.warn);
// eslint-disable-next-line no-console
console.warn = (message, ...params) => {
  const suppressedMessage = message === 'listener not found';
  if (!suppressedMessage) {
    consoleWarningOrigin(message, ...params);
  }
};

export class Map {
  constructor(id, { map } = {}) {
    const { style, center, zoom, ...tail } = map || {};

    this.map = new L.Map(id, {
      preferCanvas: true,
      zoomControl: false,
      attributionControl: false,
      minZoom: 2,
      maxZoom: 12,
      zoomDelta: 1,
      zoomSnap: 0,
      center: center || [20, 0],
      zoom: zoom || 3,
      ...tail,
    });

    this.map.setView(this.map.options.center, this.map.options.zoom);
  }
}
