import PropTypes from '+prop-types';

import { Col } from '+components/Layout';
import { timestampFormatter } from '+components/Table/Cells/formatters';
import { DateFormat } from '+utils/dayjs';

import {
  BodyGroupCol,
  BodyItemRow,
  BodyNameCol,
  BodyValueCol,
} from '../../BodyComponents';

const AuditLogOverview = ({ padding, record }) => (
  <Col gap="14px">
    <BodyGroupCol paddingLeft={padding} paddingRight={padding}>
      <BodyItemRow>
        <BodyNameCol>Audit ID:</BodyNameCol>
        <BodyValueCol>{record.id}</BodyValueCol>
      </BodyItemRow>

      <BodyItemRow>
        <BodyNameCol>Class:</BodyNameCol>
        <BodyValueCol>{record?.class}</BodyValueCol>
      </BodyItemRow>

      <BodyItemRow>
        <BodyNameCol>Subclass:</BodyNameCol>
        <BodyValueCol>{record?.subclass}</BodyValueCol>
      </BodyItemRow>

      <BodyItemRow>
        <BodyNameCol>Action:</BodyNameCol>
        <BodyValueCol>{record?.action}</BodyValueCol>
      </BodyItemRow>

      <BodyItemRow>
        <BodyNameCol>User:</BodyNameCol>
        <BodyValueCol>{record?.user}</BodyValueCol>
      </BodyItemRow>

      <BodyItemRow>
        <BodyNameCol>Source:</BodyNameCol>
        <BodyValueCol>{record?.source}</BodyValueCol>
      </BodyItemRow>

      <BodyItemRow>
        <BodyNameCol>Description:</BodyNameCol>
        <BodyValueCol>{record?.description}</BodyValueCol>
      </BodyItemRow>

      <BodyItemRow>
        <BodyNameCol>Timestamp:</BodyNameCol>
        <BodyValueCol>
          {record?.timestamp
            && timestampFormatter(record.timestamp, DateFormat.minute)}
        </BodyValueCol>
      </BodyItemRow>
    </BodyGroupCol>
  </Col>
);

AuditLogOverview.propTypes = {
  padding: PropTypes.string.isRequired,
  record: PropTypes.shape().isRequired,
};

export default AuditLogOverview;
