import styled from 'styled-components';

import IconButton from '+components/IconButton';

const UnpinButton = styled(IconButton)`
  margin-left: auto;
  padding: 0;
  width: fit-content !important;
  height: fit-content !important;
  background: none;
  .unpin-icon {
    display: none;
  }
  :hover {
    background: none;
    .unpin-icon {
      display: block;
    }
    .pin-icon {
      display: none;
    }
  }
`;

export default UnpinButton;
