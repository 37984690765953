import { useEffect, useMemo, useState } from 'react';

import getItemSize from './getItemSize';

const useListHeight = (data, maxHeight) => {
  const [vh, setVh] = useState(0);

  useEffect(
    () => {
      const onResize = () => {
        setVh(document.documentElement.clientHeight * 0.01);
      };

      onResize();

      window.addEventListener('resize', onResize, true);

      return () => {
        window.removeEventListener('resize', onResize, true);
      };
    },
    [],
  );

  return useMemo(
    () => {
      const max = Math.max(maxHeight * vh, 0);
      let height = 0;
      const len = data.length;

      data.every((item, i) => {
        height += getItemSize(item);

        if (height > max && i < len - 2) {
          height = max;
          return false;
        }

        return true;
      });

      return height;
    },
    [vh, maxHeight, data],
  );
};

export default useListHeight;
