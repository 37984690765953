import PropTypes from '+prop-types';
import { useCallback, useContext, useEffect, useState } from 'react';

import LabelTypes from '@/models/LabelTypes';

import { Col } from '+components/Layout';
import { Tab } from '+components/Tabs';

import { Context } from '../../../MenuWrapper';
import { BodyContainer } from '../BodyComponents';
import NoData from '../NoData';
import TabContent from '../TabContent';
import Tabs from '../Tabs';
import TabsContainer from '../TabsContainer';
import Events from './components/Events';
import IpIntelligenceWrapper from './components/IpIntelligenceWrapper';
import Labels from './components/Labels';

const tabs = {
  labels: {
    value: 'labels',
    label: 'Labels',
  },
  ip_intell: {
    value: 'ip_intell',
    label: 'IP Intelligence',
  },
  events: {
    value: 'events',
    label: 'Events',
  },
};

const defaultTab = tabs.labels;

const FieldBody = (props) => {
  const { padding, activeData, isOpen } = props;

  const { onLabelClick, onLabelEdit } = useContext(Context);

  const [currentTab, setCurrentTab] = useState(defaultTab);

  const onTabChange = useCallback(
    (_, value) => {
      setCurrentTab(tabs[value]);
    },
    [],
  );

  useEffect(
    () => {
      setCurrentTab(defaultTab);
    },
    [activeData],
  );

  const isEmpty = !Object.values(LabelTypes).includes(activeData?.fieldType)
    && !activeData?.eventNql;

  return (
    <BodyContainer $padding={padding} $isOpen={isOpen}>
      {isOpen && isEmpty && activeData?.info && (
        <Col paddingLeft={padding} paddingRight={padding}>
          {activeData?.info}
        </Col>
      )}

      {isOpen && isEmpty && !activeData?.info && (
        <NoData paddingLeft={padding} paddingRight={padding}>
          No data to display
        </NoData>
      )}

      {isOpen && !isEmpty && (
        <TabsContainer>
          <Tabs
            $padding={padding}
            value={currentTab.value}
            onChange={onTabChange}
          >
            <Tab label={tabs.labels.label} value={tabs.labels.value} />
            <Tab
              label={tabs.ip_intell.label}
              value={tabs.ip_intell.value}
              disabled={activeData?.fieldType !== LabelTypes.ip}
            />
            <Tab label={tabs.events.label} value={tabs.events.value} />
          </Tabs>

          {currentTab.value === tabs.labels.value && (
            <TabContent>
              <Labels
                padding={padding}
                activeData={activeData}
                onLabelClick={onLabelClick}
                onLabelEdit={onLabelEdit}
              />
            </TabContent>
          )}
          {currentTab.value === tabs.ip_intell.value && (
            <TabContent>
              <IpIntelligenceWrapper
                padding={padding}
                activeData={activeData}
              />
            </TabContent>
          )}
          {currentTab.value === tabs.events.value && (
            <TabContent>
              <Events padding={padding} activeData={activeData} />
            </TabContent>
          )}
        </TabsContainer>
      )}
    </BodyContainer>
  );
};

FieldBody.propTypes = {
  padding: PropTypes.string.isRequired,
  activeData: PropTypes.shape({
    info: PropTypes.any,
    fieldType: PropTypes.string,
    value: PropTypes.any,
    eventNql: PropTypes.string,
    customer: PropTypes.string,
  }),
  isOpen: PropTypes.bool,
};

FieldBody.defaultProps = {
  activeData: null,
  isOpen: false,
};

export default FieldBody;
