/* eslint-disable react/prop-types */
import capitalize from 'lodash.capitalize';
import styled from 'styled-components';

import { ContextTypes, ContextTypesLabels } from '@/models/ContextTypes';

import TagOrigin from '+components/Tag';
import { selectors as contextTypesTheme } from '+theme/slices/contextTypes';

import CellWrapper from './CellWrapper';

const Tag = styled(TagOrigin)`
  padding: 0 8px;
  color: ${contextTypesTheme.trafficTypeColorText} !important;
  background-color: ${({ $context }) => contextTypesTheme[$context]
    ?? contextTypesTheme[ContextTypes.flow]} !important;
`;

const CellTrafficType = ({ field, value, original, disabled }) => (
  <CellWrapper
    customer={original?.customer}
    field={field}
    value={value}
    disabled={disabled}
    hideUnderline
  >
    <Tag
      $context={value}
      color="info"
      uppercased={false}
      outlined={false}
    >
      {ContextTypesLabels[value] || capitalize(value)}
    </Tag>
  </CellWrapper>
);

export default CellTrafficType;
