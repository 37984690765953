import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';

import authClient from '@/middleware/authClient';

import loader from '+utils/loader';

const Signin = () => {
  useEffect(
    () => {
      loader.setMessage('Redirect to IAM service... 60%').show();
      authClient.login();
    },
    [],
  );

  return null;
};

export const withProtectedArea = (Component) => (props) => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Signin />;
  }

  return <Component {...props} />;
};

export default withProtectedArea;
