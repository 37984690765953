import { useEffect, useRef } from 'react';
import { useDebounce } from 'react-use';

import get from 'lodash.get';
import isEqual from 'lodash.isequal';

import { useForm, useFormState } from '+components/form/FinalForm';
import usePageTabs from '+hooks/usePageTabs';

const usePageTabsAndFormSync = () => {
  const [, activePageTab, pageTabMethods] = usePageTabs();

  const form = useForm();

  const { values: formValues, dirty: formDirty } = useFormState({
    subscription: {
      values: true,
      dirty: true,
    },
  });

  const formValuesRef = useRef(formValues);
  formValuesRef.current = formValues;
  useEffect(
    () => {
      if (!activePageTab?.formValues) {
        return;
      }
      if (isEqual(activePageTab.formValues, formValuesRef.current)) {
        return;
      }
      const registeredFields = form.getRegisteredFields();
      form.batch(() => {
        registeredFields.forEach((field) => {
        // form.resetFieldState(field);
        // form.mutators.touched(field, true);
          form.change(field, get(activePageTab.formValues, field));
        });
      });
    },
    [activePageTab?.formValues],
  );

  // we need to give time to initialize the form before we start syncing
  useDebounce(
    () => {
      pageTabMethods.updateActive({
        formValues: formDirty ? formValues : null,
      });
    },
    100,
    [formValues, formDirty],
  );
};

export default usePageTabsAndFormSync;
