import PropTypes from '+prop-types';

import Table from '+components/Table';
import { NumberColumnFactory } from '+components/Table/Columns/NumberColumnFactory';

const columns = [
  NumberColumnFactory({
    accessor: 'prefixtotalcount',
    Header: 'Prefixes advertised total',
    textAlign: 'center',
    genericCell: true,
  }),
  NumberColumnFactory({
    accessor: 'prefixsessioncount',
    Header: 'Prefixes advertised current session',
    textAlign: 'center',
    genericCell: true,
  }),
  NumberColumnFactory({
    accessor: 'advertised',
    Header: 'Prefixes advertised currently',
    textAlign: 'center',
    genericCell: true,
  }),
  NumberColumnFactory({
    accessor: 'prefixrate',
    Header: 'Block Rate',
    Cell: ({ value }) => value?.toLocaleString(undefined, {
      maximumFractionDigits: 6,
    }),
    textAlign: 'center',
  }),
];

const TablePrefixes = (props) => {
  const { data } = props;

  return (
    <Table
      disableFilters
      disableSortBy
      disableDragAndDrop
      columns={columns}
      data={data}
      pagination={false}
      showManagerLayout={false}
      getCellProps={() => ({ style: { justifyContent: 'center' } })}
      minRows={0}
    />
  );
};

TablePrefixes.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
};

TablePrefixes.defaultProps = {
  data: [],
};

export default TablePrefixes;
