import { ContextTypes, ContextTypesLabels } from '@/models/ContextTypes';

export const trafficTypes = [
  {
    value: ContextTypes.flow,
    label: ContextTypesLabels.flow,
  },
  {
    value: ContextTypes.dns,
    label: ContextTypesLabels.dns,
  },
];
