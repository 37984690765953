import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/**
 * Hook for stats requests meta (metrics and fields).
 *
 * @param {Object} params
 * @param {boolean} params.metrics - if true, metrics will be fetched
 * @param {boolean} params.fields - if true, fields will be fetched
 * @param {Object} params.actions
 * @param {Object} params.selectors
 *
 * @return {{metrics: Object, fields: Object}}
 */
export const useStatsMeta = (params) => {
  const { metrics: fetchMetrics, fields: fetchFields, actions, selectors } = params;

  const dispatch = useDispatch();

  const metrics = useSelector(selectors.getMetrics);
  const fields = useSelector(selectors.getFields);

  const metricsLength = metrics?.length;
  useEffect(
    () => {
      if (fetchMetrics && !metricsLength) {
        dispatch(actions.fetchMetrics());
      }
    },
    [fetchMetrics, metricsLength],
  );

  const fieldsLength = fields?.length;
  useEffect(
    () => {
      if (fetchFields && !fieldsLength) {
        dispatch(actions.fetchFields());
      }
    },
    [fetchFields, fieldsLength],
  );

  return { metrics, fields };
};

export default useStatsMeta;
