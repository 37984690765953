import { ensurePluginOrder } from 'react-table';

const pluginName = 'useGroupByExpandArrayValues';

const getRowProps = (props, { row }) => ({
  ...props,
  key: `row_${row.newRowId || row.id}`,
});

const makeNewRowId = (rowId, combination) => {
  const values = Object.entries(combination).map(([columnId, value]) => {
    return `${columnId}-${
      typeof value === 'object' ? JSON.stringify(value) : value
    }`;
  });
  return `${rowId}-${values.join('-')}`;
};

// @see: https://stackoverflow.com/questions/8936610/how-can-i-create-every-combination-possible-for-the-contents-of-two-arrays
const combineObjects = ([head, ...[headTail, ...tailTail]]) => {
  if (!headTail) {
    return head;
  }

  const combined = headTail.reduce((acc, x) => {
    return acc.concat(head.map((h) => ({ ...h, ...x })));
  }, []);

  return combineObjects([combined, ...tailTail]);
};

/**
 * This hook, `useGroupByBeforeFilter`, is designed to handle groupBy columns that contain array data in a specific way.
 * If the data in a groupBy column is an array, the hook transforms it into a single-element array and creates additional rows for each element.
 *
 * Example: let's say we have a row with fields
 * { id: 1, name: 'John', age: 25, hobbies: ['swimming', 'running'], skills: ['react', 'node'] }
 * and we want to group by the hobbies and skills fields
 * The result will be 4 rows:
 * 1. { id: 1, name: 'John', age: 25, hobbies: ['swimming'], skills: ['react'] }
 * 2. { id: 2, name: 'John', age: 25, hobbies: ['running'], skills: ['react'] }
 * 3. { id: 3, name: 'John', age: 25, hobbies: ['swimming'], skills: ['node'] }
 * 4. { id: 4, name: 'John', age: 25, hobbies: ['running'], skills: ['node'] }
 * @param instance
 */
const useInstance = (instance) => {
  const {
    rows: rowsProp,
    state: { groupBy },
    plugins,
  } = instance;

  ensurePluginOrder(plugins, ['useGroupByBeforeFilter'], 'useFilters');

  if (!groupBy?.length) {
    return;
  }

  const rows = Object.values(
    rowsProp.reduce((acc, row) => {
      const combinationsHash = {};

      groupBy.forEach((columnId) => {
        const value = row.values[columnId];
        if (Array.isArray(value) && value.length) {
          value.forEach((val) => {
            combinationsHash[columnId] = Array.isArray(
              combinationsHash[columnId],
            )
              ? combinationsHash[columnId]
              : [];
            combinationsHash[columnId].push({ [columnId]: val });
          });
        }
      });

      const combinations = combineObjects(Object.values(combinationsHash))?.map(
        (combination) => {
          // make all values as array of one element (return array type to the field)
          Object.keys(combination).forEach((key) => {
            combination[key] = [combination[key]];
          });
          return combination;
        },
      );

      if (!combinations?.length) {
        acc[row.id] = row;
        return acc;
      }

      combinations.forEach((combination) => {
        const newRowId = makeNewRowId(row.id, combination);
        acc[newRowId] = {
          ...row,
          newRowId,
          values: { ...row.values, ...combination },
        };
      });

      return acc;
    }, {}),
  );

  Object.assign(instance, {
    rows,
  });
};

export const useGroupByExpandArrayValues = (hooks) => {
  hooks.useInstance.push(useInstance);
  hooks.getRowProps.push(getRowProps);
};

useGroupByExpandArrayValues.pluginName = pluginName;
