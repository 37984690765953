import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import PermissionModel from '@/models/Permission';

import {
  actions as dashboardsActions,
  selectors as dashboardsSelectors,
} from '@/redux/api/dashboards';

import usePermissions from '+hooks/usePermissions';

import { defaultWidgetProps } from '../shared/propTypes';
import WidgetForm from '../WidgetForm';

const WidgetFormAdd = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const permissions = usePermissions(PermissionModel.Resources.dashboard.value);

  const dashboard = useSelector(
    dashboardsSelectors.getDashboard(params.dashboardId),
  );

  useEffect(
    () => {
      if (!dashboard?.id && params.dashboardId) {
        dispatch(dashboardsActions.fetchDashboard({ id: params.dashboardId }));
      }
    },
    [dashboard?.id, params.dashboardId],
  );

  return !permissions?.update || !dashboard ? null : (
    <WidgetForm dashboard={dashboard} widget={defaultWidgetProps} />
  );
};

export default WidgetFormAdd;
