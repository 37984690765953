import styled from 'styled-components';

import GenericLabel, {
  IconContainer,
  ContextContainer,
} from '+components/Labels/GenericLabel';

const SubAccountTag = styled(GenericLabel)`
  border-width: 0;
  ${IconContainer}, ${ContextContainer} {
    * {
      color: ${({ theme }) => theme.tagSubAccountColor};
    }
    color: ${({ theme }) => theme.tagSubAccountColor};
    background-color: ${({ theme }) => theme.tagSubAccountBackground};
  }
`;
export default SubAccountTag;
