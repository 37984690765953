import './index.scss';
import { Engine } from './Engine';
import { Category } from './models/Category';
import { Dispatcher } from './models/Dispatcher';
import { Event } from './models/Event';

const defaultOption = {
  container: document.body,
  endpoint: {
    url: '',
    apiKey: '',
  },
  visualizationSpeed: 5,
  minDelayBetweenEvents: 300,
  maxDelayBetweenEvents: 2e3,
  keepLastProcessedData: 100,
};

const toEvent = (item) => new Event(item);

const sortEventsByTimestamp = ({ timestamp: a }, { timestamp: b }) => b - a;

export default class Application extends Dispatcher {
  constructor(options) {
    super();

    this._options = {
      ...JSON.parse(JSON.stringify(defaultOption)),
      ...options,
    };

    let { container } = this._options;
    if (container && !container.appendChild) {
      container = document.querySelector(container);
    }

    if (!container) {
      throw new Error('Target can not be empty');
    }

    this._initDispatcher('processedData', 'completedItem');

    this._categories = null;

    this._container = container;
    this._container.classList.add('map-visualization');

    this._mapContainer = document.createElement('div');
    this._mapContainer.classList.add('map');

    this._container.appendChild(this._mapContainer);

    this._engine = new Engine(this._mapContainer, this._options);
    this._engine.on('completed', (item) => {
      this._sendEvent('completedItem', item);
    });

    this._events = [];
    this._lastEventTs = 0;
    this._lastEventShowDate = performance.now();

    this._lastTime = performance.now();

    this._frames = 0;
    this._maxFps = 0;
    this._currentFps = 0;

    this._eventsQueue = this._eventsQueue.bind(this);
    this._eventsQueue();
  }

  _eventsQueue() {
    if (this._destroyed) {
      return;
    }

    requestAnimationFrame(this._eventsQueue);

    this._frames += 1;

    const time = performance.now();

    const { _events, _lastTime, _lastEventTs, _lastEventShowDate } = this;
    const { length } = _events;

    if (time >= _lastTime + 1e3) {
      this._currentFps = (this._frames * 1e3) / (time - _lastTime);
      this._frames = 0;
      this._lastTime = time;
      this._maxFps = Math.max(this._maxFps, this._currentFps);
    }

    if (!length || this._currentFps < this._maxFps * 0.7) {
      // console.log('ignored', length, this._currentFps, this._maxFps);
      return;
    }

    const { visualizationSpeed, minDelayBetweenEvents, maxDelayBetweenEvents } = this._options;

    const { timestamp } = _events[length - 1];

    const realTimeBetweenEvents = (timestamp - _lastEventTs) * 1e3;
    const timeBetweenEvents = Math.min(
      Math.max(
        realTimeBetweenEvents / (visualizationSpeed || 1),
        minDelayBetweenEvents,
      ),
      maxDelayBetweenEvents,
    );

    const delayBetweenEvents = time - _lastEventShowDate;

    if (timeBetweenEvents > delayBetweenEvents) {
      return;
    }

    let l = length;
    // eslint-disable-next-line no-plusplus
    while (l--) {
      const { timestamp: current } = _events[l];
      if (current !== timestamp) {
        break;
      }
    }

    const events = _events.splice(l, Math.max(length - l, 0));

    // console.log(length, events.length);

    this._lastEventTs = timestamp;
    this._lastEventShowDate = time;

    this._engine.updateData(events);

    this._sendEvent('processedData', [...events]);
  }

  get categories() {
    const items = Object.values(Category.getAllCategories());
    if (!this._categories || items.length !== this._categories.length) {
      this._categories = items;
    }

    return this._categories;
  }

  destroy() {
    if (this._destroyed) {
      return;
    }
    this._destroyed = true;
    this._events = null;
    this._engine.destroy();
    this._engine = null;
  }

  data(...args) {
    if (this._destroyed) {
      return this._events;
    }

    if (!args.length) {
      return this._events;
    }

    const [data] = args;

    this._events.unshift(...data.map(toEvent));
    this._events.sort(sortEventsByTimestamp);

    return this._events;
  }

  processedData() {
    return this._processedData;
  }

  resize() {
    if (this._engine && this._destroyed) {
      this._engine.resize();
    }
  }

  reset() {
    if (this._engine) {
      this._categories = null;
      this._engine.reset();
    }
  }
}
