import { Fragment, useCallback, useEffect } from 'react';

import uniq from 'lodash.uniq';
import Papa from 'papaparse';
import styled from 'styled-components';

import FileUploadField from '+components/form/FileUploadField';
import { Field, useForm, useFormState } from '+components/form/FinalForm';
import {
  validateIpOrCidrs,
  validateRequired,
} from '+components/form/Validators';
import FormModal, { propTypes, defaultProps } from '+components/FormModal';
import * as toast from '+components/toast';

const HelpTextContent = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colorTextSecondary};

  border-radius: 4px;
  background-color: ${({ theme }) => theme.colorFieldBackground};
  padding: 4px;
  margin-bottom: 8px;

  section {
    width: fit-content;
    margin-top: 2px;
    border: 1px solid ${({ theme }) => theme.colorFieldsBorder};
    border-radius: 4px;
    padding: 2px 8px;
  }

  code {
    color: ${({ theme }) => theme.colorTextSecondary};
  }
`;

const FormBody = () => {
  const form = useForm();
  const { values: formValues, errors } = useFormState({
    subscription: { values: true, errors: true },
  });

  const onImport = useCallback(
    (file) => {
      if (file) {
        Papa.parse(file, {
          complete: (results) => {
            const data = [];
            results.data.forEach((row) => {
              const [ip] = row;
              data.push(ip);
            });
            form.change('whitelist', data);
            form.mutators.touched('file', true);
          },
        });
      }

      return file;
    },
    [form],
  );

  useEffect(
    () => {
      if (errors.whitelist) {
        const fileErrors = errors.whitelist.split(', ');
        toast.error(
          `File contains ${fileErrors.length} error${
            fileErrors.length > 1 ? 's' : ''
          }. First error was found on line ${fileErrors[0]}`,
        );
        return;
      }

      const fileItems = formValues.whitelist;
      if (!fileItems) {
        return;
      }

      if (fileItems.length) {
        toast.info(
          `File contains ${fileItems.length} item${
            fileItems.length > 1 ? 's' : ''
          } that are ready to be added.`,
        );
      } else {
        toast.error('File is empty');
      }
    },
    [errors.whitelist, formValues.whitelist],
  );

  return (
    <Fragment>
      <Field
        name="file"
        component={FileUploadField}
        accept=".csv,.txt"
        parse={onImport}
        validate={validateRequired}
        required
      />

      <HelpTextContent>
        Must be a TXT or CSV file in this format: no headers, one IP address per
        line.
        <br />
        Example:
        <section>
          <code>
            000.000.0.1
            <br />
            000.000.0.2
            <br />
            000.000.0.3
          </code>
        </section>
      </HelpTextContent>
    </Fragment>
  );
};

const ImportCsvForm = (props) => {
  const { initialValues, isOpen, onToggle } = props;

  const form = useForm();
  const { values: formValues } = useFormState({
    subscription: { values: true },
  });

  const onValidate = useCallback(
    (values) => {
      const errors = {};
      const whitelist = values.whitelist || [];
      if (!whitelist.length) {
        errors.file = 'File is empty';
      } else {
        const validationResult = validateIpOrCidrs(whitelist);
        errors.whitelist = validationResult || undefined;
        errors.file = validationResult ? 'File contains errors' : undefined;
      }
      return errors;
    },
    [],
  );

  const onSubmit = useCallback(
    (values) => {
      const uniqWhitelists = uniq([
        ...values.whitelist,
        ...formValues.whitelist,
      ]);
      const newItemsCount = uniqWhitelists.length - formValues.whitelist.length;
      if (newItemsCount > 0) {
        form.change('whitelist', uniqWhitelists);
        toast.success(
          `Added ${newItemsCount} new item${
            newItemsCount > 1 ? 's' : ''
          }. Don't forget to press "Save" button.`,
        );
      } else {
        toast.info('There are no new items to add.');
      }
      onToggle();
    },
    [form, formValues, onToggle],
  );

  return (
    <FormModal
      mode="import"
      item="IP addresses"
      confirmButtonText="Import"
      initialValues={initialValues}
      validate={onValidate}
      isOpen={isOpen}
      onToggle={onToggle}
      onSubmit={onSubmit}
      focusOnFields={false}
      labelOnTop
    >
      <FormBody />
    </FormModal>
  );
};

ImportCsvForm.propTypes = {
  initialValues: propTypes.initialValues,
  isOpen: propTypes.isOpen,
  onToggle: propTypes.onToggle,
};

ImportCsvForm.defaultProps = {
  initialValues: defaultProps.initialValues,
  isOpen: defaultProps.isOpen,
  onToggle: defaultProps.onToggle,
};

export default ImportCsvForm;
