import PropTypes from '+prop-types';

import styled from 'styled-components';

import ChevronDoubleLeftIcon from 'mdi-react/ChevronDoubleLeftIcon';
import ChevronDoubleRightIcon from 'mdi-react/ChevronDoubleRightIcon';

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 23px;
  margin: 13px 0 0 5px;
  border: none;
  border-radius: 11.5px;
  cursor: pointer;
  color: ${({ theme }) => theme.colorSideBarLeftGroupIcon};
  background-color: ${({ theme }) => theme.sideBarGroupItemBackground};
  
  :hover {
    background-color: ${({ theme }) => theme.sideBarGroupItemHoverBackground};
  }
  
  &:focus {
    outline: none;
  }

  .mdi-icon  {
    width: 15px;
  }
}
`;

const CollapseButton = (props) => {
  const { collapse, changeVisibility } = props;

  return (
    <Button
      style={{ marginTop: 'auto', marginBottom: '15px' }}
      type="button"
      className="sidebar__button--desktop"
      onClick={changeVisibility}
      data-tracking="sidebar-collapse-button"
    >
      {collapse ? <ChevronDoubleRightIcon /> : <ChevronDoubleLeftIcon />}
    </Button>
  );
};

CollapseButton.propTypes = {
  collapse: PropTypes.bool.isRequired,
  changeVisibility: PropTypes.func.isRequired,
};

export default CollapseButton;
