import PropTypes from '+prop-types';
import { useMemo } from 'react';

import getChartTableColumns from '+components/charts/common/getChartTableColumns';
import Table from '+components/Table';
import useGlobalFilters from '+hooks/useGlobalFilters';

import { columnsCollection } from './TableColumns';
/*
min: 1.15
child: "box2.sjc1.lyonlabs.com"
avg: 2.173770491803279
max: 4
code: 200
data: Array(3)
0:
count: 61
data: (61) [{…}, {…}, ..., {…}, {…}]
metric: "flowrate"
name: "xYYYx.box2.sjc1.lyonlabs.com"
parent: "xYYYx"
percentiles: {25.0: 1.95, 50.0: 2, 95.0: 3.2, 98.0: 3.6}
std_deviation: 0.599289644890955
std_deviation_lower: 0.9751912020213691
std_deviation_upper: 3.3723497815851893
sum: 132.60000000000002
variance: 0.359148078473527
*/

const sortBy = [
  {
    id: 'sum',
    desc: true,
  },
];

const hiddenColumns = ['count'];

const TrafficMinerTable = (props) => {
  const { fields, series, noDataText, context } = props;

  const [filters] = useGlobalFilters();

  const { columns, availableColumns } = useMemo(
    () => {
      const chartTableColumns = getChartTableColumns({
        fields,
        context,
        showLabel: true,
        labelContext: filters.labelContext,
      });
      return {
        columns: [...chartTableColumns.columns, ...columnsCollection(context)],
        availableColumns: chartTableColumns.availableColumns,
      };
    },
    [fields, context, filters.labelContext],
  );

  return (
    <Table
      id="Search_TrafficMiner_Table"
      columns={columns}
      availableColumns={availableColumns}
      hiddenColumns={hiddenColumns}
      data={series}
      sortBy={sortBy}
      style={{ marginTop: '8px' }}
      noDataText={noDataText}
      disableDuplicateBy
      disableAggregators
      disableGroupBy
    />
  );
};

TrafficMinerTable.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.string),
  series: PropTypes.arrayOf(PropTypes.shape({})),
  noDataText: PropTypes.string,
  context: PropTypes.string,
};

TrafficMinerTable.defaultProps = {
  fields: [],
  series: undefined,
  noDataText: undefined,
  context: '',
};

export default TrafficMinerTable;
