import PropTypes from '+prop-types';
import { Fragment } from 'react';

import Field from '+components/form/FinalForm/Field';
import { normalizeSelectValue } from '+components/form/Normalizers';
import RoleAuthFieldsSection from '+components/form/RoleAuthFieldSection';
import SelectField from '+components/form/SelectField';
import TextField from '+components/form/TextField';
import { validateRequired } from '+components/form/Validators';

const crowdstrikeOptions = [
  { label: 'US 1', value: 'us-1' },
  { label: 'US 2', value: 'us-2' },
  { label: 'US 3', value: 'us-3' },
  { label: 'US GOV 1', value: 'us-gov-1' },
];
const GetCrowdstrikeFields = (configObjectName) => {
  const CrowdstrikeFields = ({ disabled }) => {
    return (
      <Fragment>
        <Field
          name={`${configObjectName}.cloud`}
          label="Cloud Abbreviation"
          component={SelectField}
          validate={validateRequired}
          options={crowdstrikeOptions}
          parse={normalizeSelectValue}
          helperText="The falcon cloud to query."
          disabled={disabled}
          required
        />

        <Field
          name={`${configObjectName}.filter`}
          label="Filter"
          component={TextField}
          type="text"
          helperText="An optional FQL string to be used when filtering results. Eg. entity_type:'managed'+last_seen_timestamp:<'now-3d'"
          disabled={disabled}
        />

        <Field
          name={`${configObjectName}.sort`}
          label="Sort"
          component={TextField}
          type="text"
          helperText="An optional FQL sort string. Eg. last_seen_timestamp.desc"
          disabled={disabled}
        />

        <RoleAuthFieldsSection label="Authentication">
          <Field
            name={`${configObjectName}.clientid`}
            label="Client ID"
            component={TextField}
            type="text"
            validate={validateRequired}
            helperText="Client id to use authenticating with Falcon Cloud API"
            disabled={disabled}
            required
          />

          <Field
            name={`${configObjectName}.clientsecret`}
            label="Client Secret"
            component={TextField}
            type="password"
            autoComplete="off"
            validate={validateRequired}
            helperText="Client secret to use authenticating with Falcon Cloud API"
            disabled={disabled}
            required
          />
        </RoleAuthFieldsSection>
      </Fragment>
    );
  };

  CrowdstrikeFields.propTypes = {
    disabled: PropTypes.bool,
  };

  CrowdstrikeFields.defaultProps = {
    disabled: false,
  };

  return CrowdstrikeFields;
};

export default GetCrowdstrikeFields;
