import PropTypes from '+prop-types';
import { useState } from 'react';

import Button from './Button';

const ArrayContainer = (props) => {
  const { rootItem, itemCount, children } = props;
  const [hide, setHide] = useState(rootItem ? itemCount > 1 : false);
  return hide ? (
    <Button type="button" onClick={() => setHide(false)}>
      show {itemCount} items...
    </Button>
  ) : (
    <div>{children}</div>
  );
};

ArrayContainer.propTypes = {
  rootItem: PropTypes.bool.isRequired,
  itemCount: PropTypes.number.isRequired,
  children: PropTypes.children.isRequired,
};

export default ArrayContainer;
