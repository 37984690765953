import TechnicalColumns from '+components/Table/ReactTable/TechnicalColumns';

import { Expander } from '../components/Components';
import { canBeExpanded } from '../utils';

// eslint-disable-next-line react/prop-types
const Header = ({ isAllRowsExpanded }) => (
  <Expander $open={isAllRowsExpanded} />
);

// eslint-disable-next-line react/prop-types
const Cell = (sub, expandSingleSubRow) => ({ row: { isExpanded, subRows } }) => {
  return (
    (sub || canBeExpanded(subRows, expandSingleSubRow)) && (
      <Expander $open={isExpanded} data-tracking="row-expander" />
    )
  );
};

const visibleColumns = (props) => (
  columns,
  { instance: { state: { groupBy } } },
) => {
  const { SubComponent, expanderInHeader, expandSingleSubRow } = props;

  const groupByColumns = groupBy
    .map((g) => columns.find((col) => col.id === g))
    .filter(Boolean);

  if (!SubComponent && !groupByColumns?.length) {
    return columns.filter((col) => col.id !== TechnicalColumns.expander);
  }

  const expanderColumn = columns.find(
    (col) => col.id === TechnicalColumns.expander,
  );
  if (expanderColumn) {
    return columns;
  }

  const column = {
    id: TechnicalColumns.expander,
    disableFilters: true,
    disableGroupBy: true,
    disableAggregators: true,
    disableDuplicateBy: true,
    disableResizing: true,
    disableSortBy: true,
    disableDragAndDrop: true,
    disableContextMenu: true,
    disableHide: true,
    disableTooltip: true,
    disableExport: true,
    minWidth: 40,
    maxWidth: 40,
    expander: true,
    dependsOnIndex: true,
    Header: expanderInHeader ? Header : '',
    Cell: Cell(Boolean(SubComponent), expandSingleSubRow),
  };

  return [column, ...columns];
};

export const useExpanderColumn = (props) => (hooks) => {
  hooks.visibleColumns.push(visibleColumns(props));
};

useExpanderColumn.pluginName = 'useExpanderColumn';
