import constate from 'constate';

import childrenMap from '+utils/childrenMap';

import { useMenu } from './useMenu';

export const [Provider, useMenuState, useMenuActions] = constate(
  useMenu,
  ({ state }) => state,
  ({ actions }) => actions,
);

export const StateConsumer = ({ children }) => {
  const menuState = useMenuState();

  return childrenMap(children, (props, order) => ({
    ...props,
    key: props?.key ?? order,
    menuState,
  }));
};

export const ActionConsumer = ({ children }) => {
  const menuActions = useMenuActions();
  return childrenMap(children, (props, order) => ({
    ...props,
    key: props?.key ?? order,
    menuActions,
  }));
};
