import styled from 'styled-components';

export default styled.div`
  font-size: 12px;
  display: flex;
  position: relative;
  align-items: center;
  padding: 3px 15px;
  cursor: pointer;
  flex-wrap: nowrap;

  border-left: 1px solid
    ${({ $selected, theme }) => ($selected ? theme.colorAccent : 'transparent')};
  opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};
  pointer-events: ${({ $disabled }) => ($disabled ? 'none' : null)};

  &:hover {
    background: ${({ theme }) => theme.contextMenuItemHoverBackground};
  }
`;
