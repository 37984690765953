import styled from 'styled-components';

export const SubDataValue = styled(({ children, ...props }) => (
  <div {...props}>
    <div>{children}</div>
  </div>
))`
  display: table-cell;
  font-size: 13px;
  line-height: calc(18 / 13);
  font-weight: 400;
  overflow: hidden;

  > div {
    display: inline-block;
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
