import PropTypes from '+prop-types';
import { useCallback, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import classNames from 'classnames';

import { Chip } from '@mui/material';

const Tag = (props) => {
  const { className, id, index, disabled, onTagMove, ...tail } = props;

  const ref = useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept: 'tag',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      onTagMove({ oldIndex: dragIndex, newIndex: hoverIndex });
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'tag',
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  // drag not working without this
  const onMouseDown = useCallback(
    (e) => {
      e.stopPropagation();
    },
    [],
  );

  return (
    <Chip
      {...tail}
      ref={disabled ? undefined : ref}
      className={classNames(className, { isDragging })}
      data-handler-id={handlerId}
      disabled={disabled}
      onMouseDown={onMouseDown}
    />
  );
};

Tag.propTypes = {
  className: PropTypes.string,
  id: PropTypes.any.isRequired,
  index: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  onTagMove: PropTypes.func.isRequired,
};

Tag.defaultProps = {
  className: '',
  disabled: false,
};

export default Tag;
