import { Fragment } from 'react';

import { Field } from '+components/form/FinalForm';
import TextField from '+components/form/TextField';
import { validateRequired } from '+components/form/Validators';

const WidgetTitleFields = () => {
  return (
    <Fragment>
      <Field
        name="title"
        label="Name"
        autoComplete="new-password"
        component={TextField}
        validate={validateRequired}
        required
      />
      <Field name="description" label="Description" component={TextField} />
    </Fragment>
  );
};

export default WidgetTitleFields;
