import { Config } from '../shared/utils';

export const paramsToUi = (params) => {
  const values = { ...params };

  if (!params.thresholds?.length) {
    values.thresholds = [Config.defaultThresholds];
  }

  return values;
};

export const uiToParams = (values) => {
  const params = { ...values };

  params.thresholds = (params.thresholds || []).filter(
    (item) => !!item.threshold?.trim(),
  );

  return params;
};
