import { color } from 'd3-color';
import { scaleOrdinal } from 'd3-scale';
import { schemeSet1 } from 'd3-scale-chromatic';

const colorsSchema = scaleOrdinal(schemeSet1);

const defaultCategoryOptions = {
  options: {
    showOnMap: true,
    showPath: true,
    showSourcePoint: true,
    showTargetPoint: true,
    showPopup: false,
    showNotice: false,
  },
  style: {
    category: {},
    path: {
      lineWidth: 1,
      speed: 2,
    },
    sourcePoint: {
      radius: 20,
      waveLength: 20,
      speed: 0.5,
      lineWidth: 2,
      isWaveMovingForward: true,
    },
    targetPoint: {
      radius: 20,
      waveLength: 20,
      speed: 0.24,
      lineWidth: 2,
      isWaveMovingForward: false,
    },
  },
};

const getCategoryOptions = (name) => {
  const autoColor = colorsSchema(name);

  const { options, style } = {
    ...JSON.parse(JSON.stringify(defaultCategoryOptions)),
  };

  style.category.color = color(style.category.color || autoColor);
  style.path.color = color(style.path.color || autoColor);
  style.sourcePoint.color = color(style.sourcePoint.color || autoColor);
  style.targetPoint.color = color(style.targetPoint.color || autoColor);

  return { options, style };
};

let categoriesHash = {};

export class Category {
  static clearCache() {
    categoriesHash = {};
  }

  static getAllCategories() {
    return categoriesHash;
  }

  static create(name, flowsrcname, customer) {
    name = (name || 'undefined').toLowerCase();

    if (categoriesHash[name]) {
      categoriesHash[name].value += 1;
      if (customer && !categoriesHash[name].customer.includes(customer)) {
        categoriesHash[name].customer.push(customer);
      }
      return categoriesHash[name];
    }

    const { options, style } = getCategoryOptions(name);

    categoriesHash[name] = new Category(
      name,
      options,
      style,
      flowsrcname,
      customer,
    );

    return categoriesHash[name];
  }

  constructor(name, options, style, flowsrcname, customer) {
    this.name = name;
    this.flowsrcname = flowsrcname;
    this.customer = customer ? [customer] : [];

    this.options = options;
    this.style = style;

    this.checked = true;
    this.value = 1;
  }

  toString() {
    return this.name;
  }
}
