import { OutlineFilter } from '@pixi/filter-outline';
import * as PIXI from 'pixi.js-legacy';
import * as THREE from 'three';

import { colorStringToHex, updateLabelsGraphic } from '+utils/pixijsUtils';

import { updateObjectAABB } from './Cull';
import {
  getSourceX,
  getSourceY,
  getTargetX,
  getTargetY,
  getParticleScale,
} from './extractors';

const CubicBezierP0 = (t, p) => {
  const k = 1 - t;
  return k * k * k * p;
};

const CubicBezierP1 = (t, p) => {
  const k = 1 - t;
  return 3 * k * k * t * p;
};

const CubicBezierP2 = (t, p) => 3 * (1 - t) * t * t * p;

const CubicBezierP3 = (t, p) => t * t * t * p;

const CubicBezier = (t, p0, p1, p2, p3) => CubicBezierP0(t, p0)
  + CubicBezierP1(t, p1)
  + CubicBezierP2(t, p2)
  + CubicBezierP3(t, p3);

const getControlPoints = (sx, sy, tx, ty) => [
  0.15 * ty - 0.15 * sy + 0.8 * sx + 0.2 * tx,
  0.8 * sy + 0.2 * ty - 0.15 * tx + 0.15 * sx,
  0.15 * ty - 0.15 * sy + 0.2 * sx + 0.8 * tx,
  0.2 * sy + 0.8 * ty - 0.15 * tx + 0.15 * sx,
];

const getBezierAngle = (t, p0x, p0y, p1x, p1y, p2x, p2y, p3x, p3y) => {
  const k = 1 - t;
  const dx = k ** 2 * (p1x - p0x) + 2 * t * k * (p2x - p1x) + t * t * (p3x - p2x);
  const dy = k ** 2 * (p1y - p0y) + 2 * t * k * (p2y - p1y) + t * t * (p3y - p2y);
  return Math.atan2(dx, dy);
};

const hoveredFilter = new OutlineFilter(2, 0x61bbd9, 1);
const pinnedFilter = new OutlineFilter(2, 0x61d9bb, 1);

const applyOpacity = (graphic, opacity, interactiveProp = 'interactive') => {
  if (graphic.alpha === opacity) {
    return;
  }

  graphic.alpha = opacity;

  graphic.visible = opacity > 0;
  if (interactiveProp) {
    graphic[interactiveProp] = graphic.visible;
  }
};

export function drawNode() {
  const { graphic } = this;
  if (!graphic?.parent?.worldVisible) {
    return;
  }

  const circle = graphic.getChildByName('circle');
  const point = graphic.getChildByName('point');
  const background = graphic.getChildByName('background');
  const title = graphic.getChildByName('title');
  const labels = graphic.getChildByName('labels');

  const attrs = this.attributes;
  const anchorX = +(attrs.anchorX?.value ?? 0);
  const anchorY = +(attrs.anchorY?.value ?? 0);
  const x = +attrs.cx?.value + +(attrs.offsetX?.value ?? 0);
  const y = +attrs.cy?.value + +(attrs.offsetY?.value ?? 0);
  const radius = Math.max(+attrs.r?.value, 1);
  const pointRadius = Math.max(radius * 0.5, 0.5);
  const opacity = Math.max(+attrs.opacity?.value, 0);
  const selected = attrs.selected?.value;

  applyOpacity(graphic, opacity);

  let hasChanged = labels
    && updateLabelsGraphic({
      labels,
      visible: attrs.showLabel?.value === 'true',

      borderColor: attrs.labelBorderColor?.value,

      text: attrs.label?.value,
      color: attrs.labelColor?.value,
      background: attrs.labelBgColor?.value,

      contextText: attrs.labelContext?.value,
      contextColor: attrs.labelContextColor?.value,
      contextBackground: attrs.labelContextBgColor?.value,

      countText: attrs.labelCount?.value,
      countColor: attrs.labelCountColor?.value,
      countBackground: attrs.labelCountBgColor?.value,
    });

  hasChanged = (title
      && updateLabelsGraphic({
        labels: title,
        visible: attrs.showLabel?.value === 'false',

        borderColor: attrs.labelBorderColor?.value,

        text: attrs.text?.value,
        color: attrs.labelColor?.value,
        background: attrs.labelBgColor?.value,
      }))
    || hasChanged;

  if (hasChanged) {
    graphic.updateAnchor();
  }

  const dirty = graphic.x !== x
    || graphic.y !== y
    || (circle && circle._radius !== radius)
    || hasChanged;

  if (!(dirty || graphic.visible)) {
    return;
  }

  const data = this.__data__;

  let tint;
  if (circle) {
    tint = colorStringToHex(attrs.fill?.value);
    if (tint !== circle.tint) {
      circle.tint = tint;
    }

    if (circle._radius !== radius) {
      circle.width = radius * 2;
      circle.height = radius * 2;
      circle._radius = radius;
      graphic.hitArea = new PIXI.Circle(0, 0, radius * 1.1);
    }

    circle.filters = [
      data.fx != null && pinnedFilter,
      data.hovered && hoveredFilter,
    ]
      .filter(Boolean)
      .slice(-1);
  }

  if (point) {
    point.visible = !!selected;
  }

  if (point?.visible) {
    tint = colorStringToHex(selected);
    if (tint !== point.tint) {
      point.tint = tint;
    }

    if (point._radius !== pointRadius) {
      point.width = pointRadius * 2;
      point.height = pointRadius * 2;
      point._radius = pointRadius;
    }
  }

  if (background) {
    tint = colorStringToHex(attrs.background?.value);
    if (tint !== background.tint) {
      background.tint = tint;
    }

    background.filters = [
      data.fx != null && pinnedFilter,
      data.hovered && hoveredFilter,
    ]
      .filter(Boolean)
      .slice(-1);
  }

  graphic.x = x;
  graphic.y = y;

  if (anchorX) {
    graphic.x += graphic.width * anchorX;
  }

  if (anchorY) {
    graphic.y += graphic.height * anchorY;
  }

  if (dirty) {
    updateObjectAABB(graphic);
  }
}

export const drawLink = function () {
  const { graphic } = this;
  if (!graphic?.parent?.worldVisible) {
    return;
  }

  const attrs = this.attributes;

  const opacity = Math.max(+attrs.opacity?.value, 0);
  const isVisible = (attrs.visible?.value ?? 'true') === 'true' && opacity > 0;

  if (!isVisible) {
    return;
  }

  const sx = +attrs.x0?.value;
  const sy = +attrs.y0?.value;
  const tx = +attrs.x1?.value;
  const ty = +attrs.y1?.value;

  const [x3, y3, x4, y4] = getControlPoints(sx, sy, tx, ty);

  const color = attrs.stroke?.value;
  const width = Math.max(+attrs.width?.value, 1);

  const native = width <= 1;

  graphic.lineStyle({
    width,
    color: colorStringToHex(color),
    alpha: opacity,
    native,
    cap: native ? PIXI.LINE_CAP.BUTT : PIXI.LINE_CAP.ROUND,
  });

  graphic.moveTo(sx, sy);
  graphic.bezierCurveTo(x3, y3, x4, y4, tx, ty);

  if (attrs.arrow?.value !== 'true') {
    return;
  }

  const pos = 0.7;

  const point = {
    x: CubicBezier(pos, sx, x3, x4, tx),
    y: CubicBezier(pos, sy, y3, y4, ty),
  };

  const angle = getBezierAngle(pos, sx, sy, x3, y3, x4, y4, tx, ty);

  const edge = width * 0.5 + 10;

  graphic.lineStyle({ alpha: 0 });

  graphic.beginFill(colorStringToHex(color), Math.max(opacity, 0.3));

  graphic.moveTo(point.x, point.y);
  graphic.lineTo(
    point.x + edge * Math.cos(angle + 1),
    point.y - edge * Math.sin(angle + 1),
  );
  graphic.lineTo(
    point.x - 5 * Math.cos(0.5 * Math.PI - angle),
    point.y - 5 * Math.sin(0.5 * Math.PI - angle),
  );
  graphic.lineTo(
    point.x - edge * Math.cos(angle - 1),
    point.y + edge * Math.sin(angle - 1),
  );
  graphic.lineTo(point.x, point.y);

  graphic.endFill();
};

export function drawParticle() {
  const { graphic } = this;
  if (!graphic?.parent?.worldVisible) {
    return;
  }

  const data = this.__data__;

  if (!data.source) {
    graphic.visible = false;
    return;
  }

  const attrs = this.attributes;

  const position = +attrs.position?.value;

  const dirty = graphic._particlePosition !== position;
  graphic._particlePosition = position;

  const opacity = Math.max(+attrs.opacity?.value, 0);

  applyOpacity(graphic, opacity);

  if (!(dirty || graphic.visible)) {
    return;
  }

  let fill = attrs.fill?.value;
  if (fill) {
    fill = colorStringToHex(fill);
    if (fill !== graphic.tint) {
      graphic.tint = fill;
    }
  }

  const sx = getSourceX(data);
  const sy = getSourceY(data);
  const tx = getTargetX(data);
  const ty = getTargetY(data);

  const [x3, y3, x4, y4] = getControlPoints(sx, sy, tx, ty);

  const path = new THREE.Path();

  path.moveTo(sx, sy);
  path.bezierCurveTo(x3, y3, x4, y4, tx, ty);

  const point = path.getPoint(position);

  if (point) {
    graphic.x = point.x;
    graphic.y = point.y;
  }

  const radius = Math.max(+attrs.r?.value, 1);

  const scale = getParticleScale(radius * Math.max(+attrs.scale?.value, 1));

  if (graphic.scale.x !== scale) {
    graphic.scale.x = scale;
    graphic.scale.y = scale;
  }

  if (dirty) {
    updateObjectAABB(graphic);
  }
}

export function drawGroup() {
  const { graphic } = this;
  if (!graphic) {
    return;
  }

  const attrs = this.attributes;

  const opacity = Math.min(1, Math.max(+attrs.opacity?.value, 0));

  applyOpacity(graphic, opacity, 'interactiveChildren');
}
