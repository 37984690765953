import styled from 'styled-components';

import GenericTag from './GenericTag';

const BetaTag = styled(GenericTag).attrs({
  color: 'success',
  outlined: false,
})`
  padding: 0 3px;
  font-size: 10px;
  font-weight: 700;
  min-height: unset;
  line-height: 14px;
  border-radius: 3px !important;
  border: none !important;
`;

export default BetaTag;
