import PropTypes from '+prop-types';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectors as customerSelectors } from '@/redux/api/customer';
import {
  actions as ruleActions,
  selectors as rulesSelectors,
} from '@/redux/api/rules';

import Table from '+components/Table';

import { Columns, getColumns } from './components/Columns';

const sortBy = [
  {
    id: 'count',
    desc: true,
  },
];

const EventSummaryTable = (props) => {
  const { columns: columnsProps, data, userFilters, ...tail } = props;

  const dispatch = useDispatch();

  const customer = useSelector(customerSelectors.getCurrentCustomer);
  const algorithms = useSelector(rulesSelectors.getAlgorithms);
  const categories = useSelector(rulesSelectors.getCategories);

  const algorithmsHash = useMemo(
    () => Object.values(algorithms || {}).reduce(
      (acc, item) => ({ ...acc, [item.name]: item }),
      {},
    ),
    [algorithms],
  );

  const categoriesHash = useMemo(
    () => categories?.reduce((acc, item) => ({ ...acc, [item.name]: item }), {}),
    [categories],
  );

  const columns = useMemo(
    () => {
      if (customer?.isReseller) {
        const indexOfCustomerColumn = columnsProps.indexOf(Columns.customer);
        if (indexOfCustomerColumn === -1) {
          return [...columnsProps, Columns.customer];
        }
      }
      return columnsProps;
    },
    [columnsProps, customer],
  );

  const tableColumns = useMemo(
    () => getColumns(columns, {
      algorithms: algorithmsHash,
      categories: categoriesHash,
      userFilters,
    }),
    [algorithmsHash, categoriesHash, userFilters],
  );

  const algorithmsLength = Object.keys(algorithms || {}).length;
  useEffect(
    () => {
      if (!algorithmsLength && data?.length) {
        dispatch(ruleActions.fetchAlgorithms());
      }
    },
    [algorithmsLength, data?.length],
  );

  useEffect(
    () => {
      if (!categories?.length && data?.length) {
        dispatch(ruleActions.fetchCategories());
      }
    },
    [categories?.length, data?.length],
  );

  return (
    <Table
      id="Event Summary"
      columns={tableColumns}
      data={data}
      sortBy={sortBy}
      {...tail}
    />
  );
};

const tableColumn = PropTypes.oneOf(Object.values(Columns || {}));

EventSummaryTable.propTypes = {
  columns: PropTypes.arrayOf(tableColumn),
  data: PropTypes.arrayOf(PropTypes.shape({})),
  userFilters: PropTypes.shape({}),
  noDataText: PropTypes.string,
};

EventSummaryTable.defaultProps = {
  columns: [
    Columns.algorithm,
    Columns.categories,
    Columns.count,
    Columns.severity,
    Columns.menu,
  ],
  data: [],
  userFilters: {},
  noDataText: 'No data to show',
};

export { Columns };
export default EventSummaryTable;
