import PropTypes from '+prop-types';
import { useCallback, useState } from 'react';

import styled from 'styled-components';

import AlertOrigin from '@mui/material/Alert';

const Container = styled(AlertOrigin)`
  font-size: 13px;
  display: flex;
  align-items: center;
  min-height: 34px;
  padding: 0;
  background: none;
  margin-bottom: 1rem;

  .MuiAlert-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    box-sizing: border-box;
    width: 40px;
    min-width: 40px;
    padding: 0;
    margin: 0;
    opacity: 1;
  }

  .MuiAlert-message {
    padding: 8px;
  }

  &.MuiAlert-outlined {
    border: 2px solid
      ${({ severity, theme }) => theme.palette[severity || 'info']?.main};
    border-radius: 4px;
    color: ${({ theme }) => theme.colorText};

    .MuiAlert-icon {
      background: ${({ severity, theme }) => theme.palette[severity || 'info']?.main};
      color: ${({ theme }) => theme.colorBackground} !important;
      // workaround for small gap between main border and icon
      margin: -1px;
    }
  }

  .MuiAlert-action {
    padding: 0;
    margin: 0 2px 0 auto;
    color: ${({ theme }) => theme.colorTextSecondary};
  }
`;

/**
 * An alert displays a short, important message in a way that attracts the user's attention without interrupting the user's task.
 */
const Alert = (props) => {
  const {
    className,
    children,
    bordered,
    icon,
    showDismiss,
    onDismiss,
    ...otherProps
  } = props;

  const [isOpen, setIsOpen] = useState(children != null);

  const onClose = useCallback(
    (event) => {
      setIsOpen(false);
      if (onDismiss) {
        onDismiss(event);
      }
    },
    [onDismiss],
  );

  if (icon != null) {
    otherProps.icon = icon;
  }

  if (bordered) {
    otherProps.variant = 'outlined';
  }

  return isOpen ? (
    <Container
      {...otherProps}
      className={className}
      onClose={showDismiss ? onClose : null}
    >
      {children}
    </Container>
  ) : null;
};

Alert.displayName = 'Alert';

Alert.propTypes = {
  /**
   * Override or extend the styles applied to the component.
   */
  className: PropTypes.string,
  /**
   * Alert content.
   */
  children: PropTypes.children.isRequired,
  /**
   * Alert severity.
   */
  severity: PropTypes.oneOf(['info', 'success', 'warning', 'error']),
  /**
   * If true, alert will displayed with border.
   */
  bordered: PropTypes.bool,
  /**
   * Overlay default type of icon.
   */
  icon: PropTypes.element,
  /**
   * If true, alert will displayed dismiss button.
   */
  showDismiss: PropTypes.bool,
  /**
   * A callback fired when dismiss button is clicked.
   */
  onDismiss: PropTypes.func,
};

Alert.defaultProps = {
  className: null,
  severity: 'info',
  bordered: true,
  icon: null,
  showDismiss: false,
  onDismiss: null,
};

export default Alert;
