import PropTypes from '+prop-types';
import { useCallback } from 'react';

import { Col, Row } from '+components/Layout';
import { Provider as MenuProvider } from '+components/Menu';

import SourceField from './SourceField';

const Editor = (props) => {
  const { onChange, transforms } = props;
  const handleSourceFieldUpdate = useCallback(
    (index, data) => {
      const copy = [...transforms];
      copy[index] = data;
      onChange(copy);
    },
    [transforms, onChange],
  );

  const handleSourceFieldRemove = useCallback(
    (index) => {
      const copy = [...transforms];
      copy.splice(index, 1);
      onChange(copy);
    },
    [transforms, onChange],
  );

  return (
    <MenuProvider>
      <Col>
        <Row>
          {transforms.map((transform, index) => (
            <SourceField
              key={transform.id}
              data={transform}
              index={index}
              onChange={handleSourceFieldUpdate}
              removeField={handleSourceFieldRemove}
            />
          ))}
        </Row>
      </Col>
    </MenuProvider>
  );
};

Editor.propTypes = {
  onChange: PropTypes.func.isRequired,
  transforms: PropTypes.array.isRequired,
};

export default Editor;
