import PropTypes from '+prop-types';
import { memo, useEffect, useMemo, useRef, useState } from 'react';

import styled from 'styled-components';

import RealtimeManager from '+components/RealtimeManager';
import useGlobalFilters from '+hooks/useGlobalFilters';

import ExpandedPanel from './ExpandedPanel';
import FlowTable from './FlowTable';
import TopTable from './TopTable';

const FlowTableRealtime = RealtimeManager(FlowTable, {
  throttle: {
    wait: 0,
    props: ['tableData'],
  },
  pauseOnSelectiveProps: true,
});

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  pointer-events: ${({ $drag }) => !$drag && 'none'};
  cursor: ${({ $drag }) => $drag && 'n-resize'};
`;

const Legend = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
`;

const LegendPanel = styled(ExpandedPanel)`
  flex-basis: auto;
  min-width: 84px;
  min-height: 0;
  transition: all 0.5s;
  &.closed {
    min-width: 0;
    min-height: 84px;
  }
`;

const Layout = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  overflow: hidden;
  align-items: flex-end;
`;

const Table = styled(ExpandedPanel)`
  flex-grow: 1;
`;

const sortByValue = ({ value: a }, { value: b }) => b - a;
const byTimestamp = ({ timestamp: a }, { timestamp: b }) => b - a;

const calcTop = function (store, key, ts, labels, customer) {
  let item = store.current[key];

  if (!item) {
    item = {
      key,
      value: 0,
      values: {},
      customer: [],
      ...labels,
    };
    store.current[key] = item;
  }

  item.value += 1;
  item.values[ts] = item.values[ts] || 0;
  item.values[ts] += 1;

  if (customer && !item.customer.includes(customer)) {
    item.customer.push(customer);
  }
};

const Overlay = (props) => {
  const { data, categories, refresh } = props;

  const [filters] = useGlobalFilters();

  const [isDragged, setIsDragged] = useState(false);

  const [srcGeoTop, setSrcGeoTop] = useState([]);
  const srcGeoTopStore = useRef({});

  const [dstGeoTop, setDstGeoTop] = useState([]);
  const dstGeoTopStore = useRef({});

  const [srcIpTop, setSrcIpTop] = useState([]);
  const srcIpTopStore = useRef({});

  const [dstIpTop, setDstIpTop] = useState([]);
  const dstIpTopStore = useRef({});

  const [tableData, setTableData] = useState([]);
  const tableDataStore = useRef([]);

  const catsTop = useMemo(
    () => [...categories].map((item) => ({ ...item, key: item.flowsrcname })),
    [categories.map(({ value }) => value)],
  );

  useEffect(
    () => {
      srcGeoTopStore.current = {};
      dstGeoTopStore.current = {};
      srcIpTopStore.current = {};
      dstIpTopStore.current = {};
      tableDataStore.current = [];
    },
    [refresh],
  );

  useEffect(
    () => {
      tableDataStore.current.unshift(...data.map(({ data: inData }) => inData));

      if (tableDataStore.current.length > 30) {
        tableDataStore.current = tableDataStore.current.splice(0, 30);
      }

      const ts = performance.now();

      data.forEach((item) => {
        const {
          source: { country: srcGeoKey } = {},
          target: { country: dstGeoKey } = {},
          data: row,
        } = item;

        const srcIpKey = row.srcip;
        const srcIpLabels = row.label?.ip?.[filters.labelContext.ip]?.src;
        const dstIpKey = row.dstip;
        const dstIpLabels = row.label?.ip?.[filters.labelContext.ip]?.dst;

        calcTop(srcGeoTopStore, srcGeoKey, ts, null, row.customer);
        calcTop(dstGeoTopStore, dstGeoKey, ts, null, row.customer);
        calcTop(
          srcIpTopStore,
          srcIpKey,
          ts,
          !filters.labelContext.show
            ? {}
            : {
              labelsContext: filters.labelContext.ip,
              labels: srcIpLabels,
            },
          row.customer,
        );
        calcTop(
          dstIpTopStore,
          dstIpKey,
          ts,
          !filters.labelContext.show
            ? {}
            : {
              labelsContext: filters.labelContext.ip,
              labels: dstIpLabels,
            },
          row.customer,
        );
      });

      setSrcGeoTop(Object.values(srcGeoTopStore.current).sort(sortByValue));
      setDstGeoTop(Object.values(dstGeoTopStore.current).sort(sortByValue));
      setSrcIpTop(Object.values(srcIpTopStore.current).sort(sortByValue));
      setDstIpTop(Object.values(dstIpTopStore.current).sort(sortByValue));
      setTableData([...tableDataStore.current].sort(byTimestamp));
    },
    [data, filters.labelContext],
  );

  return (
    <Container $drag={isDragged}>
      <Legend>
        <LegendPanel title="Legend" open>
          <TopTable data={catsTop} field="flowsrcname" />
        </LegendPanel>
      </Legend>
      <Layout>
        <ExpandedPanel title="Top SRC Geo" onDrag={setIsDragged} open>
          <TopTable data={srcGeoTop} field="srcgeo" />
        </ExpandedPanel>
        <ExpandedPanel title="Top Sources" onDrag={setIsDragged}>
          <TopTable data={srcIpTop} field="srcip" />
        </ExpandedPanel>
        <ExpandedPanel title="Top DST Geo" onDrag={setIsDragged}>
          <TopTable data={dstGeoTop} field="dstgeo" />
        </ExpandedPanel>
        <ExpandedPanel title="Top Destinations" onDrag={setIsDragged}>
          <TopTable data={dstIpTop} field="dstip" />
        </ExpandedPanel>
        <Table title="Events" open onDrag={setIsDragged}>
          <FlowTableRealtime data={tableData} />
        </Table>
      </Layout>
    </Container>
  );
};

Overlay.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  categories: PropTypes.arrayOf(PropTypes.shape()),
  refresh: PropTypes.number,
};

Overlay.defaultProps = {
  data: [],
  categories: [],
  refresh: 0,
};

export default memo(Overlay);
