import RoutePaths from '@/models/RoutePaths';

const dash = '—';

export const padding = '14px';

export const getNqlForLocation = (props) => {
  const { flowNql, eventNql, blockNql, dnsNql, pathname } = props;

  switch (true) {
    case pathname.startsWith(`${RoutePaths.realTimeTraffic}`):
    case pathname.startsWith(`${RoutePaths.trafficTop}`):
    case pathname.startsWith(`${RoutePaths.searchFlow}`):
    case pathname.startsWith(`${RoutePaths.trafficMiner}`):
      return flowNql;
    case pathname.startsWith(`${RoutePaths.events}`):
    case pathname.startsWith(`${RoutePaths.searchEvents}`):
      return eventNql;
    case pathname.startsWith(`${RoutePaths.searchBlocks}`):
      return blockNql;
    case pathname.startsWith(`${RoutePaths.searchDns}`):
      return dnsNql;
    default:
      return null;
  }
};

export const getMainTitle = (item, options) => {
  if (!item) {
    return '';
  }

  let { title } = item;

  if (!title) {
    let { field } = item;
    if (field === 'algorithm') {
      field = 'detection model';
    }
    const value = Array.isArray(item.value)
      ? item.value.join(', ')
      : item.value;
    title = [field, value]
      .filter((el) => el != null && el !== '')
      .join(` ${dash} `);
  }

  if (options?.showCustomer && item.customer) {
    return `${title} (${item.customer})`;
  }

  return title;
};
