import PropTypes from '+prop-types';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectors as customerSelectors } from '@/redux/api/customer';
import { actions } from '@/redux/api/labels/ports';

import makeArr from '+utils/makeArr';

import CellWrapper from './CellWrapper';

// Generic Context Menu for Ports
const CellPort = (props) => {
  const {
    field,
    value,
    original = {},
    disabled,
    hideUnderline,
    fetchLabels,
  } = props;

  const dispatch = useDispatch();

  const customer = useSelector(customerSelectors.getCurrentCustomer);

  const stream = useMemo(
    () => ({
      srcip: original?.srcip,
      srcport: original?.srcport,
      dstip: original?.dstip,
      dstport: original?.dstport,
      protocol: original?.protocol,
    }),
    [original],
  );

  useEffect(
    () => {
      if (!fetchLabels || !value) {
        return;
      }
      const arr = makeArr(original?.customer).filter(Boolean);
      if (!arr.length) {
        arr.push(''); // we need to fetch labels for the current customer
      }
      arr.forEach((originalCustomer) => {
        const isSubAccountRequest = originalCustomer && originalCustomer !== customer?.shortname;
        if (isSubAccountRequest) {
          const namespace = `CellPort - ${value} - ${originalCustomer}`;
          dispatch(
            actions.fetchPortLabelsByPort(
              {
                ip: value,
                customer: originalCustomer,
              },
              namespace,
            ),
          );
        } else {
          const namespace = `CellPort - ${value}`;
          dispatch(actions.fetchPortLabelsByPort({ ip: value }, namespace));
        }
      });
    },
    [fetchLabels, value, original?.customer, customer?.shortname],
  );

  return (
    <CellWrapper
      customer={original?.customer}
      field={field}
      value={value}
      stream={stream}
      disabled={disabled}
      hideUnderline={hideUnderline}
    >
      <span>{value}</span>
    </CellWrapper>
  );
};

CellPort.propTypes = {
  field: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  original: PropTypes.shape(),
  disabled: PropTypes.bool,
  hideUnderline: PropTypes.bool,
  fetchLabels: PropTypes.bool,
};

CellPort.defaultProps = {
  original: undefined,
  disabled: false,
  hideUnderline: false,
  fetchLabels: false,
};

export default CellPort;
