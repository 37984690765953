import { useEffect } from 'react';

/**
 * @param {Object} chart
 * @param {string} seriesType
 * @returns {null}
 */
const useShowDataLabelsOnPrint = (chart, seriesType) => {
  useEffect(
    () => {
      if (!chart || !seriesType) {
        return undefined;
      }

      const showDataLabels = () => {
        chart.update({
          plotOptions: {
            [seriesType]: { dataLabels: { enabled: true } },
          },
        });
      };

      const hideDataLabels = () => {
        chart.update({
          plotOptions: {
            [seriesType]: { dataLabels: { enabled: false } },
          },
        });
      };

      window.addEventListener('beforeChartPrint', showDataLabels);
      window.addEventListener('afterChartPrint', hideDataLabels);

      return () => {
        window.removeEventListener('beforeChartPrint', showDataLabels);
        window.removeEventListener('afterChartPrint', hideDataLabels);
      };
    },
    [chart, seriesType],
  );

  return null;
};

export default useShowDataLabelsOnPrint;
