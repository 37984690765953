import PropTypes from '+prop-types';
import { Fragment, useRef, useMemo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import styled from 'styled-components';

import {
  actions as guestActions,
  selectors as guestSelectors,
} from '@/redux/api/guest';
import { selectors as globalFiltersSelectors } from '@/redux/globalFilters';

import { homeUrl } from '@/shared/utils';

import CopyTextOrigin from '+components/CopyText';
import { Field } from '+components/form/FinalForm';
import {
  Description,
  Group,
  Label as FieldLabel,
} from '+components/form/FormField';
import Plaintext from '+components/form/Plaintext';
import TextField from '+components/form/TextField';
import { validateEmails, validateRequired } from '+components/form/Validators';
import FormModal from '+components/FormModal';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import dayjs, { DateFormat } from '+utils/dayjs';

const exp = 600000;

const CopyText = styled(CopyTextOrigin)`
  padding: 8px 10px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colorFieldBackground};
`;

const FormBody = ({ guestLink, codeExpiresAt }) => {
  const recipientsRef = useRef();

  const onCopy = useCallback(
    () => {
      recipientsRef.current.focus();
    },
    [],
  );

  return (
    <Fragment>
      <Group>
        <FieldLabel>Sharable Link</FieldLabel>
        <Plaintext>
          <CopyText text={guestLink} onCopy={onCopy}>
            {guestLink}
          </CopyText>
        </Plaintext>
        {codeExpiresAt && (
          <Description>
            Expires at: {dayjs.unix(codeExpiresAt).format(DateFormat.second)} (
            {dayjs.unix(codeExpiresAt).fromNow()}).
          </Description>
        )}
      </Group>

      <Field
        ref={recipientsRef}
        name="recipients"
        component={TextField}
        autoComplete="new-password"
        validate={[validateRequired, validateEmails]}
        label="Recipients"
        helperText="Comma separated list of email addresses."
        required
      />
    </Fragment>
  );
};

FormBody.propTypes = {
  guestLink: PropTypes.string,
  codeExpiresAt: PropTypes.number,
};

FormBody.defaultProps = {
  guestLink: '',
  codeExpiresAt: 0,
};

const ShareForm = (props) => {
  const { dashboard, isOpen, onToggle } = props;

  const dispatch = useDispatch();
  const location = useLocation();

  const isFetching = useSelector(guestSelectors.isFetching);
  const guestCode = useSelector(guestSelectors.getGuestCode);
  const codeExpiresAt = useSelector(guestSelectors.codeExpiresAt);
  const globalFilters = useSelector(globalFiltersSelectors.getFilters);

  useLoadingIndicator(isFetching);

  const guestLink = useMemo(
    () => `${homeUrl}/g/${guestCode}`,
    [guestCode],
  );

  const onGenerateLink = useCallback(
    () => {
      const params = {
        url: `${location.pathname}${location.search || ''}`,
        exp,
        globalFilters,
      };
      dispatch(guestActions.generateGuestCode(params));
    },
    [location.pathname, location.search, globalFilters],
  );

  const onSendLinkByEmail = useCallback(
    ({ recipients }) => {
      const params = {
        pageTitle: `${dashboard?.title} Dashboard`,
        url: guestLink,
        exp: dayjs(codeExpiresAt * 1000).format(DateFormat.minute),
        recipients: recipients.split(',').map((item) => item.trim()),
      };
      dispatch(guestActions.sendGuestCodeByEmail(params));
      onToggle();
    },
    [dashboard?.title, guestLink, codeExpiresAt, onToggle],
  );

  useEffect(
    () => () => {
      dispatch(guestActions.clearGuestCode());
    },
    [],
  );

  return (
    <FormModal
      titleTemplate="Generate Sharable Link"
      confirmButtonText={guestCode ? 'Send Link via Email' : 'Generate Link'}
      cancelButtonText={guestCode ? 'Done' : 'Cancel'}
      initialValues={{}}
      isOpen={isOpen}
      onToggle={onToggle}
      onSubmit={guestCode ? onSendLinkByEmail : onGenerateLink}
      disabled={isFetching}
      labelOnTop
    >
      {!guestCode && (
        <div>
          Generate a sharable link that can be used by anyone to view this
          dashboard.
          <br />
          User accounts and login are not required to view this link.
          <br />
          People will not be able to navigate to other portions of the portal.
        </div>
      )}
      {guestCode && (
        <FormBody guestLink={guestLink} codeExpiresAt={codeExpiresAt} />
      )}
    </FormModal>
  );
};

ShareForm.propTypes = {
  dashboard: PropTypes.shape({
    title: PropTypes.string,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default ShareForm;
