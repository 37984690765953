const loader = document.querySelector('#loader');
const loaderMessage = document.querySelector('#loaderMessage');
/* loader.addEventListener('transitionend', () => {
  loader.remove();
}); */

const toggle = (on) => {
  const method = on ? 'remove' : 'add';
  loader?.classList?.[method]?.('loader--hidden');
};

export default {
  hide() {
    toggle(false);
    return this;
  },
  show() {
    toggle(true);
    return this;
  },
  setMessage(message) {
    if (loaderMessage) {
      const span = document.createElement('span');
      span.innerHTML = message;
      span.classList.add('loader__message__item--fade-in');

      const children = Array.from(
        loaderMessage.querySelectorAll(
          'span:not(.loader__message__item--fade-out)',
        ),
      );

      children.forEach((child) => {
        child.classList.add('loader__message__item--fade-out');
        child.addEventListener('transitionend', () => {
          child.remove();
        });
      });

      loaderMessage.appendChild(span);
      setTimeout(() => {
        span.classList.remove('loader__message__item--fade-in');
      }, 100);
    }
    return this;
  },
};
