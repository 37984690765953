import PropTypes from '+prop-types';
import ReactJson from 'react-json-view';

import copy from 'copy-to-clipboard';
import { withTheme } from 'styled-components';

const handleCopy = ({ src }) => copy(JSON.stringify(src, null, 2));

const JsonView = ({ theme, rowData, ...rest }) => (
  <ReactJson
    src={rowData}
    name={false}
    theme={theme.reactJsonTheme}
    style={{
      // fontSize: '0.9em',
      // fontFamily: "'Source Code Pro', monospace",
      fontSize: '12px',
      backgroundColor: 'transparent',
    }}
    displayDataTypes={false}
    enableClipboard={handleCopy}
    {...rest}
  />
);

JsonView.propTypes = {
  theme: PropTypes.shape().isRequired,
  rowData: PropTypes.shape().isRequired,
};

export default withTheme(JsonView);
