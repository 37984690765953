import PropTypes from '+prop-types';
import { useMemo } from 'react';

import { useFlag } from '@unleash/proxy-client-react';

import ChartBoxIcon from 'mdi-react/ChartBoxIcon';
import CogTransferIcon from 'mdi-react/CogTransferIcon';
import LeadPencilIcon from 'mdi-react/LeadPencilIcon';
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';

import { MenuColumnContextMenu } from '+components/Table/Columns';

export const TableContextMenu = (props) => {
  const { onDelete, onEdit, onDetails, onBGPConfig, item, permissions } = props;
  const isVpc = !!item.flowtype;
  const localPermissions = isVpc
    ? permissions.cloudPermissions
    : permissions.devicePermissions;
  const isDnsEnabled = useFlag('DNS');

  const items = useMemo(
    () => [
      {
        icon: <LeadPencilIcon />,
        text: 'Edit',
        disabled: !localPermissions?.update,
        onClick: () => {
          onEdit(item);
        },
      },
      !isVpc && {
        icon: <CogTransferIcon />,
        text: 'BGP Config',
        onClick: () => {
          onBGPConfig(item);
        },
      },
      isVpc && {
        icon: <ChartBoxIcon />,
        text: `${isDnsEnabled ? 'Traffic' : 'Flow'} Source Details`,
        onClick: () => {
          onDetails(item);
        },
      },
      {
        icon: <TrashCanOutlineIcon />,
        text: 'Delete',
        disabled: !localPermissions?.delete,
        onClick: onDelete(item),
      },
    ].filter(Boolean),
    [item, onDelete, onEdit, permissions],
  );

  return (
    <MenuColumnContextMenu
      title={item.name}
      items={items}
      dataTracking="flowsource"
    />
  );
};

TableContextMenu.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDetails: PropTypes.func.isRequired,
  onBGPConfig: PropTypes.func.isRequired,
  item: PropTypes.shape().isRequired,
  permissions: PropTypes.shape(),
};

TableContextMenu.defaultProps = {
  permissions: null,
};
