import PropTypes from '+prop-types';
import { Fragment, useState, useMemo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';

import PermissionModel from '@/models/Permission';

import { actions, selectors, TagsEntityTypes } from '@/redux/api/tag';

import EmphasizedCode from '+components/EmphasizedCode';
import { Field } from '+components/form/FinalForm';
import MultiSelectField from '+components/form/MultiSelectField';
import { normalizeMultiSelectValue } from '+components/form/Normalizers';
import TextField from '+components/form/TextField';
import {
  validateASNs,
  validateCidrs,
  validateNumber,
  validatePort,
  validateRequired,
  validateTcpFlags,
} from '+components/form/Validators';
import FormWizard, { Step } from '+components/FormWizard';
import usePermissions from '+hooks/usePermissions';

import CONDITIONS from './conditions';
import ModalRegex from './modals/Regex';
import RegexField from './RegexField';

const tagListSelector = selectors.getTagsList(TagsEntityTypes.flow);

const HelperTextContainer = styled.div`
  margin-top: 3px;
`;

const tagsHelperText = 'One or many tags to assign to every flow from this device';
const protocolHelperText = (
  <Fragment>
    Match against protocol of a given flow record.
    <br />
    Either the <EmphasizedCode>official_protocol_name</EmphasizedCode> or{' '}
    <EmphasizedCode>protocol_number</EmphasizedCode>. See{' '}
    <EmphasizedCode>/etc/protocol</EmphasizedCode> for examples.
  </Fragment>
);
// eslint-disable-next-line max-len
const tcpFlagsIntHelperText = 'Integer representing which tcpflags were set in the flow record. Values between 0-255 are valid. 0 will explicitly match flows where no flags are set. Leave empty for all flows';
const inputAliasRegexHelperText = (
  <HelperTextContainer>
    A regular expression that matches against{' '}
    <EmphasizedCode>inputalias</EmphasizedCode> of a given flow record
  </HelperTextContainer>
);
const inputNameRegexHelperText = (
  <HelperTextContainer>
    A regular expression that matches against{' '}
    <EmphasizedCode>inputname</EmphasizedCode>
    of a given flow record
  </HelperTextContainer>
);
const outputAliasRegexHelperText = (
  <HelperTextContainer>
    A regular expression that matches against{' '}
    <EmphasizedCode>outputalias</EmphasizedCode> of a given flow record
  </HelperTextContainer>
);
const outputNameRegexHelperText = (
  <HelperTextContainer>
    A regular expression that matches against{' '}
    <EmphasizedCode>outputname</EmphasizedCode> of a given flow record
  </HelperTextContainer>
);
const srcasHelperText = (
  <HelperTextContainer>
    List of Autonomous System Numbers (ASN) to match against{' '}
    <EmphasizedCode>srcas.number</EmphasizedCode> for a given flow record
  </HelperTextContainer>
);
const srcnetsHelperText = (
  <HelperTextContainer>
    List of CIDR blocks to match against <EmphasizedCode>srcip</EmphasizedCode>{' '}
    for a given flow record
  </HelperTextContainer>
);
const srcportHelperText = (
  <HelperTextContainer>
    Source port to match against <EmphasizedCode>srcport</EmphasizedCode> for a
    given flow
  </HelperTextContainer>
);
const srcownerasHelperText = (
  <HelperTextContainer>
    List of Autonomous System Numbers (ASN) to match against{' '}
    <EmphasizedCode>srcowneras.number</EmphasizedCode> for a given flow record
  </HelperTextContainer>
);
const dstasHelperText = (
  <HelperTextContainer>
    List of Autonomous System Numbers (ASN) to match against{' '}
    <EmphasizedCode>dstas.number</EmphasizedCode> for a given flow record
  </HelperTextContainer>
);
const dstnetsHelperText = (
  <HelperTextContainer>
    List of CIDR blocks to match against <EmphasizedCode>dstip</EmphasizedCode>{' '}
    for a given flow record
  </HelperTextContainer>
);
const dstownerasHelperText = (
  <HelperTextContainer>
    List of Autonomous System Numbers (ASN) to match against{' '}
    <EmphasizedCode>dstowneras.number</EmphasizedCode> for a given flow record
  </HelperTextContainer>
);
const dstportHelperText = (
  <HelperTextContainer>
    Destination port to match against <EmphasizedCode>dstport</EmphasizedCode>{' '}
    for a given flow
  </HelperTextContainer>
);

const FlowTagForm = (props) => {
  const { initialValues, onSubmit, onCancel, ...tail } = props;

  const dispatch = useDispatch();

  const { isFetching, testRegexResult } = useSelector(selectors.getState);
  const tagsList = useSelector(tagListSelector);
  const permissions = usePermissions(PermissionModel.Resources.flow_tag.value);

  const [srcasOptions] = useState(initialValues?.srcas ?? []);
  const [srcnetsOptions] = useState(initialValues?.srcnets ?? []);
  const [srcownerasOptions] = useState(initialValues?.srcowneras ?? []);
  const [dstasOptions] = useState(initialValues?.dstas ?? []);
  const [dstnetsOptions] = useState(initialValues?.dstnets ?? []);
  const [dstownerasOptions] = useState(initialValues?.dstowneras ?? []);
  const [showRegexModal, setShowRegexModal] = useState(false);

  const canManage = initialValues.id
    ? permissions?.update
    : permissions?.create;
  const canRemove = initialValues.id && permissions?.delete;

  const tagsOptions = useMemo(
    () => tagsList.map((item) => ({
      value: item.tag,
      label: item.tag,
    })),
    [tagsList],
  );

  const handleFormSubmit = useCallback(
    (values) => {
      // prepare data
      const params = {
        ...values,
        dstport: values.dstport == null ? null : Number(values.dstport),
        srcport: values.srcport == null ? null : Number(values.srcport),
        tcpflagsint:
          values.tcpflagsint == null ? null : Number(values.tcpflagsint),
        srcas: (values.srcas || []).map((item) => Number(item)),
        srcowneras: (values.srcowneras || []).map((item) => Number(item)),
        dstas: (values.dstas || []).map((item) => Number(item)),
        dstowneras: (values.dstowneras || []).map((item) => Number(item)),
      };
      onSubmit(params);
    },
    [onSubmit],
  );

  const handleCancel = useCallback(
    () => {
      onCancel();
    },
    [onCancel],
  );

  const onToggleRegexModal = useCallback(
    () => setShowRegexModal((prev) => !prev),
    [],
  );

  useEffect(
    () => {
      dispatch(actions.fetchList(TagsEntityTypes.flow, 'flowTagForm'));

      return () => {
        dispatch(actions.cancel('flowTagForm'));
      };
    },
    [],
  );

  return (
    <Fragment>
      <FormWizard
        {...tail}
        initialValues={initialValues}
        title="Add Flow Tag"
        // eslint-disable-next-line max-len
        description="Flow Tags are labels that are applied to flow data, based on user-defined criteria and are activated as Netography ingests the data into the Platform."
        image="/assets/graphic-flow-tags.png"
        onSubmit={handleFormSubmit}
        onCancel={handleCancel}
        disabled={isFetching || !canManage}
        deleteButtonHidden={!initialValues.id}
        deleteButtonDisabled={!canRemove}
      >
        <Step title="Flow Conditions">
          <div className="card__title" style={{ marginLeft: '140px' }}>
            <h5 className="head">Required Fields</h5>
            <h5 className="subhead">
              Name of the Flow Tag Rule and the tags to record to the flow
              record, pending any of the conditions are met below.
            </h5>
          </div>

          <Field
            name="name"
            label="Rule Name"
            component={TextField}
            type="text"
            validate={validateRequired}
            maxLength={100}
            disabled={!canManage}
            required
          />

          <Field
            name="tags"
            label="Tags"
            component={MultiSelectField}
            options={tagsOptions}
            parse={normalizeMultiSelectValue}
            helperText={tagsHelperText}
            disabled={!canManage}
            validate={validateRequired}
            required
            allowCreate
          />

          <div className="card__title" style={{ marginLeft: '140px' }}>
            <h5 className="head">Transport</h5>
            <h5 className="subhead">Protocol related rule conditions</h5>
          </div>

          <Field
            name="protocol"
            label={CONDITIONS.protocol.name}
            component={TextField}
            type="text"
            style={{ width: '150px' }}
            helperText={protocolHelperText}
            disabled={!canManage}
          />

          <Field
            name="tcpflagsint"
            label={CONDITIONS.tcpflagsint.name}
            component={TextField}
            type="number"
            validate={validateTcpFlags}
            style={{ width: '150px' }}
            // eslint-disable-next-line max-len
            helperText={tcpFlagsIntHelperText}
            disabled={!canManage}
          />

          <div className="card__title" style={{ marginLeft: '140px' }}>
            <h5 className="head">Interface</h5>
            <h5 className="subhead">
              Conditions related to the traffic across an interface
            </h5>
          </div>

          <RegexField
            name="inputaliasregex"
            label={CONDITIONS.inputaliasregex.name}
            placeholder="^startswith"
            helperText={inputAliasRegexHelperText}
            toggleRegexModal={onToggleRegexModal}
            disabled={!canManage}
          />

          <RegexField
            name="inputnameregex"
            label={CONDITIONS.inputnameregex.name}
            placeholder="endswith$"
            helperText={inputNameRegexHelperText}
            toggleRegexModal={onToggleRegexModal}
            disabled={!canManage}
          />

          <RegexField
            name="outputaliasregex"
            label={CONDITIONS.outputaliasregex.name}
            placeholder="(?i)^c800\."
            helperText={outputAliasRegexHelperText}
            toggleRegexModal={onToggleRegexModal}
            disabled={!canManage}
          />

          <RegexField
            name="outputnameregex"
            placeholder="^F.*t1/.*administr"
            label={CONDITIONS.outputnameregex.name}
            helperText={outputNameRegexHelperText}
            toggleRegexModal={onToggleRegexModal}
            disabled={!canManage}
          />

          <div className="card__title" style={{ marginLeft: '140px' }}>
            <h5 className="head">Source</h5>
            <h5 className="subhead">
              Conditions related to the source traffic
            </h5>
          </div>

          <Field
            name="srcas"
            label={CONDITIONS.srcas.name}
            component={MultiSelectField}
            options={srcasOptions}
            validate={validateASNs}
            parse={normalizeMultiSelectValue}
            helperText={srcasHelperText}
            disabled={!canManage}
            allowCreate
          />

          <Field
            name="srcnets"
            label={CONDITIONS.srcnets.name}
            component={MultiSelectField}
            options={srcnetsOptions}
            validate={validateCidrs}
            parse={normalizeMultiSelectValue}
            helperText={srcnetsHelperText}
            disabled={!canManage}
            allowCreate
          />

          <Field
            name="srcowneras"
            label={CONDITIONS.srcowneras.name}
            component={MultiSelectField}
            options={srcownerasOptions}
            validate={validateASNs}
            parse={normalizeMultiSelectValue}
            helperText={srcownerasHelperText}
            disabled={!canManage}
            allowCreate
          />

          <Field
            name="srcport"
            label={CONDITIONS.srcport.name}
            component={TextField}
            type="number"
            style={{ width: '150px' }}
            helperText={srcportHelperText}
            validate={[validateNumber, validatePort]}
            disabled={!canManage}
          />

          <div className="card__title" style={{ marginLeft: '140px' }}>
            <h5 className="head">Destination</h5>
            <h5 className="subhead">
              Conditions related to the destination traffic
            </h5>
          </div>

          <Field
            name="dstas"
            label={CONDITIONS.dstas.name}
            component={MultiSelectField}
            options={dstasOptions}
            validate={validateASNs}
            parse={normalizeMultiSelectValue}
            helperText={dstasHelperText}
            disabled={!canManage}
            allowCreate
          />

          <Field
            name="dstnets"
            label={CONDITIONS.dstnets.name}
            component={MultiSelectField}
            options={dstnetsOptions}
            parse={normalizeMultiSelectValue}
            validate={validateCidrs}
            helperText={dstnetsHelperText}
            disabled={!canManage}
            allowCreate
          />

          <Field
            name="dstowneras"
            label={CONDITIONS.dstowneras.name}
            component={MultiSelectField}
            options={dstownerasOptions}
            parse={normalizeMultiSelectValue}
            validate={validateASNs}
            helperText={dstownerasHelperText}
            disabled={!canManage}
            allowCreate
          />

          <Field
            name="dstport"
            label={CONDITIONS.dstport.name}
            component={TextField}
            type="number"
            style={{ width: '150px' }}
            helperText={dstportHelperText}
            validate={[validateNumber, validatePort]}
            disabled={!canManage}
          />
        </Step>
      </FormWizard>

      {showRegexModal && (
        <ModalRegex
          isOpen={showRegexModal}
          toggleModal={onToggleRegexModal}
          json={testRegexResult || {}}
        />
      )}
    </Fragment>
  );
};

FlowTagForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  initialValues: PropTypes.shape(),
};

FlowTagForm.defaultProps = {
  initialValues: null,
};

export default FlowTagForm;
