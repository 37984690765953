import { emptyGroupKey } from '+components/Table/ReactTable/utils';

const getIsEmptyGroup = (row) => {
  if (!row?.isGrouped || row?.groupByVal === undefined) {
    return false;
  }
  return Array.isArray(row.groupByVal)
    ? !row.groupByVal.length
    : row.groupByVal === emptyGroupKey;
};

const useInstance = (instance) => {
  const {
    groupedRows,
    state: { groupBy },
  } = instance;

  if (groupBy?.length > 1) {
    return;
  }

  Object.assign(instance, {
    groupedRows: groupedRows.map((row) => {
      if (getIsEmptyGroup(row)) {
        row.original = {
          ...row.original,
          expanded: true,
        };
      }
      return row;
    }),
  });
};

export const useGroupByExpandEmptyGroups = (hooks) => {
  hooks.useInstance.push(useInstance);
};

useGroupByExpandEmptyGroups.pluginName = 'useGroupByExpandEmptyGroups';
