import { useCallback } from 'react';
import { ensurePluginOrder } from 'react-table';

const pluginName = 'useFiltersColumnMethodsExtender';

const useInstance = (instance) => {
  const { flatHeaders, setAllFilters, plugins } = instance;

  ensurePluginOrder(plugins, ['useFilters'], pluginName);

  const resetAllFilters = useCallback(
    () => {
      setAllFilters([]);
    },
    [setAllFilters],
  );

  flatHeaders.forEach((column) => {
    column.resetAllFilters = resetAllFilters;
  });
};

export const useFiltersColumnMethodsExtender = (hooks) => {
  hooks.useInstance.push(useInstance);
};

useFiltersColumnMethodsExtender.pluginName = pluginName;
