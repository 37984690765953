/* eslint-disable react/forbid-prop-types */
import PropTypes from '+prop-types';

import AlphabeticalIcon from 'mdi-react/AlphabeticalIcon';
import AmpersandIcon from 'mdi-react/AmpersandIcon';
import CircleOffOutlineIcon from 'mdi-react/CircleOffOutlineIcon';
import ClockOutlineIcon from 'mdi-react/ClockOutlineIcon';
import CodeBracesIcon from 'mdi-react/CodeBracesIcon';
import CodeBracketsIcon from 'mdi-react/CodeBracketsIcon';
import LaptopIcon from 'mdi-react/LaptopIcon';
import NumericIcon from 'mdi-react/NumericIcon';

import { UnixTimestampFields } from '@/models/UnixTimestampFields';

const ipFields = ['dstip', 'flowsrcip', 'nexthop', 'srcip'];

const Icon = ({ name, value }) => {
  const [lastKeyInPath] = name.split('.').slice(-1);

  if (UnixTimestampFields.includes(lastKeyInPath)) {
    return <ClockOutlineIcon size={14} />;
  }

  if (ipFields.includes(lastKeyInPath)) {
    return <LaptopIcon size={14} />;
  }

  if (['dstiprep.categories', 'srciprep.categories', 'tags'].includes(name)) {
    return <CodeBracketsIcon size={14} />;
  }

  if (lastKeyInPath.includes('number')) {
    return <NumericIcon size={14} />;
  }

  if (value == null) {
    return <CircleOffOutlineIcon size={14} />;
  }

  if (Array.isArray(value)) {
    return <CodeBracketsIcon size={14} />;
  }

  switch (typeof value) {
    case 'string':
      return <AlphabeticalIcon size={16} />;
    case 'number':
      return <NumericIcon size={14} />;
    case 'boolean':
      return <AmpersandIcon size={14} />;
    case 'object':
      return <CodeBracesIcon size={14} />;
    default:
      return null;
  }
};

Icon.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
};

Icon.defaultProps = {
  name: '',
  value: null,
};

export default Icon;
