import PropTypes from '+prop-types';
import { Fragment } from 'react';

import Field from '+components/form/FinalForm/Field';
import { normalizeSelectValue } from '+components/form/Normalizers';
import RoleAuthFieldsSection from '+components/form/RoleAuthFieldSection';
import SelectField from '+components/form/SelectField';
import TextField from '+components/form/TextField';
import { validateRequired } from '+components/form/Validators';
import useProviderRegions from '+hooks/useProviderRegions';

const AzureNsgFields = ({ disabled }) => {
  const regions = useProviderRegions('azure');
  return (
    <Fragment>
      <Field
        name="region"
        label="Region"
        component={SelectField}
        validate={validateRequired}
        options={regions}
        parse={normalizeSelectValue}
        helperText="Location of the source"
        disabled={disabled}
        required
      />

      <Field
        name="containername"
        label="Container Name"
        component={TextField}
        type="text"
        maxLength={255}
        autoComplete="new-password"
        validate={validateRequired}
        disabled={disabled}
        helperText="Storage Account's Container Name"
        required
      />

      <Field
        name="subscriptionid"
        label="Subscription ID"
        component={TextField}
        type="text"
        maxLength={255}
        autoComplete="new-password"
        validate={validateRequired}
        disabled={disabled}
        helperText="Network Security Group's subscription ID"
        required
      />

      <Field
        name="resourcegroup"
        label="Resource Group"
        component={TextField}
        type="text"
        maxLength={255}
        autoComplete="new-password"
        validate={validateRequired}
        disabled={disabled}
        helperText="Network Security Group's Resource Group"
        required
      />

      <Field
        name="networksecuritygroup"
        label="Security Group"
        component={TextField}
        type="text"
        maxLength={255}
        autoComplete="new-password"
        validate={validateRequired}
        disabled={disabled}
        helperText="Network Security Group's Name"
        required
      />
      <RoleAuthFieldsSection label="Authentication">
        <Field
          name="accountname"
          label="Account Name"
          component={TextField}
          type="text"
          validate={validateRequired}
          helperText="The Storage Account's Access Name to use for this stream"
          disabled={disabled}
          required
        />

        <Field
          name="accountkey"
          label="Account Key"
          component={TextField}
          type="password"
          autoComplete="new-password"
          validate={validateRequired}
          disabled={disabled}
          helperText="Storage Account's Access Key for authenticating to this stream"
          required
        />
      </RoleAuthFieldsSection>
    </Fragment>
  );
};

AzureNsgFields.propTypes = {
  disabled: PropTypes.bool,
};

AzureNsgFields.defaultProps = {
  disabled: false,
};

export default AzureNsgFields;
