import PropTypes from '+prop-types';
import { useMemo } from 'react';

import styled, { useTheme } from 'styled-components';

import AccountMultipleIcon from 'mdi-react/AccountMultipleIcon';
import FilterIcon from 'mdi-react/FilterIcon';
import InformationIcon from 'mdi-react/InformationIcon';

import { TimePeriods } from '@/models/TimePeriods';

import { DateTimeLevel } from '@/pages/Dashboards/shared/requests';

import dayjs, { DateFormat } from '+utils/dayjs';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5em;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Text = styled.div``;

const Description = styled.div``;

const Title = (props) => {
  const {
    title,
    description,
    dateTimeLevel,
    dateTimeParams,
    nql,
    subAccounts,
    metrics,
    includeTitleClassname,
    alertMessage,
  } = props;

  const theme = useTheme();

  const timeRange = useMemo(
    () => {
      let startTime;
      let endTime;

      const { period: timePeriod, start, end } = dateTimeParams || {};

      const needPeriod = TimePeriods[timePeriod];

      endTime = needPeriod ? Date.now() : end;
      startTime = needPeriod ? endTime + start * 1000 : start;

      startTime = dayjs(startTime).format(DateFormat.minute);
      endTime = dayjs(endTime).format(DateFormat.minute);

      return { start: startTime, end: endTime };
    },
    [dateTimeParams],
  );

  const filtersTitle = useMemo(
    () => {
      const filters = [];

      if (nql?.length) {
        filters.push(`Custom NQL: ${nql.join(', ')}`);
      }

      if (dateTimeLevel === DateTimeLevel.widget) {
        filters.push(`Custom Date & Time: ${timeRange.start} ⟶ ${timeRange.end}`);
      }

      if (metrics?.length) {
        filters.push(`Custom Metrics: ${metrics.join(', ')}`);
      }

      return filters.join('\n');
    },
    [dateTimeLevel, timeRange, nql, metrics],
  );

  return (
    <Container
      className={includeTitleClassname ? 'widget__table-title' : undefined}
    >
      <TitleContainer>
        {alertMessage?.length > 0 && (
          <IconContainer title={alertMessage}>
            <InformationIcon size={18} color={theme?.primary} />
          </IconContainer>
        )}
        {!!subAccounts?.length && (
          <IconContainer title={`Custom Account: ${subAccounts.join(', ')}`}>
            <AccountMultipleIcon
              className="highcharts-sub-account-icon"
              size={15}
            />
          </IconContainer>
        )}
        {!!filtersTitle && (
          <IconContainer title={filtersTitle}>
            <FilterIcon size={15} />
          </IconContainer>
        )}
        <Text>{title}</Text>
      </TitleContainer>
      {!!description && (
        <Description className="highcharts-description">
          {description}
        </Description>
      )}
    </Container>
  );
};

Title.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  dateTimeLevel: PropTypes.oneOf(Object.values(DateTimeLevel)),
  dateTimeParams: PropTypes.shape({}),
  nql: PropTypes.arrayOf(PropTypes.string),
  subAccounts: PropTypes.arrayOf(PropTypes.string),
  metrics: PropTypes.arrayOf(PropTypes.string),
  includeTitleClassname: PropTypes.bool,
  alertMessage: PropTypes.string,
};

Title.defaultProps = {
  title: '',
  description: '',
  dateTimeLevel: DateTimeLevel.global,
  dateTimeParams: undefined,
  nql: [],
  subAccounts: undefined,
  metrics: undefined,
  includeTitleClassname: false,
  alertMessage: '',
};

export default Title;
