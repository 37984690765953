import styled from 'styled-components';

export default styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 21px;
  margin-right: 4px;
  text-align: center;
  line-height: 1em;
`;
