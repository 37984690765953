import { put } from 'redux-saga/effects';

import { createSlice, createSelector, tryCancelSaga } from '@/redux/util';

export const FetchStates = {
  error: 'error',
  init: 'init',
  fetching: 'fetching',
  empty: 'empty',
  success: 'success',
};

const setStatus = (statusKey, status) => (state) => {
  state[statusKey] = status;
  if (status !== FetchStates.error) {
    state.error = null;
  }
  return state;
};

const initialState = {
  status: FetchStates.init,
  error: '',
  data: {},
};

const getUrl = (path) => `/docs/${path}`;

const slice = createSlice({
  name: 'docs',
  initialState,

  reducers: {
    fetchDetectionModelsDocs: setStatus('status', FetchStates.fetching),
    fetchDetectionModelsDocsSuccess(
      state,
      { payload: { data: { data } = {} } = {} },
    ) {
      if (!data?.length) {
        setStatus('status', FetchStates.empty)(state);
        return;
      }
      setStatus('status', FetchStates.success)(state);
      state.data['detection-models'] = {};
      data.forEach((doc) => {
        state.data['detection-models'][doc.name] = doc;
      });
    },
    error(state, { payload: { message, statusKey } }) {
      setStatus(statusKey, FetchStates.error)(state);
      state.error = message;
    },
  },

  sagas: (actions) => ({
    * [actions.fetchDetectionModelsDocs]() {
      yield tryCancelSaga(
        'get',
        {
          successAction: actions.fetchDetectionModelsDocsSuccess,
          errorAction: actions.fail,
          // toasts: {
          //   error: 'Error fetching detection models docs',
          // },
          * error(error) {
            yield put(
              actions.error({
                message: error.message,
                statusKey: 'status',
              }),
            );
          },
        },
        getUrl('detection-models'),
      );
    },
  }),

  selectors: (getState) => ({
    isFetching: createSelector(
      [getState],
      (state) => state.status === FetchStates.fetching,
    ),
    getStatus: createSelector(
      [getState],
      (state) => state.status,
    ),
    getDetectionModelsDocs: createSelector(
      [getState],
      (state) => state.data['detection-models'],
    ),
    getDetectionModelDoc: (name) => createSelector(
      [getState],
      (state) => state.data['detection-models']?.[name],
    ),
  }),
});

export const { actions, selectors } = slice;

export default slice;
