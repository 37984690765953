import PropTypes from '+prop-types';
import { createContext, forwardRef, useContext } from 'react';

import classNames from 'classnames';

export const OuterElementContext = createContext({});

const OuterElement = forwardRef((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  const className = classNames(
    props?.className || '',
    outerProps?.className || '',
    'virtualized',
  );

  return (
    <div
      ref={ref}
      {...props}
      {...outerProps}
      className={className}
    />
  );
});
OuterElement.displayName = 'OuterElement';
OuterElement.propTypes = {
  className: PropTypes.string,
};
OuterElement.defaultProps = {
  className: '',
};

export default OuterElement;
