import PropTypes from '+prop-types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useMeasure } from 'react-use';

import { renderToStaticMarkup } from 'react-dom/server';
import styled from 'styled-components';

import PollIcon from 'mdi-react/PollIcon';

import { ContextTypes } from '@/models/ContextTypes';
import { MetricSettings } from '@/models/MetricSettings';
import StatsRequest from '@/models/StatsRequest';

import { selectors as customerSelectors } from '@/redux/api/customer';

import SeriesTypes from '+components/charts/common/SeriesTypes';
import TimeseriesChart from '+components/charts/TimeseriesChart';
import { CardMixin, Col } from '+components/Layout';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import useStatsRequest from '+hooks/useStatsRequest';

const ChartContainer = styled(Col)`
  ${CardMixin};
  padding: 6px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 1.5;
  width: unset;
`;

export const ThresholdFuncs = {
  sum: 'sum',
  max: 'max',
  min: 'min',
  cnt: 'count',
  count: 'count',
  avg: 'average',
  average: 'average',
  div: 'divide',
  divide: 'divide',
  multiply: 'multiply',
  product: 'multiply',
};

const getTitle = (threshold) => {
  const fn = ThresholdFuncs[threshold.Func];
  switch (fn) {
    case ThresholdFuncs.divide:
      return `${fn} ${threshold.Metric[0]} by ${threshold.Metric[1]}`;
    case ThresholdFuncs.multiply:
      return `${fn} ${threshold.Metric[0]} and ${threshold.Metric[1]}`;
    default:
      return fn ? `${fn} by ${threshold.Metric[0]}` : threshold.Thresh;
  }
};

const getFlowMetric = (isRate) => (metric) => {
  let value = MetricSettings[ContextTypes.flow].card;
  switch (metric) {
    case MetricSettings[ContextTypes.flow].bits.key:
    case 'bitsxrate':
      value = isRate
        ? MetricSettings[ContextTypes.flow].bitrate
        : MetricSettings[ContextTypes.flow].bits;
      break;
    case MetricSettings[ContextTypes.flow].packets.key:
    case 'packetsxrate':
      value = isRate
        ? MetricSettings[ContextTypes.flow].packetrate
        : MetricSettings[ContextTypes.flow].packets;
      break;
    case 'flow':
    case 'record':
      value = MetricSettings[ContextTypes.flow].flows;
      break;
    case 'count':
      value = isRate
        ? MetricSettings[ContextTypes.flow].flowrate
        : MetricSettings[ContextTypes.flow].flows;
      break;
    default:
      value = MetricSettings[ContextTypes.flow][metric] || value;
      break;
  }

  return value.key || value;
};

const getDnsMetric = (isRate) => (metric) => {
  let value = MetricSettings[ContextTypes.dns].card;
  switch (metric) {
    case MetricSettings[ContextTypes.dns].queries.key:
    case MetricSettings[ContextTypes.dns].queryrate.key:
    case 'count':
      value = isRate
        ? MetricSettings[ContextTypes.dns].queryrate
        : MetricSettings[ContextTypes.dns].queries;
      break;
    case MetricSettings[ContextTypes.dns].answers.key:
    case MetricSettings[ContextTypes.dns].avganswers.key:
      value = isRate
        ? MetricSettings[ContextTypes.dns].avganswers
        : MetricSettings[ContextTypes.dns].answers;
      break;
    case 'record':
      value = MetricSettings[ContextTypes.dns].queries;
      break;
    default:
      value = MetricSettings[ContextTypes.dns][metric] || value;
      break;
  }

  return value.key || value;
};

const getMetricDefault = () => (metric) => {
  return metric;
};

const getMetricFns = {
  [ContextTypes.flow]: getFlowMetric,
  [ContextTypes.dns]: getDnsMetric,
};

const getMetrics = (context, metrics, func) => {
  const fn = ThresholdFuncs[func];
  const isRate = fn === ThresholdFuncs.avg;
  const getFn = getMetricFns[context] || getMetricDefault;
  return (metrics || []).map(getFn(isRate));
};

const ThresholdChart = (props) => {
  const { event, threshold, colorIndex, ...rest } = props;

  const context = event.traffic_type || ContextTypes.flow;

  const seriesId = `threshold-${btoa(threshold.Expr)}`;
  const customer = useSelector(customerSelectors.getCurrentCustomer);

  const [ref, { width }] = useMeasure();

  const request = useMemo(
    () => {
      const now = Math.floor(Date.now() / 1000);
      const start = event?.start;
      const end = event?.end || Date.now();
      const nql = event?.search;
      const isSubAccountRecord = event?.customer && event?.customer !== customer?.shortname;

      return {
        seriesId,
        params: {
          start: (start - 120) * 1000,
          end: (end ? Math.min(end + 120, now) : now) * 1000,
          series: getMetrics(context, threshold?.Metric, threshold?.Func).map(
            (metric) => ({
              context,
              name: metric,
              metric,
              ...StatsRequest.makeSearch({
                search: nql,
              }),
              ...(metric === MetricSettings[context].card.key
                ? {
                  field: threshold?.Metric?.[0] ? [threshold?.Metric?.[0]] : [],
                }
                : {}),
            }),
          ),
          ...(isSubAccountRecord && { customers: [event?.customer] }),
        },
      };
    },
    [seriesId, event, threshold, customer?.shortname, context],
  );

  const { series, isFetching } = useStatsRequest({
    context,
    requestType: StatsRequest.Types.ts,
    request,
    stopPollingHeartbeat: !!event?.end,
  });

  const extendedSeries = useMemo(
    () => {
      if (!series?.length) {
        return series;
      }

      let result = [...series];

      if (result.length > 1) {
        let name;
        switch (ThresholdFuncs[threshold.Func]) {
          case ThresholdFuncs.divide:
            name = `${result[0].name} / ${result[1].name}`;
            result = [
              {
                ...result[0],
                data: (result[0].data || []).map((d, i) => [
                  d[0],
                  d[1] / (result[1].data[i][1] || 1),
                ]),
                name,
                label: name,
                series: name,
              },
            ];
            break;
          case ThresholdFuncs.multiply:
            name = `${result[0].name} * ${result[1].name}`;
            result = [
              {
                ...result[0],
                data: (result[0].data || []).map((d, i) => [
                  d[0],
                  d[1] * result[1].data[i][1],
                ]),
                name,
                label: name,
                series: name,
              },
            ];
            break;
          default:
            break;
        }
      }

      if (colorIndex != null) {
        result[0] = {
          ...result[0],
          colorIndex,
        };
      }

      result.unshift({
        ...result[0],
        name: 'threshold',
        label: 'Threshold',
        series: 'threshold',
        data: (result[0].data || []).map((d) => [d[0], threshold.Value]),
        colorIndex: 6,
        seriesType: SeriesTypes.line,
      });

      return result;
    },
    [series, colorIndex, threshold.Value, threshold.Func],
  );

  useLoadingIndicator(isFetching);

  return (
    <ChartContainer {...rest} ref={ref}>
      <TimeseriesChart
        title={renderToStaticMarkup(
          <TitleContainer>
            <PollIcon size={16} />
            <Title>
              <span>Detection Model Chart</span>
              <span className="highcharts-description">{threshold.Expr}</span>
            </Title>
          </TitleContainer>,
        )}
        subtitle={getTitle(threshold)}
        context={context}
        // fields={threshold.fields}
        series={extendedSeries}
        loading={isFetching}
        width={width}
      />
    </ChartContainer>
  );
};

ThresholdChart.propTypes = {
  event: PropTypes.shape().isRequired,
  threshold: PropTypes.shape().isRequired,
  colorIndex: PropTypes.number,
};

ThresholdChart.defaultProps = {
  colorIndex: undefined,
};

export default ThresholdChart;
