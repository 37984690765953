import PropTypes from '+prop-types';
import { Fragment } from 'react';

import { Field } from '+components/form/FinalForm';
import { normalizeSelectValue } from '+components/form/Normalizers';
import RoleAuthFieldsSection from '+components/form/RoleAuthFieldSection';
import SelectField from '+components/form/SelectField';
import TextField from '+components/form/TextField';
import { validateRequired } from '+components/form/Validators';

const apiEndpointOptions = [
  { value: 'us1' },
  { value: 'us2' },
  { value: 'eu1' },
  { value: 'eu2' },
  { value: 'us17' },
];

apiEndpointOptions.forEach((option) => {
  option.value = `https://api.${option.value}.app.wiz.io/graphql`;
});

const tokenEndpointOptions = [
  { value: 'https://auth.app.wiz.io/oauth/token' },
  { value: 'https://auth.wiz.io/oauth/token' },
  { value: 'https://auth.gov.wiz.io/oauth/token' },
  { value: 'https://auth0.gov.wiz.io/oauth/token' },
];

const WizFields = ({ disabled }) => (
  <Fragment>
    <Field
      name="wiz.apiendpoint"
      label="API Endpoint URL"
      component={SelectField}
      validate={validateRequired}
      options={apiEndpointOptions}
      parse={normalizeSelectValue}
      helperText="Wiz API Endpoint URL"
      allowCreate
      disabled={disabled}
      required
    />
    <RoleAuthFieldsSection label="Authentication">
      <Field
        name="wiz.tokenurl"
        label="Token URL"
        component={SelectField}
        validate={validateRequired}
        options={tokenEndpointOptions}
        parse={normalizeSelectValue}
        helperText="Wiz Token URL"
        disabled={disabled}
        required
      />
      <Field
        name="wiz.clientid"
        label="Client ID"
        component={TextField}
        type="text"
        validate={validateRequired}
        helperText="Client id to use authenticating with Wiz API"
        disabled={disabled}
        required
      />

      <Field
        name="wiz.clientsecret"
        label="Client Secret"
        component={TextField}
        type="password"
        autoComplete="off"
        validate={validateRequired}
        helperText="Client secret to use authenticating with Wiz API"
        disabled={disabled}
        required
      />
    </RoleAuthFieldsSection>
  </Fragment>
);

WizFields.propTypes = {
  disabled: PropTypes.bool,
};

WizFields.defaultProps = {
  disabled: false,
};

export default WizFields;
