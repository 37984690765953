import PropTypes from '+prop-types';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { useLocation } from 'react-use';

import { useFlag } from '@unleash/proxy-client-react';
import { useTheme } from 'styled-components';

import FeatureFlags from '@/models/FeatureFlags';
import RoutePaths from '@/models/RoutePaths';
import SettingCategories from '@/models/SettingCategories';

import {
  actions as customerActions,
  selectors as customerSelectors,
} from '@/redux/api/customer';
import { actions as nqlCompleteActions } from '@/redux/api/nql-complete';
import { actions as ruleActions } from '@/redux/api/rules';
import {
  actions as profileActions,
  selectors as profileSelectors,
} from '@/redux/api/user/profile';
import { actions as newSocketActions } from '@/redux/newsocket';

import { config } from '@/config';
import authClient from '@/middleware/authClient';
import DashboardsManage from '@/pages/Dashboards/Manage';
import HomePage from '@/pages/Home';
import Layout from '@/pages/Layout';
import NewTab from '@/pages/NewTab';
import NotFound404 from '@/pages/NotFound404';
import OpenSourceSoftware from '@/pages/OpenSourceSoftware';
import AdminRoutes from '@/routers/components/AdminRoutes';
import DashboardsRoutes from '@/routers/components/DashboardsRoutes';
import InvestigateRoutes from '@/routers/components/InvestigateRoutes';
import NdrRoutes from '@/routers/components/NdrRoutes';
import ToolsRoutes from '@/routers/components/ToolsRoutes';

import { BreadProvider, CrumbRoute } from '+components/Breadcrumb';
import { TryAgainBoundary } from '+components/ErrorBoundary';
import FeaturesProvider from '+components/FeaturesProvider';
import { GlobalFilterProvider } from '+components/GlobalFilters/Context';
import Portal from '+components/Portal';
import { Positions, ToastContainer, Transition } from '+components/toast';
import { useFeatureOverrides } from '+hooks/useFeatureOverrides';
import usePortalSettingsValue from '+hooks/usePortalSettingsValue';
import { useRemoveLoader } from '+hooks/useRemoveLoader';
import useUIProperty from '+hooks/useUIProperty';
import { getSsoLoginUrl, getSsoLogoutUrl, homeUrl } from '+utils';
import gtm from '+utils/GoogleTagManager';

const WrappedRoutes = ({ guest, hideNav }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const location = useLocation();

  const isRolesUiSettingsEnabled = useFlag(FeatureFlags.rolesUiSettings);
  const [isNotificationPopupsEnabled] = usePortalSettingsValue(
    SettingCategories.ui,
    'isNotificationPopupsEnabled',
    true,
  );

  const profile = useSelector(profileSelectors.getProfile);
  const samlProvider = useSelector(customerSelectors.getSamlProvider);

  const [, setVersion] = useUIProperty('version');
  const [, setUnderCover] = useUIProperty('underCover');
  const [, setImpersonating] = useUIProperty('impersonating');
  const [, setGuest] = useUIProperty('guest');
  const [, setHideNav] = useUIProperty('hideNav');
  const [, setWindowFocused] = useUIProperty('windowFocused');

  const [userRoleUiSettings] = usePortalSettingsValue(
    SettingCategories.ui,
    `${profile?.roles?.[0]}:settings`,
    {},
  );

  useFeatureOverrides();

  // it is a dirty hack for skipping the fist render for children
  // to run the next useEffect earlier than children useEffects
  const firstRender = useRef(true);

  useEffect(
    () => {
      firstRender.current = false;

      setGuest(guest);
      setHideNav(
        hideNav || (isRolesUiSettingsEnabled && userRoleUiSettings?.hideNav),
      );

      dispatch(newSocketActions.connectServer());
      dispatch(nqlCompleteActions.fetchDocs());
      dispatch(nqlCompleteActions.fetchThresholdTerms());

      dispatch(profileActions.requestProfileWithDependencies());
      dispatch(ruleActions.fetchDependencies());

      if (!guest) {
        dispatch(customerActions.requestSamlProvider()); // we need this information for redirect logout url
      }

      return () => {
        dispatch(newSocketActions.disconnectServer());
      };
    },
    [guest, hideNav, userRoleUiSettings?.hideNav, isRolesUiSettingsEnabled],
  );

  useEffect(
    () => {
      if (guest) {
        localStorage.setItem('logoutRedirectUri', homeUrl);
        return;
      }

      if (!Object.keys(profile || {}).length) {
        return;
      }

      if (profile?.idp) {
        if (profile?.app_metadata?.original) {
        // If it's masquerading user and logout URL already set - we need to keep it
        // But if logout URL not set - set logout URL to Netography Logout page
          const redirectUri = localStorage.getItem('logoutRedirectUri');
          if (!redirectUri) {
            const logoutRedirectUri = getSsoLogoutUrl(profile?.idp);
            localStorage.setItem('logoutRedirectUri', logoutRedirectUri);
          }
          return;
        }
        // If it's IDP user and company configured SLO set logout URL to company SSO URL
        // If company don't configured SLO - set logout URL to Netography Logout page
        const logoutRedirectUri = profile.idp === samlProvider?.alias
        && samlProvider?.config?.singleLogoutServiceUrl
          ? getSsoLoginUrl(profile?.idp)
          : getSsoLogoutUrl(profile?.idp);
        localStorage.setItem('logoutRedirectUri', logoutRedirectUri);
      } else {
        localStorage.removeItem('logoutRedirectUri');
      }
    },
    [guest, profile, samlProvider],
  );

  useEffect(
    () => {
      if (profile?.id) {
        gtm.dataLayerPush({
          event: 'userIdentify',
          userId: profile.id,
          userEmail: profile.email,
          userName: profile.name,
          userRole: profile.roles[0],
          userShortname: profile.app_metadata?.shortname,
          originalShortname: profile.app_metadata?.original,
          theme: theme.name,
          environment: config.environment,
        });
      }
    },
    [profile?.id],
  );

  useEffect(
    () => {
      if (guest) {
        return undefined;
      }

      const onStorageChanged = (event) => {
        if (event.key === 'impersonate') {
          document.location.reload();
        }
      };

      // Reload this tab if user impersonate on another tab
      window.addEventListener('storage', onStorageChanged);

      return () => {
        document.removeEventListener('storage', onStorageChanged, true);
      };
    },
    [guest],
  );

  useEffect(
    () => {
      setVersion(document.querySelector('meta[name="version"]')?.content);
    },
    [],
  );

  useEffect(
    () => {
      const realUser = authClient.getUser();
      setUnderCover(!!profile?.app_metadata?.original);
      setImpersonating(profile?.email && realUser?.email !== profile?.email);
    },
    [profile],
  );

  useEffect(
    () => {
      const onVisibilityChange = () => {
        setWindowFocused(document.visibilityState === 'visible');
      };
      document.addEventListener('visibilitychange', onVisibilityChange);
      return () => {
        document.removeEventListener('visibilitychange', onVisibilityChange);
      };
    },
    [],
  );

  useRemoveLoader();

  return firstRender.current ? null : (
    <TryAgainBoundary resetKey={location.key}>
      {isNotificationPopupsEnabled && (
        <Portal inBody>
          <ToastContainer
            icon={false}
            position={Positions.BOTTOM_RIGHT}
            transition={Transition.Flip}
            autoClose={6000}
            stacked
          />
        </Portal>
      )}

      <BreadProvider>
        <GlobalFilterProvider>
          <Layout>
            <TryAgainBoundary resetKey={location.key}>
              <Routes>
                <Route
                  index
                  element={<CrumbRoute title="Home" component={HomePage} />}
                />

                <Route
                  path={RoutePaths.dashboardsFavorites.pageName}
                  element={<DashboardsManage />}
                />

                <Route
                  path={`${RoutePaths.dashboards.pageName}/*`}
                  element={<DashboardsRoutes />}
                />

                <Route path="investigate/*" element={<InvestigateRoutes />} />

                <Route path="ndr/*" element={<NdrRoutes />} />

                <Route
                  path="tools/*"
                  element={<CrumbRoute title="Tools" component={ToolsRoutes} />}
                />

                <Route path="admin/*" element={<AdminRoutes />} />

                <Route
                  path={RoutePaths.openSourceSoftware.pageName}
                  element={(
                    <CrumbRoute
                      title="Netography - Open Source Software"
                      component={OpenSourceSoftware}
                    />
                  )}
                />

                <Route
                  path={RoutePaths.newTab.pageName}
                  element={<CrumbRoute title="New Tab" component={NewTab} />}
                />

                <Route
                  path="*"
                  element={(
                    <CrumbRoute
                      title="Page Not Found"
                      component={NotFound404}
                    />
                  )}
                />
              </Routes>
            </TryAgainBoundary>
          </Layout>
        </GlobalFilterProvider>
      </BreadProvider>
    </TryAgainBoundary>
  );
};

WrappedRoutes.propTypes = {
  guest: PropTypes.bool,
  hideNav: PropTypes.bool,
};

WrappedRoutes.defaultProps = {
  guest: false,
  hideNav: false,
};

const WrappedWithFeatures = (props) => (
  <FeaturesProvider>
    <WrappedRoutes {...props} />
  </FeaturesProvider>
);

WrappedWithFeatures.propTypes = WrappedRoutes.propTypes;
WrappedWithFeatures.defaultProps = WrappedRoutes.defaultProps;

export default WrappedWithFeatures;
