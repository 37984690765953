import styled from 'styled-components';

export default styled(({ className, state }) => {
  return <div className={`${className} ${state}`}>{state}</div>;
})`
  font-size: 10px;
  line-height: 18px;
  display: inline-block;
  margin-left: 2px;
  margin-right: 2px;
  text-transform: uppercase;

  height: 18px;
  padding-left: 5px;
  padding-right: 5px;

  text-align: center;
  color: black !important;
  background-color: #61bbd9;

  &.ESTABLISHED {
    background-color: #53e0b7;
  }

  &.IDLE {
    color: black;
    background-color: #878787;
  }

  &.NONE {
    color: black;
    background-color: #878787;
  }
`;
