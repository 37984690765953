import { Fragment, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ContextTypes } from '@/models/ContextTypes';

import { selectors, actions } from '@/redux/api/dnsclass';
import {
  selectors as vpcSelectors,
  actions as vpcActions,
} from '@/redux/api/vpc';

import Alert from '+components/Alert';
import { Row, Col } from '+components/Layout';
import useLoadingIndicator from '+hooks/useLoadingIndicator';

import DnsClassForm from './DnsClassForm';

const NetworkClassifications = () => {
  const dispatch = useDispatch();
  const { dnsClass, isFetching } = useSelector(selectors.getState);
  const isVpcFetching = useSelector(vpcSelectors.isFetching);
  const vpcData = useSelector(vpcSelectors.getVpcs);

  const hasDnsConfig = useMemo(
    () => Object.values(vpcData || {}).some(
      (item) => item.traffictype === ContextTypes.dns,
    ),
    [vpcData],
  );

  useLoadingIndicator(isFetching || isVpcFetching);

  useEffect(
    () => {
      dispatch(actions.fetch());
      dispatch(vpcActions.fetchVpcs());
    },
    [],
  );

  const isDisabled = isFetching || isVpcFetching || !hasDnsConfig;

  const showAlert = !(hasDnsConfig || isVpcFetching || isFetching);

  return (
    <Fragment>
      {showAlert && (
        <Row>
          <Col xl={6}>
            <Alert>No DNS traffic sources.</Alert>
          </Col>
        </Row>
      )}
      <Row>
        <Col xl={10}>
          <DnsClassForm initialValues={dnsClass} isFetching={isDisabled} />
        </Col>
      </Row>
    </Fragment>
  );
};

export default NetworkClassifications;
