import capitalize from 'lodash.capitalize';

import { ContextTypes } from '@/models/ContextTypes';
import { TimeDuration, TimePeriods } from '@/models/TimePeriods';

import dayjs from '+utils/dayjs';

export const timeInterval = 30;

export const schedulePeriods = {
  daily: 'daily',
  weekly: 'weekly',
  monthly: 'monthly',
};

export const schedulePeriodOptions = Object.values(schedulePeriods).map(
  (value) => ({
    value,
    label: capitalize(value),
  }),
);

export const dashboardPeriodsOptions = [
  TimeDuration.minute,
  TimeDuration.hour,
  TimeDuration.day,
  TimeDuration.week,
  TimeDuration.month,
].map((value) => ({
  value,
  label: TimePeriods[value].name,
}));

export const contextOptions = [
  {
    value: ContextTypes.flow,
    label: 'Flow',
  },
  {
    value: ContextTypes.dns,
    label: 'DNS',
  },
  {
    value: ContextTypes.alerts,
    label: 'Events',
  },
  {
    value: ContextTypes.blocks,
    label: 'Blocks',
  },
];

const cronStringToObj = (cronString) => {
  const [minute, hour, day, month, dayOfWeek] = cronString.split(' ');
  return {
    minute,
    hour,
    day,
    month,
    dayOfWeek,
  };
};

const cronObjToString = (cronObj) => {
  const { minute, hour, day, month, dayOfWeek } = cronObj;
  return `${minute} ${hour} ${day} ${month} ${dayOfWeek}`;
};

export const formatCronToFrequency = (cronString) => {
  const cronObj = cronStringToObj(cronString);
  if (cronObj.day !== '*') {
    return schedulePeriods.monthly;
  }
  if (cronObj.dayOfWeek !== '*') {
    return schedulePeriods.weekly;
  }
  return schedulePeriods.daily;
};

export const parseFrequencyToCron = (cronString, value) => {
  const cronObj = cronStringToObj(cronString);
  switch (value) {
    case schedulePeriods.monthly:
      return cronObjToString({
        ...cronObj,
        day: dayjs().format('D'),
        month: '*',
        dayOfWeek: '*',
      });
    case schedulePeriods.weekly:
      return cronObjToString({
        ...cronObj,
        day: '*',
        month: '*',
        dayOfWeek: dayjs().format('d'),
      });
    case schedulePeriods.daily:
    default:
      return cronObjToString({
        ...cronObj,
        day: '*',
        month: '*',
        dayOfWeek: '*',
      });
  }
};

export const formatCronToDayOfWeek = (cronString) => {
  const cronObj = cronStringToObj(cronString);
  return cronObj.dayOfWeek;
};

export const parseDayOfWeekToCron = (cronString, value) => {
  const cronObj = cronStringToObj(cronString);
  return cronObjToString({
    ...cronObj,
    dayOfWeek: value,
  });
};

export const formatCronToDayOfMonth = (cronString) => {
  const cronObj = cronStringToObj(cronString);
  return cronObj.day;
};

export const parseDayOfMonthToCron = (cronString, value) => {
  const cronObj = cronStringToObj(cronString);
  return cronObjToString({
    ...cronObj,
    day: value,
  });
};

export const formatCronToTime = (cronString, timeFormat) => {
  const cronObj = cronStringToObj(cronString);
  const start = dayjs()
    .startOf('day')
    .add(cronObj.hour, 'hour')
    .add(cronObj.minute, 'minute');
  return start.format(timeFormat);
};

export const parseTimeToCron = (cronString, value) => {
  const cronObj = cronStringToObj(cronString);
  const [time, ampm] = value.split(' ');
  const [hour, minute] = time.split(':');
  return cronObjToString({
    ...cronObj,
    minute: parseInt(minute, 10),
    hour: parseInt(hour, 10) + (ampm === 'PM' ? 12 : 0),
  });
};

export const formatLabelContext = (value) => (value?.show ? value?.ip : false);

export const parseLabelContext = (labelContext, value) => {
  if (value) {
    return {
      ...labelContext,
      show: true,
      ip: value,
    };
  }
  return {
    ...labelContext,
    show: false,
  };
};
