import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { useFlag } from '@unleash/proxy-client-react';

import { ContextTypes } from '@/models/ContextTypes';

import {
  actions as rulesActions,
  selectors as rulesSelectors,
} from '@/redux/api/rules';
import {
  actions as thresholderActions,
  selectors as thresholderSelectors,
} from '@/redux/api/thresholder';

import CommonAdd from '../shared/CommonAdd';
import {
  Config,
  cadenceOptions,
  strategyOptions,
  uiToAutoThresholdParams,
  uiToParams,
} from '../shared/utils';
import FormBody from './FormBody';

const commonDefaultValues = {
  algo_type: 'TDM',
  algo_record_type: ContextTypes.flow,
  enabled: true,
  bypassdisplay: false,
  bypassrule: false,
  rollupperiod: 300,
  updateinterval: 0,
  subscriptiontype: 'optout',
  ndm_score_threat: 0,
  ndm_score_confidence: 0,
  autoThresholdEnabled: false,
  autoThresholdData: {
    strategy: strategyOptions.average,
    data_interval: cadenceOptions.weekly,
    data_window: 12,
    data_lookback: 60,
  },
};

const defaultValues = {
  [ContextTypes.flow]: {
    ...commonDefaultValues,
    search_by: [Config.defaultSearchBy],
    thresholds: [Config.defaultThresholds],
    track_by: [Config.defaultTrackBy],
    discards: [Config.defaultDiscards],
  },
  [ContextTypes.dns]: {
    ...commonDefaultValues,
    search_by: [Config.defaultSearchBy],
    thresholds: [Config.defaultThresholds],
    track_by: [Config.defaultTrackBy],
    discards: [Config.defaultDiscards],
  },
};

const pickFields = [
  'name',
  'algo_record_type',
  'description',
  'categories',
  'enabled',
  'search_by',
  'rollupperiod',
  'thresholds',
  'track_by',
  'discards',
  'updateinterval',
  'ndm_score_confidence',
  'ndm_score_threat',
];

const AlgorithmAdd = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const initId = searchParams.get('init');

  const isDnsEnabled = useFlag('DNS');
  const autoThresholdsFeatureFlag = useFlag('autoThresholds');

  const initAlgorithm = useSelector(rulesSelectors.getAlgorithm(initId));
  const addedAlgorithm = useSelector(rulesSelectors.getAddedAlgorithm);
  const isFetching = useSelector(thresholderSelectors.isFetching);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [creationValues, setCreationValues] = useState({});

  const onSubmit = useCallback(
    ({ id, ...values }) => {
      setCreationValues(values);
      dispatch(
        rulesActions.addAlgorithm({
          ...uiToParams(values),
          saveAddedAlgorithm: values.autoThresholdEnabled,
          silent: autoThresholdsFeatureFlag && values.autoThresholdEnabled,
        }),
      );
    },
    [],
  );

  useEffect(
    () => {
      if (initId && !initAlgorithm) {
        dispatch(rulesActions.fetchAlgorithm(initId));
      }
    },
    [initId, initAlgorithm],
  );

  const postProcessing = useCallback(
    () => {
      if (isFetching) {
      // we don't want to finish submitting process and close from.
        return false;
      }

      if (
        autoThresholdsFeatureFlag
      && !isSubmitting
      && creationValues.autoThresholdEnabled
      && addedAlgorithm
      ) {
        setIsSubmitting(true);
        const automatonConfig = uiToAutoThresholdParams(creationValues);
        dispatch(
          thresholderActions.updateAutomaton({
            ...automatonConfig,
            afterCreateMode: true,
          }),
        );
        return false;
      }

      dispatch(rulesActions.clearAddedAlgorithm());
      return true;
    },
    [
      autoThresholdsFeatureFlag,
      creationValues,
      addedAlgorithm,
      isSubmitting,
      isFetching,
    ],
  );

  const title = isDnsEnabled ? '' : 'Network ';

  return (
    <CommonAdd
      title={`Add ${title}Detection Model`}
      initAlgorithm={initAlgorithm}
      pickFields={pickFields}
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      FormBody={FormBody}
      focusOnFields={false}
      isLoading={isFetching}
      postProcessing={postProcessing}
    />
  );
};

export default AlgorithmAdd;
