/* eslint-disable react/prop-types */
import SeverityLabel from '+components/Labels/SeverityLabel';

import CellWrapper from './CellWrapper';

const CellSeverity = ({ field, value, original, disabled }) => (
  <CellWrapper
    customer={original?.customer}
    field={field}
    value={value}
    disabled={disabled}
  >
    <SeverityLabel severity={value} />
  </CellWrapper>
);

export default CellSeverity;
