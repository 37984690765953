import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { FORM_ERROR } from 'final-form';

import PermissionModel from '@/models/Permission';
import RoutePaths from '@/models/RoutePaths';
import SettingCategories from '@/models/SettingCategories';

import { selectors as customerSelectors } from '@/redux/api/customer';
import {
  actions as dashboardsActions,
  selectors as dashboardsSelectors,
} from '@/redux/api/dashboards';
import {
  actions as usersActions,
  selectors as usersSelectors,
} from '@/redux/api/user';
import { selectors as profileSelectors } from '@/redux/api/user/profile';

import DashboardsTable from '@/pages/Dashboards/Manage/components/DashboardsTable';
import { Columns } from '@/pages/Dashboards/Manage/components/DashboardsTable/components/Columns';
import SchedulesTable from '@/pages/Dashboards/Manage/components/SchedulesTable';
import { defaultDashboard } from '@/pages/Dashboards/shared/propTypes';

import { Breadcrumb } from '+components/Breadcrumb';
import ConfirmModal from '+components/ConfirmModal';
import { TabsContainer, Tabs, Tab, TabContent } from '+components/Tabs';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import usePermissions from '+hooks/usePermissions';
import usePortalSettingsValue from '+hooks/usePortalSettingsValue';

import DashboardForm from '../DashboardForm';
import DashboardCloneForm from './components/DashboardCloneForm';
import DashboardGroupsManageForm from './components/DashboardGroupsManageForm';

const tabs = {
  all: { value: 'all', label: 'All' },
  recent: { value: 'recent', label: 'Recent' },
  favorites: {
    value: 'favorites',
    label: 'Favorites',
    link: `${RoutePaths.dashboardsFavorites}`,
  },
  my: { value: 'my', label: 'My' },
  system: { value: 'system', label: 'System' },
  company: { value: 'company', label: 'Company' },
  scheduled: { value: 'scheduled', label: 'Scheduled' },
};

const defaultTab = tabs.all;

const groupByAll = [Columns.groups];
const sortByAll = [
  { id: 'groups', desc: false },
  { id: 'title', desc: false },
];
const sortByRecent = [{ id: 'lastseen', desc: true }];
const sortByOther = sortByAll;

const Manage = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const permissions = usePermissions(PermissionModel.Resources.dashboard.value);

  const customer = useSelector(customerSelectors.getCurrentCustomer);
  const profile = useSelector(profileSelectors.getProfile);
  const users = useSelector(usersSelectors.getUsers);
  const isDefaultCustomer = useSelector(profileSelectors.isDefaultCustomer);

  const isFetching = useSelector(dashboardsSelectors.isFetching);
  const isAllMetaFetched = useSelector(dashboardsSelectors.isAllMetaFetched);
  const dashboardsMeta = useSelector(dashboardsSelectors.getDashboardsMeta);
  const createdDashboardId = useSelector(
    dashboardsSelectors.getCreatedDashboardId,
  );
  const error = useSelector(dashboardsSelectors.getError);

  const [favorites] = usePortalSettingsValue(
    SettingCategories.dashboard,
    'favorites',
    [],
  );
  const [recent] = usePortalSettingsValue(
    SettingCategories.dashboard,
    'recents',
    [],
  );
  const [pinnedDashboards] = usePortalSettingsValue(
    SettingCategories.dashboard,
    'pinnedDashboards',
    [],
  );

  const [currentDashboard, setCurrentDashboard] = useState({});
  const [showConfigureNew, setShowConfigureNew] = useState(false);
  const [showConfigureExisting, setShowConfigureExisting] = useState(false);
  const [dashboardToManageGroups, setDashboardToManageGroups] = useState(null);
  const [dashboardToDelete, setDashboardToDelete] = useState(null);
  const [dashboardToClone, setDashboardToClone] = useState(null);
  const [isProcessing, setIsProcessing] = useState(null);

  useLoadingIndicator(isFetching);

  const allDashboards = useMemo(
    () => {
      const pinnedHash = pinnedDashboards.reduce((acc, item) => {
        if (item.customer === customer?.shortname) {
          acc[item.id] = true;
        }
        return acc;
      }, {});
      const favoriteSet = new Set(favorites);
      const recentHash = recent.reduce((acc, { id, timestamp }) => {
        acc[id] = timestamp;
        return acc;
      }, {});
      const usersHash = Object.values(users || {}).reduce(
        (acc, el) => ({ ...acc, [el.email]: el }),
        {},
      );
      return Object.values(dashboardsMeta || {}).reduce((acc, item) => {
        if (!isDefaultCustomer && item.hidden) {
          return acc;
        }
        acc.push({
          ...item,
          user: item.system ? undefined : usersHash[item.created_by],
          pinned: !!pinnedHash[item.id],
          favorite: favoriteSet.has(item.id),
          lastseen: recentHash[item.id],
          created_by: item.system ? 'Netography' : item.created_by,
        });
        return acc;
      }, []);
    },
    [
      dashboardsMeta,
      favorites,
      users,
      recent,
      isDefaultCustomer,
      pinnedDashboards,
      customer?.shortname,
    ],
  );

  const recentDashboards = useMemo(
    () => allDashboards.filter((item) => item.lastseen),
    [allDashboards],
  );

  const favoriteDashboards = useMemo(
    () => allDashboards.filter((item) => item.favorite),
    [allDashboards],
  );

  const myDashboards = useMemo(
    () => allDashboards.filter(
      (item) => !item.system && item.created_by === profile?.email,
    ),
    [allDashboards, profile],
  );

  const systemDashboards = useMemo(
    () => allDashboards.filter((item) => item.system),
    [allDashboards],
  );

  const companyDashboards = useMemo(
    () => allDashboards.filter((item) => !item.system),
    [allDashboards],
  );

  const toggleConfigureNew = useCallback(
    () => setShowConfigureNew((prev) => !prev),
    [],
  );

  const toggleConfigureExisting = useCallback(
    () => setShowConfigureExisting((prev) => !prev),
    [],
  );

  const onDeleteToggle = useCallback(
    (id) => {
      setDashboardToDelete((prev) => (prev ? null : dashboardsMeta?.[id]));
    },
    [dashboardsMeta],
  );

  const onDeleteConfirm = useCallback(
    () => {
      dispatch(dashboardsActions.removeDashboard(dashboardToDelete.id));
      setDashboardToDelete(null);
      setShowConfigureExisting(false);
      setCurrentDashboard(null);
    },
    [dashboardToDelete],
  );

  const onEdit = useCallback(
    (id) => {
      setCurrentDashboard(dashboardsMeta?.[id]);
      setShowConfigureExisting(true);
    },
    [dashboardsMeta],
  );

  const onCloneToggle = useCallback(
    (id) => setDashboardToClone((prev) => (prev ? null : dashboardsMeta?.[id])),
    [dashboardsMeta],
  );

  const onCloneConfirm = useCallback(
    (values) => {
      dispatch(
        dashboardsActions.cloneDashboard({
          id: values.id,
          shortname: values.copyTo,
        }),
      );
      onCloneToggle();
    },
    [onCloneToggle],
  );

  const onAddDashboardToggle = useCallback(
    () => {
      setCurrentDashboard({ ...defaultDashboard });
      setShowConfigureNew(true);
    },
    [],
  );

  const onAddDashboardConfirm = useCallback(
    (dashboard) => {
      dispatch(dashboardsActions.createDashboard(dashboard));

      return new Promise((resolve) => {
        setIsProcessing({ resolve });
      });
    },
    [],
  );

  const onSave = useCallback(
    (dashboard) => {
      dispatch(dashboardsActions.updateDashboard(dashboard));

      return new Promise((resolve) => {
        setIsProcessing({ resolve });
      });
    },
    [],
  );

  useEffect(
    () => {
      if (isFetching || !isProcessing) {
        return undefined;
      }

      const { resolve } = isProcessing;

      setIsProcessing(null);

      if (error) {
        resolve({ [FORM_ERROR]: error });
        return undefined;
      }

      resolve();
      setShowConfigureNew(false);
      setShowConfigureExisting(false);
      setDashboardToManageGroups(null);

      if (createdDashboardId) {
        navigate(`${RoutePaths.dashboards}/${createdDashboardId}`);
      }

      return () => {
        dispatch(dashboardsActions.clearCreatedDashboardId());
      };
    },
    [isFetching, isProcessing, error, createdDashboardId],
  );

  const usersLength = Object.keys(users || {}).length;
  useEffect(
    () => {
      if (!usersLength) {
        dispatch(usersActions.requestUsers());
      }
    },
    [usersLength],
  );

  useEffect(
    () => {
      if (!isAllMetaFetched) {
        dispatch(dashboardsActions.fetchDashboardsMeta());
      }
    },
    [isAllMetaFetched],
  );

  useEffect(
    () => {
      if (!Object.keys(users || {}).length) {
        dispatch(usersActions.requestUsers());
      }
    },
    [Object.keys(users || {}).length],
  );

  /** * TABS ** */
  const tabId = useMemo(
    () => {
      const search = new URLSearchParams(location.search);
      const tab = search.get('tab');
      if (tab) {
        return tab;
      }
      const last = location.pathname.split('/').pop();
      return last.split('-').pop();
    },
    [location.search, location.pathname],
  );

  const currentTab = useMemo(
    () => tabs[tabId] || defaultTab,
    [tabId, tabs, defaultTab],
  );

  const onTabChange = useCallback(
    (_, value) => {
      if (value === defaultTab.value) {
        navigate(`${RoutePaths.dashboards}`);
      } else {
        if (tabs[value]?.link) {
          navigate(tabs[value]?.link);
          return;
        }
        const search = new URLSearchParams();
        search.set('tab', value);
        navigate(`${RoutePaths.dashboards}?${search.toString()}`);
      }
    },
    [defaultTab],
  );

  return (
    <Fragment>
      <Breadcrumb title={`Dashboards — ${currentTab.label}`} />

      <TabsContainer>
        <Tabs
          value={currentTab.value}
          onChange={onTabChange}
          data-tracking="dashboard-tabs"
        >
          <Tab
            label={tabs.all.label}
            value={tabs.all.value}
            data-tracking={tabs.all.value}
          />
          <Tab
            label={tabs.recent.label}
            value={tabs.recent.value}
            data-tracking={tabs.recent.value}
          />
          <Tab
            label={tabs.favorites.label}
            value={tabs.favorites.value}
            data-tracking={tabs.favorites.value}
          />
          <Tab
            label={tabs.my.label}
            value={tabs.my.value}
            data-tracking={tabs.my.value}
          />
          <Tab
            label={tabs.system.label}
            value={tabs.system.value}
            data-tracking={tabs.system.value}
          />
          <Tab
            label={tabs.company.label}
            value={tabs.company.value}
            data-tracking={tabs.company.value}
          />
          <Tab
            label={tabs.scheduled.label}
            value={tabs.scheduled.value}
            data-tracking={tabs.scheduled.value}
          />
        </Tabs>

        {currentTab.value === tabs.all.value && (
          <TabContent>
            <DashboardsTable
              id="Dashboards_Manage_DashboardsTabs_All"
              dashboards={allDashboards}
              sortBy={sortByAll}
              groupBy={groupByAll}
              onAdd={onAddDashboardToggle}
              onEdit={onEdit}
              onDelete={onDeleteToggle}
              onCopy={onCloneToggle}
              onManageGroups={setDashboardToManageGroups}
              grouped
            />
          </TabContent>
        )}

        {currentTab.value === tabs.recent.value && (
          <TabContent>
            <DashboardsTable
              id="Dashboards_Manage_DashboardsTabs_Recent"
              dashboards={recentDashboards}
              sortBy={sortByRecent}
              onAdd={onAddDashboardToggle}
              onEdit={onEdit}
              onDelete={onDeleteToggle}
              onCopy={onCloneToggle}
              onManageGroups={setDashboardToManageGroups}
            />
          </TabContent>
        )}

        {currentTab.value === tabs.favorites.value && (
          <TabContent>
            <DashboardsTable
              id="Dashboards_Manage_DashboardsTabs_Favorites"
              dashboards={favoriteDashboards}
              sortBy={sortByOther}
              onAdd={onAddDashboardToggle}
              onEdit={onEdit}
              onDelete={onDeleteToggle}
              onCopy={onCloneToggle}
              onManageGroups={setDashboardToManageGroups}
            />
          </TabContent>
        )}

        {currentTab.value === tabs.my.value && (
          <TabContent>
            <DashboardsTable
              id="Dashboards_Manage_DashboardsTabs_My"
              dashboards={myDashboards}
              sortBy={sortByOther}
              onAdd={onAddDashboardToggle}
              onEdit={onEdit}
              onDelete={onDeleteToggle}
              onCopy={onCloneToggle}
              onManageGroups={setDashboardToManageGroups}
            />
          </TabContent>
        )}

        {currentTab.value === tabs.system.value && (
          <TabContent>
            <DashboardsTable
              id="Dashboards_Manage_DashboardsTabs_System"
              dashboards={systemDashboards}
              sortBy={sortByOther}
              onAdd={onAddDashboardToggle}
              onEdit={onEdit}
              onDelete={onDeleteToggle}
              onCopy={onCloneToggle}
              onManageGroups={setDashboardToManageGroups}
            />
          </TabContent>
        )}

        {currentTab.value === tabs.company.value && (
          <TabContent>
            <DashboardsTable
              id="Dashboards_Manage_DashboardsTabs_Custom"
              dashboards={companyDashboards}
              sortBy={sortByOther}
              onAdd={onAddDashboardToggle}
              onEdit={onEdit}
              onDelete={onDeleteToggle}
              onCopy={onCloneToggle}
              onManageGroups={setDashboardToManageGroups}
            />
          </TabContent>
        )}

        {currentTab.value === tabs.scheduled.value && (
          <TabContent>
            <SchedulesTable id="Dashboards_Manage_DashboardsTabs_Scheduled" />
          </TabContent>
        )}
      </TabsContainer>

      {showConfigureNew && currentDashboard?.length !== 0 && (
        <DashboardForm
          toggleModal={isFetching ? null : toggleConfigureNew}
          dashboard={currentDashboard}
          onSave={onAddDashboardConfirm}
          disabled={isFetching || !permissions?.create}
          isOpen
        />
      )}

      {showConfigureExisting && currentDashboard?.length !== 0 && (
        <DashboardForm
          toggleModal={isFetching ? null : toggleConfigureExisting}
          dashboard={currentDashboard}
          disabled={isFetching || !permissions?.update}
          deleteButtonText="Delete Dashboard"
          deleteButtonHidden={!currentDashboard.id}
          deleteButtonDisabled={!permissions?.delete}
          onSave={onSave}
          onDelete={() => onDeleteToggle(currentDashboard.id)}
          editMode
          isOpen
        />
      )}

      {!!dashboardToClone && (
        <DashboardCloneForm
          dashboard={dashboardToClone}
          onToggle={onCloneToggle}
          onConfirm={onCloneConfirm}
          isOpen
        />
      )}

      {!!dashboardToDelete && (
        <ConfirmModal
          item={dashboardToDelete?.title}
          onToggle={onDeleteToggle}
          onConfirm={onDeleteConfirm}
          isOpen
        />
      )}

      {!!dashboardToManageGroups && (
        <DashboardGroupsManageForm
          initialValues={dashboardToManageGroups}
          onToggle={() => setDashboardToManageGroups(null)}
          onConfirm={onSave}
          isOpen
        />
      )}
    </Fragment>
  );
};

export default Manage;
