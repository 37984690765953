import PropTypes from '+prop-types';
import { forwardRef, useRef, useState, useCallback, useEffect } from 'react';

import classNames from 'classnames';
import styled from 'styled-components';

import { TextareaAutosize as TextareaAutosizeOrigin } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';

import VisibilityIcon from 'mdi-react/VisibilityIcon';
import VisibilityOffIcon from 'mdi-react/VisibilityOffIcon';

import FormField from '+components/form/FormField';
import InputTextOrigin from '+components/form/InputText';
import IconButtonOrigin from '+components/IconButton';

import createDescriptionLinks from './createDescriptionLinks';

const IconButton = styled(IconButtonOrigin)`
  padding: unset !important;
  width: 26px;
  height: 26px;

  :hover {
    background-color: ${({ theme }) => theme.color(theme.iconButtonBackground).lighten(0.2)} !important;
  }
`;

const InputText = styled(InputTextOrigin)`
  .MuiInputBase-input {
    padding: 0 7px;
  }

  .MuiInputAdornment-root {
    border-left: unset !important;
    //padding-right: 3px;
  }
`;

const FormStyledTextArea = styled(TextareaAutosizeOrigin)`
  overflow: auto !important;
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 8px;
  }
  &::-webkit-scrollbar-track-piece {
    background-color: transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-resizer {
    background-color: transparent;
  }
  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.textAreaScrollbar};
    border-radius: 4px;
  }
`;

const TextField = forwardRef((props, ref) => {
  const {
    className,
    input,
    type,
    placeholder,
    label,
    helperText,
    focus,
    meta: { touched, error, dirty, submitFailed },
    disabled,
    readOnly,
    required,
    componentBefore,
    componentAfter,
    errorPositionOver,
    allowVisiblePassword,
    parseDescriptionUrls,
    ...tail
  } = props;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const inputRef = ref || input.ref || useRef(null);
  const invalid = error && (dirty || submitFailed) && touched;
  const mergedType = input.type || type;
  const localPlaceholder = disabled ? '—' : placeholder;

  const [showPassword, setShowPassword] = useState(false);
  const onShowPasswordToggle = useCallback(
    () => setShowPassword((prevValue) => !prevValue),
    [],
  );

  useEffect(
    () => {
      if (focus && inputRef) {
        setTimeout(() => {
          if (inputRef.current) {
            inputRef.current.focus();
          }
        }, 0);
      }
    },
    [focus],
  );

  return (
    <FormField
      label={label}
      helperText={parseDescriptionUrls ? createDescriptionLinks(helperText) : helperText}
      error={error}
      invalid={invalid}
      disabled={disabled}
      required={required}
      errorPositionOver={errorPositionOver}
    >
      {componentBefore}

      {mergedType === 'textarea' && (
        <FormStyledTextArea
          {...tail}
          {...input}
          ref={inputRef}
          className={classNames(className, { invalid })}
          placeholder={localPlaceholder}
          disabled={disabled}
          readOnly={readOnly}
          maxRows={10}
        />
      )}

      {mergedType === 'password' && (
        <InputText
          {...tail}
          {...input}
          ref={inputRef}
          className={className}
          placeholder={localPlaceholder}
          type={showPassword ? 'text' : mergedType}
          $invalid={invalid}
          disabled={disabled}
          InputProps={
            mergedType === 'password' && allowVisiblePassword
              ? {
                readOnly,
                className: classNames({ invalid, readOnly }),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      size="medium"
                      disabled={disabled || readOnly}
                      onClick={onShowPasswordToggle}
                    >
                      {showPassword ? (
                        <VisibilityOffIcon size={16} />
                      ) : (
                        <VisibilityIcon size={16} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }
              : {
                readOnly,
                className: classNames({ invalid, readOnly }),
              }
          }
        />
      )}

      {/* TODO: Move all input types to MUI */}
      {mergedType !== 'textarea' && mergedType !== 'password' && (
        <input
          {...tail}
          {...input}
          ref={inputRef}
          className={classNames(className, { invalid })}
          placeholder={localPlaceholder}
          type={mergedType}
          disabled={disabled}
          readOnly={readOnly}
        />
      )}

      {componentAfter}
    </FormField>
  );
});

TextField.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    dirty: PropTypes.bool,
    submitFailed: PropTypes.bool,
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ),
  placeholder: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  helperText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  focus: PropTypes.bool,
  componentBefore: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.func,
    PropTypes.element,
  ]),
  componentAfter: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.func,
    PropTypes.element,
  ]),
  errorPositionOver: PropTypes.bool,
  allowVisiblePassword: PropTypes.bool,
  parseDescriptionUrls: PropTypes.bool,
};

TextField.defaultProps = {
  className: null,
  type: 'text',
  meta: null,
  options: [],
  placeholder: '',
  label: null,
  helperText: null,
  disabled: false,
  readOnly: false,
  required: false,
  focus: false,
  componentBefore: null,
  componentAfter: null,
  errorPositionOver: false,
  allowVisiblePassword: false,
  parseDescriptionUrls: false,
};

export { TextField, FormStyledTextArea };

export default TextField;
