import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import PermissionModel from '@/models/Permission';

import {
  actions as dashboardsActions,
  selectors as dashboardsSelectors,
} from '@/redux/api/dashboards';

import usePermissions from '+hooks/usePermissions';

import WidgetForm from '../WidgetForm';

const WidgetFormEdit = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const permissions = usePermissions(PermissionModel.Resources.dashboard.value);

  const dashboard = useSelector(
    dashboardsSelectors.getDashboard(params.dashboardId),
  );

  const widget = useMemo(
    () => (dashboard?.widgets || []).find((item) => item.id === params.widgetId),
    [dashboard, params.widgetId],
  );

  useEffect(
    () => {
      if (!dashboard?.id && params.dashboardId) {
        dispatch(dashboardsActions.fetchDashboard({ id: params.dashboardId }));
      }
    },
    [dashboard?.id, params.dashboardId],
  );

  return !permissions?.update || !dashboard || !widget ? null : (
    <WidgetForm dashboard={dashboard} widget={widget} />
  );
};

export default WidgetFormEdit;
