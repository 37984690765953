import { PluginGroups } from './PluginGroups';
import { PluginTypes } from './PluginTypes';

/**
 * Response integrations
 * Alphabetical order by title, not adapter
 */
export const ResponseIntegrations = {
  route53: {
    group: PluginGroups.dns.name,
    title: 'AWS Route 53',
  },
  bgp: {
    group: PluginGroups.traffic.name,
    title: 'BGP',
  },
  bigpanda: {
    group: PluginGroups.webhook.name,
    title: 'BigPanda',
  },
  blocklist: {
    group: PluginGroups.block.name,
    title: 'Blocklist',
  },
  crowdstrike: {
    group: PluginGroups.block.name,
    title: 'CrowdStrike',
  },
  email: {
    group: PluginGroups.notification.name,
    title: 'Email',
  },
  flowspec: {
    group: PluginGroups.block.name,
    title: 'Flowspec',
  },
  flowspec_custom: {
    group: PluginGroups.block.name,
    title: 'Flowspec (Custom)',
  },
  teams: {
    group: PluginGroups.notification.name,
    title: 'Microsoft Teams',
  },
  ns1: {
    group: PluginGroups.dns.name,
    title: 'NS1',
  },
  pagerduty: {
    group: PluginGroups.notification.name,
    title: 'Pagerduty',
  },
  panther: {
    group: PluginGroups.webhook.name,
    title: 'Panther',
  },
  rtbh: {
    group: PluginGroups.block.name,
    title: 'RTBH',
  },
  slack: {
    group: PluginGroups.notification.name,
    title: 'Slack',
  },
  sms: {
    group: PluginGroups.notification.name,
    title: 'SMS',
    disabled: true,
  },
  splunk: {
    group: PluginGroups.webhook.name,
    title: 'Splunk',
  },
  sumologic: {
    group: PluginGroups.notification.name,
    title: 'Sumo Logic',
  },
  syslog: {
    group: PluginGroups.notification.name,
    title: 'syslog',
  },
  twilio: {
    group: PluginGroups.notification.name,
    title: 'Twilio',
  },
  webhook: {
    group: PluginGroups.webhook.name,
    title: 'Webhook',
  },
};

Object.entries(ResponseIntegrations).forEach(([key, value]) => {
  value.name = key;
  value.type = PluginTypes.response;
  value.toString = () => value.name;
});
