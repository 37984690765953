/* eslint-disable react/prop-types */
import { useMemo } from 'react';

import PopperOrigin from '@mui/material/Popper';

const Popper = (props) => {
  const {
    anchorEl,
    children,
    style,
    disablePortal,
    popperStyle,
    ...tail
  } = props;

  const anchorRect = useMemo(
    () => anchorEl.getBoundingClientRect(),
    [anchorEl],
  );

  const fixedStyle = useMemo(
    () => ({
      zIndex: 9999,
      ...style,
      width: anchorRect.width,
      ...popperStyle,
    }),
    [anchorRect.width, style, popperStyle],
  );

  const modifiers = useMemo(
    () => ([{
      name: 'offset',
      options: {
        // We need this modifier for some cases when anchor and popper positions are different for few pixels to the right
        offset: ({ reference }) => ([Math.round(anchorRect.x) - Math.round(reference.x), 0]),
      },
    }]),
    [anchorRect.x],
  );

  return (
    <PopperOrigin
      {...tail}
      anchorEl={anchorEl}
      style={fixedStyle}
      modifiers={modifiers}
      disablePortal={disablePortal ?? true}
    >
      {children}
    </PopperOrigin>
  );
};

export default Popper;
