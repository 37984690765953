import { timeInterval } from 'd3-time';

export const durationSecond = 1e3;
export const duration10Seconds = durationSecond * 10;
export const duration30Seconds = durationSecond * 30;
export const durationMinute = 6e4;
export const duration5Minutes = 5 * durationMinute;
export const durationHour = 60 * durationMinute;
export const duration30Minutes = durationHour * 0.5;
export const duration12Hours = 12 * durationHour;
export const durationDay = 24 * durationHour;
export const durationWeek = durationDay * 7;
export const durationDays30 = durationDay * 30;
export const durationDays90 = durationDay * 90;
export const durationDays180 = durationDay * 180;
export const durationYear = durationDay * 365;

export const time10Seconds = timeInterval(
  (date) => date.setTime(Math.floor(date / duration10Seconds) * duration10Seconds),
  (date, step) => date.setTime(+date + step * duration10Seconds),
  (start, end) => (end - start) / duration10Seconds,
  (date) => date.getUTCSeconds(),
);

export const time30Seconds = timeInterval(
  (date) => date.setTime(Math.floor(date / duration30Seconds) * duration30Seconds),
  (date, step) => date.setTime(+date + step * duration30Seconds),
  (start, end) => (end - start) / duration30Seconds,
  (date) => date.getUTCSeconds(),
);

export const time5Minutes = timeInterval(
  (date) => {
    let offset = (date.getTimezoneOffset() * durationMinute) % duration5Minutes;
    if (offset < 0) {
      offset += duration30Minutes;
    }
    return date.setTime(Math.floor(date / duration5Minutes) * duration5Minutes);
  },
  (date, step) => date.setTime(+date + step * duration5Minutes),
  (start, end) => (end - start) / duration5Minutes,
  (date) => date.getUTCMinutes(),
);

export const time30Minutes = timeInterval(
  (date) => {
    let offset = (date.getTimezoneOffset() * durationMinute) % duration30Minutes;
    if (offset < 0) {
      offset += duration30Minutes;
    }
    return date.setTime(
      Math.floor(date / duration30Minutes) * duration30Minutes,
    );
  },
  (date, step) => date.setTime(+date + step * duration30Minutes),
  (start, end) => (end - start) / duration30Minutes,
  (date) => date.getUTCMinutes(),
);

export const time12Hours = timeInterval(
  (date) => {
    let offset = ((date.getTimezoneOffset() * durationMinute) % durationHour) * 12;
    if (offset < 0) {
      offset += duration12Hours;
    }
    return date.setTime(Math.floor(date / duration12Hours) * duration12Hours);
  },
  (date, step) => date.setTime(+date + step * duration12Hours),
  (start, end) => (end - start) / duration12Hours,
  (date) => date.getHours(),
);
