import { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions, selectors } from '@/redux/globalFilters';

/**
 * Hook to use global filter's property and callback to change it.
 *
 * @param {string} property of global filters
 * @param {*} [defaultValue] of the property
 * @return {Array} Array of `[filters[property], changePropertyValueFunction]`
 */
export const useGlobalFiltersProperty = (property, defaultValue) => {
  if (!property) {
    throw new Error('property is required');
  }

  const dispatch = useDispatch();
  const filters = useSelector(selectors.getFilters);
  const prop = useRef();
  prop.current = property;

  const change = useCallback(
    (filterPropValue) => {
      dispatch(actions.changeFilter({ [prop.current]: filterPropValue }));
    },
    [],
  );

  return [filters[property] ?? defaultValue, change];
};

export default useGlobalFiltersProperty;
