import styled from 'styled-components';

import { EventSeverity } from '@/models/EventSeverity';

export default styled(({ className, severity, label }) => (
  <div className={`${className} ${severity}`}>
    {label || EventSeverity[severity]?.title || severity}
  </div>
))`
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  height: 21px;
  max-width: fit-content;
  padding: 0 6px;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 600;

  &.info {
    color: black;
    background-color: #61bbd9;
  }

  &.low {
    color: black;
    background-color: #f6d874;
  }

  &.medium {
    color: black;
    background-color: #ff9f43;
  }

  &.high,
  &.critical {
    color: black;
    background-color: #fd4862;
  }

  &.unknown {
    color: black;
    background-color: #878787;
  }
`;
