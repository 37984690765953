import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useToggle } from 'react-use';

import isEmpty from 'lodash.isempty';

import RoutePaths from '@/models/RoutePaths';

import {
  actions as contextIntegrationsActions,
  selectors as contextIntegrationsSelectors,
} from '@/redux/api/integrations/context';

import { Breadcrumb } from '+components/Breadcrumb';
import ConfirmModal from '+components/ConfirmModal';
import EditPageAuditLogTabs from '+components/EditPageAuditLogTabs';
import { PluginLabel } from '+components/Labels/PluginLabel';
import usePageTabs from '+hooks/usePageTabs';
import useUIProperty from '+hooks/useUIProperty';

import cleanUpFields from '../shared/cleanUpFieldsBeforeSubmit';
import coerceBooleanTypes from '../shared/coerceBooleanTypes';
import NoData from '../shared/NoData';
import useContextIntegrationModels from '../shared/useContextIntegrationModels';
import Form from './Form';

const LabeledBreadCrumb = (label) => (
  <Breadcrumb title={`Edit Context Integration ${label}`} />
);

const transformFormValues = (integrationData, fields) => {
  // nesting this method because we'll have more value changing to do once the transform ui custom field is complete
  return coerceBooleanTypes(integrationData, fields);
};

const Edit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [, activePageTab] = usePageTabs();

  const { id } = useParams();
  const integration = useSelector(
    contextIntegrationsSelectors.getContextIntegration(id),
  );
  const error = useSelector(contextIntegrationsSelectors.getError);
  const isFetching = useSelector(contextIntegrationsSelectors.isFetching);

  const [showDelete, toggleShowDelete] = useToggle(false);
  const updatedIntegration = useSelector(
    contextIntegrationsSelectors.getContextIntegration(integration?.id),
  );
  const [updatingIntegration, setUpdatingIntegration] = useState(false);
  const [runningIntegration, setRunningIntegration] = useState(false);
  const [formValues, setFormValues] = useState({});

  const [contextIntegrationModels, isIntegrationTypeHardcoded] = useContextIntegrationModels();
  const areAllFetched = useSelector(contextIntegrationsSelectors.areAllFetched);

  useEffect(
    () => {
      if (!areAllFetched) {
        dispatch(contextIntegrationsActions.fetchContextIntegrations());
      }
    },
    [areAllFetched],
  );

  const model = useMemo(
    () => {
      return integration ? contextIntegrationModels?.[integration?.type] : {};
    },
    [integration?.type, contextIntegrationModels],
  );

  // true if integration is defined in portal code, false if it's a manifest integration.
  const isHardcodedIntegration = useMemo(
    () => isIntegrationTypeHardcoded(model?.name),
    [model?.name],
  );

  const initialValues = useMemo(
    () => {
      if (integration && model?.manifest?.fields) {
        return transformFormValues(integration, model.manifest.fields);
      }
      return integration ?? {};
    },
    [integration, model?.manifest?.fields],
  );

  const onSubmit = useCallback(
    (values) => {
      const data = cleanUpFields(values, model?.manifest);

      setUpdatingIntegration(activePageTab?.id);
      dispatch(
        contextIntegrationsActions.updateContextIntegration({
          ...data,
          isDynamic: !isHardcodedIntegration,
          silent: data.enabled && isHardcodedIntegration,
        }),
      );
    },
    [
      integration,
      id,
      isHardcodedIntegration,
      activePageTab?.id,
      model?.manifest,
    ],
  );

  const onCancel = useCallback(
    () => {
      navigate(`${RoutePaths.integrationsContext}`);
    },
    [],
  );

  const onDeleteConfirm = useCallback(
    () => {
      if (integration?.id) {
        dispatch(
          contextIntegrationsActions.removeContextIntegration({
            isDynamic: !isHardcodedIntegration,
            ...integration,
          }),
        );
        onCancel();
      }
    },
    [integration, isHardcodedIntegration, onCancel],
  );

  useEffect(
    () => {
      if (!updatingIntegration || isFetching) {
        return;
      }

      if (updatingIntegration !== activePageTab?.id) {
        return;
      }

      // if we're done updating the integration, then try to run it
      if (!runningIntegration && updatedIntegration?.id) {
        if (error) {
          setUpdatingIntegration(false);
          return;
        }
        // if the integration isn't enabled, or it's a dynamic integration, we don't need to run
        if (!isHardcodedIntegration || !updatedIntegration?.enabled) {
          onCancel();
          return;
        }

        setRunningIntegration(true);
        dispatch(
          contextIntegrationsActions.runContextIntegration({
            ...updatedIntegration,
            afterUpdateMode: true,
          }),
        );
        return;
      }

      // if we're done updating and running the integration
      if (runningIntegration && updatedIntegration?.id) {
        setUpdatingIntegration(false);
        setRunningIntegration(false);
        if (!error) {
          onCancel();
        }
      }
    },
    [
      updatingIntegration,
      runningIntegration,
      isFetching,
      updatedIntegration,
      error,
      activePageTab?.id,
      isHardcodedIntegration,
      onCancel,
    ],
  );

  useEffect(
    () => {
      if (integration || !id || isEmpty(model)) {
        return;
      }
      dispatch(
        contextIntegrationsActions.fetchContextIntegration({
          id,
          isDynamic: !isHardcodedIntegration,
        }),
      );
    },
    [integration, id, model],
  );

  const [, setMasqueradeUrl] = useUIProperty('masqueradeUrl');
  useEffect(
    () => {
      setMasqueradeUrl(`${RoutePaths.integrationsContext}`);
      return () => {
        setMasqueradeUrl(null);
      };
    },
    [],
  );

  return !model ? (
    <NoData>No Plugin Found</NoData>
  ) : (
    <EditPageAuditLogTabs
      auditNqlQuery={`class == integration && original_id == ${integration?.id}`}
      breadcrumb={LabeledBreadCrumb}
    >
      <Form
        mode="edit"
        model={model}
        initialValues={initialValues}
        isFetching={isFetching}
        // TODO: temporary, remove once manifest integration verification work is done see SQC-102
        confirmButtonText={
          formValues?.enabled && isHardcodedIntegration
            ? 'Update and Run'
            : 'Update'
        }
        deleteButtonText="Delete Integration"
        deleteButtonHidden={!integration?.id}
        onDelete={() => toggleShowDelete(true)}
        onSubmit={onSubmit}
        onCancel={onCancel}
        formValueChangeCallback={setFormValues}
      />

      {showDelete && (
        <ConfirmModal
          item={(
            <PluginLabel
              style={{ marginLeft: '5px' }}
              adapter={integration?.type}
              deleteName={integration?.name}
              title={model.title}
              size={14}
              logo={contextIntegrationModels[integration?.type]?.manifest?.logo}
            />
          )}
          onConfirm={onDeleteConfirm}
          toggleOnConfirm={false}
          isDisabled={isFetching}
          cancelButtonDisabled={isFetching}
          onToggle={toggleShowDelete}
          isOpen
        />
      )}
    </EditPageAuditLogTabs>
  );
};

export default Edit;
