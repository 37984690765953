import PropTypes from '+prop-types';
import { useMemo } from 'react';

import LeadPencilIcon from 'mdi-react/LeadPencilIcon';

import RoutePaths from '@/models/RoutePaths';

import { HeaderSubheader } from '+components/Table/Cells/HeaderSubheaderCell';

import CellWrapper from './CellWrapper';

const CellResponsePolicyName = (props) => {
  const { id, name, description, original, disabled } = props;

  const additionalMenuOptions = useMemo(
    () => (!id
      ? null
      : [
        {
          url: `${RoutePaths.responsePolicies}/${id}`,
          icon: <LeadPencilIcon />,
          text: 'Edit',
        },
      ]),
    [id],
  );

  return (
    <HeaderSubheader
      header={(
        <CellWrapper
          customer={original?.customer}
          field="rules.name"
          value={name}
          additionalMenuOptions={additionalMenuOptions}
          disabled={disabled}
        >
          <span>{name}</span>
        </CellWrapper>
      )}
      subHeader={description}
    />
  );
};

CellResponsePolicyName.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  original: PropTypes.shape(),
  disabled: PropTypes.bool,
};

CellResponsePolicyName.defaultProps = {
  description: null,
  original: null,
  disabled: false,
};

export default CellResponsePolicyName;
