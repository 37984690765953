export class Point {
  constructor({ lat, lng, land }) {
    this.latlng = [lat, lng];
    this._point = { x: 0, y: 0 };
    this._land = land;
  }

  get x() {
    return this._point.x;
  }

  get y() {
    return this._point.y;
  }

  get lat() {
    return this.latlng[0];
  }

  get lng() {
    return this.latlng[1];
  }

  get landCode() {
    return this._land;
  }

  updateCoords(map) {
    this._point = map.latLngToContainerPoint(this.latlng);
  }
}
