import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useResolvedPath } from 'react-router-dom';

import capitalize from 'lodash.capitalize';

import { PluginTypes } from '@/models/integrations/PluginTypes';
import PermissionModel from '@/models/Permission';
import RoutePaths from '@/models/RoutePaths';

import {
  actions as responseIntegrationsActions,
  selectors as responseIntegrationsSelectors,
} from '@/redux/api/integrations/response';

import { PluginIcon } from '@/shared/img/icon';

import { Breadcrumb } from '+components/Breadcrumb';
import Select from '+components/form/Select';
import { Row, Col } from '+components/Layout';
import PluginCard from '+components/PluginCard';
import usePermissions from '+hooks/usePermissions';

import { ResponseIntegrationModels } from '../shared/ResponseIntegrationModels';
import useContextIntegrationModels from '../shared/useContextIntegrationModels';

const allValue = 'all';

const sortByTitle = (a, b) => {
  if (a.title < b.title) {
    return -1;
  }
  if (a.title > b.title) {
    return 1;
  }

  return 0;
};

const AddIntegration = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname: parentPath } = useResolvedPath('..');

  const pluginType = parentPath.endsWith(
    RoutePaths.integrationsResponse.pageName,
  )
    ? PluginTypes.response
    : PluginTypes.context;
  const [pluginGroup, setPluginGroup] = useState(allValue);
  const availableResponseAdapters = useSelector(
    responseIntegrationsSelectors.getAdapters,
  );
  const permissions = usePermissions(
    PermissionModel.Resources.integration.value,
  );

  const [contextIntegrationModels] = useContextIntegrationModels();

  const availablePlugins = useMemo(
    () => {
      const models = pluginType === PluginTypes.context
        ? contextIntegrationModels
        : ResponseIntegrationModels;

      if (!models) {
        return [];
      }

      const available = Object.values(models).filter(
        (item) => item.type === pluginType && !item.disabled,
      );

      if (pluginType !== PluginTypes.response) {
        return available.sort(sortByTitle);
      }

      const availableResponseAdaptersHash = (
        availableResponseAdapters || []
      ).reduce(
        (acc, item) => ({ ...acc, [`${item.type}-${item.name}`]: true }),
        {},
      );

      return available.filter(
        (item) => availableResponseAdaptersHash[`${item.group}-${item.name}`],
      );
    },
    [pluginType, availableResponseAdapters, contextIntegrationModels],
  );

  const pluginGroupOptions = useMemo(
    () => Array.from(
      new Set([
        allValue,
        ...availablePlugins.map((item) => item.group).filter(Boolean),
      ]),
    )
      .sort()
      .map((value) => ({ value, label: capitalize(value) })),
    [availablePlugins],
  );

  const onAddClick = useCallback(
    (name) => () => navigate(name),
    [navigate],
  );

  useEffect(
    () => {
      if (
        pluginType !== PluginTypes.response
      || availableResponseAdapters?.length
      ) {
        return;
      }
      dispatch(responseIntegrationsActions.fetchAdapters());
    },
    [pluginType, availableResponseAdapters?.length],
  );

  const title = `Add ${capitalize(pluginType)} Integration`;

  return (
    <Fragment>
      <Breadcrumb title={title} />

      <Col gap="20px">
        {pluginGroupOptions.length > 1 && (
          <Row width="220px">
            <Select
              value={pluginGroup}
              options={pluginGroupOptions}
              onChange={({ value }) => setPluginGroup(value)}
            />
          </Row>
        )}

        <Row gap="10px">
          {availablePlugins.map(
            (item) => (pluginGroup === allValue || item.group === pluginGroup) && (
              <PluginCard
                key={item.name}
                group={item.group}
                name={item.title}
                icon={(
                  <PluginIcon
                    name={item.name}
                    size={40}
                    logo={item?.manifest?.logo}
                  />
                )}
                caption={`Add ${item.title} Integration`}
                disabled={!permissions?.create}
                onClick={onAddClick(item.name)}
                testId={`add-${item.name}-integration-button`}
              />
            ),
          )}
        </Row>
      </Col>
    </Fragment>
  );
};

export default AddIntegration;
