import LabelTypes from '@/models/LabelTypes';

export const isAsnField = (field) => [
  'srcas.number',
  'srcowneras.number',
  'dstas.number',
  'dstowneras.number',
].includes(field);

export const isOrgField = (field) => ['srcas.org', 'srcowneras.org', 'dstas.org', 'dstowneras.org'].includes(
  field,
);

export const isAsnWithOrgField = (field) => ['srcas', 'dstas', 'dstowneras', 'srcowneras'].includes(field);

export const isCountryCodeField = (field) => ['srcgeo.countrycode', 'dstgeo.countrycode'].includes(field);

export const isGeoField = (field) => ['srcgeo', 'dstgeo'].includes(field);

export const isIpField = (field) => [
  'srcip',
  'srcips',
  'flowsrcip',
  'dstip',
  'dstips',
  'nexthop',
  'ipinfo.ip',
  'ip',
  'ipintell',
  'addresslocal', // BGP table
  'addressremote', // BGP table
  'answers.rdata', // DNS table
].includes(field);

export const isLabelField = (field) => field.startsWith('label.')
  || [
    'dstname', // dstname - deprecated field
    'dstipname',
    'srcname', // srcname - deprecated field
    'srcipname',
    'ipinfo.ipname',
    // 'nexthopname',
    // 'flowsrcname',
    'dstportname',
    'srcportname',
    'dstportnames',
    'srcportnames',
    'addresslocalname', // BGP table
    'addressremotename', // BGP table
    'ipname',
  ].includes(field);

export const isPortField = (field) => ['srcport', 'srcports', 'dstport', 'dstports', 'port'].includes(field);

export const getFieldType = (field) => {
  if (isIpField(field)) {
    return LabelTypes.ip;
  }
  if (isPortField(field)) {
    return LabelTypes.port;
  }
  if (isAsnWithOrgField(field)) {
    return LabelTypes.asn;
  }
  if (isGeoField(field)) {
    return LabelTypes.geo;
  }
  return 'other';
};

export const getIpFieldType = (field) => {
  switch (field) {
    case 'srcip':
    case 'srcips':
      return 'srcip';
    case 'dstip':
    case 'dstips':
      return 'dstip';
    default:
      return null;
  }
};
