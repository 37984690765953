import { all } from 'redux-saga/effects';

import allowlistsSlice from './api/allowlists';
import apiKeysSlice from './api/apiKeys';
import auditlogsSlice from './api/auditLogs';
import bgpSlice from './api/bgp';
import blocksSlice from './api/blocks';
import customersSlice from './api/customer';
import customersSecuritySlice from './api/customer/security';
import cvesSlice from './api/cves';
import dashboards from './api/dashboards';
import devicesSlice from './api/device';
import dnsSlice from './api/dns';
import dnsClassSlice from './api/dnsclass';
import docsSlice from './api/docs';
import eventsSlice from './api/events';
import flowsSlice from './api/flows';
import guestSlice from './api/guest';
import contextIntegrationsSlice from './api/integrations/context';
import responseIntegrationsSlice from './api/integrations/response';
import ipintelSlice from './api/ipintel';
import ipLabelsSlice from './api/labels/ips';
import portLabelsSlice from './api/labels/ports';
import netclassSlice from './api/netclass';
import nqlCompleteSlice from './api/nql-complete';
import portalSettingsSlice from './api/portalSettings';
import rolesSlice from './api/roles';
import rulesSlice from './api/rules';
import searchSlice from './api/search';
import auditLogsStatsSlice from './api/stats/auditLogs';
import blocksStatsSlice from './api/stats/blocks';
import dnsStatsSlice from './api/stats/dns';
import eventsStatsSlice from './api/stats/events';
import flowStatsSlice from './api/stats/flow';
import ipStatsSlice from './api/stats/ip';
import tableSettings from './api/tableSettings';
import tagSlice from './api/tag';
import thresholderSlice from './api/thresholder';
import trafficTopSlice from './api/trafficTop';
import userSlice from './api/user/index';
import profileSlice from './api/user/profile';
import vpcsSlice from './api/vpc';
import globalFiltersSlice from './globalFilters';
import dateTimeStackSlice from './globalFilters/dateTimeStack';
import globalFiltersUISlice from './globalFilters/ui';
import socketSlice from './newsocket';
import toastSlice from './toast';
import ui from './ui';
import backTo from './ui/backTo';
import globalLoading from './ui/globalLoading';
import socketControlSlice from './ui/socketControl';
import wizardSlice from './ui/wizard';

// Put modules that have their reducers nested in other (root) reducers here
const nestedSlices = [];

// Put modules whose reducers you want in the root tree in this array.
const rootSlices = [
  eventsSlice,
  apiKeysSlice,
  backTo,
  bgpSlice,
  blocksSlice,
  customersSlice,
  customersSecuritySlice,
  cvesSlice,
  dateTimeStackSlice,
  dashboards,
  devicesSlice,
  flowsSlice,
  flowStatsSlice,
  dnsSlice,
  dnsStatsSlice,
  dnsClassSlice,
  docsSlice,
  guestSlice,
  eventsStatsSlice,
  auditLogsStatsSlice,
  blocksStatsSlice,
  globalFiltersSlice,
  globalFiltersUISlice,
  globalLoading,
  ipintelSlice,
  ipLabelsSlice,
  ipStatsSlice,
  portLabelsSlice,
  contextIntegrationsSlice,
  responseIntegrationsSlice,
  netclassSlice,
  trafficTopSlice,
  nqlCompleteSlice,
  portalSettingsSlice,
  rolesSlice,
  profileSlice,
  rulesSlice,
  searchSlice,
  socketControlSlice,
  socketSlice,
  tableSettings,
  tagSlice,
  toastSlice,
  userSlice,
  vpcsSlice,
  wizardSlice,
  allowlistsSlice,
  ui,
  auditlogsSlice,
  thresholderSlice,
];

const sagas = rootSlices
  .concat(nestedSlices)
  .map((slice) => slice.sagas)
  .filter(Boolean)
  .reduce((acc, arr) => acc.concat(arr));

export function* rootSaga() {
  yield all(sagas.map((saga) => saga()));
}

export const reducers = rootSlices.reduce(
  (acc, slice) => ({
    ...acc,
    [slice.name]: slice.reducer,
  }),
  {},
);
