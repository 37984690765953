import PropTypes from '+prop-types';
import { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  actions as dashboardsActions,
  selectors as dashboardsSelectors,
} from '@/redux/api/dashboards';

import { Field } from '+components/form/FinalForm';
import MultiSelectField from '+components/form/MultiSelectField';
import { normalizeMultiSelectValue } from '+components/form/Normalizers';
import FormModal from '+components/FormModal';

const FormBody = () => {
  const dispatch = useDispatch();

  const isAllMetaFetched = useSelector(dashboardsSelectors.isAllMetaFetched);
  const dashboardsMeta = useSelector(dashboardsSelectors.getDashboardsMeta);

  const groupsOptions = useMemo(
    () => {
      const groups = Object.values(dashboardsMeta || {}).flatMap(
        (item) => item.groups || [],
      );
      return [...new Set(groups)].sort().map((item) => ({
        value: item,
        label: item,
      }));
    },
    [dashboardsMeta],
  );

  useEffect(
    () => {
      if (!isAllMetaFetched) {
        dispatch(dashboardsActions.fetchDashboardsMeta());
      }
    },
    [isAllMetaFetched],
  );

  return (
    <Field
      name="groups"
      label="Collection"
      component={MultiSelectField}
      options={groupsOptions}
      parse={normalizeMultiSelectValue}
      allowCreate
    />
  );
};

const DashboardGroupsManageForm = (props) => {
  const { initialValues, isOpen, onConfirm, onToggle } = props;

  return (
    <FormModal
      mode="edit"
      titleTemplate={`Manage ${initialValues.title} Dashboard Groups`}
      confirmButtonText="Update"
      initialValues={initialValues}
      isOpen={isOpen}
      onSubmit={onConfirm}
      onToggle={onToggle}
      focusOnFields={false}
      labelOnTop
    >
      <FormBody />
    </FormModal>
  );
};

DashboardGroupsManageForm.propTypes = {
  initialValues: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default DashboardGroupsManageForm;
