export const WidgetTypes = {
  Area: 'h-area',
  AttackSurface: 'neto-attack-surface',
  AuditLogTable: 'neto-audit-log-table',
  Bar: 'h-bar',
  BarChartRace: 'h-bar-race',
  BlockTable: 'neto-block-table',
  BlockMap: 'neto-block-map',
  EventMap: 'neto-alert-map',
  EventTable: 'neto-alert-table',
  FlowMap: 'neto-flow-map',
  FlowTable: 'neto-flow-table',
  DnsTable: 'neto-dns-table',
  ForceDirected: 'neto-force-directed',
  Heatmap: 'h-heatmap',
  Gauge: 'h-gauge',
  Line: 'h-line',
  Markdown: 'neto-markdown',
  Multiple: 'h-multi',
  Pie: 'h-pie',
  Sankey: 'h-sankey',
  ScatterPlot: 'h-scatter-plot',
  SingleStackedBar: 'h-ssd-bar',
  Sparkline: 'h-sparkline',
  Spiral: 'neto-spiral',
  StackedBar: 'h-stacked-bar',
  Table: 'neto-table',
  Value: 'h-value',
};
