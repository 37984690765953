const MS_PER_MINUTE = 60000;
const MAX_BARS = 60;

const getStackedBarInterval = (periodValue, periodType) => {
  const numberOfBarsAt1mInterval = periodValue * (periodType / MS_PER_MINUTE);
  if (numberOfBarsAt1mInterval > MAX_BARS) {
    const idealIntervalMinutes = Math.ceil(numberOfBarsAt1mInterval / MAX_BARS);
    return `${idealIntervalMinutes}m`;
  }
  return '1m';
};

export default getStackedBarInterval;
