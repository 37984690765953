import styled from 'styled-components';

const TooltipContent = styled.div`
  display: flex;
  align-items: center;
  .mdi-icon + span {
    margin-left: 4px;
  }
`;

export default TooltipContent;
