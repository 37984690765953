import styled from 'styled-components';

export default styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 240px;
  height: 100%;
  margin-left: 10px;
  padding: 20px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.noDataPageTextBackground};
  transition: visibility 0.3s;

  :before {
    content: "";
    position: absolute;
    top: 20px;
    right: 10px;
    border-color: transparent transparent ${({ theme }) => theme.colorText} transparent;
    border-style: solid;
    border-width: 0 15px 15px 15px;
    transform: rotate(45deg);
  }
  
  div + div {
    margin-top: 15px;
  }
`;
