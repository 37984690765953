import PropTypes from '+prop-types';

import GenericLabel from './GenericLabel';

const ContextLabel = ({ children, ...tail }) => (
  <GenericLabel {...tail} context={children} />
);

ContextLabel.propTypes = {
  children: PropTypes.children,
};

ContextLabel.defaultProps = {
  children: null,
};

export default ContextLabel;
