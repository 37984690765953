import PropTypes from '+prop-types';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useDrag, useDragLayer, useDrop } from 'react-dnd';

import classNames from 'classnames';
import omit from 'lodash.omit';

import ArrowLeftBoldIcon from 'mdi-react/ArrowLeftBoldIcon';
import ArrowRightBoldIcon from 'mdi-react/ArrowRightBoldIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import ContentCopyIcon from 'mdi-react/ContentCopyIcon';
import PinIcon from 'mdi-react/PinIcon';
import PinOffIcon from 'mdi-react/PinOffIcon';

import {
  Menu,
  Item as MenuItem,
  Separator as MenuSeparator,
  useMenuActions,
  useMenuState,
  withMenu,
} from '+components/Menu';
import Tooltip from '+components/Tooltip';

import CloseButton from './components/CloseButton';
import Container from './components/Container';
import Title from './components/Title';
import UnpinButton from './components/UnpinButton';

const isNewTimeoutMs = 1000;
const getIsNew = (created) => Date.now() - created < isNewTimeoutMs;

const omitProps = ['pathname'];

const PageHeader = (props) => {
  const {
    className,
    id,
    index,
    title,
    active,
    pinned,
    created,
    onClick,
    onClose,
    onCloseOthers,
    onCloseAll,
    onCloseToLeft,
    onCloseToRight,
    onMoveLeft,
    onMoveRight,
    onMove,
    onCopy,
    onPin,
    onUnpin,
    ...otherProps
  } = omit(props, omitProps);

  const contextMenuCloseActions = [
    onClose,
    onCloseOthers,
    onCloseAll,
    onCloseToLeft,
    onCloseToRight,
  ].filter(Boolean);
  const contextMenuMoveActions = [onMoveLeft, onMoveRight].filter(Boolean);
  const contextMenuAdditionalActions = [onCopy, onPin, onUnpin].filter(Boolean);
  const isContextMenuAvailable = !!contextMenuCloseActions.length
    || !!contextMenuMoveActions.length
    || !!contextMenuAdditionalActions.length;
  const menuState = useMenuState();
  const menuActions = useMenuActions();

  const ref = useRef(null);

  const [initialActive] = useState(active);
  const [isNew, setIsNew] = useState(initialActive && getIsNew(created));
  const isNewTimer = useRef(null);
  useEffect(
    () => {
      if (!initialActive || !getIsNew(created)) {
        return undefined;
      }
      setIsNew(true);
      isNewTimer.current = setTimeout(() => {
        setIsNew(false);
      }, isNewTimeoutMs);
      return () => {
        clearTimeout(isNewTimer.current);
      };
    },
    [created, initialActive],
  );

  const dragItemType = `page-tab${pinned ? '-pinned' : ''}`;

  const isNavDragging = useDragLayer(
    (monitor) => monitor.isDragging() && monitor.getItemType() === dragItemType,
  );

  const [{ isDragging: isItemDragging }, drag] = useDrag({
    type: dragItemType,
    item: () => ({ id, index }),
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const [{ handlerId }, drop] = useDrop({
    accept: dragItemType,
    collect: (monitor) => ({
      handlerId: monitor.getHandlerId(),
    }),
    hover: (item) => {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      onMove?.(item.index, index);
      item.index = hoverIndex;
    },
  });

  drag(drop(ref));

  const onContextMenu = useCallback(
    (e) => {
      if (!isContextMenuAvailable) {
        return;
      }
      menuActions?.showMenu?.(e);
    },
    [isContextMenuAvailable, menuActions?.showMenu],
  );

  return (
    <Fragment>
      {isContextMenuAvailable && (
        <Menu>
          {onClose && (
            <MenuItem onClick={onClose}>
              <CloseIcon size={16} />
              <span>Close</span>
            </MenuItem>
          )}
          {onCloseOthers && (
            <MenuItem onClick={onCloseOthers}>
              <CloseIcon size={16} />
              <span>Close other tabs</span>
            </MenuItem>
          )}
          {onCloseAll && (
            <MenuItem onClick={onCloseAll}>
              <CloseIcon size={16} />
              <span>Close all tabs</span>
            </MenuItem>
          )}
          {onCloseToLeft && (
            <MenuItem onClick={onCloseToLeft}>
              <CloseIcon size={16} />
              <span>Close tabs to the left</span>
            </MenuItem>
          )}
          {onCloseToRight && (
            <MenuItem onClick={onCloseToRight}>
              <CloseIcon size={16} />
              <span>Close tabs to the right</span>
            </MenuItem>
          )}

          {!!contextMenuCloseActions.length
            && !!contextMenuMoveActions.length && <MenuSeparator />}

          {onMoveLeft && (
            <MenuItem onClick={onMoveLeft}>
              <ArrowLeftBoldIcon size={16} />
              <span>Move left</span>
            </MenuItem>
          )}
          {onMoveRight && (
            <MenuItem onClick={onMoveRight}>
              <ArrowRightBoldIcon size={16} />
              <span>Move right</span>
            </MenuItem>
          )}

          {(!!contextMenuCloseActions.length
            || !!contextMenuMoveActions.length)
            && !!contextMenuAdditionalActions.length && <MenuSeparator />}

          {onCopy && (
            <MenuItem onClick={onCopy}>
              <ContentCopyIcon size={16} />
              <span>Duplicate</span>
            </MenuItem>
          )}
          {onPin && (
            <MenuItem onClick={onPin}>
              <PinIcon size={16} />
              <span>Pin</span>
            </MenuItem>
          )}
          {onUnpin && (
            <MenuItem onClick={onUnpin}>
              <PinOffIcon size={16} />
              <span>Unpin</span>
            </MenuItem>
          )}
        </Menu>
      )}

      <Tooltip
        title={title || ''}
        enterDelay={2000}
        enterNextDelay={2000}
        arrow={false}
      >
        <Container
          {...otherProps}
          className={classNames(className, {
            active,
            clickable: !!onClick,
            isItemDragging,
            isNavDragging,
            isContextMenuOpen: menuState?.show,
            isNew,
          })}
          ref={onMove ? ref : undefined}
          data-handler-id={handlerId}
          onClick={onClick}
          onContextMenu={onContextMenu}
        >
          {title && <Title>{title}</Title>}

          {onUnpin && (
            <UnpinButton
              size="small"
              title="Unpin Tab"
              onClick={onUnpin}
              data-tracking="page-tab-unpin-button"
            >
              <PinIcon className="pin-icon" size={16} />
              <PinOffIcon className="unpin-icon" size={16} />
            </UnpinButton>
          )}

          {!isNavDragging && !onUnpin && onClose && (
            <CloseButton
              size="small"
              title="Close Tab"
              onClick={onClose}
              data-tracking="page-tab-close-button"
            >
              <CloseIcon size={16} />
            </CloseButton>
          )}
        </Container>
      </Tooltip>
    </Fragment>
  );
};

PageHeader.propTypes = {
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  active: PropTypes.bool,
  pinned: PropTypes.bool,
  onClose: PropTypes.func,
  onCloseOthers: PropTypes.func,
  onCloseAll: PropTypes.func,
  onCloseToLeft: PropTypes.func,
  onCloseToRight: PropTypes.func,
  onMoveLeft: PropTypes.func,
  onMoveRight: PropTypes.func,
  onMove: PropTypes.func,
  onCopy: PropTypes.func,
  onPin: PropTypes.func,
  onUnpin: PropTypes.func,
};

PageHeader.defaultProps = {
  className: '',
  title: '',
  active: false,
  pinned: false,
  onClose: null,
  onCloseOthers: null,
  onCloseAll: null,
  onCloseToLeft: null,
  onCloseToRight: null,
  onMoveLeft: null,
  onMoveRight: null,
  onMove: null,
  onCopy: null,
  onPin: null,
  onUnpin: null,
};

export default withMenu(PageHeader);
