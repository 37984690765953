import { scaleLog } from 'd3-scale';

import { CanvasLayer } from '../CanvasLayer';
import { Splash } from './effects/Splash';

export class RenderLayer extends CanvasLayer {
  constructor(map, { render } = {}) {
    super(map, { render });

    this._initDispatcher('completed');

    this._scale = scaleLog();
    this._scaleLineWidth = scaleLog();

    this._scale.range([20, 20]).domain([624, 277056]);

    this._scaleLineWidth.range([1, 1]).domain([624, 277056]);

    this._data = [];
  }

  _needRedraw() {
    return this._data && this._data.length;
  }

  _doDraw() {
    if (!this._data) {
      return;
    }

    requestAnimationFrame(this._draw);

    const { ctx } = this._canvas;
    ctx.globalCompositeOperation = 'darker';

    ctx.save();

    let item;
    let l = this._data.length;

    // eslint-disable-next-line no-plusplus
    while (l--) {
      item = this._data[l];

      item.draw(ctx);

      if (item.isDead) {
        this._data.splice(l, 1);
        this._sendEvent('completed', item.data);
      }
    }

    ctx.restore();
  }

  _doDestroy() {
    this._data = null;
    delete this._data;
  }

  _makeNode(show, style, point) {
    const hasPoint = !!point;

    if (hasPoint) {
      point.updateCoords(this._map);
    }

    return {
      show,
      style,
      point,
    };
  }

  updateData(data) {
    if (this._destroyed) {
      return this._data;
    }

    this._data.push(
      ...data
        .filter((event) => event.options.showOnMap)
        .map((event) => {
          const value = +event.data.bits || 0;
          this._scale.domain([
            Math.min(this._scale.domain()[0] || value, value),
            Math.max(this._scale.domain()[1] || value, value),
          ]);
          this._scaleLineWidth.domain(this._scale.domain());

          const source = this._makeNode(
            event.options.showSourcePoint,
            {
              ...event.style.sourcePoint,
              radius: this._scale(value),
              waveLength: this._scale(value),
              lineWidth: this._scaleLineWidth(value),
              speed: this._scale(value) * 0.01,
            },
            event.source.point,
          );

          const target = this._makeNode(
            event.options.showTargetPoint,
            {
              ...event.style.targetPoint,
              radius: this._scale(value),
              waveLength: this._scale(value),
              lineWidth: this._scaleLineWidth(value),
              speed: this._scale(value) * 0.0055,
            },
            event.target.point,
          );

          const path = {};
          path.show = event.options.showPath;
          path.style = {
            ...event.style.path,
            lineWidth: this._scaleLineWidth(value),
          };

          const splash = new Splash({
            source,
            target,
            path,
            value,
          });

          splash.data = event;
          return splash;
        }),
    );

    return this._data;
  }

  updatePointsCoords() {
    if (!this._data) {
      return;
    }

    this._data.forEach((item) => {
      item.sourcePoint.point.updateCoords(this._map);
      item.targetPoint.point.updateCoords(this._map);
    });
  }

  reset() {
    this._data = [];
  }
}
