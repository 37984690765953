import styled from 'styled-components';

import Item from './Item';

export default styled(Item)`
  cursor: default;
  .topbar__link-title {
    display: flex;
    justify-content: center;
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
    color: ${({ theme }) => theme.topbarMenuTextColorSecondary} !important;
    width: 100%;
  }
`;
