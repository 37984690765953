import PropTypes from '+prop-types';

import styled from 'styled-components';

const FieldsString = styled.span`
  flex: 1 1 0;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Container = styled.div`
  display: flex;
`;

const Aggregates = styled.div`
  font-size: 10px;
  line-height: 18px;
  display: inline-block;
  margin-left: 2px;
  margin-right: 5px;

  height: 18px;
  width: 52px;

  text-align: center;
  color: #e7e2e2;
  border: 1px solid #e7e2e2;
`;

const LabelTrack = ({ aggregate = '', fields, ...tail }) => (
  <Container {...tail}>
    {Boolean(aggregate?.length) && <Aggregates>{aggregate}</Aggregates>}
    <FieldsString>{fields?.join(', ')}</FieldsString>
  </Container>
);

LabelTrack.propTypes = {
  aggregate: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.string).isRequired,
};

LabelTrack.defaultProps = {
  aggregate: null,
};

export default LabelTrack;
