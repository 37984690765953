import { call, put } from 'redux-saga/effects';

import { actions as toastActions } from '@/redux/toast';
import {
  createSlice,
  createSelector,
  startFetching,
  stopFetching,
  defaultReducers,
} from '@/redux/util';

import backendClient from '@/middleware/backendClient';

const initialState = {
  isFetching: false,
  isFetchingRow: {},
  error: '',
  collection: {},
};

let api;

const initApi = () => {
  if (!api) {
    api = backendClient();
  }
};

const slice = createSlice({
  name: 'alerts',
  initialState,

  reducers: {
    ...defaultReducers,
    fetch: (state, { payload: { id } }) => {
      startFetching(state);
      state.isFetchingRow[id] = true;
    },
    fetchEventSuccess(state, { payload: data }) {
      stopFetching(state);
      state.isFetchingRow[data.id] = false;
      state.collection[data.id] = data;
    },
    fail(state, { payload: { id, error } }) {
      defaultReducers.fail(state, { payload: error });
      state.isFetchingRow[id] = false;
    },
  },

  sagas: (actions) => ({
    [actions.fetch]: {
      * saga({ payload: { silent = true, id, customer } }) {
        initApi();

        try {
          let url = `/fetch/alert/${id}`;
          if (customer) {
            url += `?customer=${customer}`;
          }
          const response = yield call(api.get, url);
          yield put(actions.fetchEventSuccess(response.data.data[0]));
        } catch (error) {
          yield put(actions.fail({ id, error }));
          if (!silent) {
            yield put(
              toastActions.error({
                message: 'Error retrieving the alert',
                details: error.message,
              }),
            );
          }
        }
      },
    },
  }),

  selectors: (getState) => ({
    isFetching: createSelector(
      [getState],
      (state) => state.isFetching,
    ),

    isFetchingRecord: (id) => createSelector(
      [getState],
      (state) => state.isFetchingRow[id],
    ),

    getRecord: (id) => createSelector(
      [getState],
      (state) => state.collection[id],
    ),
  }),
});

export const { actions, selectors } = slice;

export default slice;
