import PropTypes from '+prop-types';

import { Col, LayoutSizes, Row } from '+components/Layout';

import TransformTextField from '../TransformTextField';
import FieldLabel from './FieldLabel';
import MethodSelector from './MethodSelector';

const LowerRowCustom = (props) => {
  const { disabled, data, onChange } = props;
  return (
    <Col gap="6px">
      <Row gap={LayoutSizes.groupGap}>
        <Col item xs={2.5}>
          <FieldLabel $disabled={disabled}>Method</FieldLabel>
        </Col>
        <Col item xs={7.5}>
          <FieldLabel $disabled={disabled}>Custom Function Name</FieldLabel>
        </Col>
      </Row>

      <Row gap={LayoutSizes.groupGap}>
        <Col item xs={2.5}>
          <MethodSelector {...props} />
        </Col>
        <Col item xs={7.5}>
          <TransformTextField
            disabled={disabled}
            defaultValue={data.function || ''}
            onChange={(_, value) => onChange('function', { ...data, function: value })}
          />
        </Col>
      </Row>
    </Col>
  );
};

LowerRowCustom.propTypes = {
  disabled: PropTypes.bool.isRequired,
  data: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
};

export default LowerRowCustom;
