import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  width: ${(props) => (props.$isOpen ? props.$width : 0)}px;
  background-color: ${({ theme }) => theme.colorBackground};
  transition: width 0.3s ease-in-out 0.1s;
`;
