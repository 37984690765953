import PropTypes from '+prop-types';
import { Fragment, useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import styled from 'styled-components';

import SettingCategories from '@/models/SettingCategories';

import { selectors as customerSelectors } from '@/redux/api/customer';
import { selectors as profileSelectors } from '@/redux/api/user/profile';
import { selectors as globalFiltersUiSelectors } from '@/redux/globalFilters/ui';

import GlobalFiltersPanel from '+components/GlobalFilters/Panel';
import useCustomerSubscriptionTypes from '+hooks/useCustomerSubscriptionTypes';
import usePortalSettingsValue from '+hooks/usePortalSettingsValue';
import useUIProperty from '+hooks/useUIProperty';

import Banner from './Banner';
import Body from './Body';
import GlobalContextMenu from './GlobalContextMenu';
import PropertiesTray from './PropertiesTray';
import ScrollBar from './ScrollBar';
import { separatorWidth, sidebarRightWidth } from './shared/constants';
import SidebarLeft from './SidebarLeft';
import SidebarRight from './SidebarRight';
import Topbar from './Topbar';
import TtouModal from './TtouModal';

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colorBackgroundBody};
`;

const MasqueradingIndicator = styled.div`
  flex-shrink: 0;
  width: 100%;
  height: 2px;
  background-color: ${({ theme }) => theme.Palette.danger};
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const ModalRoot = styled.div.attrs({ id: 'modal-root' })`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 8888;
  visibility: hidden;
`;

const Layout = ({ children }) => {
  const location = useLocation();

  const [hideNav] = useUIProperty('hideNav');
  const [isUnderCovered] = useUIProperty('underCover');
  const [isImpersonating] = useUIProperty('impersonating');
  const [version] = useUIProperty('version', '');
  const [expectedVersion] = useUIProperty('expectedVersion');
  const [showNewReleaseBanner, setShowNewReleaseBanner] = useUIProperty(
    'showNewReleaseBanner',
    false,
  );

  const [sideBarLeftCollapse, setSideBarLeftCollapse] = usePortalSettingsValue(
    SettingCategories.ui,
    'sideBarLeftCollapse',
  );
  const [sideBarLeftCollapseLocal, setSideBarLeftCollapseLocal] = useUIProperty(
    'sideBarLeftCollapse',
    sideBarLeftCollapse ?? false,
  );
  const [propertiesTray] = useUIProperty('propertiesTray', null);

  const customerSubscriptionTypes = useCustomerSubscriptionTypes();
  const customer = useSelector(customerSelectors.getCurrentCustomer);
  const isAdmin = useSelector(profileSelectors.isAdminUser);
  const isGlobalFilterAvailable = useSelector(
    globalFiltersUiSelectors.getAvailable,
  );

  const bodyScrollRef = useRef();

  const customerSubscriptionTypeMeta = customerSubscriptionTypes[customer?.subscriptionType];
  const showTtouModal = customerSubscriptionTypeMeta?.trial
    && customer.accepted_ttou == null
    && isAdmin
    && !(isUnderCovered || isImpersonating);

  const onPageReload = useCallback(
    () => {
      window.location.reload();
    },
    [],
  );

  const onToggleLeftSidebar = useCallback(
    () => {
      setSideBarLeftCollapseLocal((prev) => !prev);
      setSideBarLeftCollapse(!(sideBarLeftCollapse ?? false));
    },
    [sideBarLeftCollapse],
  );

  const showBanner = !!expectedVersion && version < expectedVersion;
  useEffect(
    () => setShowNewReleaseBanner(showBanner),
    [showBanner],
  );

  useEffect(
    () => setSideBarLeftCollapseLocal(sideBarLeftCollapse ?? false),
    [sideBarLeftCollapse],
  );

  useEffect(
    () => {
      if (hideNav) {
        setSideBarLeftCollapseLocal(true);
        setSideBarLeftCollapse(true);
      }
    },
    [hideNav],
  );

  useEffect(
    () => {
      const scrollElement = bodyScrollRef.current?.getScrollElement();
      scrollElement?.scrollTo?.({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    },
    [location.pathname],
  );

  return (
    <LayoutContainer>
      <Banner $isOpen={showNewReleaseBanner}>
        {showNewReleaseBanner && (
          <Fragment>
            A new version is available.{' '}
            <button type="button" onClick={onPageReload}>
              Update now
            </button>
          </Fragment>
        )}
      </Banner>

      {(isUnderCovered || isImpersonating) && <MasqueradingIndicator />}

      <Topbar
        collapse={sideBarLeftCollapseLocal}
        $separatorWidth={separatorWidth}
      />

      <ContentContainer>
        <SidebarLeft
          collapse={sideBarLeftCollapseLocal}
          onToggle={onToggleLeftSidebar}
          $separatorWidth={separatorWidth}
        />

        <PageContainer>
          {isGlobalFilterAvailable && (
            <GlobalFiltersPanel $separatorWidth={separatorWidth} />
          )}

          <ScrollBar ref={bodyScrollRef} $wide>
            <Body>{children}</Body>
          </ScrollBar>
        </PageContainer>

        {!hideNav && (
          <SidebarRight
            $width={sidebarRightWidth}
            isOpen={propertiesTray?.isOpen}
          >
            {propertiesTray?.isOpen && (
              <PropertiesTray
                $width={sidebarRightWidth}
                $separatorWidth={separatorWidth}
              />
            )}
          </SidebarRight>
        )}
      </ContentContainer>

      <ModalRoot />

      <GlobalContextMenu />

      {showTtouModal && <TtouModal isOpen />}
    </LayoutContainer>
  );
};

Layout.propTypes = {
  children: PropTypes.children,
};

Layout.defaultProps = {
  children: null,
};

export { TtouModal };

export default Layout;
