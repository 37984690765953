import { color as d3color } from 'd3-color';
import { scaleLog } from 'd3-scale';
import L from 'leaflet';
import * as THREE from 'three';

import { Point } from '../../models/Point';
import dataBus from '../../services/dataBus';
import { CanvasLayer } from '../CanvasLayer';
import factoryEditMode from './editMode';
import pathsMap from './paths.geoJson.json';

const initEditMode = factoryEditMode(false);

/**
 * Generate a neon ball
 * @param {Number} w width
 * @param {Number} h height
 * @param color
 * @param a
 * @returns {HTMLCanvasElement}
 */
const generateNeonBall = (w, h, color, a = 1) => {
  const tempCanvas = document.createElement('canvas');

  tempCanvas.width = w;
  tempCanvas.height = h;

  const imgCtx = tempCanvas.getContext('2d');
  const gradient = imgCtx.createRadialGradient(
    w / 2,
    h / 2,
    0,
    w / 2,
    h / 2,
    w / 2,
  );

  const { r, g, b } = d3color(color);

  gradient.addColorStop(0, `rgba(255,255,255,${a})`);
  gradient.addColorStop(0.3, `rgba(${[r, g, b, a * 0.5]})`);
  gradient.addColorStop(1, `rgba(${[r, g, b, 0]})`);

  // gradient.addColorStop(0.1, rgb.brighter().brighter());
  // gradient.addColorStop(0.3, rgb);
  // gradient.addColorStop(0.6, 'rgba(0, 0, 0, 0)');

  imgCtx.fillStyle = gradient;
  imgCtx.fillRect(0, 0, w, h);

  return tempCanvas;
};

// fix bug
L.Polygon.prototype._updatePath = function () {
  this._renderer._updatePoly(this);
};

const hashGradient = {};
const getGradient = (ctx, color, w, h) => {
  const key = (color || '').toString();
  if (key in hashGradient) {
    return hashGradient[key];
  }
  const grad = generateNeonBall(w, h, color, 0.5);

  hashGradient[key] = grad;

  return grad;
};

export class SchemeLayer extends CanvasLayer {
  constructor(map, { board } = {}) {
    const { render, extents, range, style = {} } = board || {};
    super(map, { render });
    this._data = [];

    const renderer = L.canvas();
    L.geoJSON(pathsMap, {
      renderer,

      style: {
        noClip: true,
        weight: 1,
        fillOpacity: 0,
        color: 'rgba(255, 222, 216, 0.3)',
        opacity: 0.2,
        ...style,
      },
    }).addTo(map);
    renderer._container.innerText = 'This browser does not support Canvas';

    this._points = pathsMap.features.reduce((acc, feature) => {
      const {
        properties: { group: key },
        geometry: { coordinates: [points] },
      } = feature;

      const land = acc[key] || {
        key,
        paths: [],
        paths3: [],
      };

      land.paths.push(
        points.map(([lng, lat]) => new Point({ lat, lng })).reverse(),
      );

      acc[key] = land;

      return acc;
    }, {});

    this.updatePointsCoords();

    this._drawEditMode = null;
    if (initEditMode) {
      initEditMode.call(this, map);
    }

    this._scale = scaleLog().range(Array.isArray(range) ? range : [8, 64]);

    if (Array.isArray(extents)) {
      this._scale.domain(extents);
    }

    const listenerId = performance.now();
    dataBus.on(listenerId, ({ type, data }) => {
      switch (type) {
        case 'drawPrefix':
          this._append(data);
          break;
        default:
          break;
      }
    });
  }

  _append(data) {
    if (this._destroyed || !data.point) {
      return;
    }

    const land = data.point.landCode === 'OC' ? 'AU' : data.point.landCode;

    const { paths3, key } = this._points[land] || this._points.AF;

    const dot = new THREE.Vector2(0, data.point.y);

    let lens = key === 'EU'
      ? paths3
      : paths3
        .map((item, i) => {
          const test = item.getPoint(0).clone().setX(0);
          return {
            l: test.distanceTo(dot),
            i,
          };
        })
        .sort(({ l: a }, { l: b }) => a - b);

    let path = Math.trunc(Math.random() * lens.length);

    if (key !== 'EU') {
      const len = lens.length;
      let m = Math.trunc(len * 0.5);
      m = m * 2 === len ? (lens[m].l + lens[m + 1].l) * 0.5 : lens[m].l;

      lens = lens.filter(({ l }) => l <= m);

      path = Math.trunc(Math.random() * lens.length);
      path = lens[path].i;
    }

    this._scale.domain([
      Math.min(this._scale.domain()[0] || data.value, data.value),
      Math.max(this._scale.domain()[1] || data.value, data.value),
    ]);

    if (path > -1) {
      this._data.push({
        ...data,
        path: {
          key,
          index: path,
        },
      });
    }
  }

  _drawPrefix(ctx, data) {
    const {
      path: { key, index },
      color,
      point: origin,
      value = 0,
    } = data;
    let { position = 0, end } = data;

    if (position > 1) {
      if (end) {
        data.isDead = true;
        return;
      }
      end = true;
      data.end = end;
      position = 0;
    }

    const path = this._points[key].paths3[index];

    position += end ? 0.02 : 0.006;

    let point = path.getPoint(end ? 1 : Math.min(position, 1));
    if (end) {
      point = point
        .clone()
        .lerp(new THREE.Vector2(origin.x, origin.y), position);
    }

    ctx.save();

    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';

    ctx.beginPath();

    const size = this._scale(value);
    const maxSize = Math.max(...this._scale.range());
    const r = size * 0.5;

    const img = getGradient(ctx, color, maxSize, maxSize);
    ctx.drawImage(img, point.x - r, point.y - r, size, size);
    ctx.restore();

    data.position = position;
  }

  _needRedraw() {
    return this._data && this._data.length;
  }

  _doDraw() {
    if (!this._data) {
      return;
    }

    if (!this._drawEditMode) {
      requestAnimationFrame(this._draw);
    }

    const { ctx, width, height } = this._canvas;

    if (this._drawEditMode) {
      ctx.clearRect(0, 0, width, height);
      ctx.globalCompositeOperation = 'source-over';
    }

    ctx.save();

    if (this._drawEditMode) {
      this._drawEditMode(ctx);
    }

    let l = this._data.length;

    // eslint-disable-next-line no-plusplus
    while (l--) {
      this._drawPrefix(ctx, this._data[l]);

      if (this._data[l].isDead) {
        dataBus.emit({
          type: 'prefixDone',
          data: {
            ...this._data[l],
          },
        });
        this._data.splice(l, 1);
      }
    }

    ctx.restore();
  }

  _doDestroy() {
    this._points = null;
    delete this._points;
    this._data = null;
    delete this._data;
  }

  updatePointsCoords() {
    if (this._destroyed) {
      return;
    }

    Object.values(this._points).forEach(({ paths, paths3 }) => {
      paths3.splice(0);
      paths.forEach((path) => {
        path.forEach((point) => point.updateCoords(this._map));
        paths3.push(new THREE.Path(path));
      });
    });
  }

  reset() {
    this._data = [];
  }
}
