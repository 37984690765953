import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useFlag } from '@unleash/proxy-client-react';

import FeatureFlags from '@/models/FeatureFlags';
import SettingCategories from '@/models/SettingCategories';

import { selectors as customerSelectors } from '@/redux/api/customer';
import {
  actions as dashboardsActions,
  selectors as dashboardsSelectors,
} from '@/redux/api/dashboards';
import {
  selectors as deviceSelectors,
  actions as deviceActions,
} from '@/redux/api/device';
import { selectors as profileSelectors } from '@/redux/api/user/profile';
import {
  actions as vpcActions,
  selectors as vpcSelectors,
} from '@/redux/api/vpc';
import {
  selectors as wizardSelectors,
  actions as wizardActions,
} from '@/redux/ui/wizard';

import Dashboard from '@/pages/Dashboards/Dashboard';
import DashboardModeTypes from '@/pages/Dashboards/shared/DashboardModeTypes';

import Wizard from '+components/Wizard';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import usePortalSettingsValue from '+hooks/usePortalSettingsValue';

const HomePage = () => {
  const dispatch = useDispatch();

  const isRolesUiSettingsEnabled = useFlag(FeatureFlags.rolesUiSettings);

  const showWizard = useSelector(wizardSelectors.getShow);
  const wizardDismissed = useSelector(wizardSelectors.getDismissed);

  const isCustomerFetching = useSelector(customerSelectors.isFetching);
  const customer = useSelector(customerSelectors.getCurrentCustomer);

  const profile = useSelector(profileSelectors.getProfile);
  const isAllMetaFetched = useSelector(dashboardsSelectors.isAllMetaFetched);
  const dashboardsMeta = useSelector(dashboardsSelectors.getDashboardsMeta);

  const isDashboardsFetching = useSelector(dashboardsSelectors.isFetching);
  const [companyHomepageDashboard] = usePortalSettingsValue(
    SettingCategories.dashboard,
    'companyHomepageDashboardId',
  );
  const [userHomepageDashboard] = usePortalSettingsValue(
    SettingCategories.dashboard,
    'userHomepageDashboardId',
  );
  const [userRoleUiSettings] = usePortalSettingsValue(
    SettingCategories.ui,
    `${profile?.roles?.[0]}:settings`,
    {},
  );

  const homepageDashboard = useMemo(
    () => {
      if (userHomepageDashboard && dashboardsMeta?.[userHomepageDashboard]) {
        return userHomepageDashboard;
      }
      if (
        isRolesUiSettingsEnabled
      && userRoleUiSettings?.homepageDashboardId
      && dashboardsMeta?.[userRoleUiSettings?.homepageDashboardId]
      ) {
        return userRoleUiSettings.homepageDashboardId;
      }
      return companyHomepageDashboard;
    },
    [
      companyHomepageDashboard,
      dashboardsMeta,
      userHomepageDashboard,
      userRoleUiSettings,
      isRolesUiSettingsEnabled,
    ],
  );

  const isDevicesFetching = useSelector(deviceSelectors.isFetching);
  const devices = useSelector(deviceSelectors.getDevices);

  const isVpcsFetching = useSelector(vpcSelectors.isFetching);
  const vpcs = useSelector(vpcSelectors.getVpcs);

  const hasDevices = !!Object.keys(devices || {}).length;
  const hasVpcs = !!Object.keys(vpcs || {}).length;
  const { hasFlow } = customer;
  const hasSources = hasDevices || hasVpcs;

  const isFetching = isDashboardsFetching
    || isCustomerFetching
    || isDevicesFetching
    || isVpcsFetching;

  useLoadingIndicator(isFetching, 'HomePage');

  useEffect(
    () => {
      if (wizardDismissed) {
        return;
      }

      dispatch(wizardActions.setStepFirst(hasSources));
      dispatch(wizardActions.setStepSecond(!!hasFlow));

      // because we need exclude case when hasFlow === undefined
      if (hasFlow === null || hasFlow === false) {
        dispatch(wizardActions.show());
      }
    },
    [wizardDismissed, hasSources, hasFlow],
  );

  useEffect(
    () => {
      if (wizardDismissed || hasDevices) {
        return undefined;
      }

      dispatch(deviceActions.devicesFetch(null, 'homePage_devices'));
      return () => {
        dispatch(deviceActions.cancel('homePage_devices'));
      };
    },
    [wizardDismissed, hasDevices],
  );

  useEffect(
    () => {
      if (wizardDismissed || hasVpcs) {
        return undefined;
      }

      dispatch(vpcActions.fetchVpcs(null, 'homePage_vpcs'));
      return () => {
        dispatch(vpcActions.cancel('homePage_vpcs'));
      };
    },
    [wizardDismissed, hasVpcs],
  );

  useEffect(
    () => {
      if (!isAllMetaFetched) {
        dispatch(dashboardsActions.fetchDashboardsMeta());
      }
    },
    [isAllMetaFetched],
  );

  return showWizard ? (
    <Wizard />
  ) : (
    <Dashboard id={homepageDashboard} mode={DashboardModeTypes.page} />
  );
};

export default HomePage;
