import { useCallback } from 'react';

import uniq from 'lodash.uniq';

import { Field, useForm, useFormState } from '+components/form/FinalForm';
import MultiSelectField from '+components/form/MultiSelectField';
import { normalizeMultiSelectValue } from '+components/form/Normalizers';
import {
  validateIpOrCidrs,
  validateRequired,
} from '+components/form/Validators';
import FormModal, { propTypes, defaultProps } from '+components/FormModal';
import * as toast from '+components/toast';

const AddIndividuallyForm = (props) => {
  const { initialValues, isOpen, onToggle } = props;

  const form = useForm();
  const { values: formValues } = useFormState({
    subscription: { values: true },
  });

  const onSubmit = useCallback(
    (values) => {
      const uniqAllowlists = uniq([
        ...values.whitelist,
        ...formValues.whitelist,
      ]);
      const newItemsCount = uniqAllowlists.length - formValues.whitelist.length;
      if (newItemsCount > 0) {
        form.change('whitelist', uniqAllowlists);
        toast.success(
          `Added ${newItemsCount} new item${
            newItemsCount > 1 ? 's' : ''
          }. Don't forget to press "Save" button.`,
        );
      } else {
        toast.info('There are no new items to add.');
      }
      onToggle();
    },
    [form, formValues, onToggle],
  );

  return (
    <FormModal
      mode="add"
      item="IP addresses"
      confirmButtonText="Add"
      initialValues={initialValues}
      isOpen={isOpen}
      onToggle={onToggle}
      onSubmit={onSubmit}
      labelOnTop
    >
      <Field
        name="whitelist"
        label="IP Addresses"
        helperText="List of IP or IP/CIDR addresses."
        component={MultiSelectField}
        allowCreate
        parse={normalizeMultiSelectValue}
        validate={[validateIpOrCidrs, validateRequired]}
        required
      />
    </FormModal>
  );
};

AddIndividuallyForm.propTypes = {
  initialValues: propTypes.initialValues,
  isOpen: propTypes.isOpen,
  onToggle: propTypes.onToggle,
};

AddIndividuallyForm.defaultProps = {
  initialValues: defaultProps.initialValues,
  isOpen: defaultProps.isOpen,
  onToggle: defaultProps.onToggle,
};

export default AddIndividuallyForm;
