import PropTypes from '+prop-types';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToggle } from 'react-use';

import CancelIcon from 'mdi-react/CancelIcon';

import { ContextTypes } from '@/models/ContextTypes';
import PermissionModel from '@/models/Permission';
import * as PropertiesTray from '@/models/PropertiesTray';

import { actions as blocksActions } from '@/redux/api/blocks';
import { selectors as customerSelectors } from '@/redux/api/customer';
import {
  actions as ruleActions,
  selectors as rulesSelectors,
} from '@/redux/api/rules';

import Button, { ButtonVariants } from '+components/Button';
import ConfirmModal from '+components/ConfirmModal';
import Table from '+components/Table';
import { MenuColumnPropertiesTrayTrigger } from '+components/Table/Columns';
import { getRowOriginal } from '+components/Table/Columns/utils';
import { useAvailableExtractor } from '+hooks/useAvailableExtractor';
import useGlobalFilters from '+hooks/useGlobalFilters';
import usePermissions from '+hooks/usePermissions';
import { usePreparedColumns } from '+hooks/usePreparedColumns';
import { getColumnsHelper } from '+utils/getColumnsHelper';

import { Columns, ColumnsCollection } from './components/Columns';
import { getBlockSelectedKey } from './utils';

const getRowId = (item) => getBlockSelectedKey(item);

const defaultColumns = [
  Columns.rowSelector,
  Columns.start,
  Columns.expiration,
  Columns.active,
  Columns._source,
  Columns._srcGeo,
  Columns._destination,
  Columns._dstOwnerAs,
  Columns.rules,
  Columns.adapter,
  Columns.pluginName,
  Columns.menu,
];

const BlockList = (props) => {
  const {
    columns: columnsProp,
    data,
    setAdditionalActionItems,
    sortBy,
    ...tail
  } = props;

  const dispatch = useDispatch();
  const [filters] = useGlobalFilters();
  const permissions = usePermissions(
    PermissionModel.Resources.blocklist.value,
    !!data?.length,
  );

  const customer = useSelector(customerSelectors.getCurrentCustomer);
  // we need algorithms and categories because cells using them
  const algorithms = useSelector(rulesSelectors.getAlgorithms);
  const categories = useSelector(rulesSelectors.getCategories);

  const [selected, setSelected] = useState([]);
  const [showBulkDeleteModal, toggleBulkDeleteModal] = useToggle(false);

  const columns = useMemo(
    () => {
      if (customer?.isReseller) {
        const indexOfCustomerColumn = columnsProp.indexOf(Columns.customer);
        if (indexOfCustomerColumn === -1) {
          return [...columnsProp, Columns.customer];
        }
      }
      return columnsProp;
    },
    [columnsProp, customer],
  );

  const onBlockRemove = useCallback(
    (row) => () => {
      dispatch(blocksActions.removeBlock(row));
    },
    [],
  );

  const cxActionMenu = useCallback(
    (_, original) => (
      <MenuColumnPropertiesTrayTrigger
        title={`Block Details — ${original.id}`}
        dataType={PropertiesTray.DataTypes.record}
        recordType={ContextTypes.blocks}
        value={original}
        additionalMenuOptions={[
          {
            icon: <CancelIcon />,
            text: 'Remove',
            disabled: !original.active || !permissions?.delete,
            onClick: onBlockRemove(original),
          },
        ]}
      />
    ),
    [onBlockRemove, permissions?.delete],
  );

  useEffect(
    () => {
      if (setAdditionalActionItems) {
        if (!selected?.length) {
          setAdditionalActionItems(
          // eslint-disable-next-line react/jsx-no-useless-fragment
            <Fragment />,
          );

          return;
        }

        setAdditionalActionItems(
          <Button
            variant={ButtonVariants.outlined}
            onClick={toggleBulkDeleteModal}
            disabled={!permissions?.delete}
          >
            Remove Selected Blocks
          </Button>,
        );
      }
    },
    [selected],
  );

  const onSelectedRowsChange = useCallback(
    (selectedRowIds) => {
      setSelected((prev) => {
        const next = Object.entries(selectedRowIds || {})
          .map(([key, value]) => (value ? key : null))
          .filter(Boolean);

        if (!prev.length && !next.length) {
          return prev;
        }

        return next;
      });
    },
    [],
  );

  const onBulkDelete = useCallback(
    () => {
      toggleBulkDeleteModal();
      if (!selected?.length) {
        return;
      }

      const selectedData = selected.map((dataString) => JSON.parse(dataString));
      dispatch(blocksActions.bulkRemoveBlocks(selectedData));
    },
    [data, selected],
  );

  const getIsRowSelectorDisabled = useCallback(
    (row) => {
      const original = getRowOriginal(row);
      return !original?.active || !permissions?.delete;
    },
    [permissions],
  );

  const overrideColumns = useMemo(
    () => ColumnsCollection({
      labelContext: filters.labelContext,
      cxActionMenu,
    }),
    [filters.labelContext, cxActionMenu],
  );

  const collection = usePreparedColumns(ContextTypes.blocks, {
    overrideColumns,
  });

  const preparedColumns = useMemo(
    () => Object.keys(collection),
    [collection],
  );

  const getColumns = useMemo(
    () => getColumnsHelper(collection),
    [collection],
  );

  const parsedColumns = useMemo(
    // if we don't recieve a method to populate action bar with bulk actions,
    // assume we're not using bulk actions and don't show checkboxes
    () => (setAdditionalActionItems
      ? columns
      : columns.filter((column) => column !== 'rowSelector')),
    [columns],
  );

  const [tableColumns, available] = useAvailableExtractor({
    row: data?.[0],
    selectedColumns: parsedColumns,
    getColumns,
    preparedColumns,
  });

  const algorithmsLength = Object.keys(algorithms || {}).length;
  useEffect(
    () => {
      if (!algorithmsLength && data?.length) {
        dispatch(ruleActions.fetchAlgorithms());
      }
    },
    [algorithmsLength, data?.length],
  );

  useEffect(
    () => {
      if (!categories?.length && data?.length) {
        dispatch(ruleActions.fetchCategories());
      }
    },
    [categories?.length, data?.length],
  );

  return (
    <Fragment>
      <Table
        id="BlockList"
        columns={tableColumns}
        availableColumns={available}
        data={data}
        getRowId={getRowId}
        sortBy={sortBy}
        getIsRowSelectorDisabled={getIsRowSelectorDisabled}
        onSelectedRowsChange={onSelectedRowsChange}
        {...tail}
      />
      {showBulkDeleteModal && (
        <ConfirmModal
          item={`${selected.size} Block${selected.size > 1 ? 's' : ''}`}
          confirmButtonText="remove"
          whyAsking=""
          onToggle={toggleBulkDeleteModal}
          onConfirm={onBulkDelete}
          toggleOnConfirm={false}
          isOpen
        />
      )}
    </Fragment>
  );
};

const tableColumn = PropTypes.oneOf(Object.values(Columns || {}));

BlockList.propTypes = {
  noDataText: PropTypes.string,
  columns: PropTypes.arrayOf(tableColumn),
  data: PropTypes.arrayOf(PropTypes.shape({})),
  sortBy: PropTypes.arrayOf(PropTypes.shape({})),
  setAdditionalActionItems: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.oneOf([null]),
  ]),
};

BlockList.defaultProps = {
  noDataText: 'No blocks to show',
  data: [],
  columns: defaultColumns,
  sortBy: [
    {
      id: 'start',
      desc: true,
    },
  ],
  setAdditionalActionItems: null,
};

export { defaultColumns, Columns };
export default BlockList;
