import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import StatsRequest from '@/models/StatsRequest';

import { selectors as socketControlSelectors } from '@/redux/ui/socketControl';

import calcRefreshInterval, {
  getSeriesInterval,
  getSeriesLastBucketTime,
} from '+utils/calcRefreshInterval';

/**
 * Hook to create polling heartbeat for REST API stats requests.
 *
 * @param {Object?} series - stats series
 * @param {StatsRequest.Types?} seriesType - series type.
 * @param {boolean?} stop - if true, polling heartbeat will be paused.
 * @param {number?} minRefreshInterval - minimum refresh interval.
 * @return number
 */
export const usePollingHeartbeat = (
  series,
  seriesType,
  stop,
  minRefreshInterval,
) => {
  const isSocketPaused = useSelector(socketControlSelectors.isPaused);
  const [pollingHeartbeat, setPollingHeartbeat] = useState(0);
  const seriesInterval = getSeriesInterval(series);
  const seriesLastBucketTime = seriesType === StatsRequest.Types.ts ? getSeriesLastBucketTime(series) : 0;

  useEffect(
    () => {
      if (isSocketPaused || stop) {
        return undefined;
      }

      const refreshInterval = calcRefreshInterval({
        seriesInterval,
        seriesLastBucketTime,
        minRefreshInterval,
      });
      const intervalId = setInterval(() => {
        const newHeartbeat = Date.now();
        setPollingHeartbeat(newHeartbeat);
      }, refreshInterval);

      return () => {
        clearInterval(intervalId);
      };
    },
    [isSocketPaused, stop, seriesInterval, seriesLastBucketTime],
  );

  return pollingHeartbeat;
};

export default usePollingHeartbeat;
