import styled from 'styled-components';

const Button = styled.button`
  outline: unset;
  background: unset;
  border: unset;
  padding: unset;
  text-decoration: underline;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
`;

export default Button;
