import styled from 'styled-components';

export default styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding-left: 140px;
  margin-top: 1em;
  padding-top: 1em;
  gap: 15px;
  
  div.btn-group {
    .btn:not(.dropdown-toggle):not(:only-child) {
      padding: 0 18px;
      border-right: 1px solid ${({ theme }) => theme.colorBackground};
    }
    
    .btn.dropdown-toggle {
      margin: unset;
      padding: 0 8px;
      border-left: 1px solid ${({ theme }) => theme.colorBackground};
      
      :hover, :focus {
        border-left: 1px solid ${({ theme }) => theme.colorBackground};
      }
    }
  }

  .button-delete {
    margin-left: auto;
  }
`;
