import PropTypes from '+prop-types';
import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import styled from 'styled-components';

import {
  actions as vpcActions,
  selectors as vpcSelectors,
} from '@/redux/api/vpc';

import TableOrigin from '+components/Table';
import { ResizableHeaderContent } from '+components/Table/ReactTable/components/Components';
import dayjs from '+utils/dayjs';

import getColumns from './columns';

const vpcStatusTableId = 'VpcStatusTable';

const Table = styled(TableOrigin)`
  ${ResizableHeaderContent} {
    justify-content: flex-start;
  }
  margin-top: 0.5rem;
`;

const VpcStatusTable = ({ vpc, toggleModal, setDetailsMessage }) => {
  const dispatch = useDispatch();
  const status = useSelector(vpcSelectors.getVpcStatus(vpc?.id));

  const data = useMemo(
    () => {
      const normalizedData = [...(status?.progress || [])];
      if (normalizedData.length) {
        normalizedData[0] = {
          ...normalizedData[0],
          lastPolled: status?.time
            ? `${dayjs(status.time).fromNow(true)} ago`
            : 'N/A',
        };
      }
      return normalizedData;
    },
    [status?.progress],
  );

  useEffect(
    () => {
      if (status?.time) {
        return;
      }
      dispatch(vpcActions.vpcStatus(vpc?.id));
    },
    [status?.time, vpc?.id],
  );

  const columns = useMemo(
    () => getColumns({ toggleModal, setDetailsMessage }),
    [toggleModal, setDetailsMessage],
  );

  return (
    <Table
      id={vpcStatusTableId}
      columns={columns}
      data={data}
      noDataText="No status data to display"
      pagination={false}
      showManagerLayout={false}
      disableFilters
    />
  );
};

VpcStatusTable.propTypes = {
  vpc: PropTypes.shape({
    id: PropTypes.string,
  }),
  toggleModal: PropTypes.func.isRequired,
  setDetailsMessage: PropTypes.func.isRequired,
};

VpcStatusTable.defaultProps = {
  vpc: {},
};

export default VpcStatusTable;
