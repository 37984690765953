import styled from 'styled-components';

import Grid from './Grid';

export const Row = styled(Grid).attrs((props) => ({
  container: true,
  ...((props.container ?? true) && { direction: 'row' }),
  ...props,
  role: 'row',
}))``;
