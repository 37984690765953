/* eslint-disable react/no-array-index-key */
import PropTypes from '+prop-types';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { useToggle } from 'react-use';

import classNames from 'classnames';
import styled, { css } from 'styled-components';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import Popper from '@mui/material/Popper';

import FormField from '+components/form/FormField';

const Container = styled.div`
  display: flex;
  gap: 10px;

  ${(props) => props.$compact
    && css`
      flex-wrap: wrap;
      width: 210px;
      border-radius: 4px;
      background-color: ${({ theme }) => theme.color(theme.colorFieldBackground).lighten(0.3)};
      padding: 5px;
    `}
`;

const Color = styled.div`
  --size: 32px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: var(--size);
  min-height: var(--size);
  width: var(--size);
  min-width: var(--size);
  border-radius: 50%;
  ${(props) => (props.$color?.includes('gradient')
    ? css`
          background-image: ${props.$color};
        `
    : css`
          background-color: ${props.$color};
        `)}
  cursor: pointer;
  color: ${({ theme }) => theme.colorPickerBorder};

  :hover .inner-circle {
    opacity: 0.6;
  }

  &.active .inner-circle {
    opacity: 1;
  }

  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.25;
  }
`;

const InnerCircle = styled.div`
  --size: 24px;
  height: var(--size);
  min-height: var(--size);
  width: var(--size);
  min-width: var(--size);
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colorFieldBackground};
  opacity: 0;
`;

const popperModifiers = [
  {
    name: 'offset',
    options: {
      offset: [-5, 5],
    },
  },
];

const ColorPickerField = (props) => {
  const {
    className,
    options,
    input: { value, onChange },
    label,
    helperText,
    meta: { touched, error, dirty, submitFailed },
    disabled,
    required,
    defaultActive,
    compact,
  } = props;

  const invalid = error && (dirty || submitFailed) && touched;

  const [anchorEl, setAnchorEl] = useState(null);
  const [showAllColors, toggleAllColors] = useToggle(false);

  const innerValue = useMemo(
    () => (value == null || value === '' ? defaultActive : value),
    [value, defaultActive],
  );

  const onToggleColor = useCallback(
    (newValue) => () => {
      toggleAllColors(false);
      onChange(value === newValue ? null : newValue);
    },
    [value, onChange],
  );

  const colorElements = useMemo(
    () => options.map((color, i) => {
      const active = i === innerValue;
      return (
        <Color
          key={i}
          className={classNames({ active, disabled })}
          $color={color}
          onClick={onToggleColor(i)}
        >
          <InnerCircle className="inner-circle" />
        </Color>
      );
    }),
    [options, innerValue, disabled, onToggleColor],
  );

  return (
    <FormField
      className={className}
      label={label}
      helperText={helperText}
      error={error}
      invalid={invalid}
      disabled={disabled}
      required={required}
    >
      {!compact && <Container className="container">{colorElements}</Container>}

      {compact && (
        <Fragment>
          <Color
            ref={setAnchorEl}
            $color={options[innerValue] || 'transparent'}
            onClick={toggleAllColors}
          />

          <Popper
            anchorEl={anchorEl}
            open={showAllColors}
            modifiers={popperModifiers}
            placement="right-start"
          >
            <ClickAwayListener onClickAway={toggleAllColors}>
              <Container className="container" $compact>
                {colorElements}
              </Container>
            </ClickAwayListener>
          </Popper>
        </Fragment>
      )}
    </FormField>
  );
};

ColorPickerField.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  input: PropTypes.shape({
    value: PropTypes.any, // eslint-disable-line react/forbid-prop-types
    onChange: PropTypes.func,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    dirty: PropTypes.bool,
    submitFailed: PropTypes.bool,
  }),
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  helperText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  defaultActive: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  compact: PropTypes.bool,
};

ColorPickerField.defaultProps = {
  className: null,
  options: [],
  meta: null,
  label: null,
  helperText: null,
  disabled: false,
  required: false,
  defaultActive: undefined,
  compact: false,
};

export default ColorPickerField;
