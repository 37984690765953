import PropTypes from '+prop-types';
import { memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ContextTypes, ContextTypesLabels } from '@/models/ContextTypes';
import * as PropertiesTray from '@/models/PropertiesTray';

import { selectors as customerSelectors } from '@/redux/api/customer';

import SubDnsDetails from '+components/ContextTables/DnsTable/components/SubDetails';
import SubFlowDetails, {
  getRowHeight,
} from '+components/ContextTables/FlowTable/components/SubDetails';
import Table from '+components/Table';
import { MenuColumnPropertiesTrayTrigger } from '+components/Table/Columns';
import { useAvailableExtractor } from '+hooks/useAvailableExtractor';
import useGlobalFilters from '+hooks/useGlobalFilters';
import { usePreparedColumns } from '+hooks/usePreparedColumns';
import { getColumnsHelper } from '+utils/getColumnsHelper';

import { columnsCollection, Columns } from './components/Columns';

const defaultColumns = [
  Columns.trafficType,
  Columns.timestamp,
  Columns._source,
  Columns._srcPort,
  Columns.datasrc,
  Columns.menu,
];

const SubComponent = (props) => {
  const { original } = props;

  const Component = original?.[Columns.trafficType] === ContextTypes.dns
    ? SubDnsDetails
    : SubFlowDetails;

  return <Component {...props} />;
};

SubComponent.propTypes = {
  original: PropTypes.shape().isRequired,
};

const TrafficTable = (props) => {
  const {
    columns: columnsProp,
    tableData,
    filterable,
    hideSubComponent,
    ...tail
  } = props;

  const [filters] = useGlobalFilters();

  const customer = useSelector(customerSelectors.getCurrentCustomer);

  const columns = useMemo(
    () => {
      if (customer?.isReseller) {
        const indexOfCustomerColumn = columnsProp.indexOf(Columns.customer);
        if (indexOfCustomerColumn === -1) {
          return [...columnsProp, Columns.customer];
        }
      }
      return columnsProp;
    },
    [columnsProp, customer],
  );

  const cxActionMenu = useCallback(
    (_, original) => (
      <MenuColumnPropertiesTrayTrigger
        title={`${
          ContextTypesLabels[original.traffic_type] || original.traffic_type
        } Details — ${original.id}`}
        dataType={PropertiesTray.DataTypes.record}
        recordType={original.traffic_type}
        value={original}
      />
    ),
    [],
  );

  const overrideColumns = useMemo(
    () => columnsCollection({
      labelContext: filters.labelContext,
      cxActionMenu,
    }),
    [cxActionMenu, filters.labelContext],
  );

  const collection = usePreparedColumns(ContextTypes.traffic, {
    overrideColumns,
  });

  const preparedColumns = useMemo(
    () => Object.keys(collection),
    [collection],
  );

  const getColumns = useMemo(
    () => getColumnsHelper(collection),
    [collection],
  );

  const [tableColumns, available] = useAvailableExtractor({
    row: tableData?.[0],
    selectedColumns: columns,
    getColumns,
    preparedColumns,
  });

  return (
    <Table
      data={tableData}
      disableFilters={!filterable}
      SubComponent={SubComponent}
      rowHeight={getRowHeight}
      {...tail}
      columns={tableColumns}
      availableColumns={available}
    />
  );
};

TrafficTable.propTypes = {
  ...Table.propTypes,
  tableData: PropTypes.arrayOf(PropTypes.shape()),
  columns: PropTypes.arrayOf(PropTypes.string),
  noDataText: PropTypes.string,
  filterable: PropTypes.bool,
  hideSubComponent: PropTypes.bool,
};

TrafficTable.defaultProps = {
  columns: defaultColumns,
  noDataText: 'No Traffic received',
  tableData: [],
  filterable: true,
  hideSubComponent: false,
};

export { Columns, defaultColumns };

export default memo(TrafficTable);
