import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${(props) => props.theme.rightSideAlertBorderColor};
  margin-top: 2px;
  margin-bottom: 2px;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
    border-bottom: 0;
  }
`;
