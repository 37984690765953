import get from 'lodash.get';

import makeArr from '+utils/makeArr';

import { autoRemoveIfEmpty } from '../ReactTable/utils';
import { FilterOperator } from './utils';
import { withAutoRemove } from './withAutoRemove';

/**
 * @param {string[]} fields
 * @param {function(*): boolean} autoRemove
 * @return {function([], [], *): []}
 */
export const someOfFieldsFilter = (fields, autoRemove = autoRemoveIfEmpty) => withAutoRemove((rows, _, filterValue) => {
  if (autoRemove(filterValue)) {
    return rows;
  }

  const normalizedFilterValue = String(filterValue.value).toLowerCase();

  let filterFn;
  switch (filterValue.operator) {
    case FilterOperator.equal:
      filterFn = ({ original }) => fields.some((field) => {
        const values = makeArr(get(original, field));
        return values.some((item) => {
          const normalizedItem = makeArr(item);
          return normalizedItem.some(
            (el) => String(el ?? '').toLowerCase() === normalizedFilterValue,
          );
        });
      });
      break;
    case FilterOperator.like:
    default:
      filterFn = ({ original }) => fields.some((field) => {
        const values = makeArr(get(original, field));
        return values.some((item) => {
          const normalizedItem = String(item ?? '').toLowerCase();
          return normalizedItem.includes(normalizedFilterValue);
        });
      });
      break;
  }

  return rows.filter(filterFn);
}, autoRemove);
