import { css } from 'styled-components';

const ButtonMixin = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  border-radius: 50%;

  color: ${({ theme }) => theme.table.menuTriggerColor};
  background-color: ${({ theme }) => theme.table.menuTriggerBackground};

  :disabled {
    opacity: 0.3;
  }

  :hover:not([disabled]):not(.active) {
    color: ${({ theme }) => theme.table.menuTriggerHoverColor};
    background-color: ${({ theme }) => theme.table.menuTriggerHoverBackground};
  }

  &.active {
    color: ${({ theme }) => theme.table.menuTriggerActiveColor};
    background-color: ${({ theme }) => theme.table.menuTriggerActiveBackground};
  }
`;

export default ButtonMixin;
