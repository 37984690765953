import PropTypes from '+prop-types';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { useFlag } from '@unleash/proxy-client-react';
import isEqual from 'lodash.isequal';

import { ContextTypes } from '@/models/ContextTypes';
import StatsRequest from '@/models/StatsRequest';

import { selectors as customerSelectors } from '@/redux/api/customer';

import { lang } from '+components/charts/common/utils';
import { Columns, EventTable } from '+components/ContextTables/EventTable';
import useEvent from '+hooks/useEvent';
import useGlobalFilters from '+hooks/useGlobalFilters';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import useRealtimeOrRequest from '+hooks/useRealtimeOrRequest';
import { makeId } from '+utils';
import nqlLang from '+utils/nqlLang';

const tableIdEventList = 'result_event_list';
const eventSearchId = makeId();

const EventListTab = ({ userFilters, refresher }) => {
  const [filters] = useGlobalFilters(ContextTypes.alerts);
  const customer = useSelector(customerSelectors.getCurrentCustomer);
  const isDnsEnabled = useFlag('DNS');

  const defaultEventListColumns = useMemo(
    () => [
      isDnsEnabled && Columns.traffictype,
      Columns.summary,
      Columns.categories,
      Columns.start,
      Columns.duration,
      Columns.alerttype,
      Columns.severity,
      Columns.menu,
    ].filter(Boolean),
    [isDnsEnabled],
  );

  const [includeFields, setIncludeFields] = useState([]);

  const eventListColumns = useMemo(
    () => {
      if (customer?.isReseller) {
        const indexOfCustomerColumn = defaultEventListColumns.indexOf(
          Columns.customer,
        );
        if (indexOfCustomerColumn === -1) {
          return [...defaultEventListColumns, Columns.customer];
        }
      }
      return defaultEventListColumns;
    },
    [defaultEventListColumns, customer],
  );

  const request = useMemo(
    () => ({
      feed: ContextTypes.alerts,
      start: userFilters.start,
      end: userFilters.end,
      size: 1000,
      include: includeFields,
      ...StatsRequest.makeSearch({
        search: filters.nql,
        intersect: filters.intersect,
        andSearch: nqlLang.and(
          nqlLang.equal('algorithm', userFilters.algorithm),
          nqlLang.equal('ipinfo.ip', userFilters.ip),
          userFilters.ip
            ? nqlLang.or(
              nqlLang.greater('ndm_score_threat', '0'),
              nqlLang.greater('ndm_score_confidence', '0'),
            )
            : '',
        ),
      }),
      customers: filters.customers,
      sort: {
        field: 'start',
        order: 'desc',
      },
    }),
    [
      userFilters.start,
      userFilters.end,
      userFilters.algorithm,
      userFilters.ip,
      filters.nql,
      filters.intersect,
      filters.customers,
      includeFields,
      refresher,
    ],
  );

  const { isFetching, records } = useRealtimeOrRequest({
    name: eventSearchId,
    socketOptions: request,
    stopRequest: !includeFields.length,
  });

  useLoadingIndicator(isFetching);

  const data = useMemo(
    () => records?.toArray() || [],
    [records],
  );

  const onColumnsChange = useEvent(
    (allColumns, hiddenColumns, technicalColumns) => {
      const fieldsSet = new Set(
        allColumns
          .map((item) => {
            if (item.realAccessor) {
              return item.realAccessor;
            }
            return typeof item.accessor === 'string' ? item.accessor : item.id;
          })
          .flat()
          .map((field) => {
            if (field.startsWith('label.ip')) {
              return 'label.ip';
            }
            if (field.startsWith('label.port')) {
              return 'label.port';
            }
            return field;
          }),
      );
      // remove hidden columns
      hiddenColumns.forEach((item) => {
        fieldsSet.delete(item);
      });
      // remove technical columns
      technicalColumns.forEach((item) => {
        fieldsSet.delete(item);
      });
      // add id - we need it to fetch full record if we need it
      fieldsSet.add('id');
      if (customer?.isReseller) {
        fieldsSet.add('customer');
      }
      const nextValue = [...fieldsSet].filter(Boolean);
      setIncludeFields((prevValue) => (isEqual(prevValue, nextValue) ? prevValue : nextValue));
    },
  );

  return (
    <EventTable
      id={tableIdEventList}
      data={data}
      columns={eventListColumns}
      noDataText={records ? 'There are no events.' : lang.loading}
      onColumnsChange={onColumnsChange}
      exportingAllFields={false}
      fillWithEmptyRows
    />
  );
};

EventListTab.propTypes = {
  userFilters: PropTypes.shape({
    start: PropTypes.number,
    end: PropTypes.number,
    algorithm: PropTypes.string,
    ip: PropTypes.string,
  }).isRequired,
  refresher: PropTypes.any,
};

EventListTab.defaultProps = {
  refresher: null,
};

export default EventListTab;
