import styled from 'styled-components';

import { Col } from '+components/Layout';

export default styled(Col)`
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: auto 0;
  color: ${({ theme }) => theme.colorTextSecondary} !important;
`;
