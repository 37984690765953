import { useMemo } from 'react';

import { ContextTypes } from '@/models/ContextTypes';

import {
  actions as auditLogsActions,
  selectors as auditLogsSelectors,
} from '@/redux/api/stats/auditLogs';
import {
  actions as blocksActions,
  selectors as blocksSelectors,
} from '@/redux/api/stats/blocks';
import {
  actions as dnsActions,
  selectors as dnsSelectors,
} from '@/redux/api/stats/dns';
import {
  actions as eventsActions,
  selectors as eventsSelectors,
} from '@/redux/api/stats/events';
import {
  actions as flowActions,
  selectors as flowSelectors,
} from '@/redux/api/stats/flow';

import useStatsMeta from '+hooks/useStatsMeta';

export const useAllMetricsAndFields = (context, { fields, metrics }) => {
  const { metrics: flowMetrics, fields: flowFields } = useStatsMeta({
    metrics: metrics && context === ContextTypes.flow,
    fields: fields && context === ContextTypes.flow,
    actions: flowActions,
    selectors: flowSelectors,
  });

  const { metrics: dnsMetrics, fields: dnsFields } = useStatsMeta({
    metrics: metrics && context === ContextTypes.dns,
    fields: fields && context === ContextTypes.dns,
    actions: dnsActions,
    selectors: dnsSelectors,
  });

  const { metrics: alertMetrics, fields: alertFields } = useStatsMeta({
    metrics: metrics && context === ContextTypes.alerts,
    fields: fields && context === ContextTypes.alerts,
    actions: eventsActions,
    selectors: eventsSelectors,
  });

  const { metrics: blockMetrics, fields: blockFields } = useStatsMeta({
    metrics: metrics && context === ContextTypes.blocks,
    fields: fields && context === ContextTypes.blocks,
    actions: blocksActions,
    selectors: blocksSelectors,
  });

  const { metrics: auditLogsMetrics, fields: auditLogsFields } = useStatsMeta({
    metrics: metrics && context === ContextTypes.audit,
    fields: fields && context === ContextTypes.audit,
    actions: auditLogsActions,
    selectors: auditLogsSelectors,
  });

  const allMetrics = useMemo(
    () => ({
      [ContextTypes.alerts]: alertMetrics,
      [ContextTypes.audit]: auditLogsMetrics,
      [ContextTypes.blocks]: blockMetrics,
      [ContextTypes.flow]: flowMetrics,
      [ContextTypes.dns]: dnsMetrics,
    }),
    [alertMetrics, auditLogsMetrics, blockMetrics, flowMetrics, dnsMetrics],
  );

  const allFields = useMemo(
    () => ({
      [ContextTypes.alerts]: alertFields,
      [ContextTypes.audit]: auditLogsFields,
      [ContextTypes.blocks]: blockFields,
      [ContextTypes.flow]: flowFields,
      [ContextTypes.dns]: dnsFields,
    }),
    [alertFields, auditLogsFields, blockFields, flowFields, dnsFields],
  );

  return useMemo(
    () => ({ allMetrics, allFields }),
    [allMetrics, allFields],
  );
};
