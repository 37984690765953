import styled from 'styled-components';

export default styled.button.attrs(() => ({ type: 'button' }))`
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;

  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  color: ${({ theme }) => theme.linkColor};
  transition: color 0.1s;

  &:hover {
    color: ${({ theme }) => theme.linkHoverColor};
  }

  &:active {
    color: ${({ theme }) => theme.linkHoverColor};
  }

  opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};
  pointer-events: ${({ $disabled }) => ($disabled ? 'none' : null)};
`;
