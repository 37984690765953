import { memo } from 'react';
import ContentLoader from 'react-content-loader';

import { withTheme } from 'styled-components';

const widths = Array(10).fill(false).map(() => Math.random());

const MenuLoader = withTheme(({
  theme,
  length = 13,
  ...props
}) => (
  <ContentLoader
    speed={2}
    width={130}
    height={240}
    viewBox="0 0 130 240"
    backgroundColor={theme.colorDisabledText}
    foregroundColor={theme.colorText}
    css="margin-left: 3px"
    {...props}
  >
    {[...Array(length).keys()].map((i) => (
      <rect
        key={i}
        x="6"
        y={i * 23 + 4}
        rx="3"
        ry="3"
        height="6"
        width={70 + Math.floor(50 * widths[i % widths.length])}
      />
    ))}
  </ContentLoader>
));

export default memo(MenuLoader);
