import classNames from 'classnames';
import styled, { css } from 'styled-components';

import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import ContentSaveIcon from 'mdi-react/ContentSaveIcon';

export default styled(({ className, disabled, readOnly, ...tail }) => (
  <div
    className={classNames(className, 'nql-dropdown-btn-container', {
      disabled,
      readOnly,
    })}
  >
    <button
      className="nql-dropdown-btn"
      type="button"
      disabled={disabled}
      {...tail}
      data-tracking="nql-drop-down"
    >
      <ContentSaveIcon size={18} />
      <ChevronDownIcon size={16} />
    </button>
  </div>
))`
  ${({ theme }) => css`
    display: flex;
    align-items: flex-start;
    background: ${theme.colorFieldBackground};
    padding: 2px 0 0 7px;

    button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 50px;
      height: 26px;
      padding: 0 6px;
      background-color: ${theme.iconButtonInsideComponentsBackground};
      color: ${theme.iconButtonInsideComponentsText};
      border-radius: 16px;
      border: unset;
      outline: none;
      transition: border-color 0.3s ease-in-out, background 0.3s ease-in-out;

      &:focus,
      &:hover,
      &:active {
        outline: none;
        background-color: ${theme
    .color(theme.iconButtonInsideComponentsBackground)
    .lighten(0.2)} !important;
      }

      &[disabled] {
        cursor: not-allowed;
        background: transparent !important;
        &:hover {
          background: transparent !important;
        }
      }
    }

    &.readOnly {
      pointer-events: none;
      background: transparent !important;
      button {
        background: transparent !important;
      }
    }

    &.disabled {
      cursor: not-allowed;
      background: ${theme.colorFieldBackground} !important;
      &:hover {
        background: ${theme.colorFieldBackground} !important;
      }
    }
  `}
`;
