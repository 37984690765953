import PropTypes from '+prop-types';
import { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useMatch } from 'react-router-dom';

import { useFlag } from '@unleash/proxy-client-react';

import RoutePaths from '@/models/RoutePaths';

import { selectors as customerSelectors } from '@/redux/api/customer';
import { selectors as profileSelectors } from '@/redux/api/user/profile';

import useUIProperty from '+hooks/useUIProperty';

import Tab from './components/Tab';
import TabContent from './components/TabContent';
import TabGroup from './components/TabGroup';
import TabGroupTitle from './components/TabGroupTitle';
import Tabs from './components/Tabs';
import TabsContainer from './components/TabsContainer';

const tabGroups = {
  account: 'Account',
  profile: 'My Profile',
  userManagement: 'User Management',
  dataManagement: 'Data Management',
  detectionResponse: 'Detect and Respond',
};

const rootPath = '/admin';

const Settings = ({ children }) => {
  const navigate = useNavigate();
  const isDnsEnabled = useFlag('DNS');

  const { params: { id: tabId } = {} } = useMatch(`${rootPath}/:id/*`) || {};
  const customer = useSelector(customerSelectors.getCurrentCustomer);
  const profile = useSelector(profileSelectors.getProfile);
  const [guest] = useUIProperty('guest');

  const isProfileSecurityAvailable = !(
    profile?.idp
    || profile?.app_metadata?.useResellerSso
    || profile?.app_metadata?.original
  );

  const tabs = useMemo(
    () => ({
      // General
      overview: {
        value: RoutePaths.settings.pageName,
        label: 'Overview',
        group: tabGroups.account,
      },
      'audit-logs': {
        value: RoutePaths.searchAuditLogs.pageName,
        label: 'Audit Logs',
        group: tabGroups.account,
      },
      ...(customer?.isReseller
        ? {
          customers: {
            value: RoutePaths.customers.pageName,
            label: 'Customers',
            group: tabGroups.account,
          },
        }
        : {}),
      // My Profile
      ...(guest
        ? {}
        : {
          profile: {
            value: RoutePaths.profile.pageName,
            label: 'Details',
            group: tabGroups.profile,
          },
          'profile-personalization': {
            value: RoutePaths.profilePersonalization.pageName,
            label: 'Personalization',
            group: tabGroups.profile,
          },
          ...(!isProfileSecurityAvailable
            ? {}
            : {
              'profile-security': {
                value: RoutePaths.profileSecurity.pageName,
                label: 'Security',
                group: tabGroups.profile,
              },
            }),
          'profile-activity': {
            value: RoutePaths.profileActivity.pageName,
            label: 'Activity',
            group: tabGroups.profile,
          },
        }),
      // User Management
      'api-keys': {
        value: RoutePaths.apiKeys.pageName,
        label: 'API Keys',
        group: tabGroups.userManagement,
      },
      users: {
        value: RoutePaths.users.pageName,
        label: 'Users',
        group: tabGroups.userManagement,
      },
      roles: {
        value: RoutePaths.roles.pageName,
        label: 'Roles',
        group: tabGroups.userManagement,
      },
      security: {
        value: RoutePaths.security.pageName,
        label: 'Global Security/SSO',
        group: tabGroups.userManagement,
      },
      // Data Management
      sources: {
        value: RoutePaths.sources.pageName,
        label: `${isDnsEnabled ? 'Traffic' : 'Flow'} Sources`,
        group: tabGroups.dataManagement,
      },
      'context-integrations': {
        value: RoutePaths.integrationsContext.pageName,
        label: 'Context Integrations',
        group: tabGroups.dataManagement,
      },
      labels: {
        value: 'labels/ip',
        label: 'Context Labels',
        group: tabGroups.dataManagement,
      },
      'flow-tags': {
        value: RoutePaths.flowTags.pageName,
        label: 'Flow Tags',
        group: tabGroups.dataManagement,
      },
      ...(isDnsEnabled
        ? {
          'traffic-classification': {
            value: RoutePaths.networkClassification.pageName,
            label: 'Traffic Classification',
            group: tabGroups.dataManagement,
          },
        }
        : {
          'network-classification': {
            value: RoutePaths.networkClassification.pageName,
            label: 'Network Classification',
            group: tabGroups.dataManagement,
          },
        }),
      // Detect and Respond
      'detection-categories': {
        value: RoutePaths.detectionCategories.pageName,
        label: 'Detection Categories',
        group: tabGroups.detectionResponse,
      },
      'response-policies': {
        value: RoutePaths.responsePolicies.pageName,
        label: 'Response Policies',
        group: tabGroups.detectionResponse,
      },
      'response-integrations': {
        value: RoutePaths.integrationsResponse.pageName,
        label: 'Response Integrations',
        group: tabGroups.detectionResponse,
      },
    }),
    [customer?.isReseller, isDnsEnabled, guest, isProfileSecurityAvailable],
  );

  const groupedTabs = useMemo(
    () => {
      const groups = {};
      Object.values(tabs).forEach((tab) => {
        if (tab.group) {
          if (!groups[tab.group]) {
            groups[tab.group] = [];
          }
          groups[tab.group].push(tab);
        }
      });
      return groups;
    },
    [tabs],
  );

  const defaultTab = useMemo(
    () => tabs.overview,
    [tabs.overview],
  );

  const currentTab = useMemo(
    () => tabs[tabId] || defaultTab,
    [tabs, tabId, defaultTab],
  );

  const onTabChange = useCallback(
    (_, value) => {
      // if (value === tabs.overview.value) {
      //   navigate(rootPath);
      //   return;
      // }
      navigate(`${rootPath}/${value}`);
    },
    [tabs.overview.value, navigate],
  );

  // const isCustomerLoaded = !!Object.keys(customer || {}).length;
  // useEffect(
  //   () => {
  //     if (isCustomerLoaded && !tabs[tabId]) {
  //       navigate(`${parentPath}/${defaultTab.value}`, { replace: true });
  //     }
  //   },
  //   [tabId, tabs, defaultTab, isCustomerLoaded],
  // );

  return (
    <TabsContainer>
      <Tabs>
        {Object.entries(groupedTabs).map(([tabsGroup, tabsInGroup]) => (
          <TabGroup key={tabsGroup}>
            <TabGroupTitle>{tabsGroup}</TabGroupTitle>
            {tabsInGroup.map((tab) => (
              <Tab
                key={tab.value}
                $active={currentTab.value === tab.value}
                onClick={() => onTabChange(null, tab.value)}
              >
                {tab.label}
              </Tab>
            ))}
          </TabGroup>
        ))}
      </Tabs>

      <TabContent>{children}</TabContent>
    </TabsContainer>
  );
};

Settings.propTypes = {
  children: PropTypes.children.isRequired,
};

export default Settings;
