import PropTypes from '+prop-types';

const tdStyle = {
  paddingLeft: '5px',
  textAlign: 'right',
};

const Tooltip = ({
  name,
  value,
  packets,
  bandwidth,
  asn,
  asorg,
  dstip,
  customer,
}) => (
  <table style={{ width: '100%', height: 'fit-content' }}>
    <tbody>
      {name && (
        <tr>
          <td colSpan={2}>
            {name}
            <br />
            <br />
          </td>
        </tr>
      )}
      <tr>
        <td>Flows:</td>
        <td style={tdStyle}>{value}</td>
      </tr>
      <tr>
        <td>Packets:</td>
        <td style={tdStyle}>{packets}</td>
      </tr>
      <tr>
        <td>Bandwidth:</td>
        <td style={tdStyle}>{bandwidth}</td>
      </tr>
      {dstip && (
        <tr>
          <td>IP:</td>
          <td style={tdStyle}>{dstip}</td>
        </tr>
      )}
      {asn && (
        <tr>
          <td>ASN:</td>
          <td style={tdStyle}>{asn}</td>
        </tr>
      )}
      {asorg && (
        <tr>
          <td>AS Org:</td>
          <td style={tdStyle}>{asorg}</td>
        </tr>
      )}
      {customer && (
        <tr>
          <td>Account:</td>
          <td style={tdStyle}>{customer}</td>
        </tr>
      )}
    </tbody>
  </table>
);

Tooltip.propTypes = {
  value: PropTypes.string,
  packets: PropTypes.string,
  bandwidth: PropTypes.string,
  dstip: PropTypes.string,
  asn: PropTypes.number,
  asorg: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  customer: PropTypes.string,
};

Tooltip.defaultProps = {
  value: null,
  packets: null,
  bandwidth: null,
  dstip: null,
  asn: null,
  asorg: null,
  name: null,
  customer: null,
};

export default Tooltip;
