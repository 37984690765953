/**
 * @enum {string}
 */
export const ContextTypes = {
  alerts: 'alerts',
  audit: 'audit',
  blocks: 'blocks',
  dns: 'dns',
  flow: 'flow',
  ip: 'ip',
  thresholdFlow: 'thresholdFlow',
  thresholdDns: 'thresholdDns',
  traffic: 'traffic',
};

export const ContextTypesLabels = {
  [ContextTypes.alerts]: 'Events',
  [ContextTypes.audit]: 'Audit',
  [ContextTypes.blocks]: 'Blocks',
  [ContextTypes.dns]: 'DNS',
  [ContextTypes.flow]: 'Flow',
  [ContextTypes.ip]: 'IP',
  [ContextTypes.thresholdFlow]: 'Thresholds',
  [ContextTypes.thresholdDns]: 'Thresholds',
  [ContextTypes.traffic]: 'Traffic',
};
