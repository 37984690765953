/* eslint-disable jsx-a11y/label-has-for */
import PropTypes from '+prop-types';
import { useEffect } from 'react';

import classNames from 'classnames';
import styled, { css } from 'styled-components';

import CheckIcon from 'mdi-react/CheckIcon';

import { Description } from './FormField/index';

const inlineBlockTransitionMixin = css`
  display: inline-block;
  vertical-align: middle;
  //transition: all 0.3s;
`;

// Custom checkbox box to replace native input visual
const CheckboxCustom = styled((props) => (
  <span {...props}>
    <CheckIcon />
  </span>
))`
  ${({ theme, $color }) => `
  display: flex !important;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  background-color: ${theme.colorFieldBackground};
  border: 1px solid ${theme.checkboxBorderColor};

  svg {
    // transition: all 0.3s;
    opacity: 0;
    height: 16px;
    width: 16px;
    fill: ${theme.checkboxColor};
  }

  ${
  $color
    ? `
    background: ${$color};
    border-color: ${$color};
  `
    : ''
}
  ${inlineBlockTransitionMixin}
`}
`;

// Hidden input for actual checkbox state
const CheckboxHidden = styled.input.attrs({
  type: 'checkbox',
})`
  display: none;
`;

// Text adjacent to checkbox box
const LabelText = styled.span`
  ${({ theme }) => `
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 18px;
  padding-left: 28px;
  padding-top: 0;
  color: ${theme.colorText};
`}
`;

// HTML label wrapper for checkbox
const Label = styled.label`
  ${({ theme }) => `
  display: flex;
  cursor: pointer;
  width: fit-content;
  height: 18px;
  position: relative;
  
  ${CheckboxHidden}:checked + ${CheckboxCustom} {
    svg {
      opacity: 1;
    }
  }

  &:not(.disabled) {
    &:hover ${CheckboxCustom}, 
    ${CheckboxHidden}:focus ~ ${CheckboxCustom} {
      border-color: ${theme.checkboxFocusBorderColor};
    }
  
    &:hover ${LabelText}, 
    ${CheckboxHidden}:focus ~ ${LabelText} {
      color: ${theme.checkboxFocusBorderColor};
    }
  }

  &.readOnly {
    cursor: default;    
    span {
      background-color: unset;
    }
    input {
      opacity: 0 !important;
    }
  }

  &.disabled {
    cursor: not-allowed;
    opacity: .25;
    input {
      opacity: 0 !important;
    }
  }
`}
`;

const CheckBox = (props) => {
  const {
    disabled,
    readOnly,
    className,
    name,
    value,
    checked,
    onChange,
    label,
    color,
    helperText,
    defaultChecked,
  } = props;

  useEffect(
    () => {
      if (defaultChecked != null) {
        onChange(defaultChecked);
      }
    },
    [defaultChecked, onChange],
  );

  return (
    <Label
      className={classNames(className, 'checkbox', { disabled, readOnly })}
    >
      <CheckboxHidden
        name={name}
        onChange={onChange}
        checked={value || checked}
        disabled={disabled || readOnly}
      />
      <CheckboxCustom $color={color} />
      {(label || helperText) && (
        <div>
          {label && <LabelText>{label}</LabelText>}
          {helperText && (
            <Description style={{ marginLeft: 0 }}>{helperText}</Description>
          )}
        </div>
      )}
    </Label>
  );
};

CheckBox.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  checked: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  helperText: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.func,
    PropTypes.element,
  ]),
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

CheckBox.defaultProps = {
  label: '',
  name: '',
  defaultChecked: undefined,
  value: false,
  checked: false,
  disabled: false,
  readOnly: false,
  className: '',
  color: '',
  helperText: '',
};

const CheckBoxField = (props) => {
  const {
    input,
    label,
    defaultChecked,
    disabled,
    readOnly,
    className,
    color,
    helperText,
  } = props;

  return (
    <CheckBox
      {...input}
      label={label}
      defaultChecked={defaultChecked}
      disabled={disabled}
      readOnly={readOnly}
      className={className}
      color={color}
      helperText={helperText}
    />
  );
};

CheckBoxField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  }).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  helperText: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.func,
    PropTypes.element,
  ]),
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
};

CheckBoxField.defaultProps = {
  label: '',
  helperText: '',
  defaultChecked: undefined,
  disabled: false,
  readOnly: false,
  className: '',
  color: '',
};

export { CheckBox };
export default CheckBoxField;
