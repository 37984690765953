import { useCallback, useContext, useMemo, useState, Fragment } from 'react';
import { useDebounce } from 'react-use';

import isFunction from 'lodash.isfunction';
import styled from 'styled-components';

import ScrollBar from '+components/ScrollBar/smooth';
import { incName } from '+utils/incName';

import ContainerOrigin from '../Container';
import Context from '../Context';
import ExportAction from './ExportAction';
import LayoutItem from './LayoutItem';
import SaveAction from './SaveAction';
import TitleItem from './TitleItem';

const Container = styled(ContainerOrigin)`
  padding-top: 5px;
`;

const ItemsContainer = styled(ScrollBar)`
  padding-top: 0;
  max-height: 11vh;
`;

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px 5px;
`;

const Input = styled.input`
  width: 0;
  flex: 1;
  border-radius: 3px;
`;

const makeFilterAction = (filter) => (item) => {
  const fixedFilter = filter.toLowerCase();

  return item.title?.toLowerCase().includes(fixedFilter);
};

const ActionsList = () => {
  const {
    save,
    selected,
    items,
    needSave,
    isDefault,
    isAdmin,
    userItems,
    companyItems,
    systemItems,
    ...tail
  } = useContext(Context);
  const [filter, setFilter] = useState('');
  const [filterAction, setFilterAction] = useState(null);
  const [editItem, setEditItem] = useState(null);

  useDebounce(
    () => {
      setFilterAction(filter?.trim() ? () => makeFilterAction(filter) : null);
    },
    300,
    [filter],
  );

  const onFilterChange = useCallback(
    (event) => {
      setFilter(event?.target?.value);
    },
    [],
  );

  const names = useMemo(
    () => Array.from(new Set((items || []).map(({ title }) => title))),
    [items],
  );

  const selectedTitle = useMemo(
    () => {
      const item = items.find(({ id }) => id === selected);
      return item?.title || '';
    },
    [selected, items],
  );

  const onSaveClick = useCallback(
    () => setEditItem({ title: incName(selectedTitle || 'Untitled', names) }),
    [selectedTitle, names],
  );

  const onFinishSaveClick = useCallback(
    (title) => {
      if (isFunction(save)) {
        save(title);
      }
      setEditItem(null);
    },
    [save],
  );

  const cancel = useCallback(
    () => setEditItem(null),
    [],
  );

  const canSaveMore = userItems?.length < 20;

  const filteredUserItems = useMemo(
    () => (filterAction ? (userItems || []).filter(filterAction) : userItems),
    [userItems, filterAction],
  );

  const filteredCompanyItems = useMemo(
    () => (filterAction ? (companyItems || []).filter(filterAction) : companyItems),
    [companyItems, filterAction],
  );

  const filteredSystemItems = useMemo(
    () => (filterAction ? (systemItems || []).filter(filterAction) : systemItems),
    [systemItems, filterAction],
  );

  return (
    <Container>
      {items?.length > 3 && (
        <FilterContainer>
          <Input value={filter} onChange={onFilterChange} />
        </FilterContainer>
      )}

      {!editItem && canSaveMore && (
        <SaveAction
          onClick={editItem ? null : onSaveClick}
          $disabled={Boolean(editItem)}
        />
      )}

      {editItem && (
        <LayoutItem
          {...tail}
          isNew
          item={editItem}
          save={onFinishSaveClick}
          cancel={cancel}
          isEditable
        />
      )}

      <ItemsContainer>
        {(filteredUserItems || []).map((item) => (
          <LayoutItem
            {...tail}
            key={item.id}
            selected={selected === item.id}
            needSave={needSave && selected === item.id}
            save={save}
            item={item}
            isEditable
            isDuplicable={canSaveMore}
            canBeDefault={isAdmin}
          />
        ))}

        {filteredCompanyItems?.length > 0 && (
          <Fragment>
            <TitleItem>Company Layouts</TitleItem>
            {filteredCompanyItems?.map((item) => (
              <LayoutItem
                {...tail}
                key={item.id}
                selected={selected === item.id}
                save={save}
                item={item}
                isDuplicable={canSaveMore}
                canBeDefault={isAdmin}
              />
            ))}
          </Fragment>
        )}

        {filteredSystemItems?.length > 0 && (
          <Fragment>
            <TitleItem>System Layouts</TitleItem>
            {filteredSystemItems?.map((item) => (
              <LayoutItem
                {...tail}
                key={item.id}
                selected={selected === item.id}
                needSave={needSave && selected === item.id}
                save={save}
                item={item}
                isEditable={isDefault}
                isDuplicable={canSaveMore}
                canBeDefault={isDefault}
              />
            ))}
          </Fragment>
        )}
      </ItemsContainer>
    </Container>
  );
};

export { SaveAction, ExportAction, LayoutItem };

export default ActionsList;
