import styled from 'styled-components';

import { ShowMoreWrapper } from '+components/Table/Cells';

import { BaseColumnFactory } from './BaseColumnFactory';

const StyledSpan = styled.span`
  margin-left: 4px;
`;

/**
 * @param {Object} props
 * @return {JSX.Element} - individual divs with class, rdata, and type returned
 */
export const AnswersColumnFactory = (props) => ({
  ...BaseColumnFactory(props),
  width: 180,
  /* eslint-disable react/prop-types */
  Cell: ({ value }) => {
    return (
      <ShowMoreWrapper>
        {value?.map((answer) => {
          return (
            <div key={answer?.rdata}>
              <span>{answer?.class}</span>
              <StyledSpan>{answer?.rdata}</StyledSpan>
              <StyledSpan>{answer?.type}</StyledSpan>
            </div>
          );
        })}
      </ShowMoreWrapper>
    );
  },
});
