import Palette from '../palette';
import { createThemeSlice } from '../util';

const themeSlice = createThemeSlice('globalFilters', (choose) => ({
  mainRowBackground: choose({
    light: '#F0F1F4',
    dark: '#35353A',
  }),
  mainRowSectionBackground: choose({
    light: '#FFFFFF',
    dark: '#202124',
  }),
  mainRowNqlGroupHoverBackground: choose({
    light: '#F0F1F4',
    dark: '#36363C',
  }),
  mainRowNqlGroupHoverShadow: choose({
    light: '#F0F1F4',
    dark: '#00000040',
  }),
  mainRowSeparatorBackground: choose({
    light: '#E0DFE2',
    dark: '#35353B',
  }),
  additionalRowBackground: choose({
    light: '#FFFFFF',
    dark: '#26272A',
  }),
  additionalFiltersButtonBackground: choose({
    light: '#FFFFFF',
    dark: '#26272A',
  }),
  additionalFiltersButtonText: choose({
    light: '#A2A2A2',
    dark: '#FFFFFF',
  }),
  additionalFiltersButtonCollapsedText: choose({
    light: '#707070',
    dark: '#A2A2A2',
  }),
  separatorColor: choose({
    light: '#E7E2E2',
    dark: '#26272C',
  }),
  selectBackground: choose({
    light: '#f2f4f7',
    dark: '#38373F',
  }),
  selectOptionSelected: choose({
    light: '#C0BFC5',
    dark: '#54545A',
  }),
  selectOptionFocused: choose({
    light: Palette.primary,
    dark: Palette.primary,
  }),
}));

export const { selector, selectors, propsSelectors } = themeSlice;
export default themeSlice;
