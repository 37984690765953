import PropTypes from '+prop-types';
import { Fragment } from 'react';

import { Field, FieldArray } from '+components/form/FinalForm';
import FieldContainer from '+components/form/FormField/components/FieldContainer';
import Group from '+components/form/FormField/components/Group';
import Label from '+components/form/FormField/components/Label';
import TextField from '+components/form/TextField';
import { validateRequired } from '+components/form/Validators';

import RenderPhoneInputArray from '../components/RenderPhoneInputArray';

const SmsFields = ({ disabled }) => (
  <Fragment>
    <Field
      name="name"
      label="Name"
      component={TextField}
      type="text"
      maxLength={255}
      autoComplete="new-password"
      validate={validateRequired}
      style={{ width: '50%' }}
      disabled={disabled}
      required
    />

    <Field
      name="description"
      label="Description"
      component={TextField}
      type="text"
      maxLength={255}
      autoComplete="new-password"
      disabled={disabled}
    />

    <Group>
      <Label disabled={disabled}>To</Label>
      <FieldContainer style={{ display: 'block' }}>
        <FieldArray
          name="config.to"
          component={RenderPhoneInputArray}
          disabled={disabled}
        />
      </FieldContainer>
    </Group>
  </Fragment>
);

SmsFields.propTypes = {
  disabled: PropTypes.bool,
};

SmsFields.defaultProps = {
  disabled: false,
};

export default SmsFields;
