import PropTypes from '+prop-types';
import { Fragment } from 'react';

import Field from '+components/form/FinalForm/Field';
import { normalizeSelectValue } from '+components/form/Normalizers';
import RoleAuthFieldsSection from '+components/form/RoleAuthFieldSection';
import SelectField from '+components/form/SelectField';
import TextField from '+components/form/TextField';
import { validateRequired } from '+components/form/Validators';

const types = [
  { value: 'A', label: 'A - IPv4 address' },
  { value: 'CNAME', label: 'CNAME - Canonical name' },
  { value: 'MX', label: 'MX - Mail exchange' },
  { value: 'AAAA', label: 'AAAA - IPv6 address' },
  { value: 'TXT', label: 'TXT - Text' },
  { value: 'PTR', label: 'PTR - Pointer' },
  { value: 'SRV', label: 'SRV - Service locator' },
  { value: 'SPF', label: 'SRV - Sender Policy Framework' },
  { value: 'NAPTR', label: 'NAPTR - Name Authority Pointer' },
  { value: 'CAA', label: 'CAA - Certification Authority Authorization' },
  { value: 'NS', label: 'NS - Name server' },
];

const Ns1Fields = ({ disabled }) => (
  <Fragment>
    <Field
      name="name"
      label="Name"
      component={TextField}
      type="text"
      maxLength={255}
      autoComplete="new-password"
      validate={validateRequired}
      style={{ width: '50%' }}
      disabled={disabled}
      required
    />

    <Field
      name="description"
      label="Description"
      component={TextField}
      type="text"
      maxLength={255}
      autoComplete="new-password"
      disabled={disabled}
    />

    <Field
      name="config.type"
      label="Type"
      component={SelectField}
      options={types}
      validate={validateRequired}
      parse={normalizeSelectValue}
      disabled={disabled}
      required
    />

    <Field
      name="config.domain"
      label="Domain"
      component={TextField}
      type="text"
      autoComplete="new-password"
      validate={validateRequired}
      disabled={disabled}
      required
    />

    <Field
      name="config.link"
      label="Link"
      component={TextField}
      type="text"
      autoComplete="new-password"
      validate={validateRequired}
      disabled={disabled}
      required
    />

    <Field
      name="config.zone"
      label="Zone"
      component={TextField}
      type="text"
      autoComplete="new-password"
      validate={validateRequired}
      disabled={disabled}
      required
    />
    <RoleAuthFieldsSection label="Authentication">
      <Field
        name="config.apikey"
        label="API Key"
        component={TextField}
        type="password"
        autoComplete="new-password"
        validate={validateRequired}
        disabled={disabled}
        required
      />
    </RoleAuthFieldsSection>
  </Fragment>
);

Ns1Fields.propTypes = {
  disabled: PropTypes.bool,
};

Ns1Fields.defaultProps = {
  disabled: false,
};

export default Ns1Fields;
