import { call, put, cancelled } from 'redux-saga/effects';

import backendClient from '@/middleware/backendClient';

import { actions as toastActions } from '../toast';

const initApi = () => {
  if (!initApi.instance) {
    initApi.instance = backendClient();
  }

  return initApi.instance;
};

const toastSuccess = function* (successMessage) {
  if (!successMessage || typeof successMessage !== 'string') {
    return;
  }

  yield put(toastActions.success(successMessage));
};

const toastSuccessWithAuditLogVerification = function* ({ message, response }) {
  if (!message || typeof message !== 'string') {
    return;
  }

  yield put(toastActions.successWithAuditLogVerification({ message, response }));
};

const toastError = function* (errorMessage, error) {
  if (!errorMessage || typeof errorMessage !== 'string') {
    return;
  }

  yield put(toastActions.error({
    message: errorMessage,
    details: error.message,
  }));
};

/**
 * Abstraction over try,catch,finally cancel pattern
 *
 * @param {string|Function} callable - name of axios actions, or axios action, callable function to execute.
 * @param {Object} options - processing options.
 *
 * @param {Generator?} options.success - a function that will be called if callable is called without error.
 * @param {Generator?} options.error - a function that will be called if an error happened.
 * @param {Generator?} options.cancel - a function that will be called if operation is cancelled
 *
 * @param {Function?} options.successAction - an action that will be put if callable is called without error.
 * @param {Function?} options.errorAction - an action that will be put if an error happened.
 * @param {Function?} options.cancelAction - an action that will be put if operation is cancelled.
 *
 * @param {Object?} options.toasts - if you need to send a toast at success or error process.
 * @param {string?} options.toasts.success - toast message if successful result
 * @param {string?} options.toasts.error - toast message if error
 * @param {string} url - url
 * @param {...*} params - callable arguments: payload, ...etc
 *
 * @example
 * *saga() {
 *  const api = backendClient();
 *  yield tryCancelCall(api.get, {
 *    * success(result) {
 *      // some operation
 *    },
 *    cancelAction: actions.cancel,
 *    errorAction: actions.error,
 *    toasts: {
 *      error: 'Action has been failed'
 *    }
 *  }, ...params);
 * };
 *
 * // ---OR---
 *
 * *saga() {
 *  yield tryCancelCall('post', {
 *    * success(result) {
 *      // some operation
 *    },
 *    * error(error) {
 *      // do something
 *    },
 *    * cancel() {
 *      // do something
 *    },
 *  }, url, payload);
 * };
 */
export const tryCancelSaga = function* (
  callable,
  options,
  url,
  ...params
) {
  const {
    success,
    successAction,
    error,
    errorAction,
    cancel,
    cancelAction,
    toasts: {
      success: successMessage,
      error: errorMessage,
      withAuditLogVerification,
    } = {},
  } = options || {};

  if (!success && !successAction) {
    throw new Error('options.success or options.successAction should be defined');
  }

  let action = callable;
  if (typeof action === 'string') {
    const api = initApi();
    action = api[action];
  }

  if (!action) {
    return;
  }

  try {
    const result = yield call(action, url, ...params);

    if (success) {
      yield* success(result);
    } else if (successAction) {
      yield put(successAction(result));
    }

    if (withAuditLogVerification) {
      yield toastSuccessWithAuditLogVerification({
        message: successMessage,
        response: result,
      });
    } else {
      yield toastSuccess(successMessage);
    }
  } catch (exception) {
    if (error) {
      yield* error(exception);
      yield toastError(errorMessage, exception);
    } else if (errorAction) {
      yield put(errorAction(exception));
      yield toastError(errorMessage, exception);
    } else {
      throw exception;
    }
  } finally {
    if (yield cancelled()) {
      if (cancel) {
        yield* cancel();
      } else if (cancelAction) {
        yield put(cancelAction());
      }
    }
  }
};
