import Select from '+components/form/Select';

export const METHOD_OPTIONS = {
  replace: { value: 'replace', label: 'replace' },
  context: { value: 'context', label: 'copy' },
  regex: { value: 'regex', label: 'regex' },
  function: { value: 'function', label: 'custom' },
};

const MethodSelector = (props) => {
  // eslint-disable-next-line react/prop-types
  const { setMethod, method, disabled } = props;
  return (
    <Select
      disabled={disabled}
      value={method}
      options={Object.values(METHOD_OPTIONS)}
      onChange={(option) => {
        setMethod(option.value);
      }}
    />
  );
};

export default MethodSelector;
