import PropTypes from '+prop-types';
import ReactCountryFlag from 'react-country-flag';

/**
 * Flags of the world component.
 */
const Flag = ({ countryCode, ...otherProps }) => (
  <ReactCountryFlag
    countryCode={countryCode}
    cdnUrl="/flags/"
    style={{ width: '1.4em' }}
    svg
    {...otherProps}
  />
);

Flag.displayName = 'Flag';

Flag.propTypes = {
  /**
   * The ISO 3166-1 alpha-2 or alpha-3 of the country's flag.
   */
  countryCode: PropTypes.string.isRequired,
};

export default Flag;
