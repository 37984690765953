import PropTypes from '+prop-types';

import ArrowCollapseRightIcon from 'mdi-react/ArrowCollapseRightIcon';
import ArrowExpandLeftIcon from 'mdi-react/ArrowExpandLeftIcon';

import TopbarButton from '+components/TopbarButton';

const PropertiesTrayButton = (props) => {
  const { isOpen, onClick } = props;

  return (
    <TopbarButton
      type="button"
      title={isOpen ? 'Close Properties Tray' : 'Open Properties Tray'}
      onClick={onClick}
      data-tracking="global-properties-tray-button"
    >
      {isOpen ? (
        <ArrowCollapseRightIcon size={20} />
      ) : (
        <ArrowExpandLeftIcon size={20} />
      )}
    </TopbarButton>
  );
};

PropertiesTrayButton.propTypes = {
  isOpen: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

PropertiesTrayButton.defaultProps = {
  isOpen: false,
};

export default PropertiesTrayButton;
