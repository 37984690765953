import PropTypes from '+prop-types';

import classNames from 'classnames';

const Description = ({ className, children, ...tail }) => (
  <div
    {...tail}
    className={classNames(className || '', {
      'form__form-group-description': true,
    })}
  >
    {children}
  </div>
);

Description.propTypes = {
  /**
   * Override or extend the styles applied to the component.
   */
  className: PropTypes.string,
  /**
   * Child elements.
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
};

Description.defaultProps = {
  children: null,
  className: null,
};

export default Description;
