// IDM2021: app_ added to our custom role names because there is a default role created by Keycloak called `admin`
// which has more privileges than we want users to have, and is not deletable, so we can't use `admin` (that's why we changed it to `app_admin`)
export const Roles = {
  app_admin: 'app_admin',
  app_readonly: 'app_readonly',
  app_operational_manager: 'app_operational_manager',
};

export const RoleColors = {
  [Roles.app_admin]: 'success',
  [Roles.app_operational_manager]: 'warning',
};

export const getRoleColor = (role) => RoleColors[role] || 'info';

export const sortRoles = (a, b) => {
  if (a?.system > b?.system) {
    return -1;
  }

  if (a?.system !== b?.system) {
    return 1;
  }

  return (a?.name || '')
    .toLowerCase()
    .localeCompare((b?.name || '').toLowerCase());
};

class Role {
  constructor(data) {
    const {
      id,
      name,
      description,
      canMasquerade,
      canSendFlow,
      canFetchAuditLog,
      system,
      ...rest
    } = data || {};

    Object.assign(this, {
      ...rest,
      id,
      name,
      description,
      canMasquerade,
      canSendFlow,
      canFetchAuditLog,
      system: !!system,
      // ui params only
      value: id,
      label: name,
      color: getRoleColor(id),
    });
  }
}

const createModel = (data) => new Role(data);

export default {
  Roles,
  RoleColors,
  getRoleColor,
  sortRoles,
  createModel,
};
