import IpExplorerOrigin from '+components/IpExplorer';
import useIpExplorerResolver from '+hooks/useIpExplorerResolver';

const IpExplorer = (props) => {
  const { nodesFunction, particlesFunction } = useIpExplorerResolver();

  return (
    <IpExplorerOrigin
      {...props}
      nodesFunction={nodesFunction}
      particlesFunction={particlesFunction}
    />
  );
};

export default IpExplorer;
