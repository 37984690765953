/* eslint-disable react/no-array-index-key */
import PropTypes from '+prop-types';
import { Fragment, cloneElement } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';

import classNames from 'classnames';

import { Separator } from '+components/Menu';

import Item from './Item';

const makeItem = ({ item, index, showFirstItemAsTrigger, iconSize }) => {
  const key = `${index}`;

  if (item.separator) {
    return <Separator key={key} />;
  }

  let itemContent = (
    <span className={classNames(item.className, 'menu-item__content')}>
      {item.icon && (
        <span className="menu-item__icon">
          {cloneElement(item.icon, { size: iconSize })}
        </span>
      )}
      {item.text && !showFirstItemAsTrigger && (
        <span className="menu-item__text">{item.text}</span>
      )}
    </span>
  );

  if (item.copyToClipboard) {
    itemContent = (
      <CopyToClipboard text={item.copyToClipboard}>
        {itemContent}
      </CopyToClipboard>
    );
  }

  if (item.url) {
    itemContent = (
      <Link to={item.url} onClick={item.onClick}>
        {itemContent}
      </Link>
    );
  }

  return (
    <Item
      key={key}
      onClick={item.url ? undefined : item.onClick}
      disabled={item.disabled}
    >
      {itemContent}
    </Item>
  );
};

const MenuContent = (props) => {
  const { title, iconSize, items, showFirstItemAsTrigger } = props;

  return (
    <Fragment>
      {showFirstItemAsTrigger
        && cloneElement(
          makeItem({
            item: items[0],
            index: 0,
            showFirstItemAsTrigger,
            iconSize,
          }),
          {
            className: classNames('menu-trigger', 'first-item-as-trigger'),
            title: items[0].text,
          },
        )}

      {!showFirstItemAsTrigger && title && (
        <Fragment>
          <Item disabled>{title}</Item>
          <Separator />
        </Fragment>
      )}

      {!showFirstItemAsTrigger
        && items.map((item, index) => makeItem({
          item,
          index,
          showFirstItemAsTrigger,
          iconSize,
        }))}
    </Fragment>
  );
};

const propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      className: PropTypes.string,
      separator: PropTypes.bool,
      icon: PropTypes.element,
      text: PropTypes.node,
      url: PropTypes.string,
      disabled: PropTypes.bool,
      onClick: PropTypes.func,
    }),
  ),
  iconSize: PropTypes.number,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  showFirstItemAsTrigger: PropTypes.bool,
};

const defaultProps = {
  items: [],
  iconSize: 16,
  title: null,
  showFirstItemAsTrigger: false,
};

MenuContent.propTypes = propTypes;
MenuContent.defaultProps = defaultProps;

export { propTypes, defaultProps };
export default MenuContent;
