import {
  getLabelFieldData,
  getLabelFieldMeta,
} from '+components/Table/Cells/UniversalCell';
import { SelectColumnFilter } from '+components/Table/Filters';
import {
  autoRemoveIfAll,
  withAutoRemove,
} from '+components/Table/FilterTypeFactories';
import sortByHelper from '+utils/sortByHelper';

import { BaseColumnFactory } from './BaseColumnFactory';

const makeLabelsArr = (value, field) => {
  const fieldMeta = getLabelFieldMeta(field);
  const { contexts, directions, labels } = getLabelFieldData(fieldMeta, value);

  const labelsSet = new Set();

  contexts.forEach((context) => {
    directions.forEach((direction) => {
      labels?.[context]?.[direction]?.forEach((label) => {
        labelsSet.add(label);
      });
    });
  });

  return Array.from(labelsSet);
};

/**
 * @param {Object} props
 * @param {string} props.field - field name
 * @param {string} [props.description=''] - field description
 * @return {Object}
 */
export const LabelColumnsFactory = (props) => ({
  ...BaseColumnFactory(props),
  Filter: SelectColumnFilter({
    optionValueExtractor: (row, id) => {
      const value = row?.values?.[id];
      return makeLabelsArr(value, id);
    },
    optionLabel: (key) => (key === 'all' ? 'All' : key),
  }),
  filter: withAutoRemove((rows, [id], filterValue) => {
    if (autoRemoveIfAll(filterValue)) {
      return rows;
    }

    const fieldMeta = getLabelFieldMeta(id);

    return rows.filter(({ values: { [id]: value } }) => {
      const { contexts, directions, labels } = getLabelFieldData(
        fieldMeta,
        value,
      );
      return contexts.some((context) => directions.some(
        (direction) => !!labels?.[context]?.[direction]?.includes(filterValue.value),
      ));
    });
  }, autoRemoveIfAll),
  sortType: sortByHelper((value, _, id) => {
    const labelsArr = makeLabelsArr(value, id);
    return labelsArr.sort().join('');
  }),
});
