import PropTypes from '+prop-types';
import { Fragment } from 'react';

import { ContextTypes } from '@/models/ContextTypes';

import {
  getPropertiesList,
  SubDataColumn,
  SubDetails,
} from '+components/SubDetails';

export { getRowHeight } from '+components/SubDetails';

const originDetails = [
  {
    field: 'site',
    label: 'site',
  },
  {
    field: 'region',
    label: 'region',
  },
  {
    field: 'accountid',
    label: 'accountid',
  },
  {
    field: 'vpcid',
    label: 'vpcid',
  },
  {
    field: 'instanceid',
    label: 'instanceid',
  },
  {
    field: 'datasrc',
    label: 'source',
  },
  {
    field: 'type',
    label: 'type',
  },
  {
    field: 'version',
    label: 'version',
  },
];

const network = [
  {
    field: 'srcip',
    label: 'srcip',
  },
  {
    field: 'srcport',
    label: 'srcport',
  },
  {
    field: 'protocol',
    label: 'protocol',
  },
];

const query = [
  {
    field: 'query.name',
    label: 'name',
  },
  {
    field: 'query.domain',
    label: 'domain',
  },
  {
    field: 'query.host',
    label: 'host',
  },
  {
    field: 'query.publicsuffix',
    label: 'publicsuffix',
  },
  {
    field: 'query.tld',
    label: 'tld',
  },
  {
    field: 'query.type',
    label: 'type',
  },
  {
    field: 'internal',
    label: 'internal',
  },
  {
    field: 'provider',
    label: 'provider',
  },
];

const response = [
  {
    field: 'rcode',
    label: 'rcode',
  },
  {
    field: 'answercount',
    label: 'answercount',
  },
  {
    field: 'action',
    label: 'action',
  },
];

const DataBody = ({ record }) => (
  <Fragment>
    <SubDataColumn title="Origin Details">
      {getPropertiesList(record, originDetails)}
    </SubDataColumn>
    <SubDataColumn title="Network">
      {getPropertiesList(record, network)}
    </SubDataColumn>
    <SubDataColumn title="Query">
      {getPropertiesList(record, query)}
    </SubDataColumn>
    <SubDataColumn title="Response">
      {getPropertiesList(record, response)}
    </SubDataColumn>
  </Fragment>
);

DataBody.propTypes = {
  record: PropTypes.shape().isRequired,
};

const SubDnsDetails = (props) => (
  <SubDetails context={ContextTypes.dns} rowData={props} Component={DataBody} />
);

export default SubDnsDetails;
