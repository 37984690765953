export const getHighchartsColor = ({
  paletteClassName,
  paletteIndex,
  colorClassName,
  colorIndex,
}) => {
  const virtualContainer = document.createElement('div');
  virtualContainer.style.visibility = 'hidden';
  virtualContainer.className = `${paletteClassName}-${paletteIndex} ${colorClassName}-${colorIndex}`;
  document.body.append(virtualContainer);
  const { color } = getComputedStyle(virtualContainer);
  virtualContainer.remove();

  return color;
};
