import PropTypes from '+prop-types';

import classNames from 'classnames';
import styled, { css } from 'styled-components';

const TextWrapper = styled.div`
  ${({ monospace }) => monospace
    && css`
      font-family: monospace, monospace;
      font-size: 0.8em;
      width: 550px;
      scroll-x: auto;
      white-space: pre;
      margin-top: 4px;
    `}
`;

const Plaintext = (props) => {
  const { className, children, wrappedText, monospace, ...tail } = props;

  return (
    <div
      className={classNames(className, 'form__form-group-plaintext')}
      {...tail}
    >
      {wrappedText && (
        <TextWrapper monospace={monospace}>{children}</TextWrapper>
      )}
      {!wrappedText && children}
    </div>
  );
};

Plaintext.propTypes = {
  /**
   * Override or extend the styles applied to the component.
   */
  className: PropTypes.string,
  /**
   * Child elements.
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
  /**
   * Fixed width font or not
   */
  monospace: PropTypes.bool,

  /**
   * Wrap text or not
   */
  wrappedText: PropTypes.bool,
};

Plaintext.defaultProps = {
  children: null,
  className: null,
  monospace: false,
  wrappedText: true,
};

export default Plaintext;
