import { dispatch } from 'd3-dispatch';

export class Dispatcher {
  _initDispatcher(...events) {
    this._availableEvents = events.reduce(
      (acc, key) => ({ ...acc, [key]: key }),
      {},
    );

    this._dispatcher = dispatch(...events);
  }

  get availableEvents() {
    return this._availableEvents || {};
  }

  _sendEvent(event, data) {
    if (this._dispatcher) {
      this._dispatcher.call(event, this, data);
    }
  }

  on(...args) {
    if (!this._dispatcher) {
      return args.length ? this : null;
    }

    const value = this._dispatcher.on(...args);
    return value === this._dispatcher ? this : value;
  }
}
