import { call, put } from 'redux-saga/effects';

import { Customer } from '@/models/Customer';
import RoutePaths from '@/models/RoutePaths';

import { actions as actionsGF } from '@/redux/globalFilters';
import { actions as toastActions } from '@/redux/toast';
import {
  createSelector,
  createSlice,
  startFetching as startFetchingOrigin,
  stopFetching,
  defaultReducers,
} from '@/redux/util';

import backendClient from '@/middleware/backendClient';

export const MAX_RECENT_CUSTOMERS = 5;

export const initialState = {
  isFetching: false,
  allFetched: false,
  allForTopBarMenuFetched: false,
  data: null, // {},
  error: '',
  meta: {
    shortname: null,
    errors: null,
  },
  customerTypes: {},
  customerSubscriptionTypes: {},
  customer: {},
  customersForTopBarMenu: [],
  samlProviderMetadataXml: null,
  samlProviderSettings: {},
  samlProvider: {},
  resellerSamlProvider: {},
  passwordPolicyTypes: [],
  passwordPolicy: [],
  sharedSecret: '',
};

let api;

const initApi = () => {
  if (!api) {
    api = backendClient();
  }
};

const url = '/customer';

const startFetching = (state) => {
  startFetchingOrigin(state);
  state.meta = {};
};

const slice = createSlice({
  name: 'customers',
  initialState,

  reducers: {
    ...defaultReducers,
    requestCustomerTypes: startFetching,
    requestCustomerTypesSuccess(state, { payload: customerTypes }) {
      stopFetching(state);
      state.customerTypes = customerTypes;
    },
    requestCustomerSubscriptionTypes: startFetching,
    requestCustomerSubscriptionTypesSuccess(
      state,
      { payload: customerSubscriptionTypes },
    ) {
      stopFetching(state);
      state.customerSubscriptionTypes = customerSubscriptionTypes;
    },

    fetch: (state) => {
      startFetching(state);
      state.allFetched = false;
    },
    fetchSuccess(state, { payload: { data } }) {
      stopFetching(state);
      state.allFetched = true;
      state.data = data.reduce((acc, item) => {
        acc[item.shortname] = new Customer(item);
        return acc;
      }, {});
    },

    fetchForTopBarMenu: (state) => {
      startFetching(state);
      state.allForTopBarMenuFetched = false;
    },
    fetchForTopBarMenuSuccess(state, { payload: customers }) {
      stopFetching(state);
      state.allForTopBarMenuFetched = true;
      state.customersForTopBarMenu = customers.reduce((acc, item) => {
        acc[item.shortname] = new Customer(item);
        return acc;
      }, {});
    },

    fetchByShortname: startFetching,
    fetchByShortnameSuccess(state, { payload: { data } }) {
      stopFetching(state);
      if (!state.data) {
        state.data = {};
      }
      state.data[data.shortname] = new Customer(data);
    },

    fetchCurrentCustomer: startFetching,
    fetchCurrentCustomerSuccess(state, { payload: { meta, data } }) {
      stopFetching(state);
      const fixedData = { ...data, hasFlow: meta.hasFlow };
      state.customer = new Customer(fixedData);
    },

    checkShortname: startFetching,
    checkShortnameSuccess(state, { payload: { data: shortname } }) {
      stopFetching(state);
      state.meta.shortname = shortname;
    },

    checkShortnameFail(
      state,
      {
        payload: {
          error: { message },
          shortname,
        },
      },
    ) {
      stopFetching(state);
      state.error = message;
      state.meta = {
        shortname,
        errors: {
          shortname: message,
        },
      };
    },

    impersonate: startFetching,
    impersonateSuccess(state, { payload: data }) {
      stopFetching(state);
      localStorage.setItem('impersonate', data.access_token);
      setTimeout(() => {
        document.location = `${RoutePaths.home}`;
      }, 10);
    },

    add: startFetching,
    addSuccess(state, { payload: { data } }) {
      stopFetching(state);
      if (!state.data) {
        state.data = {};
      }
      state.data[data.shortname] = new Customer(data);
    },

    update: startFetching,
    updateSuccess(
      state,
      {
        payload: {
          data: { meta, data },
          shortname,
        },
      },
    ) {
      stopFetching(state);
      if (!state.data) {
        state.data = {};
      }
      if (shortname) {
        delete state.data[shortname];
      }
      const fixedData = { ...data, hasFlow: meta.hasFlow };
      const customer = new Customer(fixedData);
      state.data[data.shortname] = customer;
      if (state.customer.id === customer.id) {
        state.customer = customer;
      }
    },

    remove: startFetching,
    removeSuccess(state, { payload: shortname }) {
      stopFetching(state);
      delete state.data?.[shortname];
    },

    /* ** SAML Provider Methods ** */
    requestSamlProviderMetadataXml: startFetching,
    requestSamlProviderMetadataXmlSuccess(
      state,
      { payload: samlProviderMetadataXml },
    ) {
      stopFetching(state);
      state.samlProviderMetadataXml = samlProviderMetadataXml;
    },

    requestSamlProviderSettings: startFetching,
    requestSamlProviderSettingsSuccess(
      state,
      { payload: samlProviderSettings },
    ) {
      stopFetching(state);
      state.samlProviderSettings = samlProviderSettings;
    },

    requestSamlProvider: startFetching,
    requestSamlProviderSuccess(state, { payload: samlProvider }) {
      stopFetching(state);
      state.samlProvider = samlProvider;
    },

    createSamlProvider: startFetching,
    createSamlProviderSuccess(state, { payload: samlProvider }) {
      stopFetching(state);
      state.samlProvider = samlProvider;
    },

    updateSamlProvider: startFetching,
    updateSamlProviderSuccess(state, { payload: samlProvider }) {
      stopFetching(state);
      state.samlProvider = samlProvider;
    },

    deleteSamlProvider: startFetching,
    deleteSamlProviderSuccess(state) {
      stopFetching(state);
      state.samlProvider = {};
    },

    requestResellerSamlProvider: startFetching,
    requestResellerSamlProviderSuccess(state, { payload: samlProvider }) {
      stopFetching(state);
      state.resellerSamlProvider = samlProvider;
    },

    /* ** Password Methods ** */
    requestPasswordReset: startFetching,
    requestPasswordResetSuccess: stopFetching,

    /* ** Password Policy Methods ** */
    requestPasswordPolicyTypes: startFetching,
    requestPasswordPolicyTypesSuccess(state, { payload: passwordPolicyTypes }) {
      stopFetching(state);
      state.passwordPolicyTypes = passwordPolicyTypes;
    },

    requestPasswordPolicy: startFetching,
    requestPasswordPolicySuccess(state, { payload: passwordPolicy }) {
      stopFetching(state);
      state.passwordPolicy = passwordPolicy;
    },

    setPasswordPolicy: startFetching,
    setPasswordPolicySuccess(state, { payload: passwordPolicy }) {
      stopFetching(state);
      state.passwordPolicy = passwordPolicy;
    },

    resetPasswordPolicyToDefault: startFetching,
    resetPasswordPolicyToDefaultSuccess(state, { payload: passwordPolicy }) {
      stopFetching(state);
      state.passwordPolicy = passwordPolicy;
    },

    /* ** API Shared Secret Methods ** */
    requestSharedSecret: startFetching,
    requestSharedSecretSuccess(state, { payload: sharedSecret }) {
      stopFetching(state);
      state.sharedSecret = sharedSecret;
    },

    regenSharedSecret: startFetching,
    regenSharedSecretSuccess(state, { payload: sharedSecret }) {
      stopFetching(state);
      state.sharedSecret = sharedSecret;
    },

    clearSharedSecret: (state) => {
      stopFetching(state);
      state.sharedSecret = '';
    },

    /* ** TOS ** */
    acceptTtou: startFetching,
    acceptTtouSuccess(state, { payload: { meta, data } }) {
      stopFetching(state);
      const fixedData = { ...data, hasFlow: meta.hasFlow };
      state.customer = new Customer(fixedData);
    },

    /* ** Reseller SSO ** */
    enableResellerSso: startFetching,
    enableResellerSsoSuccess(state, { payload: { meta, data } }) {
      stopFetching(state);
      const fixedData = { ...data, hasFlow: meta.hasFlow };
      const customer = new Customer(fixedData);
      state.data[data.shortname] = customer;
      if (state.customer.id === customer.id) {
        state.customer = customer;
      }
    },

    disableResellerSso: startFetching,
    disableResellerSsoSuccess(state, { payload: { meta, data } }) {
      stopFetching(state);
      const fixedData = { ...data, hasFlow: meta.hasFlow };
      const customer = new Customer(fixedData);
      state.data[data.shortname] = customer;
      if (state.customer.id === customer.id) {
        state.customer = customer;
      }
    },

    /* ** Common Methods ** */
    fail(state, { payload }) {
      defaultReducers.fail(state, { payload });
      state.meta.errors = payload?.errors;
    },
    cancelled: defaultReducers.cancel,
  },

  sagas: (actions) => ({
    [actions.requestCustomerTypes]: {
      * saga() {
        initApi();
        try {
          const { data } = yield call(api.get, `${url}s/types`);
          yield put(actions.requestCustomerTypesSuccess(data.data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error loading customer types',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.requestCustomerSubscriptionTypes]: {
      * saga() {
        initApi();
        try {
          const { data } = yield call(api.get, `${url}s/subscription-types`);
          yield put(actions.requestCustomerSubscriptionTypesSuccess(data.data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error loading customer subscription types',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.fetch]: {
      * saga({ payload: shortname }) {
        initApi();
        try {
          const { data } = yield call(
            api.get,
            `${url}s/${encodeURIComponent(shortname || '')}`,
          );
          yield put(actions.fetchSuccess(data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching customer data',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.fetchForTopBarMenu]: {
      * saga({ payload: shortname }) {
        initApi();
        try {
          const responce = yield call(
            api.get,
            `${url}s/${encodeURIComponent(shortname || '')}`,
          );
          yield put(actions.fetchForTopBarMenuSuccess(responce.data.data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching customer data',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.fetchByShortname]: {
      * saga({ payload: shortname }) {
        initApi();
        try {
          const response = yield call(api.get, `${url}/${shortname || ''}`);
          yield put(actions.fetchByShortnameSuccess(response.data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching customer data',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.fetchCurrentCustomer]: {
      * saga() {
        initApi();
        try {
          const response = yield call(api.get, `${url}`);
          yield put(actions.fetchCurrentCustomerSuccess(response.data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching customer data',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.checkShortname]: {
      * saga({ payload: shortname }) {
        initApi();

        const correct = (shortname || '').toLowerCase().trim();

        try {
          if (!correct) {
            throw new Error('Empty shortname is not allowed!');
          }

          const { data } = yield call(
            api.get,
            `${url}/check/shortname/${encodeURIComponent(correct)}`,
          );
          yield put(actions.checkShortnameSuccess(data));
        } catch (error) {
          yield put(
            actions.checkShortnameFail({
              shortname: correct,
              error,
            }),
          );
          yield put(
            toastActions.error({
              message: 'Error validating shortname',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.impersonate]: {
      * saga({ payload: shortname }) {
        initApi();
        try {
          const impersonateToken = localStorage.getItem('impersonate');
          const { data } = yield call(
            api.post,
            `${url}/${shortname}/impersonate`,
            { deleteSession: !!impersonateToken },
          );
          yield put(actionsGF.reset());
          yield put(actions.impersonateSuccess(data.data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error impersonating',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.add]: {
      * saga({ payload }) {
        initApi();
        try {
          const response = yield call(api.post, `${url}`, payload);
          yield put(actions.addSuccess(response.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Customer has been created',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              title: 'Customers',
              message: 'Error creating customer',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.update]: {
      * saga({
        payload: {
          shortname,
          data: { id, ...params },
          silent = false,
          enableResellerSso = false,
          disableResellerSso = false,
        },
      }) {
        initApi();
        try {
          delete params.meta;
          delete params.created;
          delete params.devices;
          delete params.vpcs;
          delete params.isRoot;
          delete params.useResellerSso;

          if (enableResellerSso) {
            yield call(api.put, `${url}/${shortname}/enable-reseller-sso`);
          }
          if (disableResellerSso) {
            yield call(api.put, `${url}/${shortname}/disable-reseller-sso`);
          }
          const response = yield call(api.put, `${url}/${shortname}`, params);
          yield put(actions.updateSuccess({ data: response.data, shortname }));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Customer has been updated',
              response,
              showWarningOnly: silent,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error updating customer',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.remove]: {
      * saga({ payload: shortname }) {
        initApi();
        try {
          const response = yield call(api.delete, `${url}/${shortname}`);
          yield put(actions.removeSuccess(shortname));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: `Customer '${shortname}' has been removed`,
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error deleting customer',
              details: error.message,
            }),
          );
        }
      },
    },

    /* ** SAML Provider Methods ** */
    [actions.requestSamlProviderMetadataXml]: {
      * saga({ payload: { silent = true, shortname } }) {
        initApi();
        try {
          const { data } = yield call(
            api.get,
            `/sso/${shortname}/metadata.xml`,
          );
          yield put(actions.requestSamlProviderMetadataXmlSuccess(data));
        } catch (error) {
          yield put(actions.fail(error));
          if (!silent) {
            yield put(
              toastActions.error({
                message: 'Error loading SAML provider metadata.xml',
                details: error.message,
              }),
            );
          }
        }
      },
    },

    [actions.requestSamlProviderSettings]: {
      * saga() {
        initApi();
        try {
          const { data } = yield call(api.get, `${url}/idp/saml/settings`);
          yield put(actions.requestSamlProviderSettingsSuccess(data.data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error loading SAML provider settings',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.requestSamlProvider]: {
      * saga() {
        initApi();
        try {
          const { data } = yield call(api.get, `${url}/idp/saml`);
          yield put(actions.requestSamlProviderSuccess(data.data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error loading SAML provider',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.createSamlProvider]: {
      * saga({ payload: samlProvider }) {
        initApi();
        const formData = new FormData();
        if (samlProvider.file) {
          formData.append('file', samlProvider.file);
        }
        if (samlProvider.resellerSso != null) {
          formData.append('resellerSso', samlProvider.resellerSso);
        }
        if (samlProvider.config != null) {
          formData.append('config', JSON.stringify(samlProvider.config));
        }
        if (samlProvider.userAttributeMappers) {
          formData.append(
            'userAttributeMappers',
            JSON.stringify(samlProvider.userAttributeMappers),
          );
        }
        if (samlProvider.userRoleMappers) {
          formData.append(
            'userRoleMappers',
            JSON.stringify(samlProvider.userRoleMappers),
          );
        }
        if (samlProvider.defaultUserRole) {
          formData.append('defaultUserRole', samlProvider.defaultUserRole);
        }

        try {
          const response = yield call(api.post, `${url}/idp/saml`, formData);
          yield put(actions.createSamlProviderSuccess(response.data.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'SAML Single Sign-On (SSO) is configured.',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error configuring SAML provider',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.updateSamlProvider]: {
      * saga({ payload: samlProvider }) {
        initApi();
        const formData = new FormData();
        if (samlProvider.file) {
          formData.append('file', samlProvider.file);
        }
        // if (samlProvider.resellerSso != null) {
        //   formData.append('resellerSso', samlProvider.resellerSso);
        // }
        if (samlProvider.config != null) {
          formData.append('config', JSON.stringify(samlProvider.config));
        }
        if (samlProvider.userAttributeMappers) {
          formData.append(
            'userAttributeMappers',
            JSON.stringify(samlProvider.userAttributeMappers),
          );
        }
        if (samlProvider.userRoleMappers) {
          formData.append(
            'userRoleMappers',
            JSON.stringify(samlProvider.userRoleMappers),
          );
        }
        if (samlProvider.defaultUserRole) {
          formData.append('defaultUserRole', samlProvider.defaultUserRole);
        }

        try {
          const response = yield call(api.put, `${url}/idp/saml`, formData);
          yield put(actions.updateSamlProviderSuccess(response.data.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'SAML Single Sign-on configuration is updated.',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error updating SAML Single Sign-on configuration',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.deleteSamlProvider]: {
      * saga() {
        initApi();
        try {
          const response = yield call(api.delete, `${url}/idp/saml`);
          yield put(actions.deleteSamlProviderSuccess());
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'SAML Single Sign-on configuration removed.',
              response,
              showWarningOnly: true,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error loading SAML provider',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.requestResellerSamlProvider]: {
      * saga() {
        initApi();
        try {
          const { data } = yield call(api.get, `${url}/idp/saml/reseller-sso`);
          yield put(actions.requestResellerSamlProviderSuccess(data.data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error loading Parent SSO',
              details: error.message,
            }),
          );
        }
      },
    },

    /* ** Password Methods ** */
    [actions.requestPasswordReset]: {
      * saga() {
        initApi();
        try {
          const response = yield call(
            api.post,
            `${url}/require-password-reset`,
          );
          yield put(actions.requestPasswordResetSuccess());
          yield put(
            toastActions.successWithAuditLogVerification({
              message:
                'All current users will required to reset their passwords the next time they login.',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error while requiring password reset.',
              details: error.message,
            }),
          );
        }
      },
    },

    /* ** Password Policy Methods ** */
    [actions.requestPasswordPolicyTypes]: {
      * saga() {
        initApi();
        try {
          const { data } = yield call(api.get, `${url}/password-policy/types`);
          yield put(actions.requestPasswordPolicyTypesSuccess(data.data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error loading password policy types',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.requestPasswordPolicy]: {
      * saga() {
        initApi();
        try {
          const { data } = yield call(api.get, `${url}/password-policy`);
          yield put(actions.requestPasswordPolicySuccess(data.data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error loading password policy',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.setPasswordPolicy]: {
      * saga({ payload: passwordPolicy }) {
        initApi();
        try {
          const response = yield call(
            api.put,
            `${url}/password-policy`,
            passwordPolicy,
          );
          yield put(actions.setPasswordPolicySuccess(response.data.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message:
                'Password policy is updated. If you would like to force users to adopt the new password policies, be sure to click the "Require password reset" button.',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error updating password policy',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.resetPasswordPolicyToDefault]: {
      * saga() {
        initApi();
        try {
          const response = yield call(
            api.put,
            `${url}/password-policy/reset-to-default`,
          );
          yield put(
            actions.resetPasswordPolicyToDefaultSuccess(response.data.data),
          );
          yield put(
            toastActions.successWithAuditLogVerification({
              message:
                'Password policy reset to default. If you would like to force users to adopt the new password policies, be sure to click the "Require password reset" button.',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error updating password policy',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.requestSharedSecret]: {
      * saga() {
        initApi();
        try {
          const response = yield call(api.get, `${url}/api-shared-secret`);
          yield put(actions.requestSharedSecretSuccess(response.data.data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error loading API Shared Secret',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.regenSharedSecret]: {
      * saga() {
        initApi();
        try {
          const response = yield call(api.put, `${url}/api-shared-secret`);
          yield put(actions.regenSharedSecretSuccess(response.data.data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error loading API Shared Secret',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.acceptTtou]: {
      * saga({ payload: shortname }) {
        initApi();
        try {
          const response = yield call(
            api.put,
            `${url}/${shortname}/accept-ttou`,
          );
          yield put(actions.acceptTtouSuccess(response.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Trial Terms Of Use accepted',
              response,
              showWarningOnly: true,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error accepting Trial Terms Of Use',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.enableResellerSso]: {
      * saga({ payload: shortname }) {
        initApi();
        try {
          const response = yield call(
            api.put,
            `${url}/${shortname}/enable-reseller-sso`,
          );
          yield put(actions.enableResellerSsoSuccess(response.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Parent SSO enabled',
              response,
              showWarningOnly: true,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error enabling Parent SSO',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.disableResellerSso]: {
      * saga({ payload: shortname }) {
        initApi();
        try {
          const response = yield call(
            api.put,
            `${url}/${shortname}/disable-reseller-sso`,
          );
          yield put(actions.disableResellerSsoSuccess(response.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Parent SSO disabled',
              response,
              showWarningOnly: true,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error disabling Parent SSO',
              details: error.message,
            }),
          );
        }
      },
    },
  }),

  selectors: (getState) => ({
    isFetching: createSelector(
      [getState],
      ({ isFetching }) => isFetching,
    ),

    areAllFetched: createSelector(
      [getState],
      (state) => state.allFetched,
    ),

    areAllForTopBarMenuFetched: createSelector(
      [getState],
      (state) => state.allForTopBarMenuFetched,
    ),

    getError: createSelector(
      [getState],
      (state) => state.error,
    ),

    getMeta: createSelector(
      [getState],
      (state) => state.meta,
    ),

    getCustomerTypes: createSelector(
      [getState],
      (state) => state.customerTypes,
    ),

    getCustomerSubscriptionTypes: createSelector(
      [getState],
      (state) => state.customerSubscriptionTypes,
    ),

    getCurrentCustomer: createSelector(
      [getState],
      ({ customer }) => customer,
    ),

    getCustomers: createSelector(
      [getState],
      (state) => state.data,
    ),

    getCustomersForTopBarMenu: createSelector(
      [getState],
      (state) => state.customersForTopBarMenu,
    ),

    getRetention: createSelector(
      [getState],
      ({ customer }) => Math.max(+(customer.retention || 0), +(customer.rollupRetention || 0), 7),
    ),

    getCollectorIp: createSelector(
      [getState],
      ({ customer: { ingestIp, ingestPort } }) => (ingestIp ? `${ingestIp}:${ingestPort || 0}` : 'N/A'),
    ),

    getSamlProviderMetadataXml: createSelector(
      [getState],
      ({ samlProviderMetadataXml }) => samlProviderMetadataXml,
    ),

    getSamlProviderSettings: createSelector(
      [getState],
      ({ samlProviderSettings }) => samlProviderSettings,
    ),

    getSamlProvider: createSelector(
      [getState],
      ({ samlProvider }) => samlProvider,
    ),

    getResellerSamlProvider: createSelector(
      [getState],
      ({ resellerSamlProvider }) => resellerSamlProvider,
    ),

    getPasswordPolicyTypes: createSelector(
      [getState],
      (state) => state.passwordPolicyTypes,
    ),

    getPasswordPolicy: createSelector(
      [getState],
      (state) => state.passwordPolicy,
    ),

    getSharedSecret: createSelector(
      [getState],
      (state) => state.sharedSecret,
    ),
  }),
});

// For convenience
export const { actions, selectors } = slice;

export default slice;
