import SimpleBar from 'simplebar-react';
import styled from 'styled-components';

const ScrollBar = styled(SimpleBar)`
  & .simplebar-scrollbar::before {
    background: ${(props) => props.theme.scrollBackgroundColor};
  }

  & .simplebar-track.simplebar-vertical {
    width: 8px;
  }

  & .simplebar-content {
    position: relative;
    min-height: 100%;
  }
`;

export default ScrollBar;
