import bigInt from 'big-integer';

const normalizeIPv6 = (ip) => {
  // Convert to lowercase
  ip = ip.trim().toLowerCase();

  // Replace double-colons with the appropriate number of zeros
  if (ip.includes('::')) {
    const numMissingBlocks = 8 - ip.split(':').filter(Boolean).length;
    const zeroBlocks = Array(numMissingBlocks).fill('0000').join(':');
    ip = ip.replace('::', `:${zeroBlocks}:`);
  }

  // Pad each block with leading zeros to a length of 4 characters
  ip = ip
    .split(':')
    .map((block) => block.padStart(4, '0'))
    .join(':');

  return ip;
};

const convertIpv6ToNumber = (ip) => {
  let num;
  try {
    const parts = [];
    normalizeIPv6(ip)
      .split(':')
      .forEach((it) => {
        let bin = parseInt(it, 16).toString(2);
        while (bin.length < 16) {
          bin = `0${bin}`;
        }
        parts.push(bin);
      });
    const bin = parts.join('');
    num = bigInt(bin, 2);
  } catch (e) {
    num = 0;
  }
  return num;
};

export { normalizeIPv6 };
export default convertIpv6ToNumber;
