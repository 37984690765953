/* eslint-disable max-len */
import PropTypes from '+prop-types';

import styled from 'styled-components';

const RtbhIcon = styled(({ className, size }) => (
  <svg
    className={className}
    height={size}
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <g>
      <g>
        <path
          className="title"
          d="M336,96c-43.106,0-82.584,15.504-113.184,41.23c25.931-58.966,83.266-101.002,150.837-104.918
C344.944,11.963,309.869,0,272,0C174.794,0,96,78.798,96,176c0,43.106,15.504,82.584,41.23,113.184
C78.264,263.253,36.229,205.917,32.312,138.346C11.963,167.056,0,202.13,0,240c0,97.206,78.798,176,176,176
c43.106,0,82.584-15.504,113.184-41.23c-25.931,58.966-83.266,101.002-150.838,104.918C167.056,500.037,202.13,512,240,512
c97.206,0,176-78.798,176-176c0-43.106-15.504-82.584-41.23-113.184c58.966,25.931,101.002,83.267,104.918,150.838
C500.037,344.944,512,309.87,512,272C512,174.794,433.202,96,336,96z M256,312c-30.928,0-56-25.072-56-56s25.072-56,56-56
s56,25.072,56,56S286.928,312,256,312z"
        />
      </g>
    </g>
  </svg>
))`
  .title {
    fill: ${({ theme }) => (theme.name === 'dark' ? '#FFFFFF' : '#000000')} !important;
  }
`;

RtbhIcon.propTypes = {
  size: PropTypes.number,
};

RtbhIcon.defaultProps = {
  size: 24,
};

export { RtbhIcon };
