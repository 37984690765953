import TechnicalColumns from '+components/Table/ReactTable/TechnicalColumns';

const pluginName = 'useTotalColumnSortBy';

const sortFn = (desc) => (a, b) => {
  return desc
    ? b?.leafRows?.length - a?.leafRows?.length
    : a?.leafRows?.length - b?.leafRows?.length;
};

// each row can contain a list of sub rows, we need to sort them as well
const sortSubRows = (rows, desc) => {
  return rows
    .map((row) => {
      if (row.subRows) {
        row.subRows = sortSubRows(row.subRows.sort(sortFn(desc)), desc);
      }
      return row;
    })
    .sort(sortFn(desc));
};

const useInstance = (instance) => {
  const {
    allColumns,
    rows,
    state: { sortBy },
  } = instance;

  const totalColumn = allColumns.find(
    (col) => col.id === TechnicalColumns.totalColumn,
  );
  if (!totalColumn) {
    return;
  }

  const totalColumnSortBy = sortBy.find((sort) => sort.id === totalColumn.id);
  if (!totalColumnSortBy) {
    return;
  }

  Object.assign(instance, {
    rows: sortSubRows(rows, totalColumnSortBy.desc),
  });
};

export const useTotalColumnSortBy = (hooks) => {
  hooks.useInstance.push(useInstance);
};

useTotalColumnSortBy.pluginName = pluginName;
