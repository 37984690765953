/* eslint-disable max-len */
import PropTypes from '+prop-types';
import { memo } from 'react';

const TdmIcon = memo(({ size, color }) => (
  <svg
    fill={color}
    width={size}
    height={size}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 28 24.72"
  >
    <polygon points="28 6.9 26.13 6.9 26.13 8.77 26.57 8.77 26.57 11.82 25.32 11.82 25.32 4.27 22.22 4.27 22.22 14.6 20.97 14.6 20.97 1.46 17.87 1.46 17.87 17.78 16.62 17.78 16.62 0 13.52 0 13.52 18.48 12.28 18.48 12.28 0 9.18 0 9.18 23.79 7.93 23.79 7.93 1.82 4.83 1.82 4.83 16.74 3.58 16.74 3.58 6.52 .48 6.52 .48 12.53 0 12.53 0 14.41 1.87 14.41 1.87 12.53 1.41 12.53 1.41 7.44 2.65 7.44 2.65 17.67 5.75 17.67 5.75 2.74 7 2.74 7 24.72 10.1 24.72 10.1 .93 11.35 .93 11.35 19.41 14.45 19.41 14.45 .93 15.7 .93 15.7 18.7 18.8 18.7 18.8 2.39 20.04 2.39 20.04 15.53 23.14 15.53 23.14 5.2 24.39 5.2 24.39 12.75 27.49 12.75 27.49 8.77 28 8.77 28 6.9" />
  </svg>
));

TdmIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

TdmIcon.defaultProps = {
  color: 'currentColor',
  size: 24,
};

export { TdmIcon };
