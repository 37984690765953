import styled, { css } from 'styled-components';

import TabOrigin from '@mui/material/Tab';
import TabsOrigin from '@mui/material/Tabs';

const defaultOrientation = 'horizontal'; // 'vertical' || 'horizontal'

const height = 32;

const Tabs = styled(TabsOrigin).attrs((props) => ({
  orientation: defaultOrientation,
  variant: 'scrollable',
  ...props,
}))`
  min-height: ${height}px;

  ${(props) => (props.orientation === 'horizontal'
    ? css`
          --width: 100%;
          --borderWidth: 2px;

          min-width: var(--width);
          max-width: var(--width);

          .MuiTabs-scroller {
            //.MuiButtonBase-root {
            //  border-top-left-radius: 4px;
            //  border-top-right-radius: 4px;
            //}

            .MuiTabs-flexContainer:after {
              content: '';
              width: 100%;
              height: unset;
              border-right: unset;
              border-bottom: var(--borderWidth) solid
                ${({ theme }) => theme.tabsBorderColor};
              margin-top: -var(--borderWidth);
              margin-right: unset;
            }
          }

          .MuiTabs-indicator {
            background-color: ${({ theme }) => theme.tabActiveBorderColor};
          }
        `
    : css`
          --width: 144px;
          --borderWidth: 2px;

          min-width: var(--width);
          max-width: var(--width);

          .MuiTabs-scroller {
            display: flex;
            .MuiTabs-flexContainer:after {
              content: '';
              width: unset;
              height: 100%;
              border-bottom: unset;
              border-right: var(--borderWidth) solid
                ${({ theme }) => theme.tabsBorderColor};
              margin-top: unset;
              margin-left: var(--borderWidth);
            }

            .MuiButtonBase-root {
              max-width: calc(var(--width) - var(--borderWidth));
              padding: unset;
              //border-top-left-radius: unset;
              //border-top-right-radius: unset;
            }
          }

          .MuiTabs-indicator {
            right: var(--borderWidth) !important;
          }

          .MuiTab-root {
            border-bottom: unset;
            border-right: var(--borderWidth) solid;
            border-color: ${({ theme }) => theme.tabDefaultBorderColor};
            min-width: calc(var(--width) - var(--borderWidth));
          }
        `)}
`;

const Tab = styled(TabOrigin).attrs({
  disableRipple: true,
})`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  font-family: 'Source Sans Pro', sans-serif;
  font-size: 13px;
  text-transform: unset;
  white-space: nowrap;

  border-bottom: 2px solid;
  color: ${({ theme }) => theme.tabDefaultTextColor};
  // background-color: ${({ theme }) => theme.tabDefaultBackgroundColor};
  border-color: ${({ theme }) => theme.tabDefaultBorderColor};

  height: ${height}px;
  min-height: ${height}px;
  min-width: unset;

  :hover:not(.Mui-selected) {
    color: ${({ theme }) => theme.tabHoverTextColor};
    border-color: ${({ theme }) => theme.tabHoverBorderColor};
    :before {
      content: '';
      z-index: -1;
      position: absolute;
      top: 2px;
      left: 0;
      width: 100%;
      height: 24px;
      border-radius: 12px;
      background-color: ${({ theme }) => theme.tabHoverBackground};
    }
  }

  &.Mui-selected {
    color: ${({ theme }) => theme.tabActiveTextColor};
    // we don't need to set border color for active tab because MUI using indicator
    // border-color: ${({ theme }) => theme.tabActiveBorderColor};

    :before {
      content: '';
      z-index: -1;
      position: absolute;
      top: 2px;
      left: 0;
      width: 100%;
      height: 24px;
      border-radius: 12px;
      background-color: ${({ theme }) => theme.tabActiveBackground};
    }
  }

  .mdi-icon {
    display: flex;
    align-items: center;
    margin-right: 5px;
    margin-bottom: unset;

    width: 15px;
    height: 15px;
  }
`;

const TabContent = styled.div`
  width: 100%;
  overflow: hidden;
`;

const TabsContainer = styled.div.attrs((props) => ({
  orientation: defaultOrientation,
  ...props,
}))`
  display: flex;
  flex-wrap: nowrap;
  flex: 1;
  flex-direction: ${(props) => (props.orientation === 'horizontal' ? 'column' : 'row')};

  ${Tabs} + ${TabContent} {
    margin-top: ${(props) => (props.orientation === 'horizontal' ? '20px' : '0')};
    margin-left: ${(props) => (props.orientation === 'horizontal' ? '0' : '20px')};
  }
`;

export { Tabs, Tab, TabContent, TabsContainer };

export default Tabs;
