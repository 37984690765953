import PropTypes from '+prop-types';
import { useCallback, useContext, useEffect, useRef } from 'react';

import classNames from 'classnames';

import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import ChevronUpIcon from 'mdi-react/ChevronUpIcon';

import Button, { ButtonVariants } from '+components/Button';

import { Context } from './Context';

const Toggle = (props) => {
  const { className, children, showIcon, onOpenOrClose, ...rest } = props;
  const { isOpen, setAnchor } = useContext(Context);
  const isOpenPrevRef = useRef(isOpen);

  const onClick = useCallback(
    (event) => {
      setAnchor((prev) => (prev ? null : event.currentTarget));
    },
    [],
  );

  const icon = isOpen ? (
    <ChevronUpIcon size={18} />
  ) : (
    <ChevronDownIcon size={18} />
  );
  const endIcon = children && showIcon ? icon : null;
  const iconInBody = !children && showIcon ? icon : null;

  useEffect(
    () => {
      if (isOpen !== isOpenPrevRef.current) {
        isOpenPrevRef.current = isOpen;
        onOpenOrClose?.(isOpen);
      }
    },
    [isOpen],
  );

  return (
    <Button
      className={classNames(className, 'dropdown-toggle', {
        'dropdown-toggle--open': isOpen,
      })}
      variant={ButtonVariants.contained}
      disableElevation
      {...rest}
      onClick={onClick}
      endIcon={endIcon}
    >
      {children}
      {iconInBody}
    </Button>
  );
};

Toggle.propTypes = {
  className: PropTypes.string,
  children: PropTypes.children,
  showIcon: PropTypes.bool,
  onOpenOrClose: PropTypes.func,
};

Toggle.defaultProps = {
  className: null,
  children: null,
  showIcon: true,
  onOpenOrClose: undefined,
};

export default Toggle;
