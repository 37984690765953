const tasks = {};

/**
 * implementation of setTimeout function but with using requestAnimationFrame
 * @param {function} fn - function to execute
 * @param {number} [delay=0] - delay in ms
 * @param args - additional arguments that will be passed to fn
 * @returns {number|undefined}
 */
export const setTimeoutRAF = (fn, delay = 0, ...args) => {
  const ms = Math.max(+delay, 0);

  if (typeof fn !== 'function') {
    return undefined;
  }

  const id = Math.floor(performance.now());

  let lastTime;

  const runner = () => {
    const time = Math.floor(performance.now());
    lastTime = lastTime ?? time;

    if (time - lastTime < ms) {
      tasks[id] = requestAnimationFrame(runner);
      return;
    }

    fn(...args);
  };

  runner();

  return id;
};

/**
 * stop execution of setTimeoutMicrotask
 * @param {number} id - id of a task that is returned of setTimeoutRAF
 */
export const clearTimeoutRAF = (id) => {
  if (!id || !tasks[id]) {
    return;
  }

  cancelAnimationFrame(tasks[id]);
  delete tasks[id];
};
