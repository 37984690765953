import styled from 'styled-components';

const Title = styled.div`
  font-weight: 600;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  pointer-events: all;

  .sub-account-icon {
    display: unset !important;
    transform: unset !important;
    color: ${({ theme }) => theme.Palette.danger};
    margin-right: 5px;
    vertical-align: middle;
  }
`;

export default Title;
