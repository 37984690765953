import styled from 'styled-components';

export default styled.div`
  position: relative;
  
  width: 420px;
  height: 100%;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.noDataPageImageBackground};

  background-image: url(${({ image }) => image});
  background-repeat: no-repeat;
  background-position: center ${({ imageOffsetY }) => (imageOffsetY ? `${imageOffsetY}px` : undefined)};
  background-size: contain;
`;
