import { Route, Routes } from 'react-router-dom';

import Dashboard from '@/pages/Dashboards/Dashboard';
import DashboardScheduleForm from '@/pages/Dashboards/DashboardScheduleForm';
import DashboardsManage from '@/pages/Dashboards/Manage';
import WidgetFormAdd from '@/pages/Dashboards/WidgetFormAdd';
import WidgetFormEdit from '@/pages/Dashboards/WidgetFormEdit';

import { CrumbRoute } from '+components/Breadcrumb';

const DashboardsRoutes = () => (
  <Routes>
    <Route index element={<DashboardsManage />} />

    <Route
      path=":dashboardId/schedule"
      element={(
        <CrumbRoute
          title="Dashboard Schedule"
          component={DashboardScheduleForm}
        />
      )}
    />

    <Route
      path=":dashboardId/add"
      element={<CrumbRoute title="Add Widget" component={WidgetFormAdd} />}
    />

    <Route
      path=":dashboardId/:widgetId/*"
      element={<CrumbRoute title="Edit Widget" component={WidgetFormEdit} />}
    />

    <Route path=":id" element={<Dashboard />} />
  </Routes>
);

export default DashboardsRoutes;
