import {
  UniversalCell,
  UniversalAggregatedCell,
} from '+components/Table/Cells';

/**
 * @param {Object} props
 * @param {string?} props.field - field name
 * @param {string?} props.accessor - accessor name
 * @param {string?} [props.description=''] - field description
 * @param {function?} [props.Cell] - cell data formatter
 * @return {{ Header, accessor, Cell: (function({value?: *}): JSX.Element), Description? }}
 */
export const BaseColumnFactory = (props) => {
  const {
    field: fieldProp,
    accessor,
    Aggregated: AggregatedProp,
    Cell: CellProp,
    description,
    ...rest
  } = props || {};

  // if fieldProp is not provided, accessor will be used as field name if accessor is a string
  const field = fieldProp ?? (typeof accessor === 'string' ? accessor : undefined);
  const Aggregated = AggregatedProp ?? UniversalAggregatedCell(field);
  const Cell = CellProp ?? UniversalCell(field);
  const Description = description && description !== 'NotMatchable' ? description : undefined;

  return {
    ...rest,
    Description,
    accessor: accessor ?? fieldProp,
    Aggregated,
    Cell,
    createdOnFactory: true,
  };
};
