import { useState, useCallback, useEffect, useContext, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import isEqual from 'lodash.isequal';

import { CustomType } from '@/models/CustomType';
import { DateTimeModes } from '@/models/DateTimeModes';
import SettingCategories from '@/models/SettingCategories';
import { TimeDuration } from '@/models/TimePeriods';

import { selectors as customerSelectors } from '@/redux/api/customer';
import { actions } from '@/redux/globalFilters';

import { FinalForm } from '+components/form/FinalForm';
import PortalContext from '+components/GlobalFilters/Context';
import useDateTimePeriodStack from '+hooks/useDateTimePeriodStack';
import useGlobalFilters from '+hooks/useGlobalFilters';
import usePortalSettingsValue from '+hooks/usePortalSettingsValue';
import useSessionStorage from '+hooks/useSessionStorage';
import useUIProperty from '+hooks/useUIProperty';
import { getNewRecentFiltersList } from '+utils/getNewRecentFilters';
import getNqlFieldName from '+utils/getNqlFieldName';

import FormBody from './components/FormBody';
import { paramsToUi } from './components/utils';
import withRealTimeController from './components/withRealTimeController';

const GlobalFiltersPanel = (props) => {
  const dispatch = useDispatch();

  const [filters] = useGlobalFilters();
  const [guest] = useUIProperty('guest');
  const [, { clear }] = useDateTimePeriodStack();
  const { portalInitialValues } = useContext(PortalContext);

  const customer = useSelector(customerSelectors.getCurrentCustomer);
  const retention = useSelector(customerSelectors.getRetention);

  const [recents, changeRecents] = usePortalSettingsValue(
    SettingCategories.ui,
    'globalFiltersRecents',
    [],
  );
  const [sessionRecents, setSessionRecents] = useSessionStorage(
    'globalFiltersRecents',
  );

  const [initialValues, setInitialValues] = useState({});

  const recentsRef = useRef();
  recentsRef.current = guest ? sessionRecents || recents : recents;

  const applyFilters = useCallback(
    (values) => {
      // TODO: Is it a bug or feature? We are saving to recent nql only for current context
      // but user could set nql for another context and it will be saved to recent filters
      const newRecents = getNewRecentFiltersList(
        values[`${getNqlFieldName(values.context)}[0]`],
        recentsRef,
        values.context,
        customer.shortname,
      );

      if (!guest) {
        changeRecents(newRecents);
      } else {
        setSessionRecents(newRecents);
      }

      let isDateTimeEqual;
      if (values.period.value === CustomType) {
        // from and to updating in filters each N seconds, so we can compare them only in custom mode
        isDateTimeEqual = filters.from === values.from
          && filters.to === values.to
          && filters.startIsMin === values.startIsMin
          && filters.endIsNow === values.endIsNow
          && filters.period.type === values.period.type
          && filters.period.value === values.period.value;
      } else {
        isDateTimeEqual = filters.period.type === values.period.type
          && filters.period.value === values.period.value;
      }

      if (!(isDateTimeEqual && filters.realtime === values.realtime)) {
        clear();
      }

      let source = values;

      if (filters.dateTimeMode === DateTimeModes.realtime) {
        if (values.realtime) {
          source = filters;
        }
      } else if (!isDateTimeEqual) {
        values.realtime = false;
      }

      const now = Date.now();

      const state = {
        ...values,
        period: {
          type: source.period.type,
          value: source.period.value,
        },
        from:
          source.period.type === CustomType
          && source.startIsMin
          && source === values
            ? now - TimeDuration.day * retention + 1e3
            : source.from,
        to:
          source.period.type === CustomType
          && source.endIsNow
          && source === values
            ? now
            : source.to,
        startIsMin: source.period.type === CustomType && !!source.startIsMin,
        endIsNow: source.period.type === CustomType && !!source.endIsNow,
      };

      dispatch(actions.changeFilter(state));
    },
    [filters, customer, retention],
  );

  const onSubmit = useCallback(
    async (values) => {
      applyFilters(values);
      return null;
    },
    [applyFilters],
  );

  useEffect(
    () => {
      setInitialValues((prev) => {
        const next = paramsToUi({
          ...filters,
          ...portalInitialValues,
        });
        return isEqual(prev, next) ? prev : next;
      });
    },
    [filters, portalInitialValues],
  );

  return (
    <FinalForm
      {...props}
      initialValues={initialValues}
      onSubmit={onSubmit}
      component={FormBody}
      validateOnBlur
      keepDirtyOnReinitialize
      focusOnFields={false}
    />
  );
};

export default withRealTimeController(GlobalFiltersPanel);
