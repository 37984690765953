import { getId } from '../extractors';
import { WorkerEvents } from './WorkerEvents';

class Simulation {
  constructor() {
    this._worker = new Worker(
      /* webpackChunkName: 'simulation_layout_worker' */ new URL(
        './layout.worker.js',
        import.meta.url,
      ),
      { type: 'module' },
    );

    this._prepareNode = (node) => ({
      id: getId(node),
      x: node.x,
      y: node.y,
      fx: node.fx,
      fy: node.fy,
      forceGroup: this._groupBy?.(node),
      forceRadius: this._radius?.(node),
    });

    this._mergeNode = (node) => {
      const { id, x, y } = node;
      const found = this._nodesById.get(id);
      if (found) {
        found.x = x;
        found.y = y;
      }
    };

    this._worker.onmessage = this._onMessage.bind(this);
  }

  destroy() {
    this._worker.terminate();
  }

  radius(...args) {
    if (args.length === 0) {
      return this._radius;
    }

    const [radius] = args;

    this._radius = radius;
    return this;
  }

  groupBy(...args) {
    if (args.length === 0) {
      return this._groupBy;
    }

    const [groupBy] = args;

    this._groupBy = groupBy;
    return this;
  }

  nodes(...args) {
    if (args.length === 0) {
      this._nodes = this._nodes || [];
      return this._nodes;
    }

    const [nodes] = args;

    this._nodes = nodes || [];
    this._nodesById = new Map((nodes || []).map((node) => [getId(node), node]));
    return this;
  }

  links(...args) {
    if (args.length === 0) {
      this._links = this._links || [];
      return this._links;
    }

    const [links] = args;

    this._links = links || [];
    (links || []).forEach((link) => {
      const { source, target } = link;

      if (typeof source !== 'object') {
        link.source = this._nodesById.get(source);
      }

      if (typeof target !== 'object') {
        link.target = this._nodesById.get(target);
      }
    });
    return this;
  }

  sync() {
    const nodes = this._nodes.map(this._prepareNode);

    const links = (this._links || []).map((link) => ({
      source: getId(link.source),
      target: getId(link.target),
    }));

    this._worker.postMessage(
      WorkerEvents.data({
        nodes,
        links,
      }),
    );
    return this;
  }

  updateNode(node) {
    this._worker.postMessage(WorkerEvents.updateNode(this._prepareNode(node)));
    return this;
  }

  updateNodes() {
    const nodes = this._nodes.map(this._prepareNode);
    this._worker.postMessage(WorkerEvents.updateNodes(nodes));
    return this;
  }

  switchAutoStop(on) {
    this._worker.postMessage(WorkerEvents.switchAutoStop(on));
    return this;
  }

  stop() {
    this._worker.postMessage(WorkerEvents.stop());
    return this;
  }

  restart(alpha) {
    this._worker.postMessage(WorkerEvents.restart(alpha));
    return this;
  }

  onTick(fn) {
    this._onTick = fn;
    return this;
  }

  _onMessage(event) {
    const { data, type } = event?.data || {};

    if (type === `${WorkerEvents.tick}`) {
      const { nodes } = data;
      if (this._nodesById.size > 0) {
        nodes.forEach(this._mergeNode);
      }
      this._onTick?.();
    }
  }
}

export default Simulation;
