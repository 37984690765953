import styled from 'styled-components';

const Value = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  font-weight: 600;

  &:empty::before {
    content: '—';
  }

  table {
    margin: -5px;
  }
`;

export default Value;
