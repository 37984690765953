import styled from 'styled-components';

const Row = styled.div`
  display: flex;
  padding: 0.2em;
  min-height: 2em;
  justify-content: ${({ $center }) => $center && 'center'};
  align-items: center;
`;

export default Row;
