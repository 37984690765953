/* eslint-disable react/prop-types */
import CellWrapper from './CellWrapper';

const unknown = 'Unknown';

const CellOwnerAs = ({ field, value, original, hideUnderline }) => (
  <CellWrapper
    customer={original?.customer}
    title={`${field}.number — ${value?.number}`}
    field={field}
    value={value}
    hideUnderline={hideUnderline}
  >
    <span>{value?.org || unknown}</span>
  </CellWrapper>
);

export default CellOwnerAs;
