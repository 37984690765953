/* eslint-disable max-len */
import {
  Fragment,
  useState,
  useMemo,
  useCallback,
  useEffect,
  useContext,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ColorTypes } from '@/models/ColorTypes';

import {
  actions as customerActions,
  selectors as customerSelectors,
} from '@/redux/api/customer';

import Alert from '+components/Alert';
import Button, { ButtonVariants } from '+components/Button';
import ConfirmModal from '+components/ConfirmModal';
import FieldsSection from '+components/form/FieldsSection';
import {
  Label,
  Group,
  FieldContainer,
  Description,
} from '+components/form/FormField';
import Toggle from '+components/form/Toggle';
import { Col } from '+components/Layout';

import Context from '../Context';
import PasswordPolicyForm from './components/PasswordPolicyForm';

const PasswordSection = () => {
  const dispatch = useDispatch();

  const { layout, permissions, isFetching } = useContext(Context);

  const passwordPolicyTypes = useSelector(
    customerSelectors.getPasswordPolicyTypes,
  );
  const passwordPolicy = useSelector(customerSelectors.getPasswordPolicy);

  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [showPasswordPolicyModal, setShowPasswordPolicyModal] = useState(false);
  const [showNewPasswordPolicyForm, setShowNewPasswordPolicyForm] = useState(false);

  const isPasswordPolicyCustomized = useMemo(
    () => {
      if (!passwordPolicyTypes.length || !passwordPolicy.length) {
        return false;
      }
      const passwordPolicyHash = passwordPolicy.reduce(
        (acc, el) => ({ ...acc, [el.name]: el }),
        {},
      );
      return passwordPolicyTypes.some(
        (el) => (passwordPolicyHash[el.name]?.value || null) !== el.defaultValue,
      );
    },
    [passwordPolicyTypes, passwordPolicy],
  );

  const onResetPassword = useCallback(
    () => {
      setShowResetPasswordModal(true);
    },
    [],
  );

  const onResetPasswordCancel = useCallback(
    () => {
      setShowResetPasswordModal(false);
    },
    [],
  );

  const onResetPasswordConfirm = useCallback(
    () => {
      setShowResetPasswordModal(false);
      dispatch(customerActions.requestPasswordReset());
    },
    [],
  );

  const onPasswordPolicyChange = useCallback(
    () => {
      setShowPasswordPolicyModal(true);
    },
    [],
  );

  const onPasswordPolicyChangeCancel = useCallback(
    () => {
      setShowPasswordPolicyModal(false);
    },
    [],
  );

  const onPasswordPolicyChangeConfirm = useCallback(
    () => {
      if (isPasswordPolicyCustomized) {
        dispatch(customerActions.resetPasswordPolicyToDefault());
        setShowNewPasswordPolicyForm(false);
      } else {
        setShowNewPasswordPolicyForm((prevValue) => !prevValue);
      }
    },
    [isPasswordPolicyCustomized],
  );

  const onPasswordPolicySubmit = useCallback(
    (values) => {
      const passwordPolicyHash = values.reduce(
        (acc, el) => ({ ...acc, [el.name]: el }),
        {},
      );
      const isNewPasswordPolicyCustomized = passwordPolicyTypes.some(
        (el) => (passwordPolicyHash[el.name]?.value || null) !== el.defaultValue,
      );

      if (!isPasswordPolicyCustomized && !isNewPasswordPolicyCustomized) {
        setShowNewPasswordPolicyForm(false);
        return;
      }

      if (isPasswordPolicyCustomized && !isNewPasswordPolicyCustomized) {
        dispatch(customerActions.resetPasswordPolicyToDefault());
        setShowNewPasswordPolicyForm(false);
        return;
      }

      dispatch(customerActions.setPasswordPolicy(values));
    },
    [isPasswordPolicyCustomized, passwordPolicyTypes],
  );

  useEffect(
    () => {
      dispatch(customerActions.requestPasswordPolicyTypes());
      dispatch(customerActions.requestPasswordPolicy());
    },
    [],
  );

  return (
    <Fragment>
      <Col
        className="form form--horizontal"
        width={`calc(${layout.marginLeft} + ${layout.width})`}
        item
        container={false}
      >
        <Group>
          <Label disabled={!permissions?.update}>Resetting Passwords</Label>
          <FieldContainer>
            <Button
              variant={ButtonVariants.outlined}
              disabled={!permissions?.update}
              onClick={onResetPassword}
            >
              Require Password Reset
            </Button>
          </FieldContainer>
          <Description style={{ marginTop: '10px' }}>
            Require all current users to reset their passwords the next time
            they login. This will not log users out of their current sessions.
          </Description>
        </Group>

        <Group>
          <Label disabled={isFetching || !permissions?.update}>
            Password Policy
          </Label>
          <FieldContainer>
            <Toggle
              name="isPasswordPolicyCustomized"
              checked={isPasswordPolicyCustomized || showNewPasswordPolicyForm}
              type="checkbox"
              checkedLabel="Custom"
              disabled={isFetching || !permissions?.update}
              onChange={onPasswordPolicyChange}
            />
          </FieldContainer>
          <Description>
            Set custom password restrictions policy for all users.
          </Description>
        </Group>
      </Col>

      {(isPasswordPolicyCustomized || showNewPasswordPolicyForm) && (
        <Col
          marginLeft={layout.marginLeft}
          width={layout.width}
          item
          container={false}
        >
          <FieldsSection label="Netography Password Policy Settings">
            <Alert severity="info">
              Changes not affect existing user passwords, but will be enforce on
              new users and password update requests.
            </Alert>

            <PasswordPolicyForm
              passwordPolicy={passwordPolicy}
              passwordPolicyTypes={passwordPolicyTypes}
              mode="edit"
              item="Password Policy"
              cancelButtonText={isPasswordPolicyCustomized ? 'Reset' : undefined}
              disabled={isFetching || !permissions?.update}
              onCancel={onPasswordPolicyChange}
              onSubmit={onPasswordPolicySubmit}
            />
          </FieldsSection>
        </Col>
      )}

      {showResetPasswordModal && (
        <ConfirmModal
          item="current users passwords"
          confirmButtonText="Reset"
          whyAsking="This action will require all current users to reset their passwords the next time they login. This will not log users out of their current sessions."
          onToggle={onResetPasswordCancel}
          onConfirm={onResetPasswordConfirm}
          isOpen
        />
      )}

      {showPasswordPolicyModal && (
        <ConfirmModal
          item="password policy"
          confirmButtonText={
            isPasswordPolicyCustomized || showNewPasswordPolicyForm
              ? 'Reset'
              : 'Customize'
          }
          confirmButtonColor={ColorTypes.primary}
          whyAsking={
            isPasswordPolicyCustomized || showNewPasswordPolicyForm
              ? ''
              : 'Currently there are no password restrictions for your users.  Enabling password policies will give you control over settings such as expiration, length and special characters requirements.'
          }
          onToggle={onPasswordPolicyChangeCancel}
          onConfirm={onPasswordPolicyChangeConfirm}
          isOpen
        />
      )}
    </Fragment>
  );
};

export default PasswordSection;
