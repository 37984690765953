import isFunction from 'lodash.isfunction';

import AggregatorTypes from '+components/Table/Aggregators/AggregatorTypes';

import { UniversalCell } from '../UniversalCell';

const UniversalAggregatedCell = (field, formatter) => (props) => {
  const { column: { aggregate } = {}, value } = props ?? {};

  if (!aggregate || aggregate === AggregatorTypes.none) {
    return null;
  }

  if (aggregate === AggregatorTypes.unique) {
    // TODO: Improve in the future if needed
    return value?.join(', ') ?? null;
  }

  if (aggregate === AggregatorTypes.count) {
    return value;
  }

  return isFunction(formatter)
    ? formatter(value)
    : UniversalCell(field)({ value });
};

export default UniversalAggregatedCell;
