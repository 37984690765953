import PropTypes from '+prop-types';
import { Fragment, memo } from 'react';

import styled from 'styled-components';

import { FlowMetricSettings } from '@/models/MetricSettings';

const LegendContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 4px 8px;
  position: absolute;
  right: 5px;
  bottom: 10px;

  font-size: 10px;

  background: ${({ theme }) => theme.fdcLegendBackground};
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: flex-end;
`;

const LegendSeparator = styled.div`
  display: flex;
  border-bottom: 1px solid rgba(80, 80, 80, 0.3);
`;

const CircleBox = styled.div`
  min-width: 32px;
  height: 32px;
  position: relative;
`;

const ColorCircle = styled.span`
  background: ${({ theme }) => theme.fdcLegendShape};
  width: ${({ $radius }) => `calc(${$radius} * 2)` || '10px'};
  height: ${({ $radius }) => `calc(${$radius} * 2)` || '10px'};
  border-radius: ${({ $radius }) => $radius || '5px'};
  opacity: 0.3;

  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
`;

const ValueTitle = styled.div`
  position: absolute;
  right: 5px;
  bottom: ${({ $bottom }) => $bottom || 0};

  :after {
    content: '';
    position: absolute;
    top: 50%;
    right: -23px;
    width: 20px;
    border-bottom: 1px dotted ${({ theme }) => theme.colorText};
    opacity: 0.5;
  }
`;

const LineBox = styled(CircleBox)``;

const ColorLine = styled.div`
  position: absolute;
  width: 30px;
  bottom: ${({ $bottom }) => $bottom || 0};
  right: 0;
  border-bottom: ${({ $height }) => $height || 0} solid
    ${({ theme }) => theme.fdcLegendShape};
  opacity: 0.3;
`;

const LineValueTitle = styled(ValueTitle)`
  :after {
    right: -8px;
    width: 5px;
  }
`;

const ColorMapContainer = styled.div`
  position: relative;
  width: 100%;
  height: 25px;
`;

const pos = (i, length) => (i ? (i / (length - 1)) * 100 : 0);

const ColorMapGradient = styled.div`
  width: 100%;
  height: 10px;
  background: linear-gradient(
    90deg,
    ${({ $colors }) => $colors
    .map(({ color }, i) => `${color} ${pos(i, $colors.length)}%`)
    .join(', ')}
  );
`;

const ColorMapValue = styled.div`
  position: absolute;
  top: 15px;
  left: ${({ $pos }) => ($pos === 100 ? null : `${$pos}%`)};
  right: ${({ $pos }) => ($pos === 100 ? 0 : null)};
  transform: translate(
    ${({ $pos }) => (!$pos || $pos === 100 ? 0 : '-50%')},
    0
  );
  color: ${({ theme }) => theme.colorText};

  :before {
    content: '';
    position: absolute;
    top: -5px;
    height: 5px;
    width: 1px;
    background: ${({ theme }) => theme.colorText};
    left: ${({ $pos }) => ($pos === 100 ? null : `${$pos}%`)};
    right: ${({ $pos }) => ($pos === 100 ? 0 : null)};
    transform: translate(
      ${({ $pos }) => (!$pos || $pos === 100 ? 0 : '-50%')},
      0
    );
  }
`;

const Legend = (props) => {
  const {
    colorsMap,
    nodeRadiusRange,
    linkWidthRange,
    nodeSizeTitle,
    linkWidthTitle,
    formatter,
    metricTitle,
  } = props;

  const isShown = (colorsMap || []).length > 0 || nodeRadiusRange || linkWidthRange;

  return (
    isShown && (
      <LegendContainer>
        {nodeRadiusRange && (
          <Fragment>
            <LegendItem>
              Node Size ({nodeSizeTitle || 'Links Count'}):
            </LegendItem>
            <LegendItem>
              <CircleBox>
                <ValueTitle $bottom="23px">{nodeRadiusRange.max}</ValueTitle>
                <ValueTitle $bottom="13px">
                  {Math.max(
                    Math.floor(nodeRadiusRange.avg),
                    nodeRadiusRange.min,
                  )}
                </ValueTitle>
                <ValueTitle $bottom="3px">{nodeRadiusRange.min}</ValueTitle>
              </CircleBox>
              <CircleBox>
                <ColorCircle $radius="15px" />
                <ColorCircle $radius="10px" />
                <ColorCircle $radius="5px" />
              </CircleBox>
            </LegendItem>
            <LegendItem>
              <CircleBox>
                <ValueTitle $bottom="3px">selected</ValueTitle>
              </CircleBox>
              <CircleBox>
                <ColorCircle $radius="10px" />
                <ColorCircle
                  $radius="5px"
                  style={{ bottom: '5px', opacity: 0.5 }}
                />
              </CircleBox>
            </LegendItem>
            <LegendSeparator />
          </Fragment>
        )}
        {linkWidthRange && (
          <Fragment>
            <LegendItem>
              Link Width ({linkWidthTitle || 'Flow Records Count'}):
            </LegendItem>
            <LegendItem>
              <LineBox>
                <LineValueTitle $bottom="20px">
                  {linkWidthRange.max}
                </LineValueTitle>
                <LineValueTitle $bottom="9px">
                  {Math.max(Math.floor(linkWidthRange.avg), linkWidthRange.min)}
                </LineValueTitle>
                <LineValueTitle $bottom="-2px">
                  {linkWidthRange.min}
                </LineValueTitle>
              </LineBox>

              <LineBox>
                <ColorLine $bottom="24px" $color="#61BBD9" $height="8px" />
                <ColorLine $bottom="14px" $color="#61BBD9" $height="5px" />
                <ColorLine $bottom="5px" $color="#61BBD9" $height="1px" />
              </LineBox>
            </LegendItem>
            <LegendSeparator />
          </Fragment>
        )}
        {!!(colorsMap || []).length && (
          <Fragment>
            <LegendItem>Color ({metricTitle || 'Metric'}):</LegendItem>
            <ColorMapContainer>
              {colorsMap.map((item, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <ColorMapValue key={i} $pos={pos(i, colorsMap.length)}>
                  {formatter?.(item.value) ?? item.value}
                </ColorMapValue>
              ))}
              <ColorMapGradient $colors={colorsMap} />
            </ColorMapContainer>
          </Fragment>
        )}
      </LegendContainer>
    )
  );
};

export const propTypes = {
  colorsMap: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ),
  nodeRadiusRange: PropTypes.shape({
    min: PropTypes.number.isRequired,
    avg: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
  }),
  linkWidthRange: PropTypes.shape({
    min: PropTypes.number.isRequired,
    avg: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
  }),
  nodeSizeTitle: PropTypes.string,
  linkWidthTitle: PropTypes.string,
  formatter: PropTypes.func,
  metricTitle: PropTypes.string,
};

Legend.propTypes = propTypes;

Legend.defaultProps = {
  colorsMap: null,
  nodeRadiusRange: null,
  linkWidthRange: null,
  nodeSizeTitle: null,
  linkWidthTitle: null,
  formatter: FlowMetricSettings.bits.yAxisFormatter,
  metricTitle: FlowMetricSettings.bits.chartTitle,
};

export default memo(Legend);
