import { createGlobalStyle } from 'styled-components';

import { ScrollBarMixin } from '+theme/mixins/scrollBarMixin';
import { propsSelectors as globalFiltersTheme } from '+theme/slices/globalFilters';

const PickerGlobalStyle = createGlobalStyle`
  .dateTimePickerStyle:not(#fake_id) {
    z-index: 9999;

    .MuiPaper-root {
      border-radius: 4px !important;
      overflow: hidden;
      background: ${globalFiltersTheme.selectBackground};
    }

    .MuiPickersArrowSwitcher-spacer {
      font-size: 14px;
      color: ${({ theme }) => theme.colorText};
      &.Mui-disabled {
        color: ${({ theme }) => theme.colorText};
        opacity: 0.2;
      }
    }

    .MuiPaper-root > div {
      overflow: hidden;
      font-size: 14px;
      position: relative;
      //max-height: 375px;
      color: ${({ theme }) => theme.colorText};
      background-color: ${({ theme }) => theme.dateTimePickerCalendarBackground};

      > div:nth-child(2) {
        margin-bottom: 50px;
      }

      .MuiClockPicker-arrowSwitcher {
        margin-top: 3px;
      }

      .MuiCalendarPicker-viewTransitionContainer {
        ${ScrollBarMixin};
      }

      .PrivatePickersSlideTransition-root {
        min-height: 230px;
      }

      .MuiButtonBase-root {
        &.Mui-disabled {
          color: rgba(0, 0, 0, 0.26);
          background-color: transparent;
        }
      }

      .MuiPickersCalendarHeader-yearSelectionSwitcher {
        display: none;
      }

      .MuiPickersArrowSwitcher-iconButton {
        background-color: transparent;
      }

      .MuiCalendarPicker-root,
      .MuiClockPicker-root {
        position: relative;
      }

      .MuiIconButton-root {
        color: inherit;

        &:hover {
          background-color: rgba(0, 0, 0, 0.2);
        }
      }

      .MuiIconButton-sizeMedium {
        display: flex;
        margin-bottom: 3px;
        height: 24px;
        width: 24px;

        .MuiTouchRipple-root {
          display: none;
        }

        &.Mui-disabled {
          color: rgba(0, 0, 0, 0.26);
          background-color: transparent;
        }
      }

      .-bKxbdk {
        cursor: default;
        .-ffulXt {
          font-size: 13px;
        }
      }

      .-foZFzu {
        cursor: default;
      }

      .MuiCalendarPicker-root div[role='presentation'], //.sc-bBrHrO
      .MuiTypography-caption,
      .MuiPickersDay-dayWithMargin,
      .MuiPickersDay-dayWithMargin.Mui-disabled,
      .MuiTypography-subtitle1,
      .MuiTypography-body1,
      .MuiTypography-body2,
      .PrivatePickersYear-yearButton {
        font-size: 1em;
        font-family: inherit;
        color: ${({ theme }) => theme.colorText};
      }

      .MuiPickersDay-today:not(.Mui-selected) {
        border-color: ${globalFiltersTheme.selectOptionFocused};
      }

      .MuiTypography-caption {
        font-size: 0.75em;
      }

      .MuiTypography-body2 {
        font-size: 0.875em;
      }

      //.MuiTypography-caption {
      //  opacity: 0.64;
      //}

      .MuiPickersDay-dayWithMargin,
      .MuiTypography-body1,
      .MuiTypography-body2 {
        opacity: 0.83;
        background-color: transparent;

        &.Mui-disabled {
          opacity: 0.34;
        }

        &:hover {
          background-color: rgba(0, 0, 0, 0.2);
        }

        &.Mui-selected,
        &:focus.Mui-selected {
          font-weight: bold;
          color: ${({ theme }) => theme.iconButtonText};
          background-color: ${({ theme }) => theme.iconButtonBackground};
        }
      }

      .MuiPickersClockNumber-clockNumberSelected {
        .MuiTypography-body1,
        .MuiTypography-body2 {
          font-weight: bold;
          color: ${({ theme }) => theme.iconButtonText};
        }
      }

      .Mui-selected {
        font-weight: bold;
        color: ${({ theme }) => theme.iconButtonText};
      }

      .MuiPickersClockNumber-clockNumberDisabled {
        .MuiTypography-body1,
        .MuiTypography-body2 {
          opacity: 0.34;
        }
      }

      .PrivatePickersYear-yearButton {
        &:hover {
          background-color: rgba(0, 0, 0, 0.2);
        }

        &.Mui-selected,
        &:focus.Mui-selected {
          font-weight: bold;
          color: ${({ theme }) => theme.iconButtonText};
          background-color: ${({ theme }) => theme.iconButtonBackground};
        }
      }

      .MuiClockPicker-root > div:nth-child(2) > div:nth-child(1) > div:nth-child(3) > div, // .sc-ftvSup
      .MuiPickersClockPointer-thumb {
        color: ${({ theme }) => theme.iconButtonText};
        background-color: ${({ theme }) => theme.iconButtonBackground};
      }

      .MuiClockPicker-root > div:nth-child(2) > div:nth-child(1) > div:nth-child(2), // .sc-iqcoie
      .MuiClockPicker-root > div:nth-child(2) > div:nth-child(1) > div:nth-child(3), // .sc-iBkjds
      .MuiPickersClockPointer-noPoint,
      .MuiPickersClock-pin,
      .MuiPickersClockPointer-pointer {
        background-color: ${globalFiltersTheme.selectOptionFocused};
      }

      .MuiPickersClock-amButton,
      .MuiPickersClock-pmButton {
        .MuiTypography-caption {
          opacity: 0.84;
        }
      }

      .MuiClockPicker-root > div:nth-child(2) button { // .sc-evZas, .sc-crXcEl
        bottom: 8px;
      }

      .cHGkTL,
      .bQMUkI {
        background-color: ${globalFiltersTheme.selectOptionFocused};

        .MuiTypography-caption {
          opacity: 1;
          font-weight: bold;
          transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          color: ${({ theme }) => theme.colorFieldBackground};
        }

        :hover .MuiTypography-caption {
          color: ${({ theme }) => theme.colorText};
        }
      }
    }

    .MuiCalendarPicker-root {
      .sc-fLlhyt {
        padding: 0 15px;
      }
    }

    .MuiYearPicker-root {
      margin: 0 15px;
    }
  }
`;

export default PickerGlobalStyle;
