import PropTypes from '+prop-types';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import classNames from 'classnames';
import styled from 'styled-components';

const Div = styled.div``;
const ExternalLink = styled.a``;

const Item = styled((props) => {
  const {
    className,
    icon,
    title,
    description,
    path,
    external,
    disabled,
    onClick,
    testId,
  } = props;

  const Component = useMemo(
    () => {
      if (!path) {
        return Div;
      }
      return external ? ExternalLink : Link;
    },
    [path, external],
  );

  const componentProps = useMemo(
    () => {
      if (!path) {
        return {};
      }
      return external ? { href: path, target: '_blank' } : { to: path };
    },
    [path, external],
  );

  return (
    <Component
      className={classNames(className, 'topbar__link', {
        clickable: !!path || !!onClick,
        disabled,
      })}
      {...componentProps}
      onClick={onClick}
      data-testid={testId}
    >
      {icon && <div className="topbar__link-icon">{icon}</div>}
      {title && <div className="topbar__link-title">{title}</div>}
      {description && (
        <div className="topbar__link-description">{description}</div>
      )}
    </Component>
  );
})`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 19px;
  transition: all 0.3s;
  height: 40px;
  width: 100%;
  position: relative;
  cursor: default;

  &.clickable {
    cursor: pointer;
    :hover {
      background-color: ${({ theme }) => theme.topbarMenuBackgroundHover};
    }
  }

  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  .topbar__link-icon {
    font-size: 13px;
    line-height: 13px;
    color: ${({ theme }) => theme.topbarMenuTextColor};
  }

  .topbar__link-title,
  .topbar__link-description {
    cursor: inherit;
    font-size: 13px;
    line-height: 16px;
    white-space: nowrap;
  }

  .topbar__link-title {
    color: ${({ theme }) => theme.topbarMenuTextColor};
  }

  .topbar__link-description {
    color: ${({ theme }) => theme.topbarMenuTextColorSecondary};
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

Item.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.shape({}),
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.element,
  ]).isRequired,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.element,
  ]),
  path: PropTypes.string,
  external: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  testId: PropTypes.string,
};

Item.defaultProps = {
  className: '',
  icon: null,
  description: null,
  path: null,
  external: false,
  disabled: false,
  onClick: null,
  testId: null,
};

export default Item;
