import { LRUMap } from 'lru_map';
import { put } from 'redux-saga/effects';

import { ContextTypes } from '@/models/ContextTypes';

import {
  createSlice,
  createSelector,
  tryCancelSaga,
  takeLatestOrEvery,
} from '@/redux/util';

import backendClient from '@/middleware/backendClient';

export const FetchStates = {
  error: 'error',
  init: 'init',
  fetching: 'fetching',
  empty: 'empty',
  success: 'success',
};

const setStatus = (statusKey, status) => (state) => {
  state[statusKey] = status;
  if (status !== FetchStates.error) {
    state.error = null;
  }
  return state;
};

const initialState = {
  docStatus: FetchStates.init,
  docs: null,
  thresholdTermsStatus: FetchStates.init,
  thresholdTerms: null,
  suggestionsStatus: FetchStates.init,
  suggestionsData: {},
  error: null,
  validationStatus: FetchStates.init,
  validationErrors: {},
  parseStatus: {},
  parseData: {},
  parseErrors: {},
  presetsStatus: FetchStates.init,
};

const suggestionCache = new LRUMap(40);
const validationCache = new LRUMap(40);
const parseCache = new LRUMap(40);

const fixId = (id) => id || '_';

const getUrl = (path) => `/nql-complete/${path}`;

let api;

const initApi = () => {
  if (!api) {
    api = backendClient();
  }
};

const slice = createSlice({
  name: 'nqlSuggest',
  initialState,

  reducers: {
    fetchDocs: setStatus('docStatus', FetchStates.fetching),
    fetchThresholdTerms: setStatus(
      'thresholdTermsStatus',
      FetchStates.fetching,
    ),
    fetchSuggestions: setStatus('suggestionsStatus', FetchStates.fetching),
    validate: setStatus('validationStatus', FetchStates.fetching),
    parse: (state, { payload: { id } }) => {
      delete state.parseErrors[fixId(id)];
      state.parseStatus[fixId(id)] = FetchStates.fetching;
    },
    fetchPresets: setStatus('presetsStatus', FetchStates.fetching),
    addPreset: setStatus('presetsStatus', FetchStates.fetching),
    updatePreset: setStatus('presetsStatus', FetchStates.fetching),
    deletePreset: setStatus('presetsStatus', FetchStates.fetching),
    docsSuccess(state, { payload: { data: { data } = {} } = {} }) {
      state.docs = data || {};
      setStatus('docStatus', FetchStates.success)(state);
    },
    thresholdTermsSuccess(state, { payload: { data } = {} }) {
      state.thresholdTerms = data || {};
      setStatus('thresholdTermsStatus', FetchStates.success)(state);
    },
    suggestionsSuccess(state, { payload: { data, cacheKey, id } }) {
      state.suggestionsData[fixId(id)] = data;
      suggestionCache.set(cacheKey, data);
      setStatus('suggestionsStatus', FetchStates.success)(state);
    },
    clearSuggestions(state, { payload }) {
      delete state.suggestionsData[payload];
      suggestionCache.clear();
    },
    clearMultipleSuggestions(state, { payload }) {
      payload.forEach((id) => {
        delete state.suggestionsData[fixId(id)];
      });
      suggestionCache.clear();
    },
    validateSuccess(state, { payload: { key, id } }) {
      delete state.validationErrors[fixId(id)];
      validationCache.set(key, true);
      setStatus('validationStatus', FetchStates.success)(state);
    },
    validateError(state, { payload: { message, id } }) {
      state.validationErrors[fixId(id)] = message;
      setStatus('validationStatus', FetchStates.error)(state);
    },
    clearValidationError(state, { payload: id }) {
      delete state.validationErrors[fixId(id)];
    },
    parseSuccess(state, { payload: { id, key, data, skipCache } }) {
      if (!skipCache) {
        state.parseData[fixId(id)] = data;
        parseCache.set(key, data);
      }
      state.parseStatus[fixId(id)] = FetchStates.success;
    },
    parseError(state, { payload: { id, message } }) {
      state.parseErrors[fixId(id)] = message;
      state.parseStatus[fixId(id)] = FetchStates.error;
    },
    clearParseError(state, { payload: id }) {
      delete state.parseErrors[fixId(id)];
      delete state.parseStatus[fixId(id)];
    },
    presetsSuccess(state, { payload: { data: { data } = {} } = {} }) {
      const contexts = (data || []).reduce((acc, item) => {
        const context = acc[item.context] || (acc[item.context] = {});

        context[item.id] = item;

        return acc;
      }, {});

      Object.entries(contexts).forEach(([key, value]) => {
        state[`presets_${key}`] = value;
      });
    },
    addPresetSuccess(state, { payload: { data: { data } = {} } = {} }) {
      const { id, context } = data || {};
      if (!id || !context) {
        return;
      }

      const key = `presets_${context}`;

      state[key] = {
        ...state[key],
        [id]: data,
      };
    },
    deletePresetSuccess(state, { payload }) {
      const { id, context } = payload || {};
      if (!id || !context) {
        return;
      }

      const key = `presets_${context}`;

      delete state[key][id];
    },
    error(state, { payload: { message, statusKey } }) {
      state.error = message;
      setStatus(statusKey, FetchStates.error)(state);
    },
    noop: () => {},
  },

  sagas: (actions) => ({
    * [actions.fetchDocs]() {
      yield tryCancelSaga(
        'get',
        {
          successAction: actions.docsSuccess,
          * error(error) {
            yield put(
              actions.error({
                message: error.message,
                statusKey: 'docStatus',
              }),
            );
          },
        },
        getUrl('suggest-docs'),
      );
    },
    * [actions.fetchThresholdTerms]() {
      yield tryCancelSaga(
        'post',
        {
          * success({ data: { data } = {} } = {}) {
            yield put(actions.thresholdTermsSuccess({ data }));
          },
          * error(error) {
            yield put(
              actions.error({
                message: error.message,
                statusKey: 'thresholdTermsStatus',
              }),
            );
          },
        },
        getUrl('suggest'),
        {
          text: '',
          caretPos: 0,
          fieldType: ContextTypes.thresholdFlow,
        },
      );
    },
    [actions.fetchSuggestions]: {
      taker: takeLatestOrEvery,
      * saga({ payload: { id, text, caretPos, fieldType } }) {
        const cacheKey = `${id} + ${text} + ${caretPos} + ${fieldType}`;
        const cacheData = suggestionCache.get(cacheKey);

        // Don't repeat requests that are made often
        if (cacheData) {
          yield put(
            actions.suggestionsSuccess({ data: cacheData, cacheKey, id }),
          );
          return;
        }

        yield tryCancelSaga(
          'post',
          {
            * success({ data: { data } = {} } = {}) {
              yield put(actions.suggestionsSuccess({ data, cacheKey, id }));
            },
            * error(error) {
              yield put(
                actions.error({
                  message: error.message,
                  statusKey: 'suggestionsStatus',
                }),
              );
            },
          },
          getUrl('suggest'),
          { text, caretPos, fieldType },
        );
      },
    },

    [actions.validate]: {
      * saga({ payload: { text, context: fieldType, id, promise } }) {
        const fixedId = fixId(id);
        const key = `${(text || '').trim()}_${fieldType}`;
        const cacheData = validationCache.get(key);
        const successPayload = { key, id: fixedId };

        if (!(text || '').trim()) {
          promise.resolve();
          yield put(actions.validateSuccess(successPayload));
          return;
        }

        if (cacheData) {
          promise.resolve();
          yield put(actions.validateSuccess(successPayload));
          return;
        }

        yield tryCancelSaga(
          'post',
          {
            * success() {
              promise.resolve();
              yield put(actions.validateSuccess(successPayload));
            },
            * error(error) {
              promise.resolve(error.message);
              yield put(
                actions.validateError({
                  message: error.message,
                  id: fixedId,
                }),
              );
            },
            * cancel() {
              promise.reject();
              yield put(actions.noop());
            },
          },
          getUrl('validate'),
          { text, fieldType },
        );
      },
    },

    * [actions.parse]({ payload: { text, context: fieldType, id } }) {
      initApi();
      const fixedId = fixId(id);
      const fixedText = (text || '').trim();
      const key = `${fixedText}_${fieldType}_${fixedId}`;
      const cacheData = parseCache.get(key);
      const successPayload = { key, id: fixedId, data: cacheData };

      if (!fixedText) {
        successPayload.skipCache = true;
        yield put(actions.parseSuccess(successPayload));
        return;
      }

      if (cacheData) {
        yield put(actions.parseSuccess(successPayload));
        return;
      }

      yield tryCancelSaga(
        'post',
        {
          * success({ data: { data } = {} } = {}) {
            successPayload.data = data;
            yield put(actions.parseSuccess(successPayload));
          },
          * error(error) {
            yield put(
              actions.parseError({
                message: error.message,
                id: fixedId,
              }),
            );
          },
        },
        getUrl('parse'),
        { text, fieldType },
      );
    },

    * [actions.fetchPresets]({ payload }) {
      yield tryCancelSaga(
        'get',
        {
          successAction: actions.presetsSuccess,
          * error(error) {
            yield put(
              actions.error({
                message: error.message,
                statusKey: 'presetsStatus',
              }),
            );
          },
        },
        getUrl(`presets/${payload}`),
      );
    },
    * [actions.addPreset]({ payload }) {
      yield tryCancelSaga(
        'post',
        {
          successAction: actions.addPresetSuccess,
          * error(error) {
            yield put(
              actions.error({
                message: error.message,
                statusKey: 'presetsStatus',
              }),
            );
          },
          toasts: {
            success: `Preset "${payload.title}" has been added`,
            error: 'Error adding preset',
          },
        },
        getUrl('presets'),
        payload,
      );
    },
    * [actions.updatePreset]({ payload }) {
      yield tryCancelSaga(
        'put',
        {
          successAction: actions.addPresetSuccess,
          * error(error) {
            yield put(
              actions.error({
                message: error.message,
                statusKey: 'presetsStatus',
              }),
            );
          },
          toasts: {
            success: `Preset "${payload.title}" has been updated`,
            error: 'Error updating preset',
          },
        },
        getUrl(`presets/${payload.id}`),
        payload,
      );
    },
    * [actions.deletePreset]({ payload }) {
      yield tryCancelSaga(
        'delete',
        {
          * success() {
            yield put(actions.deletePresetSuccess(payload));
          },
          * error(error) {
            yield put(
              actions.error({
                message: error.message,
                statusKey: 'presetsStatus',
              }),
            );
          },
          toasts: {
            success: `Preset ${payload.title} has been removed`,
            error: 'Error removing preset',
          },
        },
        getUrl(`presets/${payload.id}`),
      );
    },
  }),

  selectors: (getState) => ({
    getSuggestionsData: (id) => createSelector(
      [getState],
      (state) => state.suggestionsData[fixId(id)],
    ),
    getMultipleSuggestionsData: (ids) => createSelector(
      [getState],
      (state) => ids.map((id) => state.suggestionsData[id]),
    ),
    getSuggestionsStatus: createSelector(
      [getState],
      (state) => state.suggestionsStatus,
    ),
    getDocStatus: createSelector(
      [getState],
      (state) => state.docStatus,
    ),
    getDocs: createSelector(
      [getState],
      (state) => state.docs,
    ),
    getThresholdTerms: createSelector(
      [getState],
      (state) => state.thresholdTerms,
    ),
    getValidationStatus: createSelector(
      [getState],
      (state) => state.validationStatus,
    ),
    getValidationError: (id) => createSelector(
      [getState],
      (state) => state.validationErrors[fixId(id)],
    ),
    getPresets: (context) => createSelector(
      [getState],
      (state) => state[`presets_${context}`],
    ),
    getParseStatus: (id) => createSelector(
      [getState],
      (state) => state.parseStatus[fixId(id)],
    ),
    getParseData: (id) => createSelector(
      [getState],
      (state) => state.parseData[fixId(id)],
    ),
    getParseError: (id) => createSelector(
      [getState],
      (state) => state.parseErrors[fixId(id)],
    ),
  }),
});

export const { actions, selectors } = slice;

export default slice;
