import PropTypes from '+prop-types';
import { useContext, useEffect, useMemo } from 'react';

import { makeId } from '+utils/general';

import Context from './Context';

const Portal = (props) => {
  const { children, initialValues, isFiltered } = props;
  const {
    setAdditionalFilters,
    setPortalInitialValues,
    setPortalIsFiltered,
    remove,
  } = useContext(Context);

  const id = useMemo(
    () => makeId(),
    [],
  );

  useEffect(
    () => {
      setAdditionalFilters(id, children);
    },
    [children],
  );

  useEffect(
    () => {
      setPortalInitialValues(id, initialValues);
    },
    [initialValues],
  );

  useEffect(
    () => {
      setPortalIsFiltered(id, isFiltered);
    },
    [isFiltered],
  );

  useEffect(
    () => () => {
      remove(id);
    },
    [],
  );

  return null;
};

Portal.propTypes = {
  children: PropTypes.children.isRequired,
  initialValues: PropTypes.oneOfType([PropTypes.shape(), PropTypes.func]),
  isFiltered: PropTypes.bool,
};

Portal.defaultProps = {
  initialValues: null,
  isFiltered: false,
};

export default Portal;
