import { Children, cloneElement, Fragment, isValidElement } from 'react';

const sortByOrder = ({ order: a }, { order: b }) => a - b;

/**
 * @param children
 * @param {Function} propsMaker
 * @return {[]|null}
 */
const childrenMap = (children, propsMaker) => {
  let nodes = children.type === Fragment
    ? children.props.children
    : children;
  nodes = Array.isArray(nodes) ? nodes : [nodes];

  const map = new Map();

  const { fns, valid } = nodes.reduce((acc, node, i) => {
    const key = typeof node === 'function' ? 'fns' : 'valid';
    acc[key].push(node);
    map.set(node, i);
    return acc;
  }, { fns: [], valid: [] });

  const fn = typeof propsMaker === 'function' ? propsMaker : (props) => props;

  const result = [
    ...fns.map((item) => {
      const order = map.get(item);
      map.delete(item);

      return {
        order,
        value: item(fn({}, order)),
      };
    }),
    ...Children.map(valid.filter((child) => !!child), (child) => {
      const { props } = child;
      const order = map.get(child);
      map.delete(child);
      const newProps = fn(props, order);

      return {
        order,
        value: isValidElement(child) ? cloneElement(child, newProps) : child,
      };
    }),
  ];

  return result.sort(sortByOrder).map(({ value }) => value);
};

export default childrenMap;
