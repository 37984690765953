import PropTypes from '+prop-types';

import flattenObject from '+utils/flattenObject';

import RowWithIcon from './components/RowWithIcon';
import Table from './components/Table';

const TableView = ({ rowData, hideIcon }) => (
  <Table cellSpacing="1" cellPadding="2">
    <tbody>
      {Object.entries(flattenObject(rowData)).map(([name, value]) => (
        <RowWithIcon
          key={name}
          name={name}
          value={value}
          hideIcon={hideIcon}
          rootItem
        />
      ))}
    </tbody>
  </Table>
);

TableView.propTypes = {
  rowData: PropTypes.oneOfType([PropTypes.array, PropTypes.shape()]).isRequired,
  hideIcon: PropTypes.bool,
};

TableView.defaultProps = {
  hideIcon: false,
};

export default TableView;
