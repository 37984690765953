import PropTypes from '+prop-types';
import { Fragment, useMemo } from 'react';

import upperFirst from 'lodash.upperfirst';

import { ColorTypes } from '@/models/ColorTypes';

import Button, { ButtonVariants } from '+components/Button';
import ButtonGroup from '+components/ButtonGroup';
import { Dropdown, DropdownItem } from '+components/Dropdown';
import Form from '+components/form/Form';
import { ModalBody, ModalFooter, ModalHeader } from '+components/Modal';

const defaultTitleTemplate = (mode, item) => (
  <Fragment>
    {upperFirst(mode || '')} {item}
  </Fragment>
);

const FormBody = (props) => {
  const {
    className,
    material,
    horizontal,
    labelOnTop,
    mode,
    item,
    children,
    titleTemplate,
    cancelButtonText,
    confirmButtonText,
    confirmButtonColor,
    secondaryButtonText,
    secondaryButtonDisabled,
    additionalActions,
    disabled,
    onToggle,
    onSecondary,
    deleteButtonText,
    deleteButtonDisabled,
    deleteButtonHidden,
    onDelete,
    handleSubmit,
    submitting,
  } = props;

  const normalizedTitle = useMemo(
    () => (typeof titleTemplate === 'function'
      ? titleTemplate(mode, item)
      : titleTemplate),
    [titleTemplate, mode, item],
  );

  return (
    <Form
      className={className}
      material={material}
      horizontal={horizontal}
      labelOnTop={labelOnTop}
      onSubmit={handleSubmit}
    >
      <ModalHeader onClose={onToggle}>{normalizedTitle}</ModalHeader>

      <ModalBody>{children}</ModalBody>

      <ModalFooter>
        <ButtonGroup
          variant={ButtonVariants.contained}
          disabled={disabled || submitting}
        >
          {confirmButtonText && (
            <Button
              type="submit"
              color={confirmButtonColor}
              testId="confirm-button"
            >
              {confirmButtonText}
            </Button>
          )}

          {additionalActions && (
            <Dropdown disabled={disabled}>
              {additionalActions.map(({ text, onClick }) => (
                <DropdownItem key={text} onClick={onClick}>
                  {text}
                </DropdownItem>
              ))}
            </Dropdown>
          )}
        </ButtonGroup>

        {secondaryButtonText && onSecondary && (
          <Button
            variant={ButtonVariants.outlined}
            onClick={onSecondary}
            disabled={disabled || secondaryButtonDisabled}
            testId="secondary-button"
          >
            {secondaryButtonText}
          </Button>
        )}

        {cancelButtonText && onToggle && (
          <Button
            variant={ButtonVariants.outlined}
            onClick={onToggle}
            testId="cancel-button"
          >
            {cancelButtonText}
          </Button>
        )}

        {!deleteButtonHidden && deleteButtonText && onDelete && (
          <Button
            className="button-delete"
            variant={ButtonVariants.outlined}
            onClick={onDelete}
            disabled={deleteButtonDisabled}
            testId="delete-button"
          >
            {deleteButtonText}
          </Button>
        )}
      </ModalFooter>
    </Form>
  );
};

export const propTypes = {
  /**
   * Override or extend the styles applied to the component.
   */
  className: PropTypes.string,
  /**
   * If true, form will have material-ui className.
   */
  material: PropTypes.bool,
  /**
   * If true, form will be displayed in horizontal style.
   */
  horizontal: PropTypes.bool,
  /**
   * If true, label will be displayed on top of field.
   */
  labelOnTop: PropTypes.bool,
  /**
   * Form mode.
   */
  mode: PropTypes.oneOfType([
    PropTypes.oneOf(['add', 'edit']),
    PropTypes.string,
  ]),
  /**
   * Item to add/edit.
   */
  item: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.func,
    PropTypes.element,
  ]),
  /**
   * The same initialValues object passed to reduxForm to initialize the form data.
   */
  initialValues: PropTypes.shape({}),
  /**
   * Form fields.
   */
  children: PropTypes.children,
  /**
   * Title template function.
   */
  titleTemplate: PropTypes.children,
  /**
   * Text for the chancel button.
   */
  cancelButtonText: PropTypes.string,
  /**
   * Text for the confirm button.
   */
  confirmButtonText: PropTypes.string,
  /**
   * Confirm button color.
   */
  confirmButtonColor: PropTypes.oneOf(Object.values(ColorTypes)),
  /**
   * Text for the additional button.
   */
  secondaryButtonText: PropTypes.string,
  /**
   * Boolean to control the state secondary button.
   */
  secondaryButtonDisabled: PropTypes.bool,
  /**
   * Text for the delete button.
   */
  deleteButtonText: PropTypes.string,
  /**
   * Boolean to control the state of delete button.
   */
  deleteButtonDisabled: PropTypes.bool,
  /**
   * If true, delete button will be hidden.
   */
  deleteButtonHidden: PropTypes.bool,
  /**
   * Additional actions that will be displayed as confirm button dropdown.
   */
  additionalActions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.node,
      onClick: PropTypes.func,
    }),
  ),
  /**
   * Boolean to control the state confirm button.
   */
  disabled: PropTypes.bool,
  /**
   * A callback fired when the modal toggle.
   */
  onToggle: PropTypes.func,
  /**
   * A callback fired when additional button clicked.
   */
  onSecondary: PropTypes.func,
  /**
   * A callback fired when delete button clicked.
   */
  onDelete: PropTypes.func,
};

export const defaultProps = {
  className: null,
  material: false,
  horizontal: true,
  labelOnTop: false,
  mode: 'add',
  item: null,
  initialValues: {},
  children: null,
  titleTemplate: defaultTitleTemplate,
  cancelButtonText: 'Cancel',
  confirmButtonText: 'Save',
  confirmButtonColor: ColorTypes.primary,
  secondaryButtonText: undefined,
  secondaryButtonDisabled: false,
  deleteButtonText: 'Delete',
  deleteButtonDisabled: false,
  deleteButtonHidden: true,
  additionalActions: undefined,
  disabled: false,
  onSecondary: undefined,
  onToggle: undefined,
  onDelete: undefined,
};

FormBody.propTypes = {
  ...propTypes,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};
FormBody.defaultProps = {
  ...defaultProps,
};

export default FormBody;
