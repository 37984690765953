import PropTypes from '+prop-types';
import { useCallback } from 'react';

import FinalForm from '+components/form/FinalForm';
import Modal from '+components/Modal';
import useLoadingIndicator from '+hooks/useLoadingIndicator';

import FormBody, {
  defaultProps as defaultPropsOriginal,
  propTypes as propTypesOriginal,
} from './FormBody';

/**
 * Form modal dialog.
 */
const FormModal = (props) => {
  const {
    isOpen,
    loading,
    toggleOnSubmit,
    onSubmit,
    onToggle,
    testId,
    ...tail
  } = props;

  useLoadingIndicator(loading);

  const onFormSubmit = useCallback(
    (...args) => {
      if (toggleOnSubmit && onToggle) {
        onToggle();
      }
      if (onSubmit) {
        return onSubmit(...args);
      }
      return null;
    },
    [onSubmit, toggleOnSubmit, onToggle],
  );

  return (
    <Modal isOpen={isOpen} onClose={onToggle} data-testid={testId}>
      <FinalForm
        keepDirtyOnReinitialize
        {...tail}
        onToggle={onToggle}
        onSubmit={onFormSubmit}
        component={FormBody}
        validateOnBlur
      />
    </Modal>
  );
};

const propTypes = {
  ...propTypesOriginal,
  /**
   * Boolean to control the state of the popover.
   */
  isOpen: PropTypes.bool,
  /**
   * If true, loading overlay will be active.
   */
  loading: PropTypes.bool,
  /**
   * If true, modal will toggle on submit action.
   */
  toggleOnSubmit: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  testId: PropTypes.string,
};

const defaultProps = {
  ...defaultPropsOriginal,
  isOpen: false,
  loading: false,
  toggleOnSubmit: false,
  testId: 'form-modal',
};

FormModal.propTypes = propTypes;
FormModal.defaultProps = defaultProps;

export { FormModal, propTypes, defaultProps };

export default FormModal;
