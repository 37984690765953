import { ContextTypes } from '@/models/ContextTypes';
import RoutePaths from '@/models/RoutePaths';

export const getRecordDetailsUrl = ({ context, id, customer }) => {
  const search = new URLSearchParams();
  if (customer) {
    search.append('customer', customer);
  }
  switch (context) {
    case ContextTypes.flow:
      return `${RoutePaths.searchFlow}/${id}?${search.toString()}`;
    case ContextTypes.dns:
      return `${RoutePaths.searchDns}/${id}?${search.toString()}`;
    case ContextTypes.alerts:
      return `${RoutePaths.searchEvents}/${id}?${search.toString()}`;
    case ContextTypes.blocks:
      return `${RoutePaths.searchBlocks}/${id}?${search.toString()}`;
    case ContextTypes.audit:
      return `${RoutePaths.searchAuditLogs}/${id}?${search.toString()}`;
    default:
      return null;
  }
};
