import PropTypes from '+prop-types';
import { memo, useCallback, useEffect, useRef, useMemo } from 'react';
import * as HSX from 'react-jsx-highcharts';

import classNames from 'classnames';
import styled from 'styled-components';

import useExportingFilename from '+hooks/useExportingFilename';
import { getHighchartsColor } from '+utils/getHighchartsColor';

import useDefaultPropsHSX from './common/defaultPropsHSX';
import { Highcharts } from './common/highcharts';
import { defaultColorVariety, lang } from './common/utils';

const defaultFormatterTooltip = function () {
  const { series, point } = this;
  return `
    <table style="width: 100%">
      <td>
        <b>${point.value}</b>
      </td>
      <tr>
        <td>
          ${series.xAxis?.axisTitle?.textStr}:
        </td>
        <td style="text-align:right">
          <b>${point.x}</b>
        </td>
      </tr>
      <tr>
        <td>
          ${series.yAxis?.axisTitle?.textStr}:
        </td>
        <td style="text-align:right">
          <b>${point.y}</b>
        </td>
      </tr>
    </table>
 `;
};

const plotOptions = {
  series: {
    stickyTracking: false,
  },
  bubble: {
    animation: false,
    shadow: false,
    dataLabels: { style: { textShadow: false } },
    marker: {
      fillOpacity: 1,
    },
  },
};

const defaultColorIndexMin = 0;
const defaultColorIndexMax = 6;

const BubbleChart = styled((props) => {
  const {
    className,
    title,
    subtitle,
    data,
    metric,
    xfield,
    yfield,
    xAxis,
    yAxis,
    exporting,
    loading,
    width,
    height,
    colorVariety,
    colorIndexMin,
    colorIndexMax,
    hideTitle,
    hideSubtitle,
    onPlotClick,
    minColor,
    maxColor,
    xAxisLabelRotation,
    spacingLeft,
    marginBottom,
    alternateGridColor,
    formatterTooltip,
    tooltipStickOnContact,
    xAxisReversed: initialXAxisReversed,
  } = props;

  const chartRef = useRef(null);

  const exportingFilename = useExportingFilename(title);

  const defaultProps = useDefaultPropsHSX({ exporting });

  const xField = xfield;
  const xAxisSeries = xAxis;
  const xAxisReversed = initialXAxisReversed;

  const yField = yfield;
  const yAxisSeries = yAxis;

  const colorAxis = useMemo(
    () => {
      const _minColor = minColor
      || getHighchartsColor({
        paletteClassName: 'p',
        paletteIndex: colorVariety,
        colorClassName: 'highcharts-color',
        colorIndex: colorIndexMin ?? defaultColorIndexMin,
      });
      const _maxColor = maxColor
      || getHighchartsColor({
        paletteClassName: 'p',
        paletteIndex: colorVariety,
        colorClassName: 'highcharts-color',
        colorIndex: colorIndexMax ?? defaultColorIndexMax,
      });
      return {
        min: 0,
        minColor: _minColor,
        maxColor: _maxColor,
        className: 'color-axis',
      };
    },
    [colorVariety, colorIndexMin, colorIndexMax, minColor, maxColor],
  );

  const onChartCallback = useCallback(
    (chart) => {
      chartRef.current = chart;
    },
    [],
  );

  // Dynamically set export file name (for cases when chart title changing dynamically in widgets)
  // @see: https://api.highcharts.com/highcharts/exporting.filename
  // @see: https://www.highcharts.com/forum/viewtopic.php?t=31299
  useEffect(
    () => {
      const chart = chartRef.current;
      if (chart) {
        chart.options.exporting.filename = exportingFilename;
      }
    },
    [chartRef.current, exportingFilename],
  );

  // Workaround to dynamically change colorAxis
  // @see https://www.highcharts.com/forum/viewtopic.php?t=35199
  useEffect(
    () => {
      const chart = chartRef.current;
      if (chart) {
        chart.colorAxis[0].update(colorAxis);
      }
    },
    [chartRef.current, colorAxis],
  );

  return (
    <HSX.HighchartsProvider Highcharts={Highcharts}>
      <HSX.HighchartsChart
        {...defaultProps}
        className={classNames(className, 'bubble-chart', {
          short: width > 360 && height < 180,
          ultrashort: width <= 360 && height < 180,
        })}
        plotOptions={{
          ...plotOptions,
          series: {
            ...plotOptions.series,
            events: { click: onPlotClick },
          },
        }}
        callback={onChartCallback}
        colorAxis={colorAxis}
        time={{ useUTC: true }}
      >
        {!!title && !hideTitle && (
          <HSX.Title align="left" useHTML margin={0}>
            {title}
          </HSX.Title>
        )}

        {!!subtitle && !hideSubtitle && (
          <HSX.Subtitle useHTML>{subtitle}</HSX.Subtitle>
        )}

        <HSX.Loading isLoading={loading}>{lang.loading}</HSX.Loading>

        <HSX.Tooltip
          useHTML
          formatter={formatterTooltip ?? defaultFormatterTooltip}
          metric={metric}
          shadow={false}
          animation={false}
          stickOnContact={tooltipStickOnContact}
          className={tooltipStickOnContact ? 'allEvents' : undefined}
        />

        <HSX.Chart
          width={width}
          height={height}
          marginTop={title || subtitle ? 48 : 32}
          marginBottom={marginBottom ?? undefined}
          plotBorderWidth={1}
          animation={false}
          reflow={false}
          spacingLeft={spacingLeft ?? undefined}
          zoomType="xy"
        />

        <HSX.XAxis
          title={{
            text: xField,
          }}
          categories={xAxisSeries}
          labels={{
            rotation: xAxisLabelRotation,
          }}
          minPadding={0.1}
          maxPadding={0.1}
          tickWidth={1}
          gridLineWidth={1}
          reversed={xAxisReversed}
          startOnTick
          endOnTick
        />

        <HSX.YAxis
          title={{
            text: yField,
          }}
          categories={yAxisSeries}
          minPadding={0}
          maxPadding={0}
          tickWidth={15}
          gridLineWidth={1}
          alternateGridColor={alternateGridColor ?? undefined}
        >
          <HSX.BubbleSeries
            name="Bubble"
            data={data}
            borderWidth={1}
            dataLabels={{
              style: {
                color: 'black',
                textOutline: 'none',
              },
              // formatter: formatterValue,
            }}
          />
        </HSX.YAxis>
      </HSX.HighchartsChart>
    </HSX.HighchartsProvider>
  );
})``;

BubbleChart.propTypes = {
  /**
   * Override or extend the styles applied to the component.
   */
  className: PropTypes.string,
  /**
   * Chart title.
   */
  title: PropTypes.string,
  /**
   * Chart subtitle.
   */
  subtitle: PropTypes.string,
  /**
   * Chart data.
   */
  data: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.number),
      PropTypes.shape(),
    ]),
  ),
  /**
   * Chart metric.
   */
  metric: PropTypes.string,
  seriesSize: PropTypes.number,
  xAxis: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  yAxis: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  xfield: PropTypes.string,
  yfield: PropTypes.string,
  /**
   * If true, exporting mode on.
   */
  exporting: PropTypes.bool,
  /**
   * If true, loading overlay will be active.
   */
  loading: PropTypes.bool,
  /**
   * Chart width.
   */
  width: PropTypes.number,
  /**
   * Chart height.
   */
  height: PropTypes.number,
  /**
   * Series color palette variety.
   */
  colorVariety: PropTypes.number,
  /**
   * Series color index for min value.
   */
  colorIndexMin: PropTypes.number,
  /**
   * Series color index for min value.
   */
  colorIndexMax: PropTypes.number,
  /**
   * If true, chart title will be hidden.
   */
  hideTitle: PropTypes.bool,
  /**
   * If true, chart subtitle will be hidden.
   */
  hideSubtitle: PropTypes.bool,

  minColor: PropTypes.string,
  maxColor: PropTypes.string,
  xAxisLabelRotation: PropTypes.number,
  spacingLeft: PropTypes.number,
  onPlotClick: PropTypes.func,
  marginBottom: PropTypes.number,
  alternateGridColor: PropTypes.string,
  formatterTooltip: PropTypes.func,
  xAxisReversed: PropTypes.bool,
  yAxisReversed: PropTypes.bool,
  tooltipStickOnContact: PropTypes.bool,
};

BubbleChart.defaultProps = {
  className: '',
  title: undefined,
  subtitle: undefined,
  data: [],
  metric: 'bitrate',
  seriesSize: 5,
  xAxis: undefined,
  yAxis: undefined,
  xfield: 'Unknown',
  yfield: 'Unknown',
  exporting: true,
  loading: false,
  width: undefined,
  height: 300,
  colorVariety: defaultColorVariety,
  colorIndexMin: defaultColorIndexMin,
  colorIndexMax: defaultColorIndexMax,
  hideTitle: false,
  hideSubtitle: false,
  minColor: undefined,
  maxColor: undefined,
  xAxisLabelRotation: 0,
  spacingLeft: null,
  onPlotClick: null,
  marginBottom: null,
  alternateGridColor: null,
  formatterTooltip: null,
  xAxisReversed: false,
  yAxisReversed: true,
  tooltipStickOnContact: undefined,
};

export { defaultColorIndexMin, defaultColorIndexMax };
export default memo(BubbleChart);
