/* eslint-disable react/no-array-index-key */
import PropTypes from '+prop-types';
import { Fragment, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useFlag } from '@unleash/proxy-client-react';
import styled from 'styled-components';

import RoutePaths from '@/models/RoutePaths';

import Button, { ButtonVariants } from '+components/Button';
import Collapsible from '+components/Collapsible';
import { Row, Col, LayoutTypes } from '+components/Layout';
import { UniversalCell } from '+components/Table/Cells/UniversalCell';
import useThresholdOverrides from '+hooks/useThresholdOverrides';

const ButtonRow = styled(Row)`
  justify-content: end;
`;

const AlgorithmDetails = (props) => {
  const { event, icon, formatter, isSubAccountRecord, disabled } = props;
  const navigate = useNavigate();
  const isDnsEnabled = useFlag('DNS');

  const row = useMemo(
    () => ({ original: event }),
    [event],
  );

  const algorithmId = useMemo(
    () => event?.tdm?.id,
    [event],
  );
  const { getTrackBySearchParams, triggerExists } = useThresholdOverrides(
    algorithmId,
    event,
  );

  const onOverrideClick = useCallback(
    () => {
      const trackbySearchParams = getTrackBySearchParams();

      const path = isDnsEnabled
        ? RoutePaths.modelsDetection
        : RoutePaths.modelsNetwork;

      navigate({
        pathname: `${path}/${algorithmId}/overrides/update`,
        search: `${trackbySearchParams}${triggerExists() ? '&mode=edit' : ''}`,
      });
    },
    [event, isDnsEnabled, algorithmId, getTrackBySearchParams, triggerExists],
  );

  return (
    <Collapsible
      name={event.algorithm || event.name}
      id={event.tdm?.id}
      description={event?.description}
      formatter={formatter}
      icon={icon}
      expandable={false}
      original={event}
      disabled={disabled}
      initialExpanded
    >
      <Row $type={LayoutTypes.field}>
        <Col $type={LayoutTypes.fieldName}>Categories</Col>
        <Col $type={LayoutTypes.fieldValue} gap="5px">
          {event?.categories?.map((item, i) => (
            <Fragment key={i}>
              {UniversalCell('categories')({
                row,
                value: item,
                options: { disabled },
              })}
            </Fragment>
          ))}
        </Col>
      </Row>

      <Row $type={LayoutTypes.field}>
        <Col $type={LayoutTypes.fieldName}>NQL Search</Col>
        <Col $type={LayoutTypes.fieldValue}>
          {UniversalCell('search')({ row, value: event?.search })}
        </Col>
      </Row>

      <Row $type={LayoutTypes.field}>
        <Col $type={LayoutTypes.fieldName}>Threshold</Col>
        <Col $type={LayoutTypes.fieldValue}>
          {UniversalCell('threshold')({
            row,
            value: event?.threshold,
            options: { disabled },
          })}
        </Col>
      </Row>

      <Row $type={LayoutTypes.field}>
        <Col $type={LayoutTypes.fieldName}>Track</Col>
        <Col $type={LayoutTypes.fieldValue}>
          {UniversalCell('track_by')({
            row,
            value: event?.track_by,
            options: { disabled },
          })}
        </Col>
      </Row>

      {!isSubAccountRecord && !disabled && (
        <ButtonRow>
          <Button
            variant={ButtonVariants.text}
            onClick={onOverrideClick}
            className="excludeStyle"
          >
            {`${triggerExists() ? 'Edit' : 'Add'} Threshold Override`}
          </Button>
        </ButtonRow>
      )}
    </Collapsible>
  );
};

AlgorithmDetails.propTypes = {
  event: PropTypes.shape().isRequired,
  icon: PropTypes.node.isRequired,
  formatter: PropTypes.func,
  isSubAccountRecord: PropTypes.bool,
  disabled: PropTypes.bool,
};
AlgorithmDetails.defaultProps = {
  formatter: null,
  isSubAccountRecord: false,
  disabled: false,
};

export default AlgorithmDetails;
