import { useContext, useEffect, useMemo } from 'react';

import { getFieldType } from '@/models/FieldTypes';
import { DataTypes } from '@/models/PropertiesTray';

import {
  Menu,
  Item as MenuItem,
  useMenuActions,
  withMenu,
} from '+components/Menu';
import useUIProperty from '+hooks/useUIProperty';
import getNqlByFieldName from '+utils/getNqlByFieldName';
import { getStreamNql } from '+utils/getStreamNql';

import MenuWrapper, { Context } from '../MenuWrapper';

const MenuRenderer = () => {
  const { menuOptions } = useContext(Context);

  return (
    <Menu>
      {menuOptions.map((option, index) => (option.disabled ? null : (
      // eslint-disable-next-line react/no-array-index-key
        <MenuItem key={index} onClick={option.onClick}>
          {option.icon}
          <span>{option.text}</span>
        </MenuItem>
      )))}
    </Menu>
  );
};

const GlobalContextMenu = () => {
  const [globalContextMenu] = useUIProperty('globalContextMenu', null);
  const menuActions = useMenuActions();

  const activeData = useMemo(
    () => {
      const data = globalContextMenu?.data?.[0];
      if (!data) {
        return null;
      }

      if (data.dataType === DataTypes.field) {
        return {
          streamNql: getStreamNql(data?.stream),
          ...getNqlByFieldName({
            context: data?.context,
            field: data?.field,
            value: data?.value,
          }),
          fieldType: getFieldType(data.field),
          ...data,
        };
      }

      return data;
    },
    [globalContextMenu?.data],
  );

  useEffect(
    () => {
      if (globalContextMenu?.event) {
        menuActions?.showMenu?.(globalContextMenu?.event);
      }
    },
    [globalContextMenu?.event],
  );

  return (
    <MenuWrapper data={activeData}>
      <MenuRenderer />
    </MenuWrapper>
  );
};

export default withMenu(GlobalContextMenu);
