import { useMemo } from 'react';
import { NavLink } from 'react-router-dom';

import styled from 'styled-components';

import RoutePaths from '@/models/RoutePaths';

import getEventTableFilterValues from '+components/ContextTables/EventTable/getEventTableFilterValues';
import { ShowMoreWrapper, UniversalCell } from '+components/Table/Cells';
import {
  BaseColumnFactory,
  LabelOrIpColumnFactory,
  MenuColumnFactory,
  MenuColumnLink,
  NumberColumnFactory,
} from '+components/Table/Columns';
import { getRowOriginal } from '+components/Table/Columns/utils';
import { SelectColumnFilter } from '+components/Table/Filters';
import { formatNumber } from '+utils';
import { getColumnsHelper } from '+utils/getColumnsHelper';

import getColorScale from '../../getColorScale';

export const Columns = {
  ip: 'ip',
  categories: 'categories',
  algorithms: 'algorithms',
  events: 'events',
  threat: 'avgNdmThreadScore',
  confidence: 'avgNdmConfidenceScore',
  total: 'totalScore',
  customer: 'customer',
  menu: 'menu',
};

const TotalValue = styled.span`
  padding: 2px 4px;
  border-radius: 3px;
  line-height: 1;
  color: #16171a !important;
  font-weight: bold;
  background-color: ${({ $color }) => $color};
`;

export const columnsCollection = ({
  algorithms,
  categories,
  scoreMinMax,
  userFilters,
  labelContext,
}) => {
  const colorScale = getColorScale(scoreMinMax);

  return {
    [Columns.ip]: LabelOrIpColumnFactory({
      Header: 'IP',
      dataFieldName: Columns.ip,
      labelFieldName: 'ipname',
      showLabel: labelContext.show,
      fetchLabels: true,
      width: 200,
    }),
    [Columns.categories]: BaseColumnFactory({
      accessor: Columns.categories,
      getCellProps: () => ({ style: { whiteSpace: 'unset' } }),
      Cell: UniversalCell(Columns.categories),
      ...getEventTableFilterValues(Columns.categories, { categories }),
    }),
    [Columns.algorithms]: BaseColumnFactory({
      accessor: Columns.algorithms,
      Header: 'tdm.name',
      Cell: UniversalCell('algorithm'),
      ...getEventTableFilterValues(Columns.algorithms, { algorithms }),
    }),
    [Columns.events]: NumberColumnFactory({
      Header: 'Events Count',
      field: Columns.events,
      width: 150,
      Cell: ({ row }) => useMemo(
        () => {
          const original = getRowOriginal(row);
          const search = new URLSearchParams();
          search.set('ip', original.ip);

          if (userFilters.isCustomPeriod) {
            search.set('from', userFilters.start);
            search.set('to', userFilters.end);
          }

          const url = `${RoutePaths.events}/list?${search.toString()}`;

          return (
            <NavLink to={url} title={`Filter by ip: ${original.ip}`}>
              {original.events}
            </NavLink>
          );
        },
        [row, userFilters.start, userFilters.end],
      ),
    }),
    [Columns.threat]: NumberColumnFactory({
      Header: 'Avg Threat Score',
      field: Columns.threat,
      width: 150,
      Cell: ({ value }) => value != null && formatNumber(value, 1, '', true),
    }),
    [Columns.confidence]: NumberColumnFactory({
      Header: 'Avg Confidence Score',
      field: Columns.confidence,
      width: 150,
      Cell: ({ value }) => value != null && formatNumber(value, 1, '', true),
    }),
    [Columns.total]: NumberColumnFactory({
      Header: 'Total Score',
      field: Columns.total,
      width: 150,
      // eslint-disable-next-line react/prop-types
      Cell: ({ value }) => value != null && (
        <TotalValue $color={colorScale(value)}>
          {formatNumber(value, 1, '', true)}
        </TotalValue>
      ),
    }),
    [Columns.customer]: BaseColumnFactory({
      accessor: 'customer',
      Header: 'Account',
      width: 160,
      Cell: ({ value }) => useMemo(
        () => (
          <ShowMoreWrapper>
            {value.map((item) => (
              <span key={item}>{item}</span>
            ))}
          </ShowMoreWrapper>
        ),
        [value],
      ),
      Filter: SelectColumnFilter({
        optionLabel: (key) => (key === 'all' ? 'All' : key),
      }),
      filter: 'selectFilter',
    }),
    [Columns.menu]: MenuColumnFactory({
      cxActionMenu: (_, original) => {
        const search = new URLSearchParams();
        search.set('ip', original.ip);

        const url = `${RoutePaths.events}/list?${search.toString()}`;

        return (
          <MenuColumnLink to={url} title={`Filter by ip: ${original.ip}`} />
        );
      },
    }),
  };
};

export const getColumns = getColumnsHelper(columnsCollection);
