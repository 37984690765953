import PropTypes from '+prop-types';
import { useCallback, useMemo } from 'react';

import {
  Dropdown,
  DropdownItem,
  DropdownItemCheckbox,
  DropdownItemLabel,
  DropdownItemDescription,
} from '+components/Dropdown';
import FormField from '+components/form/FormField';

const DropdownField = (props) => {
  const {
    options,
    input,
    label,
    helperText,
    meta: { touched, error, dirty, submitFailed },
    disabled,
    required,
    short,
    showSelected,
    caption: captionProp,
    ...tail
  } = props;

  const invalid = error && (dirty || submitFailed) && touched;

  const caption = useMemo(
    () => {
      if (captionProp) {
        return captionProp;
      }
      const selectedOptions = options.find((o) => input.value?.includes(o.value));
      if (!selectedOptions.length) {
        return 'Select';
      }
      if (selectedOptions.length === 1) {
        return selectedOptions[0].label || selectedOptions[0].value;
      }
      return `${selectedOptions[0].label || selectedOptions[0].value} +${
        selectedOptions.length - 1
      }`;
    },
    [captionProp, input.value, options],
  );

  const onOptionClick = useCallback(
    (value) => () => {
      const newValue = input.value?.includes(value)
        ? input.value.filter((v) => v !== value)
        : [...(input.value || []), value];
      input.onChange(newValue);
    },
    [input.value],
  );

  return (
    <FormField
      label={label}
      helperText={helperText}
      error={error}
      invalid={invalid}
      disabled={disabled}
      required={required}
    >
      <Dropdown caption={caption} {...tail} disabled={disabled}>
        {options.map((option, index) => {
          const key = `${option.value}-${index}`;

          if (option.header) {
            return (
              <DropdownItem
                key={key}
                data-tracking={`filter-option-${option.value}`}
                header
              >
                {option.label || option.value}
              </DropdownItem>
            );
          }

          if (option.separator) {
            return (
              <DropdownItem
                key={key}
                data-tracking={`filter-option-${option.value}`}
                divider
              />
            );
          }

          const selected = showSelected && input.value?.includes(option.value);

          return (
            <DropdownItem
              key={key}
              onClick={onOptionClick(option.value)}
              data-tracking={`filter-option-${option.value}`}
              disabled={option.disabled}
              short={short}
              hideOnClick={false}
            >
              <DropdownItemCheckbox checked={selected} />
              <DropdownItemLabel>
                {option.label || option.value}
              </DropdownItemLabel>
              {option.description && (
                <DropdownItemDescription>
                  {option.description}
                </DropdownItemDescription>
              )}
            </DropdownItem>
          );
        })}
      </Dropdown>
    </FormField>
  );
};

DropdownField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    dirty: PropTypes.bool,
    submitFailed: PropTypes.bool,
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
        PropTypes.number,
      ]),
      label: PropTypes.string,
      description: PropTypes.string,
      header: PropTypes.bool,
      separator: PropTypes.bool,
    }),
  ),
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  helperText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  short: PropTypes.bool,
  showSelected: PropTypes.bool,
  caption: PropTypes.children,
};

DropdownField.defaultProps = {
  meta: null,
  options: [],
  label: null,
  helperText: null,
  disabled: false,
  required: false,
  short: undefined,
  showSelected: true,
  caption: undefined,
};

export default DropdownField;
