import styled from 'styled-components';

export default styled.span`
  z-index: 1;
  min-width: 25px;
  min-height: 25px;
  margin-right: 5px;
  border-radius: 50%;
  background: ${({ theme }) => theme.formWizardStepTitleBackground};
  pointer-events: all;
`;
