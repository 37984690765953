import styled, { css } from 'styled-components';

const commonCss = css`
  font-size: 9px;
  font-weight: 600;
  height: 16px;
  line-height: 16px;
  width: 45px;
  text-align: center;
  border-radius: 2px;
`;

export const PresetTag = styled.div`
  background-color: ${({ theme }) => (theme.name === 'dark' ? theme.Palette.gray5 : theme.Palette.gray1)};
  margin-right: -8px;
  padding: 0 2px;
  ${commonCss}
`;
