import { call, put, select } from 'redux-saga/effects';

import { ContextTypes } from '@/models/ContextTypes';

import { actions as toastActions } from '@/redux/toast';
import {
  createSlice,
  createSelector,
  startFetching,
  stopFetching,
  awaiters,
  defaultReducers,
} from '@/redux/util';

import backendClient from '@/middleware/backendClient';

import { makeId } from '+utils';

export const initialState = {
  isFetching: false,
  isFetchingFields: false,
  error: '',
  fields: {},
};

const url = (path) => `${path}`;

let api;
const initApi = () => {
  if (!api) {
    api = backendClient();
  }
};

const searchSagaConfig = (actions, path, isFlow) => function* ({ payload }) {
  initApi();

  const { id } = payload;
  const params = { size: 1000, ...payload };
  delete params.id;

  try {
    const { data } = yield call(api.post, url(path), params);
    const normalizedData = !isFlow
      ? data.data
      : data.data?.map((item) => ({
        ...item,
        id: item.id || makeId(),
      }));
    yield put(actions.searchSuccess({ id, data: normalizedData }));
  } catch (error) {
    yield put(actions.searchClear({ id }));
    yield put(actions.searchFail(error));
    yield put(
      toastActions.error({
        message: 'Search Error',
        details: error.message,
      }),
    );
  }
};

const slice = createSlice({
  name: 'search',
  initialState,

  reducers: {
    ...defaultReducers,
    fetchFields: (state) => {
      state.isFetchingFields = true;
    },
    fetchFieldsSuccess: (state, { payload: { context, data, ignoreSave } }) => {
      state.isFetchingFields = false;
      if (!ignoreSave) {
        state.fields[context] = data;
      }
    },
    fetchFieldsFail: (state, { payload }) => {
      defaultReducers.fail(state, { payload });
      state.isFetchingFields = false;
    },

    searchEvents: startFetching,
    searchAuditLogs: startFetching,
    searchBlocks: startFetching,
    searchFlow: startFetching,
    searchDns: startFetching,
    searchTraffic: startFetching,
    search: () => {},

    searchSuccess: (state, { payload: { id, data } }) => {
      stopFetching(state);
      state[id] = data || [];
    },
    searchFail: (state, { payload: { message } }) => {
      stopFetching(state);
      state.error = message;
    },
    searchClear: (state, { payload: { id } }) => {
      if (id) {
        delete state[id];
      }
    },
  },

  sagas: (actions, selectors) => ({
    [actions.fetchFields]: {
      * saga({ payload: context }) {
        initApi();
        const key = `fetchFields_${context}`;

        try {
          const { fields } = yield select(selectors.getState);

          if (fields?.[context] || awaiters.has(key)) {
            yield put(actions.fetchFieldsSuccess({ ignoreSave: true }));
            return;
          }

          awaiters.add(key);

          const { data } = yield call(
            api.get,
            url(`/search/${context}/fields`),
          );
          yield put(actions.fetchFieldsSuccess({ context, data: data.data }));

          awaiters.delete(key);
        } catch (error) {
          awaiters.delete(key);
          yield put(actions.fetchFieldsFail(error));
          yield put(
            toastActions.error({
              message: 'Error receiving list of fields',
              details:
                error && error.message
                  ? error.message
                  : 'Unknown error occurred',
            }),
          );
        }
      },
    },

    [actions.searchEvents]: {
      saga: searchSagaConfig(actions, '/search/alert'),
    },

    [actions.searchAuditLogs]: {
      saga: searchSagaConfig(actions, '/search/audit'),
    },

    [actions.searchBlocks]: {
      saga: searchSagaConfig(actions, '/search/block'),
    },

    [actions.searchFlow]: {
      saga: searchSagaConfig(actions, '/search/flow', true),
    },

    [actions.searchDns]: {
      saga: searchSagaConfig(actions, '/search/dns'),
    },

    [actions.searchTraffic]: {
      saga: searchSagaConfig(actions, '/search/traffic'),
    },

    [actions.search]: {
      * saga({ payload }) {
        const { context, ...params } = payload;

        switch (context) {
          case ContextTypes.alerts:
            yield put(actions.searchEvents(params));
            break;
          case ContextTypes.audit:
            yield put(actions.searchAuditLogs(params));
            break;
          case ContextTypes.blocks:
            yield put(actions.searchBlocks(params));
            break;
          case ContextTypes.dns:
            yield put(actions.searchDns(params));
            break;
          case ContextTypes.traffic:
            yield put(actions.searchTraffic(params));
            break;
          case ContextTypes.flow:
          default:
            yield put(actions.searchFlow(params));
            break;
        }
      },
    },
  }),

  selectors: (getState) => ({
    isFetching: createSelector(
      [getState],
      (state) => state.isFetching,
    ),

    isFetchingFields: createSelector(
      [getState],
      (state) => state.isFetchingFields,
    ),

    getFields: (context) => createSelector(
      [getState],
      (state) => state.fields[context],
    ),

    getSearchResult: (id) => createSelector(
      [getState],
      (state) => state[id],
    ),
  }),
});

// For convenience
export const { actions, selectors } = slice;

export default slice;
