import styled from 'styled-components';

export default styled(({ className, event }) => (
  <div className={className}>
    <h5 className="head">{event.description}</h5>
    <h5 className="subhead">{event.summary}</h5>
  </div>
))`
  font-size: 14px;
  line-height: 16px;
`;
