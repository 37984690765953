export const hex = [
  '#4ce1b6', // green
  '#70bbfd', // blue
  '#8884d8', // purple
  '#f9b022', // orange
  '#a4de6c', // lime
  '#9A2DE9', // purple
  '#f9acb7', // pink
  '#f6da6e', // yellow
  '#ff4861', // red,
  '#8dd1e1', // teal
];

// const hexToRgb = (hexStr) => {
//   let c;
//   if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hexStr)) {
//     c = hexStr.substring(1).split('');
//     if (c.length === 3) {
//       c = [c[0], c[0], c[1], c[1], c[2], c[2]];
//     }
//     c = `0x${c.join('')}`;
//     // eslint-disable-next-line no-bitwise
//     return [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',');
//   }
//   throw new Error('Bad Hex');
// };

// export const rgb = [
//   hexToRgb('#4ce1b6'), // green
//   hexToRgb('#70bbfd'), // blue
//   hexToRgb('#8884d8'), // purple
//   hexToRgb('#f9b022'), // orange
//   hexToRgb('#a4de6c'), // lime
//   hexToRgb('#140da3'), // purple
//   hexToRgb('#f9acb7'), // pink
//   hexToRgb('#f6da6e'), // yellow
//   hexToRgb('#ff4861'), // red,
//   hexToRgb('#8dd1e1'), // teal
// ];

// static version of above so we don't have to recompute rgb values at runtime
export const rgb = [
  '76,225,182', // green
  '112,187,253', // blue
  '136,132,216', // purple
  '249,176,34', // orange
  '164,222,108', // lime
  '154,45,233', // purple
  '249,172,183', // pink
  '246,218,110', // yellow
  '255,72,97', // red,
  '141,209,225', // teal
];

const hexLen = hex.length;
export const getHexColor = (i) => {
  let x = i;
  if (i + hexLen > hexLen) {
    x = i % hexLen;
  }
  return hex[x];
};

const rgbLen = rgb.length;
export const getRgbColor = (i) => {
  let x = i;
  if (i + rgbLen > rgbLen) {
    x = i % rgbLen;
  }
  return rgb[x];
};

export default hex;
