import { defaultAutoRemove } from '../ReactTable/utils';

/**
 * @param {Function} fn - filter function
 * @param {Function} [autoRemove=defaultAutoRemove] - the filter be removed if the function returns true
 * @returns {Function}
 */
export const withAutoRemove = (fn, autoRemove = defaultAutoRemove) => {
  fn.autoRemove = autoRemove;
  return fn;
};
