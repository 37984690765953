import PropTypes from '+prop-types';
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  Suspense,
} from 'react';
import { useSelector } from 'react-redux';
import { useMeasure } from 'react-use';

import classNames from 'classnames';
import capitalize from 'lodash.capitalize';
import uniq from 'lodash.uniq';
import { renderToStaticMarkup } from 'react-dom/server';

import ContentCopyIcon from 'mdi-react/ContentCopyIcon';
import SettingsIcon from 'mdi-react/SettingsIcon';
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';

import { ChartTypes } from '@/models/ChartTypes';
import { ContextTypes } from '@/models/ContextTypes';
import { DateTimeModes } from '@/models/DateTimeModes';
import { findMetricSettings, isCardMetric } from '@/models/MetricSettings';
import { WidgetTypes } from '@/models/WidgetTypes';

import { selectors as customerSelectors } from '@/redux/api/customer';
import { selectors as globalFiltersSelectors } from '@/redux/globalFilters';
import { selectors as socketControlSelectors } from '@/redux/ui/socketControl';

import useOnScreen from '+hooks/useOnScreen';
import getMetricFieldName from '+utils/getMetricFieldName';

import { dashboardPropTypes, widgetPropTypes } from '../shared/propTypes';
import {
  DateTimeLevel,
  getWidgetDateTimeMode,
  getWidgetMetric,
  getWidgetPeriodType,
  getWidgetTimePeriod,
} from '../shared/requests';
import { widgets } from '../shared/widgets';
import ChildContainer from './components/ChildContainer';
import Container from './components/Container';
import ControlsContainer from './components/ControlsContainer';
import DragDropBar from './components/DragDropBar';
import InvalidContainer from './components/InvalidContainer';
import Title from './components/Title';

const DOUBLE_TAP_DELAY_MS = 500;

const Widget = (props) => {
  const {
    widget,
    dashboard,
    refresher,
    readOnly,
    onDelete,
    onClone,
    onEdit,
    setAdditionalActionItems,
  } = props;

  const customer = useSelector(customerSelectors.getCurrentCustomer);
  const allFilters = useSelector(globalFiltersSelectors.getFilters);

  const [isHovered, setIsHovered] = useState(false);
  const lastTouchTime = useRef(performance.now());

  /** * Dimensions ** */
  const [width, setWidth] = useState(undefined);
  const [height, setHeight] = useState(undefined);

  const [measureRef, measureResult] = useMeasure();
  const containerRef = useRef(null);
  const isOnScreen = useOnScreen(containerRef);

  const setRef = useCallback(
    (node) => {
      measureRef(node);
      containerRef.current = node;
    },
    [],
  );

  useEffect(
    () => {
      if (!measureResult.width || !measureResult.height) {
        return;
      }
      setWidth((prevValue) => (isOnScreen || prevValue === undefined ? measureResult.width : prevValue));
      setHeight((prevValue) => (isOnScreen || prevValue === undefined ? measureResult.height : prevValue));
    },
    [isOnScreen, measureResult.width, measureResult.height],
  );

  /** * Widget Meta ** */
  const series0 = widget?.series?.[0];

  const widgetMeta = useMemo(
    () => {
      if (widget?.series?.length > 1) {
        const chartType = widget?.series.map((series) => {
          const seriesMeta = widgets[series?.display?.type];
          return seriesMeta?.chartType;
        });
        const requestGenerator = widget?.series.map((series) => {
          const seriesMeta = widgets[series?.display?.type];
          return seriesMeta?.requestGenerator;
        });
        const requestType = widget?.series.map((series) => {
          const seriesMeta = widgets[series?.display?.type];
          return seriesMeta?.requestType;
        });
        const requestFormat = widget?.series.map((series) => {
          const seriesMeta = widgets[series?.display?.type];
          return seriesMeta?.requestFormat;
        });
        const generateSummary = widget?.series.map((series) => {
          const seriesMeta = widgets[series?.display?.type];
          return seriesMeta?.generateSummary;
        });
        return {
          ...widgets[WidgetTypes.Multiple],
          chartType,
          requestGenerator,
          requestType,
          requestFormat,
          generateSummary,
        };
      }
      return widgets[series0?.display?.type] || {};
    },
    [JSON.stringify(widget?.series), series0],
  );

  /** * Request ** */
  const isSocketPaused = useSelector(socketControlSelectors.isPaused);

  // DateTime
  const dateTimeLevel = getWidgetDateTimeMode(
    dashboard.useGlobalDateTime,
    widget?.useDashboardDateTime,
  );

  const { start, end } = getWidgetTimePeriod(
    dateTimeLevel,
    allFilters,
    dashboard.dateTime,
    widget?.dateTime,
    !isSocketPaused,
  );

  const period = getWidgetPeriodType(
    dateTimeLevel,
    allFilters?.period?.type,
    dashboard.dateTime?.periodType,
    widget?.dateTime?.periodType,
  );

  const dateTimeParams = useMemo(
    () => ({
      start,
      end,
      period,
    }),
    [start, end, period],
  );

  let dateTimeMode = period === 'custom' ? DateTimeModes.range : DateTimeModes.now;
  if (dateTimeLevel === DateTimeLevel.global) {
    dateTimeMode = allFilters.actualDateTimeMode;
  }

  /** * Display Settings ** */
  const Wrapper = widgetMeta?.wrappers?.[series0?.context];
  const Renderer = widgetMeta.renderer;
  const isValid = Wrapper && Renderer && widget?.id && widget?.series?.length;

  const customMetrics = useMemo(
    () => (widget?.series || [])
      .flatMap((item) => item.metric)
      .filter((item) => !!item && item !== 'inherit'),
    [JSON.stringify(widget?.series)],
  );

  const customNql = useMemo(
    () => (widget?.series || []).flatMap((item) => item.nql).filter(Boolean),
    [JSON.stringify(widget?.series)],
  );

  const customSubAccounts = useMemo(
    () => uniq(
      (widget?.series || []).reduce((acc, item) => {
        if (Array.isArray(item.customers) && item.customers?.length) {
          // remove current customer from sub-accounts if it is the only one
          const arr = [...item.customers];
          const indexOfCurrentCustomer = arr.indexOf(customer?.shortname);
          if (arr.length === 1 && indexOfCurrentCustomer !== -1) {
            arr.splice(indexOfCurrentCustomer, 1);
          }
          acc.push(...arr);
        }
        return acc;
      }, []),
    ),
    [JSON.stringify(widget?.series), customer?.shortname],
  );

  const titleIconsCount = useMemo(
    () => {
      if (!widget?.settings?.showTitle) {
        return widgetMeta.chartType === ChartTypes.map ? 2 : 0;
      }
      let count = 0;
      if (customSubAccounts?.length) {
        count += 1;
      }
      if (
        customNql?.length
      || dateTimeLevel === DateTimeLevel.widget
      || customMetrics?.length
      ) {
        count += 1;
      }
      return count;
    },
    [
      customSubAccounts?.length,
      dateTimeLevel,
      customNql?.length,
      widget?.settings?.showTitle,
      widgetMeta.chartType,
      customMetrics?.length,
    ],
  );

  const title = useMemo(
    () => {
      if (!isValid) {
        return undefined;
      }

      return renderToStaticMarkup(
        <Title
          title={widget?.title}
          description={widget?.description}
          dateTimeLevel={dateTimeLevel}
          dateTimeParams={dateTimeParams}
          nql={customNql}
          subAccounts={customSubAccounts}
          metrics={customMetrics}
        />,
      );
    },
    [
      isValid,
      widget?.title,
      widget.description,
      dateTimeLevel,
      dateTimeParams,
      JSON.stringify(customNql),
      customSubAccounts,
      customMetrics,
    ],
  );

  const subtitle = useMemo(
    () => {
      if (!isValid || !widget?.series?.length) {
        return undefined;
      }

      // we need to use original widget here because we need to show original fields
      const subtitleArr = widget.series.map((item) => {
        if (!ContextTypes[item.context]) {
          return '';
        }

        const metric = getWidgetMetric(
          item.metric,
          allFilters[getMetricFieldName(item.context)],
        );

        if ([WidgetTypes.Gauge, WidgetTypes.Value].includes(item.display?.type)) {
          return item.fields?.length
            ? `${capitalize(metric)} by ${item.fields.join('-')}`
            : `${capitalize(metric)} ${item.display.aggregate?.type}`;
        }

        const isCard = isCardMetric(metric);
        const { chartTitle } = findMetricSettings({
          context: item.context,
          metric,
        }) || {};
        const metricLabel = chartTitle || metric;
        const fieldsLabel = !item.fields?.length
          ? ''
          : ` by ${item.fields.join('-')}`;
        const sizeLabel = item.size && !isCard ? ` (top ${item.size})` : '';
        return `${metricLabel || ''}${fieldsLabel || ''}${sizeLabel || ''}`;
      });

      return subtitleArr.join(', ');
    },
    [isValid, JSON.stringify(widget?.series), allFilters],
  );

  const commonClassNames = {
    'widget__chart_with-menu':
      isValid
      && widget?.settings?.exporting !== false
      && widgetMeta.chartType !== ChartTypes.table
      && widgetMeta.value !== WidgetTypes.Markdown,
    widget__table: isValid && widgetMeta.chartType === ChartTypes.table,
  };

  const controlsClassNames = {
    'widget__controls-hovered': isHovered,
  };

  /** * Callbacks ** */

  const onWidgetMouseOver = useCallback(
    (event) => {
      const { y } = event.currentTarget.getBoundingClientRect();
      setIsHovered(event.clientY - y <= 26);
    },
    [],
  );

  const onWidgetMouseLeave = useCallback(
    () => setIsHovered(false),
    [],
  );

  const onControlsMouseDown = useCallback(
    (event) => event.stopPropagation(),
    [],
  );

  const onChartMouseDown = useCallback(
    (event) => event.stopPropagation(),
    [],
  );

  const onWidgetTouch = useCallback(
    (event) => {
      const currentTouchTime = performance.now();
      if (currentTouchTime - lastTouchTime.current > DOUBLE_TAP_DELAY_MS) {
        event.stopPropagation();
      }
      lastTouchTime.current = currentTouchTime;
    },
    [],
  );

  return (
    <Container
      ref={setRef}
      onMouseMove={onWidgetMouseOver}
      onMouseLeave={onWidgetMouseLeave}
      onTouchStartCapture={onWidgetTouch}
    >
      {!readOnly && isOnScreen && (
        <Fragment>
          <DragDropBar
            className="widget__drag-drop-bar"
            $titleIconsCount={titleIconsCount}
          />
          <ControlsContainer
            className={classNames('widget__controls', {
              ...commonClassNames,
              ...controlsClassNames,
            })}
            onMouseDown={onControlsMouseDown}
          >
            <span className="widget__control-icon" title="Delete widget">
              <TrashCanOutlineIcon onClick={onDelete} />
            </span>
            {isValid && (
              <Fragment>
                <span className="widget__control-icon" title="Copy widget">
                  <ContentCopyIcon onClick={onClone} />
                </span>
                <span className="widget__control-icon" title="Widget settings">
                  <SettingsIcon onClick={onEdit} />
                </span>
              </Fragment>
            )}
          </ControlsContainer>
        </Fragment>
      )}
      <ChildContainer
        className="widget__chart_container"
        onMouseDown={onChartMouseDown}
        $isOnScreen={isOnScreen}
      >
        {isValid && width && height && (
          <Suspense>
            <Wrapper
              widgetMeta={widgetMeta}
              dashboard={dashboard}
              widget={widget}
              title={title}
              subtitle={subtitle}
              dateTimeLevel={dateTimeLevel}
              dateTimeMode={dateTimeMode}
              dateTimeParams={dateTimeParams}
              width={width}
              height={height}
              readOnly={readOnly}
              refresher={refresher}
              Renderer={Renderer}
              setAdditionalActionItems={setAdditionalActionItems}
              isOnScreen={isOnScreen}
              customNql={customNql}
              subAccounts={customSubAccounts}
              customMetrics={customMetrics}
            />
          </Suspense>
        )}
        {!isValid && <InvalidContainer>Invalid widget</InvalidContainer>}
      </ChildContainer>
    </Container>
  );
};

Widget.propTypes = {
  dashboard: PropTypes.shape(dashboardPropTypes),
  widget: PropTypes.shape(widgetPropTypes),
  refresher: PropTypes.number,
  readOnly: PropTypes.bool,
  onDelete: PropTypes.func,
  onClone: PropTypes.func,
  onEdit: PropTypes.func,
  setAdditionalActionItems: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.oneOf([null]),
  ]),
};

Widget.defaultProps = {
  dashboard: {},
  widget: undefined,
  readOnly: true,
  refresher: undefined,
  onDelete: () => {},
  onClone: () => {},
  onEdit: () => {},
  setAdditionalActionItems: null,
};

export default Widget;
