import { Navigate, useLocation } from 'react-router-dom';

export const withReplaceRoute = (from, to) => () => {
  const { pathname, search } = useLocation();

  const newPath = pathname.replace(from, to);

  const fixedSearch = search ? `?${search}` : '';

  return <Navigate to={`${newPath}${fixedSearch}`} replace />;
};
