import PropTypes from '+prop-types';
import { useMemo } from 'react';

import { Col, Row } from '+components/Layout';
import {
  CellAlgorithmName,
  CellPluginName,
  CellResponsePolicyName,
  UniversalCell,
} from '+components/Table/Cells';
import useGlobalFilters from '+hooks/useGlobalFilters';

import {
  BodyGroupCol,
  BodyGroupTitleRow,
  BodyItemRow,
  BodyNameCol,
  BodySeparator,
  BodyValueCol,
} from '../../BodyComponents';

const BlockOverview = ({ padding, record }) => {
  const [filters] = useGlobalFilters();

  const row = useMemo(
    () => ({ original: record }),
    [record],
  );

  return (
    <Col gap="14px">
      <BodyGroupCol paddingLeft={padding} paddingRight={padding}>
        <BodyItemRow>
          <BodyNameCol>Block ID:</BodyNameCol>
          <BodyValueCol>{record.id}</BodyValueCol>
        </BodyItemRow>

        <BodyItemRow>
          <BodyNameCol>Active:</BodyNameCol>
          <BodyValueCol>
            {UniversalCell('active')({
              row,
              value: record.active,
              options: { disabled: true },
            })}
          </BodyValueCol>
        </BodyItemRow>

        <BodyItemRow>
          <BodyNameCol>Adapter:</BodyNameCol>
          <BodyValueCol>
            {UniversalCell('adapter')({
              row,
              value: record.adapter,
              options: { disabled: true },
            })}
          </BodyValueCol>
        </BodyItemRow>

        <BodyItemRow>
          <BodyNameCol>Protocol:</BodyNameCol>
          <BodyValueCol>
            {UniversalCell('protocol')({
              row,
              value: record.protocol,
              options: { disabled: true },
            })}
          </BodyValueCol>
        </BodyItemRow>

        <BodyItemRow>
          <BodyNameCol>Update Count:</BodyNameCol>
          <BodyValueCol>
            {UniversalCell('updatecount')({
              row,
              value: record.updatecount,
              options: { disabled: true },
            })}
          </BodyValueCol>
        </BodyItemRow>
      </BodyGroupCol>

      <BodySeparator />

      <BodyGroupCol paddingLeft={padding} paddingRight={padding}>
        <BodyGroupTitleRow>Time</BodyGroupTitleRow>

        <BodyItemRow>
          <BodyNameCol>Start:</BodyNameCol>
          <BodyValueCol>
            {UniversalCell('start')({
              row,
              value: record.start,
              options: { disabled: true },
            })}
          </BodyValueCol>
        </BodyItemRow>

        <BodyItemRow>
          <BodyNameCol>End:</BodyNameCol>
          <BodyValueCol>
            {UniversalCell('end')({
              row,
              value: record.end,
              options: { disabled: true },
            })}
          </BodyValueCol>
        </BodyItemRow>

        <BodyItemRow>
          <BodyNameCol>Expiration:</BodyNameCol>
          <BodyValueCol>
            {UniversalCell('expiration')({
              row,
              value: record.expiration,
              options: { disabled: true },
            })}
          </BodyValueCol>
        </BodyItemRow>

        <BodyItemRow>
          <BodyNameCol>Last Update:</BodyNameCol>
          <BodyValueCol>
            {UniversalCell('lastupdate')({
              row,
              value: record.lastupdate,
              options: { disabled: true },
            })}
          </BodyValueCol>
        </BodyItemRow>
      </BodyGroupCol>

      <BodySeparator />

      <BodyGroupCol paddingLeft={padding} paddingRight={padding}>
        <BodyGroupTitleRow>Traffic</BodyGroupTitleRow>

        <BodyItemRow>
          <BodyNameCol>Source:</BodyNameCol>
          <BodyValueCol>
            {!filters.labelContext.show
            || filters.labelContext.ip !== 'name'
            || !record.srcipname?.length
              ? UniversalCell('srcip')({
                row,
                value: record.srcip,
                options: { showAsLabel: true, disabled: true },
              })
              : UniversalCell('srcipname')({
                row,
                value: record.srcipname,
                options: {
                  useDataValueInPropertiesTray: true,
                  disabled: true,
                },
              })}
          </BodyValueCol>
        </BodyItemRow>

        <BodyItemRow>
          <BodyNameCol>SRC Port:</BodyNameCol>
          <BodyValueCol>
            {!filters.labelContext.show
            || filters.labelContext.port !== 'name'
            || !record.srcportname?.length
              ? UniversalCell('srcport')({
                row,
                value: record.srcport,
                options: { showAsLabel: true, disabled: true },
              })
              : UniversalCell('srcportname')({
                row,
                value: record.srcportname,
                options: {
                  useDataValueInPropertiesTray: true,
                  disabled: true,
                },
              })}
          </BodyValueCol>
        </BodyItemRow>

        <BodyItemRow>
          <BodyNameCol>Destination:</BodyNameCol>
          <BodyValueCol>
            {!filters.labelContext.show
            || filters.labelContext.ip !== 'name'
            || !record.dstipname?.length
              ? UniversalCell('dstip')({
                row,
                value: record.dstip,
                options: { showAsLabel: true, disabled: true },
              })
              : UniversalCell('dstipname')({
                row,
                value: record.dstipname,
                options: {
                  useDataValueInPropertiesTray: true,
                  disabled: true,
                },
              })}
          </BodyValueCol>
        </BodyItemRow>

        <BodyItemRow>
          <BodyNameCol>DST Port:</BodyNameCol>
          <BodyValueCol>
            {!filters.labelContext.show
            || filters.labelContext.port !== 'name'
            || !record.dstportname?.length
              ? UniversalCell('dstport')({
                row,
                value: record.dstport,
                options: { showAsLabel: true, disabled: true },
              })
              : UniversalCell('dstportname')({
                row,
                value: record.dstportname,
                options: {
                  useDataValueInPropertiesTray: true,
                  disabled: true,
                },
              })}
          </BodyValueCol>
        </BodyItemRow>
      </BodyGroupCol>

      <BodySeparator />

      <BodyGroupCol paddingLeft={padding} paddingRight={padding}>
        <BodyGroupTitleRow>Detection Model</BodyGroupTitleRow>

        <Row gap="5px">
          {record.tdm?.map((item) => (
            <CellAlgorithmName
              key={item.id}
              id={item.id}
              name={item.name}
              description={item.description}
              disabled
            />
          ))}
        </Row>
      </BodyGroupCol>

      <BodySeparator />

      <BodyGroupCol paddingLeft={padding} paddingRight={padding}>
        <BodyGroupTitleRow>Response Policies</BodyGroupTitleRow>

        <Row gap="5px">
          {record.rules?.map((item) => (
            <CellResponsePolicyName
              key={item.id}
              id={item.id}
              name={item.name}
              description={item.description}
              disabled
            />
          ))}
        </Row>
      </BodyGroupCol>

      <BodySeparator />

      <BodyGroupCol paddingLeft={padding} paddingRight={padding}>
        <BodyGroupTitleRow>Integrations</BodyGroupTitleRow>

        <Row gap="5px">
          {record.plugin?.id && record.plugin?.name && (
            <CellPluginName
              id={record.plugin.id}
              name={record.plugin.name}
              description={record.plugin.description}
              disabled
            />
          )}
        </Row>
      </BodyGroupCol>
    </Col>
  );
};

BlockOverview.propTypes = {
  padding: PropTypes.string.isRequired,
  record: PropTypes.shape().isRequired,
};

export default BlockOverview;
