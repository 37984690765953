import dayjs, { DateFormat } from '+utils/dayjs';

export const convertValueToDayjs = (value) => {
  if (value == null) {
    return null;
  }

  const parsedValue = +value;
  if (Number.isNaN(parsedValue) || parsedValue < 1514764800) {
    // 2018-01-01
    return null;
  }

  // start, end may be a relative number from the server uptime
  // so test if we have a unix timestamp. if not, do nothing.
  const str = `${parsedValue}`;
  const timestamp = str.length > 13 ? parsedValue * +`1e-${str.length - 13}` : parsedValue;
  const date = `${timestamp}`.length > 10 ? dayjs(timestamp) : dayjs.unix(timestamp);
  return date.isValid() ? date : null;
};

export const timestampFormatter = (value, format = DateFormat.second) => {
  const date = convertValueToDayjs(value);
  return date?.isValid() ? date.format(format) : null;
};

export const durationFormatter = (data, unit = 'seconds') => {
  if (data == null) {
    return null;
  }

  const duration = dayjs.duration(data, unit);
  if (duration.days() > 0) {
    return `${duration.days()}d`;
  }
  if (duration.hours() > 0) {
    return `${duration.hours()}h`;
  }
  if (duration.minutes() > 0) {
    return `${duration.minutes()}m`;
  }
  if (duration.seconds() > 0) {
    return `${duration.seconds()}s`;
  }
  return `${duration.milliseconds()}ms`;
};

export const flowrateFormatter = (value) => (value == null ? null : +value?.toFixed(2));

export const numberFormatter = (value, ...args) => value.toLocaleString(...args);

export const protocolIntKeyword = (protocolInt) => {
  // https://en.wikipedia.org/wiki/List_of_IP_protocol_numbers
  switch (protocolInt) {
    case 0:
      return 'HOPOPT';
    case 1:
      return 'ICMP';
    case 2:
      return 'IGMP';
    case 3:
      return 'GGP';
    case 4:
      return 'IP-in-IP';
    case 5:
      return 'ST';
    case 6:
      return 'TCP';
    case 7:
      return 'CBT';
    case 8:
      return 'EGP';
    case 9:
      return 'IGP';
    case 17:
      return 'UDP';
    case 27:
      return 'RDP';
    case 28:
      return 'IRTP';
    case 41:
      return 'IPv6';
    case 50:
      return 'ESP';
    case 62:
      return 'CFTP';
    case 75:
      return 'PVP';
    case 132:
      return 'SCTP';
    default:
      return '';
  }
};

export const geoValueToTextFormatter = (value) => [value?.countrycode, value?.subdiso].filter(Boolean).join(' - ');
