import PropTypes from '+prop-types';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useDebounce, usePrevious } from 'react-use';

import classNames from 'classnames';
import styled from 'styled-components';

import SettingCategories from '@/models/SettingCategories';

import { selectors as customerSelectors } from '@/redux/api/customer';
import { selectors, actions } from '@/redux/api/nql-complete';
import { selectors as profileSelectors } from '@/redux/api/user/profile';

import { convertNqlContextToModeTag } from '@/shared/utils/getNewRecentFilters';

import {
  Menu,
  Separator,
  useMenuActions,
  useMenuState,
  withMenu,
} from '+components/Menu';
import usePortalSettingsValue from '+hooks/usePortalSettingsValue';
import useSessionStorage from '+hooks/useSessionStorage';
import useUIProperty from '+hooks/useUIProperty';
import { incName } from '+utils/incName';

import Context from './Context';
import DropdownButton from './DropdownButton';
import Filter from './Filter';
import PresetItem from './PresetItem';
import Presets from './Presets';

const PresetMenu = styled(Menu)`
  width: ${({ containerWidth }) => containerWidth}px;
`;

const saveItem = {
  title: 'Save Current Nql',
};

const makeFilterAction = (filter) => (item) => {
  const fixedFilter = filter.toLowerCase();

  return (
    item.title?.toLowerCase().includes(fixedFilter)
    || item.nql.toLowerCase().includes(fixedFilter)
    || item.preset?.toLowerCase().includes(fixedFilter)
  );
};

const Dropdown = (props) => {
  const {
    context,
    onChooseItem,
    containerWidth,
    presetsLeftOffset,
    presetsWidthExtension,
    onOpenOrClose,
    disabled,
    invalid,
    readOnly,
    allowRecents,
    nql,
  } = props;

  const { showMenuDropdown } = useMenuActions();
  const { show } = useMenuState();
  const prevShow = usePrevious(show);
  const dispatch = useDispatch();
  const [filter, setFilter] = useState('');
  const [filterAction, setFilterAction] = useState(null);
  const [newPreset, setNewPreset] = useState(null);
  const [globalRecents] = usePortalSettingsValue(
    SettingCategories.ui,
    'globalFiltersRecents',
    [],
  );
  const [sessionRecents] = useSessionStorage('globalFiltersRecents');
  const [guest] = useUIProperty('guest');
  const customer = useSelector(customerSelectors.getCurrentCustomer);
  const presetsSelector = useMemo(
    () => selectors.getPresets(context),
    [context],
  );

  const recents = guest ? sessionRecents || globalRecents : globalRecents;

  const appendModeTagToDocPresets = (docPresets, nqlContext) => {
    const updatedPresets = [];
    docPresets.forEach((preset) => {
      updatedPresets.push({
        ...preset,
        mode: convertNqlContextToModeTag(nqlContext),
      });
    });
    return updatedPresets;
  };

  const extractedRecents = useMemo(
    () => {
      if (!customer?.shortname || !allowRecents) {
        return [];
      }
      const customerRecents = recents.find(
        (recent) => recent.customer === customer.shortname,
      );
      return customerRecents?.recentQueries || [];
    },
    [customer?.shortname, recents, allowRecents],
  );

  useDebounce(
    () => {
      setFilterAction(filter?.trim() ? () => makeFilterAction(filter) : null);
    },
    300,
    [filter],
  );

  const { user: { email } } = useSelector(profileSelectors.getState);
  const presetsData = useSelector(presetsSelector);
  const docs = useSelector(selectors.getDocs);
  const isDefaultCustomer = useSelector(profileSelectors.isDefaultCustomer);
  const separatedPresets = useMemo(
    () => Object.values(presetsData || {}).reduce(
      (acc, item) => {
        let index = 1;

        if (item.system || isDefaultCustomer) {
          index = 2;
        } else if (item.email === email) {
          index = 0;
        }
        acc[index].push({
          ...item,
          mode: convertNqlContextToModeTag(context),
        });
        return acc;
      },
      [
        [],
        [],
        [
          ...appendModeTagToDocPresets(
            docs?.presets?.[context] || [],
            context,
          ),
        ],
        extractedRecents,
      ],
    ),
    [
      presetsData,
      email,
      docs?.presets?.[context],
      isDefaultCustomer,
      extractedRecents,
    ],
  );

  const [userPresets, companyPresets, systemPresets, recentQueries] = useMemo(
    () => {
      if (!filterAction) {
        return separatedPresets;
      }

      return separatedPresets.map((presets) => presets.filter(filterAction));
    },
    [separatedPresets, filterAction, recents],
  );

  const onClick = useCallback(
    (event) => {
      showMenuDropdown(event.currentTarget);
      if (onOpenOrClose) {
        onOpenOrClose(true);
      }
    },
    [showMenuDropdown, onOpenOrClose],
  );

  const onFilterChange = useCallback(
    (value) => {
      setFilter(value);
    },
    [],
  );

  useEffect(
    () => {
      dispatch(actions.fetchPresets(context, 'nql_dropdown'));

      return () => {
        dispatch(actions.cancel('nql_dropdown'));
      };
    },
    [context],
  );

  useEffect(
    () => {
      const closed = prevShow && !show;
      if (closed && onOpenOrClose) {
        onOpenOrClose(false);
      }
    },
    [prevShow, show],
  );

  const onSaveItemClick = useCallback(
    (event) => {
      event.stopPropagation();
      setNewPreset({
        title: 'Untitled',
        nql,
      });
    },
    [nql],
  );

  const onClone = useCallback(
    (item) => {
      const title = item.title || item.preset;
      setNewPreset({
        title: incName(title, [title]),
        nql: item.nql,
      });
    },
    [],
  );

  const onSave = useCallback(
    (item) => {
      if (!item) {
        return;
      }

      if (item.id) {
        dispatch(actions.updatePreset(item));
        return;
      }

      setNewPreset(null);

      dispatch(
        actions.addPreset({
          ...item,
          context,
        }),
      );
    },
    [context],
  );

  const onRemove = useCallback(
    (item) => {
      if (!item?.id) {
        return;
      }

      dispatch(actions.deletePreset(item));
    },
    [],
  );

  const onCancel = useCallback(
    () => {
      setNewPreset(null);
    },
    [],
  );

  const contextValue = useMemo(
    () => ({
      onClone,
      onSave,
      onCancel,
      onRemove,
    }),
    [onClone, onSave, onCancel, onRemove],
  );

  return (
    <Fragment>
      <Context.Provider value={contextValue}>
        <PresetMenu
          containerWidth={containerWidth - 2 + presetsWidthExtension}
          cssLeft={presetsLeftOffset}
        >
          <Filter onChange={onFilterChange} />
          {!newPreset && (
            <PresetItem
              isSave
              disabled={!nql}
              item={saveItem}
              onClick={onSaveItemClick}
            />
          )}
          {newPreset && <PresetItem isNew item={newPreset} />}
          <Separator />
          <Presets
            userPresets={userPresets}
            companyPresets={companyPresets}
            systemPresets={systemPresets}
            recentQueries={recentQueries || []}
            filter={filter}
            onChooseItem={onChooseItem}
            isDefault={isDefaultCustomer}
          />
        </PresetMenu>
      </Context.Provider>
      <DropdownButton
        className={classNames({ invalid })}
        readOnly={readOnly}
        disabled={disabled}
        onClick={onClick}
      />
    </Fragment>
  );
};

Dropdown.propTypes = {
  context: PropTypes.string.isRequired,
  onChooseItem: PropTypes.func.isRequired,
  containerWidth: PropTypes.number,
  presetsLeftOffset: PropTypes.string.isRequired,
  presetsWidthExtension: PropTypes.number.isRequired,
  onOpenOrClose: PropTypes.func,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  invalid: PropTypes.bool,
  allowRecents: PropTypes.bool,
  nql: PropTypes.string,
};

Dropdown.defaultProps = {
  containerWidth: 450,
  onOpenOrClose: null,
  readOnly: false,
  disabled: false,
  invalid: false,
  allowRecents: true,
  nql: null,
};

export default withMenu(Dropdown);
