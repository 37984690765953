import { useCallback, useEffect, useRef } from 'react';

import isFunction from 'lodash.isfunction';

const useOnAction = (state, listener) => {
  const refListener = useRef();

  useEffect(
    () => {
      if (!isFunction(listener)) {
        refListener.current = null;
        return;
      }

      refListener.current = (...args) => {
        listener(...args);
      };
    },
    [listener],
  );

  useEffect(
    () => {
      refListener.current?.(state);
    },
    [state],
  );
};

export const useOnChangeActionsFactory = (state, listeners) => {
  const {
    onExpandedChange,
    onColumnOrderChange,
    onHiddenColumnsChange,
    onColumnWidthsChange,
    onPageSizeChange,
    onPageIndexChange,
    onSortByChange,
    onFiltersChange,
    onGroupByChange,
    onAggregatesChange,
    onSelectedRowsChange,
    onDuplicatesChange,
  } = listeners;

  useOnAction(state?.expanded, onExpandedChange);
  useOnAction(state?.columnOrder, onColumnOrderChange);
  useOnAction(state?.hiddenColumns, onHiddenColumnsChange);
  useOnAction(state?.pageSize, onPageSizeChange);
  useOnAction(state?.pageIndex, onPageIndexChange);
  useOnAction(state?.sortBy, onSortByChange);
  useOnAction(state?.filters, onFiltersChange);
  useOnAction(state?.groupBy, onGroupByChange);
  useOnAction(state?.aggregates, onAggregatesChange);
  useOnAction(state?.selectedRowIds, onSelectedRowsChange);
  useOnAction(state?.duplicates, onDuplicatesChange);

  const isResizing = useRef();
  isResizing.current = state.columnResizing?.isResizingColumn != null;

  const doColumnWidthsChange = useCallback(
    (...args) => {
      if (isResizing.current) {
        onColumnWidthsChange(...args);
      }
    },
    [onColumnWidthsChange],
  );

  useOnAction(state?.columnWidths, doColumnWidthsChange);
};
