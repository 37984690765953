import styled from 'styled-components';

export default styled.div`
  margin-left: 140px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colorTextSecondary} !important;
  white-space: nowrap;
`;
