/* eslint-disable react/no-array-index-key, react/prop-types */
import {
  LabelOrIpColumnFactory,
  StringsArrayColumnsFactory,
} from '+components/Table/Columns';

const columnsCollection = ({ labelContext }) => [
  LabelOrIpColumnFactory({
    Header: 'RData',
    dataFieldName: 'answers.rdata',
    labelFieldName: 'ipname',
    showLabel: labelContext.show,
    fetchLabels: true,
  }),
  StringsArrayColumnsFactory({
    accessor: 'answers.type',
    Header: 'Type',
    // width: 100,
  }),
  StringsArrayColumnsFactory({
    accessor: 'answers.class',
    Header: 'Class',
    // width: 100,
  }),
];

export const getColumns = columnsCollection;
