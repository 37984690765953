import PropTypes from '+prop-types';
import { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import styled from 'styled-components';

import ArrowDownBoldIcon from 'mdi-react/ArrowDownBoldIcon';

import RoleModel from '@/models/Role';

import { selectors as customerSelectors } from '@/redux/api/customer';

import Alert from '+components/Alert';
import CopyText from '+components/CopyText';
import FieldsSection from '+components/form/FieldsSection';
import FileUploadField from '+components/form/FileUploadField';
import { Field, FieldArray, FormSpy } from '+components/form/FinalForm';
import { Description, Group, Label } from '+components/form/FormField';
import { normalizeSelectValue } from '+components/form/Normalizers';
import Plaintext from '+components/form/Plaintext';
import SelectField from '+components/form/SelectField';
import TextField from '+components/form/TextField';
import { ToggleField } from '+components/form/Toggle';
import { validateRequired } from '+components/form/Validators';
import FormWizard, { Step } from '+components/FormWizard';
import IconButton from '+components/IconButton';
import useRoles from '+hooks/useRoles';
import useUIProperty from '+hooks/useUIProperty';
import { ScrollBarMixin } from '+theme/mixins/scrollBarMixin';
import {
  getSsoLoginUrl,
  getMetadataXmlPublicUrl,
} from '+utils/portalUrlHelper';

import UserRoleMapperFields from './UserRoleMapperFields';

const onNoop = () => {};

const StyledToggleField = styled(ToggleField)`
  .form__form-group-field,
  .form__form-group-description {
    width: 100% !important;
    padding-left: unset !important;
    margin-left: 140px !important;
  }
`;

const StyledAlert = styled(Alert)`
  margin-left: 140px;
`;

const StyledFieldArray = styled(FieldArray)`
  margin-left: 140px;
`;

const PlaintextForUrl = styled(Plaintext)`
  padding: 8px 10px !important;
  border-radius: 4px;
  background: ${({ theme }) => theme.colorFieldBackground};
  width: 100%;
  > div {
    width: 100%;
  }
`;

const PlaintextForFile = styled(Plaintext)`
  position: relative;
  padding: 8px 10px !important;
  border-radius: 4px;
  background: ${({ theme }) => theme.colorFieldBackground};
  width: 100%;
  white-space: pre-line;

  > div {
    width: 100%;
  }

  .copy-to-clipboard__content {
    ${ScrollBarMixin};
    height: 80px;
    overflow: auto;
  }

  .copy-to-clipboard__button {
    position: absolute;
    top: calc(50% - 24px);
    right: 10px;
    transform: translateY(-50%);
  }

  .download-button {
    position: absolute;
    top: calc(50% + 24px);
    right: 10px;
    transform: translateY(-50%);
  }
`;

const downloadFile = ({ fileDataType, fileData, fileName }) => {
  const element = document.createElement('a');
  element.setAttribute('href', `data:${fileDataType},${fileData}`);
  element.setAttribute('download', fileName);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

const SamlConfigurationForm = styled((props) => {
  const {
    className,
    samlProvider,
    samlProviderSettings,
    samlProviderMetadataXml,
    disabled,
    onCancel,
    onSubmit,
  } = props;

  const location = useLocation();

  const customer = useSelector(customerSelectors.getCurrentCustomer);
  const { roles } = useRoles();
  const portalSsoLoginUrl = getSsoLoginUrl(customer?.shortname);
  const metadataXmlPublicUrl = getMetadataXmlPublicUrl(customer?.shortname);
  const [resetter, setResetter] = useState(null);
  const [formValues, setFormValues] = useState(samlProvider);

  const steps = useMemo(
    () => ({
      essentials: 'essentials',
      provider: 'provider',
      attributes: 'attributes',
      ...(!formValues.resellerSso ? { roles: 'roles' } : {}),
    }),
    [formValues.resellerSso],
  );

  const [formInvalid, setFormInvalid] = useState(
    Object.values(steps).reduce((acc, item) => {
      acc[item] = !samlProvider?.alias;
      return acc;
    }, {}),
  );

  const rolesOptions = useMemo(
    () => Object.values(roles || {}).sort(RoleModel.sortRoles),
    [roles],
  );

  const onCertDownload = useCallback(
    () => {
      const fileDataType = 'application/x-x509-ca-cert;base64';
      const fileData = btoa(samlProviderSettings.publicKey);
      const fileName = 'netography.crt';
      downloadFile({ fileDataType, fileData, fileName });
    },
    [samlProviderSettings.publicKey],
  );

  const onMetadataDownload = useCallback(
    () => {
      const fileDataType = 'text/plain;charset=utf-8';
      const fileData = encodeURIComponent(samlProviderMetadataXml);
      const fileName = 'metadata.xml';
      downloadFile({ fileDataType, fileData, fileName });
    },
    [samlProviderMetadataXml],
  );

  useEffect(
    () => {
      if (samlProvider?.alias) {
        setResetter(Date.now());
      }
    },
    [samlProvider],
  );

  const timer = useRef();
  const onChange = useCallback(
    (step) => ({ values, invalid }) => {
      timer.current = setTimeout(() => {
        setFormValues(values);
        setFormInvalid((prev) => ({ ...prev, [step]: invalid }));
      }, 10);
    },
    [],
  );
  useEffect(
    () => () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    },
    [],
  );

  const [, setMasqueradeUrl] = useUIProperty('masqueradeUrl');
  useEffect(
    () => {
      const rootPath = location.pathname.slice(
        0,
        location.pathname.lastIndexOf('/'),
      );
      setMasqueradeUrl(rootPath);
      return () => {
        setMasqueradeUrl(null);
      };
    },
    [location.pathname],
  );

  const allStepsValid = Object.values(formInvalid).every((item) => !item);

  return (
    <FormWizard
      className={className}
      initialValues={samlProvider}
      mode={samlProvider?.alias ? 'edit' : 'add'}
      item="SAML configuration"
      cancelButtonText={samlProvider?.alias ? 'Reset' : undefined}
      disabled={disabled}
      resetOnChancel={!!samlProvider?.alias}
      resetter={resetter}
      onCancel={samlProvider?.alias ? onNoop : onCancel}
      onSubmit={onSubmit}
    >
      <Step title="Essentials" completed={allStepsValid}>
        <FormSpy
          subscription={{ values: true, invalid: true }}
          onChange={onChange(steps.essentials)}
        />

        <FieldsSection label="SSO Login URL">
          <Group>
            <Label>Login URL</Label>
            <PlaintextForUrl>
              <CopyText text={portalSsoLoginUrl}>{portalSsoLoginUrl}</CopyText>
            </PlaintextForUrl>
            <Description>Account-specific URL for your SSO logins</Description>
          </Group>
        </FieldsSection>

        <FieldsSection label="Netography Service Provider Settings">
          <Group>
            <Label>Entity ID</Label>
            <PlaintextForUrl>
              <CopyText text={samlProviderSettings.entityId || ''}>
                {samlProviderSettings.entityId}
              </CopyText>
            </PlaintextForUrl>
            <Description>
              Netography&apos;s unique service URI. Required when setting up
              your Single Sign-on
            </Description>
          </Group>

          <Group>
            <Label>Assertion Consumer Service (ACS)</Label>
            <PlaintextForUrl>
              <CopyText text={samlProviderSettings.endpointUrl || ''}>
                {samlProviderSettings.endpointUrl}
              </CopyText>
            </PlaintextForUrl>
            <Description>
              Your accounts unique SAML ACS URL. Required when setting up your
              Single Sign-on
            </Description>
          </Group>

          <Group>
            <Label>Public Key</Label>
            <PlaintextForFile>
              <IconButton
                className="download-button"
                color="primary"
                title="Download Certificate"
                onClick={onCertDownload}
              >
                <ArrowDownBoldIcon size={16} />
              </IconButton>

              <CopyText text={samlProviderSettings.publicKey || ''}>
                {samlProviderSettings.publicKey}
              </CopyText>
            </PlaintextForFile>
            <Description>
              X.509 certificate used to sign SAML requests
            </Description>
          </Group>

          {samlProviderMetadataXml && (
            <FieldsSection label="Metadata">
              <Group>
                <Label>Metadata public URL</Label>
                <PlaintextForUrl>
                  <CopyText text={metadataXmlPublicUrl}>
                    {metadataXmlPublicUrl}
                  </CopyText>
                </PlaintextForUrl>
              </Group>

              <Group>
                <Label>Metadata.xml</Label>
                <PlaintextForFile>
                  <IconButton
                    className="download-button"
                    color="primary"
                    title="Download Metadata.xml"
                    onClick={onMetadataDownload}
                  >
                    <ArrowDownBoldIcon size={16} />
                  </IconButton>

                  <CopyText text={samlProviderMetadataXml}>
                    {samlProviderMetadataXml}
                  </CopyText>
                </PlaintextForFile>
              </Group>
            </FieldsSection>
          )}
        </FieldsSection>
      </Step>

      <Step title="Provider" completed={allStepsValid}>
        <FormSpy
          subscription={{ values: true, invalid: true }}
          onChange={onChange(steps.provider)}
        />

        <FieldsSection label="Single Sign-on metadata file">
          <Field
            name="file"
            label="Metadata File"
            component={FileUploadField}
            accept=".xml"
            validate={samlProvider?.alias ? undefined : validateRequired}
            required={!samlProvider?.alias}
            disabled={disabled}
          />
        </FieldsSection>

        <Field
          name="config.wantAssertionsEncrypted"
          component={StyledToggleField}
          type="checkbox"
          checkedLabel="Encrypt assertions"
          helperText="Encrypt assertions that are sent between the Netography and SSO."
          disabled={disabled}
          parse={(v) => (v === true ? 'true' : 'false')}
          format={(v) => v === 'true'}
        />

        <Field
          name="resellerSso"
          component={StyledToggleField}
          type="checkbox"
          checkedLabel="Self-Managed Users"
          helperText={`
          Users must be pre-created in our portal before logging in using their credentials.
          Most user data, such as profile information, is automatically synchronized,
          but roles must be managed within our portal separately.
          ${
    customer.isReseller
      ? `Sub-Accounts will be given the option to opt-in to using ${customer.shortname} configuration for SSO.`
      : ''
    }
          The setting cannot be modified once the integration is created. 
          `}
          disabled={disabled || !!samlProvider?.alias}
        />
      </Step>

      <Step title="Attributes" completed={allStepsValid}>
        <FormSpy
          subscription={{ values: true, invalid: true }}
          onChange={onChange(steps.attributes)}
        />

        <StyledAlert>
          SAML attributes can be mapped to Netography Portal user properties by
          selecting the appropriate SAML attribute for each target property
          (Netography user properties are on the left side, SAML attributes - on
          the right).
        </StyledAlert>

        <FieldsSection label="User attribute mappers">
          <Field
            name="userAttributeMappers.email"
            component={TextField}
            label="Email"
            validate={validateRequired}
            disabled={disabled}
            required
          />

          <Field
            name="userAttributeMappers.given_name"
            component={TextField}
            label="First Name"
            validate={validateRequired}
            disabled={disabled}
            required
          />

          <Field
            name="userAttributeMappers.family_name"
            component={TextField}
            label="Last Name"
            validate={validateRequired}
            disabled={disabled}
            required
          />

          <Field
            name="userAttributeMappers.nickname"
            component={TextField}
            label="Nickname"
            disabled={disabled}
          />

          <Field
            name="userAttributeMappers.phone_number"
            component={TextField}
            label="Phone Number"
            disabled={disabled}
          />

          <Field
            name="userAttributeMappers.job_title"
            component={TextField}
            label="Job Title"
            disabled={disabled}
          />

          <Field
            name="userAttributeMappers.picture"
            component={TextField}
            label="Picture URL"
            disabled={disabled}
          />
        </FieldsSection>
      </Step>

      {!formValues.resellerSso && (
        <Step title="Roles" completed={allStepsValid}>
          <FormSpy
            subscription={{ values: true, invalid: true }}
            onChange={onChange(steps.roles)}
          />

          <FieldsSection label="User role">
            <div className="default-user-role-field">
              <Field
                label="Default User Role"
                name="defaultUserRole"
                defaultValue="app_admin"
                // eslint-disable-next-line max-len
                helperText="Role that will be assigned to an SSO user upon login, if no role mappers (below) are defined, or if the role mapper configuration is invalid. Once a mapper is configured, the user's role will be updated upon their next login."
                component={SelectField}
                options={rolesOptions}
                groupBy={(item) => (item.system ? 'System' : 'Custom')}
                parse={normalizeSelectValue}
                validate={validateRequired}
                disabled={disabled}
                required
              />
            </div>
          </FieldsSection>

          <FieldsSection label="Roles mappers">
            <StyledFieldArray
              name="userRoleMappers"
              rolesOptions={rolesOptions}
              helperText="Role that will be assigned to users based on an attribute's value in the SAML response."
              component={UserRoleMapperFields}
              disabled={disabled}
            />
          </FieldsSection>
        </Step>
      )}
    </FormWizard>
  );
})`
  .fields-section-label {
    margin-left: 140px;
  }
`;

SamlConfigurationForm.propTypes = {
  samlProvider: PropTypes.shape().isRequired,
  samlProviderSettings: PropTypes.shape().isRequired,
  samlProviderMetadataXml: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

SamlConfigurationForm.defaultProps = {
  disabled: false,
  samlProviderMetadataXml: null,
};

export default SamlConfigurationForm;
