import { fork, take, put, cancel as cancelEffect } from 'redux-saga/effects';

export { default as createSlice } from './createSlice';
export { createSelector } from '@reduxjs/toolkit';
export { tryCancelSaga } from './tryCancelSaga';
export { cancelled as isCancelled } from 'redux-saga/effects';

export const takeLeading = (skipAction) => (patternOrChannel, saga, ...args) => fork(function* () {
  let task;
  while (true) {
    const action = yield take(patternOrChannel);
    if (task?.isRunning()) {
      if (skipAction) {
        yield put(skipAction());
      }
      // eslint-disable-next-line no-continue
      continue;
    }
    task = yield fork(saga, ...args, action);
  }
});

export const takeLatestOrEvery = (pattern, saga, ...args) => fork(function* () {
  let task;
  while (true) {
    const action = yield take(pattern);

    if (task && !task.takeEvery && !action?.payload?.takeEvery) {
      yield cancelEffect(task);
    }

    task = yield fork(saga, ...args, action);
    task.takeEvery = action?.payload?.takeEvery;
  }
});

/**
 * incs counter of request and set isFetching to true
 * @param state
 */
export const startFetching = (state) => {
  state.isFetching = true;
  state._requests = (state._requests ?? 0) + 1;
  state.error = '';
  return state;
};

/**
 * decs counter of request and set isFetching to false if counter less than 1.
 * @param state
 */
export const stopFetching = (state) => {
  state._requests = Math.max(0, (state._requests ?? 1) - 1);
  state.isFetching = !!state._requests;
  return state;
};

/**
 * common action for catching error.
 * Catch error and call stopFetching
 * @param state
 * @param message
 */
export const fail = (state, { payload: { message } }) => {
  stopFetching(state);
  state.error = message;
};

/**
 * common action for cancelled
 * @param state
 */
export const cancel = (state) => {
  stopFetching(state);
  state.error = '';
};

export const defaultReducers = {
  fail,
  cancel,
};

export const awaiters = new Set();

const TTLS = Symbol('TTLS');

export const pseudoTtlCache = {
  /**
   * @param {object} collection - collection for adding the item
   * @param {string} key - key for adding the item
   * @param {*} item - item for adding
   * @param {number} ttl - time to live in ms
   */
  setWithTtl(collection, key, item, ttl) {
    collection[key] = item;
    collection[TTLS] = collection[TTLS] || {};
    collection[TTLS][key] = Date.now() + ttl;
  },

  /**
   * Checks if the specified ttl of key is valid in the provided collection.
   *
   * @param {object} collection - The collection to check.
   * @param {string} key - The key to check.
   * @returns {boolean} - True if the ttl is valid, otherwise false.
   */
  isValid(collection, key) {
    const ttl = collection?.[TTLS]?.[key];
    return collection?.[key] != null && (!ttl || ttl > Date.now());
  },

  removeTtl(collection, key) {
    if (collection[TTLS]) {
      delete collection[TTLS][key];
    }
  },
};
