import { LabelOrIpColumnFactory } from '+components/Table/Columns';
import { TimestampColumnFactory } from '+components/Table/Columns/TimestampColumnFactory';
import { getColumnsHelper } from '+utils/getColumnsHelper';

export const Columns = {
  _ip: '_ip',
  start: 'start',
  lastAccess: 'lastAccess',
};

const columnsCollection = ({ labelContext }) => ({
  [Columns._ip]: LabelOrIpColumnFactory({
    Header: 'IP',
    dataFieldName: 'ip',
    labelFieldName: 'ipname',
    showLabel: labelContext.show,
    fetchLabels: true,
  }),
  [Columns.start]: TimestampColumnFactory({
    accessor: Columns.start,
    Header: 'Started',
    width: 160,
  }),
  [Columns.lastAccess]: TimestampColumnFactory({
    accessor: Columns.lastAccess,
    Header: 'Last Access',
    width: 160,
  }),
});

export const getColumns = getColumnsHelper(columnsCollection);
