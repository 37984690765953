import styled from 'styled-components';

import FieldsCol from '+components/form/FieldsCol';

const FieldsRow = styled.div`
  display: flex;
  
  .form__form-group + .form__form-group,
  ${FieldsCol} + ${FieldsCol} {
    margin-left: 15px;
  }
  
  .form__form-group {
    .form__form-group-label, .form__form-group-field {
      align-self: flex-start;
    }
  }
`;

export default FieldsRow;
