import styled from 'styled-components';

import FileUploadField from '+components/form/FileUploadField';
import { Field } from '+components/form/FinalForm';
import { validateRequired } from '+components/form/Validators';
import FormModal, { propTypes, defaultProps } from '+components/FormModal';

const HelpTextContent = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colorTextSecondary};

  border-radius: 4px;
  background-color: ${({ theme }) => theme.colorFieldBackground};
  padding: 4px;
  margin-bottom: 8px;

  section {
    width: fit-content;
    margin-top: 2px;
    border: 1px solid ${({ theme }) => theme.colorFieldsBorder};
    border-radius: 4px;
    padding: 2px 8px;
  }

  code {
    color: ${({ theme }) => theme.colorTextSecondary};
  }
`;

const ImportCsvForm = (props) => {
  const { initialValues, isOpen, onToggle, onSubmit } = props;

  return (
    <FormModal
      mode="import"
      item="port labels"
      confirmButtonText="Upload"
      initialValues={initialValues}
      isOpen={isOpen}
      onToggle={onToggle}
      onSubmit={onSubmit}
      focusOnFields={false}
      labelOnTop
    >
      <Field
        name="file"
        component={FileUploadField}
        accept=".csv,.txt"
        validate={validateRequired}
        required
      />

      <HelpTextContent>
        Must be a TXT or CSV file without headers.
        <br />
        Example:
        <section>
          <code>
            Port,Protocol,Label1,Label2
            <br />
            Port,Protocol,Label1,Label2
            <br />
            Port,Protocol,Label1,Label2
          </code>
        </section>
      </HelpTextContent>
    </FormModal>
  );
};

ImportCsvForm.propTypes = {
  initialValues: propTypes.initialValues,
  isOpen: propTypes.isOpen,
  onToggle: propTypes.onToggle,
  onSubmit: propTypes.onSubmit,
};

ImportCsvForm.defaultProps = {
  initialValues: defaultProps.initialValues,
  isOpen: defaultProps.isOpen,
  onToggle: defaultProps.onToggle,
  onSubmit: defaultProps.onSubmit,
};

export default ImportCsvForm;
