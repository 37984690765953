import PropTypes from '+prop-types';
import { useMemo } from 'react';

import { useFlag } from '@unleash/proxy-client-react';

import LeadPencilIcon from 'mdi-react/LeadPencilIcon';

import RoutePaths from '@/models/RoutePaths';

import { HeaderSubheader } from '+components/Table/Cells/HeaderSubheaderCell';

import CellWrapper from './CellWrapper';

const CellAlgorithmName = (props) => {
  const { id, name, description, original, disabled } = props;

  const isDnsEnabled = useFlag('DNS');

  const additionalMenuOptions = useMemo(
    () => (!id
      ? null
      : [
        {
          url: `${
            isDnsEnabled
              ? RoutePaths.modelsDetection
              : RoutePaths.modelsNetwork
          }/${id}`,
          icon: <LeadPencilIcon />,
          text: 'Edit',
        },
      ]),
    [id, isDnsEnabled],
  );

  return (
    <HeaderSubheader
      header={(
        <CellWrapper
          customer={original?.customer}
          field="algorithm"
          value={name}
          additionalMenuOptions={additionalMenuOptions}
          disabled={disabled}
        >
          <span>{name}</span>
        </CellWrapper>
      )}
      subHeader={description}
    />
  );
};

CellAlgorithmName.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  original: PropTypes.shape(),
  disabled: PropTypes.bool,
};

CellAlgorithmName.defaultProps = {
  description: null,
  original: null,
  disabled: false,
};

export default CellAlgorithmName;
