import PropTypes from '+prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';

import { actions, selectors } from '@/redux/api/tag';

import Button, { ButtonVariants } from '+components/Button';
import { Field, useForm, useFormState } from '+components/form/FinalForm';
import TextField from '+components/form/TextField';
import { Row as RowOrigin } from '+components/Layout';

const Row = styled(RowOrigin)`
  gap: 15px;

  .form__form-group {
    width: unset;
    flex: 1 1 0;
  }
`;

const RegexField = (props) => {
  const { name, label, helperText, placeholder, toggleRegexModal, disabled } = props;

  const dispatch = useDispatch();
  const { isFetching, testRegexError } = useSelector(selectors.getState);

  const form = useForm();
  const { values: { [name]: value } } = useFormState({ subscription: { values: true } });
  const [testError, setTestError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const onTestRegex = useCallback(
    () => {
      if (!name || !value) {
        return;
      }

      const params = {
        field: name,
        regex: value,
      };

      setTestError(null);

      dispatch(actions.testRegexFlowTags(params));
      setIsProcessing(true);
    },
    [name, value],
  );

  const validation = useCallback(
    () => testError,
    [testError],
  );

  useEffect(
    () => {
      if (isFetching || !isProcessing) {
        return;
      }

      setIsProcessing(false);

      if (testRegexError) {
        setTestError(testRegexError);
        return;
      }

      toggleRegexModal();
    },
    [isFetching, isProcessing, testRegexError],
  );

  useEffect(
    () => {
      if (!testError) {
        return;
      }
      form.mutators.touched(name, true);
    },
    [name, form, testError],
  );

  useEffect(
    () => {
      setTestError(null);
    },
    [value],
  );

  return (
    <Row>
      <Field
        name={name}
        component={TextField}
        type="text"
        placeholder={placeholder}
        maxLength={100}
        validate={validation}
        label={label}
        helperText={helperText}
        disabled={disabled}
      />
      <Button
        variant={ButtonVariants.text}
        onClick={onTestRegex}
        disabled={disabled || isFetching || !value}
      >
        Test
      </Button>
    </Row>
  );
};

RegexField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  placeholder: PropTypes.string.isRequired,
  toggleRegexModal: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

RegexField.defaultProps = {
  disabled: false,
};

export default RegexField;
