/* eslint-disable react/prop-types */
import Tag from '+components/Tag';

import CellWrapper from './CellWrapper';

const CellTag = ({ field, value, original, disabled }) => (
  <CellWrapper
    customer={original?.customer}
    field={field}
    value={value}
    disabled={disabled}
    hideUnderline
  >
    <Tag color="info" uppercased={false}>
      {value}
    </Tag>
  </CellWrapper>
);

export default CellTag;
