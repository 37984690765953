import styled from 'styled-components';

export default styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  border: none;
  cursor: pointer;
  transition: 0.3s;
  background: transparent;
  color: ${({ theme }) => theme.topbarMenuButtonColor};
  padding: unset;

  &:hover {
    color: ${({ theme, $noHover }) => ($noHover ? undefined : theme.topbarMenuButtonColorHover)};
  }

  &:focus {
    outline: none;
  }
`;
