import * as PIXI from 'pixi.js-legacy';

import { stringTruncate } from '+utils/stringTruncate';

import { colorStringToHex } from './colorStringToHex';
import { roundedRectTexture } from './textures';

const borderWidth = 1;

class RoundedRectangleContainer extends PIXI.Container {
  constructor(width, height, rx) {
    super();

    const cornerSize = rx * 3;
    const cornerTexture = roundedRectTexture(
      '#fff',
      cornerSize,
      cornerSize,
      rx,
      `${cornerSize}_${cornerSize}_${rx}`,
    );
    const pixelTexture = roundedRectTexture('#fff', 1, 1, 0, '1_1_0');

    const leftTopCorner = new PIXI.Sprite(cornerTexture);
    const rightTopCorner = new PIXI.Sprite(cornerTexture);
    const rightBottomCorner = new PIXI.Sprite(cornerTexture);
    const leftBottomCorner = new PIXI.Sprite(cornerTexture);
    const horizontalBox = new PIXI.Sprite(pixelTexture);
    const verticalBox = new PIXI.Sprite(pixelTexture);

    leftTopCorner.anchor.set(0, 0.5);
    rightTopCorner.anchor.set(0, 0.5);
    rightBottomCorner.anchor.set(0, 0.5);
    leftBottomCorner.anchor.set(0, 0.5);
    horizontalBox.anchor.set(0, 0.5);
    verticalBox.anchor.set(0, 0.5);

    this.addChild(horizontalBox);
    this.addChild(verticalBox);
    this.addChild(leftTopCorner);
    this.addChild(rightTopCorner);
    this.addChild(rightBottomCorner);
    this.addChild(leftBottomCorner);

    this.width = width;
    this.height = height;
  }

  updateProportions() {
    const { _rectWidth: width, _rectHeight: height } = this;
    const [
      horizontalBox,
      verticalBox,
      leftTopCorner,
      rightTopCorner,
      rightBottomCorner,
      leftBottomCorner,
    ] = this.children;

    const rx = leftTopCorner.width / 2;

    leftTopCorner.x = 0;
    leftTopCorner.y = -(height / 2 - rx);

    leftBottomCorner.x = 0;
    leftBottomCorner.y = height / 2 - rx;

    rightTopCorner.x = width - rx * 2;
    rightTopCorner.y = -(height / 2 - rx);

    rightBottomCorner.x = width - rx * 2;
    rightBottomCorner.y = height / 2 - rx;

    horizontalBox.x = 0;
    horizontalBox.y = 0;
    horizontalBox.width = width;
    horizontalBox.height = height - rx * 2;

    verticalBox.x = rx;
    verticalBox.y = 0;
    verticalBox.width = width - rx * 2;
    verticalBox.height = height;
  }

  set width(value) {
    this._rectWidth = value;
    this.updateProportions();
  }

  get width() {
    return super.width;
  }

  set height(value) {
    this._rectHeight = value;
    this.updateProportions();
  }

  get height() {
    return super.height;
  }

  set tint(value) {
    this._tint = value;
    this.children.forEach((child) => {
      child.tint = value;
    });
  }

  get tint() {
    return this._tint;
  }
}

/**
 * Updates the text of a PIXI.Text object.
 * And updates the width and height of background.
 * @param {PIXI.Container} container - container for container that returned by {@link createLabelsGraphic}
 * @param {string} text - text to display
 */
export const updateLabelGraphic = (container, text) => {
  const [backgroundNode, textNode] = container.children;

  textNode.text = text;
  textNode.updateText();

  const visible = !!text;

  backgroundNode.width = !visible ? 0 : textNode.width + 12;
  backgroundNode.height = !visible ? 0 : textNode.height + 4;

  container.visible = visible;
  backgroundNode.visible = visible;
  textNode.visible = visible;
};

/**
 * Creates a PIXI.Container with Label and showMore button.
 * @param {string?} labelContextText
 * @param {string?} labelText
 * @param {string?} labelCountText
 * @param {PIXI.ITextStyle} textStyle - options for PIXI.Text
 * @param {number} [maxLabelLength] - max length of label text
 * @returns {PIXI.Container}
 */
export const createLabelsGraphic = (
  labelContextText,
  labelText,
  labelCountText,
  textStyle,
  maxLabelLength,
) => {
  const labelContextContainer = new PIXI.Container();
  labelContextContainer.name = 'labelContextContainer';
  labelContextContainer.visible = !!labelContextText;

  const labelContext = new PIXI.Text(labelContextText, textStyle);
  labelContext.name = 'labelContext';
  labelContext.visible = !!labelContextText;
  labelContext.scale.set(0.5);
  labelContext.anchor.set(0, 0.5);
  labelContext.x = 6;

  const labelContextBackground = new PIXI.Sprite(
    roundedRectTexture('#fff', 1, 1, 0, '1_1_0'),
  );
  labelContextBackground.name = 'labelContextBackground';
  labelContextBackground.visible = !!labelContextText;
  labelContextBackground.anchor.set(0, 0.5);

  labelContextContainer.addChild(labelContextBackground);
  labelContextContainer.addChild(labelContext);

  const labelContainer = new PIXI.Container();
  labelContainer.name = 'labelContainer';
  labelContainer.visible = !!labelText;

  const label = new PIXI.Text(
    stringTruncate(labelText, maxLabelLength),
    textStyle,
  );
  label.name = 'label';
  label.visible = !!labelText;
  label.scale.set(0.5);
  label.anchor.set(0, 0.5);
  label.x = 6;

  const labelBackground = new PIXI.Sprite(
    roundedRectTexture('#fff', 1, 1, 0, '1_1_0'),
  );
  labelBackground.name = 'labelBackground';
  labelBackground.visible = !!labelText;
  labelBackground.anchor.set(0, 0.5);

  labelContainer.addChild(labelBackground);
  labelContainer.addChild(label);

  const labelCountContainer = new PIXI.Container();
  labelCountContainer.name = 'labelCountContainer';
  labelCountContainer.visible = !!labelCountText;

  const labelCount = new PIXI.Text(labelCountText, textStyle);
  labelCount.name = 'labelCount';
  labelCount.visible = !!labelCountText;
  labelCount.scale.set(0.5);
  labelCount.anchor.set(0, 0.5);
  labelCount.x = 6;

  const labelCountBackground = new RoundedRectangleContainer(10, 10, 6);
  labelCountBackground.name = 'labelCountBackground';
  labelCountBackground.visible = !!labelCountText;

  labelCountContainer.addChild(labelCountBackground);
  labelCountContainer.addChild(labelCount);

  updateLabelGraphic(labelContextContainer, labelContextText);

  updateLabelGraphic(labelContainer, labelText);

  updateLabelGraphic(labelCountContainer, labelCountText);

  const commonBackgroundContainer = new RoundedRectangleContainer(10, 10, 2);
  commonBackgroundContainer.name = 'commonBackground';

  const mainContainer = new PIXI.Container();
  mainContainer.maxLabelLength = maxLabelLength;

  mainContainer.addChild(commonBackgroundContainer);
  mainContainer.addChild(labelContextContainer);
  mainContainer.addChild(labelContainer);
  mainContainer.addChild(labelCountContainer);

  return mainContainer;
};

const updateParts = (container, visible, text, color, background) => {
  let hasChanged = container.visible !== visible;

  container.visible = visible;

  if (!container.visible) {
    return hasChanged;
  }

  const [backgroundContainer, textContainer] = container.children;

  let tint = colorStringToHex(color);
  if (textContainer && tint !== textContainer.tint) {
    textContainer.tint = tint;
  }

  tint = colorStringToHex(background);
  if (backgroundContainer && tint !== backgroundContainer.tint) {
    backgroundContainer.tint = tint;
  }

  if (textContainer && text !== textContainer.text) {
    hasChanged = true;
    updateLabelGraphic(container, text);
  }

  return hasChanged;
};

/**
 * Updates the text of a PIXI.Container object.
 * And updates the width and height of background.
 * @param {object} options
 * @param {PIXI.Container} options.labels - container for labels that returned by {@link createLabelsGraphic}
 * @param {boolean} options.visible - is label visible
 * @param {string} options.text - text for label
 * @param {string} options.color - label color
 * @param {string} options.background - label background color
 * @param {string} options.countText - text for show more button
 * @param {string} options.countColor - color for show more button
 * @param {string} options.countBackground - background color for show more button
 * @param {number} [options.maxLabelLength] - max length of label text
 * @returns {boolean} - true if any of texts have changed
 */
export const updateLabelsGraphic = (options) => {
  const {
    labels,
    visible,

    borderColor,

    text,
    color,
    background,

    contextText,
    contextColor,
    contextBackground,

    countText,
    countColor,
    countBackground,

    maxLabelLength,
  } = options || {};

  const [
    commonBackground,
    contextContainer,
    labelContainer,
    labelCountContainer,
  ] = labels.children;

  let hasChanged = labels.visible !== visible;

  labels.visible = visible;

  if (!labels.visible) {
    return hasChanged;
  }

  commonBackground.visible = visible;
  const tint = colorStringToHex(borderColor);
  if (tint !== commonBackground.tint) {
    commonBackground.tint = tint;
  }

  hasChanged = updateParts(
    contextContainer,
    visible,
    contextText,
    contextColor,
    contextBackground,
  ) || hasChanged;

  hasChanged = updateParts(
    labelContainer,
    visible,
    stringTruncate(text, maxLabelLength || labels.maxLabelLength),
    color,
    background,
  ) || hasChanged;

  hasChanged = updateParts(
    labelCountContainer,
    !!countText,
    countText,
    countColor,
    countBackground,
  ) || hasChanged;

  if (hasChanged) {
    contextContainer.x = !contextContainer.visible ? 0 : borderWidth;
    labelContainer.x = contextContainer.x + contextContainer.width + borderWidth;
    labelCountContainer.x = labelContainer.x + labelContainer.width + 4;
    commonBackground.width = contextContainer.x
      + contextContainer.width
      + labelContainer.width
      + borderWidth * 2;
    commonBackground.height = labelContainer.height + borderWidth * 2;
  }

  return hasChanged;
};
