import TagManager from 'react-gtm-module';

import { config } from '@/config';

export const init = () => {
  TagManager.initialize(config.gtm);
};

export const dataLayerPush = (data) => {
  if (data) {
    TagManager.dataLayer({ dataLayer: data });
  }
};

export default {
  init,
  dataLayerPush,
};
