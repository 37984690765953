import PropTypes from '+prop-types';
import { Fragment, useState, useMemo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useToggle } from 'react-use';

import { useFlag } from '@unleash/proxy-client-react';

import RoutePaths from '@/models/RoutePaths';

import {
  actions as deviceActions,
  selectors as deviceSelectors,
} from '@/redux/api/device';
import {
  actions as vpcActions,
  selectors as vpcSelectors,
} from '@/redux/api/vpc';

import ConfirmModal from '+components/ConfirmModal';
import Table from '+components/Table';
import useFocusOnRowId from '+hooks/useFocusOnRowId';
import useGlobalFilters from '+hooks/useGlobalFilters';
import useIpLabels from '+hooks/useIpLabels';
import useLoadingIndicator from '+hooks/useLoadingIndicator';

import { getColumns, Columns } from './TableColumns';
import { vpcUItoParams } from './VpcForm/utils';

const tableId = 'Sources';

const sortBy = [
  {
    id: 'name',
  },
];

const SourcesTable = (props) => {
  const { sources, permissions, ...rest } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isDnsEnabled = useFlag('DNS');

  const defaultColumns = useMemo(
    () => [
      Columns.rowSelector,
      Columns.source,
      isDnsEnabled && Columns.trafficType,
      Columns.name,
      Columns.site,
      Columns.ips,
      Columns.tags,
      Columns.samplerate,
      Columns.flow,
      Columns.bgp,
      Columns.enabled,
      Columns.menu,
    ].filter(Boolean),
    [isDnsEnabled],
  );

  const additionalColumns = useMemo(
    () => Object.keys(Columns).filter((i) => !defaultColumns.includes(i)),
    [defaultColumns],
  );

  const [filters] = useGlobalFilters();
  const { ipLabelsHash } = useIpLabels();

  const [, setRowId] = useFocusOnRowId(tableId);
  const { isFetching: isDeviceFetching, statuses: deviceStatuses } = useSelector(deviceSelectors.getState);
  const { isFetching: isVpcFetching, statuses: vpcStatuses } = useSelector(
    vpcSelectors.getState,
  );
  const statuses = useMemo(
    () => ({ ...vpcStatuses, ...deviceStatuses }),
    [deviceStatuses, vpcStatuses],
  );

  const [currentSource, setCurrentSource] = useState(false);
  const [showDelete, toggleDelete] = useToggle(false);
  const [showEnableModal, toggleEnableModal] = useToggle(false);

  const isDataLoading = isDeviceFetching || isVpcFetching;

  useLoadingIndicator(isDataLoading);

  const tableData = useMemo(
    () => (!filters.labelContext.show
      ? sources
      : (sources || []).map((el) => {
        const ipsnames = (el.ips || []).map(
          (ip) => ipLabelsHash[ip]?.[filters.labelContext.ip],
        );

        let state;
        let stateLastUpdate;

        if (statuses[el.id] && statuses[el.id]?.flowresource) {
          // if source is a cloud provider
          state = statuses[el.id].success ? 'active' : 'not polling';
          stateLastUpdate = statuses[el.id].time;
        }

        if (statuses[el.id] && !statuses[el.id]?.flowresource) {
          // if source is a device
          state = statuses[el.id].flow.active ? 'active' : 'inactive';
          stateLastUpdate = statuses[el.id].flow.lastseen * 1000;
        }

        return {
          ...el,
          ipsnames,
          state,
          stateLastUpdate,
        };
      })),
    [sources, ipLabelsHash, filters.labelContext, statuses],
  );

  const onEdit = useCallback(
    (item) => {
      setRowId(item.id);
      const path = item.flowtype
        ? RoutePaths.sourcesCloudProviders
        : RoutePaths.sourcesDevices;
      navigate(`${path}/edit/${item.id}`);
    },
    [setRowId],
  );

  useEffect(
    () => {
      if (Object.keys(vpcStatuses).length === 0) {
        dispatch(vpcActions.fetchVpcStatuses(null, 'vpcStatuses'));
      }
      return () => {
        dispatch(vpcActions.cancel('vpcStatuses'));
      };
    },
    [vpcStatuses],
  );

  const onBGPConfig = useCallback(
    (item) => {
      setRowId(item.id);
      navigate(
        `${RoutePaths.sourcesDevices}/bgp/${encodeURIComponent(item.id)}`,
      );
    },
    [setRowId],
  );

  const onDetails = useCallback(
    (item) => {
      setRowId(item.id);
      const path = item.flowtype
        ? RoutePaths.sourcesCloudProviders
        : RoutePaths.sourcesDevices;
      navigate(`${path}/details/${item.id}`);
    },
    [setRowId],
  );

  const onDeleteModalToggle = useCallback(
    (item) => () => {
      if (showDelete) {
        setCurrentSource(false);
        toggleDelete(false);
      } else {
        setCurrentSource(item);
        toggleDelete(true);
      }
    },
    [showDelete],
  );

  const onDeleteModalConfirm = useCallback(
    (item) => () => {
      if (!item?.id) {
        return;
      }
      dispatch(
        item.flowtype
          ? vpcActions.vpcDelete(item.id)
          : deviceActions.deviceDelete(item.id),
      );
    },
    [],
  );

  const onEnableToggle = useCallback(
    (item) => {
      if (showEnableModal) {
        setCurrentSource(false);
        toggleEnableModal(false);
      } else {
        setCurrentSource(item);
        toggleEnableModal(true);
      }
    },
    [showEnableModal],
  );

  const onEnableConfirm = useCallback(
    (item) => {
      if (!item?.id) {
        return;
      }
      const values = vpcUItoParams(item);
      dispatch(
        item.enabled
          ? vpcActions.vpcDisable(values)
          : vpcActions.vpcEnable(values),
      );
    },
    [],
  );

  const columns = useMemo(
    () => getColumns(defaultColumns, {
      labelContext: filters.labelContext,
      onEdit,
      onDelete: onDeleteModalToggle,
      onDetails,
      onBGPConfig,
      permissions,
      onEnableToggle,
    }),
    [
      onEdit,
      onDeleteModalToggle,
      onDetails,
      onBGPConfig,
      permissions,
      filters.labelContext,
    ],
  );

  const availableColumns = useMemo(
    () => getColumns(additionalColumns, {
      labelContext: filters.labelContext,
      onEdit,
      onDelete: onDeleteModalToggle,
      onDetails,
      onBGPConfig,
      permissions,
      onEnableToggle,
    }),
    [
      onEdit,
      onDeleteModalToggle,
      onDetails,
      onBGPConfig,
      permissions,
      filters.labelContext,
    ],
  );

  return (
    <Fragment>
      <div style={{ marginTop: '8px' }}>
        <Table
          {...rest}
          id={tableId}
          columns={columns}
          data={tableData}
          availableColumns={availableColumns}
          sortBy={sortBy}
        />
      </div>

      {showDelete && (
        <ConfirmModal
          item={currentSource?.name}
          onConfirm={onDeleteModalConfirm(currentSource)}
          onToggle={onDeleteModalToggle(currentSource)}
          isOpen
        />
      )}
      {showEnableModal && (
        <ConfirmModal
          confirmButtonText={currentSource?.enabled ? 'Disable' : 'Enable'}
          whyAsking=""
          confirmButtonColor="primary"
          item={currentSource?.name}
          onConfirm={() => onEnableConfirm(currentSource)}
          onToggle={() => onEnableToggle(currentSource)}
          isOpen
        />
      )}
    </Fragment>
  );
};

SourcesTable.propTypes = {
  filter: PropTypes.shape({}),
  sources: PropTypes.arrayOf(PropTypes.shape({})),
  permissions: PropTypes.shape({}),
};

SourcesTable.defaultProps = {
  filter: {},
  sources: [],
  permissions: null,
};

export default SourcesTable;
