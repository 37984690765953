import PropTypes from '+prop-types';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, matchPath } from 'react-router-dom';

import { useFlag } from '@unleash/proxy-client-react';
import uniq from 'lodash.uniq';

import RoutePaths from '@/models/RoutePaths';

import { selectors as blocksSelectors } from '@/redux/api/blocks';
import { selectors as eventsSelectors } from '@/redux/api/events';
import { selectors as rulesSelectors } from '@/redux/api/rules';

import { Field } from '+components/form/FinalForm';
import { normalizeSelectValue } from '+components/form/Normalizers';
import SelectField from '+components/form/SelectField';
import { validateRequired } from '+components/form/Validators';
import FormModal from '+components/FormModal';
import * as toast from '+components/toast';
import nqlLang from '+utils/nqlLang';

const AddIpToDiscardForm = (props) => {
  const { ip, isOpen, onSubmit, onToggle } = props;

  const isDnsEnabled = useFlag('DNS');

  const location = useLocation();
  const algorithms = useSelector(rulesSelectors.getAlgorithms);

  const event = useSelector((state) => {
    const { params: { id } = {} } = matchPath(`${RoutePaths.searchEvents}/:id`, location.pathname) || {};
    return id ? eventsSelectors.getRecord(id)(state) : null;
  });

  const block = useSelector((state) => {
    const { params: { id } = {} } = matchPath(`${RoutePaths.searchBlocks}/:id`, location.pathname) || {};
    return id ? blocksSelectors.getRecord(id)(state) : null;
  });

  const algorithmsOptions = useMemo(
    () => Object.values(algorithms || {}).map(({ id, name, description }) => ({
      value: id,
      label: name,
      description,
    })),
    [algorithms],
  );

  const initialValues = useMemo(
    () => {
      const path = isDnsEnabled
        ? RoutePaths.modelsDetection
        : RoutePaths.modelsNetwork;

      const { params: { id } = {} } = matchPath(`${path}/:id`, location.pathname) || {};
      const tdmId = event?.tdm?.id || block?.tdm?.[0]?.id || id;
      return { ip, tdmId };
    },
    [ip, event, block, location.pathname, isDnsEnabled],
  );

  const doSubmit = useCallback(
    (values) => {
      const algorithm = algorithms[values.tdmId];
      const discards = uniq([
        ...(algorithm.discards || []),
        nqlLang.equal('ip', initialValues.ip),
      ]);
      if (algorithm.discards.length === discards.length) {
        toast.info('IP address already in discard list');
        return;
      }
      onSubmit({
        ...algorithm,
        discards,
      });
    },
    [algorithms, onSubmit],
  );

  return (
    <FormModal
      mode="add"
      item={`IP address ${initialValues.ip} to discard list`}
      confirmButtonText="Add"
      initialValues={initialValues}
      onSubmit={doSubmit}
      onToggle={onToggle}
      focusOnFields={false}
      isOpen={isOpen}
      labelOnTop
    >
      <Field
        name="tdmId"
        label="Detection Model"
        options={algorithmsOptions}
        component={SelectField}
        parse={normalizeSelectValue}
        validate={[validateRequired]}
        required
      />
    </FormModal>
  );
};

AddIpToDiscardForm.propTypes = {
  ip: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default AddIpToDiscardForm;
