/* eslint-disable no-param-reassign */
import { UniversalCell } from '+components/Table/Cells';
import { durationFormatter } from '+components/Table/Cells/formatters';
import {
  MenuColumnFactory,
  NumberColumnFactory,
  LabelOrIpColumnFactory,
  TimestampColumnFactory,
  GeoColumnFactory,
  LabelOrPortColumnFactory,
  OwnerAsColumnFactory,
  BaseColumnFactory,
} from '+components/Table/Columns';
import { SelectColumnFilter } from '+components/Table/Filters';
import {
  autoRemoveIfAll,
  autoRemoveIfEmpty,
  withAutoRemove,
} from '+components/Table/FilterTypeFactories';
import sortByHelper from '+utils/sortByHelper';

// commented out columns can fallback on the default presentation (text)
export const Columns = {
  _source: '_source',
  _srcPort: '_srcPort',
  _srcGeo: '_srcGeo',
  _srcOwnerAs: '_srcOwnerAs',
  _destination: '_destination',
  _dstPort: '_dstPort',
  _dstGeo: '_dstGeo',
  _dstOwnerAs: '_dstOwnerAs',
  action: 'action',
  bits: 'bits',
  bitsxrate: 'bitsxrate',
  bogondst: 'bogondst',
  bogonsrc: 'bogonsrc',
  dstasAsNumber: 'dstas.number',
  dstasAsOrg: 'dstas.org',
  dstinternal: 'dstinternal',
  dstip: 'dstip',
  dstipname: 'label.ip.name.dst',
  dstiprepCategories: 'dstiprep.categories',
  dstiprepCount: 'dstiprep.count',
  dstOwnerAsNumber: 'dstowneras.number',
  dstOwnerAsOrg: 'dstowneras.org',
  dstport: 'dstport',
  dstportname: 'label.port.name.dst',
  duration: 'duration',
  end: 'end',
  flowbrate: 'flowbrate',
  flowsrcip: 'flowsrcip',
  nexthop: 'nexthop',
  packets: 'packets',
  protocol: 'protocol',
  protocolint: 'protocolint',
  srcAsNumber: 'srcas.number',
  srcAsOrg: 'srcas.org',
  srcip: 'srcip',
  srcipname: 'label.ip.name.src',
  srciprepCategories: 'srciprep.categories',
  srcport: 'srcport',
  srcportname: 'label.port.name.src',
  srcOwnerAsNumber: 'srcowneras.number',
  srcOwnerAsOrg: 'srcowneras.org',
  start: 'start',
  tags: 'tags',
  tcpflags: 'tcpflags', // custom
  timestamp: 'timestamp',
  customer: 'customer',
  menu: 'menu',
};

const tcpFlagsExtractor = (value) => Object.keys(value || {})
  .filter((key) => value[key])
  .sort()
  .join('');

export const columnsCollection = ({ labelContext, cxActionMenu }) => {
  const collection = {
    [Columns._source]: LabelOrIpColumnFactory({
      Header: 'Source',
      dataFieldName: 'srcip',
      labelFieldName: `label.ip.${labelContext.ip}.src`,
      showLabel: labelContext.show,
    }),
    [Columns._srcPort]: LabelOrPortColumnFactory({
      Header: 'SRC Port',
      dataFieldName: 'srcport',
      labelFieldName: `label.port.${labelContext.port}.src`,
      showLabel: labelContext.show,
    }),
    [Columns._srcGeo]: GeoColumnFactory({
      Header: 'SRC Geo',
      field: 'srcgeo',
    }),
    [Columns._srcOwnerAs]: OwnerAsColumnFactory({
      Header: 'SRC Owner AS',
      field: 'srcowneras',
    }),
    [Columns._destination]: LabelOrIpColumnFactory({
      Header: 'Destination',
      dataFieldName: 'dstip',
      labelFieldName: `label.ip.${labelContext.ip}.dst`,
      showLabel: labelContext.show,
    }),
    [Columns._dstPort]: LabelOrPortColumnFactory({
      Header: 'DST Port',
      dataFieldName: 'dstport',
      labelFieldName: `label.port.${labelContext.port}.dst`,
      showLabel: labelContext.show,
    }),
    [Columns._dstGeo]: GeoColumnFactory({
      Header: 'DST Geo',
      field: 'dstgeo',
    }),
    [Columns._dstOwnerAs]: OwnerAsColumnFactory({
      Header: 'DST Owner AS',
      field: 'dstowneras',
    }),
    [Columns.action]: {
      getCellProps: () => ({ style: { justifyContent: 'center' } }),
      width: 100,
    },
    [Columns.bits]: NumberColumnFactory({
      accessor: Columns.bits,
      width: 80,
    }),
    [Columns.bogondst]: BaseColumnFactory({
      width: 70,
    }),
    [Columns.bogonsrc]: BaseColumnFactory({
      width: 70,
    }),
    [Columns.bitsxrate]: NumberColumnFactory({
      accessor: Columns.bitsxrate,
      width: 80,
    }),

    [Columns.dstasAsNumber]: NumberColumnFactory({
      accessor: Columns.dstasAsNumber,
      width: 110,
    }),

    [Columns.dstasAsOrg]: BaseColumnFactory({
      width: 100,
    }),
    [Columns.dstinternal]: BaseColumnFactory({
      width: 80,
    }),
    [Columns.dstip]: BaseColumnFactory({
      width: 110,
      getCellProps: () => ({ style: { justifyContent: 'flex-end' } }),
      sortType: 'ip',
    }),
    [Columns.dstipname]: BaseColumnFactory({
      width: 110,
      getCellProps: () => ({ style: { justifyContent: 'flex-end' } }),
      Cell: UniversalCell(Columns.dstipname),
    }),
    [Columns.dstiprepCategories]: BaseColumnFactory({
      width: 130,
    }),
    [Columns.dstiprepCount]: NumberColumnFactory({
      accessor: Columns.dstiprepCount,
      width: 100,
    }),
    [Columns.dstport]: NumberColumnFactory({
      accessor: Columns.dstport,
      width: 90,
    }),

    [Columns.dstportname]: BaseColumnFactory({
      width: 90,
      getCellProps: () => ({ style: { justifyContent: 'flex-end' } }),
      Cell: UniversalCell(Columns.dstportname),
    }),
    [Columns.duration]: NumberColumnFactory({
      accessor: Columns.duration,
      width: 70,
      filter: withAutoRemove((rows, [id], filterValue) => {
        if (autoRemoveIfEmpty(filterValue)) {
          return rows;
        }

        const normalizedFilterValue = String(filterValue).toLowerCase();
        return rows.filter(({ values: { [id]: value } }) => durationFormatter(value, 'milliseconds').includes(
          normalizedFilterValue,
        ));
      }, autoRemoveIfEmpty),
    }),
    [Columns.end]: NumberColumnFactory({
      accessor: Columns.end,
      width: 140,
    }),
    [Columns.flowbrate]: NumberColumnFactory({
      accessor: Columns.flowbrate,
      width: 80,
    }),

    [Columns.flowsrcip]: BaseColumnFactory({
      getCellProps: () => ({ style: { justifyContent: 'flex-end' } }),
      Cell: UniversalCell(Columns.flowsrcip),
      sortType: 'ip',
    }),
    [Columns.nexthop]: BaseColumnFactory({
      Cell: UniversalCell(Columns.nexthop),
    }),
    [Columns.packets]: NumberColumnFactory({ accessor: Columns.packets }),
    [Columns.protocol]: BaseColumnFactory({
      getCellProps: () => ({ style: { justifyContent: 'center' } }),
      width: 80,
      Filter: SelectColumnFilter({
        optionLabel: (key) => (key === 'all' ? 'All' : key),
        enableLikeFilter: true,
      }),
      filter: 'selectFilter',
    }),
    [Columns.srcAsNumber]: NumberColumnFactory({
      accessor: Columns.srcAsNumber,
      width: 80,
      genericCell: true,
    }),
    [Columns.srcAsOrg]: BaseColumnFactory({}),
    [Columns.srcip]: BaseColumnFactory({
      width: 110,
      getCellProps: () => ({ style: { justifyContent: 'flex-end' } }),
      sortType: 'ip',
    }),
    [Columns.srcipname]: BaseColumnFactory({
      width: 110,
      getCellProps: () => ({ style: { justifyContent: 'flex-end' } }),
      Cell: UniversalCell(Columns.srcipname),
    }),
    [Columns.srciprepCategories]: BaseColumnFactory({
      width: 140, // in order to fit the column header, not data
    }),
    [Columns.srcOwnerAsNumber]: NumberColumnFactory({
      accessor: Columns.srcOwnerAsNumber,
      width: 144, // in order to fit the column header, not data
    }),
    [Columns.srcOwnerAsOrg]: BaseColumnFactory({
      width: 140,
    }),
    [Columns.srcport]: NumberColumnFactory({
      accessor: Columns.srcport,
      width: 90,
    }),
    [Columns.srcportname]: BaseColumnFactory({
      width: 90,
      getCellProps: () => ({ style: { justifyContent: 'flex-end' } }),
      Cell: UniversalCell(Columns.srcportname),
    }),
    [Columns.start]: NumberColumnFactory({
      accessor: Columns.start,
      width: 140,
    }),
    [Columns.tags]: BaseColumnFactory({
      getCellProps: () => ({ style: { whiteSpace: 'unset' } }),
    }),
    [Columns.tcpflags]: BaseColumnFactory({
      accessor: Columns.tcpflags,
      Header: Columns.tcpflags,
      getCellProps: () => ({ style: { whiteSpace: 'unset' } }),
      Cell: UniversalCell(Columns.tcpflags),
      Filter: SelectColumnFilter({
        optionValueExtractor: (row, id) => Object.keys(row.values[id] || {}),
        optionLabel: (key) => (key === 'all' ? 'All' : key.toUpperCase()),
        sort: false,
      }),
      filter: withAutoRemove((rows, [id], filterValue) => {
        if (autoRemoveIfAll(filterValue)) {
          return rows;
        }

        return rows.filter(
          ({ values: { [id]: value } }) => !!value?.[filterValue.value],
        );
      }, autoRemoveIfAll),
      sortType: sortByHelper(tcpFlagsExtractor),
    }),
    [Columns.timestamp]: TimestampColumnFactory({ width: 160 }),
    [Columns.customer]: BaseColumnFactory({
      accessor: 'customer',
      Header: 'Account',
      width: 160,
      Filter: SelectColumnFilter({
        optionLabel: (key) => (key === 'all' ? 'All' : key),
      }),
      filter: 'selectFilter',
    }),
    [Columns.menu]: MenuColumnFactory({ cxActionMenu }),
  };

  Object.keys(collection).forEach((key) => {
    if (!collection[key].accessor) {
      collection[key].accessor = key;
    }
  });

  return collection;
};
