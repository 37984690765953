import styled from 'styled-components';

export default styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  margin-left: 15px;
  gap: 4px;
  overflow: hidden;
`;
