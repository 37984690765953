import nqlLang from '+utils/nqlLang';

export const getStreamNql = (data, fullStreamOnly = true) => {
  const { srcip, srcport, dstip, dstport, protocol } = data || {};

  const isFullStream = srcip && dstip && protocol;
  if (!isFullStream && fullStreamOnly) {
    return undefined;
  }

  // if srcport or dstport == 0 or null or empty, then omit from the NQL
  const stream = {};
  if (srcip) {
    stream.srcip = srcip;
  }
  if (srcport) {
    stream.srcport = srcport;
  }
  if (dstip) {
    stream.dstip = dstip;
  }
  if (dstport) {
    stream.dstport = dstport;
  }
  if (protocol) {
    stream.protocol = protocol;
  }
  return nqlLang.and(
    ...Object.entries(stream).map(([_key, _value]) => nqlLang.equal(_key, _value)),
  );
};
