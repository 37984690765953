import dataBus from '../../../../services/dataBus';
import { Path } from './Path';
import { Point } from './Point';

const drawPoint = (ctx, point) => {
  const x = +point.x - point.radius - point.lineWidth;
  const y = +point.y - point.radius - point.lineWidth;

  ctx.save();
  ctx.translate(x, y);

  point.draw(ctx);

  ctx.translate(-x, -y);
  ctx.restore();
};

export class Splash {
  constructor({ source, target, path, value }) {
    this.id = performance.now();

    this.renderSettings = {
      sourceShow: source && source.show,
      targetShow: target && target.show,
    };

    this.renderSettings.pathShow = this.renderSettings.sourceShow
      && this.renderSettings.targetShow
      && path
      && path.show;

    this._dead = !(
      this.renderSettings.sourceShow || this.renderSettings.targetShow
    );

    this._canDraw = false;

    this._deadTime = 40;

    this._value = value;

    if (this.renderSettings.sourceShow) {
      const sourceOptions = {
        ...source.style,
        point: source.point,
      };
      this._setSourcePoint(sourceOptions);
    }

    if (this.renderSettings.targetShow) {
      const targetOptions = {
        ...target.style,
        point: target.point,
      };
      this._setTargetPoint(targetOptions);
    }

    if (this.renderSettings.pathShow) {
      const pathOptions = {
        ...path.style,
        source: this.sourcePoint,
        target: this.targetPoint,
      };
      this._setPath(pathOptions);
    }

    if (!this._dead) {
      const listenerId = `waitPrefix_${this.id}`;
      dataBus.on(listenerId, ({ type, data: { id } }) => {
        if (type !== 'prefixDone') {
          return;
        }

        if (this.id === id) {
          this._canDraw = true;

          dataBus.off(listenerId);
        }
      });

      dataBus.emit({
        type: 'drawPrefix',
        data: {
          id: this.id,
          point: this.sourcePoint.point,
          color: source.style && source.style.color,
          value,
        },
      });
    }
  }

  /**
   * @param options
   * @private
   */
  _setSourcePoint(options) {
    this.sourcePoint = new Point(options);
  }

  /**
   * @param options
   * @private
   */
  _setTargetPoint(options) {
    this.targetPoint = new Point(options);
  }

  /**
   * @param options
   * @private
   */
  _setPath(options) {
    this.path = new Path(options);
  }

  get isDead() {
    return this._dead;
  }

  draw(ctx) {
    if (!this._canDraw || this._dead) {
      return;
    }

    this._dead = (!this.sourcePoint || this.sourcePoint.isDead)
      && (!this.targetPoint || this.targetPoint.isDead);

    if (!this.sourcePoint || !this.targetPoint) {
      this._deadTime -= 1;
      if (this._deadTime < 0) {
        if (this.targetPoint && !this.targetPoint.isDead) {
          this.targetPoint.kill();
        } else if (this.sourcePoint && !this.sourcePoint.isDead) {
          this.sourcePoint.kill();
        } else {
          this._dead = true;
        }
      }
    }

    if (this.renderSettings.sourceShow) {
      drawPoint(ctx, this.sourcePoint);
    }

    if (this.renderSettings.targetShow) {
      drawPoint(ctx, this.targetPoint);
    }

    if (this.renderSettings.pathShow) {
      this.path.source = this.sourcePoint.center();
      this.path.target = this.targetPoint.center();

      this.path.draw(ctx);

      if (this.path.atTarget) {
        if (this.sourcePoint) {
          this.sourcePoint.kill();
        }
        if (this.targetPoint) {
          this.targetPoint.kill();
        }
      }
    }
  }
}
