import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import CloudProviderTypes from '@/models/CloudProviderTypes';
import { ContextTypes } from '@/models/ContextTypes';
import RoutePaths from '@/models/RoutePaths';

import { actions, selectors } from '@/redux/api/vpc';

import useMaxSources from '+hooks/useMaxSources';
import usePageTabs from '+hooks/usePageTabs';
import useProviderClassification from '+hooks/useProviderClassification';

import { cleanUpFields } from '../../utils';
import VpcForm from './Form';

const providers = Object.keys(CloudProviderTypes);

const VpcAdd = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { flowtype, flowresource, traffictype } = useParams();
  const [, activePageTab] = usePageTabs();

  const classification = useProviderClassification(flowtype, flowresource);

  // track if we have intercepted a submit and if we are waiting on the API/dispatch to complete
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { isFetching, error } = useSelector(selectors.getState);
  const { maxDownsample } = useMaxSources();

  const handleSubmit = useCallback(
    (values) => {
      const data = cleanUpFields(values);
      setIsSubmitting(activePageTab?.id);
      dispatch(actions.vpcAdd(data));
    },
    [activePageTab?.id],
  );

  useEffect(
    () => {
      if (!isSubmitting || isFetching) {
        return;
      }

      if (isSubmitting !== activePageTab?.id) {
        return;
      }

      if (!error) {
        navigate(`${RoutePaths.sources}`);
      } else {
        setIsSubmitting(false);
      }
    },
    [isSubmitting, isFetching, error, activePageTab?.id],
  );

  if (!providers.includes(`${flowtype}/${flowresource}`)) {
    return <Navigate to={`${RoutePaths.sources}`} replace />;
  }

  // convert nested API object to flattenened UI object
  const initialValues = {
    enabled: true,
    flowtype,
    flowresource,
    samplerate: maxDownsample ?? 1,
    traffictype: classification?.[traffictype]
      ? traffictype
      : ContextTypes.flow,
  };

  return (
    <VpcForm
      mode="create"
      initialValues={initialValues}
      createCallback={handleSubmit}
      loading={!!isSubmitting && isFetching}
    />
  );
};

export default VpcAdd;
