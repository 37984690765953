import { put } from 'redux-saga/effects';

import {
  createSlice,
  startFetching,
  stopFetching,
  tryCancelSaga,
  defaultReducers,
  createSelector,
} from '@/redux/util';

export const initialState = {
  isFetching: false,
  error: '',
  reseller_login_enabled: false,
  reseller_data_agg_enabled: false,
};

const apiPath = '/customer/security';

const slice = createSlice({
  name: 'customer/security',
  initialState,

  reducers: {
    ...defaultReducers,
    fetchResellerLoginStatus: startFetching,
    fetchResellerLoginStatusSuccess(
      state,
      { payload: { data: { data } = {} } = {} },
    ) {
      stopFetching(state);
      state.reseller_login_enabled = data.enabled;
    },

    enableResellerLogin: startFetching,
    enableResellerLoginSuccess(
      state,
      { payload: { data: { data } = {} } = {} },
    ) {
      stopFetching(state);
      state.reseller_login_enabled = data.enabled;
    },

    disableResellerLogin: startFetching,
    disableResellerLoginSuccess(
      state,
      { payload: { data: { data } = {}, masqueradeUrl } = {} },
    ) {
      stopFetching(state);
      state.reseller_login_enabled = data.enabled;
      if (masqueradeUrl) {
        setTimeout(() => {
          document.location = masqueradeUrl;
        }, 10);
      }
    },

    fetchResellerDataAggStatus: startFetching,
    fetchResellerDataAggStatusSuccess(
      state,
      { payload: { data: { data } = {} } = {} },
    ) {
      stopFetching(state);
      state.reseller_data_agg_enabled = data.enabled;
    },

    enableResellerDataAgg: startFetching,
    enableResellerDataAggSuccess(
      state,
      { payload: { data: { data } = {} } = {} },
    ) {
      stopFetching(state);
      state.reseller_data_agg_enabled = data.enabled;
    },

    disableResellerDataAgg: startFetching,
    disableResellerDataAggSuccess(
      state,
      { payload: { data: { data } = {} } = {} },
    ) {
      stopFetching(state);
      state.reseller_data_agg_enabled = data.enabled;
    },
  },

  sagas: (actions) => ({
    [actions.fetchResellerLoginStatus]: {
      * saga({ payload }) {
        yield tryCancelSaga(
          'get',
          {
            successAction: actions.fetchResellerLoginStatusSuccess,
            errorAction: actions.fail,
            toasts: {
              error: 'Error fetching reseller login status',
            },
          },
          `${apiPath}/reseller-login/status`,
          payload,
        );
      },
    },

    [actions.enableResellerLogin]: {
      * saga() {
        yield tryCancelSaga(
          'put',
          {
            successAction: actions.enableResellerLoginSuccess,
            errorAction: actions.fail,
            toasts: {
              error: 'Error enabling reseller login',
            },
          },
          `${apiPath}/reseller-login/enable`,
        );
      },
    },

    [actions.disableResellerLogin]: {
      * saga({ payload: masqueradeUrl }) {
        yield tryCancelSaga(
          'put',
          {
            * success(result) {
              yield put(
                actions.disableResellerLoginSuccess({
                  ...(result || {}),
                  masqueradeUrl,
                }),
              );
            },
            errorAction: actions.fail,
            toasts: {
              error: 'Error disabling reseller login',
            },
          },
          `${apiPath}/reseller-login/disable`,
        );
      },
    },

    [actions.fetchResellerDataAggStatus]: {
      * saga({ payload }) {
        yield tryCancelSaga(
          'get',
          {
            successAction: actions.fetchResellerDataAggStatusSuccess,
            errorAction: actions.fail,
            toasts: {
              error: 'Error fetching reseller data aggregation status',
            },
          },
          `${apiPath}/reseller-data-agg/status`,
          payload,
        );
      },
    },

    [actions.enableResellerDataAgg]: {
      * saga() {
        yield tryCancelSaga(
          'put',
          {
            successAction: actions.enableResellerDataAggSuccess,
            errorAction: actions.fail,
            toasts: {
              error: 'Error enabling reseller data aggregation',
            },
          },
          `${apiPath}/reseller-data-agg/enable`,
        );
      },
    },

    [actions.disableResellerDataAgg]: {
      * saga() {
        yield tryCancelSaga(
          'put',
          {
            successAction: actions.disableResellerDataAggSuccess,
            errorAction: actions.fail,
            toasts: {
              error: 'Error disabling reseller data aggregation',
            },
          },
          `${apiPath}/reseller-data-agg/disable`,
        );
      },
    },
  }),

  selectors: (getState) => ({
    isFetching: createSelector(
      [getState],
      (state) => state.isFetching,
    ),

    getResellerLoginStatus: createSelector(
      [getState],
      (state) => state.reseller_login_enabled,
    ),

    getResellerDataAggStatus: createSelector(
      [getState],
      (state) => state.reseller_data_agg_enabled,
    ),
  }),
});

export const { actions, selectors } = slice;

export default slice;
