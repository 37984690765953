import PropTypes from '+prop-types';

import FormField from '+components/form/FormField';

import createDescriptionLinks from '../createDescriptionLinks';
import Toggle from './Toggle';

const ToggleField = (props) => {
  const {
    className,
    label,
    helperText,
    meta,
    disabled,
    required,
    parseDescriptionUrls,
    ...tail
  } = props;

  const { touched, error, dirty, submitFailed } = meta;
  const invalid = error && (dirty || submitFailed) && touched;

  return (
    <FormField
      label={label}
      helperText={parseDescriptionUrls ? createDescriptionLinks(helperText) : helperText}
      error={error}
      invalid={invalid}
      disabled={disabled}
      required={required}
      className={className}
    >
      <Toggle
        {...tail}
        meta={meta}
        required={required}
        disabled={disabled}
      />
    </FormField>
  );
};

ToggleField.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    dirty: PropTypes.bool,
    submitFailed: PropTypes.bool,
  }),
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  helperText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  uncheckedLabel: PropTypes.string,
  checkedLabel: PropTypes.string,
  checked: PropTypes.bool,
  uppercaseLabels: PropTypes.bool,
  parseDescriptionUrls: PropTypes.bool,
};

ToggleField.defaultProps = {
  className: null,
  type: 'text',
  meta: null,
  label: null,
  helperText: null,
  disabled: false,
  readOnly: false,
  required: false,
  uncheckedLabel: null,
  checkedLabel: null,
  checked: false,
  uppercaseLabels: true,
  parseDescriptionUrls: false,
};

export default ToggleField;
