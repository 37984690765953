import PropTypes from '+prop-types';
import { useMemo, useEffect, useState, useRef, memo } from 'react';
import { useDispatch } from 'react-redux';

import styled, { css } from 'styled-components';

import AddIcon from 'mdi-react/AddIcon';
import ArrowTopLeftIcon from 'mdi-react/ArrowTopLeftIcon';
import DeleteForeverIcon from 'mdi-react/DeleteForeverIcon';

import { ContextTypesLabels } from '@/models/ContextTypes';

import { actions as globalFiltersActions } from '@/redux/globalFilters';

import { Field, useField, useFieldArray } from '+components/form/FinalForm';
import {
  Group as GroupOrigin,
  FieldContainer,
  Label,
  Description,
} from '+components/form/FormField';
import NqlTextField from '+components/form/NqlTextField';
import { validateRequired } from '+components/form/Validators';
import IconButtonOrigin from '+components/IconButton';
import { Col, LayoutSizes, Row } from '+components/Layout';
import * as toast from '+components/toast';
import useEvent from '+hooks/useEvent';
import { useVerifyNqlBeforeSend } from '+hooks/useVerifyNqlBeforeSend';
import getNqlFieldName from '+utils/getNqlFieldName';
import makeArr from '+utils/makeArr';

const Group = styled(GroupOrigin)`
  ${(props) => !props.$isLabelVisible
    && css`
      .form__form-group-field {
        margin-left: unset !important;
        padding-left: unset !important;
        width: 100% !important;
      }
    `}
`;

const IconButton = styled(IconButtonOrigin)`
  visibility: ${(props) => (props.$hidden ? 'hidden' : 'visible')};
  pointer-events: ${(props) => (props.$hidden ? 'none' : 'auto')};
  margin-top: 4px;
`;

const defaultNql = '';

const parse = (v) => v;

const NQLItem = memo((props) => {
  const {
    name,
    fieldComponent,
    index,
    placeholder,
    allowPresets,
    presetsLeftOffset,
    fieldLength,
    maxLength,
    docTemplateConfig,
    context,
    required,
    disabled,
    showGfButton,
    onAdd,
    onRemove,
    onEnterPress,
    onPushToGlobalFilters,
  } = props;

  const { input, meta } = useField(name);
  const isPushToGFDisabled = meta?.active || meta?.validating || !meta?.valid || !input?.value;

  const { validateNql } = useVerifyNqlBeforeSend(context, name);

  const nqlValidate = useMemo(
    () => [required && validateRequired, validateNql],
    [required, validateNql],
  );

  const doRemove = useEvent(() => {
    onRemove?.(index);
  });

  const doPushToGlobalFilters = useEvent(() => {
    onPushToGlobalFilters?.(index);
  });

  return (
    <Row gap={LayoutSizes.groupGap} alignItems="flex-start" wrap="nowrap">
      <Field
        name={name}
        component={fieldComponent}
        context={context}
        docTemplateConfig={docTemplateConfig}
        placeholder={placeholder}
        allowPresets={allowPresets}
        presetsLeftOffset={presetsLeftOffset}
        validate={nqlValidate}
        // otherwise it returns undefined even if you will push ''
        // @see: https://final-form.org/docs/react-final-form/types/FieldProps#parse
        parse={parse}
        required={required}
        disabled={disabled}
        onEnterPress={onEnterPress}
      />

      {showGfButton && (
        <IconButton
          color="primary"
          title="Push NQL to Global Filters"
          disabled={isPushToGFDisabled}
          onClick={doPushToGlobalFilters}
        >
          <ArrowTopLeftIcon size={16} />
        </IconButton>
      )}

      {fieldLength > 1 && (
        <IconButton
          color="primary"
          title="Remove NQL"
          disabled={disabled}
          onClick={doRemove}
          data-tracking="remove-nql-query"
        >
          <DeleteForeverIcon size={16} />
        </IconButton>
      )}

      {maxLength > 1 && (
        <IconButton
          color="primary"
          title="Add NQL"
          $hidden={fieldLength !== index + 1}
          disabled={disabled || fieldLength === maxLength}
          onClick={onAdd}
          data-tracking="add-nql-query"
        >
          <AddIcon size={16} />
        </IconButton>
      )}
    </Row>
  );
});

NQLItem.propTypes = {
  name: PropTypes.string.isRequired,
  fieldComponent: PropTypes.elementType.isRequired,
  index: PropTypes.number.isRequired,
  placeholder: PropTypes.string,
  allowPresets: PropTypes.bool,
  presetsLeftOffset: PropTypes.string,
  fieldLength: PropTypes.number.isRequired,
  maxLength: PropTypes.number.isRequired,
  docTemplateConfig: PropTypes.shape({}),
  context: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  showGfButton: PropTypes.bool.isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onEnterPress: PropTypes.func.isRequired,
  onPushToGlobalFilters: PropTypes.func.isRequired,
};

NQLItem.defaultProps = {
  placeholder: undefined,
  allowPresets: undefined,
  presetsLeftOffset: undefined,
  docTemplateConfig: undefined,
};

const getIsInvalid = (node) => {
  if (!node) {
    return false;
  }
  const errors = node.querySelectorAll('.form__form-group-error');
  return !!errors.length;
};

const ArrayNQLField = (props) => {
  const {
    style,
    fields,
    fieldComponent,
    label,
    helperText,
    placeholder,
    allowPresets,
    presetsLeftOffset,
    context,
    maxLength,
    docTemplateConfig,
    required,
    disabled,
    showGfButton,
    onEnterPress,
  } = props;

  const dispatch = useDispatch();

  const fieldsColRef = useRef();
  const { meta } = useFieldArray(fields.name, {});
  const [fieldInvalid, setFieldInvalid] = useState(false);
  const fieldLength = fields.value?.length || 0;
  const isRequired = required || fieldLength > 1;
  const isLabelVisible = !!fieldLength && label;
  const isHelperTextVisible = !!fieldLength && helperText;

  const groupStyle = useMemo(
    () => ({
      ...(style || {}),
      display: maxLength ? null : 'none',
    }),
    [style, maxLength],
  );

  const onAdd = useEvent(() => {
    fields.push(defaultNql);
  });

  const onRemove = useEvent((index) => {
    fields.remove(index);
  });

  const doEnterPress = useEvent((event) => {
    if (event?.key === 'Enter') {
      onEnterPress?.(event);
    }
  });

  const onPushToGlobalFilters = useEvent((index) => {
    dispatch(
      globalFiltersActions.changeFilter({
        context,
        [getNqlFieldName(context)]: makeArr(fields.value[index]),
      }),
    );
    toast.success(`Copied to ${ContextTypesLabels[context]} Global Filter`);
  });

  const refreshInvalid = useEvent(() => {
    const next = getIsInvalid(fieldsColRef.current);
    if (fieldInvalid !== next) {
      setFieldInvalid(next);
    }
  });

  useEffect(
    () => {
      if (!isLabelVisible) {
        return undefined;
      }

      const timer = setTimeout(refreshInvalid, 100);

      return () => {
        clearTimeout(timer);
      };
    },
    [isLabelVisible, fields.name, meta.error, meta.submitFailed, meta.dirty],
  );

  return (
    <Group id={fields.name} style={groupStyle} $isLabelVisible={isLabelVisible}>
      {isLabelVisible && (
        <Label required={isRequired} invalid={fieldInvalid}>
          {label}
        </Label>
      )}

      <FieldContainer>
        <Col gap="15px" ref={fieldsColRef}>
          {fields.map((name, index) => (
            <NQLItem
              // we need to update component on fieldLength change to refresh validation
              // otherwise if zero element removed, validation will not be triggered and 'Required' error will be shown
              key={`${name}-${fieldLength}`}
              name={name}
              fieldComponent={fieldComponent}
              index={index}
              placeholder={placeholder}
              allowPresets={allowPresets}
              presetsLeftOffset={presetsLeftOffset}
              fieldLength={fieldLength}
              maxLength={maxLength}
              docTemplateConfig={docTemplateConfig}
              context={context}
              required={isRequired}
              disabled={disabled}
              showGfButton={!!showGfButton}
              onAdd={onAdd}
              onRemove={onRemove}
              onEnterPress={doEnterPress}
              onPushToGlobalFilters={onPushToGlobalFilters}
            />
          ))}
        </Col>
      </FieldContainer>

      {isHelperTextVisible && <Description>{helperText}</Description>}
    </Group>
  );
};

ArrayNQLField.propTypes = {
  style: PropTypes.shape(),
  fields: PropTypes.shape().isRequired,
  fieldComponent: PropTypes.elementType,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  helperText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  placeholder: PropTypes.string,
  allowPresets: PropTypes.bool,
  presetsLeftOffset: PropTypes.string,
  context: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  docTemplateConfig: PropTypes.shape({}),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  showGfButton: PropTypes.bool,
  onEnterPress: PropTypes.func,
};

ArrayNQLField.defaultProps = {
  style: null,
  fieldComponent: NqlTextField,
  label: '',
  helperText: '',
  placeholder: undefined,
  allowPresets: undefined,
  presetsLeftOffset: undefined,
  maxLength: Number.MAX_SAFE_INTEGER,
  docTemplateConfig: undefined,
  required: false,
  disabled: false,
  showGfButton: false,
  onEnterPress: null,
};

export default ArrayNQLField;
