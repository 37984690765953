/**
 * @param {string} shortname
 * @param {string} pageTitle
 * @param {string} chartTitle
 * @returns {string}
 */
export const getExportingFilename = ({
  shortname,
  pageTitle,
  chartTitle,
}) => {
  const root = document.createElement('div');
  root.innerHTML = chartTitle;
  const texts = Array.from(root.querySelectorAll('div'))
    .map((v) => {
      return v.textContent || v.innerText || '';
    });
  const normalizedChartTitle = texts[0] || chartTitle || 'unnamed';
  return `${shortname} ${pageTitle} ${normalizedChartTitle}`
    .replace(/[^a-z0-9]/gi, '_')
    .substring(0, 255)
    .toLowerCase();
};
