/**
 * @callback ChildrenCallback
 * @return AccumulatorTreeNode[]
 */

/**
 * @typedef {Object} AccumulatorTreeNode
 * @property {string} relPath
 * @property {AccumulatorTreeNode} parent
 * @property {ChildrenCallback} children
 * @property {Record<string, AccumulatorTreeNode>} childrenMap
 */

/**
 * @callback NodeMutator
 * @param {AccumulatorTreeNode} node
 * @param {Object} y
 * @param {Number} depth
 */

/**
 * Constructor for AccumulatorTreeNode type
 * @param {string} relPath
 * @param {Object} data data object to merge into AccumulatorTreeNode
 * @param {AccumulatorTreeNode} [parent] optional parent pointer
 * @return {AccumulatorTreeNode}
 * @constructor
 */
export const AccumulatorTreeNode = (relPath, data, parent) => ({
  ...data,
  relPath,
  get parent() {
    return parent || null;
  },
  children() {
    return Object.values(this.childrenMap);
  },
  childrenMap: {},
});

/**
 * A tree that follows a given path to insert/remove a node. If part of the path does not
 * exist it will be created. Otherwise it will accumulate data on the nodes along
 * the path. Each lookup for a path is O(N) where N is length of path.
 */
export class AccumulatorTree {
  root;

  merger;

  unmerger;

  countDepthAt;

  _count;

  constructor(countDepthAt = -1) {
    this.root = null;
    this.countDepthAt = countDepthAt;
    this._count = 0;
  }

  /**
   * Count of the inserted node or nodes at a certain depth defined by countDepthAt
   * @return {Number}
   */
  get count() {
    return this._count;
  }

  /**
   * Inserts data at the end of the path. At every point along the path, if a node
   * exists there, then merger is called to merge the data passed into insert.
   *
   * @param {string} path path to insert node on
   * @param {Object} data data to insert at path end
   * @param {NodeMutator} [merger] function that merges the supplied data down the tree.
   *  First argument is the existing node, second is the data supplied,
   *  should mutate the existing arg with the data.
   */
  insert(path, data, merger) {
    if (!path.length) {
      return;
    }

    merger = merger || this.merger;
    if (!merger) {
      throw Error('Merge function must be set');
    }

    let currentNode = this.root;
    let parentNode;

    for (let i = 0; i < path.length; i += 1) {
      if (!currentNode) {
        if (
          this.countDepthAt === i
          || (this.countDepthAt === -1 && i === path.length - 1)
        ) {
          this._count += 1;
        }
        // If it doesn't exist then create a new blank node
        currentNode = AccumulatorTreeNode(path[i], {}, parentNode);
      }
      merger(currentNode, data, i);

      if (parentNode) {
        parentNode.childrenMap[path[i]] = currentNode;
      }

      if (i === 0) {
        this.root = currentNode;
      }

      // Setup for next iteration
      const nextPath = path[i + 1];
      if (nextPath) {
        parentNode = currentNode;
        currentNode = currentNode.childrenMap[nextPath];
      }
    }
  }

  /**
   * Returns the node at the specified path or null otherwise
   * @param {string} path
   * @return {AccumulatorTreeNode|null}
   */
  get(path) {
    if (!this.root) {
      return null;
    }

    let currentNode = this.root;
    for (let i = 0; i < path.length; i += 1) {
      if (!currentNode) {
        return null;
      }

      if (i === path.length - 1 && currentNode.relPath === path[i]) {
        return currentNode;
      }

      if (i < path.length - 1) {
        currentNode = currentNode.childrenMap[path[i + 1]];
      }
    }

    return null;
  }

  /**
   * Removed the node at the specified path. Will iterate up the tree from the
   * removed node to unmerge data.
   * @param {string}  path
   * @param {NodeMutator} [unmerger] function to "unmerge" removed data up the tree.
   *  First arg is the existing node, second is the removed node.
   *  Should mutate the first to remove data.
   *
   * @return {AccumulatorTreeNode|null}
   */
  remove(path, unmerger) {
    unmerger = unmerger || this.unmerger;
    if (!unmerger) {
      throw Error('Require unmerge function');
    }
    let node = this.get(path);
    const removed = node;
    if (!removed) {
      return null;
    }

    if (removed === this.root) {
      this.root = null;
      return null;
    }

    let depth = path.length;
    let removing = true;
    while (node) {
      const { parent } = node;
      if (parent) {
        if (removing) {
          delete parent.childrenMap[node.relPath];
        }
        unmerger(parent, removed, depth);
        if (Object.keys(parent.childrenMap).length > 0) {
          removing = false;
        }
      }
      depth -= 1;
      node = parent;
    }

    return removed;
  }
}

export default AccumulatorTree;
