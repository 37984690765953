import { ContextTypes } from '@/models/ContextTypes';
import { TimeDuration } from '@/models/TimePeriods';

import dayjs from '+utils/dayjs';
import { getStreamNql } from '+utils/getStreamNql';

export const getFlowDataFromRecord = ({ record, type }) => {
  if (!record) {
    return {};
  }

  if (
    type === ContextTypes.flow
    || type === ContextTypes.blocks
    || type === ContextTypes.dns
  ) {
    const nql = getStreamNql(record, false);
    return { nql };
  }

  if (type === ContextTypes.alerts) {
    const nql = record.search;
    const now = Date.now();
    const from = +dayjs.utc(record.start * 1000 - TimeDuration.minutes5);
    const to = record.end
      ? Math.min(+dayjs.utc(record.end * 1000 + TimeDuration.minutes5), now)
      : now;
    return {
      nql,
      from,
      to,
      endIsNow: to === now,
    };
  }

  return {};
};
