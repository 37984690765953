import PropTypes from '+prop-types';
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { select } from 'd3-selection';
import { renderToStaticMarkup } from 'react-dom/server';
import styled, { createGlobalStyle } from 'styled-components';

import * as PropertiesTray from '@/models/PropertiesTray';

import {
  countryCodeSeriesValueRenderer,
  labelWithDataRenderer,
} from '+components/charts/common/formatters';
import { geoValueToTextFormatter } from '+components/Table/Cells/formatters';
import useUIProperty from '+hooks/useUIProperty';
import { propsSelectors as mapTheme } from '+theme/slices/map';
import { formatNumber } from '+utils/format';
import makeArr from '+utils/makeArr';

import { templater } from './utils/templater';

const uniqueClassName = 'RealTimeMapTopTable';

const getClassName = (name) => `${uniqueClassName}__${name}`;

const GlobalStyle = createGlobalStyle`
  .${uniqueClassName} {
    &__row {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      width: 100%;
      overflow: hidden;
      padding: 2px 6px;
      justify-content: space-between;

      &:nth-child(odd) {
        background-color: ${mapTheme.tableOddRowBackground};
      }

      &:hover {
        background-color: ${mapTheme.tableRowHover};
      }
    }

    &__circle {
      display: flex;
      border-radius: 50%;
      width: 8px;
      min-width: 8px;
      height: 8px;
      min-height: 8px;
    }

    &__cell {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      position: relative;
      box-sizing: border-box;
      padding: 0 2px;
      justify-content: stretch;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      border-right: 1px solid ${mapTheme.tableCellBorder};
      height: 100%;
      width: 100%;

      &:last-child {
        border-right: 0;
      }

      &.name {
        margin: 0 4px;
        > span {
          cursor: pointer;
        }
      }

      &.value {
        font-weight: bold;
        min-width: 40px;
        max-width: 40px;
        justify-content: center;
        pointer-events: none;
        cursor: default;
        background: ${mapTheme.tableCellValueBackground};
      }
    }
  }
`;

const Row = getClassName('row');
const Name = getClassName('cell name');
const Value = getClassName('cell value');
const Circle = getClassName('circle');

const prepareData = (data, field) => {
  return data.map((row) => {
    const {
      key,
      labelsContext,
      labels,
      value,
      customer,
      style: { category: { color } = {} } = {},
    } = row || {};

    let name;

    if (field === 'flowsrcname') {
      name = renderToStaticMarkup(
        <span style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          <span className={Circle} style={{ background: color }} />
          <span style={{ color }}>{row.name}</span>
        </span>,
      );
    }

    if (['srcip', 'dstip'].includes(field)) {
      name = labelWithDataRenderer({
        data: key,
        labelsContext,
        labels,
      });
    }

    if (['srcgeo', 'dstgeo'].includes(field)) {
      name = countryCodeSeriesValueRenderer({
        countrycode: key,
      });
    }

    return {
      key,
      name,
      value: formatNumber(value, 2),
      customer,
      color: (color || '').toString(),
    };
  });
};

const byValue = ({ value: a }, { value: b }) => b - a;

const removeChildren = (node) => {
  let l = node.childNodes.length;
  // eslint-disable-next-line no-plusplus
  while (l--) {
    node.removeChild(node.firstChild);
  }
};

class List {
  constructor(container) {
    this._container = select(container);

    const template = `
      <div class="${Name}">{{name}}</div>
      <div class="${Value}">{{value}}</div>
    `;

    this._formatToHtml = (row) => templater(template, row);
  }

  update(data, field, onRowClick) {
    requestAnimationFrame(() => {
      const fragment = new DocumentFragment();

      prepareData(data, field)
        .sort(byValue)
        .forEach((d) => {
          const div = document.createElement('div');
          fragment.append(div);
          select(div)
            .datum(d)
            .attr('class', `list__row ${Row}`)
            .html(this._formatToHtml)
            .on('click', (event) => {
              onRowClick(d)(event);
            });
        });

      requestAnimationFrame(() => {
        removeChildren(this._container.node());
        this._container.node().append(fragment);
      });
    });
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

const TopTable = (props) => {
  const { data, field } = props;

  const [, setPropertiesTray] = useUIProperty('propertiesTray', null);

  const [list, setList] = useState(null);
  const ref = useRef();

  const onRowClick = useCallback(
    (d) => (event) => {
      if (
        event.target.classList.contains(getClassName('row'))
        || event.target.classList.contains(getClassName('cell'))
      ) {
        event.stopPropagation();
        return;
      }

      let title;
      let value = d.key;

      if (['srcgeo', 'dstgeo'].includes(field)) {
        value = { countrycode: d.key };
        const text = geoValueToTextFormatter(value);
        title = `${field} — ${text}`;
      }

      const customers = makeArr(d.customer).filter(Boolean);

      const trayItem = {
        title,
        dataType: PropertiesTray.DataTypes.field,
        field,
        value,
      };
      setPropertiesTray({
        data: customers.length
          ? customers.map((customer) => ({
            ...trayItem,
            customer,
          }))
          : [trayItem],
        isOpen: true,
      });
    },
    [field],
  );

  useEffect(
    () => {
      if (!ref.current) {
        return undefined;
      }

      setList(new List(ref.current));

      return () => {
        setList(null);
      };
    },
    [ref.current],
  );

  useEffect(
    () => {
      if (!list) {
        return;
      }

      list.update(data, field, onRowClick);
    },
    [data, list, field, onRowClick],
  );

  return useMemo(
    () => (
      <Fragment>
        <GlobalStyle />
        <Container ref={ref} />
      </Fragment>
    ),
    [],
  );
};

TopTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default TopTable;
