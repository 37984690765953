import { ResponseIntegrations } from '@/models/integrations/ResponseIntegrations';

import BgpFields from './fields/response/Bgp';
import BigPandaFields from './fields/response/BigPanda';
import BlocklistFields from './fields/response/Blocklist';
import CrowdstrikeFields from './fields/response/Crowdstrike';
import EmailFields from './fields/response/Email';
import FlowspecFields from './fields/response/Flowspec';
import FlowspecTrafficFields from './fields/response/FlowspecTraffic';
import Ns1Fields from './fields/response/Ns1';
import PagerdutyFields from './fields/response/Pagerduty';
import PantherFields from './fields/response/Panther';
import Route53Fields from './fields/response/Route53';
import RtbhFields from './fields/response/Rtbh';
import SlackFields from './fields/response/Slack';
import SmsFields from './fields/response/Sms';
import SplunkFields from './fields/response/Splunk';
import SumoLogicFields from './fields/response/SumoLogic';
import SyslogFields from './fields/response/Syslog';
import TeamsFields from './fields/response/Teams';
import TwilioFields from './fields/response/Twilio';
import WebhookFields from './fields/response/Webhook';

/**
 * Defines components for response integrations.
 */
export const ResponseIntegrationModels = {
  [ResponseIntegrations.route53]: {
    component: Route53Fields,
  },
  [ResponseIntegrations.bgp]: {
    component: BgpFields,
  },
  [ResponseIntegrations.bigpanda]: {
    component: BigPandaFields,
  },
  [ResponseIntegrations.blocklist]: {
    component: BlocklistFields,
  },
  [ResponseIntegrations.crowdstrike]: {
    component: CrowdstrikeFields,
  },
  [ResponseIntegrations.email]: {
    component: EmailFields,
  },
  [ResponseIntegrations.flowspec]: {
    component: FlowspecFields,
  },
  [ResponseIntegrations.flowspec_custom]: {
    component: FlowspecTrafficFields,
  },
  [ResponseIntegrations.teams]: {
    component: TeamsFields,
  },
  [ResponseIntegrations.ns1]: {
    component: Ns1Fields,
  },
  [ResponseIntegrations.pagerduty]: {
    component: PagerdutyFields,
  },
  [ResponseIntegrations.panther]: {
    component: PantherFields,
  },
  [ResponseIntegrations.rtbh]: {
    component: RtbhFields,
  },
  [ResponseIntegrations.slack]: {
    component: SlackFields,
  },
  [ResponseIntegrations.sms]: {
    component: SmsFields,
  },
  [ResponseIntegrations.splunk]: {
    component: SplunkFields,
  },
  [ResponseIntegrations.sumologic]: {
    component: SumoLogicFields,
  },
  [ResponseIntegrations.syslog]: {
    component: SyslogFields,
  },
  [ResponseIntegrations.twilio]: {
    component: TwilioFields,
  },
  [ResponseIntegrations.webhook]: {
    component: WebhookFields,
  },
};

/**
 * Assign all props of the response integration to the model.
 */
Object.entries(ResponseIntegrationModels).forEach(([key, value]) => {
  Object.assign(value, ResponseIntegrations[key]);
});
