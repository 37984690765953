import PropTypes from '+prop-types';
import { Fragment } from 'react';

import EmphasizedCode from '+components/EmphasizedCode';
import CheckBox from '+components/form/CheckBox';
import Field from '+components/form/FinalForm/Field';
import Description from '+components/form/FormField/components/Description';
import FieldContainer from '+components/form/FormField/components/FieldContainer';
import Group from '+components/form/FormField/components/Group';
import RoleAuthFieldsSection from '+components/form/RoleAuthFieldSection';
import TextField from '+components/form/TextField';
import { validateRequired, validateUrl } from '+components/form/Validators';

const BigPandaFields = ({ disabled }) => (
  <Fragment>
    <Field
      name="name"
      label="Name"
      component={TextField}
      type="text"
      maxLength={255}
      autoComplete="new-password"
      validate={validateRequired}
      style={{ width: '50%' }}
      disabled={disabled}
      required
    />

    <Field
      name="description"
      label="Description"
      component={TextField}
      type="text"
      maxLength={255}
      autoComplete="new-password"
      disabled={disabled}
    />

    <Field
      name="config.url"
      label="Webhook URL"
      component={TextField}
      type="text"
      autoComplete="new-password"
      validate={[validateRequired, validateUrl]}
      disabled={disabled}
      required
    />

    <Group>
      <FieldContainer>
        <Field
          name="config.skipsslverification"
          label="Skip SSL Verification"
          component={CheckBox}
          type="checkbox"
          disabled={disabled}
        />
      </FieldContainer>
      <Description>
        Don’t check the server certificate against the available certificate
        authorities. Also don’t require the URL host name to match the common
        name presented by the certificate
      </Description>
    </Group>

    <Field
      name="config.headers"
      label="Headers"
      component={TextField}
      type="textarea"
      autoComplete="new-password"
      helperText={(
        <Fragment>
          Comma separated list of <EmphasizedCode>header:value</EmphasizedCode>{' '}
          pairs
        </Fragment>
      )}
      disabled={disabled}
    />
    <RoleAuthFieldsSection label="Authentication">
      <Field
        name="config.authusername"
        label="Username"
        component={TextField}
        type="text"
        autoComplete="new-password"
        helperText="HTTP Basic Auth ID"
        disabled={disabled}
      />

      <Field
        name="config.authpassword"
        label="Password"
        component={TextField}
        type="password"
        autoComplete="new-password"
        helperText="HTTP Basic Auth password"
        disabled={disabled}
      />
    </RoleAuthFieldsSection>
  </Fragment>
);

BigPandaFields.propTypes = {
  disabled: PropTypes.bool,
};

BigPandaFields.defaultProps = {
  disabled: false,
};

export default BigPandaFields;
