import styled from 'styled-components';

import FieldsRowOrigin from '+components/form/FieldsRow';

const FieldsRow = styled(FieldsRowOrigin)`
  gap: 20px;
  flex-wrap: nowrap;
`;

export default FieldsRow;
