import PropTypes from '+prop-types';

import LabelTypes from '@/models/LabelTypes';

import IpIntelligence from '../../IpIntelligence';

const IpIntelligenceWrapper = ({ padding, activeData }) => {
  const ip = activeData?.fieldType === LabelTypes.ip ? activeData?.value : null;
  return <IpIntelligence padding={padding} ip={ip} />;
};

IpIntelligenceWrapper.propTypes = {
  padding: PropTypes.string.isRequired,
  activeData: PropTypes.shape({
    fieldType: PropTypes.string,
    value: PropTypes.any,
  }),
};

IpIntelligenceWrapper.defaultProps = {
  activeData: null,
};

export default IpIntelligenceWrapper;
