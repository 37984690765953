import PropTypes from '+prop-types';
import { PureComponent } from 'react';

import Button, { ButtonVariants } from '+components/Button';
import Modal, { ModalHeader, ModalBody, ModalFooter } from '+components/Modal';

// TODO: rewrite to functional component

class RegexModal extends PureComponent {
  static propTypes = {
    toggleModal: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    json: PropTypes.arrayOf(PropTypes.string).isRequired,
  };

  render() {
    const { toggleModal, isOpen } = this.props;

    return (
      <Modal isOpen={isOpen} onClose={toggleModal}>
        <ModalHeader onClose={toggleModal}>Regex Test</ModalHeader>

        <ModalBody>
          {this.props.json.length <= 0 ? 'The regex found no matches' : ''}
          {this.props.json.length > 0 ? (
            <div style={{ overflow: 'hidden' }}>
              The regex found {this.props.json.length}
              {this.props.json.length >= 2 && ' matches:'}
              {this.props.json.length < 2 && ' match:'}
              <br />
              <code>{this.props.json.join(', ')}</code>
            </div>
          ) : (
            ''
          )}
        </ModalBody>

        <ModalFooter>
          <Button variant={ButtonVariants.outlined} onClick={toggleModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default RegexModal;
