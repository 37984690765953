import PropTypes from '+prop-types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useFlag } from '@unleash/proxy-client-react';

import LeadPencilIcon from 'mdi-react/LeadPencilIcon';

import RoutePaths from '@/models/RoutePaths';

import { selectors } from '@/redux/api/rules';

import CellWrapper from './CellWrapper';

const CellCaterithm = (props) => {
  const { field, value, original, renderer: Renderer, disabled } = props;

  const isDnsEnabled = useFlag('DNS');
  const navigate = useNavigate();
  const category = useSelector(selectors.getCategoryByName(value));
  const algorithm = useSelector(selectors.getAlgorithmByName(value));

  const item = useMemo(
    () => {
      if (category) {
        return {
          name: category.name,
          description: category.description,
        };
      }
      if (algorithm) {
        const path = isDnsEnabled
          ? RoutePaths.modelsDetection
          : RoutePaths.modelsNetwork;

        return {
          id: algorithm.id,
          name: algorithm.name,
          description: algorithm.description,
          type: 'algorithm',
          model:
          algorithm.algo_type === 'CDM'
            ? RoutePaths.modelsContext.pageName
            : path.pageName,
        };
      }
      // this is possible when category or algorithm was removed but it still in policy config
      return {
        name: value,
      };
    },
    [value, category, algorithm, isDnsEnabled],
  );

  const additionalMenuOptions = useMemo(
    () => (item.id && item.type === 'algorithm'
      ? [
        {
          icon: <LeadPencilIcon />,
          text: 'Edit',
          onClick: () => {
            navigate(`${RoutePaths.models}/${item.model}/${item.id}`);
          },
        },
      ]
      : []),
    [item.id, item.type, item.model],
  );

  return value === 'all' ? null : (
    <Renderer
      header={(
        <CellWrapper
          customer={original?.customer}
          field={field}
          value={value}
          disabled={disabled}
          additionalMenuOptions={additionalMenuOptions}
        >
          <span>{item.name}</span>
        </CellWrapper>
      )}
      subHeader={item.description}
    />
  );
};

CellCaterithm.propTypes = {
  field: PropTypes.string,
  value: PropTypes.string,
  original: PropTypes.shape(),
  renderer: PropTypes.children.isRequired,
  disabled: PropTypes.bool,
};
CellCaterithm.defaultProps = {
  field: null,
  value: null,
  original: null,
  disabled: false,
};

export default CellCaterithm;
