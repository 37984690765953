export const PluginGroups = {
  dns: {
    name: 'dns',
    title: 'DNS',
  },
  block: {
    name: 'block',
    title: 'Block',
  },
  traffic: {
    name: 'traffic',
    title: 'Traffic',
  },
  notification: {
    name: 'notification',
    title: 'Notification',
  },
  webhook: {
    name: 'webhook',
    title: 'Webhook',
  },
};
