/* eslint-disable react/prop-types */
import capitalize from 'lodash.capitalize';

import NoDataStr from '@/models/NoDataStr';

import { PluginIcon } from '@/shared/img/icon';

import { PluginLabel } from '+components/Labels/PluginLabel';
import { ShowMoreWrapper } from '+components/Table/Cells';
import {
  ToggleColumnFactory,
  MenuColumnFactory,
  RowSelectorColumnFactory,
  BaseColumnFactory,
} from '+components/Table/Columns';
import { getRowOriginal } from '+components/Table/Columns/utils';
import { SelectColumnFilter } from '+components/Table/Filters';
import { getColumnsHelper } from '+utils/getColumnsHelper';

export const Columns = {
  rowSelector: 'rowSelector',
  type: 'type',
  name: 'name',
  config: 'config',
  enabled: 'enabled',
  menu: 'menu',
};

const columnsCollection = ({
  permissions,
  cxActionMenu,
  onToggleEnabled,
  contextIntegrationModels,
}) => ({
  [Columns.rowSelector]: RowSelectorColumnFactory(),
  [Columns.type]: BaseColumnFactory({
    accessor: 'type',
    Header: 'Provider',
    Cell: ({ value }) => (
      <PluginLabel
        adapter={value}
        title={contextIntegrationModels[value]?.title}
        size={14}
        logo={contextIntegrationModels[value]?.manifest?.logo}
      />
    ),
    Filter: SelectColumnFilter({
      optionIcon: (value) => (value === 'all' ? null : <PluginIcon name={value} size={14} />),
      optionLabel: (value) => contextIntegrationModels[value]?.title || capitalize(value),
    }),
    filter: 'selectFilter',
  }),
  [Columns.name]: BaseColumnFactory({
    accessor: 'name',
    Header: 'Name',
  }),
  [Columns.config]: BaseColumnFactory({
    id: 'config',
    Header: 'Config',
    // getCellProps: () => ({
    //   style: { fontFamily: 'courier new', fontSize: 'smaller' },
    // }),
    Cell: ({ row }) => {
      const { type, ...tail } = getRowOriginal(row) || {};
      const configArr = [];
      switch (type) {
        case contextIntegrationModels.aws.name:
          configArr.push(
            `Region: ${tail[type]?.region || NoDataStr}`,
            `Tag/Label Matches: ${tail[type]?.tags?.join(', ') || NoDataStr}`,
            `Updates every ${tail?.updateinterval || NoDataStr} seconds`,
          );
          break;
        case contextIntegrationModels.azure.name:
          configArr.push(
            `Subscription ID: ${tail[type]?.subscriptionid || NoDataStr}`,
            `Tenant ID: ${tail[type]?.tenantid || NoDataStr}`,
            `Tag/Label Matches: ${tail[type]?.tags?.join(', ') || NoDataStr}`,
            `Updates every ${tail?.updateinterval || NoDataStr} seconds`,
          );
          break;
        case contextIntegrationModels.crowdstrike.name:
          configArr.push(
            `Client ID: ${tail[type]?.clientid || NoDataStr}`,
            `Cloud Abbreviation: ${tail[type]?.cloud || NoDataStr}`,
            `Updates every ${tail?.updateinterval || NoDataStr} seconds`,
          );
          break;
        case contextIntegrationModels.crowdstrikediscover.name:
          configArr.push(
            `Client ID: ${tail[type]?.clientid || NoDataStr}`,
            `Cloud Abbreviation: ${tail[type]?.cloud || NoDataStr}`,
            `Updates every ${tail?.updateinterval || NoDataStr} seconds`,
          );
          break;
        case contextIntegrationModels.gcp.name:
          configArr.push(
            `Zone: ${tail[type]?.zone || NoDataStr}`,
            `Tag/Label Matches: ${tail[type]?.labels?.join(', ') || NoDataStr}`,
            `Updates every ${tail?.updateinterval || NoDataStr} seconds`,
          );
          break;
        case contextIntegrationModels.ibm.name:
          configArr.push(
            `Region: ${tail[type]?.region || NoDataStr}`,
            `Updates every ${tail?.updateinterval || NoDataStr} seconds`,
          );
          break;
        case contextIntegrationModels.oracle.name:
          configArr.push(
            `Region: ${tail[type]?.region || NoDataStr}`,
            `Tag/Label Matches: ${tail[type]?.tags?.join(', ') || NoDataStr}`,
            `Updates every ${tail?.updateinterval || NoDataStr} seconds`,
          );
          break;
        case contextIntegrationModels.s3.name:
          configArr.push(
            `Region: ${tail[type]?.region || NoDataStr}`,
            `Bucket Name: ${tail[type]?.bucket || NoDataStr}`,
            `CSV File Path: ${tail[type]?.path || NoDataStr}`,
            `Updates every ${tail?.updateinterval || NoDataStr} seconds`,
          );
          break;
        case contextIntegrationModels.sentinelone.name:
          configArr.push(
            `Domain: ${tail[type]?.domain || NoDataStr}`,
            tail[type]?.params?.accountIds
              && `AccountIds: ${tail[type]?.params?.accountIds || NoDataStr}`,
            tail[type]?.params?.filterId
              && `FilterId: ${tail[type]?.params?.filterId || NoDataStr}`,
          );
          break;
        case contextIntegrationModels.wiz.name:
          configArr.push(
            `API Endpoint: ${tail[type]?.apiendpoint || NoDataStr}`,
            `Client ID: ${tail[type]?.clientid || NoDataStr}`,
          );
          break;
        default:
          // configArr.push(JSON.stringify(tail[type]));
          configArr.push(
            ...Object.entries(tail[type]).map(
              ([key, value]) => `${key}: ${value}`,
            ),
          );
          break;
      }
      return (
        <ShowMoreWrapper slicedLength={2}>
          {configArr.filter(Boolean).map((item) => (
            <span key={item}>{item}</span>
          ))}
        </ShowMoreWrapper>
      );
    },
    disableFilters: true,
    disableSortBy: true,
    disableGroupBy: true,
  }),
  [Columns.enabled]: ToggleColumnFactory({
    Header: 'Auto Update',
    width: 85,
    minWidth: 85,
    maxWidth: 85,
    getDisabled: (params) => {
      const isDisabled = 'boolean' === typeof contextIntegrationModels[params.type]?.disabled
        && contextIntegrationModels[params.type].disabled;
      return !permissions?.update || isDisabled;
    },
    onToggle: onToggleEnabled,
  }),
  [Columns.menu]: MenuColumnFactory({ cxActionMenu }),
});

export const getColumns = getColumnsHelper(columnsCollection);
