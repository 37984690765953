import styled from 'styled-components';

import { propsSelectors as globalFiltersTheme } from '+theme/slices/globalFilters';

const MainRowSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
  height: 40px;
  max-height: 40px;
  width: 100%;
  border-radius: 60px;
  padding: 2px 8px;
  background: ${globalFiltersTheme.mainRowSectionBackground};
`;

export default MainRowSection;
