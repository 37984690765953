import styled from 'styled-components';

import Flag from '+components/Flag';
import Tag from '+components/Tag';
import dayjs from '+utils/dayjs';

import { ResponseIntegrationModels } from '../../shared/ResponseIntegrationModels';

const ConfigText = styled.div`
  //font-family: 'Courier New', Courier, monospace;
  //font-size: smaller;
  white-space: unset;
`;

// eslint-disable-next-line
const smsFormatter = (config) => {
  return (
    <div key={config.number} style={{ display: 'flex', alignItems: 'center' }}>
      <Flag countryCode={config.region} style={{ marginRight: '4px' }} />
      {config.number}
    </div>
  );
};

// eslint-disable-next-line
const twilioFormatter = (config) => {
  if (config.messagingservicesid) {
    return (
      <div key={config.messagingservicesid}>
        Messaging Service {config.messagingservicesid}
      </div>
    );
  }
  return (
    <div key={config.number} style={{ display: 'flex', alignItems: 'center' }}>
      <Flag countryCode={config.region} style={{ marginRight: '4px' }} />
      {config.number}
    </div>
  );
};

export const formatResponseIntegrationConfig = (plugin) => {
  const { adapter = '', config } = plugin;
  switch (adapter) {
    case ResponseIntegrationModels.blocklist.name:
    case ResponseIntegrationModels.flowspec.name:
    case ResponseIntegrationModels.flowspec_custom.name:
      return (
        <div>
          <ConfigText>
            Block {config.factors.join(', ')} for{' '}
            {dayjs.duration(config.expiration, 'seconds').humanize()}
          </ConfigText>
          {Array.isArray(config.whitelist) && config.whitelist.length > 0 && (
            <div>
              <Tag color="info">{config.whitelist.length} whitelisted IPs</Tag>
            </div>
          )}
        </div>
      );
    case ResponseIntegrationModels.ns1.name:
      return (
        <ConfigText>
          {config.link} {config.type} {config.domain}
        </ConfigText>
      );
    case ResponseIntegrationModels.pagerduty.name:
      switch (config.severity) {
        case 'critical':
          return (
            <Tag color="danger" outlined={false}>
              Critical
            </Tag>
          );
        case 'error':
          return (
            <Tag color="danger" outlined>
              Error
            </Tag>
          );
        case 'warning':
          return (
            <Tag color="warning" outlined>
              Warning
            </Tag>
          );
        case 'info':
          return (
            <Tag color="info" outlined>
              Info
            </Tag>
          );
        default:
          return config.severity;
      }
    case ResponseIntegrationModels.route53.name:
      return (
        <ConfigText>
          {config.aliastarget} {config.type} {config.name}
        </ConfigText>
      );
    case ResponseIntegrationModels.bgp.name:
    case ResponseIntegrationModels.rtbh.name:
      return (
        <div>
          <div>
            <Tag color="info">{config.community}</Tag>
          </div>
          <ConfigText>
            Block {config.factors.join(', ')} for{' '}
            {dayjs.duration(config.expiration, 'seconds').humanize()}
          </ConfigText>
          {(config.whitelist?.length || 0) > 0 && (
            <div>
              <Tag color="info">{config.whitelist.length} whitelisted IPs</Tag>
            </div>
          )}
        </div>
      );
    case ResponseIntegrationModels.email.name: {
      let recipients = null;
      let cc = null;
      let bcc = null;

      if (config.recipients) {
        if (config.recipients.length === 1) {
          recipients = config.recipients[0]; // eslint-disable-line prefer-destructuring
        } else if (config.recipients.length > 1) {
          recipients = (
            <Tag color="info">{config.recipients.length} recipients</Tag>
          );
        }
      }

      if (config.cc && config.cc.length > 0) {
        cc = <Tag color="info">{config.cc.length} CC</Tag>;
      }

      if (config.bcc && config.bcc.length > 0) {
        bcc = <Tag color="info">{config.bcc.length} BCC</Tag>;
      }
      return (
        <ConfigText>
          {recipients}
          <div style={{ display: 'flex', gap: '3px' }}>
            {cc}
            {bcc}
          </div>
        </ConfigText>
      );
    }
    case ResponseIntegrationModels.slack.name:
      return <ConfigText>Channel #{config.channel}</ConfigText>;
    case ResponseIntegrationModels.sms.name:
      return <ConfigText>{config.to.map((to) => smsFormatter(to))}</ConfigText>;
    case ResponseIntegrationModels.sumologic.name:
    case ResponseIntegrationModels.syslog.name:
      return (
        <ConfigText>
          {config.protocol} {config.host}
        </ConfigText>
      );
    case ResponseIntegrationModels.twilio.name:
      return (
        <ConfigText>{config.to.map((to) => twilioFormatter(to))}</ConfigText>
      );
    case ResponseIntegrationModels.bigpanda.name:
    case ResponseIntegrationModels.panther.name:
    case ResponseIntegrationModels.splunk.name:
    case ResponseIntegrationModels.webhook.name:
      return (
        <ConfigText
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {config.url}
        </ConfigText>
      );
    // TODO: flowspec, blockhook, pagerduty
    case ResponseIntegrationModels.teams.name: {
      try {
        const { protocol, hostname } = new URL(config.url);
        return <ConfigText>{`${protocol}//${hostname}`}</ConfigText>;
      } catch {
        return <ConfigText>{config.url}</ConfigText>;
      }
    }
    default:
      return <ConfigText>JSON.stringify(config)</ConfigText>;
  }
};
