import styled, { css } from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: stretch;
  border: 1px solid ${({ theme }) => theme.colorFieldsBorder};
  border-radius: 4px;
  transition: border-color 0.3s ease-in-out;
  overflow: hidden;
  position: relative;
  font-size: 12px;
  min-height: 30px;

  * {
    transition: background 0.3s ease-in-out;
  }

  ${(props) => props.$activeBorder
    && css`
      border-color: ${props.theme.primary} !important;
    `}

  &:hover, &:focus, &:focus-within {
    border-color: ${({ theme }) => theme.primary} !important;
  }

  &.invalid {
    border-color: ${({ theme }) => theme.colorFieldsBorderInvalid} !important;
    &:hover,
    &:focus,
    label:hover & {
      border-color: ${({ theme }) => theme.colorFieldsBorderInvalid} !important;
    }
  }

  &.disabled {
    opacity: 0.25;
    border-color: ${({ theme }) => theme.colorFieldsBorder} !important;
    &:hover,
    &:focus,
    label:hover & {
      border-color: ${({ theme }) => theme.colorFieldsBorder} !important;
    }
  }
`;
