import { Navigate, Route, Routes } from 'react-router-dom';

import { useFlag } from '@unleash/proxy-client-react';

import RoutePaths from '@/models/RoutePaths';

import FlowSources from '@/pages/TrafficSources';
import AddSource from '@/pages/TrafficSources/components/AddSource';
import BgpConfig from '@/pages/TrafficSources/components/DeviceBgpConfig';
import DeviceForm from '@/pages/TrafficSources/components/DeviceForm';
import VpcDetails from '@/pages/TrafficSources/components/VpcDetails';
import VpcFormAdd from '@/pages/TrafficSources/components/VpcForm/Add';
import VpcFormEdit from '@/pages/TrafficSources/components/VpcForm/Edit';

import { CrumbRoute } from '+components/Breadcrumb';

const VpcRoutes = () => (
  <Routes>
    <Route
      path="add/:flowtype/:flowresource/*"
      element={(
        <Routes>
          <Route
            path="/:traffictype"
            element={<CrumbRoute title="Add Cloud Provider" component={VpcFormAdd} />}
          />
          <Route path="*" element={<Navigate to="../flow" replace />} />
        </Routes>
      )}
    />
    <Route
      path="edit/:vpcid/*"
      element={<CrumbRoute title="Edit Cloud Provider" component={VpcFormEdit} />}
    />
    <Route path="details/:vpcid" element={<VpcDetails />} />
    <Route path="*" element={<Navigate to="../.." replace />} />
  </Routes>
);

const DevicesRoutes = () => (
  <Routes>
    <Route
      path="add"
      element={<CrumbRoute title="Add Device" component={DeviceForm} />}
    />
    <Route
      path="edit/:deviceid/*"
      element={<CrumbRoute title="Edit Device" component={DeviceForm} />}
    />
    <Route
      path="bgp/:deviceid"
      element={<CrumbRoute title="Device Details" component={BgpConfig} />}
    />
    <Route path="*" element={<Navigate to="../.." replace />} />
  </Routes>
);

const SourcesRoutes = () => {
  const isDnsEnabled = useFlag('DNS');
  return (
    <Routes>
      <Route
        path="add"
        element={(
          <CrumbRoute
            title={`Add ${isDnsEnabled ? 'Traffic' : 'Flow'} Source`}
            component={AddSource}
          />
        )}
      />

      <Route
        path={`${RoutePaths.sourcesCloudProviders.pageName}/*`}
        element={<VpcRoutes />}
      />
      <Route
        path={`${RoutePaths.sourcesDevices.pageName}/*`}
        element={<DevicesRoutes />}
      />
      <Route path="*" element={<FlowSources />} />
    </Routes>
  );
};

export default SourcesRoutes;
