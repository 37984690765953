/* eslint-disable indent */
import PropTypes from '+prop-types';

import styled, { css } from 'styled-components';

import ButtonOrigin from '@mui/material/Button';

import { ColorTypes } from '@/models/ColorTypes';

import contained from './mixins/contained';
import link from './mixins/link';
import outlined from './mixins/outlined';
import text from './mixins/text';

export const ButtonVariants = {
  text: 'text',
  contained: 'contained',
  outlined: 'outlined',
  link: 'link',
};

/**
 * Buttons allow users to take actions, and make choices, with a single tap.
 */
const ButtonStyled = styled(ButtonOrigin)`
  display: flex;
  justify-content: center;
  align-items: center;

  width: fit-content;
  min-width: fit-content;
  height: 32px;
  margin: 0;
  padding: 0 10px;
  font-size: 13px;
  line-height: 30px;
  font-weight: 600;
  text-transform: uppercase;
  border: 2px solid transparent;
  border-radius: 16px;
  overflow: hidden;
  white-space: nowrap;

  &.MuiButton-sizeSmall {
    height: 16px;
    line-height: 7px;
    font-size: 10px;
  }

  &,
  .MuiButton-startIcon,
  .MuiButton-endIcon {
    transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out,
      background-color 0.2s ease-in-out;
  }

  /**
   * Style for variant: text
   */
  ${({ variant, theme }) => variant === ButtonVariants.text
    && css`
      &.MuiButton-text {
        ${text({
          textColor: theme.buttonTertiaryText,
          background: theme.buttonTertiaryBackground,
          borderColor: theme.buttonTertiaryBorder,
          textColorDisabled: theme.buttonTertiaryTextDisabled,
          backgroundDisabled: theme.buttonTertiaryBackgroundDisabled,
          borderColorDisabled: theme.buttonTertiaryBorderDisabled,
        })}
      }

      &.MuiButton-textDanger {
        ${text({
          textColor: theme.buttonDangerBackground,
          background: theme.buttonTertiaryBackground,
          borderColor: theme.buttonTertiaryBorder,
          textColorDisabled: theme.buttonDangerBackgroundDisabled,
          backgroundDisabled: theme.buttonTertiaryBackgroundDisabled,
          borderColorDisabled: theme.buttonTertiaryBorderDisabled,
        })}
      }
    `}

  /**
   * Style for variant: contained
   */
  ${({ variant, theme }) => variant === ButtonVariants.contained
    && css`
      &.MuiButton-contained {
        ${contained({
          textColor: theme.buttonPrimaryText,
          background: theme.buttonPrimaryBackground,
          borderColor: theme.buttonPrimaryBorder,
          textColorDisabled: theme.buttonPrimaryTextDisabled,
          backgroundDisabled: theme.buttonPrimaryBackgroundDisabled,
          borderColorDisabled: theme.buttonPrimaryBorderDisabled,
        })}
      }

      &.MuiButton-containedDanger {
        ${contained({
          textColor: theme.buttonDangerText,
          background: theme.buttonDangerBackground,
          borderColor: theme.buttonDangerBorder,
          textColorDisabled: theme.buttonDangerTextDisabled,
          backgroundDisabled: theme.buttonDangerBackgroundDisabled,
          borderColorDisabled: theme.buttonDangerBorderDisabled,
        })}
      }

      &.MuiButton-containedWarning {
        ${contained({
          textColor: theme.buttonDangerText,
          background: theme.palette.warning.main,
          borderColor: theme.palette.warning.main,
          textColorDisabled: theme.buttonDangerTextDisabled,
          backgroundDisabled: theme.buttonDangerBackgroundDisabled,
          borderColorDisabled: theme.buttonDangerBorderDisabled,
        })}
      }
    `}

  /**
   * Style for variant: outlined
   */
  ${({ variant, theme }) => variant === ButtonVariants.outlined
    && css`
      &.MuiButton-outlined {
        ${outlined({
          textColor: theme.buttonSecondaryText,
          background: theme.buttonSecondaryBackground,
          borderColor: theme.buttonSecondaryBorder,
          textColorDisabled: theme.buttonSecondaryTextDisabled,
          backgroundDisabled: theme.buttonSecondaryBackgroundDisabled,
          borderColorDisabled: theme.buttonSecondaryBorderDisabled,
        })}
      }

      &.MuiButton-outlinedDanger {
        ${outlined({
          textColor: theme.buttonDangerBorder,
          background: theme.buttonSecondaryBackground,
          borderColor: theme.buttonDangerBorder,
          textColorDisabled: theme.buttonSecondaryTextDisabled,
          backgroundDisabled: theme.buttonSecondaryBackgroundDisabled,
          borderColorDisabled: theme.buttonSecondaryBorderDisabled,
        })}
      }

      &.MuiButton-outlinedWarning {
        ${outlined({
          textColor: theme.palette.warning.main,
          background: theme.buttonSecondaryBackground,
          borderColor: theme.palette.warning.main,
          textColorDisabled: theme.buttonSecondaryTextDisabled,
          backgroundDisabled: theme.buttonSecondaryBackgroundDisabled,
          borderColorDisabled: theme.buttonSecondaryBorderDisabled,
        })}
      }
    `}

  /**
   * Style for variant: link
   */
  ${({ variant, theme }) => variant === ButtonVariants.link
    && css`
      &.MuiButton-link {
        ${link({
          textColor: theme.buttonSecondaryText,
          background: 'rgba(0, 0, 0, 0)',
          borderColor: 'rgba(0, 0, 0, 0)',
          textColorDisabled: theme.buttonSecondaryTextDisabled,
          backgroundDisabled: 'rgba(0, 0, 0, 0)',
          borderColorDisabled: 'rgba(0, 0, 0, 0)',
        })}
      }

      &.MuiButton-linkDanger {
        ${link({
          textColor: theme.buttonDangerBackground,
          background: 'rgba(0, 0, 0, 0)',
          borderColor: 'rgba(0, 0, 0, 0)',
          textColorDisabled: theme.buttonSecondaryTextDisabled,
          backgroundDisabled: 'rgba(0, 0, 0, 0)',
          borderColorDisabled: 'rgba(0, 0, 0, 0)',
        })}
      }
    `}
`;

const Button = ({ testId, ...tail }) => (
  <ButtonStyled
    {...tail}
    data-testid={testId}
    disableRipple
    disableElevation
  />
);

Button.propTypes = {
  /**
   * Button size: 'small' or 'medium'.
   * @default medium
   */
  size: PropTypes.oneOf(['small', 'medium']),
  /**
   * Button color: primary, secondary, danger and etc,
   * @see {@link ColorTypes}
   * @default primary
   */
  color: PropTypes.oneOf(Object.values(ColorTypes)),
  /**
   * Button variant: text, outlined, contained, link
   * @see {@link ButtonVariants}
   * @default text
   */
  variant: PropTypes.oneOf(Object.values(ButtonVariants)),
  /**
   * Material Design Icon.
   */
  startIcon: PropTypes.element,
  /**
   * Material Design Icon.
   */
  endIcon: PropTypes.element,
  /**
   * The content of the button.
   */
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.object,
    PropTypes.func,
  ]),
  /**
   * Test id for testing purposes.
   */
  testId: PropTypes.string,
};

Button.defaultProps = {
  size: 'medium',
  color: ColorTypes.primary,
  variant: ButtonVariants.contained,
  startIcon: null,
  endIcon: null,
  children: null,
  testId: null,
};

export default Button;
