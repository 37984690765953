import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useToggle } from 'react-use';

import RoutePaths from '@/models/RoutePaths';

import {
  actions as tagActions,
  selectors as tagSelectors,
} from '@/redux/api/tag';

import { Breadcrumb } from '+components/Breadcrumb';
import ConfirmModal from '+components/ConfirmModal';
import EditPageAuditLogTabs from '+components/EditPageAuditLogTabs';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import usePageTabs from '+hooks/usePageTabs';
import useUIProperty from '+hooks/useUIProperty';

import FlowTagForm from './FlowTagForm';

const FlowTagEdit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { flowtagid } = useParams();

  const [, activePageTab] = usePageTabs();

  const { isFetching, flowTags, error } = useSelector(tagSelectors.getState);

  useLoadingIndicator(isFetching);

  const [inProgress, setInProgress] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useToggle(false);

  const flowTag = useMemo(
    () => flowTags?.[flowtagid] || { tags: [] },
    [flowTags, flowtagid],
  );

  const onSubmit = useCallback(
    (values) => {
      setInProgress(activePageTab?.id);
      dispatch(tagActions.updateFlowTag(values));
    },
    [activePageTab?.id],
  );

  const onCancel = useCallback(
    () => {
      navigate(`${RoutePaths.flowTags}`);
    },
    [],
  );

  const onDeleteModalToggle = useCallback(
    () => {
      setShowDeleteModal((prevValue) => !prevValue);
    },
    [],
  );

  const onDelete = useCallback(
    () => {
      dispatch(tagActions.deleteFlowTag(flowtagid));
      onCancel();
    },
    [flowtagid, onCancel],
  );

  useEffect(
    () => {
      if (isFetching || !inProgress) {
        return;
      }

      if (inProgress !== activePageTab?.id) {
        return;
      }

      if (error) {
        setInProgress(false);
        return;
      }

      onCancel();
    },
    [isFetching, inProgress, error, activePageTab?.id, onCancel],
  );

  useEffect(
    () => {
      dispatch(tagActions.fetchFlowTagById(flowtagid));
    },
    [flowtagid],
  );

  const [, setMasqueradeUrl] = useUIProperty('masqueradeUrl');
  useEffect(
    () => {
      setMasqueradeUrl(`${RoutePaths.flowTags}`);
      return () => {
        setMasqueradeUrl(null);
      };
    },
    [],
  );

  const showForm = !!flowTag.id;

  return (
    <EditPageAuditLogTabs
      auditNqlQuery={`class == flow_tag && original_id == ${flowtagid}`}
      breadcrumb={<Breadcrumb title="Edit Flow Tag" />}
    >
      <Fragment>
        {showForm && (
          <FlowTagForm
            initialValues={flowTag}
            onSubmit={onSubmit}
            onCancel={onCancel}
            deleteButtonText="Delete Flow Tag"
            onDelete={onDeleteModalToggle}
          />
        )}

        {showDeleteModal && (
          <ConfirmModal
            item={flowTag?.name}
            onToggle={onDeleteModalToggle}
            onConfirm={onDelete}
            isOpen
          />
        )}
      </Fragment>
    </EditPageAuditLogTabs>
  );
};

export default FlowTagEdit;
