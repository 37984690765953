import styled from 'styled-components';

import { Row } from '+components/Layout';

const Separator = styled(Row)`
  width: 100%;
  border-top: ${({ $width }) => $width}px solid
    ${({ theme }) => theme.colorBackgroundSeparator};
`;

export default Separator;
