/* eslint-disable no-param-reassign */

import { ContextTypes } from '@/models/ContextTypes';

import { UniversalCell } from '+components/Table/Cells';
import { durationFormatter } from '+components/Table/Cells/formatters';
import {
  AnswersColumnFactory,
  BaseColumnFactory,
  MenuColumnFactory,
  NumberColumnFactory,
  GeoColumnFactory,
  StringsArrayColumnsFactory,
  LabelOrIpColumnFactory,
  TimestampColumnFactory,
  LabelOrPortColumnFactory,
  OwnerAsColumnFactory,
} from '+components/Table/Columns';
import { TrafficColumnFactory } from '+components/Table/Columns/TrafficColumnFactory';
import { SelectColumnFilter } from '+components/Table/Filters';
import {
  autoRemoveIfAll,
  autoRemoveIfEmpty,
  withAutoRemove,
} from '+components/Table/FilterTypeFactories';
import sortByHelper from '+utils/sortByHelper';

// commented out columns can fallback on the default presentation (text)
export const Columns = {
  trafficType: 'traffic_type',
  _source: '_source',
  _srcPort: '_srcPort',
  action: 'action',
  dstinternal: 'internal',
  protocol: 'protocol',
  srcip: 'srcip',
  srcport: 'srcport',
  timestamp: 'timestamp',
  customer: 'customer',
  menu: 'menu',
  queryName: 'query.name',
  queryType: 'query.type',
  queryDepth: 'query.depth',
  rcode: 'rcode',
  answercount: 'answercount',
  datasrc: 'datasrc',
  answers: 'answers',
  type: 'type',
  _srcGeo: '_srcGeo',
  _srcOwnerAs: '_srcOwnerAs',
  _destination: '_destination',
  _dstPort: '_dstPort',
  _dstGeo: '_dstGeo',
  _dstOwnerAs: '_dstOwnerAs',
  bits: 'bits',
  bitsxrate: 'bitsxrate',
  bogondst: 'bogondst',
  bogonsrc: 'bogonsrc',
  dstasAsNumber: 'dstas.number',
  dstasAsOrg: 'dstas.org',
  dstip: 'dstip',
  dstipname: 'label.ip.name.dst',
  dstiprepCategories: 'dstiprep.categories',
  dstiprepCount: 'dstiprep.count',
  dstOwnerAsNumber: 'dstowneras.number',
  dstOwnerAsOrg: 'dstowneras.org',
  dstport: 'dstport',
  dstportname: 'label.port.name.dst',
  duration: 'duration',
  end: 'end',
  flowbrate: 'flowbrate',
  flowsrcip: 'flowsrcip',
  nexthop: 'nexthop',
  packets: 'packets',
  protocolint: 'protocolint',
  srcAsNumber: 'srcas.number',
  srcAsOrg: 'srcas.org',
  srcipname: 'label.ip.name.src',
  srciprepCategories: 'srciprep.categories',
  srcportname: 'label.port.name.src',
  srcOwnerAsNumber: 'srcowneras.number',
  srcOwnerAsOrg: 'srcowneras.org',
  start: 'start',
  tags: 'tags',
  tcpflags: 'tcpflags',
};

const tcpFlagsExtractor = (value) => Object.keys(value || {})
  .filter((key) => value[key])
  .sort()
  .join('');

const sharedColumns = ({ labelContext, cxActionMenu }) => ({
  [Columns.trafficType]: TrafficColumnFactory({
    Header: 'Traffic',
  }),
  [Columns.timestamp]: TimestampColumnFactory({ width: 160 }),
  [Columns._source]: LabelOrIpColumnFactory({
    Header: 'SRC IP',
    dataFieldName: 'srcip',
    labelFieldName: `label.ip.${labelContext.ip}.src`,
    showLabel: labelContext.show,
  }),
  [Columns._srcPort]: LabelOrPortColumnFactory({
    Header: 'SRC Port',
    dataFieldName: 'srcport',
    labelFieldName: `label.port.${labelContext.port}.src`,
    showLabel: labelContext.show,
  }),
  [Columns.srcip]: BaseColumnFactory({
    // width: 110,
    getCellProps: () => ({ style: { textAlign: 'right' } }),
    sortType: 'ip',
  }),
  [Columns.srcport]: NumberColumnFactory({
    // width: 90,
  }),
  [Columns.protocol]: BaseColumnFactory({
    getCellProps: () => ({ style: { textAlign: 'center' } }),
    width: 80,
    Filter: SelectColumnFilter({
      optionLabel: (key) => (key === 'all' ? 'All' : key),
      enableLikeFilter: true,
    }),
    filter: 'selectFilter',
  }),
  [Columns.customer]: {
    accessor: 'customer',
    Header: 'Account',
    width: 160,
    Filter: SelectColumnFilter({
      optionLabel: (key) => (key === 'all' ? 'All' : key),
    }),
    filter: 'selectFilter',
  },
  [Columns.tags]: BaseColumnFactory({
    getCellProps: () => ({ style: { whiteSpace: 'unset' } }),
  }),
  [Columns.datasrc]: BaseColumnFactory({}),
  [Columns.menu]: MenuColumnFactory({ cxActionMenu }),
});

const dnsColumns = () => ({
  [Columns.queryName]: BaseColumnFactory({
    // width: 120,
    trafficType: ContextTypes.dns,
  }),
  [Columns.queryType]: StringsArrayColumnsFactory({
    // width: 100,
    trafficType: ContextTypes.dns,
  }),
  [Columns.queryDepth]: BaseColumnFactory({
    width: 90,
    trafficType: ContextTypes.dns,
  }),
  [Columns.action]: BaseColumnFactory({
    getCellProps: () => ({ style: { textAlign: 'center' } }),
    width: 100,
    trafficType: ContextTypes.dns,
  }),
  [Columns.answercount]: BaseColumnFactory({
    width: 90,
    trafficType: ContextTypes.dns,
  }),
  [Columns.rcode]: StringsArrayColumnsFactory({
    // width: 80,
    trafficType: ContextTypes.dns,
  }),
  [Columns.type]: StringsArrayColumnsFactory({
    // width: 80,
    trafficType: ContextTypes.dns,
  }),
  [Columns.answers]: AnswersColumnFactory({
    width: 250,
    disableFilters: true,
    disableSortBy: true,
    disableGroupBy: true,
    trafficType: ContextTypes.dns,
  }),
});

const flowColumns = ({ labelContext }) => ({
  [Columns._srcGeo]: GeoColumnFactory({
    Header: 'SRC Geo',
    field: 'srcgeo',
    trafficType: ContextTypes.flow,
  }),
  [Columns._srcOwnerAs]: OwnerAsColumnFactory({
    Header: 'SRC Owner AS',
    field: 'srcowneras',
    trafficType: ContextTypes.flow,
  }),
  [Columns._destination]: LabelOrIpColumnFactory({
    Header: 'Destination',
    dataFieldName: 'dstip',
    labelFieldName: `label.ip.${labelContext.ip}.dst`,
    showLabel: labelContext.show,
    trafficType: ContextTypes.flow,
  }),
  [Columns._dstPort]: LabelOrPortColumnFactory({
    Header: 'DST Port',
    dataFieldName: 'dstport',
    labelFieldName: `label.port.${labelContext.port}.dst`,
    showLabel: labelContext.show,
    trafficType: ContextTypes.flow,
  }),
  [Columns._dstGeo]: GeoColumnFactory({
    Header: 'DST Geo',
    field: 'dstgeo',
    trafficType: ContextTypes.flow,
  }),
  [Columns._dstOwnerAs]: OwnerAsColumnFactory({
    Header: 'DST Owner AS',
    field: 'dstowneras',
    trafficType: ContextTypes.flow,
  }),
  [Columns.bits]: NumberColumnFactory({
    accessor: Columns.bits,
    width: 80,
    trafficType: ContextTypes.flow,
  }),
  [Columns.bogondst]: BaseColumnFactory({
    width: 70,
    trafficType: ContextTypes.flow,
  }),
  [Columns.bogonsrc]: BaseColumnFactory({
    width: 70,
    trafficType: ContextTypes.flow,
  }),
  [Columns.bitsxrate]: NumberColumnFactory({
    accessor: Columns.bitsxrate,
    width: 80,
    trafficType: ContextTypes.flow,
  }),

  [Columns.dstasAsNumber]: NumberColumnFactory({
    accessor: Columns.dstasAsNumber,
    width: 110,
    trafficType: ContextTypes.flow,
  }),

  [Columns.dstasAsOrg]: BaseColumnFactory({
    width: 100,
    trafficType: ContextTypes.flow,
  }),
  [Columns.dstinternal]: BaseColumnFactory({
    width: 80,
    trafficType: ContextTypes.flow,
  }),
  [Columns.dstip]: BaseColumnFactory({
    width: 110,
    getCellProps: () => ({ style: { justifyContent: 'flex-end' } }),
    sortType: 'ip',
    trafficType: ContextTypes.flow,
  }),
  [Columns.dstipname]: BaseColumnFactory({
    width: 110,
    getCellProps: () => ({ style: { justifyContent: 'flex-end' } }),
    Cell: UniversalCell(Columns.dstipname),
    trafficType: ContextTypes.flow,
  }),
  [Columns.dstiprepCategories]: BaseColumnFactory({
    width: 130,
    trafficType: ContextTypes.flow,
  }),
  [Columns.dstiprepCount]: NumberColumnFactory({
    accessor: Columns.dstiprepCount,
    width: 100,
    trafficType: ContextTypes.flow,
  }),
  [Columns.dstport]: NumberColumnFactory({
    accessor: Columns.dstport,
    width: 90,
    trafficType: ContextTypes.flow,
  }),

  [Columns.dstportname]: BaseColumnFactory({
    width: 90,
    getCellProps: () => ({ style: { justifyContent: 'flex-end' } }),
    Cell: UniversalCell(Columns.dstportname),
    trafficType: ContextTypes.flow,
  }),
  [Columns.duration]: NumberColumnFactory({
    accessor: Columns.duration,
    width: 70,
    filter: withAutoRemove((rows, [id], filterValue) => {
      if (autoRemoveIfEmpty(filterValue)) {
        return rows;
      }

      const normalizedFilterValue = String(filterValue).toLowerCase();
      return rows.filter(({ values: { [id]: value } }) => durationFormatter(value, 'milliseconds').includes(
        normalizedFilterValue,
      ));
    }, autoRemoveIfEmpty),
    trafficType: ContextTypes.flow,
  }),
  [Columns.end]: NumberColumnFactory({
    accessor: Columns.end,
    width: 140,
    trafficType: ContextTypes.flow,
  }),
  [Columns.flowbrate]: NumberColumnFactory({
    accessor: Columns.flowbrate,
    width: 80,
    trafficType: ContextTypes.flow,
  }),

  [Columns.flowsrcip]: BaseColumnFactory({
    getCellProps: () => ({ style: { justifyContent: 'flex-end' } }),
    Cell: UniversalCell(Columns.flowsrcip),
    sortType: 'ip',
    trafficType: ContextTypes.flow,
  }),
  [Columns.nexthop]: BaseColumnFactory({
    Cell: UniversalCell(Columns.nexthop),
    trafficType: ContextTypes.flow,
  }),
  [Columns.packets]: NumberColumnFactory({ accessor: Columns.packets }),
  [Columns.srcAsNumber]: NumberColumnFactory({
    accessor: Columns.srcAsNumber,
    width: 80,
    genericCell: true,
    trafficType: ContextTypes.flow,
  }),
  [Columns.srcAsOrg]: BaseColumnFactory({
    trafficType: ContextTypes.flow,
  }),
  [Columns.srcipname]: BaseColumnFactory({
    width: 110,
    getCellProps: () => ({ style: { justifyContent: 'flex-end' } }),
    Cell: UniversalCell(Columns.srcipname),
    // trafficType: ContextTypes.flow,
  }),
  [Columns.srciprepCategories]: BaseColumnFactory({
    width: 140, // in order to fit the column header, not data
    trafficType: ContextTypes.flow,
  }),
  [Columns.srcOwnerAsNumber]: NumberColumnFactory({
    accessor: Columns.srcOwnerAsNumber,
    width: 144, // in order to fit the column header, not data
    trafficType: ContextTypes.flow,
  }),
  [Columns.srcOwnerAsOrg]: BaseColumnFactory({
    width: 140,
    trafficType: ContextTypes.flow,
  }),
  [Columns.srcportname]: BaseColumnFactory({
    width: 90,
    getCellProps: () => ({ style: { justifyContent: 'flex-end' } }),
    Cell: UniversalCell(Columns.srcportname),
    // trafficType: ContextTypes.flow,
  }),
  [Columns.start]: NumberColumnFactory({
    accessor: Columns.start,
    width: 140,
    trafficType: ContextTypes.flow,
  }),
  [Columns.tcpflags]: BaseColumnFactory({
    accessor: Columns.tcpflags,
    Header: Columns.tcpflags,
    getCellProps: () => ({ style: { whiteSpace: 'unset' } }),
    Cell: UniversalCell(Columns.tcpflags),
    Filter: SelectColumnFilter({
      optionValueExtractor: (row, id) => Object.keys(row.values[id] || {}),
      optionLabel: (key) => (key === 'all' ? 'All' : key.toUpperCase()),
      sort: false,
    }),
    filter: withAutoRemove((rows, [id], filterValue) => {
      if (autoRemoveIfAll(filterValue)) {
        return rows;
      }

      return rows.filter(
        ({ values: { [id]: value } }) => !!value?.[filterValue.value],
      );
    }, autoRemoveIfAll),
    sortType: sortByHelper(tcpFlagsExtractor),
    trafficType: ContextTypes.flow,
  }),
});

export const columnsCollection = ({ labelContext, cxActionMenu }) => {
  const collection = {
    // ================== Shared Columns ==================
    ...sharedColumns({ labelContext, cxActionMenu }),
    // ================== DNS Columns ==================
    ...dnsColumns(),
    // ================== Flow Columns ==================
    ...flowColumns({ labelContext }),
  };

  Object.keys(collection).forEach((key) => {
    if (!collection[key].accessor) {
      collection[key].accessor = key;
    }
  });

  return collection;
};
