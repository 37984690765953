import { useCallback, useContext, useMemo } from 'react';

import styled from 'styled-components';

import MenuOrigin from '@mui/material/Menu';

import { ScrollBarMixin } from '+theme/mixins/scrollBarMixin';

import { Context } from './Context';

const StyledMenu = styled(MenuOrigin)`
  .MuiPaper-root {
    max-height: 50vh;
    min-width: 140px;
    background: ${({ theme }) => theme.contextMenuBackground} !important;
    border: 1px solid ${({ theme }) => theme.contextMenuBorderColor};
    ${ScrollBarMixin};
  }

  .MuiMenuItem-root + :not(.MuiMenuItem-root) {
    margin-top: 12px;
  }
`;

const Menu = (props) => {
  const { isOpen, anchor, hide } = useContext(Context);

  const doClose = useCallback(
    () => {
      hide();
    },
    [],
  );

  const sx = useMemo(
    () => {
      if (!anchor) {
        return null;
      }

      const minWidth = anchor.getBoundingClientRect().width;

      return {
        '& .MuiPaper-root': {
          minWidth,
        },
      };
    },
    [anchor],
  );

  return (
    <StyledMenu
      elevation={0}
      // anchorOrigin={{
      //   vertical: 'bottom',
      //   horizontal: 'right',
      // }}
      // transformOrigin={{
      //   vertical: 'top',
      //   horizontal: 'right',
      // }}
      sx={sx}
      {...props}
      PaperProps={{
        style: {
          transform: 'translateY(4px)',
          // eslint-disable-next-line react/prop-types
          ...props.PaperProps?.style,
        },
        // eslint-disable-next-line react/prop-types
        ...props.PaperProps,
      }}
      anchorEl={anchor}
      open={isOpen}
      onClose={doClose}
    />
  );
};

export default Menu;
