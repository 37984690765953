import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import RoutePaths from '@/models/RoutePaths';

import {
  actions as rulesActions,
  selectors as rulesSelectors,
} from '@/redux/api/rules';

import CommonEdit from '../shared/CommonEdit';
import { uiToParams } from '../shared/utils';
import FormBody from './FormBody';

const methods = {
  fetch: rulesActions.fetchCCM,
  update: rulesActions.updateCCM,
  remove: rulesActions.deleteCCM,
  reset: rulesActions.resetCCM,
  get: rulesSelectors.getCCM,
};

const CCMEdit = () => {
  const dispatch = useDispatch();
  const { algorithmId } = useParams();

  const onSubmit = useCallback(
    (values) => {
      dispatch(
        methods.update(
          uiToParams({
            ...values,
            id: algorithmId,
          }),
        ),
      );
    },
    [algorithmId],
  );

  return (
    <CommonEdit
      title="Context"
      backTitle="Context Creation Models"
      methods={methods}
      FormBody={FormBody}
      routePath={RoutePaths.modelsContext}
      deleteButtonText="Delete Context Model"
      onSubmit={onSubmit}
    />
  );
};
export default CCMEdit;
