import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { useFlag } from '@unleash/proxy-client-react';

import RoutePaths from '@/models/RoutePaths';

import {
  selectors as docsSelectors,
  actions as docsActions,
  FetchStates,
} from '@/redux/api/docs';

import Events from '@/pages/Events';
import DetectionModels from '@/pages/Models';
import ContextDetectionModelsAdd from '@/pages/Models/Context/Add';
import ContextDetectionModelsEdit from '@/pages/Models/Context/Edit';
import DetectionDetectionModelsAdd from '@/pages/Models/Detection/Add';
import DetectionDetectionModelsEdit from '@/pages/Models/Detection/Edit';
import ThresholdsOverridesForm from '@/pages/Models/ThresholdsOverrides/components/Form';

import { CrumbRoute } from '+components/Breadcrumb';
import useLoadingIndicator from '+hooks/useLoadingIndicator';

const ReplaceNetwork = () => {
  const { pathname, search } = useLocation();

  const newPath = pathname.replace(
    RoutePaths.modelsNetwork,
    RoutePaths.modelsDetection,
  );

  return <Navigate to={`${newPath}${search}`} replace />;
};

const DetectionModelsRoutes = () => {
  const isDnsEnabled = useFlag('DNS');

  const detectionPath = isDnsEnabled
    ? RoutePaths.modelsDetection
    : RoutePaths.modelsNetwork;

  const dispatch = useDispatch();
  const docsStatus = useSelector(docsSelectors.getStatus);
  useLoadingIndicator(docsStatus === FetchStates.fetching);
  useEffect(
    () => {
      if (docsStatus === FetchStates.success) {
        return undefined;
      }
      const namespace = 'fetchDetectionModelsDocs';
      dispatch(docsActions.fetchDetectionModelsDocs(namespace));
      return () => {
        dispatch(docsActions.cancel(namespace));
      };
    },
    [],
  );

  return (
    <Routes>
      <Route index element={<DetectionModels />} />

      <Route
        path={`${detectionPath.pageName}/add`}
        element={<DetectionDetectionModelsAdd />}
      />
      <Route
        path={`${detectionPath.pageName}/:algorithmId/overrides/update`}
        element={<ThresholdsOverridesForm />}
      />
      <Route
        path={`${detectionPath.pageName}/:algorithmId/*`}
        element={<DetectionDetectionModelsEdit />}
      />

      {isDnsEnabled && (
        <Route
          path={`${RoutePaths.modelsNetwork.pageName}/*`}
          element={<ReplaceNetwork />}
        />
      )}

      <Route
        path={`${RoutePaths.modelsContext.pageName}/add`}
        element={<ContextDetectionModelsAdd />}
      />
      <Route
        path={`${RoutePaths.modelsContext.pageName}/:algorithmId/*`}
        element={<ContextDetectionModelsEdit />}
      />

      <Route
        path="*"
        element={<Navigate to={`${RoutePaths.models}`} replace />}
      />
    </Routes>
  );
};

const NdrRoutes = () => (
  <Routes>
    <Route path="events/*" element={<Events />} />

    <Route
      path="models/*"
      element={(
        <CrumbRoute
          title="Detection Models"
          component={DetectionModelsRoutes}
        />
      )}
    />
  </Routes>
);

export default NdrRoutes;
