import { ToastContainer } from 'react-toastify';

import styled from 'styled-components';

export default styled(ToastContainer)`
  z-index: 99999; // z-index must be more then modal's (otherwise toast will be under modal form)

  .Toastify__toast {
    margin-right: 2px;
    padding: 0;
    border: 2px solid;
    border-radius: 4px;
    color: ${({ theme }) => theme.colorText};
    background-color: ${({ theme }) => theme.toastBodyBackground};
    cursor: default;
  }

  // modification for stacked toasts
  // we need to hide all toasts except the last one
  // and we need to show that there is something hidden
  :not(:hover) {
    .Toastify__toast--stacked:not(:last-child) {
      counter-increment: toastCounter;
      visibility: hidden;
    }
    .Toastify__toast--stacked:last-child:not(:only-child) {
      overflow: unset;
      :after {
        --height: 20px;
        content: '+' counter(toastCounter) ' more'; // show how many toasts are hidden
        top: 0;
        width: 100%;
        height: var(--height);
        transform: translateY(calc((var(--height) + 2px) * -1)) scale(0.975);
        border-radius: 4px 4px 0 0;
        border-top-style: inherit;
        border-left-style: inherit;
        border-right-style: inherit;
        border-color: ${({ theme }) => theme.Palette.gray2};
        background: ${({ theme }) => theme.toastBodyBackground};
        opacity: 0.8;
        font-size: 11px;
        color: ${({ theme }) => theme.colorTextSecondary};
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .Toastify__toast-body {
    margin: 0;
    padding-right: 0;
  }

  .Toastify__close-button {
    width: 12px;
    height: 12px;
    transform: translate(-50%, 50%);
    color: ${({ theme }) => theme.colorTextSecondary};
  }

  .Toastify__progress-bar {
    border-radius: 0;
  }

  .Toastify__toast--default {
    border-color: ${({ theme }) => theme.Palette.gray2};
    .toast__icon {
      color: ${({ theme }) => theme.Palette.gray2};
    }
    .Toastify__progress-bar {
      background-color: ${({ theme }) => theme.Palette.gray2};
    }
  }

  .Toastify__toast--error {
    border-color: ${({ theme }) => theme.Palette.danger};
    .toast__icon {
      color: ${({ theme }) => theme.Palette.danger};
    }
    .Toastify__progress-bar {
      background-color: ${({ theme }) => theme.Palette.danger};
    }
  }

  .Toastify__toast--warning {
    border-color: ${({ theme }) => theme.Palette.warning};
    .toast__icon {
      color: ${({ theme }) => theme.Palette.warning};
    }
    .Toastify__progress-bar {
      background-color: ${({ theme }) => theme.Palette.warning};
    }
  }

  .Toastify__toast--info {
    border-color: ${({ theme }) => theme.primary};
    .toast__icon {
      color: ${({ theme }) => theme.primary};
    }
    .Toastify__progress-bar {
      background-color: ${({ theme }) => theme.primary};
    }
  }

  .Toastify__toast--success {
    border-color: ${({ theme }) => theme.Palette.success};
    .toast__icon {
      color: ${({ theme }) => theme.Palette.success};
    }
    .Toastify__progress-bar {
      background-color: ${({ theme }) => theme.Palette.success};
    }
  }
`;
