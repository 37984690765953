import { useCallback, useContext } from 'react';

import styled from 'styled-components';

import Context from './Context';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 18px 5px 23px;

  &:not(:first-child) {
    padding-top: 10px;
  }
`;

const Title = styled.div`
  margin-right: 20px;
`;

const Reset = styled.button.attrs(({ testId }) => ({
  type: 'button',
  'data-testid': testId,
}))`
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;

  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  color: ${({ theme }) => theme.linkColor};
  transition: color 0.1s;

  &:hover {
    color: ${({ theme }) => theme.linkHoverColor};
  }

  &:active {
    color: ${({ theme }) => theme.linkHoverColor};
  }
`;

export default () => {
  const { select } = useContext(Context);

  const onClick = useCallback(
    () => select(),
    [select],
  );

  return (
    <Container>
      <Title>Manage Layout</Title>
      <Reset onClick={onClick} testId="reset-layout-button">
        Reset Layout
      </Reset>
    </Container>
  );
};
