import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useFlag } from '@unleash/proxy-client-react';

import RoutePaths from '@/models/RoutePaths';

import {
  actions as rulesActions,
  selectors as rulesSelectors,
} from '@/redux/api/rules';
import {
  actions as thresholderActions,
  selectors as thresholderSelectors,
} from '@/redux/api/thresholder';

import AutoThresholds from '../AutoThresholds';
import CommonEdit from '../shared/CommonEdit';
import {
  cadenceOptions,
  strategyOptions,
  uiToAutoThresholdParams,
  uiToParams,
} from '../shared/utils';
import ThresholdsOverrides from '../ThresholdsOverrides';
import FormBody from './FormBody';

const methods = {
  fetch: rulesActions.fetchAlgorithm,
  update: rulesActions.updateAlgorithm,
  remove: rulesActions.deleteAlgorithm,
  reset: rulesActions.resetAlgorithm,
  get: rulesSelectors.getAlgorithm,
};

const AlgorithmEdit = () => {
  const dispatch = useDispatch();
  const { algorithmId } = useParams();
  const autoThresholdsFeatureFlag = useFlag('autoThresholds');

  const algorithm = useSelector(methods.get(algorithmId));
  const thresholdAutomaton = useSelector(
    thresholderSelectors.getAutomaton(algorithm?.name),
  );
  const updatedAlgorithm = useSelector(
    rulesSelectors.getAlgorithm(algorithmId),
  );

  const isFetching = useSelector(thresholderSelectors.isFetching);

  const [submitValues, setSubmitValues] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const additionalTabs = useMemo(
    () => {
      if (algorithm?.autothreshold && autoThresholdsFeatureFlag) {
        return [
          {
            value: 'autothresholds',
            label: 'Auto Thresholds',
            element: <AutoThresholds />,
          },
        ];
      }
      return [
        {
          value: 'overrides',
          label: 'Threshold Overrides',
          element: <ThresholdsOverrides />,
        },
      ];
    },
    [algorithm],
  );

  const postProcessing = useCallback(
    () => {
      if (isFetching) {
        return false;
      }
      if (autoThresholdsFeatureFlag && !isSubmitting) {
        if (submitValues.autoThresholdEnabled && updatedAlgorithm) {
          setIsSubmitting(true);
          const automatonConfig = uiToAutoThresholdParams(submitValues);
          dispatch(
            thresholderActions.updateAutomaton({
              ...automatonConfig,
              afterUpdateMode: true,
            }),
          );
          return false;
        }
        if (
          !submitValues.autoThresholdEnabled
        && thresholdAutomaton
        && !isSubmitting
        ) {
          setIsSubmitting(true);
          dispatch(
            thresholderActions.removeAutomaton({
              ...algorithm,
              afterUpdateMode: true,
            }),
          );
          return false;
        }
      }
      return true;
    },
    [
      autoThresholdsFeatureFlag,
      submitValues,
      updatedAlgorithm,
      isSubmitting,
      isFetching,
    ],
  );

  const onSubmit = useCallback(
    (values) => {
      setSubmitValues(values);
      dispatch(
        methods.update({
          ...uiToParams(values),
          id: algorithmId,
          silent: autoThresholdsFeatureFlag && values.autoThresholdEnabled,
        }),
      );
    },
    [algorithmId],
  );

  const autoThresholdValues = useMemo(
    () => {
      if (algorithm?.autothreshold && thresholdAutomaton) {
        return {
          autoThresholdEnabled: true,
          autoThresholdData: { ...thresholdAutomaton },
        };
      }
      return {
        autoThresholdEnabled: false,
        autoThresholdData: {
          strategy: strategyOptions.average,
          data_interval: cadenceOptions.weekly,
          data_window: 1,
          data_lookback: 60,
        },
      };
    },
    [algorithm, thresholdAutomaton],
  );

  const isDnsEnabled = useFlag('DNS');

  return (
    <CommonEdit
      title="Detection"
      methods={methods}
      FormBody={FormBody}
      routePath={isDnsEnabled ? RoutePaths.modelsDetection : RoutePaths.modelsNetwork}
      deleteButtonText="Delete Detection Model"
      additionalTabs={additionalTabs}
      focusOnFields={false}
      additionalInitialValues={autoThresholdValues}
      onSubmit={onSubmit}
      postProcessing={postProcessing}
    />
  );
};

export default AlgorithmEdit;
