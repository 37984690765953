import PropTypes from '+prop-types';
import { useEffect, useRef, useState } from 'react';
import {
  AuthProvider as AuthProviderOriginal,
  useAuth,
} from 'react-oidc-context';

import { Log, WebStorageStateStore } from 'oidc-client-ts';

import { config } from '@/config';
import authClient from '@/middleware/authClient';

import loader from '+utils/loader';

const propTypes = {
  children: PropTypes.children.isRequired,
};

const listForRemoving = new Set(['code', 'iss', 'state', 'session_state']);

const getUrlWithoutAuthState = () => {
  const { search, hash } = window.location;

  let url = new URLSearchParams(search);

  listForRemoving.forEach((part) => {
    url.delete(part);
  });

  url = url.toString();
  url = url.length ? `?${url}` : '';

  let fixedHash = hash
    .slice(1)
    .split('&')
    .map((item) => item.split('='))
    .filter(([item]) => !listForRemoving.has(item))
    .map((item) => item.join('='))
    .join('&');

  fixedHash = fixedHash.length ? `#${fixedHash}` : '';

  return `${window.location.pathname}${url}${fixedHash}`;
};

const SilentSignin = ({ children }) => {
  const auth = useAuth();
  const [isSilentSignin, setIsSilentSignin] = useState(auth.isAuthenticated);

  useEffect(
    () => {
      (async () => {
        try {
          if (auth.user) {
            loader.setMessage('Authentication... 60%').show();
            await authClient.silentLogin();
          }
          setIsSilentSignin(false);
        } catch (error) {
          authClient.logout();
        }
      })();
    },
    [],
  );

  if (isSilentSignin) {
    return null;
  }

  return children;
};
SilentSignin.propTypes = propTypes;

const Wrapper = ({ children }) => {
  const auth = useAuth();
  authClient.setClient(auth);
  const isErrorRef = useRef(false);

  if (isErrorRef.current) {
    return null;
  }

  if (auth.error || isErrorRef.current) {
    // eslint-disable-next-line no-console
    console.error('auth error:', auth.error.message);

    isErrorRef.current = true;

    if (!auth.user && auth.error.message === 'Failed to fetch') {
      loader.setMessage('Authentication error').show();
      return null;
    }

    auth
      .revokeTokens()
      .then(() => auth.clearStaleState())
      .then(() => auth.removeUser())
      .finally(() => {
        window.location.replace(getUrlWithoutAuthState());
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error('catch error:', err.message);
      });

    return null;
  }

  // console.log({
  //   user: auth.user,
  //   isLoading: auth.isLoading,
  //   isAuthenticated: auth.isAuthenticated,
  //   activeNavigator: auth.activeNavigator,
  //   params: hasAuthParams(),
  // });

  if (auth.isLoading && auth.activeNavigator !== 'signinSilent') {
    return null;
  }

  return <SilentSignin>{children}</SilentSignin>;
};
Wrapper.propTypes = propTypes;

if (config.environment === 'development') {
  Log.setLevel(Log.DEBUG);
  Log.setLogger(console);
}

const oidcConfig = {
  authority: `${config.keycloak.serverUrl}/realms/${config.keycloak.realm}`,
  client_id: config.keycloak.clientId,
  redirect_uri: window.location.href,
  automaticSilentRenew: true,
  onSigninCallback: () => {
    const url = getUrlWithoutAuthState();

    window.history.replaceState({}, document.title, url);
  },
  userStore: new WebStorageStateStore({ store: window.localStorage }),
};

const AuthProvider = ({ children }) => (
  <AuthProviderOriginal {...oidcConfig}>
    <Wrapper>{children}</Wrapper>
  </AuthProviderOriginal>
);
AuthProvider.propTypes = propTypes;

export default AuthProvider;
