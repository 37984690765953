import { dispatch } from 'd3-dispatch';

class DataBus {
  constructor() {
    this._events = dispatch('message');
  }

  emit(message) {
    this._events.call('message', this, message);
  }

  on(id, listener) {
    this._events.on(`message.${id}`, listener);
    return this;
  }

  once(id, listener) {
    this.on(id, (message) => {
      listener(message);
      this.off(id);
    });
    return this;
  }

  off(id) {
    this._events.on(`message.${id}`, null);
    return this;
  }
}

export default new DataBus();
