import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions, selectors } from '@/redux/globalFilters/dateTimeStack';

const useDateTimePeriodStack = () => {
  const dispatch = useDispatch();
  const stack = useSelector(selectors.getStack);

  const push = useCallback(
    (item, keepOnlyFirst) => {
      dispatch(actions.push({ item, keepOnlyFirst }));
    },
    [],
  );

  const pop = useCallback(
    () => {
      dispatch(actions.pop());
    },
    [],
  );

  const clear = useCallback(
    () => {
      dispatch(actions.clear());
    },
    [],
  );

  return [stack, { push, pop, clear }];
};

export default useDateTimePeriodStack;
