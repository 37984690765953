import PropTypes from '+prop-types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { select } from 'd3-selection';
import styled, { createGlobalStyle } from 'styled-components';

import * as PropertiesTray from '@/models/PropertiesTray';

import {
  labelWithDataRenderer,
  autonomousSystemsRenderer,
  countryCodeSeriesValueRenderer,
} from '+components/charts/common/formatters';
import { geoValueToTextFormatter } from '+components/Table/Cells/formatters';
import useGlobalFilters from '+hooks/useGlobalFilters';
import useUIProperty from '+hooks/useUIProperty';
import { UnderlineMixin } from '+theme/mixins/underlineMixin';
import { propsSelectors as mapTheme } from '+theme/slices/map';

import { templater } from './utils/templater';

const uniqueClassName = 'RealTimeMapFlowTable';

const getClassName = (name) => `${uniqueClassName}__${name}`;

const GlobalStyle = createGlobalStyle`
  .${uniqueClassName} {
    &__tcpTag {
      font-weight: bold;
      padding: 2px;
    }

    &__row,
    &__header {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      width: 100%;
      overflow: hidden;
    }

    &__row {
      &:nth-child(odd) {
        background-color: ${mapTheme.tableOddRowBackground};
      }

      &:hover {
        background-color: ${mapTheme.tableRowHover};
      }
    }

    &__header {
      & div {
        text-align: center;
      }
    }

    &__cell {
      flex: 100%;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      position: relative;
      box-sizing: border-box;
      padding: 0 2px;
      justify-content: stretch;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      border-right: 1px solid ${mapTheme.tableCellBorder};
      height: 100%;
      width: 100%;

      &:last-child {
        border-right: 0;
      }
      
      > span {
        cursor: pointer;
      }

      &.protocol {
        min-width: 70px;
        max-width: 70px;
        justify-content: center;
        
        > span {
          ${UnderlineMixin}
        }
      }

      &.bits {
        min-width: 70px;
        max-width: 70px;
        justify-content: center;
        pointer-events: none;
        cursor: default;
      }

      .label-series-group {
        justify-content: flex-start !important;
      }
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

const Row = getClassName('row');
const Header = getClassName('header');
const Ip = getClassName('cell ip');
const Port = getClassName('cell port');
const SrcGeo = getClassName('cell srcGeo');
const SrcOwnerAs = getClassName('cell srcOwnerAs');
const Protocol = getClassName('cell protocol');
const Bits = getClassName('cell bits');

const removeChildren = (node) => {
  let l = node.childNodes.length;
  // eslint-disable-next-line no-plusplus
  while (l--) {
    node.removeChild(node.firstChild);
  }
};

const prepareData = (rawData, labelContext) => rawData.map((row) => {
  let data;
  let labelsContext;
  let labels;

  data = row.srcip;
  labelsContext = labelContext.ip;
  labels = !labelContext.show ? [] : row.label?.ip?.[labelContext.ip]?.src;
  const source = labelWithDataRenderer({
    data,
    labelsContext,
    labels,
  });

  data = row.srcport;
  labelsContext = row.srcport;
  labels = !labelContext.show
    ? []
    : row.label?.port?.[labelContext.port]?.src;
  const srcPort = labelWithDataRenderer({
    data,
    labelsContext,
    labels,
  });

  const srcGeo = countryCodeSeriesValueRenderer(row.srcgeo);
  const srcOwnerAs = autonomousSystemsRenderer(row.srcowneras);

  data = row.dstip;
  labelsContext = labelContext.ip;
  labels = !labelContext.show ? [] : row.label?.ip?.[labelContext.ip]?.dst;
  const destination = labelWithDataRenderer({
    data,
    labelsContext,
    labels,
  });

  data = row.dstport;
  labelsContext = row.dstport;
  labels = !labelContext.show
    ? []
    : row.label?.port?.[labelContext.port]?.dst;
  const dstPort = labelWithDataRenderer({
    data,
    labelsContext,
    labels,
  });

  return {
    ...row,
    source,
    srcPort,
    srcGeo,
    srcOwnerAs,
    destination,
    dstPort,
    protocol: row.protocol,
    bits: +row.bits.toFixed(2),
  };
});

class List {
  constructor(container) {
    this._container = select(container);

    const template = `
      <div class="${Ip}" data-field="srcip">{{source}}</div>
      <div class="${Port}" data-field="srcport">{{srcPort}}</div>
      <div class="${SrcGeo}" data-field="srcgeo">{{srcGeo}}</div>
      <div class="${SrcOwnerAs}" data-field="srcowneras">{{srcOwnerAs}}</div>
      <div class="${Ip}" data-field="dstip">{{destination}}</div>
      <div class="${Port}" data-field="dstport">{{dstPort}}</div>
      <div class="${Protocol}" data-field="protocol"><span>{{protocol}}</span></div>
      <div class="${Bits}" data-field="bits"><span>{{bits}}</span></div>
    `;

    this._formatToHtml = (row) => templater(template, row);
  }

  update(data, labelContext, onRowClick) {
    requestAnimationFrame(() => {
      const fragment = new DocumentFragment();

      prepareData(data, labelContext).forEach((d) => {
        const div = document.createElement('div');
        fragment.append(div);
        select(div)
          .datum(d)
          .attr('class', `list__row ${Row}`)
          .html(this._formatToHtml)
          .on('click', onRowClick(d));
      });

      requestAnimationFrame(() => {
        removeChildren(this._container.node());
        this._container.node().append(fragment);
      });
    });
  }
}

const RealtimeFlowTable = (props) => {
  const { data } = props;

  const [filters] = useGlobalFilters();
  const [, setPropertiesTray] = useUIProperty('propertiesTray', null);

  const [list, setList] = useState(null);
  const ref = useRef();

  const onRowClick = useCallback(
    (d) => (event) => {
      if (
        event.target.classList.contains(getClassName('row'))
        || event.target.classList.contains(getClassName('cell'))
      ) {
        event.stopPropagation();
        return;
      }

      // find element with data-field attribute in parent nodes
      let field = null;
      let node = event.target;
      while (node) {
        field = node.dataset?.field;
        if (field) {
          break;
        }
        node = node.parentNode;
      }

      const value = d[field];

      let title;

      if (field === 'srcgeo') {
        const text = geoValueToTextFormatter(value);
        title = `${field} — ${text}`;
      }

      if (field === 'srcowneras') {
        const text = [value?.number, value?.org].filter(Boolean).join(', ');
        title = `${field} — ${text}`;
      }

      setPropertiesTray({
        data: [
          {
            title,
            dataType: PropertiesTray.DataTypes.field,
            field,
            value,
            customer: d.customer,
          },
        ],
        isOpen: true,
      });
    },
    [],
  );

  useEffect(
    () => {
      if (!ref.current) {
        return undefined;
      }

      setList(new List(ref.current));

      return () => {
        setList(null);
      };
    },
    [ref.current],
  );

  useEffect(
    () => {
      if (!list) {
        return;
      }

      list.update(data, filters.labelContext, onRowClick);
    },
    [data, list, filters.labelContext, onRowClick],
  );

  return useMemo(
    () => (
      <Container>
        <GlobalStyle />
        <div className={Header}>
          <div className={Ip}>
            <div>Source</div>
          </div>
          <div className={Port}>
            <div>SRC Port</div>
          </div>
          <div className={SrcGeo}>
            <div>SRC Geo</div>
          </div>
          <div className={SrcOwnerAs}>
            <div>SRC Owner AS</div>
          </div>
          <div className={Ip}>
            <div>Destination</div>
          </div>
          <div className={Port}>
            <div>DST Port</div>
          </div>
          <div className={Protocol}>
            <div>protocol</div>
          </div>
          <div className={Bits}>
            <div>bits</div>
          </div>
        </div>
        <Container ref={ref} />
      </Container>
    ),
    [],
  );
};

RealtimeFlowTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default RealtimeFlowTable;
