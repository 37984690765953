import PropTypes from '+prop-types';
import { useMemo } from 'react';

import RealtimeManager from '+components/RealtimeManager';
import Table from '+components/Table';
import useGlobalFilters from '+hooks/useGlobalFilters';

import { getColumns } from './flowTableColumns';

const sortBy = [{ id: 'agg_count', desc: true }];

const TrafficTopFlowTable = (props) => {
  const { field, data, noDataText } = props;
  const [filters] = useGlobalFilters();

  const tableColumns = useMemo(
    () => (!field
      ? []
      : getColumns({
        field,
        labelContext: filters.labelContext,
      })),
    [field, filters.labelContext],
  );

  return (
    <Table
      id="TrafficTopFlow_Table"
      minRows={20}
      data={data || []}
      noDataText={noDataText}
      columns={tableColumns}
      sortBy={sortBy}
      fillWithEmptyRows
      disableDuplicateBy
      disableAggregators
      disableGroupBy
    />
  );
};

TrafficTopFlowTable.propTypes = {
  field: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  noDataText: PropTypes.string,
};

TrafficTopFlowTable.defaultProps = {
  field: null,
  data: [],
  noDataText: 'No flow received',
};

export default RealtimeManager(TrafficTopFlowTable, {
  throttle: {
    wait: 100,
    props: ['field', 'data', 'noDataText'],
  },
});
