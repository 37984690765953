import cloneDeep from 'lodash.clonedeep';

import AwsAuthTypes from '@/models/AwsAuthTypes';

const parseIfInt = (value) => (typeof value === 'number' ? value : parseInt(value, 10));

export const excludeMetrics = ['card', 'counts'];

export const deviceUItoParams = (values) => {
  // convert from string to bool
  const ips = Array.isArray(values.ips)
    ? values.ips
    : (values.ips || '').split(',').map((ip) => ip.trim());

  const samplerate = parseIfInt(values.samplerate);
  const downsample = parseIfInt(values.downsample);
  const itemTags = Array.isArray(values.tags) ? values.tags : [];

  return {
    ...values,
    tags: itemTags,
    ips,
    samplerate,
    downsample,
  };
};

const cleanUpAwsFields = (values, roleAuth) => {
  const copy = cloneDeep(values);
  if (roleAuth === AwsAuthTypes.role.value) {
    copy.awsauthtype = 'RoleARN';
    copy.accesskeyid = '';
    copy.accesssecret = '';
  } else {
    copy.awsauthtype = 'AccessKey';
    delete copy.role;
  }
  delete copy.roleAuth;
  return copy;
};

export const cleanUpFields = (values) => {
  if (values.flowtype === 'aws') {
    return cleanUpAwsFields(values, values.roleAuth);
  }
  return values;
};
