import PropTypes from '+prop-types';

import styled from 'styled-components';

import ScrollBar from '+components/ScrollBar/smooth';

const DataColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-right: 1px solid ${({ theme }) => theme.table.colorHeaderBorder};
  padding: 10px 15px;
  &:last-child {
    border-right: 0;
  }
  position: relative;
  overflow: hidden;
`;

const DataColumnTitle = styled.div`
  font-size: 11px;
  line-height: calc(18 / 11);
  font-weight: 600;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colorTextSecondary} !important;
  padding-bottom: 6px;
`;

const DataColumnContainer = styled(ScrollBar)`
  height: 100%;

  .scroll-content > div {
    display: table;
    width: 100%;
  }
`;

export const SubDataColumn = (props) => {
  const { title, children } = props;
  return (
    <DataColumn>
      <DataColumnTitle>{title}</DataColumnTitle>
      <DataColumnContainer>{children}</DataColumnContainer>
    </DataColumn>
  );
};

SubDataColumn.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.children.isRequired,
};
