import styled from 'styled-components';

export const SubDataProperty = styled.div`
  display: table-cell;
  font-size: 13px;
  line-height: calc(18 / 13);
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 4px;
`;
