import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  actions as portalSettingsActions,
  selectors as portalSettingsSelectors,
} from '@/redux/api/portalSettings';

import useUIProperty from '+hooks/useUIProperty';

/**
 * @return {[*, function]}
 */
export default () => {
  const [guest] = useUIProperty('guest');

  const dispatch = useDispatch();
  const settings = useSelector(portalSettingsSelectors.getState);

  const save = useCallback(
    (setting, isDefault) => {
      if (guest) {
        return;
      }
      dispatch(
        portalSettingsActions.savePortalSetting({ ...setting, isDefault }),
      );
    },
    [guest],
  );

  const remove = useCallback(
    (setting) => {
      if (guest) {
        return;
      }
      dispatch(portalSettingsActions.removePortalSetting(setting));
    },
    [guest],
  );

  return [settings, save, remove];
};
