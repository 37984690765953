/* eslint-disable react/no-array-index-key, react/forbid-prop-types */
import PropTypes from '+prop-types';
import { Fragment } from 'react';

import { UnixTimestampFields } from '@/models/UnixTimestampFields';

import {
  numberFormatter,
  timestampFormatter,
} from '+components/Table/Cells/formatters';
import ArrayContainer from '+components/TableTabsSub/components/TableView/components/ArrayContainer';
import Icon from '+components/TableTabsSub/components/TableView/components/Icon';
import Row from '+components/TableTabsSub/components/TableView/components/Row';
import { DateFormat } from '+utils/dayjs';

const portFields = ['srcport', 'dstport', 'srcports', 'dstports'];
const asFields = [
  'dstas.number',
  'dstowneras.number',
  'srcas.number',
  'srcowneras.number',
];

const formatValue = (key, value) => {
  const [lastKeyInPath] = `${key ?? ''}`.split('.').slice(-1);

  if (UnixTimestampFields.includes(lastKeyInPath)) {
    return timestampFormatter(
      value,
      lastKeyInPath === 'flowrtime' ? DateFormat.millisecond : undefined,
    );
  }

  if (key === 'pbratio') {
    return numberFormatter(value, 'en-US', { minimumFractionDigits: 5 });
  }

  switch (typeof value) {
    case 'object':
      return JSON.stringify(value);
    case 'boolean':
      return value ? 'true' : 'false';
    case 'number':
      return portFields.includes(lastKeyInPath) || asFields.includes(key)
        ? value
        : numberFormatter(value);
    default:
      return value;
  }
};

const RowWithIcon = ({ name, value, rootItem, hideIcon, hideName }) => (
  <tr>
    {!hideIcon && (
      <td>
        <Row $center>
          <Icon name={name} value={value} label="icon" />
        </Row>
      </td>
    )}
    {!hideName && (
      <td>
        <Row>{name}</Row>
      </td>
    )}
    <td>
      <Row>
        {Array.isArray(value) ? (
          <ArrayContainer itemCount={value.length} rootItem={rootItem}>
            [
            {value.map((item, index) => {
              if (typeof item === 'object' && item !== null) {
                const itemEntries = Object.entries(item);
                return (
                  <Fragment key={`${name}_${index}`}>
                    <table
                      cellSpacing="1"
                      cellPadding="2"
                      style={{ marginLeft: '20px' }}
                    >
                      <tbody>
                        {Array.isArray(item) ? (
                          <RowWithIcon
                            name={`${index}`}
                            value={item}
                            hideIcon
                            hideName
                          />
                        ) : (
                          itemEntries.map(([_name, _value]) => (
                            <Fragment key={_name}>
                              <RowWithIcon name={_name} value={_value} />
                            </Fragment>
                          ))
                        )}
                      </tbody>
                    </table>
                    {index < value.length - 1 && <span>, </span>}
                  </Fragment>
                );
              }

              return (
                <Fragment key={`${name}_${index}`}>
                  <span>{formatValue(name, item)}</span>
                  {index < value.length - 1 && <span>, </span>}
                </Fragment>
              );
            })}
            ]
          </ArrayContainer>
        ) : (
          formatValue(name, value)
        )}
      </Row>
    </td>
  </tr>
);

RowWithIcon.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  rootItem: PropTypes.bool,
  hideIcon: PropTypes.bool,
  hideName: PropTypes.bool,
};

RowWithIcon.defaultProps = {
  name: '',
  value: null,
  rootItem: false,
  hideIcon: false,
  hideName: false,
};

export default RowWithIcon;
