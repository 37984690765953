import arrayMutators from 'final-form-arrays';

const touched = (args, state) => {
  const [name, value] = args;
  const field = state.fields[name];
  if (field) {
    field.touched = !!value;
  }
};

const runValidation = () => {};

export default {
  touched,
  runValidation,
  ...arrayMutators,
};
