import upperFirst from 'lodash.upperfirst';
import { call, put } from 'redux-saga/effects';

import { actions as toastActions } from '@/redux/toast';
import {
  createSlice,
  startFetching,
  stopFetching,
  defaultReducers,
  takeLeading,
} from '@/redux/util';

import backendClient from '@/middleware/backendClient';

export const initialState = {
  isFetching: false,
  error: '',
  netclasses: {},
  isSubmitting: false, // for modals
  submitSucceeded: false, // for modals
};

let api;

const initApi = () => {
  if (!api) {
    api = backendClient();
  }
};

const slice = createSlice({
  name: 'netclass',
  initialState,

  reducers: {
    ...defaultReducers,
    fetchNetclasses: startFetching,
    setNetclass: startFetching,
    deleteNetclass: startFetching,

    success(state, { payload: { data: [netclass] = [] } = {} }) {
      stopFetching(state);
      if (netclass?.type) {
        state.netclasses[netclass.type] = netclass;
      }
    },

    successDelete(state, { payload }) {
      stopFetching(state);
      delete state.netclasses[payload];
    },

    resetSubmit(state) {
      state.submitSucceeded = false;
      state.isSubmitting = false;
    },

    skip: stopFetching,
  },

  sagas: (actions) => ({
    [actions.fetchNetclasses]: {
      taker: takeLeading(actions.skip),
      * saga() {
        initApi();
        try {
          const { data } = yield call(api.get, '/netclasses');
          yield put(actions.success(data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching Network Classifications',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.setNetclass]: {
      * saga({ payload: { type, ...params } }) {
        initApi();
        try {
          const response = yield call(api.put, `/netclass/${type}`, params);
          yield put(actions.success(response.data));
          yield put(actions.resetSubmit());
          yield put(
            toastActions.successWithAuditLogVerification({
              message: `${upperFirst(
                type,
              )} Network Classification has been updated`,
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error configuring Network Classification',
              details: error.message,
            }),
          );
        }
      },
    },
  }),
});

slice.selectors = {
  ...slice.selectors,

  getState: (state) => {
    return slice.selector(state) || {};
  },

  getNetclasses: (state) => {
    return slice.selector(state).netclasses || {};
  },

  getNetclassByType: (state, type) => {
    return slice.selector(state).netclasses[type] || {};
  },

  isFetching: (state) => slice.selector(state).isFetching,
};

// For convenience
export const { actions, selectors } = slice;

export default slice;
