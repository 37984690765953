import { useMemo } from 'react';

import RoleModel from '@/models/Role';

import Field from '+components/form/FinalForm/Field';
import { Group, Label } from '+components/form/FormField';
import { normalizeSelectValue } from '+components/form/Normalizers';
import Plaintext from '+components/form/Plaintext';
import SelectField from '+components/form/SelectField';
import TextField from '+components/form/TextField';
import {
  validateRequired,
  validateApiKeyName,
} from '+components/form/Validators';
import FormModal, { propTypes, defaultProps } from '+components/FormModal';

const ApiKeyModal = (props) => {
  const { mode, initialValues, disabled, roles, ...tail } = props;

  const rolesOptions = useMemo(
    () => Object.values(roles || {}).sort(RoleModel.sortRoles),
    [roles],
  );

  return (
    <FormModal
      {...tail}
      mode={mode}
      initialValues={initialValues}
      disabled={disabled}
    >
      {mode === 'edit' ? (
        <Group>
          <Label>Application name</Label>
          <Plaintext>{initialValues.appname}</Plaintext>
        </Group>
      ) : (
        <Field
          name="appname"
          label="Application name"
          component={TextField}
          validate={[validateRequired, validateApiKeyName]}
          required
          disabled={disabled}
        />
      )}

      <Field
        name="description"
        label="Description"
        component={TextField}
        disabled={disabled}
      />

      <Field
        name="roles[0]"
        label="Role"
        component={SelectField}
        options={rolesOptions}
        groupBy={(item) => (item.system ? 'System' : 'Custom')}
        parse={normalizeSelectValue}
        validate={validateRequired}
        required
        disabled={disabled}
      />
    </FormModal>
  );
};

ApiKeyModal.propTypes = propTypes;
ApiKeyModal.defaultProps = defaultProps;

export default ApiKeyModal;
