import { makeId } from '+utils/general';

const set = () => {
  const value = makeId(32);
  document.cookie = `feature-session=${value}; Path=/`;
  return value;
};

const get = () => {
  const name = 'feature-session=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const found = decodedCookie
    .split(';')
    .find((cookie) => cookie.trim().startsWith(name));

  if (found) {
    return found.split('=')[1];
  }

  return set();
};

export default get;
