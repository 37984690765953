import makeArr from '+utils/makeArr';

export const paramsToUi = (params) => {
  const nql = [...makeArr(params.nql)];
  if (!nql.length) {
    nql.push('');
  }
  const customers = makeArr(params.customers).filter(Boolean);
  const ipLabelContext = params.labelContext?.show
    ? params.labelContext?.ip
    : params.labelContext?.show;

  return { ...params, nql, customers, ipLabelContext };
};

export const uiToParams = ({ ipLabelContext, ...values }) => {
  const nql = makeArr(values.nql).filter((item) => !!item?.trim());
  const customers = makeArr(values.customers).filter(Boolean);
  const labelContext = ipLabelContext
    ? {
      show: true,
      ip: ipLabelContext,
      port: values.labelContext?.port || 'name',
    }
    : {
      show: false,
      ip: values.labelContext?.ip || 'name',
      port: values.labelContext?.port || 'name',
    };

  return { ...values, nql, customers, labelContext };
};
