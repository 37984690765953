import PropTypes from '+prop-types';
import { useState, useMemo, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useToggle } from 'react-use';

import isEqual from 'lodash.isequal';
import styled from 'styled-components';

import { ContextTypes } from '@/models/ContextTypes';
import PermissionModel from '@/models/Permission';
import RoutePaths from '@/models/RoutePaths';

import { selectors as rulesSelectors } from '@/redux/api/rules';
import { selectors as profileSelectors } from '@/redux/api/user/profile';

import { Breadcrumb } from '+components/Breadcrumb';
import ConfirmModal from '+components/ConfirmModal';
import EditPageAuditLogTabs from '+components/EditPageAuditLogTabs';
import usePageTabs from '+hooks/usePageTabs';
import usePermissions from '+hooks/usePermissions';
import useUIProperty from '+hooks/useUIProperty';
import nqlLang from '+utils/nqlLang';

import CommonForm from './CommonForm';
import { paramsToUi } from './utils';

const Container = styled.div`
  .MuiTabs-root {
    visibility: hidden;
    width: 0;
    min-width: 0;
    max-width: 0;
    height: 0;
    min-height: 0;
    max-height: 0;
  }
`;

const CommonEdit = (props) => {
  const {
    title,
    FormBody,
    additionalTabs,
    routePath,
    methods,
    deleteButtonText,
    focusOnFields,
    additionalInitialValues,
    onSubmit,
    postProcessing,
  } = props;

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [, activePageTab] = usePageTabs();
  const { algorithmId } = useParams();
  const { error } = useSelector(rulesSelectors.getState);
  const isFetching = useSelector(rulesSelectors.isFetching);
  const algorithm = useSelector(methods.get(algorithmId));

  const isDefaultCustomer = useSelector(profileSelectors.isDefaultCustomer);
  const permissions = usePermissions(
    PermissionModel.Resources.threat_model.value,
  );
  const canRemove = (!algorithm?.system || isDefaultCustomer) && permissions?.delete;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [initialValuesMap, setInitialValuesMap] = useState({});
  // if the user nav'd from the rules list, then the algorithm will already be in redux
  // if directly loading (like on a refresh, then we need to call the api to fetch the algorithm)
  const [isLoadingAlgorithm, setIsLoadingAlgorithm] = useState(false);

  const [showResetModal, toggleResetModal] = useToggle(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const onCancel = useCallback(
    () => {
      navigate(`${RoutePaths.models}`);
    },
    [],
  );

  const doSubmit = useCallback(
    (values) => {
      setIsSubmitting(activePageTab?.id);
      onSubmit(values);
    },
    [onSubmit, activePageTab?.id],
  );

  useEffect(
    () => {
      if (!isSubmitting || isFetching) {
        return;
      }

      if (isSubmitting !== activePageTab?.id) {
        return;
      }

      if (error) {
        setIsSubmitting(false);
        return;
      }

      const isOk = postProcessing?.() ?? true;

      if (isOk) {
        onCancel();
      }
    },
    [
      isSubmitting,
      isFetching,
      error,
      postProcessing,
      activePageTab?.id,
      onCancel,
    ],
  );

  const onCreateAsNew = useCallback(
    () => {
      navigate(`${routePath}/add?init=${algorithm?.id}`);
    },
    [algorithm?.id, routePath],
  );

  const onReset = useCallback(
    () => {
      dispatch(methods.reset(algorithmId));
    },
    [dispatch, algorithmId],
  );

  const additionalActions = useMemo(
    () => (permissions?.create
      ? [{ text: 'Create As New', onClick: onCreateAsNew }]
      : undefined),
    [permissions, onCreateAsNew],
  );

  const onDeleteModalToggle = useCallback(
    () => {
      setShowDeleteModal((prevValue) => !prevValue);
    },
    [],
  );

  const onDelete = useCallback(
    () => {
      dispatch(methods.remove(algorithmId));
      onCancel();
    },
    [algorithmId, onCancel],
  );

  useEffect(
    () => {
      if (!algorithm) {
        setIsLoadingAlgorithm(true);
        dispatch(methods.fetch(algorithmId));
      }
    },
    [algorithm, algorithmId],
  );

  useEffect(
    () => {
      if (algorithm || error) {
        setIsLoadingAlgorithm(false);
      }
    },
    [algorithm, error],
  );

  // Workaround to prevent form rerendering while using fieldsArray with async validation
  useEffect(
    () => {
      setInitialValuesMap((prev) => {
        const context = algorithm?.algo_record_type || ContextTypes.flow;
        const next = {
          [context]: {
            ...paramsToUi({ ...algorithm, ...additionalInitialValues }),
          },
        };
        return isEqual(prev, next) ? prev : next;
      });
    },
    [algorithm, additionalInitialValues],
  );

  const [, setMasqueradeUrl] = useUIProperty('masqueradeUrl');
  useEffect(
    () => {
      if (algorithm?.id && !algorithm.system) {
        setMasqueradeUrl(`${RoutePaths.models}`);
      }
      return () => {
        setMasqueradeUrl(null);
      };
    },
    [algorithm],
  );

  const fixedTitle = `Edit ${
    algorithm?.system ? 'System' : 'Custom'
  } ${title} Creation Model - ${algorithm?.name || 'unknown'}`;

  return (
    <EditPageAuditLogTabs
      auditNqlQuery={nqlLang.and(
        nqlLang.equal('class', 'threat_model'),
        nqlLang.equal('original_id', algorithmId),
      )}
      additionalTabs={additionalTabs}
      breadcrumb={<Breadcrumb title={fixedTitle} pathname={location.pathname} />}
    >
      <Container>
        {!!Object.keys(initialValuesMap).length && (
          <CommonForm
            FormBody={FormBody}
            isDefaultCustomer={isDefaultCustomer}
            mode="update"
            initialValuesMap={initialValuesMap}
            initialContext={algorithm?.algo_record_type || ContextTypes.flow}
            loading={isLoadingAlgorithm || !!isSubmitting}
            permissions={permissions}
            confirmButtonText="Update"
            additionalActions={additionalActions}
            secondaryButtonText={
              algorithm?.system && !algorithm?.systemdefault
                ? 'Reset Customization'
                : undefined
            }
            secondaryButtonDisabled={!permissions?.update}
            onSecondary={
              algorithm?.system && !algorithm?.systemdefault
                ? toggleResetModal
                : undefined
            }
            onSubmit={doSubmit}
            onCancel={onCancel}
            deleteButtonText={deleteButtonText}
            onDelete={onDeleteModalToggle}
            deleteButtonHidden={!algorithm?.id}
            deleteButtonDisabled={!canRemove}
            focusOnFields={focusOnFields}
          />
        )}

        {showResetModal && (
          <ConfirmModal
            item={algorithm?.name}
            confirmButtonText="Reset"
            whyAsking="This will undo any customization for this system algorithm and restore the system default values."
            isDisabled={isLoadingAlgorithm || isSubmitting}
            onToggle={toggleResetModal}
            onConfirm={onReset}
            isOpen
          />
        )}

        {showDeleteModal && (
          <ConfirmModal
            item={algorithm?.name}
            onToggle={onDeleteModalToggle}
            onConfirm={onDelete}
            isOpen
          />
        )}
      </Container>
    </EditPageAuditLogTabs>
  );
};

CommonEdit.propTypes = {
  title: PropTypes.string.isRequired,
  FormBody: PropTypes.component.isRequired,
  additionalTabs: PropTypes.arrayOf(PropTypes.object),
  routePath: PropTypes.shape({
    pageName: PropTypes.string.isRequired,
  }).isRequired,
  methods: PropTypes.shape({
    fetch: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    get: PropTypes.func.isRequired,
  }).isRequired,
  deleteButtonText: PropTypes.string.isRequired,
  focusOnFields: PropTypes.bool,
  additionalInitialValues: PropTypes.shape(),
  onSubmit: PropTypes.func.isRequired,
  /**
   * Function to be called after the form is submitted and the API call is finished without errors
   * @returns {boolean} - if the form should be closed or not
   */
  postProcessing: PropTypes.func,
};

CommonEdit.defaultProps = {
  additionalTabs: undefined,
  focusOnFields: true,
  additionalInitialValues: {},
  postProcessing: null,
};

export default CommonEdit;
