import { useRef } from 'react';

/**
 * Mutate the value returned by this hook whenever you need a mutable object
 * that persists state across renders. It will not cause re-renders across
 * state changes.
 * @param {Object} [init]
 */
export const useMutableValue = (init = {}) => {
  const ref = useRef(init);
  return ref.current;
};
