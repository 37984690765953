import PropTypes from '+prop-types';
import { Fragment, useCallback, useEffect } from 'react';

import DateUtils from '@date-io/luxon';
import styled from 'styled-components';

import AlarmIcon from 'mdi-react/AlarmIcon';
import CalendarMonthOutlineIcon from 'mdi-react/CalendarMonthOutlineIcon';

import Button, { ButtonVariants } from '+components/Button';

import { ViewTypes } from './ViewTypes';

const utils = new DateUtils();
const { formats } = utils;
formats.ampm = 'a';

const ToolbarContainer = styled.div`
  color: ${({ theme }) => theme.colorText};
  display: flex;
  align-items: center;
  font-size: 1.2em;
  padding: 15px;
  gap: 0.2em;
  background: ${({ theme }) => theme.dateTimePickerCalendarToolbarBackground};

  & > svg {
    margin-right: 0.2em;
  }

  user-select: none;
`;

const ToolbarButton = styled.button.attrs(() => ({ type: 'button' }))`
  margin: 0;
  padding: 0;
  border: 0;
  color: inherit;
  background: transparent;
  opacity: ${({ $selected }) => ($selected ? 0.8 : 0.3)};
  transition: opacity 0.3s, background 0.3s;
  font-weight: bold;

  display: flex;
  align-items: center;

  :hover {
    background: rgba(0, 0, 0, 0.3);
    opacity: ${({ $selected }) => ($selected ? 1 : 0.6)};
  }

  :active {
    background: rgba(0, 0, 0, 0.5);
  }

  :disabled,
  * {
    pointer-events: none;
  }
`;

const ToolbarSeparator = styled.div``;

const ToolbarSpace = styled.div`
  flex: 1 1 0;
`;

const ButtonContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  transform: translate(15px, -125%);
  z-index: 10;
  display: flex;
  gap: 10px;
`;

const ActionButton = styled(Button)`
  min-width: 10ch;
  * {
    pointer-events: none;
  }
`;

const empty = '‒ ‒';

const format = (data, formatString) => {
  if (!data) {
    return '';
  }

  return utils.formatByString(data, formatString);
};

const Toolbar = (props) => {
  const {
    parsedValue: date,
    setOpenView,
    openView,
    ampm,
    views,
    onChange,
  } = props;

  const hour = date ? utils.getHours(date) : null;

  const ampmMode = (ampm && hour != null && (hour >= 12 ? 'pm' : 'am')) || null;

  const year = format(date, formats.year) || `${empty} ${empty}`;
  const day = format(date, formats.shortDate) || '‒ ‒ ‒ ‒ ‒';
  const hours = format(date, ampm ? 'hh' : 'HH') || empty;
  const minutes = format(date, 'mm') || empty;
  const seconds = format(date, 'ss') || empty;
  const appendix = ampm ? format(date, formats.ampm) || empty : '';

  const onActionClick = useCallback(
    (event) => {
      const type = event.target.dataset.action;
      document.dispatchEvent(
        new CustomEvent('toolbarActionButton', { detail: { type } }),
      );
    },
    [],
  );

  const isDateBlock = views.includes(ViewTypes.year) || views.includes(ViewTypes.day);

  const isTimeBlock = views.includes(ViewTypes.hours)
    || views.includes(ViewTypes.minutes)
    || views.includes(ViewTypes.seconds);

  useEffect(
    () => {
      if (ampmMode != null && ampm) {
        onChange(date);
      }
    },
    [ampmMode],
  );

  return (
    <ToolbarContainer>
      {isDateBlock && (
        <Fragment>
          <CalendarMonthOutlineIcon size={18} />
          <ToolbarButton
            onClick={() => setOpenView(ViewTypes.year)}
            $selected={openView === ViewTypes.year}
          >
            {year}
          </ToolbarButton>
          <ToolbarSeparator>, </ToolbarSeparator>
          <ToolbarButton
            onClick={() => setOpenView(ViewTypes.day)}
            $selected={openView === ViewTypes.day}
          >
            {day}
          </ToolbarButton>
        </Fragment>
      )}
      <ToolbarSpace />

      {isTimeBlock && (
        <Fragment>
          <AlarmIcon size={18} />
          <ToolbarButton
            disabled={hours === empty}
            onClick={() => setOpenView(ViewTypes.hours)}
            $selected={openView === ViewTypes.hours}
          >
            {hours}
          </ToolbarButton>
          <ToolbarSeparator>:</ToolbarSeparator>
          <ToolbarButton
            disabled={minutes === empty}
            onClick={() => setOpenView(ViewTypes.minutes)}
            $selected={openView === ViewTypes.minutes}
          >
            {minutes}
          </ToolbarButton>
          {views.includes(ViewTypes.seconds) && (
            <Fragment>
              <ToolbarSeparator>:</ToolbarSeparator>
              <ToolbarButton
                disabled={seconds === empty}
                onClick={() => setOpenView(ViewTypes.seconds)}
                $selected={openView === ViewTypes.seconds}
              >
                {seconds}
              </ToolbarButton>
            </Fragment>
          )}
          <ToolbarButton
            disabled={appendix === empty}
            onClick={() => setOpenView(ViewTypes.hours)}
          >
            {appendix}
          </ToolbarButton>
        </Fragment>
      )}
      <ButtonContainer>
        <ActionButton data-action="apply" onClick={onActionClick}>
          Apply
        </ActionButton>
        <ActionButton
          variant={ButtonVariants.outlined}
          data-action="cancel"
          onClick={onActionClick}
        >
          Cancel
        </ActionButton>
      </ButtonContainer>
    </ToolbarContainer>
  );
};

Toolbar.propTypes = {
  parsedValue: PropTypes.shape(),
  setOpenView: PropTypes.func.isRequired,
  openView: PropTypes.string.isRequired,
  ampm: PropTypes.bool.isRequired,
  views: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
};

Toolbar.defaultProps = {
  parsedValue: null,
};

export default Toolbar;
