import { BooleanColumnFilter } from '+components/Table/Filters';

import { BaseColumnFactory } from './BaseColumnFactory';

/**
 * @param {Object} props
 * @param {string} props.field - field name
 * @param {string} [props.description=''] - field description
 * @return {Object} - column with filter=booleanFilter and Filter=BooleanColumnFilter
 */
export const BooleanColumnFactory = (props) => ({
  ...BaseColumnFactory(props),
  width: 70,
  getCellProps: () => ({ style: { justifyContent: 'center' } }),
  sortType: 'boolean',
  Filter: BooleanColumnFilter(),
  filter: 'booleanFilter',
  // eslint-disable-next-line react/prop-types
  Cell: ({ value }) => {
    if (value == null) {
      return null;
    }
    return value ? 'TRUE' : <span style={{ color: 'red' }}>FALSE</span>;
  },
});
