export const cleanObject = (obj) => {
  const newObj = {};
  Object.keys(obj || {}).forEach((key) => {
    if (obj[key] === undefined) {
      return;
    }
    if (Array.isArray(obj[key]) && !obj[key].length) {
      return;
    }
    newObj[key] = obj[key];
  });
  return newObj;
};
