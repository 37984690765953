import { createSelector } from 'reselect';

import { createSlice } from '@/redux/util';

const initialState = {
  isPaused: true,
  shown: false,
  hasData: false,
  instances: 0,
  pauseTime: Date.now(),
};

export const slice = createSlice({
  name: 'socketControl',
  initialState,

  reducers: {
    pause(state) {
      state.isPaused = true;
      state.pauseTime = Date.now();
    },
    resume(state) {
      state.isPaused = false;
      state.pauseTime = null;
    },
    hide(state) {
      state.instances -= 1;
      if (state.instances < 0) {
        state.instances = 0;
      }
      state.shown = !!state.instances;
    },
    show(state) {
      state.instances += 1;
      state.shown = true;
    },
    setHasData(state, { payload }) {
      state.hasData = payload;
    },
  },

  sagas: () => ({}),

  selectors: (getState) => ({
    isPaused: createSelector(
      [getState],
      (state) => state.isPaused,
    ),
    // TODO: do we need this pauseTime?
    getPauseTime: createSelector(
      [getState],
      (state) => state.pauseTime,
    ),
  }),
});

export const { actions, selectors } = slice;
export default slice;
