import * as timeInterval from '@/shared/misc/timeIntervals';

import { DateFormat } from '+utils/dayjs';

export const TimeDuration = {
  second: timeInterval.durationSecond,
  minute: timeInterval.durationMinute,
  minutes5: timeInterval.durationMinute * 5,
  minutes10: timeInterval.durationMinute * 10,
  halfHour: timeInterval.durationMinute * 30,
  hour: timeInterval.durationHour,
  hours6: timeInterval.durationHour * 6,
  hours12: timeInterval.duration12Hours,
  hours24: timeInterval.durationDay,
  day: timeInterval.durationDay,
  week: timeInterval.durationWeek,
  days30: timeInterval.durationDays30,
  month: timeInterval.durationDay * 31,
  days90: timeInterval.durationDays90,
  days180: timeInterval.durationDays180,
  year: timeInterval.durationYear,
};

// moment.js formatting
export const TimePeriods = {
  [TimeDuration.minute]: {
    period: TimeDuration.minute,
    query: -TimeDuration.minute / 1000,
    name: 'Minutes ago',
    format: DateFormat.minuteWithoutDate,
    fullFormat: DateFormat.minute,
    interval: '10s',
  },
  [TimeDuration.hour]: {
    period: TimeDuration.hour,
    query: -TimeDuration.hour / 1000,
    name: 'Hours ago',
    format: DateFormat.minuteWithoutDate,
    fullFormat: DateFormat.minute,
    interval: '10s',
  },
  [TimeDuration.hours6]: {
    period: TimeDuration.hours6,
    query: -TimeDuration.hours6 / 1000,
    name: '6 Hours ago',
    format: DateFormat.minuteWithoutDate,
    fullFormat: DateFormat.minute,
    interval: '1m',
  },
  [TimeDuration.hours12]: {
    period: TimeDuration.hours12,
    query: -TimeDuration.hours12 / 1000,
    name: '12 Hours ago',
    format: DateFormat.minuteWithoutDate,
    fullFormat: DateFormat.minute,
    interval: '5m',
  },
  [TimeDuration.day]: {
    period: TimeDuration.day,
    query: -TimeDuration.day / 1000,
    name: 'Days ago',
    format: DateFormat.minuteWithoutYear,
    fullFormat: DateFormat.minute,
    interval: '10m',
  },
  [TimeDuration.week]: {
    period: TimeDuration.week,
    query: -TimeDuration.week / 1000,
    name: 'Weeks ago',
    format: DateFormat.dayWithoutYear,
    fullFormat: DateFormat.day,
    interval: '30m',
  },
  [TimeDuration.month]: {
    period: TimeDuration.month,
    query: -TimeDuration.month / 1000,
    name: 'Months ago',
    format: DateFormat.dayWithoutYear,
    fullFormat: DateFormat.day,
    interval: '1h',
  },
  [TimeDuration.days90]: {
    period: TimeDuration.days90,
    query: -TimeDuration.days90 / 1000,
    name: '90 days ago',
    format: DateFormat.dayWithoutYear,
    fullFormat: DateFormat.day,
    interval: '1d',
  },
  [TimeDuration.days180]: {
    period: TimeDuration.days180,
    query: -TimeDuration.days180 / 1000,
    name: '180 days ago',
    format: DateFormat.dayWithoutYear,
    fullFormat: DateFormat.day,
    interval: '1d',
  },
  [TimeDuration.year]: {
    period: TimeDuration.year,
    query: -TimeDuration.year / 1000,
    name: 'Years ago',
    format: DateFormat.dayWithoutYear,
    fullFormat: DateFormat.day,
    interval: '1d',
  },
};
