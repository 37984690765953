import PlusIcon from 'mdi-react/PlusIcon';

import { ItemContainer, Icon, Title, Space } from './LayoutItem';

export default (props) => (
  <ItemContainer {...props}>
    <Icon>
      <PlusIcon size={14} />
    </Icon>
    <Title>Save Current Layout</Title>
    <Space />
  </ItemContainer>
);
