import {
  MenuColumnFactory,
  TimestampColumnFactory,
} from '+components/Table/Columns';
import dayjs, { DateFormat } from '+utils/dayjs';
import { getColumnsHelper } from '+utils/getColumnsHelper';

const dateFormatter = ({ value }) => {
  return value ? dayjs(value).format(DateFormat.minute) : '';
};

export const Columns = {
  userLabel: 'userLabel',
  createdDate: 'createdDate',
  menu: 'menu',
};

const columnsCollection = {
  [Columns.userLabel]: {
    accessor: Columns.userLabel,
    Header: 'Device',
    Cell: ({ value }) => value || 'Unnamed',
  },
  [Columns.createdDate]: TimestampColumnFactory({
    accessor: Columns.createdDate,
    Header: 'Created Date',
    width: 130,
    Cell: dateFormatter,
  }),
  [Columns.menu]: MenuColumnFactory,
};

export const getColumns = getColumnsHelper(columnsCollection);
