import { useEffect } from 'react';

import loader from '+utils/loader';

export const useRemoveLoader = () => {
  useEffect(
    () => {
      loader.hide();
    },
    [],
  );
};
