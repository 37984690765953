/* eslint-disable max-len */
import PropTypes from '+prop-types';
import { memo } from 'react';

const SlackIcon = memo(({ size }) => (
  <svg
    fill="#fff"
    fillRule="evenodd"
    viewBox="0 0 64 64"
    width={size}
    height={size}
    strokeLinecap="round"
    strokeLinejoin="round"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g strokeWidth=".790123">
      <path
        d="m17.777788 40.309957c0-3.728276 2.81916-6.729717 6.320986-6.729717 3.501827 0 6.320986 3.001441 6.320986 6.729717v16.56525c0 3.728197-2.819159 6.729717-6.320986 6.729717-3.501826 0-6.320986-3.00152-6.320986-6.729717z"
        fill="#e01e5a"
        stroke="#e01e5a"
      />
      <path
        d="m40.309891 46.222212c-3.728196 0-6.729638-2.81916-6.729638-6.320986s3.001442-6.320986 6.729638-6.320986h16.565409c3.728197 0 6.729638 2.81916 6.729638 6.320986s-3.001441 6.320986-6.729638 6.320986z"
        fill="#ecb22d"
        stroke="#ecb22d"
      />
      <path
        d="m33.580253 7.1246997c0-3.7281963 2.81916-6.72963758 6.320987-6.72963758 3.501826 0 6.320986 3.00144128 6.320986 6.72963758v16.5653303c0 3.728275-2.81916 6.729717-6.320986 6.729717-3.501827 0-6.320987-3.001442-6.320987-6.729717z"
        fill="#2fb67c"
        stroke="#2fb67c"
      />
      <path
        d="m7.1247142 30.419747c-3.7281967 0-6.72963796-2.81916-6.72963796-6.320987 0-3.501826 3.00144126-6.320986 6.72963796-6.320986h16.5653288c3.728276 0 6.729717 2.81916 6.729717 6.320986 0 3.501827-3.001441 6.320987-6.729717 6.320987z"
        fill="#36c5f1"
        stroke="#36c5f1"
      />
    </g>
    <g
      strokeLinejoin="miter"
      transform="matrix(.79012328 0 0 .79012328 -.395047 -.395061)"
    >
      <path
        d="m43 73a7.983 7.983 0 0 0 8 8c4.432 0 8-3.568 8-8a7.983 7.983 0 0 0 -8-8h-8z"
        fill="#ecb22d"
        stroke="#ecb22d"
      />
      <path
        d="m73 39h-8v-8c0-4.432 3.568-8 8-8s8 3.568 8 8-3.568 8-8 8z"
        fill="#2fb67c"
        stroke="#2fb67c"
      />
      <path
        d="m9 43h8v8c0 4.432-3.568 8-8 8a7.983 7.983 0 0 1 -8-8c0-4.432 3.568-8 8-8z"
        fill="#e01e5a"
        stroke="#e01e5a"
      />
      <path
        d="m39 9v8h-8a7.983 7.983 0 0 1 -8-8c0-4.432 3.568-8 8-8a7.983 7.983 0 0 1 8 8z"
        fill="#36c5f1"
        stroke="#36c5f1"
      />
    </g>
  </svg>
));

SlackIcon.propTypes = {
  size: PropTypes.number,
};

SlackIcon.defaultProps = {
  size: 24,
};

export { SlackIcon };
