import { useEffect } from 'react';

import gtm from '+utils/GoogleTagManager';

export const useGoogleTagManager = () => {
  useEffect(
    () => {
      gtm.init();
    },
    [],
  );
};

export default useGoogleTagManager;
