import PropTypes from '+prop-types';
import * as CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch } from 'react-redux';

import styled from 'styled-components';

import { actions as toastActions } from '@/redux/toast';

import Button, { ButtonVariants } from '+components/Button';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '+components/Modal';

const MessageContainer = styled.div`
  white-space: pre-line;
  overflow-wrap: break-word;
`;

const MessageDetailsModal = ({ isOpen, toggleModal, message }) => {
  const dispatch = useDispatch();

  return (
    <Modal isOpen={isOpen} onClose={toggleModal}>
      <ModalHeader onClose={toggleModal}>Error Message Details</ModalHeader>
      <ModalBody>
        <MessageContainer>{message}</MessageContainer>
      </ModalBody>
      <ModalFooter>
        <CopyToClipboard
          text={message}
          onCopy={() => dispatch(toastActions.info('Copied to clipboard'))}
        >
          <Button>Copy</Button>
        </CopyToClipboard>
        <Button onClick={toggleModal} variant={ButtonVariants.outlined}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

MessageDetailsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
};

export default MessageDetailsModal;
