export const WorkerEvents = {
  data: 'data',
  tick: 'tick',
  stop: 'stop',
  restart: 'restart',
  switchAutoStop: 'switchAutoStop',
  updateNode: 'updateNode',
  updateNodes: 'updateNodes',
};

Object.keys(WorkerEvents).forEach((key) => {
  WorkerEvents[key] = (data) => ({
    type: key,
    data,
  });
  WorkerEvents[key].toString = () => key;
});
