import styled from 'styled-components';

import Spinner from '+components/Spinner';

export default styled(({ className, testId }) => (
  <div className={className} data-testid={testId}>
    <Spinner $size={24} />
  </div>
))`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  visibility: ${(props) => (props.$active ? 'visible' : 'hidden')};
  margin: 0 18px;
`;
