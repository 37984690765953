import styled from 'styled-components';

import FieldsSection from '+components/form/FieldsSection';

// TODO: remove this file and replace with <FieldsSection boldLabel formLabelMargin >
const RoleAuthFieldsSection = styled(FieldsSection)`
  .fields-section-label {
    margin-left: 140px;
  }
`;

export default RoleAuthFieldsSection;
