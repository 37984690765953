import styled from 'styled-components';

import IconButton from '+components/IconButton';

import ButtonMixin from './ButtonMixin';

const MenuColumnButton = styled(IconButton)`
  ${ButtonMixin};
`;

export default MenuColumnButton;
