import PropTypes from '+prop-types';
import { memo } from 'react';

const IntegrationIcon = memo(({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill={color}
      width={size}
      height={(19.272 / 23.882) * size}
      viewBox="0 0 23.882 19.272"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_2140"
            data-name="Rectangle 2140"
            width={size}
            height={(19.272 / 23.882) * size}
            transform="translate(0 0)"
            fill="white"
          />
        </clipPath>
      </defs>
      <g id="Group_859" data-name="Group 859" transform="translate(0.001 0)">
        <g id="Group_857" data-name="Group 857" transform="translate(-0.001 0)">
          <path
            id="Path_535"
            data-name="Path 535"
            d="M23.654,12C22.135,12,21,12.7,21,13.327s1.135,1.327,2.654,1.327,2.654-.7,2.654-1.327S25.172,12,23.654,12"
            transform="translate(-11.713 -6.693)"
          />
          <path
            id="Path_536"
            data-name="Path 536"
            d="M39.654,8.6c1.519,0,2.654-.7,2.654-1.327s-1.135-1.327-2.654-1.327S37,6.65,37,7.276,38.135,8.6,39.654,8.6"
            transform="translate(-20.637 -3.318)"
          />
          <path
            id="Path_537"
            data-name="Path 537"
            d="M23.654,2.654c1.519,0,2.654-.7,2.654-1.327S25.172,0,23.654,0,21,.7,21,1.327s1.135,1.327,2.654,1.327"
            transform="translate(-11.713 0)"
          />
          <path
            id="Path_538"
            data-name="Path 538"
            d="M8.646,8.6c1.519,0,2.654-.7,2.654-1.327S10.165,5.949,8.646,5.949s-2.654.7-2.654,1.327S7.127,8.6,8.646,8.6"
            transform="translate(-3.342 -3.318)"
          />
          <path
            id="Path_539"
            data-name="Path 539"
            d="M28,18.945V27.09l11.5-4.931V14.017Z"
            transform="translate(-15.617 -7.818)"
          />
          <path
            id="Path_540"
            data-name="Path 540"
            d="M0,22.079,11.5,27.01V18.865L0,13.875Z"
            transform="translate(0 -7.739)"
          />
          <path
            id="Path_541"
            data-name="Path 541"
            /* eslint-disable-next-line max-len */
            d="M22.846,7.3l0,0-.015-.006a4.063,4.063,0,0,1-3.2,1.268c-1.951,0-3.538-.992-3.538-2.211a1.887,1.887,0,0,1,.979-1.522L15.961,4.35a.094.094,0,0,1,0-.015l-.008,0a3.7,3.7,0,0,1-3.4,1.6A3.725,3.725,0,0,1,9.171,4.35l-.865.371A1.981,1.981,0,0,1,9.461,6.35c0,1.22-1.587,2.211-3.538,2.211a3.9,3.9,0,0,1-3.294-1.41L1.111,7.8l11.45,4.968L24.082,7.833ZM12.56,11.239c-1.951,0-3.538-.992-3.538-2.211S10.61,6.816,12.56,6.816,16.1,7.808,16.1,9.027s-1.587,2.211-3.538,2.211"
            transform="translate(-0.62 -2.416)"
          />
        </g>
      </g>
    </svg>
  );
});

IntegrationIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

IntegrationIcon.defaultProps = {
  color: 'currentColor',
  size: 24,
};

export { IntegrationIcon };
