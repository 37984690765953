import { useEffect, useMemo, Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useToggle } from 'react-use';

import { useFlag } from '@unleash/proxy-client-react';

import { ContextTypes } from '@/models/ContextTypes';
import { DateTimeModes } from '@/models/DateTimeModes';

import {
  actions as vpcActions,
  selectors as vpcSelectors,
} from '@/redux/api/vpc';

import { Breadcrumb } from '+components/Breadcrumb';
import GlobalFiltersSetting from '+components/GlobalFilters/Setting';
import nqlLang from '+utils/nqlLang';

import { excludeMetrics } from '../../utils';
import SourcesLineChart from '../SourcesLineChart';
import MessageDetailsModal from './MessageDetailsModal';
import VpcStatusTable from './VpcStatusTable';

const nameFields = {
  [ContextTypes.dns]: 'datasrc',
};

const VpcDetails = () => {
  const dispatch = useDispatch();
  const { vpcid } = useParams();
  const [detailsModalOpen, toggleDetailsModal] = useToggle(false);
  const [detailsMessage, setDetailsMessage] = useState('');
  const vpc = useSelector(vpcSelectors.getVpcById(vpcid));
  const sources = useMemo(
    () => (vpc ? [vpc] : []),
    [vpc],
  );
  const isDnsEnabled = useFlag('DNS');

  const nqlQuery = useMemo(
    () => (vpc?.name
      ? nqlLang.equal(nameFields[vpc?.traffictype] || 'flowsrcname', vpc.name)
      : ''),
    [vpc],
  );

  useEffect(
    () => {
      dispatch(vpcActions.fetchById(vpcid));
    },
    [vpcid],
  );

  return !Object.keys(vpc || {}).length ? null : (
    <Fragment>
      <Breadcrumb
        title={`${isDnsEnabled ? 'Traffic' : 'Flow'} Source Details — ${
          vpc?.name || 'unknown'
        }`}
      />
      <MessageDetailsModal
        isOpen={detailsModalOpen}
        toggleModal={toggleDetailsModal}
        message={detailsMessage}
      />
      <GlobalFiltersSetting
        context={vpc.traffictype || ContextTypes.flow}
        dateTimeMode={DateTimeModes.now}
        excludeMetrics={excludeMetrics}
        nql
        metric
      />
      <SourcesLineChart
        sources={sources}
        nqlQuery={nqlQuery}
        context={vpc.traffictype || ContextTypes.flow}
      />
      <VpcStatusTable
        vpc={vpc}
        toggleModal={toggleDetailsModal}
        setDetailsMessage={setDetailsMessage}
      />
    </Fragment>
  );
};

export default VpcDetails;
