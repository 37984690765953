import { hex } from '@/models/ChartColors';
import { ContextTypes } from '@/models/ContextTypes';

import {
  formatBits,
  formatBitRate,
  formatNumber,
  formatRate,
} from '+utils/format';

const MetricSettings = {
  [ContextTypes.flow]: {
    avgduration: {
      charTitle: 'Average Duration',
      suffix: 'avg',
      tooltipFormatter: (v, d = 0) => formatNumber(v, d),
      yAxisFormatter: (v, d = 0) => formatNumber(v, d),
      color: hex[0],
    },
    bits: {
      chartTitle: 'Total Traffic',
      suffix: 'bits',
      tooltipFormatter: formatBits,
      yAxisFormatter: formatBits,
      color: hex[0],
    },
    bitrate: {
      chartTitle: '', // no title since the yAxisFormatter has units
      suffix: 'bps',
      tooltipFormatter: formatBitRate,
      yAxisFormatter: formatBitRate,
      color: hex[0],
    },
    card: {
      chartTitle: 'Cardinality',
      suffix: '',
      tooltipFormatter: (v, d = 0) => formatNumber(v, d),
      yAxisFormatter: (v, d = 0) => formatNumber(v, d),
      color: hex[0],
      card: true,
    },
    counts: {
      chartTitle: '',
      suffix: '',
      tooltipFormatter: (v, d = 0) => formatNumber(v, d),
      yAxisFormatter: (v, d = 0) => formatNumber(v, d),
      color: hex[0],
    },
    duration: {
      chartTitle: '',
      suffix: '',
      tooltipFormatter: (v, d = 0) => formatNumber(v, d),
      yAxisFormatter: (v, d = 0) => formatNumber(v, d),
      color: hex[0],
    },
    packets: {
      chartTitle: 'Total Packets',
      suffix: 'p',
      tooltipFormatter: (v, d = 0) => formatNumber(v, d),
      yAxisFormatter: (v, d = 0) => formatNumber(v, d),
      color: hex[3],
    },
    packetrate: {
      chartTitle: '',
      suffix: 'pps',
      tooltipFormatter: (v, d = 2, l = 'pps') => formatRate(v, d, l),
      yAxisFormatter: (v, d = 2, l = 'pps') => formatRate(v, d, l),
      color: hex[3],
    },
    flows: {
      chartTitle: 'Total Flows',
      suffix: 'f',
      tooltipFormatter: (v, d = 0) => formatNumber(v, d),
      yAxisFormatter: (v, d = 0) => formatNumber(v, d),
      color: hex[1],
    },
    flowrate: {
      chartTitle: '',
      suffix: 'fps',
      tooltipFormatter: (v, d = 2, l = 'fps') => formatRate(v, d, l),
      yAxisFormatter: (v, d = 2, l = 'fps') => formatRate(v, d, l),
      color: hex[1],
    },
  },
  [ContextTypes.dns]: {
    answers: {
      chartTitle: 'Total Answers',
      suffix: '',
      tooltipFormatter: (v, d = 0) => formatNumber(v, d),
      yAxisFormatter: (v, d = 0) => formatNumber(v, d),
      color: hex[1],
    },
    avganswers: {
      chartTitle: '',
      suffix: 'avg',
      tooltipFormatter: (v, d = 2) => formatNumber(v, d),
      yAxisFormatter: (v, d = 2) => formatNumber(v, d),
      color: hex[1],
    },
    avgdepth: {
      chartTitle: '',
      suffix: 'avg',
      tooltipFormatter: (v, d = 2) => formatNumber(v, d),
      yAxisFormatter: (v, d = 2) => formatNumber(v, d),
      color: hex[1],
    },
    card: {
      chartTitle: 'Cardinality',
      suffix: '',
      tooltipFormatter: (v, d = 0) => formatNumber(v, d),
      yAxisFormatter: (v, d = 0) => formatNumber(v, d),
      color: hex[0],
      card: true,
    },
    queries: {
      chartTitle: 'Total Queries',
      suffix: 'q',
      tooltipFormatter: (v, d = 0) => formatNumber(v, d),
      yAxisFormatter: (v, d = 0) => formatNumber(v, d),
      color: hex[0],
    },
    queryrate: {
      chartTitle: '',
      suffix: '',
      tooltipFormatter: (v, d = 2, l = 'qps') => formatRate(v, d, l),
      yAxisFormatter: (v, d = 2, l = 'qps') => formatRate(v, d, l),
      color: hex[1],
    },
  },
  [ContextTypes.alerts]: {
    alerts: {
      chartTitle: '',
      suffix: '',
      tooltipFormatter: (v, d = 0) => formatNumber(v, d),
      yAxisFormatter: (v, d = 0) => formatNumber(v, d),
      color: hex[4],
    },
    alertrate: {
      chartTitle: '',
      suffix: '',
      tooltipFormatter: (v, d = 2) => formatNumber(v, d),
      yAxisFormatter: (v, d = 2) => formatNumber(v, d),
      color: hex[3],
    },
    card: {
      chartTitle: 'Cardinality',
      suffix: '',
      tooltipFormatter: (v, d = 0) => formatNumber(v, d),
      yAxisFormatter: (v, d = 0) => formatNumber(v, d),
      color: hex[0],
      card: true,
    },
    counts: {
      chartTitle: '',
      suffix: '',
      tooltipFormatter: (v, d = 0) => formatNumber(v, d),
      yAxisFormatter: (v, d = 0) => formatNumber(v, d),
      color: hex[0],
    },
  },
  [ContextTypes.audit]: {
    audits: {
      chartTitle: '',
      suffix: '',
      tooltipFormatter: (v, d = 0) => formatNumber(v, d),
      yAxisFormatter: (v, d = 0) => formatNumber(v, d),
      color: hex[4],
    },
    auditrate: {
      chartTitle: '',
      suffix: '',
      tooltipFormatter: (v, d = 2) => formatNumber(v, d),
      yAxisFormatter: (v, d = 2) => formatNumber(v, d),
      color: hex[3],
    },
    counts: {
      chartTitle: '',
      suffix: '',
      tooltipFormatter: (v, d = 0) => formatNumber(v, d),
      yAxisFormatter: (v, d = 0) => formatNumber(v, d),
      color: hex[0],
    },
  },
  [ContextTypes.blocks]: {
    blocks: {
      chartTitle: '',
      suffix: '',
      tooltipFormatter: (v, d = 0) => formatNumber(v, d),
      yAxisFormatter: (v, d = 0) => formatNumber(v, d),
      color: hex[4],
    },
    blockrate: {
      chartTitle: '',
      suffix: 'bps',
      tooltipFormatter: (v, d = 2, l = 'bps') => formatRate(v, d, l),
      yAxisFormatter: (v, d = 2, l = 'bps') => formatRate(v, d, l),
      color: hex[3],
    },
    card: {
      chartTitle: 'Cardinality',
      suffix: '',
      tooltipFormatter: (v, d = 0) => formatNumber(v, d),
      yAxisFormatter: (v, d = 0) => formatNumber(v, d),
      color: hex[0],
      card: true,
    },
    counts: {
      chartTitle: '',
      suffix: '',
      tooltipFormatter: (v, d = 0) => formatNumber(v, d),
      yAxisFormatter: (v, d = 0) => formatNumber(v, d),
      color: hex[0],
    },
  },
};

Object.keys(MetricSettings).forEach((context) => {
  Object.keys(MetricSettings[context]).forEach((key) => {
    MetricSettings[context][key].key = key;
    MetricSettings[context][key].context = context;
  });
});

const EventMetricSettings = MetricSettings[ContextTypes.alerts];
const AuditMetricSettings = MetricSettings[ContextTypes.audit];
const BlockMetricSettings = MetricSettings[ContextTypes.blocks];
const FlowMetricSettings = MetricSettings[ContextTypes.flow];

const DNSMetricSettings = MetricSettings[ContextTypes.dns];

const findMetricSettings = ({ context, metric }) => {
  if (context && MetricSettings[context]) {
    return MetricSettings[context][metric];
  }
  return (
    FlowMetricSettings[metric]
    || EventMetricSettings[metric]
    || BlockMetricSettings[metric]
    || AuditMetricSettings[metric]
    || DNSMetricSettings[metric]
  );
};

const isCardMetric = (metric) => (metric || '').includes('card');

export {
  MetricSettings,
  EventMetricSettings,
  AuditMetricSettings,
  BlockMetricSettings,
  FlowMetricSettings,
  DNSMetricSettings,
  findMetricSettings,
  isCardMetric,
};

export default MetricSettings;
