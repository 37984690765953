import { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import omit from 'lodash.omit';

import { ContextTypes } from '@/models/ContextTypes';

import {
  actions as globalFiltersActions,
  selectors as globalFiltersSelectors,
  defaultState,
} from '@/redux/globalFilters';

import getIntersectFieldName from '+utils/getIntersectFieldName';
import getMetricFieldName from '+utils/getMetricFieldName';
import getNqlFieldName from '+utils/getNqlFieldName';

const omitProps = [];
// Object.values(ContextTypes).forEach((context) => {
//   const metricFieldName = getMetricFieldName(context);
//   const nqlFieldName = getNqlFieldName(context);
//   const intersectFieldName = getIntersectFieldName(context);
//   omitProps.push(metricFieldName);
//   omitProps.push(nqlFieldName);
//   omitProps.push(intersectFieldName);
// });

/**
 * Hook to use return global filter state.
 *
 * @param {ContextTypes} [context]
 * @return {Object} filters
 */
export const useGlobalFilters = (context) => {
  const dispatch = useDispatch();
  const filters = useSelector(globalFiltersSelectors.getFilters);

  const filtersByContext = useMemo(
    () => {
      let fixedContext = context || filters.context;

      if (!ContextTypes[fixedContext]) {
      // eslint-disable-next-line no-console
        console.warn(`Unknown context type: ${fixedContext}`);
        fixedContext = ContextTypes.flow;
      }

      const metricFieldName = getMetricFieldName(fixedContext);
      const nqlFieldName = getNqlFieldName(fixedContext);
      const intersectFieldName = getIntersectFieldName(fixedContext);

      const { filters: defaultFilters } = defaultState;
      const metricDef = defaultFilters[metricFieldName];
      const nqlDef = defaultFilters[nqlFieldName] || defaultFilters.nql;
      const intersectDef = defaultFilters[intersectFieldName] || defaultFilters.intersect;

      const {
        [metricFieldName]: metric = metricDef,
        [nqlFieldName]: nql = nqlDef,
        [intersectFieldName]: intersect = intersectDef,
      } = filters;

      return omit(
        {
          ...filters,
          context: fixedContext,
          metric,
          nql,
          intersect,
        },
        omitProps,
      );
    },
    [filters, context],
  );

  const change = useCallback(
    (filterPropValue) => {
      dispatch(globalFiltersActions.changeFilter(filterPropValue));
    },
    [],
  );

  return [filtersByContext, change];
};

export default useGlobalFilters;
