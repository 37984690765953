import { countries } from '@/models/countries';

import { Point } from './Point';

export const getCountryFlagByName = (name) => {
  return name.toLowerCase();
};

export class Source {
  constructor({ lat, lng, country, land }) {
    lat = lat || 0;
    lng = lng || 0;

    this.country = country || 'Unknown';
    this.countryName = countries[this.country.toUpperCase()] || 'Unknown';
    this.flag = getCountryFlagByName(this.country);

    this._numberOfUses = 0;
    this._links = 0;

    this.point = new Point({ lat, lng, land });
  }

  get count() {
    return this._numberOfUses;
  }

  incUses() {
    this._numberOfUses += 1;
    this._links += 1;
  }

  unlink() {
    this._links -= 1;
  }

  isDead() {
    return this._links < 1;
  }

  get lat() {
    return this.point.lat;
  }

  get lng() {
    return this.point.lng;
  }

  get x() {
    return this.point.x;
  }

  get y() {
    return this.point.y;
  }
}
