import styled from 'styled-components';

import TrafficLightOutlineIcon from 'mdi-react/TrafficLightOutlineIcon';

import { IntegrationIcon } from './IntegrationIcon';
import { TdmIcon } from './TdmIcon';

const colors = {
  tdIconBgColor: '#9C88FF',
  trafficIconBgColor: '#FECA57',
  integrationIconBgColor: '#7EFFF5',
};

const GradientBackground = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  height: 100%;
  min-width: 40px;
  background: ${(props) => props.$bgColor};
  margin-right: 20px;
  svg {
    align-self: start;
    height: 30px;
    width: 30px;
    margin: 7px 0;
  }
  border-radius: 2px;
`;

const TdmGradientIcon = (
  <GradientBackground $bgColor={colors.tdIconBgColor}>
    <TdmIcon color="black" />
  </GradientBackground>
);

const TrafficLightGradientIcon = (
  <GradientBackground $bgColor={colors.trafficIconBgColor}>
    <TrafficLightOutlineIcon color="black" />
  </GradientBackground>
);

const IntegrationGradientIcon = (
  <GradientBackground $bgColor={colors.integrationIconBgColor}>
    <IntegrationIcon color="black" />
  </GradientBackground>
);

export { TdmGradientIcon, TrafficLightGradientIcon, IntegrationGradientIcon };
