import PropTypes from '+prop-types';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';

import { selectors as customerSelectors } from '@/redux/api/customer';

import ButtonGroupField from '+components/form/ButtonGroupField';
import FieldsSection from '+components/form/FieldsSection';
import { Field, useFormState } from '+components/form/FinalForm';
import { normalizeSelectValue } from '+components/form/Normalizers';
import SelectField from '+components/form/SelectField';
import { SliderField } from '+components/form/Slider';
import { validateRequired } from '+components/form/Validators';

import {
  formatTimeSliderLabel,
  getSliderMarks,
} from '../../shared/sliderUtils';
import { cadenceOptions, strategyOptions } from '../../shared/utils';

const ThresholdToggleOptions = [
  { value: false, label: 'Disabled' },
  { value: true, label: 'Enabled' },
];

const AutoThresholdsSection = ({ canManage }) => {
  const { values } = useFormState({
    subscription: { values: true },
  });

  const { autoThresholdEnabled = false } = values || {};
  const customer = useSelector(customerSelectors.getCurrentCustomer);

  return (
    <Fragment>
      <Field
        name="autoThresholdEnabled"
        label="Auto Thresholding"
        options={ThresholdToggleOptions}
        component={ButtonGroupField}
        disabled={!canManage}
      />

      {autoThresholdEnabled && (
        <FieldsSection label="AUTO THRESHOLD OPTIONS" boldLabel formLabelMargin>
          <Field
            name="autoThresholdData.strategy"
            label="Strategy"
            component={SelectField}
            options={Object.values(strategyOptions)}
            validate={validateRequired}
            parse={normalizeSelectValue}
            disabled={!canManage}
            style={{ maxWidth: '200px' }}
            required
          />
          <Field
            name="autoThresholdData.data_interval"
            label="Cadence"
            component={SelectField}
            options={Object.values(cadenceOptions)}
            validate={validateRequired}
            parse={normalizeSelectValue}
            disabled={!canManage}
            style={{ maxWidth: '200px' }}
            required
          />
          <Field
            name="autoThresholdData.data_window"
            label="Learning Window"
            component={SliderField}
            min={1}
            max={24}
            isRangeSlider={false}
            showInputField
            marks={getSliderMarks(5, 4)}
            disabled={!canManage}
            helperText="Auto threshold learning window duration"
            inputHelperText="Hours"
            valueLabelFormat={(value) => formatTimeSliderLabel(value * 3600)}
            required
          />
          <Field
            name="autoThresholdData.data_lookback"
            label="Lookback"
            component={SliderField}
            min={7}
            max={180}
            isRangeSlider={false}
            showInputField
            marks={getSliderMarks(5, 30)}
            disabled={!canManage}
            helperText={`The maximum age of data used to train thresholds, recommended value is your retention period: (${customer.retention}) days`}
            inputHelperText="Days"
            valueLabelFormat={(value) => `${value}d`}
            required
          />
        </FieldsSection>
      )}
    </Fragment>
  );
};

AutoThresholdsSection.propTypes = {
  canManage: PropTypes.bool,
};

AutoThresholdsSection.defaultProps = {
  canManage: false,
};

export default AutoThresholdsSection;
