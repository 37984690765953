import PropTypes from '+prop-types';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import styled from 'styled-components';

import PermissionModel from '@/models/Permission';

import { actions } from '@/redux/api/dnsclass';

import Button from '+components/Button';
import { Field, FinalForm } from '+components/form/FinalForm';
import MultiSelectField from '+components/form/MultiSelectField';
import { normalizeMultiSelectValue } from '+components/form/Normalizers';
import { validateDomains } from '+components/form/Validators';
import { ActionsContainer } from '+components/FormWizard';
import { Col, Row } from '+components/Layout';
import usePageTabsAndFormSync from '+hooks/usePageTabsAndFormSync';
import usePermissions from '+hooks/usePermissions';

const Form = styled.form`
  width: 80%;
  background-color: transparent;
  margin-bottom: 40px;
`;

const FormBody = (props) => {
  const { initialValues, handleSubmit, isFetching } = props;

  usePageTabsAndFormSync();

  const permissions = usePermissions(
    PermissionModel.Resources.network_classification.value,
  );

  const [domainsOptions] = useState(initialValues?.domains ?? []);

  const canManage = !isFetching && permissions?.update;

  return (
    <Form
      className="form form--horizontal wizard__form"
      onSubmit={handleSubmit}
    >
      <div className="card__title" style={{ marginLeft: '140px' }}>
        <h5 className="head">Internal Domains</h5>
        <h5 className="subhead">
          When matched, the dns query will be flagged as internal.
        </h5>
      </div>

      <Row>
        <Col lg={7} item>
          <Field
            name="domains"
            component={MultiSelectField}
            options={domainsOptions}
            parse={normalizeMultiSelectValue}
            validate={validateDomains}
            allowCreate
            disabled={!canManage}
            label="Private Domains"
            helperText="List of interal domains."
          />
        </Col>
      </Row>

      <ActionsContainer>
        <Button type="submit" disabled={!canManage}>
          Update
        </Button>
      </ActionsContainer>
    </Form>
  );
};

FormBody.propTypes = {
  initialValues: PropTypes.shape().isRequired,
  isFetching: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const DnsClassForm = (props) => {
  const { initialValues, isFetching } = props;

  const dispatch = useDispatch();

  const onSubmit = useCallback(
    (values) => {
      const params = {
        id: values.id,
        domains: values.domains,
      };

      dispatch(actions.set(params));
    },
    [],
  );

  const fixedInitialValues = useMemo(
    () => initialValues || {},
    [initialValues],
  );

  return (
    <FinalForm
      onSubmit={onSubmit}
      initialValues={fixedInitialValues}
      component={FormBody}
      isFetching={isFetching}
    />
  );
};

DnsClassForm.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape(),
};

DnsClassForm.defaultProps = {
  initialValues: null,
};

export default DnsClassForm;
