import { SelectColumnFilter } from '+components/Table/Filters';
import sortByHelper from '+utils/sortByHelper';

export const sortSystemFirst = (a, b) => {
  if (a?.system > b?.system) {
    return -1;
  }

  if (a?.system !== b?.system) {
    return 1;
  }

  return (a?.label || '')
    .toLowerCase()
    .localeCompare((b?.label || '').toLowerCase());
};

const defaultGetName = (row, id) => row?.values?.[id] || [];

const getMergedAlertTypes = (type) => {
  if (type === 'start') {
    return 1;
  }
  if (type === 'ongoing') {
    return 2;
  }
  return 3;
};

/**
 *
 * @param {string} field - field name
 * @param {object} [params] - params object
 * @param {object} [params.categories] - categories object
 * @param {object} [params.algorithms] - algorithms object
 * @param {function (object, string): string|string[]} [params.getCategoriesName] - function to get category name(s)
 * @param {function (object, string): string|string[]} [params.getAlgorithmsName] - function to get algorithm name(s)
 * @returns {{filter: function|string, Filter: {function(*): JSX.Element}, sortType?: function|string }}
 */
export const getEventTableFilterValues = (field, params) => {
  const { categories, algorithms, getAlgorithmsName, getCategoriesName } = params || {};
  switch (field) {
    case 'alerttype':
      return {
        Filter: SelectColumnFilter({
          fixedOptions: ['all', 'start', 'ongoing', 'end'],
        }),
        filter: 'selectFilter',
        sortType: sortByHelper(getMergedAlertTypes),
      };
    case 'categories':
      return {
        Filter: SelectColumnFilter({
          optionValueExtractor: (row, id) => {
            let rowCategoryValues = (getCategoriesName || defaultGetName)(
              row,
              id,
            );
            if (!Array.isArray(rowCategoryValues)) {
              rowCategoryValues = [rowCategoryValues];
            }

            const options = rowCategoryValues.reduce((acc, name) => {
              if (acc[name]) {
                return acc;
              }

              const category = categories?.[name] || {};

              return {
                ...acc,
                [name]: {
                  value: name,
                  label: name,
                  description: category?.description,
                  system: category?.systemdefault,
                },
              };
            }, {});
            return Object.values(options);
          },
          selectProps: {
            groupBy: (option) => {
              if (option.value === 'all') {
                return '';
              }
              return option.system ? 'System Categories' : 'Custom Categories';
            },
          },
          sort: sortSystemFirst,
        }),
        filter: 'selectFilter',
      };
    case 'algorithm':
    case 'algorithms':
      return {
        Filter: SelectColumnFilter({
          optionValueExtractor: (row, id) => {
            let names = (getAlgorithmsName || defaultGetName)(row, id);
            if (!Array.isArray(names)) {
              names = [names];
            }

            const options = names.reduce((acc, name) => {
              if (acc[name]) {
                return acc;
              }

              const algorithm = algorithms?.[name] || {};

              return {
                ...acc,
                [name]: {
                  value: name,
                  label: name,
                  description: algorithm.description,
                  system: algorithm.systemdefault,
                },
              };
            }, {});

            return Object.values(options);
          },
          selectProps: {
            groupBy: (option) => {
              if (option.value === 'all') {
                return '';
              }
              return option.system ? 'System Algorithms' : 'Custom Algorithms';
            },
          },
          sort: sortSystemFirst,
        }),
        filter: 'selectFilter',
      };
    case 'severity':
      return {
        Filter: SelectColumnFilter(),
        filter: 'selectFilter',
        sortType: (rowA, rowB, id) => {
          const { values: { [id]: a } } = rowA;
          const { values: { [id]: b } } = rowB;

          switch (true) {
            case a === b:
              return 0;
            case a === 'high' && b === 'medium':
            case a === 'high' && b === 'low':
            case a === 'medium' && b === 'low':
              return 1;
            case a === 'medium' && b === 'high':
            default:
              return -1;
          }
        },
      };
    default:
      return {};
  }
};

export default getEventTableFilterValues;
