import { useMemo } from 'react';

import useSearchMeta from '+hooks/useSearchMeta';

export const useTrackByOptions = (context, { addGlobal, onlyFields }) => {
  const { fields } = useSearchMeta({ context, fields: true });

  return useMemo(
    () => {
      const result = (fields || [])
        .filter(({ type }) => type !== 'Object')
        .map(({ field, description }) => (onlyFields
          ? field
          : {
            value: field,
            label: field,
            description,
          }));

      if (addGlobal) {
        result.unshift(
          onlyFields
            ? 'global'
            : {
              value: 'global',
              label: 'global',
            },
        );
      }

      return result;
    },
    [fields],
  );
};
