/* eslint-disable max-len */
import PropTypes from '+prop-types';
import { memo } from 'react';

const WizIcon = memo(({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 42 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 0h42v28H0z" />
    <path
      d="M12.082 13.007c.027.058.11.056.133-.004l2.129-5.55a.686.686 0 0 1 .637-.44l1.985-.011a.686.686 0 0 1 .648.924l-4.801 12.962c-.008.02-.166.158-.266.235-.049.037-.108.042-.168.025a.669.669 0 0 1-.434-.387l-2.562-6.175a.363.363 0 0 0-.655-.013l-3.212 6.425a.073.073 0 0 1-.133-.006L.359 7.929a.686.686 0 0 1 .64-.933h2.157c.284 0 .54.176.64.441l2.128 5.565c.023.06.108.063.134.005L8.74 7.169a.363.363 0 0 1 .66 0l2.682 5.838ZM36.516 1.746c-.016-.078-.183-.078-.2 0-.124.608-.383 1.525-.87 2.012-.487.487-1.404.746-2.012.87-.078.017-.078.184 0 .2.608.125 1.525.384 2.012.87.636.637.855 1.506.93 2.028.006.033.11.038.116.005.114-.56.374-1.512.895-2.032.487-.487 1.404-.746 2.012-.871.078-.016.078-.183 0-.2-.608-.124-1.525-.383-2.012-.87-.487-.487-.746-1.404-.871-2.012ZM18.407 7.76c0-.38.307-.687.686-.687h2.074c.38 0 .687.307.687.686v12.7c0 .379-.308.686-.687.686h-2.074a.686.686 0 0 1-.686-.686v-12.7ZM27.842 10.705a.181.181 0 0 0-.162-.262H23.58a.686.686 0 0 1-.686-.687V7.76c0-.379.307-.686.686-.686h9.456c.294 0 .688.239.548.497L28.12 17.793l-.001.002a.182.182 0 0 0 .16.266h4.995c.379 0 .686.308.686.687v1.71c0 .38-.307.687-.686.687h-10.03c-.33 0-.396-.48-.248-.776l4.846-9.664Z"
      fill="currentColor"
    />
  </svg>
));

WizIcon.propTypes = {
  size: PropTypes.number,
};

WizIcon.defaultProps = {
  size: 24,
};

const WizBoxIcon = memo(({ size }) => (
  <svg
    width={size}
    height={size}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1753.000000 1754.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,1754.000000) scale(0.100000,-0.100000)"
      fill="#0254ec"
      stroke="none"
    >
      <rect
        x="10"
        y="10"
        width="17520"
        height="17530"
        fill="currentColor"
        stroke="none"
      />
      <path
        d={`
M0 8765 l0 -8765 8765 0 8765 0 0 8765 0 8765 -8765 0 -8765 0 0
-8765z m15235 4207 c6 -4 26 -63 44 -132 68 -254 141 -416 229 -511 93 -101
280 -182 620 -269 36 -9 43 -15 40 -33 -3 -18 -23 -27 -148 -62 -265 -75 -420
-146 -505 -230 -87 -87 -167 -267 -238 -530 -30 -111 -34 -120 -56 -120 -22 0
-26 9 -58 129 -68 260 -157 451 -245 531 -90 80 -276 162 -523 228 -99 27
-120 36 -120 51 0 25 7 27 165 71 266 72 453 166 523 263 72 99 166 330 212
522 22 93 34 111 60 92z m-9995 -1827 c18 -9 40 -35 54 -63 13 -26 232 -501
486 -1057 255 -555 468 -1013 474 -1017 6 -4 14 -4 19 1 4 4 188 480 408 1057
258 675 408 1055 421 1067 19 16 63 17 618 17 l599 0 20 -26 c12 -15 21 -36
21 -47 0 -26 -1868 -4888 -1885 -4906 -7 -7 -20 -11 -29 -8 -9 4 -277 528
-596 1166 -519 1038 -583 1162 -611 1177 -42 21 -95 20 -128 -3 -19 -13 -187
-340 -607 -1178 -514 -1025 -584 -1160 -604 -1160 -21 0 -97 192 -957 2430
-514 1337 -937 2448 -940 2471 -4 34 -1 45 18 63 23 21 23 21 619 21 l597 0
20 -22 c11 -13 197 -486 413 -1053 215 -566 399 -1041 407 -1054 10 -16 18
-20 25 -13 6 6 226 480 489 1054 365 795 486 1050 509 1071 35 31 93 36 140
12z m4699 -21 l21 -27 0 -2445 0 -2446 -22 -23 -21 -23 -571 0 -570 0 -23 22
-23 21 0 2451 0 2450 22 23 21 23 573 0 573 0 20 -26z m4451 4 c14 -15 20 -32
18 -48 -3 -14 -466 -859 -1031 -1878 -564 -1019 -1029 -1861 -1032 -1871 -3
-10 4 -29 16 -44 l20 -27 1008 0 c1005 0 1008 0 1035 -21 l26 -20 0 -509 0
-509 -26 -20 -27 -21 -1974 0 -1974 0 -24 25 c-14 13 -25 31 -25 40 0 9 416
833 925 1832 509 998 925 1824 925 1835 0 12 -7 30 -17 40 -15 17 -56 18 -898
18 -877 0 -882 0 -909 21 l-26 20 0 560 c0 546 0 559 20 579 20 20 33 20 1985
20 l1964 0 21 -22z
        `}
      />
    </g>
  </svg>
));

WizBoxIcon.propTypes = {
  size: PropTypes.number,
};

WizBoxIcon.defaultProps = {
  size: 24,
};

export { WizBoxIcon, WizIcon };
