import styled from 'styled-components';

import NqlTextField from '+components/form/NqlTextField';
import { propsSelectors as globalFiltersTheme } from '+theme/slices/globalFilters';

const AdditionalNqlFilterLabel = styled.span`
  position: absolute;
  top: 1px;
  left: 10px;

  display: flex;
  align-items: center;
  font-size: 11px;
  font-weight: 600;
  white-space: nowrap;
  user-select: none;
  max-height: 14px;
  color: ${({ theme }) => theme.colorTextSecondary} !important;

  z-index: 2;
`;

const AdditionalNqlFilter = styled(NqlTextField)`
  --height: 32px;

  .form__form-group-error {
    display: none;
  }

  .nql-main-container {
    flex-direction: unset;
    border: none;
    border-radius: 0;
    background: transparent !important;
    min-height: var(--height) !important;
    max-height: var(--height);
  }

  .nql-dropdown-btn-container {
    width: 0;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    padding: 0 !important;
    margin: 0 !important;
  }

  .nql-code-block {
    padding-top: 12px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    background: transparent !important;
    min-height: var(--height) !important;
    line-height: unset !important;
    * {
      background: transparent !important;
    }
  }

  .nql-textarea {
    --buttons-width: 0px;
    padding-top: 12px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    background: transparent !important;
    min-height: var(--height) !important;
    line-height: unset !important;
  }

  &.active:not(.disabled),
  :focus:not(.disabled),
  :hover:not(.disabled),
  :active:not(.disabled),
  :focus-within:not(.disabled) {
    background: ${globalFiltersTheme.additionalRowBackground};
    height: fit-content;
    max-height: fit-content;
    overflow: visible;
    border: 1px solid ${({ theme }) => theme.colorBackgroundSeparator};
    margin-top: -1px;
    margin-left: -2px;
    //padding-top: 2px;
    padding-left: 1px;
    width: calc(100% + 4px) !important;
    z-index: 1;

    .nql-main-container {
      max-height: unset;
    }

    .form__form-group-error {
      display: block;
      margin-left: 10px;
    }

    &.invalid {
      box-shadow: 0 0 0 1px ${({ theme }) => theme.Palette.danger};
    }
  }

  &.invalid {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.Palette.danger};
    margin-left: -1px;
    padding-left: 1px;
    width: calc(100% + 2px) !important;
  }

  &.disabled {
    //cursor: not-allowed;
    background: transparent;
    box-shadow: none;
    opacity: 0.5;
    * {
      user-select: none !important;
      pointer-events: none !important;
    }
  }
`;

export { AdditionalNqlFilterLabel };
export default AdditionalNqlFilter;
