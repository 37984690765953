import PropTypes from '+prop-types';
import { useState, useCallback, useEffect } from 'react';
import { useDebounce } from 'react-use';

import isEqual from 'lodash.isequal';

import MagnifyIcon from 'mdi-react/MagnifyIcon';

import { FilterOperator, createFilterValue } from '../FilterTypeFactories';
import { Container, Row, Input } from './Components';

export const DefaultColumnFilter = ({ column }) => {
  const { filterValue, setFilter } = column;

  const [localValue, setLocalValue] = useState({
    value: undefined,
    operator: undefined,
  });

  const onChange = useCallback(
    (nextOperator) => (event) => {
      const nextValue = event?.target?.value;
      setLocalValue({
        value: nextValue,
        operator:
          nextValue == null || nextValue === '' ? undefined : nextOperator,
      });
    },
    [],
  );

  useEffect(
    () => {
      setLocalValue((prevValue) => {
        const nextValue = {
          value: filterValue?.value ?? undefined,
          operator: filterValue?.operator ?? undefined,
        };
        return isEqual(prevValue, nextValue) ? prevValue : nextValue;
      });
    },
    [filterValue],
  );

  useDebounce(
    () => {
      if (localValue?.value == null || localValue?.value === '') {
        setFilter(undefined);
        return;
      }
      setFilter(createFilterValue(localValue));
    },
    300,
    [localValue],
  );

  return (
    <Container>
      <Row>
        <MagnifyIcon size={24} />
        <Input
          type="text"
          value={
            localValue.operator === FilterOperator.like
              ? localValue.value ?? ''
              : ''
          }
          onChange={onChange(FilterOperator.like)}
        />
      </Row>
    </Container>
  );
};

DefaultColumnFilter.propTypes = {
  column: PropTypes.shape().isRequired,
};
