import PropTypes from '+prop-types';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import upperFirst from 'lodash.upperfirst';
import styled from 'styled-components';

import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon';

import { ColorTypes } from '@/models/ColorTypes';

import {
  actions as contextIntegrationsActions,
  selectors as contextIntegrationsSelectors,
} from '@/redux/api/integrations/context';

import Button, { ButtonVariants } from '+components/Button';
import ButtonGroup from '+components/ButtonGroup';
import Title from '+components/FormWizard/components/Title';
import IconButtonOrigin from '+components/IconButton';
import ContextNameLabel from '+components/Labels/ContextNameLabel';
import { Col, Row } from '+components/Layout';
import Modal, {
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
} from '+components/Modal';
import TableOrigin from '+components/Table';
import { CellIp } from '+components/Table/Cells';
import { Container as TContainer } from '+components/Table/ReactTable/components/Components';

import {
  IpColumns,
  getIpColumns,
  ContextColumns,
  getContextColumns,
  getLabelIpColumns,
  LabelIpColumns,
  getLabelContextColumns,
  LabelContextColumns,
} from './IntegrationTestColumns';

const defaultTitleTemplate = (action, item) => (
  <Fragment>
    {upperFirst(action || '')} {item}
  </Fragment>
);

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${TContainer} {
    flex: 1 1 0;
    min-height: 400px;
  }
`;

const ToggleContainer = styled.div`
  display: flex;
  justify-content: center;
  //margin-left: auto;
`;

const Table = styled(TableOrigin)`
  .col-menu button {
    display: none;
  }
`;

const GoBackContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const ContextSpacer = styled.span`
  margin-left: 5px;
`;

const IconButton = styled(IconButtonOrigin)`
  margin-right: 5px;
`;

const ViewTypes = {
  ip: 'ip',
  context: 'context',
};

const Summary = styled.div`
  margin-bottom: 16px;
`;

const dataForIpView = (data) => {
  const group = {};

  Object.values(data).forEach((item) => {
    let groupIp = group[item.ip];
    if (!groupIp) {
      groupIp = {
        ip: item.ip,
        contexts: [],
        labels: [],
        count: 0,
      };
      group[item.ip] = groupIp;
    }
    groupIp.contexts.push({ context: item.context });
    groupIp.labels.push({
      labels: item.labels,
      context: item.context,
      ip: item.ip,
    });
    groupIp.count += item.labels.length;
  });

  return Object.values(group);
};

const dataForContextView = (data) => {
  const group = {};

  Object.values(data).forEach((item) => {
    let groupContext = group[item.context];
    if (!groupContext) {
      groupContext = {
        context: item.context,
        ips: [],
        count: 0,
        labels: [],
      };
      group[item.context] = groupContext;
    }
    // if any user, show user icon, else if any custom, show custom icon, else show system
    groupContext.context = item.context;
    groupContext.ips.push(item.ip);
    groupContext.count += item.labels.length;
    groupContext.labels.push({
      labels: item.labels,
      context: item.context,
      ip: item.ip,
    });
  });

  return Object.values(group);
};

const IntegrationTestModal = styled((props) => {
  const {
    className,
    titleTemplate,
    confirmButtonText,
    titleText,
    confirmButtonColor,
    confirmButtonVariant,
    isOpen,
    onConfirm,
    data,
    summary,
  } = props;

  const dispatch = useDispatch();

  const testLabels = useSelector(contextIntegrationsSelectors.getTestLabels);

  const normalizedTitle = useMemo(
    () => (typeof titleTemplate === 'function'
      ? titleTemplate(titleText || confirmButtonText)
      : titleTemplate),
    [titleTemplate, confirmButtonText, titleText],
  );

  const [viewType, setViewType] = useState(ViewTypes.context);

  const onViewTypeToggle = useCallback(
    () => {
      setViewType((prev) => (prev === ViewTypes.ip ? ViewTypes.context : ViewTypes.ip));
    },
    [],
  );

  const sortedData = useMemo(
    () => {
      switch (viewType) {
        case ViewTypes.ip:
          return dataForIpView(data);
        case ViewTypes.context:
          return dataForContextView(data);
        default:
          return [];
      }
    },
    [viewType, data],
  );

  const showLabels = (labels) => {
    dispatch(contextIntegrationsActions.setTestLabels(labels));
  };

  const columns = useMemo(
    () => {
      return viewType === ViewTypes.ip
        ? getIpColumns(Object.values(IpColumns), { showLabels })
        : getContextColumns(Object.values(ContextColumns), { showLabels });
    },
    [viewType, showLabels],
  );

  const labelColumns = useMemo(
    () => {
      return viewType === ViewTypes.ip
        ? getLabelIpColumns(Object.values(LabelIpColumns))
        : getLabelContextColumns(Object.values(LabelContextColumns));
    },
    [viewType],
  );

  const goBack = useCallback(
    () => {
      dispatch(contextIntegrationsActions.clearTestLabels());
    },
    [],
  );

  return (
    <Modal isOpen={isOpen} onClose={onConfirm} className={className}>
      <ModalHeader color={confirmButtonColor} onClose={onConfirm}>
        {normalizedTitle}
      </ModalHeader>

      <ModalBody>
        <TableContainer>
          {testLabels ? (
            <div>
              <GoBackContainer>
                <IconButton
                  onClick={() => goBack()}
                  variant={ButtonVariants.contained}
                >
                  <ArrowLeftIcon />
                </IconButton>

                <Title>Labels for {viewType}</Title>

                <ContextSpacer>
                  {viewType === ViewTypes.ip ? (
                    <CellIp value={testLabels[0].ip} clickable={false} />
                  ) : (
                    <ContextNameLabel>{testLabels[0].context}</ContextNameLabel>
                  )}
                </ContextSpacer>
              </GoBackContainer>
              <Table
                pageSize={10}
                id="labelPreviewTable"
                columns={labelColumns}
                data={testLabels}
                autoHeight={false}
                virtualization
              />
            </div>
          ) : (
            <div>
              {summary && <Summary>{summary}</Summary>}
              <Table
                pageSize={10}
                id="ipPreviewTable"
                columns={columns}
                data={sortedData}
                autoHeight={false}
                virtualization
              />
            </div>
          )}
        </TableContainer>
      </ModalBody>

      <ModalFooter>
        {confirmButtonText && (
          <Button
            color={confirmButtonColor}
            variant={confirmButtonVariant}
            type="button"
            onClick={onConfirm}
          >
            {confirmButtonText}
          </Button>
        )}
        {!testLabels && (
          <ToggleContainer>
            <Row
              gap="10px"
              wrap="nowrap"
              alignItems="center"
              width="fit-content"
            >
              <Col container={false} whiteSpace="nowrap">
                Arrange by:
              </Col>
              <Col>
                <ButtonGroup>
                  <Button
                    variant={
                      viewType === ViewTypes.ip
                        ? ButtonVariants.contained
                        : ButtonVariants.outlined
                    }
                    onClick={onViewTypeToggle}
                  >
                    IP Address
                  </Button>

                  <Button
                    variant={
                      viewType === ViewTypes.context
                        ? ButtonVariants.contained
                        : ButtonVariants.outlined
                    }
                    onClick={onViewTypeToggle}
                  >
                    Context
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
          </ToggleContainer>
        )}
      </ModalFooter>
    </Modal>
  );
})`
  ${Container} {
    min-width: 500px;
    max-width: 800px;
    width: 80%;
    overflow: hidden;
  }
`;

IntegrationTestModal.propTypes = {
  className: PropTypes.string,
  titleTemplate: PropTypes.children,
  confirmButtonText: PropTypes.string,
  titleText: PropTypes.string,
  confirmButtonColor: PropTypes.oneOf(Object.values(ColorTypes)),
  confirmButtonVariant: PropTypes.oneOf(Object.values(ButtonVariants)),
  isOpen: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape()),
  summary: PropTypes.string,
};

IntegrationTestModal.defaultProps = {
  className: '',
  titleTemplate: defaultTitleTemplate,
  confirmButtonText: 'Close Preview',
  titleText: 'Preview',
  confirmButtonColor: ColorTypes.primary,
  confirmButtonVariant: ButtonVariants.contained,
  isOpen: false,
  data: [],
  summary: '',
};

export default IntegrationTestModal;
