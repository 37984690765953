export const itemHeight = 32;
export const groupHeight = 32;
export const separatorHeight = 5;
export const listPadding = 8;

const getItemSize = (item) => {
  if (item.group != null) {
    return groupHeight;
  }

  if (item.separator) {
    return separatorHeight * 2 - 1;
  }

  return itemHeight;
};

export default getItemSize;
