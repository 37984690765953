// eslint-disable-next-line no-unused-vars
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { CANCEL } from 'redux-saga';

import { config as appConfig } from '@/config';

import featureOverridesCookie from '+utils/featureOverridesCookie';
import featureSessionCookie from '+utils/featureSessionCookie';

import authClient from './authClient';

/**
 * @typedef {Object} ServerResponse
 * @property {any} data
 * @property {Object} meta
 * @property {Number} meta.timems
 * @property {Number} meta.count
 * @property {Number} meta.code
 */

/**
 * @callback AxiosMethod1
 * @param {AxiosRequestConfig} [config]
 * @return {Promise<AxiosResponse<ServerResponse>>}
 */

/**
 * @callback AxiosMethod2
 * @param {string} url
 * @param {AxiosRequestConfig} [config]
 * @return {Promise<AxiosResponse<ServerResponse>>}
 */

/**
 * @callback AxiosMethod3
 * @param {string} url
 * @param {any} [data]
 * @param {AxiosRequestConfig} [config]
 * @return {Promise<AxiosResponse<ServerResponse>>}
 */

export const unAuthMessage = 'You have been logged out automatically';

// un-authenticated function
let unAuthAction;

/*
 * backend is now returning 403s for READONLY users attempting to perform an update.
 * This is problematic, because we have not yet removed all of the UI that allows
 * users to attempt to perform updates.  Let them be, but kick a message that they
 * are unauthorized to do so
 * TL;DR: 403 - OK, but not allowed.  401 - auth issue, so auto-logout
 */
const unAuthStatuses = [401];
// const unAuthStatuses = [401, 403];

const resolve = (res) => res;
const reject = (error) => {
  let retError = error;

  if (axios.isCancel(error)) {
    // eslint-disable-next-line no-console
    console.warn(error.message);
  }

  // error of type Network Error has no response key
  if (error.response) {
    const { status, data } = error.response;

    // Logout if backend request failed auth
    if (unAuthStatuses.includes(+status) && unAuthAction) {
      unAuthAction(unAuthMessage);
    }

    // To handle custom functionality on front-end based on error data, return customError boolean from portal-backend
    // see redux/api/rules for example
    if (data?.customError) {
      retError = data;
      delete retError.customError;
    } else {
      retError = data || error;
    }
  }

  return Promise.reject(retError);
};

axios.defaults.baseURL = `${appConfig.appBackendUrlRoot}/api/v1`;

const CancelToken = () => ({
  cancel: () => null,
});

/**
 * @param {Function} httpMethod
 * @param {number} arity
 * @return {Function}
 */
const wrapHttpWithCancellation = (httpMethod, arity) => {
  return function (...args) {
    const inCancelToken = args[arity] || CancelToken();

    if (!inCancelToken) {
      return httpMethod(...args);
    }

    const url = args[0];
    const reqConfig = args[arity - 1] || {};

    const cancelToken = new axios.CancelToken((cancelFn) => {
      const { cancel } = inCancelToken;
      inCancelToken.cancel = (...args2) => {
        const cancelFeedback = {
          message: 'Request is cancelled!',
          url,
          payload: args2,
        };
        cancel(cancelFeedback);
        cancelFn(`Cancelled HTTP call to ${url}`);
      };
    });

    const request = httpMethod.apply(null, [
      ...args.slice(0, arity - 1),
      {
        ...reqConfig,
        cancelToken,
      },
    ]);
    request[CANCEL] = () => inCancelToken.cancel();

    return request;
  };
};

/**
 * Configures action for if backend request failed auth
 * @param {Function} unAuthFunction
 */
export const configureBackendClient = (unAuthFunction) => {
  unAuthAction = unAuthFunction;
};

/**
 * instantiate axios
 * @param {AxiosRequestConfig} [config] - axios config [details](https://github.com/axios/axios#request-config)
 */
const backendClient = (config = {}) => {
  if (!unAuthAction) {
    throw new Error(
      'Backed client is not configured, use configureBackendClient',
    );
  }

  const instance = axios.create(config);

  instance.interceptors.request.use((request) => {
    // We need this header to determine audit log source on portal-backend side
    request.headers['X-Neto-Source'] = 'portal';
    request.headers['X-Neto-Feature-Session'] = featureSessionCookie();
    request.headers['X-Neto-Feature-Override'] = `${featureOverridesCookie}`;

    const jwtToken = authClient.getToken();
    if (jwtToken) {
      request.headers.Authorization = `Bearer ${jwtToken}`;
    }

    const { code: guestCode } = authClient.getGuest();
    if (guestCode) {
      request.headers.GuestCode = guestCode;
    }

    return request;
  });

  // intercept un-authenticated requests, and log the user out if unauthorized.
  instance.interceptors.response.use(resolve, reject);

  // Mofidy HTTP methods to allow cancellation with a token
  const { request, get, put, post, patch, head, options } = instance;
  const del = instance.delete.bind(instance);

  return {
    request: wrapHttpWithCancellation(request, 1),
    get: wrapHttpWithCancellation(get, 2),
    delete: wrapHttpWithCancellation(del, 2),
    head: wrapHttpWithCancellation(head, 2),
    options: wrapHttpWithCancellation(options, 2),
    put: wrapHttpWithCancellation(put, 3),
    post: wrapHttpWithCancellation(post, 3),
    patch: wrapHttpWithCancellation(patch, 3),
    CancelToken,
  };
};

backendClient.CancelToken = CancelToken;

export default backendClient;
