import get from 'lodash.get';

import { BaseColumnFactory } from '+components/Table/Columns/BaseColumnFactory';
import { SelectColumnFilter } from '+components/Table/Filters';
import { someOfFieldsFilter } from '+components/Table/FilterTypeFactories';
import { autoRemoveByOperator } from '+components/Table/ReactTable/utils';
import sortByHelper from '+utils/sortByHelper';

export const OwnerAsColumnFactory = (props) => ({
  ...BaseColumnFactory(props),
  // realAccessor: props.field,
  realAccessor: [`${props.field}.number`, `${props.field}.org`],
  Filter: SelectColumnFilter({
    optionLabel: (key) => (key === 'all' ? 'All' : key),
    optionValueExtractor: (row, id) => row.values[id]?.org || null,
    enableLikeFilter: true,
  }),
  filter: someOfFieldsFilter(
    [`${props.field}.number`, `${props.field}.org`],
    autoRemoveByOperator,
  ),
  sortType: sortByHelper((_, row) => {
    const original = row?.original;
    const fieldValue = get(original, `${props.field}.org`);
    return fieldValue === 'Unknown' ? '' : fieldValue;
  }),
});
