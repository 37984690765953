import { useCallback, useEffect, useMemo, useState } from 'react';

import { Resolvers } from '+components/charts/ForceDirected';
import useGlobalFilters from '+hooks/useGlobalFilters';

const nodesResolverOptionsNoContext = [
  {
    value: Resolvers.ip.key,
    label: 'IP',
  },
  {
    value: Resolvers.ipPort.key,
    label: 'IP -> Port',
  },
];

const nodesResolverOptionsWithContext = [
  {
    value: Resolvers.ipLabels.key,
    label: 'IP Labels',
  },
  ...nodesResolverOptionsNoContext.map((item) => ({
    ...item,
    disabled: true,
  })),
];

const getIpLabelsResolvers = (context) => ({
  nodes: Resolvers.ipLabels.nodes(context),
  particles: Resolvers.ipLabels.particles(context),
});

const getResolverOptions = (labelsAreShown) => (labelsAreShown
  ? nodesResolverOptionsWithContext
  : nodesResolverOptionsNoContext);

export const useIpExplorerResolver = () => {
  const [{ labelContext }] = useGlobalFilters();

  const [nodesResolver, setNodesResolver] = useState(
    getResolverOptions(labelContext.show)[0],
  );

  const resolvers = useMemo(
    () => ({
      ...Resolvers,
      ...(labelContext.ip && {
        [Resolvers.ipLabels.key]: {
          ...Resolvers.ipLabels,
          ...getIpLabelsResolvers(labelContext.ip),
        },
      }),
    }),
    [labelContext.ip],
  );

  const nodesFunction = useCallback(
    (...args) => resolvers[nodesResolver.value]?.nodes?.(...args),
    [resolvers, nodesResolver?.value],
  );

  const particlesFunction = useCallback(
    (...args) => resolvers[nodesResolver.value]?.particles?.(...args),
    [resolvers, nodesResolver?.value],
  );

  useEffect(
    () => {
      setNodesResolver(getResolverOptions(labelContext.show)[0]);
    },
    [labelContext.show],
  );

  return {
    nodesResolver,
    setNodesResolver,
    nodesResolverOptions: getResolverOptions(labelContext.show),
    nodesFunction,
    particlesFunction,
  };
};

export default useIpExplorerResolver;
