import 'react';
import styled from 'styled-components';

import FontMixin from './FontMixin';

/**
 * Button that looks like a link. For accessibility reasons use when you need
 * something that looks like a link but doesn't perform navigation.
 */
export default styled.button`
  ${FontMixin};

  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  transition: all 0.3s;
  color: ${({ theme: { Palette } }) => Palette.blue};
  text-align: left;

  &:hover {
    color: ${({ theme: { Palette } }) => Palette.blueHover};
  }
`;
