import styled from 'styled-components';

export default styled.div`
  display: flex;
  align-items: center;

  flex: 0 0 ${(props) => props.$width};
  white-space: nowrap;
  margin-right: 10px;

  font-weight: ${(props) => (props.$bold ? 'bold' : 'normal')};
`;
