import { useMemo } from 'react';

import { exportingButtons, exportingLoadEvent, lang } from './utils';

/**
 * @param {Object} props
 * @param {boolean} [props.exporting=false]
 * @param {boolean|Function} [props.onPushToGF=false]
 * @returns {Object}
 */
const useDefaultPropsHSX = (props) => {
  const { exporting = false, onPushToGF = false } = props || {};

  return useMemo(
    () => ({
      chart: {
        reflow: false,
        animation: false,
        zooming: {
          mouseWheel: {
            enabled: false,
          },
        },
      },
      styledMode: true,
      accessibility: { enabled: false },
      credits: { enabled: false },
      exporting: {
        enabled: exporting,
        ...(exporting
          ? {
            buttons: exportingButtons({ onPushToGF }),
            fallbackToExportServer: false,
            chartOptions: {
              chart: {
                events: {
                  load: exportingLoadEvent,
                },
              },
            },
          }
          : {}),
      },
      lang,
    }),
    [exporting, onPushToGF],
  );
};

export default useDefaultPropsHSX;
