const sortBySystemAndValue = (a, b) => {
  if (a?.system > b?.system) {
    return -1;
  }

  if (a?.system !== b?.system) {
    return 1;
  }

  return (a?.value || '')
    .toLowerCase()
    .localeCompare((b?.value || '').toLowerCase());
};

export default sortBySystemAndValue;
