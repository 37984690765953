import useUIProperty from '+hooks/useUIProperty';

const PageTabsActionType = {
  init: 'init',
  add: 'add',
  update: 'update',
  remove: 'remove',
  move: 'move',
  changeActiveIndex: 'changeActiveIndex',
  pin: 'pin',
  unpin: 'unpin',
};

const pageTabsActionTypeStorageKey = 'netography:pageTabs:actionType';
const pageTabsActionTypeInitialValue = PageTabsActionType.init;

const usePageTabsActionType = () => {
  return useUIProperty(
    pageTabsActionTypeStorageKey,
    pageTabsActionTypeInitialValue,
  );
};

export { PageTabsActionType };
export default usePageTabsActionType;
