import { call, put } from 'redux-saga/effects';

import { actions as toastActions } from '@/redux/toast';
import {
  createSlice,
  createSelector,
  startFetching,
  stopFetching,
  defaultReducers,
} from '@/redux/util';

import backendClient from '@/middleware/backendClient';

export const initialState = {
  isFetching: false,
  error: '',
  guestCode: '',
  codeExpiresAt: null,
};

const apiPath = '/guests';

let api;

const initApi = () => {
  if (!api) {
    api = backendClient();
  }
};

const slice = createSlice({
  name: 'guest',
  initialState,

  reducers: {
    ...defaultReducers,
    generateGuestCode: startFetching,
    generateGuestCodeSuccess(state, { payload: { code, exp } }) {
      stopFetching(state);
      state.guestCode = code;
      state.codeExpiresAt = exp;
    },
    clearGuestCode(state) {
      state.guestCode = '';
      state.codeExpiresAt = null;
    },

    sendGuestCodeByEmail: startFetching,
    sendGuestCodeByEmailSuccess: stopFetching,
  },

  sagas: (actions) => ({
    [actions.generateGuestCode]: {
      * saga({ payload: params }) {
        initApi();

        try {
          const response = yield call(api.post, `${apiPath}/code`, params);
          yield put(actions.generateGuestCodeSuccess(response.data.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Guest access granted',
              response,
              showWarningOnly: true,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error granting guest access',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.sendGuestCodeByEmail]: {
      * saga({ payload: params }) {
        initApi();

        try {
          yield call(api.post, `${apiPath}/send-invite-email`, params);
          yield put(actions.sendGuestCodeByEmailSuccess());
          yield put(toastActions.success('Sharable link sent'));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error sending sharable link',
              details: error.message,
            }),
          );
        }
      },
    },
  }),

  selectors: (getState) => ({
    isFetching: createSelector(
      [getState],
      (state) => state.isFetching,
    ),

    getGuestCode: createSelector(
      [getState],
      (state) => state.guestCode,
    ),

    codeExpiresAt: createSelector(
      [getState],
      (state) => state.codeExpiresAt,
    ),
  }),
});

export const { actions, selectors } = slice;

export default slice;
