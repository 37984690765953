import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
