import PropTypes from '+prop-types';
import { useRef, useState, useMemo, useCallback } from 'react';

import classNames from 'classnames';
import styled from 'styled-components';

import ColorTypes from '@/models/ColorTypes';

import Button, { ButtonVariants } from '+components/Button';

const Container = styled.div`
`;

const FileUpload = styled((props) => {
  const {
    className,
    name,
    value,
    placeholder,
    accept,
    buttonText,
    buttonColor,
    buttonVariant,
    multiple,
    resetAfterChange,
    invalid,
    disabled,
    hideInput,
    hideButton,
    onChange,
    ...tail
  } = props;

  const hiddenInputRef = useRef();
  const [dropHover, setDropHover] = useState(false);

  const inputValue = useMemo(
    () => {
      if (!value) {
        return '';
      }
      if (typeof value === 'string') {
        return value;
      }
      if (Array.isArray(value)) {
        return value.map((el) => el.name).join(', ');
      }
      return value.name;
    },
    [value],
  );

  const getAcceptedFiles = useCallback(
    (files) => {
      if (!accept) {
        return files;
      }
      const accepts = accept.toLowerCase().split(',');
      return files.filter((d) => accepts.includes(`.${d.name.split('.').pop().toLowerCase()}`));
    },
    [accept],
  );

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();
      if (disabled) {
        return;
      }
      setDropHover(false);
      const files = getAcceptedFiles(Object.values(event.dataTransfer.files));
      onChange(multiple ? files : files[0]);
      if (resetAfterChange) {
        event.target.value = '';
      }
    },
    [disabled, getAcceptedFiles, multiple, resetAfterChange, onChange],
  );

  const onDragOver = useCallback(
    (event) => {
      event.preventDefault();
      if (disabled) {
        return;
      }
      setDropHover((prevValue) => prevValue || true);
    },
    [disabled],
  );

  const onDragLeave = useCallback(
    (event) => {
      event.preventDefault();
      if (disabled) {
        return;
      }
      setDropHover(false);
    },
    [disabled],
  );

  const onFileChoose = useCallback(
    () => {
      hiddenInputRef.current.click();
    },
    [hiddenInputRef.current],
  );

  const onFileChange = useCallback(
    (event) => {
      const { files } = event.target;
      onChange(multiple ? files : files[0]);
      if (resetAfterChange) {
        event.target.value = '';
      }
    },
    [multiple, resetAfterChange, onChange],
  );

  return (
    <Container
      {...tail}
      className={classNames(className, 'file-upload', { dropHover })}
      onDrop={onDrop}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
    >
      <input
        style={{ display: 'none' }}
        ref={hiddenInputRef}
        type="file"
        accept={accept}
        multiple={multiple}
        onChange={onFileChange}
      />

      {!hideInput && (
        <input
          className={classNames('file-upload__input', { invalid })}
          name={name}
          type="text"
          value={inputValue}
          placeholder={placeholder}
          onClick={onFileChoose}
          disabled={disabled}
          readOnly
        />
      )}

      {!hideButton && (
        <Button
          className="file-upload__btn"
          color={buttonColor}
          variant={buttonVariant}
          disabled={disabled}
          onClick={onFileChoose}
        >
          {buttonText}
        </Button>
      )}
    </Container>
  );
})`
  &.file-upload {
    display: flex;
    width: 100%;

    &.dropHover {
      position: relative;
      box-shadow: 0 0 0 3px ${({ theme }) => theme.primary};
      border-radius: 4px;

      :before {
        content: "Drop files here";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        white-space: nowrap;
        text-transform: uppercase;
        color: ${({ theme }) => theme.primary};
        font-weight: bold;
        font-size: 10px;
      }

      * {
        opacity: 0.2;
      }
    }

    .file-upload__input {
      cursor: pointer;
    }

    .file-upload__input + .file-upload__btn {
      margin-left: 15px;
    }
  }
`;

FileUpload.propTypes = {
  className: PropTypes.string,
  value: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  name: PropTypes.string,
  placeholder: PropTypes.string,
  accept: PropTypes.string,
  buttonText: PropTypes.string,
  buttonColor: PropTypes.oneOf(Object.values(ColorTypes)),
  buttonVariant: PropTypes.oneOf(Object.values(ButtonVariants)),
  multiple: PropTypes.bool,
  resetAfterChoose: PropTypes.bool,
  invalid: PropTypes.bool,
  disabled: PropTypes.bool,
  hideInput: PropTypes.bool,
  hideButton: PropTypes.bool,
  onChange: PropTypes.func,
};

FileUpload.defaultProps = {
  className: null,
  value: null,
  name: null,
  placeholder: 'Drag and drop file here, or click to select file',
  accept: '',
  buttonText: 'Choose File',
  buttonVariant: ButtonVariants.text,
  buttonColor: ColorTypes.primary,
  multiple: false,
  resetAfterChange: false,
  invalid: false,
  disabled: false,
  hideInput: false,
  hideButton: false,
  onChange: () => {},
};

export default FileUpload;
