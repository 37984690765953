import get from 'lodash.get';

import makeArr from '+utils/makeArr';

import { autoRemoveIfEmpty } from '../ReactTable/utils';
import { FilterOperator } from './utils';
import { withAutoRemove } from './withAutoRemove';

/**
 * @param {Object} fields
 * @return {function([], [], *): []}
 */
export const labelOrPortFieldsFilter = (fields) => withAutoRemove((rows, _, filterValue) => {
  if (autoRemoveIfEmpty(filterValue)) {
    return rows;
  }

  const parsedValue = parseFloat(filterValue.value);
  if (
    filterValue.operator
      && filterValue.operator !== FilterOperator.like
      && Number.isNaN(parsedValue)
  ) {
    return [];
  }

  let filterFn;
  switch (filterValue.operator) {
    case FilterOperator.greaterThen:
      filterFn = ({ original }) => get(original, fields.dataFieldName) > parsedValue;
      break;
    case FilterOperator.greaterThenOrEqual:
      filterFn = ({ original }) => get(original, fields.dataFieldName) >= parsedValue;
      break;
    case FilterOperator.lessThen:
      filterFn = ({ original }) => get(original, fields.dataFieldName) < parsedValue;
      break;
    case FilterOperator.lessThenOrEqual:
      filterFn = ({ original }) => get(original, fields.dataFieldName) <= parsedValue;
      break;
    case FilterOperator.equal: {
      filterFn = ({ original }) => get(original, fields.dataFieldName) === parsedValue;
      break;
    }
    case FilterOperator.like:
    default: {
      const normalizedFilterValue = String(filterValue.value).toLowerCase();
      filterFn = ({ original }) => Object.values(fields).some((field) => {
        const values = makeArr(get(original, field));
        return values.some((item) => {
          const normalizedItem = String(item ?? '').toLowerCase();
          return normalizedItem.includes(normalizedFilterValue);
        });
      });
      break;
    }
  }

  return rows.filter(filterFn);
}, autoRemoveIfEmpty);
