import styled from 'styled-components';

export default styled.div`
  display: flex;
  justify-content: flex-end;
  width: 20%;
  div {
    height: fit-content;
  }
  div + div {
    margin-left: 15px;
  }
`;
