import { SelectColumnFilter } from '+components/Table/Filters';

import { BaseColumnFactory } from './BaseColumnFactory';

/**
 * @param {Object} props
 * @param {string} props.field - field name
 * @param {string} [props.description=''] - field description
 * @param {boolean} props.enableLikeFilter
 * @param {any} props.filter
 * @return {Object} - column with filter=selectFilter and Filter=SelectColumnFilter
 */
export const StringsArrayColumnsFactory = ({
  enableLikeFilter,
  filter,
  ...tail
}) => ({
  ...BaseColumnFactory(tail),
  Filter: SelectColumnFilter({
    optionLabel: (key) => (key === 'all' ? 'All' : key),
    enableLikeFilter: enableLikeFilter ?? true,
  }),
  filter: filter ?? 'selectFilter',
});
