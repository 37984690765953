import { isIPv6, isIPv4, isIP } from 'is-ip';
import isFunction from 'lodash.isfunction';

import convertIpv4ToNumber from '+utils/convertIpv4ToNumber';
import convertIpv6ToNumber from '+utils/convertIpv6ToNumber';

const convertToString = (value) => String(value ?? '');

const compareString = (valueA, valueB) => {
  const aLower = convertToString(valueA).toLowerCase();
  const bLower = convertToString(valueB).toLowerCase();

  if (aLower === bLower) {
    return 0; // Rows are equal in the grouping key, no change in order.
  }

  if (aLower === '') {
    return 1; // Move rowA to the end.
  }

  if (bLower === '') {
    return -1; // Move rowB to the end.
  }

  return aLower.localeCompare(bLower);
};

export const compareNumberAndString = (a, b) => {
  // check for num vs num
  if (typeof a === 'number' && typeof b === 'number') {
    // eslint-disable-next-line no-nested-ternary
    return a === b ? 0 : a > b ? 1 : -1;
  }
  // check for num vs string
  if (typeof a === 'number' && typeof b === 'string') {
    return -1;
  }
  // check for string vs num
  if (typeof a === 'string' && typeof b === 'number') {
    return 1;
  }
  // check for string vs string
  return compareString(a, b);
};

export const compareIpAndLabel = (a, b) => {
  let aToCompare = a;
  let bToCompare = b;

  if (isIP(a)) {
    if (isIPv4(a)) {
      aToCompare = convertIpv4ToNumber(a);
    } else if (isIPv6(a)) {
      aToCompare = convertIpv6ToNumber(a);
    }
  }

  if (isIP(b)) {
    if (isIPv4(b)) {
      bToCompare = convertIpv4ToNumber(b);
    } else if (isIPv6(b)) {
      bToCompare = convertIpv6ToNumber(b);
    }
  }

  return compareNumberAndString(aToCompare, bToCompare);
};

const compareBoolean = (a, b) => {
  // eslint-disable-next-line no-nested-ternary
  return a === b ? 0 : a ? -1 : 1;
};

/**
 * Helper for making sortBy.
 * @param {function(value, row, id): *} extractor
 * @param {function(a, b): number} [compare]
 * @return {function(*, *, string): number}
 */
export const sortByHelper = (extractor = null, compare = null) => (rowA, rowB, id) => {
  const a = isFunction(extractor)
    ? extractor(rowA.values[id], rowA, id)
    : rowA.values[id];
  const b = isFunction(extractor)
    ? extractor(rowB.values[id], rowB, id)
    : rowB.values[id];

  if (isFunction(compare)) {
    return compare(a, b);
  }

  return compareNumberAndString(a, b);
};

export const ip = sortByHelper(
  (value) => (Array.isArray(value) ? value[0] : value),
  compareIpAndLabel,
);

export const string = sortByHelper(null, compareString);

export const boolean = sortByHelper(null, compareBoolean);

export const number = sortByHelper(null, compareNumberAndString);
