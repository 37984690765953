import { call, put } from 'redux-saga/effects';

import { actions as toastActions } from '@/redux/toast';
import {
  createSlice,
  startFetching,
  stopFetching,
  createSelector,
  defaultReducers,
  takeLeading,
} from '@/redux/util';

import backendClient from '@/middleware/backendClient';

export const initialState = {
  isFetching: false,
  isFetchingTags: false,
  isSubmitting: false,
  submitSucceeded: false,
  providers: {},
  status: {},
  statuses: {},
  tags: [],
  regions: [],
  error: null,
  classifications: null,
};

let api;

const initApi = () => {
  if (!api) {
    api = backendClient();
  }
};

const url = '/vpc';

const startSubmit = (state) => {
  startFetching(state);

  state.submitSucceeded = false;
  state.isSubmitting = true;
};

const submitSuccess = (state) => {
  stopFetching(state);
  state.submitSucceeded = true;
  state.isSubmitting = false;
};

const slice = createSlice({
  name: 'vpcs',
  initialState,

  reducers: {
    ...defaultReducers,
    fetchVpcs: startFetching,
    fetchVpcStatuses: startFetching,
    fetchById: startFetching,
    fetchVpcRegion: startFetching,
    fetchVpcClassification: startFetching,
    vpcAdd: startSubmit,
    vpcUpdate: startSubmit,
    vpcEnable: startSubmit,
    vpcDisable: startSubmit,
    vpcDelete: startFetching,
    vpcStatus: startFetching,
    regenerateKeys: startFetching,
    bulkEnableVpcs: startFetching,
    bulkDisableVpcs: startFetching,
    bulkDeleteVpcs: startFetching,

    fetchTags: (state) => {
      state.isFetchingTags = true;
      state.error = null;
    },

    fetchTagsSuccess(state, { payload: { data } }) {
      state.isFetchingTags = false;
      state.tags = data;
    },

    success(state, { payload: { data } }) {
      stopFetching(state);

      state.providers = (data || []).reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {});
    },

    successById(state, { payload: { data } }) {
      stopFetching(state);
      state.providers[data.id] = data;
    },

    addSuccess(
      state,
      { payload: { data: [data] } },
    ) {
      submitSuccess(state);
      state.providers[data.id] = data;
    },

    updateSuccess(
      state,
      { payload: { data: [data] } },
    ) {
      submitSuccess(state);
      state.providers[data.id] = data;
    },

    bulkToggleSuccess(state, { payload: { data } }) {
      submitSuccess(state);
      data.forEach((vpc) => {
        state.providers[vpc.id] = vpc;
      });
    },
    regenerateKeySuccess(
      state,
      { payload: { data: [data] } },
    ) {
      submitSuccess(state);
      state.providers[data.id] = data;
    },

    deleteSuccess(state, { payload: id }) {
      stopFetching(state);
      delete state.providers[id];
    },

    bulkDeleteSuccess(state, { payload: vpcs }) {
      stopFetching(state);
      vpcs.forEach((vpc) => {
        delete state.providers[vpc.id];
      });
    },

    resetSubmit(state) {
      state.submitSucceeded = false;
      state.isSubmitting = false;
    },

    vpcStatusSuccess(state, { payload: { data } }) {
      stopFetching(state);
      state.status[data.id] = data;
    },

    fetchVpcStatusesSuccess: (state, { payload: { data = [] } = {} }) => {
      stopFetching(state);
      const { statuses = {} } = state;

      if (Array.isArray(data)) {
        data.forEach((device) => {
          statuses[device.id] = device;
        });
      }

      state.statuses = statuses;
    },

    successFetchingRegions(state, { payload: { data } }) {
      stopFetching(state);
      state.regions = (data || []).reduce(
        (result, item) => ({
          ...result,
          [item.provider]: [
            ...(result[item.provider] || []),
            { ...item, value: item.region },
          ],
        }),
        {},
      );
    },

    successFetchingClassification(state, { payload: { data } }) {
      stopFetching(state);
      state.classifications = data;
    },

    skip: stopFetching,
    fail(state, { payload }) {
      defaultReducers.fail(state, { payload });
      state.isSubmitting = false;
    },
  },

  sagas: (actions) => ({
    [actions.fetchVpcs]: {
      taker: takeLeading(actions.skip),
      * saga() {
        initApi();

        try {
          const { data } = yield call(api.get, url);

          yield put(actions.success(data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching cloud providers',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.fetchById]: {
      * saga({ payload: id }) {
        initApi();

        try {
          const { data } = yield call(api.get, `${url}/${id}`);
          yield put(actions.successById(data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching cloud provider',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.vpcAdd]: {
      * saga({ payload }) {
        initApi();

        try {
          const response = yield call(api.post, url, payload);
          yield put(actions.addSuccess(response.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Cloud provider has been created',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error creating cloud provider',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.vpcEnable]: {
      * saga({ payload: { id, ...params } }) {
        initApi();
        try {
          const response = yield call(api.put, `${url}/${id}/enable`, params);
          yield put(actions.updateSuccess(response.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Cloud provider has been enabled',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error enabling cloud provider',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.bulkEnableVpcs]: {
      * saga({ payload: { vpcs } }) {
        initApi();
        try {
          const response = yield call(api.put, `${url}/bulkenable`, { vpcs });
          yield put(actions.bulkToggleSuccess(response.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Cloud providers have been enabled',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error enabling cloud providers',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.vpcDisable]: {
      * saga({ payload: { id, ...params } }) {
        initApi();
        try {
          const response = yield call(api.put, `${url}/${id}/disable`, params);
          yield put(actions.updateSuccess(response.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Cloud provider has been disabled',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error enabling cloud provider',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.bulkDisableVpcs]: {
      * saga({ payload: { vpcs } }) {
        initApi();
        try {
          const response = yield call(api.put, `${url}/bulkdisable`, { vpcs });
          yield put(actions.bulkToggleSuccess(response.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Cloud providers have been disabled',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error disabling cloud providers',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.vpcUpdate]: {
      * saga({ payload: { id, ...params } }) {
        initApi();

        try {
          const response = yield call(api.put, `${url}/${id}`, params);
          // bug: PUT API response does not include the id.  (POST does, however)
          // data.data[0].id = id;
          yield put(actions.updateSuccess(response.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Cloud provider has been updated',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error updating cloud provider',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.vpcDelete]: {
      * saga({ payload: id }) {
        initApi();

        try {
          const response = yield call(api.delete, `${url}/${id}`);
          yield put(actions.deleteSuccess(id));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Cloud provider has been removed',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error deleting cloud provider',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.bulkDeleteVpcs]: {
      * saga({ payload: vpcs }) {
        initApi();

        try {
          const response = yield call(api.put, `${url}/bulkdelete`, { vpcs });
          yield put(actions.bulkDeleteSuccess(vpcs));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Cloud providers have been removed',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error deleting cloud providers',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.vpcStatus]: {
      * saga({ payload: id }) {
        initApi();

        try {
          const { data } = yield call(api.get, `${url}/status/${id}`);
          yield put(actions.vpcStatusSuccess(data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error retrieving cloud provider statuses',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.fetchVpcStatuses]: {
      taker: takeLeading(actions.skip),
      * saga() {
        initApi();

        try {
          const { data } = yield call(api.get, `${url}/status/`);
          yield put(actions.fetchVpcStatusesSuccess(data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error retrieving cloud provider statuses',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.regenerateKeys]: {
      * saga({ payload: params }) {
        initApi();

        const { flowtype, flowresource, id } = params;
        try {
          const response = yield call(
            api.post,
            `${url}/keys/${flowtype}/${flowresource}/${id}`,
          );
          yield put(actions.regenerateKeySuccess(response.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Cloud provider keys have been regenerated',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error regenerating cloud provider keys',
              details: error.message,
            }),
          );
        }
      },
    },
    [actions.fetchVpcRegion]: {
      taker: takeLeading(actions.skip),
      * saga() {
        initApi();

        try {
          const { data } = yield call(api.get, `${url}/regions`);
          yield put(actions.successFetchingRegions(data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching vpc regions',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.fetchVpcClassification]: {
      taker: takeLeading(actions.skip),
      * saga() {
        initApi();

        try {
          const { data } = yield call(api.get, `${url}/classification`);
          yield put(actions.successFetchingClassification(data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching vpc classifications',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.fetchTags]: {
      taker: takeLeading(actions.skip),
      * saga() {
        initApi();

        try {
          const { data } = yield call(api.get, '/tags/vpc?terse=0');
          yield put(actions.fetchTagsSuccess(data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Could not retrieve cloud provider tags',
              details: error.message,
            }),
          );
        }
      },
    },
  }),

  selectors: (getState) => ({
    isFetching: createSelector(
      [getState],
      (state) => state.isFetching,
    ),

    getVpcs: createSelector(
      [getState],
      (state) => state.providers,
    ),

    getVpcById: (id) => createSelector(
      [getState],
      (state) => state.providers?.[id],
    ),

    getVpcTags: createSelector(
      [getState],
      (vpcState) => vpcState.tags,
    ),

    getVpcStatus: (id) => createSelector(
      [getState],
      (vpcState) => vpcState.status[id],
    ),

    getVpcStatuses: createSelector(
      [getState],
      (vpcState) => vpcState?.statuses,
    ),

    getVpcRegion: createSelector(
      [getState],
      (state) => state?.regions,
    ),

    getVpcRegionByProvider: (provider) => createSelector(
      [getState],
      (state) => state?.regions?.[provider],
    ),

    getVpcClassifications: createSelector(
      [getState],
      (state) => state?.classifications,
    ),

    getVpcClassification: (provider, source) => createSelector(
      [getState],
      (state) => state?.classifications?.[provider]?.[source],
    ),
  }),
});

// For convenience
export const { actions, selectors } = slice;

export default slice;
