import PropTypes from '+prop-types';
import {
  CircleMarker as MarkerOrigin,
  Popup as PopupOrigin,
} from 'react-leaflet';

import styled from 'styled-components';

import NoDataStr from '@/models/NoDataStr';

import Flag from '+components/Flag';
import { Row, Col } from '+components/Layout';

const Popup = styled(PopupOrigin)`
  .leaflet-popup-content-wrapper {
    min-width: 280px;
    border-radius: 4px;
  }

  * > div,
  * > span {
    color: black;
    font-size: 1.1em;
  }
`;

const ColValue = styled(Col)`
  font-weight: bold;
`;

const Marker = (props) => {
  const { geo } = props;
  const {
    location,
    continentcode,
    countrycode,
    subdiso,
    subdivisionb,
    city,
    postal,
  } = geo;

  // @see https://leafletjs.com/reference-1.6.0.html#circlemarker-option
  return (
    <MarkerOrigin
      center={[location?.lat, location?.lon]}
      position={[location?.lat, location?.lon]}
      radius={10}
      weight={2}
      fill
      fillOpacity={0.8}
    >
      <Popup>
        <Col style={{ whiteSpace: 'nowrap' }}>
          <Row>
            <Col xs item container={false}>
              Continent:
            </Col>
            <ColValue xs item container={false}>
              {continentcode || NoDataStr}
            </ColValue>
          </Row>
          <Row>
            <Col xs item container={false}>
              Country:
            </Col>
            <ColValue xs item container={false}>
              {countrycode ? (
                <Row alignItems="center">
                  <span style={{ marginRight: '0.5rem' }}>{countrycode}</span>
                  <Flag countryCode={countrycode} />
                </Row>
              ) : (
                NoDataStr
              )}
            </ColValue>
          </Row>
          <Row>
            <Col xs item container={false}>
              Subdivision:
            </Col>
            <ColValue xs item container={false}>
              {subdiso || NoDataStr}
            </ColValue>
          </Row>
          <Row>
            <Col xs item container={false}>
              Subdivision B:
            </Col>
            <ColValue xs item container={false}>
              {subdivisionb || NoDataStr}
            </ColValue>
          </Row>
          <Row>
            <Col xs item container={false}>
              City:
            </Col>
            <ColValue xs item container={false}>
              {city || NoDataStr}
            </ColValue>
          </Row>
          <Row>
            <Col xs item container={false}>
              Postal:
            </Col>
            <ColValue xs item container={false}>
              {postal || NoDataStr}
            </ColValue>
          </Row>
          <Row>
            <Col xs item container={false}>
              Coordinates:
            </Col>
            <ColValue xs item container={false}>
              {location?.lat === 0 && location?.lon === 0
                ? NoDataStr
                : `${location.lat}, ${location.lon}`}
            </ColValue>
          </Row>
        </Col>
      </Popup>
    </MarkerOrigin>
  );
};

Marker.propTypes = {
  geo: PropTypes.shape({
    continentcode: PropTypes.string,
    countrycode: PropTypes.string,
    subdiso: PropTypes.string,
    subdivisionb: PropTypes.string,
    city: PropTypes.string,
    postal: PropTypes.string,
    location: PropTypes.shape({
      lat: PropTypes.number,
      lon: PropTypes.number,
    }),
  }).isRequired,
};

export default Marker;
