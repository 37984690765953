import PropTypes from '+prop-types';
import { Fragment } from 'react';
import { useLocation } from 'react-router-dom';

import { TryAgainBoundary } from '+components/ErrorBoundary';

import Breadcrumb from './Breadcrumb';

/**
 * Use instead of react-router's Route to have this route added to Breadcrumbs.
 */
const CrumbRoute = (props) => {
  const { component: Component, componentProps, ...rest } = props;
  const { pathname, key } = useLocation();
  return (
    <Fragment>
      <Breadcrumb {...rest} pathname={pathname} />
      <TryAgainBoundary resetKey={key}>
        <Component {...componentProps} />
      </TryAgainBoundary>
    </Fragment>
  );
};

CrumbRoute.propTypes = {
  component: PropTypes.children.isRequired,
  componentProps: PropTypes.object,
  /** title to show on Breadcrumbs  */
  title: PropTypes.string,
};

CrumbRoute.defaultProps = {
  componentProps: {},
  title: '',
};

export default CrumbRoute;
