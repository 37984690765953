import { ContextTypes } from '@/models/ContextTypes';

import {
  createSlice,
  startFetching,
  stopFetching,
  tryCancelSaga,
  defaultReducers,
  createSelector,
} from '@/redux/util';

export const initialState = {
  isFetching: false,
  fields: {
    [ContextTypes.flow]: [],
    [ContextTypes.dns]: [],
    [ContextTypes.traffic]: [],
  },
  data: {
    [ContextTypes.flow]: [],
    [ContextTypes.dns]: [],
    [ContextTypes.traffic]: [],
  },
  error: '',
};

const apiPath = '/traffic-top';

const url = (...parts) => [apiPath, ...parts].join('/');

const slice = createSlice({
  name: 'traffic-top',
  initialState,

  reducers: {
    ...defaultReducers,
    fetchFields: startFetching,
    fetchFieldsSuccess(state, { payload }) {
      stopFetching(state);
      const { context, data } = payload.data.data;

      state.fields[context] = data;
    },

    search: startFetching,
    searchSuccess(state, { payload }) {
      stopFetching(state);
      const { context, data } = payload.data.data;

      state.data[context] = data;
    },

    // TODO: we are really need to clear only data or can use default clear method that reinstall initialState.
    clear(state) {
      state.data = {
        ...initialState.data,
      };
    },
  },

  sagas: (actions) => ({
    [actions.fetchFields]: {
      * saga({ payload }) {
        yield tryCancelSaga(
          'get',
          {
            successAction: actions.fetchFieldsSuccess,
            errorAction: actions.fail,
            toasts: {
              error: 'Error fetching Traffic Top fields',
            },
          },
          url('fields', payload),
        );
      },
    },

    [actions.search]: {
      * saga({ payload }) {
        const { context } = payload;
        yield tryCancelSaga(
          'post',
          {
            successAction: actions.searchSuccess,
            errorAction: actions.fail,
            toasts: {
              error: 'Error fetching Traffic Top data',
            },
          },
          url(context),
          payload,
        );
      },
    },
  }),

  selectors: (getState) => ({
    isFetching: createSelector(
      [getState],
      (state) => state.isFetching,
    ),

    getFields: (context) => createSelector(
      [getState],
      (state) => state.fields[context],
    ),

    getData: (context) => createSelector(
      [getState],
      (state) => state.data[context],
    ),
  }),
});

export const { actions, selectors } = slice;

export default slice;
