import PropTypes from '+prop-types';

import styled from 'styled-components';

const Path = styled.path.attrs(() => ({
  className: 'highcharts-button-symbol',
}))`
  transform: scale(0.9,0.9)translate(2px, -2px);
`;

const MenuIcon = ({ size }) => (
  <svg
    className="mdi-icon"
    width={size}
    height={size}
    fill="currentColor"
    viewBox="0 0 16 16"
  >
    <Path d="M 0 6 L 14 6 M 0 11 L 14 11 M 0 16 L 14 16" data-z-index="1" />
  </svg>
);

MenuIcon.propTypes = {
  size: PropTypes.number,
};

MenuIcon.defaultProps = {
  size: 16,
};

export default MenuIcon;
