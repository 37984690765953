import styled from 'styled-components';

export default styled.div`
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  padding-left: 140px;

  button:last-child {
    width: unset;
    .wizard__step__line {
      display: none;
    }
  }
`;
