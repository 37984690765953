import PropTypes from '+prop-types';
import { Fragment, useEffect } from 'react';
import { useToggle } from 'react-use';

const fontsTests = [
  '400 16px "Source Sans Pro"',
  '600 16px "Source Sans Pro"',
  '700 16px "Source Sans Pro"',
  '400 16px "Source Code Pro"',
];

const FontsReadyAwaiter = (props) => {
  const { loadingComponent, children } = props;
  const [isFontsReady, toggle] = useToggle(false);

  useEffect(
    () => {
      let timeout;

      const checker = () => {
        let fontReady = typeof document.fonts?.check !== 'function';
        fontReady = fontReady || fontsTests.every((font) => document.fonts.check(font));
        if (fontReady) {
          toggle();
          return;
        }

        timeout = setTimeout(checker, 100);
      };

      checker();

      return () => {
        clearTimeout(timeout);
      };
    },
    [],
  );

  return isFontsReady ? (
    children
  ) : (
    <Fragment>
      <div className="testFontContainer">
        {fontsTests.map((font) => (
          <div style={{ font }} key={font}>
            {font}
          </div>
        ))}
      </div>
      {loadingComponent}
    </Fragment>
  );
};

FontsReadyAwaiter.propTypes = {
  loadingComponent: PropTypes.children,
  children: PropTypes.children.isRequired,
};

FontsReadyAwaiter.defaultProps = {
  loadingComponent: null,
};

export default FontsReadyAwaiter;
