import styled from 'styled-components';

import NqlTextFieldOrigin from '+components/form/NqlTextField';

const NQLFieldLabel = styled.div`
  position: absolute;
  top: 0;
  left: calc(56px + 8px + 4px);
  align-items: center;
  font-size: 11px;
  font-weight: 600;
  white-space: nowrap;
  user-select: none;
  max-height: 12px;
  color: ${({ theme }) => theme.colorTextSecondary} !important;
`;

const NqlButtonsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  gap: 2px;

  :empty {
    display: none;
  }
`;

const NqlFieldError = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2px;
  cursor: pointer;

  .mdi-icon {
    color: ${({ theme }) => theme.Palette.danger};
  }
`;

const NQLFieldClear = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2px;
  cursor: pointer;

  .MuiButtonBase-root {
    background: transparent;
  }

  .mdi-icon {
    color: ${({ theme }) => theme.colorTextSecondary} !important;
  }
`;

const MainNqlFilter = styled(NqlTextFieldOrigin)`
  --height: 26px;

  .nql-main-container {
    flex-direction: unset;
    border: none;
    border-radius: 0;
    background: transparent !important;
    min-height: calc(var(--height) + 4px) !important;
    max-height: var(--height);
  }

  .nql-dropdown-btn-container {
    width: 0;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    padding: 0 !important;
    margin: 0 !important;
  }

  .nql-code-block {
    padding-top: 10px !important;
    padding-left: 0 !important;
    padding-right: 18px !important;
    background: transparent !important;
    min-height: var(--height) !important;
    line-height: unset !important;
    * {
      background: transparent !important;
    }
  }

  .nql-textarea {
    --buttons-width: 0px;
    padding-top: 10px !important;
    padding-left: 0 !important;
    padding-right: 18px !important;
    background: transparent !important;
    min-height: var(--height) !important;
    line-height: unset !important;
  }

  .form__form-group-error {
    display: none;
  }
`;

export { NQLFieldLabel, NqlFieldError, NQLFieldClear, NqlButtonsContainer };
export default MainNqlFilter;
