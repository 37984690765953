import PropTypes from '+prop-types';
import { Fragment } from 'react';

import Field from '+components/form/FinalForm/Field';
import { normalizeSelectValue } from '+components/form/Normalizers';
import RoleAuthFieldsSection from '+components/form/RoleAuthFieldSection';
import SelectField from '+components/form/SelectField';
import TextField from '+components/form/TextField';
import { validateRequired } from '+components/form/Validators';
import useProviderRegions from '+hooks/useProviderRegions';

const IbmFields = ({ disabled }) => {
  const regionOptions = useProviderRegions('ibm');
  return (
    <Fragment>
      <Field
        name="ibm.region"
        label="Region"
        component={SelectField}
        validate={validateRequired}
        options={regionOptions}
        parse={normalizeSelectValue}
        helperText="Cloud Region"
        disabled={disabled}
        required
      />

      <RoleAuthFieldsSection label="Authentication">
        <Field
          name="ibm.apikey"
          label="API Key"
          component={TextField}
          type="password"
          autoComplete="off"
          validate={validateRequired}
          helperText="API Key for authenticating to IBM"
          disabled={disabled}
          required
        />
      </RoleAuthFieldsSection>
    </Fragment>
  );
};

IbmFields.propTypes = {
  disabled: PropTypes.bool,
};

IbmFields.defaultProps = {
  disabled: false,
};

export default IbmFields;
