import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  actions as allowlistsActions,
  selectors as allowlistsSelectors,
} from '@/redux/api/allowlists';

/**
 * Returns allowLists.
 * @return {Object}
 */
export const useAllowlists = () => {
  const dispatch = useDispatch();

  const allowlists = useSelector(allowlistsSelectors.getAllowlists);
  const isAllowlistsFetching = useSelector(allowlistsSelectors.isFetching);

  const allowlistLength = Object.keys(allowlists || {}).length;
  useEffect(
    () => {
      if (allowlistLength) {
        return;
      }
      dispatch(allowlistsActions.fetchAllowlists());
    },
    [allowlistLength],
  );

  return { allowlists, isAllowlistsFetching };
};

export default useAllowlists;
