import PropTypes from '+prop-types';
import { useEffect } from 'react';
import { useMap } from 'react-leaflet';

import L from 'leaflet';

// See https://github.com/Leaflet/Leaflet.fullscreen
L.Control.Fullscreen = L.Control.extend({
  options: {
    position: 'topleft',
    title: {
      false: 'View Fullscreen',
      true: 'Exit Fullscreen',
    },
  },

  onAdd(map) {
    const container = L.DomUtil.create(
      'div',
      'leaflet-control-fullscreen leaflet-bar leaflet-control',
    );

    this.link = L.DomUtil.create(
      'a',
      'leaflet-control-fullscreen-button leaflet-bar-part',
      container,
    );
    this.link.href = '#';

    this._map = map;
    this._map.on('fullscreenchange', this._toggleTitle, this);
    this._toggleTitle();

    L.DomEvent.on(this.link, 'click', this._click, this);
    L.DomEvent.on(this.link, 'dblclick', this._noop, this);
    L.DomEvent.on(this.link, 'mousedown ', this._noop, this);
    L.DomEvent.on(this.link, 'touchstart ', this._noop, this);

    return container;
  },

  _click(e) {
    L.DomEvent.stopPropagation(e);
    L.DomEvent.preventDefault(e);
    this._map.toggleFullscreen(this.options);
  },

  _noop(e) {
    L.DomEvent.stopPropagation(e);
    L.DomEvent.preventDefault(e);
  },

  _toggleTitle() {
    this.link.title = this.options.title[this._map.isFullscreen()];
  },
});

L.Map.include({
  isFullscreen() {
    return this._isFullscreen || false;
  },

  toggleFullscreen(options) {
    const container = this.getContainer();
    if (this.isFullscreen()) {
      if (options && options.pseudoFullscreen) {
        this._disablePseudoFullscreen(container);
      } else if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else {
        this._disablePseudoFullscreen(container);
      }
    } else if (options && options.pseudoFullscreen) {
      this._enablePseudoFullscreen(container);
    } else if (container.requestFullscreen) {
      container.requestFullscreen();
    } else if (container.mozRequestFullScreen) {
      container.mozRequestFullScreen();
    } else if (container.webkitRequestFullscreen) {
      container.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
    } else if (container.msRequestFullscreen) {
      container.msRequestFullscreen();
    } else {
      this._enablePseudoFullscreen(container);
    }
  },

  _enablePseudoFullscreen(container) {
    L.DomUtil.addClass(container, 'leaflet-pseudo-fullscreen');
    this._setFullscreen(true);
    this.fire('fullscreenchange');
  },

  _disablePseudoFullscreen(container) {
    L.DomUtil.removeClass(container, 'leaflet-pseudo-fullscreen');
    this._setFullscreen(false);
    this.fire('fullscreenchange');
  },

  _setFullscreen(fullscreen) {
    this._isFullscreen = fullscreen;
    const container = this.getContainer();
    if (fullscreen) {
      L.DomUtil.addClass(container, 'leaflet-fullscreen-on');
    } else {
      L.DomUtil.removeClass(container, 'leaflet-fullscreen-on');
    }
    this.invalidateSize();
  },

  _onFullscreenChange() {
    const fullscreenElement = document.fullscreenElement
      || document.mozFullScreenElement
      || document.webkitFullscreenElement
      || document.msFullscreenElement;

    if (fullscreenElement === this.getContainer() && !this._isFullscreen) {
      this._setFullscreen(true);
      this.fire('fullscreenchange');
    } else if (
      fullscreenElement !== this.getContainer()
      && this._isFullscreen
    ) {
      this._setFullscreen(false);
      this.fire('fullscreenchange');
    }
  },
});

L.Map.mergeOptions({
  fullscreenControl: false,
});

L.Map.addInitHook(function () {
  if (this.options.fullscreenControl) {
    this.fullscreenControl = new L.Control.Fullscreen(
      this.options.fullscreenControl,
    );
    this.addControl(this.fullscreenControl);
  }

  let fullscreenchange;

  if ('onfullscreenchange' in document) {
    fullscreenchange = 'fullscreenchange';
  } else if ('onmozfullscreenchange' in document) {
    fullscreenchange = 'mozfullscreenchange';
  } else if ('onwebkitfullscreenchange' in document) {
    fullscreenchange = 'webkitfullscreenchange';
  } else if ('onmsfullscreenchange' in document) {
    fullscreenchange = 'MSFullscreenChange';
  }

  if (fullscreenchange) {
    const onFullscreenChange = L.bind(this._onFullscreenChange, this);

    this.whenReady(() => {
      L.DomEvent.on(document, fullscreenchange, onFullscreenChange);
    });

    this.on('unload', () => {
      L.DomEvent.off(document, fullscreenchange, onFullscreenChange);
    });
  }
});

const FullscreenControl = (props) => {
  const { position } = props;
  const map = useMap();

  useEffect(
    () => {
      const options = { position };
      const fullscreenControl = new L.Control.Fullscreen(options);
      map.addControl(fullscreenControl);
      return () => {
        map.removeControl(fullscreenControl);
      };
    },
    [map, position],
  );

  return null;
};

FullscreenControl.propTypes = {
  position: PropTypes.string.isRequired,
};

export default FullscreenControl;
