import styled from 'styled-components';

const Body = styled.div`
  width: 100%;
  border-radius: 0;
  margin-top: 0;
  box-sizing: border-box;

  *:focus {
    outline: none;
  }
`;

export default Body;
