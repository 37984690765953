import styled from 'styled-components';

import { Row } from '+components/Layout';

const MenuContainer = styled(Row)`
  position: absolute;
  bottom: 0;
  height: fit-content;
  background-color: ${({ theme }) => theme.propertiesTrayActiveBackground};
  transition: opacity 0.3s;
  opacity: ${({ $isOpen }) => ($isOpen ? 1 : 0)};
  :empty {
    visibility: hidden;
  }
`;

export default MenuContainer;
