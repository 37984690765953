import PropTypes from '+prop-types';

import { ContextTypes } from '@/models/ContextTypes';

import { BlockTable } from '+components/ContextTables';
import SearchPage from '+components/SearchPage';

const tableSortBy = [
  {
    id: 'start',
    desc: true,
  },
];

const requestSort = {
  field: 'start',
  order: 'desc',
};

const Table = ({ data = [], ...tail }) => (
  <BlockTable
    {...tail}
    id="Search_BlocksSearch_Table"
    data={data}
    noDataText={tail.loading ? 'Searching...' : undefined}
    sortBy={tableSortBy}
  />
);

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
};

Table.defaultProps = {
  data: [],
};

const BlocksSearch = () => (
  <SearchPage
    nqlContext={ContextTypes.blocks}
    nqlPlaceholder="dstport != 443 && dstip == 10.0.0.0/24"
    resultRenderer={Table}
    sort={requestSort}
  />
);

export default BlocksSearch;
