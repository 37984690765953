/* eslint-disable react/prop-types */
import CellWrapper from './CellWrapper';

// Generic Context Menu for Site
const CellSite = ({ field, value, original, disabled }) => (
  <CellWrapper
    customer={original?.customer}
    field={field}
    value={value}
    disabled={disabled}
  >
    <span>{value}</span>
  </CellWrapper>
);

export default CellSite;
