import * as dayjs from 'dayjs';

const formatTimeSliderLabel = (seconds) => {
  if (seconds < 60) {
    return `${seconds}s`;
  }
  const duration = dayjs.duration(seconds * 1000);
  const minutes = duration.format('m[m]');
  const hours = duration.format('H[h]');
  const days = duration.format('D[d]');

  return `${days === '0d' ? '' : days} ${hours === '0h' ? '' : hours} ${
    minutes === '0m' ? '' : minutes
  }`;
};

const getSliderMarks = (numberOfMarks, interval) => Array.from({ length: numberOfMarks }).map((_, index) => ({
  value: (index + 1) * interval,
  label: '',
}));

export { formatTimeSliderLabel, getSliderMarks };
