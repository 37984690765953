/* eslint-disable max-len */
import PropTypes from '+prop-types';
import { memo } from 'react';

const IbmIcon = memo(({ size }) => (
  <svg
    width={size}
    height={size}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 65 65"
  >
    <defs>
      <filter
        id="A"
        filterUnits="userSpaceOnUse"
        x="79.3"
        y="86.2"
        width="188.1"
        height="166.2"
      >
        <feColorMatrix
          values="-1 0 0 0 1 0 -1 0 0 1 0 0 -1 0 1 0 0 0 1 0"
          colorInterpolationFilters="sRGB"
        />
      </filter>
    </defs>
    <mask
      maskUnits="userSpaceOnUse"
      x="79.3"
      y="86.2"
      width="188.1"
      height="166.2"
      id="B"
    >
      <g filter="url(#A)">
        <path d="M104.4 152.5c-.6 0-1.2-.2-1.8-.5l-16.8-9.7c-1.6-.8-2.3-2.7-1.7-4.4.7-1.8 2.7-2.8 4.6-2.1l.6.3 16.8 9.7c1.7.9 2.4 3.1 1.5 4.8-.6 1.2-1.8 1.9-3.2 1.9zm29.1-29c-1.3 0-2.4-.7-3.1-1.8l-9.7-16.8c-.9-1.5-.6-3.5.7-4.7 1.5-1.3 3.7-1.1 5 .4.1.2.3.3.4.5l9.7 16.8c1 1.6.6 3.7-.9 4.9-.7.5-1.4.7-2.1.7zm36-14.4V90c0-2 1.4-3.6 3.4-3.8 2-.1 3.6 1.4 3.7 3.4v19.8c0 2-1.6 3.6-3.6 3.6h-.2c-1.8-.3-3.3-2-3.3-3.9zm43.2 14.4c-.7 0-1.4-.2-2-.6-1.5-1.1-1.9-3.3-.9-4.9l9.7-16.8c1-1.7 3.2-2.3 4.9-1.3.2.1.4.2.5.4 1.3 1.2 1.6 3.2.7 4.7l-9.7 16.8c-.7 1-1.9 1.7-3.2 1.7zm29 29c-1.3 0-2.5-.7-3.2-1.9-.8-1.8-.2-3.9 1.5-4.8l16.6-9.6c1.5-.9 3.4-.6 4.6.7 1.3 1.5 1.1 3.7-.4 5-.2.1-.3.3-.5.4l-17 9.8c-.3.2-.9.4-1.6.4z" />
        <linearGradient
          id="C"
          gradientUnits="userSpaceOnUse"
          x1="179.54"
          y1="197.623"
          x2="136.578"
          y2="136.268"
        >
          <stop offset=".2" stopOpacity="0" />
          <stop offset=".287" stopOpacity=".03" />
          <stop offset=".501" stopOpacity=".2" />
          <stop offset=".793" stopOpacity=".742" />
          <stop offset="1" />
        </linearGradient>
        <path
          d="M128 233.7c-24.9-24.9-24.9-65.3 0-90.2s65.3-24.9 90.2 0a69.1 69.1 0 0 1 5.1 5.7c1.1 1.4 2.1 2.8 3.1 4.3l-5.9 3.9c-.9-1.3-1.8-2.6-2.8-3.8-1.4-1.8-2.9-3.5-4.5-5.1-22.1-22.1-58-22.1-80.2 0-22.1 22.1-22.1 58 0 80.2z"
          fill="url(#C)"
        />
        <path d="M218.6 252.4h-98.2c-22.8-.2-41.1-18.7-40.9-41.5l7.1.1c-.1 18.8 15 34.2 33.8 34.4h98.1c23 0 41.6-18.6 41.7-41.6 0-10.2-3.8-20.1-10.6-27.8l5.3-4.7c17.9 20.1 16.2 50.9-3.8 68.8-9 7.9-20.5 12.3-32.5 12.3z" />
        <linearGradient
          id="D"
          gradientUnits="userSpaceOnUse"
          x1="75.254"
          y1="203.618"
          x2="118.48"
          y2="178.661"
        >
          <stop offset=".08" />
          <stop offset=".753" stopOpacity=".07" />
          <stop offset=".875" stopOpacity="0" />
        </linearGradient>
        <path
          d="M86.6 210.9l-7.1-.1c.1-20 14.6-37 34.3-40.3l1.2 7c-16.3 2.8-28.3 16.9-28.4 33.4z"
          fill="url(#D)"
        />
        <linearGradient
          id="E"
          gradientUnits="userSpaceOnUse"
          x1="174.059"
          y1="208.327"
          x2="239.81"
          y2="153.155"
        >
          <stop offset=".138" stopOpacity="0" />
          <stop offset=".32" stopOpacity=".07" />
          <stop offset=".847" stopOpacity=".764" />
          <stop offset=".947" />
        </linearGradient>
        <path
          d="M176.9 203.3l-7.1-.1c.2-26.9 22.2-48.6 49.1-48.4 13.7.1 26.8 6 36 16.2l-5.3 4.7c-15.3-17.1-41.7-18.6-58.8-3.3-8.7 8-13.8 19.2-13.9 30.9z"
          fill="url(#E)"
        />
      </g>
    </mask>
    <linearGradient
      id="F"
      gradientUnits="userSpaceOnUse"
      x1="234.367"
      y1="65.613"
      x2="120.164"
      y2="259.491"
    >
      <stop offset="0" stopColor="#50ffd2" />
      <stop offset="1" stopColor="#0064ff" />
    </linearGradient>
    <path
      d="M79.3 86.2h188.1v166.2H79.3z"
      mask="url(#B)"
      transform="matrix(.340245 0 0 .320686 -26.981393 -22.29207)"
      fill="url(#F)"
    />
  </svg>
));

IbmIcon.propTypes = {
  size: PropTypes.number,
};

IbmIcon.defaultProps = {
  size: 24,
};

export { IbmIcon };
