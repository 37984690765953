import styled from 'styled-components';

import Link from '@mui/material/Link';

const GlobalLink = styled(Link)`
  text-decoration: none !important;
  color: ${({ theme: { Palette } }) => Palette.blueHover};

  &:hover {
    text-decoration: none;
    color: ${({ theme: { Palette } }) => Palette.blue};
  }
`;

export default GlobalLink;
