import PropTypes from '+prop-types';
import { useCallback } from 'react';

import MenuColumnPropertiesTrayIcon from 'mdi-react/ChartAreasplineIcon';

import * as PropertiesTray from '@/models/PropertiesTray';

import useUIProperty from '+hooks/useUIProperty';

import MenuColumnButton from './MenuColumnButton';

const MenuColumnPropertiesTrayTrigger = (props) => {
  const { title, dataType, recordType, value, additionalMenuOptions } = props;

  const [hideNav] = useUIProperty('hideNav');

  const [propertiesTray, setPropertiesTray] = useUIProperty(
    'propertiesTray',
    null,
  );
  const [, setGlobalContextMenu] = useUIProperty('globalContextMenu', null);

  const isActive = propertiesTray?.isOpen
    && propertiesTray?.data?.[0]?.dataType === PropertiesTray.DataTypes.record
    && propertiesTray?.data?.[0]?.recordType === recordType
    && propertiesTray?.data?.[0]?.value?.id === value?.id;

  const onClick = useCallback(
    () => {
      setPropertiesTray((prevValue) => {
        if (isActive) {
          return {
            ...prevValue,
            isOpen: false,
          };
        }
        return {
          data: [
            {
              title,
              dataType,
              recordType,
              value,
              customer: value?.customer,
              additionalMenuOptions,
            },
          ],
          isOpen: true,
        };
      });
    },
    [isActive, title, dataType, recordType, value, additionalMenuOptions],
  );

  const onContextMenu = useCallback(
    (e) => {
      setGlobalContextMenu({
        data: [
          {
            title,
            dataType,
            recordType,
            value,
            customer: value?.customer,
            additionalMenuOptions,
          },
        ],
        event: e,
      });
    },
    [title, dataType, recordType, value, additionalMenuOptions],
  );

  return (
    <MenuColumnButton
      className={isActive ? 'active' : ''}
      disabled={hideNav}
      onClick={hideNav ? null : onClick}
      onContextMenu={hideNav ? null : onContextMenu}
      data-tracking={`property-tray-trigger-${String(recordType)
        .toLowerCase()
        .replace(/\s+/g, '-')}`}
    >
      <MenuColumnPropertiesTrayIcon size={16} />
    </MenuColumnButton>
  );
};

MenuColumnPropertiesTrayTrigger.propTypes = {
  title: PropTypes.string,
  dataType: PropTypes.string.isRequired,
  recordType: PropTypes.string.isRequired,
  value: PropTypes.object.isRequired,
  additionalMenuOptions: PropTypes.arrayOf(PropTypes.shape({})),
};

MenuColumnPropertiesTrayTrigger.defaultProps = {
  title: null,
  additionalMenuOptions: null,
};

export { MenuColumnPropertiesTrayIcon };
export default MenuColumnPropertiesTrayTrigger;
