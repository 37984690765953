import PropTypes from '+prop-types';
import { Fragment, useMemo } from 'react';

import Description from './components/Description';
import Error from './components/Error';
import FieldContainer from './components/FieldContainer';
import Group from './components/Group';
import Label from './components/Label';

const FormField = (props) => {
  const {
    className,
    label,
    helperText,
    error,
    invalid,
    disabled,
    required,
    children,
    errorPositionOver,
  } = props;

  const Container = useMemo(
    () => (label || helperText ? FieldContainer : Fragment),
    [label, helperText],
  );

  return (
    <Group className={className}>
      {label && (
        <Label invalid={invalid} disabled={disabled} required={required}>
          {label}
        </Label>
      )}
      <Container>
        {children}
        {invalid && (
          <Error $errorOver={!helperText && errorPositionOver}>{error}</Error>
        )}
      </Container>
      {helperText && <Description>{helperText}</Description>}
    </Group>
  );
};

FormField.propTypes = {
  className: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.func,
    PropTypes.element,
  ]),
  helperText: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.func,
    PropTypes.element,
  ]),
  error: PropTypes.string,
  invalid: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.func,
    PropTypes.element,
  ]).isRequired,
  errorPositionOver: PropTypes.bool,
};

FormField.defaultProps = {
  className: undefined,
  label: null,
  helperText: null,
  error: null,
  invalid: false,
  disabled: false,
  required: false,
  errorPositionOver: false,
};

export { Group, FieldContainer, Label, Description, Error };

export default FormField;
