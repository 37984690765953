import classNames from 'classnames';
import styled from 'styled-components';

import {
  BaseColumnFactory,
  MenuColumnFactory,
} from '+components/Table/Columns';
import { getRowOriginal } from '+components/Table/Columns/utils';
import Tag from '+components/Tag';

import VpcDetailsContextMenu from './VpcDetailsContextMenu';

const StepCell = styled(({ className, status, step, description }) => (
  <div className={classNames(className, { 'no-status': !status })}>
    <span className="step-cell__name" title={description}>
      {step}
    </span>
  </div>
))`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-width: 100%;
  white-space: normal;

  &.no-status {
    opacity: 0.4;
  }

  .step-cell__name {
    color: ${(p) => p.theme.colorTextSecondary} !important;
  }
`;

const SecondaryTextColorCell = styled(({ value }) => (
  <span className="last-polled-cell">{value || ''}</span>
))`
  .last-polled-cell {
    color: ${(p) => p.theme.colorTextSecondary};
  }
`;

const getColumns = ({ toggleModal, setDetailsMessage }) => {
  return [
    BaseColumnFactory({
      accessor: 'lastPolled',
      Header: 'Last Polled',
      maxWidth: 200,
      // eslint-disable-next-line react/prop-types
      Cell: ({ value }) => <SecondaryTextColorCell value={value || ''} />,
    }),
    BaseColumnFactory({
      id: 'step',
      Header: 'Step',
      maxWidth: 400,
      // eslint-disable-next-line react/prop-types
      Cell: ({ row }) => {
        const original = getRowOriginal(row);
        return <StepCell {...original} />;
      },
    }),
    BaseColumnFactory({
      accessor: 'status',
      Header: 'Status',
      maxWidth: 150,
      // eslint-disable-next-line react/prop-types
      Cell: ({ value }) => {
        let color;
        switch (value) {
          case 'pass':
            color = 'success';
            break;
          case 'fail':
            color = 'danger';
            break;
          case 'no flow':
          case 'no messages':
            color = 'warning';
            break;
          default:
            break;
        }
        return value ? (
          <Tag color={color} outlined={false}>
            {value}
          </Tag>
        ) : (
          ''
        );
      },
    }),
    BaseColumnFactory({
      accessor: 'error',
      Header: 'Message',
      // eslint-disable-next-line react/prop-types
      Cell: ({ value }) => <SecondaryTextColorCell value={value || ''} />,
    }),
    MenuColumnFactory({
      cxActionMenu: (id, item) => (
        <VpcDetailsContextMenu
          item={item}
          onDetails={() => {
            setDetailsMessage(item.error);
            toggleModal();
          }}
        />
      ),
    }),
  ];
};

export default getColumns;
