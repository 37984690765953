import styled from 'styled-components';

export default styled.div`
  width: 100%;
  height: 60%;
  min-height: 80px;
  max-height: 320px;
  background-color: transparent;
  background-image: url(${({ image }) => image});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-bottom: 10px;
`;
