import PropTypes from '+prop-types';
import { useEffect, useMemo, useState } from 'react';

import styled from 'styled-components';

import { Field, useForm, useFormState } from '+components/form/FinalForm';
import {
  Group,
  FieldContainer,
  Label,
  Description,
} from '+components/form/FormField';
import MultiSelectField from '+components/form/MultiSelectField';
import {
  normalizeMultiSelectValue,
  normalizeSelectValue,
} from '+components/form/Normalizers';
import SelectField from '+components/form/SelectField';
import { validateLabels, validateRequired } from '+components/form/Validators';
import { Row, Col } from '+components/Layout';

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.cardBackgroundColor};
  padding: 10px 20px 20px;
  border-radius: 3px;
  margin-top: 10px;
`;

const RenderContextLabels = (props) => {
  const { fields, label, helperText, required, labelContextOptions, disabled } = props;

  const form = useForm();
  const { values: formValues } = useFormState({
    subscription: { values: true },
  });

  const [showSrcipFields, setShowSrcipFields] = useState(false);
  const [showDstipFields, setShowDstipFields] = useState(false);

  useEffect(
    () => {
      let included = !!formValues.track_by?.[0]?.includes('srcip');
      setShowSrcipFields(included);
      if (!included) {
        form.batch(() => {
          form.change('srcipContext', null);
          form.change('srcipLabels', null);
        });
      }

      included = !!formValues.track_by?.[0]?.includes('dstip');
      setShowDstipFields(included);
      if (!included) {
        form.batch(() => {
          form.change('dstipContext', null);
          form.change('dstipLabels', null);
        });
      }
    },
    [formValues],
  );

  const isSrcipFieldsRequired = !!formValues.srcipContext?.length || !!formValues.srcipLabels?.length;
  const isDstipFieldsRequired = !!formValues.dstipContext?.length || !!formValues.dstipLabels?.length;
  const isRequired = required || isSrcipFieldsRequired || isDstipFieldsRequired;

  const renderFields = useMemo(
    () => {
      const arr = [];
      if (showSrcipFields) {
        arr.push({
          title: 'srcip',
          context: 'srcipContext',
          labels: 'srcipLabels',
          required: isSrcipFieldsRequired,
        });
      }
      if (showDstipFields) {
        arr.push({
          title: 'dstip',
          context: 'dstipContext',
          labels: 'dstipLabels',
          required: isDstipFieldsRequired,
        });
      }
      return arr;
    },
    [
      showSrcipFields,
      showDstipFields,
      isSrcipFieldsRequired,
      isDstipFieldsRequired,
    ],
  );

  return (
    <Group id={fields.name}>
      {label && <Label required={isRequired}>{label}</Label>}

      {renderFields.map((item) => (
        <FieldContainer key={item.title}>
          <Wrapper>
            <Row>
              <Col>
                <h3>{item.title}</h3>
              </Col>
            </Row>
            <Row>
              <Col>Context{item.required ? '*' : ''}</Col>
            </Row>
            <Row alignItems="flex-start" wrap="nowrap">
              <Col>
                <Field
                  name={item.context}
                  placeholder="+ Add context"
                  options={labelContextOptions}
                  component={SelectField}
                  parse={normalizeSelectValue}
                  allowCreate
                  freeSolo
                  disabled={disabled}
                  validate={item.required && validateRequired}
                  required={item.required}
                />
              </Col>
            </Row>
            <Row>
              <Col>Labels{item.required ? '*' : ''}</Col>
            </Row>
            <Row>
              <Col>
                <Field
                  name={item.labels}
                  placeholder="+ Add label"
                  component={MultiSelectField}
                  parse={normalizeMultiSelectValue}
                  validate={[item.required && validateRequired, validateLabels]}
                  allowCreate
                  disabled={disabled}
                  required={item.required}
                />
              </Col>
            </Row>
          </Wrapper>
        </FieldContainer>
      ))}

      {!showDstipFields && !showSrcipFields && (
        <FieldContainer>
          <Col gap="15px">
            No &quot;Track By&quot; selected, please choose one to continue
            adding labels.
          </Col>
        </FieldContainer>
      )}

      {helperText && <Description>{helperText}</Description>}
    </Group>
  );
};

RenderContextLabels.propTypes = {
  fields: PropTypes.shape().isRequired,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  helperText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  required: PropTypes.bool,
  labelContextOptions: PropTypes.arrayOf(PropTypes.shape({})),
  disabled: PropTypes.bool,
};

RenderContextLabels.defaultProps = {
  label: '',
  helperText: '',
  required: false,
  labelContextOptions: [],
  disabled: false,
};

export default RenderContextLabels;
