const warnPortList = [
  {
    port: 22,
    protocol: 'tcp',
  },
  {
    port: 22,
    protocol: 'udp',
  },
  {
    port: 115,
    protocol: 'tcp',
  },
  {
    port: 115,
    protocol: 'udp',
  },
  {
    port: 137,
    protocol: 'tcp',
  },
  {
    port: 137,
    protocol: 'udp',
  },
  {
    port: 138,
    protocol: 'tcp',
  },
  {
    port: 138,
    protocol: 'udp',
  },
  {
    port: 139,
    protocol: 'tcp',
  },
  {
    port: 139,
    protocol: 'udp',
  },
  {
    port: 161,
    protocol: 'tcp',
  },
  {
    port: 161,
    protocol: 'udp',
  },
  {
    port: 162,
    protocol: 'tcp',
  },
  {
    port: 162,
    protocol: 'udp',
  },
  {
    port: 194,
    protocol: 'tcp',
  },
  {
    port: 194,
    protocol: 'udp',
  },
  {
    port: 830,
    protocol: 'tcp',
  },
  {
    port: 830,
    protocol: 'udp',
  },
  {
    port: 831,
    protocol: 'tcp',
  },
  {
    port: 831,
    protocol: 'udp',
  },
  {
    port: 832,
    protocol: 'tcp',
  },
  {
    port: 832,
    protocol: 'udp',
  },
  {
    port: 833,
    protocol: 'tcp',
  },
  {
    port: 833,
    protocol: 'udp',
  },
  {
    port: 873,
    protocol: 'tcp',
  },
  {
    port: 873,
    protocol: 'udp',
  },
];

const criticalPortList = [
  {
    port: 19,
    protocol: 'tcp',
  },
  {
    port: 19,
    protocol: 'udp',
  },
  {
    port: 20,
    protocol: 'tcp',
  },
  {
    port: 20,
    protocol: 'udp',
  },
  {
    port: 21,
    protocol: 'tcp',
  },
  {
    port: 21,
    protocol: 'udp',
  },
  {
    port: 23,
    protocol: 'tcp',
  },
  {
    port: 23,
    protocol: 'udp',
  },
  {
    port: 25,
    protocol: 'tcp',
  },
  {
    port: 25,
    protocol: 'udp',
  },
  {
    port: 69,
    protocol: 'tcp',
  },
  {
    port: 69,
    protocol: 'udp',
  },
  {
    port: 110,
    protocol: 'tcp',
  },
  {
    port: 110,
    protocol: 'udp',
  },
  {
    port: 512,
    protocol: 'tcp',
  },
  {
    port: 513,
    protocol: 'tcp',
  },
];

const _warnPortMap = {};
const _criticalPortMap = {};

warnPortList.forEach((entry) => {
  _warnPortMap[`${entry.port}:${entry.protocol}`] = true;
});

criticalPortList.forEach((entry) => {
  _criticalPortMap[`${entry.port}:${entry.protocol}`] = true;
});

export const warnPortMap = _warnPortMap;
export const criticalPortMap = _criticalPortMap;
