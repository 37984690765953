import { useEffect, useState } from 'react';

const useOnScreen = (ref, rootMargin = '0px') => {
  const [isOnScreen, setIsOnScreen] = useState(false);
  const [isPrintMode, setIsPrintMode] = useState(false);

  useEffect(
    () => {
      if (!ref.current) {
        return undefined;
      }

      const observer = new IntersectionObserver(
        ([entry]) => {
          setIsOnScreen(entry.isIntersecting);
        },
        { rootMargin },
      );

      observer.observe(ref.current);

      return () => {
        observer.disconnect();
      };
    },
    [rootMargin],
  );

  useEffect(
    () => {
      if (!ref.current) {
        return undefined;
      }

      // on print mode
      const printModeOn = () => {
        setIsPrintMode(true);
      };

      const printModeOff = () => {
        setIsPrintMode(false);
      };

      window.addEventListener('beforeChartPrint', printModeOn);
      window.addEventListener('afterChartPrint', printModeOff);

      return () => {
        window.removeEventListener('beforeChartPrint', printModeOn);
        window.removeEventListener('afterChartPrint', printModeOff);
      };
    },
    [],
  );

  return isPrintMode || isOnScreen;
};

export default useOnScreen;
