import { createRoot } from 'react-dom/client';

import 'core-js/stable';
import Highcharts from 'highcharts';
import * as PIXI from 'pixi.js-legacy';
import 'regenerator-runtime/runtime';

// import './customConsole';
import App from '@/pages/App';

// some highcharts libraries expect access to Highcharts on window
window.Highcharts = Highcharts;

PIXI.utils.skipHello();

// https://ahooks.js.org/guide/blog/hmr/
// https://github.com/reactwg/react-18/discussions/21
// https://reactrouter.com/docs/en/v6/upgrading/v5

const root = createRoot(document.getElementById('app'));

root.render(<App />);
