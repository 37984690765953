import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
  // background-color: ${({ theme }) => theme.noDataPageImageBackground};
`;

const NewTabButton = () => {
  return <Container>New Tab</Container>;
};

export default NewTabButton;
