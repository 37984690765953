import PropTypes from '+prop-types';
import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useField } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useMouse, useToggle } from 'react-use';

import { useFlag } from '@unleash/proxy-client-react';
import classNames from 'classnames';
import isEqual from 'lodash.isequal';

import ArrowLeftBottomIcon from 'mdi-react/ArrowLeftBottomIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import FilterIcon from 'mdi-react/FilterIcon';
import InformationOutlineIcon from 'mdi-react/InformationOutlineIcon';

import { ContextTypes, ContextTypesLabels } from '@/models/ContextTypes';
import { CustomType } from '@/models/CustomType';
import { DateTimeModes } from '@/models/DateTimeModes';
import { DateTimeSpans } from '@/models/DateTimeSpans';
import FeatureFlags from '@/models/FeatureFlags';
import SettingCategories from '@/models/SettingCategories';
import { TimeDuration, TimePeriods } from '@/models/TimePeriods';

import {
  actions as customerActions,
  selectors as customerSelectors,
} from '@/redux/api/customer';
import {
  actions as ipLabelsActions,
  selectors as ipLabelsSelectors,
} from '@/redux/api/labels/ips';
import { selectors as profileSelectors } from '@/redux/api/user/profile';
import {
  actions as globalFiltersActions,
  calcActualDateTimeMode,
  calcFromAndToByPeriod,
} from '@/redux/globalFilters';
import { selectors as globalFiltersUISelectors } from '@/redux/globalFilters/ui';

import { DateTimePickerViewTypes } from '+components/form/DateTimePicker';
import { Field, useForm, useFormState } from '+components/form/FinalForm';
import {
  normalizeDateTimeToNumber,
  normalizeMultiSelectValue,
  normalizeSelectValue,
} from '+components/form/Normalizers';
import Toggle from '+components/form/Toggle';
import {
  validateDateTime,
  validateDateTimeBetween,
  validateDateTimeCannotBeSame,
  validateMinMaxValue,
  validateRequired,
} from '+components/form/Validators';
import {
  AgoSelect,
  DateTimePickerField,
  NumberField,
} from '+components/GlobalFilters/Components';
import Context from '+components/GlobalFilters/Context';
import IconButton from '+components/IconButton';
import Tooltip from '+components/Tooltip';
import { useVerifyNqlBeforeSend } from '+hooks';
import { useAllMetricsAndFields } from '+hooks/useAllMetricsAndFields';
import useGlobalFilters from '+hooks/useGlobalFilters';
import usePortalSettingsValue from '+hooks/usePortalSettingsValue';
import useUIProperty from '+hooks/useUIProperty';
import dayjs from '+utils/dayjs';
import getMetricFieldName from '+utils/getMetricFieldName';
import getNqlFieldName from '+utils/getNqlFieldName';

import AdditionalFiltersButton from './AdditionalFiltersButton';
import AdditionalFiltersDropdownField, {
  AdditionalFiltersDropdownCaptureContainer,
  AdditionalFiltersDropdownCaptureLabel,
  AdditionalFiltersDropdownCaptureValue,
} from './AdditionalFiltersDropdownField';
import AdditionalFiltersMultiDropdownField, {
  AdditionalFiltersMultiDropdownCaptureContainer,
  AdditionalFiltersMultiDropdownCaptureLabel,
  AdditionalFiltersMultiDropdownCaptureValue,
  AdditionalFiltersMultiDropdownShowMore,
} from './AdditionalFiltersMultiDropdownField';
import AdditionalFiltersRow from './AdditionalFiltersRow';
import AdditionalFiltersRowItem from './AdditionalFiltersRowItem';
import AdditionalFiltersSeparator from './AdditionalFiltersSeparator';
import ApplyButton from './ApplyButton';
import ContextDropdownField from './ContextDropdownField';
import DateTimeDropdown, {
  DateTimeMenuContainer,
  DateTimeMenuDoneButton,
  DateTimeMenuLabel,
  DateTimeMenuRow,
} from './DateTimeDropdown';
import FakePresetsButton from './FakePresetsButton';
import FormContainer from './FormContainer';
import MainNqlFilter, {
  NqlButtonsContainer,
  NQLFieldClear,
  NqlFieldError,
  NQLFieldLabel,
} from './MainNqlFilter';
import MainRow from './MainRow';
import MainRowGroup from './MainRowGroup';
import MainRowNqlGroup from './MainRowNqlGroup';
import MainRowSection from './MainRowSection';
import MainRowSeparator from './MainRowSeparator';
import SocketControl from './SocketControl';
import { uiToParams } from './utils';

const contextLabel = 'Filter By';
const labelContextsLabel = 'Display Labels';
const metricLabel = 'Metric';
const subAccountLabel = 'Filter by sub-accounts';

const parserPeriodValue = (input) => input.replace(/[^\d]/g, '');
const parserNql = (v) => v;
const isEqualNql = (a, b) => (a || '') === (b || '');
const isEqualSubAccounts = (a, b) => isEqual([...(a || [])].sort(), [...(b || [])].sort());

const getContextOptions = (excludeContexts, dnsEnabled) => {
  const isExcluded = new Set(excludeContexts || []);

  const tempOptions = [
    dnsEnabled && {
      value: ContextTypes.traffic,
      label: ContextTypesLabels[ContextTypes.traffic],
      disabled: isExcluded.has(ContextTypes.traffic),
    },
    {
      value: ContextTypes.flow,
      label: ContextTypesLabels[ContextTypes.flow],
      disabled: isExcluded.has(ContextTypes.flow),
    },
    dnsEnabled && {
      value: ContextTypes.dns,
      label: ContextTypesLabels[ContextTypes.dns],
      disabled: isExcluded.has(ContextTypes.dns),
    },
    {
      value: ContextTypes.alerts,
      label: ContextTypesLabels[ContextTypes.alerts],
      disabled: isExcluded.has(ContextTypes.alerts),
    },
    {
      value: ContextTypes.blocks,
      label: ContextTypesLabels[ContextTypes.blocks],
      disabled: isExcluded.has(ContextTypes.blocks),
    },
  ].filter(Boolean);

  return tempOptions;
};

const FormBody = (props) => {
  const { initialValues, handleSubmit, $separatorWidth } = props;
  const isDnsEnabled = useFlag('DNS');

  const { portalChildren: additionalPageFilters } = useContext(Context);

  const dispatch = useDispatch();
  const location = useLocation();

  const form = useForm();
  const { pristine, values: formValues } = useFormState({
    subscription: {
      pristine: true,
      values: true,
    },
  });

  const currentContext = formValues.context || ContextTypes.flow;

  const { meta: nqlFieldMeta } = useField(
    `${getNqlFieldName(currentContext)}[0]`,
  );
  const nqlFieldError = useMemo(
    () => {
      const errorMessage = nqlFieldMeta.error || nqlFieldMeta.submitError;
      const touched = nqlFieldMeta.touched && (nqlFieldMeta.dirty || nqlFieldMeta.submitFailed);
      const invalid = errorMessage && (errorMessage !== 'Required' || touched);
      return invalid ? errorMessage : null;
    },
    [nqlFieldMeta],
  );

  const [guest] = useUIProperty('guest');
  const [filters] = useGlobalFilters();

  const ui = useSelector(globalFiltersUISelectors.getAllState);
  const customer = useSelector(customerSelectors.getCurrentCustomer);
  const retention = useSelector(customerSelectors.getRetention);

  const isRolesUiSettingsEnabled = useFlag(FeatureFlags.rolesUiSettings);
  const profile = useSelector(profileSelectors.getProfile);
  const [userRoleUiSettings] = usePortalSettingsValue(
    SettingCategories.ui,
    `${profile?.roles?.[0]}:settings`,
    {},
  );

  const submitButtonRef = useRef();

  const nqlGroupRowRef = useRef(null);
  const nqlGroupRowMouse = useMouse(nqlGroupRowRef);
  const isNqlGroupRowHigher = nqlGroupRowMouse.elH > 36; // 36px is min-height of MainRowNqlGroup
  const isNqlGroupRowHoveredByMouse = nqlGroupRowMouse.elX >= 0
    && nqlGroupRowMouse.elX <= nqlGroupRowMouse.elW
    && nqlGroupRowMouse.elY >= 0
    && nqlGroupRowMouse.elY <= nqlGroupRowMouse.elH
    && nqlGroupRowMouse.elW > 0
    && nqlGroupRowMouse.elH > 0;

  const [isNqlGroupRowMouseEnter, setIsNqlGroupRowMouseEnter] = useState(false);
  const [isNqlFieldActive, setIsNqlFieldActive] = useState(false);
  const [isContextFieldActive, setIsContextFieldActive] = useState(false);
  const [isDateTimeFieldActive, setIsDateTimeFieldActive] = useState(false);
  const [isPeriodTypeMenuOpen, setIsPeriodTypeMenuOpen] = useState(false);
  const [isAdditionalFiltersExpanded, setIsAdditionalFiltersExpanded] = useToggle(true);

  const { validateNql } = useVerifyNqlBeforeSend(
    formValues.context,
    `${getNqlFieldName(currentContext)}[0]`,
  );

  const {
    from: dateFrom,
    to: dateTo,
    startIsMin,
    endIsNow,
    realtime,
  } = formValues;

  const isCustomType = formValues.period?.type === CustomType;
  const realtimeMode = filters.dateTimeMode === DateTimeModes.realtime;
  const isRealtime = realtimeMode && realtime;

  const uiDisabled = {
    periodValue: ui.onlyRealtime || !(ui.range && !isCustomType && !isRealtime),
    periodType: ui.onlyRealtime || !(ui.range && !isRealtime),
    from: ui.onlyRealtime || !(ui.from && isCustomType && !isRealtime),
    to: ui.onlyRealtime || !(ui.to && isCustomType && !isRealtime),
    nql: !ui.nql,
    metric: !ui.metric,
    customers: !ui.customers,
    socketControl: !ui.socketControl,
  };

  const contextOptions = useMemo(
    () => [
      {
        value: contextLabel,
        label: contextLabel,
        header: true,
      },
      ...getContextOptions(ui.excludeContexts, isDnsEnabled),
    ],
    [ui.excludeContexts, isDnsEnabled],
  );

  // Default Global Filter to Flow when DNS feature flag is disabled
  useEffect(
    () => {
      if (!isDnsEnabled && formValues.context === ContextTypes.dns) {
        dispatch(
          globalFiltersActions.changeFilter({ context: ContextTypes.flow }),
        );
      }
    },
    [isDnsEnabled],
  );

  const { allMetrics } = useAllMetricsAndFields(formValues.context, {
    metrics: true,
  });

  const metricsOptions = useMemo(
    () => {
      const options = (allMetrics[formValues.context] || []).map((el) => ({
        value: el.metric || el.field,
        label: el.metric || el.field,
        description: el.description,
        disabled: (ui.excludeMetrics || []).includes(el.metric),
      }));
      options.unshift({
        value: metricLabel,
        label: metricLabel,
        header: true,
      });
      return options;
    },
    [allMetrics, formValues.context, ui.excludeMetrics],
  );

  const periodOptions = useMemo(
    () => {
      const allowedPeriods = [
        TimeDuration.minute,
        TimeDuration.hour,
        TimeDuration.day,
        TimeDuration.week,
        TimeDuration.month,
      ];
      return [
        {
          value: CustomType,
          label: 'Custom Range',
        },
        ...allowedPeriods.map((value) => ({
          value,
          label: TimePeriods[value].name,
        })),
      ];
    },
    [],
  );

  // TODO: where we are set/use this dateTimeSpan?
  const viewTypes = useMemo(
    () => (ui.dateTimeSpan === DateTimeSpans.minute
      ? [
        DateTimePickerViewTypes.day,
        DateTimePickerViewTypes.hours,
        DateTimePickerViewTypes.minutes,
      ]
      : undefined),
    [ui.dateTimeSpan],
  );

  const isIpLabelsFetching = useSelector(ipLabelsSelectors.isFetching);
  const labelContexts = useSelector(ipLabelsSelectors.getContexts);
  const subAccountLabelContexts = useSelector(
    ipLabelsSelectors.getSubAccountsContexts(filters?.customers),
  );

  useEffect(
    () => {
      if (isIpLabelsFetching) {
        return;
      }
      if (!labelContexts?.length) {
        dispatch(ipLabelsActions.fetchContexts());
      }
    },
    [isIpLabelsFetching, labelContexts?.length],
  );

  useEffect(
    () => {
      if (isIpLabelsFetching) {
        return;
      }
      const emptySubAccountsContexts = filters?.customers?.filter(
        (item) => !subAccountLabelContexts?.[item]?.length,
      );
      if (emptySubAccountsContexts?.length) {
        dispatch(
          ipLabelsActions.fetchSubAccountsContexts(emptySubAccountsContexts),
        );
      }
    },
    [isIpLabelsFetching, filters?.customers, subAccountLabelContexts],
  );

  const labelContextsOptions = useMemo(
    () => {
      const contexts = filters?.customers?.length
        ? Object.values(subAccountLabelContexts || {}).flat()
        : labelContexts;

      const optionsHash = (contexts || []).flat().reduce((acc, item) => {
        if (item.alias || item.value === 'name') {
          return acc;
        }
        let record = acc[item.value];
        if (!record) {
          record = { value: item.value, label: item.value, customers: [] };
        }
        if (item.customer && !record.customers.includes(item.customer)) {
          record.customers.push(item.customer);
        }
        acc[item.value] = record;
        return acc;
      }, {});

      const options = Object.values(optionsHash)
        .map((item) => ({
          ...item,
          description: item.customers?.sort().join(', '),
        }))
        .sort((a, b) => a.value.localeCompare(b.value));

      options.unshift(
        ...[
          { value: labelContextsLabel, label: labelContextsLabel, header: true },
          { value: false, label: 'None' },
          { separator: true },
          { value: 'name', label: 'name' },
        ],
      );

      return options;
    },
    [labelContexts, subAccountLabelContexts, filters?.customers?.length],
  );

  const customers = useSelector(customerSelectors.getCustomers);
  const areAllCustomersFetched = useSelector(customerSelectors.areAllFetched);
  useEffect(
    () => {
      if (!customer?.isReseller || areAllCustomersFetched) {
        return undefined;
      }
      const namespace = 'fetch_customers';
      dispatch(customerActions.fetch(customer?.shortname, namespace));
      return () => {
        dispatch(customerActions.cancel(namespace));
      };
    },
    [customer?.isReseller, areAllCustomersFetched, customer?.shortname],
  );
  const subAccountOptions = useMemo(
    () => {
      if (!customer?.isReseller) {
        return [];
      }
      const options = Object.values(customers || {})
        .reduce((acc, item) => {
          const canAggSubAccountData = customer?.shortname === 'netography'
          || (customer?.shortname === item.parent
            && item.meta.reseller_data_agg_enabled);
          const haveSameRetention = customer?.retention === item.retention
          && customer?.rollupRetention === item.rollupRetention;
          if (!canAggSubAccountData || !haveSameRetention) {
            return acc;
          }
          return [
            ...acc,
            {
              value: item.shortname,
              label: item.shortname,
              description: item.organization,
            },
          ];
        }, [])
        .sort((a, b) => a.label.localeCompare(b.label));
      options.unshift({
        value: customer?.shortname,
        label: `${customer?.shortname} (current)`,
        description: customer?.organization,
      });
      options.unshift({
        value: subAccountLabel,
        label: subAccountLabel,
        header: true,
      });
      return options;
    },
    [customers, customer],
  );

  const dateTimeLimit = useMemo(
    () => {
      if (isRolesUiSettingsEnabled && userRoleUiSettings?.dateTimeLimit) {
        return Math.min(userRoleUiSettings.dateTimeLimit, retention);
      }
      return retention;
    },
    [retention, isRolesUiSettingsEnabled, userRoleUiSettings?.dateTimeLimit],
  );

  const minPeriodValue = 1;

  const maxPeriodValue = useMemo(
    () => {
      if (formValues.dateTime?.periodType === CustomType) {
        return undefined;
      }
      return Math.floor(
        (dateTimeLimit * TimeDuration.day) / (formValues.period?.type || 1),
      );
    },
    [dateTimeLimit, formValues.period?.type],
  );

  const min = useMemo(
    () => (formValues.context === ContextTypes.audit
      ? new Date(2019, 0, 1, 0, 0, 0, 0)
      : dayjs(Date.now() - TimeDuration.day * dateTimeLimit)
        .millisecond(0)
        .toDate()),
    [dateTimeLimit, formValues],
  );

  const max = useMemo(
    () => dayjs().millisecond(0),
    [formValues],
  );

  const periodTypeValidator = useMemo(
    () => {
      if (uiDisabled.periodValue) {
        return undefined;
      }
      return [
        validateRequired,
        validateMinMaxValue({
          min: 1,
          max: maxPeriodValue,
          fieldName: 'period.value',
        }),
      ];
    },
    [maxPeriodValue, uiDisabled.periodValue, formValues.period?.type],
  );

  const fromDateValidate = useMemo(
    () => (uiDisabled.from || startIsMin
      ? null
      : [
        validateRequired,
        validateDateTime,
        !endIsNow
              && validateDateTimeCannotBeSame({
                fieldName: 'to',
                errorMessage: 'From date and To date cannot be the same.',
              }),
        !endIsNow
              && validateDateTimeBetween({
                min,
                maxFieldName: 'to',
                includeMin: true,
                includeMax: false,
              }),
      ]),
    [uiDisabled.from, min, endIsNow, startIsMin],
  );

  const toDateValidate = useMemo(
    () => (uiDisabled.to || endIsNow
      ? null
      : [
        validateRequired,
        validateDateTime,
        !startIsMin
              && validateDateTimeCannotBeSame({
                fieldName: 'from',
                errorMessage: ' ',
              }),
        !startIsMin
              && validateDateTimeBetween({
                minFieldName: 'from',
                max,
                includeMin: false,
                includeMax: true,
              }),
      ]),
    // To prevent form validation looping do not put 'max' to deps (validator will update if form values changed)
    // @see: https://netography.atlassian.net/browse/PORTAL-1415
    [uiDisabled.to, formValues, startIsMin],
  );

  const onNqlClear = useCallback(
    () => {
      form.change(`${getNqlFieldName(currentContext)}`, ['']);
      form.mutators.touched(`${getNqlFieldName(currentContext)}`, true);
    },
    [currentContext],
  );

  const onMinToggle = useCallback(
    (isMin) => {
      form.change('startIsMin', isMin);
    },
    [form],
  );

  const onNowToggle = useCallback(
    (isNow) => {
      form.change('endIsNow', isNow);
    },
    [form],
  );

  const doSubmit = useCallback(
    () => {
      if (!pristine) {
        submitButtonRef.current?.click();
      }
    },
    [pristine],
  );

  const onNqlKeyPress = useCallback(
    (event) => {
      if (event?.key === 'Enter') {
        document.activeElement.blur();
        doSubmit();
        document.activeElement.focus();
      }
    },
    [doSubmit],
  );

  const onPeriodValueEnterPress = useCallback(
    (event) => {
      if (event?.key === 'Enter') {
        doSubmit();
      }
    },
    [doSubmit],
  );

  const onPeriodTypeKeyDown = useCallback(
    (event) => {
      if (event?.key === 'Enter' && !isPeriodTypeMenuOpen) {
        doSubmit();
      }
    },
    [isPeriodTypeMenuOpen, doSubmit],
  );

  const onPeriodTypeMenuOpen = useCallback(
    () => setIsPeriodTypeMenuOpen(true),
    [],
  );

  const onPeriodTypeMenuClose = useCallback(
    () => setIsPeriodTypeMenuOpen(false),
    [],
  );

  useEffect(
    () => {
      const checkCaretPosition = () => {
        setIsNqlFieldActive(
          nqlGroupRowRef.current?.contains(document.activeElement),
        );
      };

      document.addEventListener('click', checkCaretPosition);
      return () => {
        document.removeEventListener('click', checkCaretPosition);
      };
    },
    [],
  );

  // set label context every time when ipLabelContext changes
  useEffect(
    () => {
      if (formValues.ipLabelContext === initialValues.ipLabelContext) {
        return;
      }
      const currentValues = uiToParams(formValues);
      dispatch(globalFiltersActions.setLabelContext(currentValues.labelContext));
    },
    [formValues, initialValues],
  );

  // update filter context
  useEffect(
    () => {
      if (formValues.context === initialValues.context) {
        return;
      }
      dispatch(
        globalFiltersActions.changeFilter({ context: formValues.context }),
      );
    },
    [formValues.context, initialValues.context],
  );

  useEffect(
    () => {
      setIsAdditionalFiltersExpanded(true);
    },
    [location.pathname],
  );

  useEffect(
    () => {
    // in case initial values are bigger than max period value
      if (
        initialValues.period?.type !== CustomType
      && initialValues.period?.value > maxPeriodValue
      ) {
        dispatch(
          globalFiltersActions.changeFilter({
            period: {
              ...formValues.period,
              value: maxPeriodValue,
            },
          }),
        );
      }
    },
    [initialValues.period, maxPeriodValue],
  );

  useEffect(
    () => {
      if (uiDisabled.periodValue) {
        return;
      }
      if (formValues.period?.type === CustomType) {
        return;
      }
      if (formValues.period?.value < minPeriodValue) {
        form.change('period.value', minPeriodValue);
        return;
      }
      if (formValues.period?.value > maxPeriodValue) {
        form.change('period.value', maxPeriodValue);
      }
    },
    [
      uiDisabled.periodValue,
      formValues.period?.type,
      formValues.period?.value,
      minPeriodValue,
      maxPeriodValue,
    ],
  );

  const prevPeriod = useRef(null);
  useEffect(
    () => {
      if (!formValues.period) {
        prevPeriod.current = formValues.period;
        return;
      }
      if (formValues.period.type === CustomType) {
        prevPeriod.current = formValues.period;
        return;
      }
      if (!prevPeriod.current) {
        prevPeriod.current = formValues.period;
      }
      if (
        formValues.period.type !== prevPeriod.current.type
      || formValues.period.value !== prevPeriod.current.value
      ) {
        const { from, to } = calcFromAndToByPeriod(formValues.period);
        form.change('from', from);
        form.change('to', to);
        form.change('startIsMin', false);
        form.change('endIsNow', false);
        prevPeriod.current = formValues.period;
      }
    },
    [formValues.period],
  );

  useEffect(
    () => {
      form.change('actualDateTimeMode', calcActualDateTimeMode(formValues));
    },
    [formValues],
  );

  const isSubAccountsFieldAvailable = !!customer?.isReseller && !guest;
  const isNqlGroupActive = ((isNqlGroupRowMouseEnter && isNqlGroupRowHoveredByMouse)
      || isContextFieldActive
      || isNqlFieldActive)
    && !isDateTimeFieldActive;

  return (
    <FormContainer id="global-filters" onSubmit={handleSubmit}>
      <MainRow $separatorWidth={$separatorWidth}>
        <MainRowSection>
          <MainRowGroup>
            <FakePresetsButton disabled={uiDisabled.nql} />
          </MainRowGroup>

          <MainRowNqlGroup
            ref={nqlGroupRowRef}
            className={classNames({
              invalid: !!nqlFieldError,
              active: isNqlGroupActive,
              shadow: isNqlGroupRowHigher,
              disabled: uiDisabled.nql,
            })}
            onMouseEnter={() => setIsNqlGroupRowMouseEnter(true)}
            onMouseLeave={() => setIsNqlGroupRowMouseEnter(false)}
          >
            <Field
              className={formValues.context}
              name="context"
              component={ContextDropdownField}
              options={contextOptions}
              validate={validateRequired}
              isEqual={() => true}
              onOpenOrClose={setIsContextFieldActive}
              disabled={uiDisabled.nql}
              showIcon={false}
              required
              data-tracking="traffic-type"
            />

            <NQLFieldLabel>NQL</NQLFieldLabel>

            <Field
              name={`${getNqlFieldName(currentContext)}[0]`}
              component={MainNqlFilter}
              presetsLeftOffset="3px"
              presetsWidthExtension={105}
              context={currentContext}
              validate={validateNql}
              disabled={uiDisabled.nql}
              parse={parserNql}
              isEqual={isEqualNql}
              onEnterPress={onNqlKeyPress}
              data-tracking="nql-form"
            />

            <NqlButtonsContainer>
              {!!formValues[getNqlFieldName(currentContext)]?.[0]
                && isNqlGroupActive && (
                <NQLFieldClear>
                  <IconButton
                    size="small"
                    title="Clear NQL"
                    onClick={onNqlClear}
                    data-tracking="gf-clear-nql"
                  >
                    <CloseIcon size={16} />
                  </IconButton>
                </NQLFieldClear>
              )}

              {!!nqlFieldError && (
                <Tooltip title={nqlFieldError} arrow={false}>
                  <NqlFieldError>
                    <InformationOutlineIcon />
                  </NqlFieldError>
                </Tooltip>
              )}
            </NqlButtonsContainer>
          </MainRowNqlGroup>

          <MainRowSeparator $width={$separatorWidth} $visible />

          <Fragment>
            {/* We need those hidden fields to have them registered on the form even if DateTimeMenu is hidden */}
            <Field name="startIsMin" component={() => null} />
            <Field name="endIsNow" component={() => null} />
            <Field name="realtime" component={() => null} />
            <Field name="period.value" component={() => null} />
            <Field name="period.type" component={() => null} />
            <Field name="from" component={() => null} />
            <Field name="to" component={() => null} />

            <DateTimeDropdown
              className={classNames({ active: isDateTimeFieldActive })}
              values={formValues}
              disabled={
                (!realtimeMode
                  && uiDisabled.periodValue
                  && uiDisabled.periodType
                  && uiDisabled.from
                  && uiDisabled.to)
                || ui.onlyRealtime
              }
              onOpenOrClose={setIsDateTimeFieldActive}
              data-tracking="date-time-dropdown"
            >
              <DateTimeMenuContainer>
                {realtimeMode && (
                  <DateTimeMenuRow>
                    <DateTimeMenuLabel />

                    <Field
                      name="realtime"
                      component={Toggle}
                      type="checkbox"
                      uncheckedLabel="Real-Time"
                      checkedLabel="Custom"
                      flipped={ui.onlyRealtime ? filters.realtime : true}
                      disabled={ui.onlyRealtime}
                      twoOptionToggle
                    />
                  </DateTimeMenuRow>
                )}

                <DateTimeMenuRow>
                  <DateTimeMenuLabel>Date & Time:</DateTimeMenuLabel>

                  <Field
                    className="period-value"
                    component={NumberField}
                    name="period.value"
                    step={1}
                    min={minPeriodValue}
                    max={maxPeriodValue}
                    precision={0}
                    validate={periodTypeValidator}
                    parser={parserPeriodValue}
                    onKeyUp={onPeriodValueEnterPress}
                    tabIndex={uiDisabled.periodValue ? '-1' : null}
                    disabled={uiDisabled.periodValue}
                  />

                  <Field
                    className="period-type"
                    component={AgoSelect}
                    name="period.type"
                    options={periodOptions}
                    onOpen={onPeriodTypeMenuOpen}
                    onClose={onPeriodTypeMenuClose}
                    onKeyDown={onPeriodTypeKeyDown}
                    disabled={uiDisabled.periodType}
                    parse={normalizeSelectValue}
                  />
                </DateTimeMenuRow>

                <DateTimeMenuRow>
                  <DateTimeMenuLabel>From:</DateTimeMenuLabel>

                  <Field
                    className="from"
                    component={DateTimePickerField}
                    name="from"
                    min={min}
                    max={
                      +(endIsNow
                        ? max.subtract(1, 'minute')
                        : dateTo ?? max.subtract(1, 'minute'))
                    }
                    disabled={uiDisabled.from}
                    validate={fromDateValidate}
                    parse={normalizeDateTimeToNumber}
                    errorPositionOver
                    views={viewTypes}
                    minButton
                    onMinToggle={onMinToggle}
                    isMin={startIsMin}
                  />
                </DateTimeMenuRow>

                <DateTimeMenuRow>
                  <DateTimeMenuLabel>To:</DateTimeMenuLabel>

                  <Field
                    className="to"
                    component={DateTimePickerField}
                    name="to"
                    min={startIsMin ? min : dateFrom ?? min}
                    max={+max}
                    disabled={uiDisabled.to}
                    validate={toDateValidate}
                    parse={normalizeDateTimeToNumber}
                    errorPositionOver
                    views={viewTypes}
                    nowButton
                    onNowToggle={onNowToggle}
                    isNow={endIsNow}
                  />
                </DateTimeMenuRow>

                <DateTimeMenuDoneButton />
              </DateTimeMenuContainer>
            </DateTimeDropdown>
          </Fragment>

          <MainRowGroup>
            <ApplyButton
              ref={submitButtonRef}
              type="submit"
              data-tracking="apply-filters"
              disabled={pristine}
            >
              <ArrowLeftBottomIcon />
            </ApplyButton>
          </MainRowGroup>

          <MainRowGroup>
            <SocketControl disabled={uiDisabled.socketControl} />
          </MainRowGroup>
        </MainRowSection>

        <AdditionalFiltersButton
          $expanded={isAdditionalFiltersExpanded}
          onClick={setIsAdditionalFiltersExpanded}
          data-tracking="filters-row-hide-show"
        >
          <FilterIcon />
        </AdditionalFiltersButton>
      </MainRow>

      <AdditionalFiltersRow
        $expanded={isAdditionalFiltersExpanded}
        $separatorWidth={$separatorWidth}
      >
        <AdditionalFiltersRowItem>
          <Field
            component={AdditionalFiltersDropdownField}
            name="ipLabelContext"
            options={labelContextsOptions}
            parse={normalizeSelectValue}
            caption={(
              <AdditionalFiltersDropdownCaptureContainer>
                <AdditionalFiltersDropdownCaptureLabel>
                  {labelContextsLabel}
                </AdditionalFiltersDropdownCaptureLabel>
                <AdditionalFiltersDropdownCaptureValue>
                  {formValues.ipLabelContext || 'None'}
                </AdditionalFiltersDropdownCaptureValue>
              </AdditionalFiltersDropdownCaptureContainer>
            )}
            isEqual={() => true}
            data-tracking="filter-row-display-labels"
          />
        </AdditionalFiltersRowItem>

        <AdditionalFiltersSeparator $width={$separatorWidth} />

        {!uiDisabled.metric && (
          <Fragment>
            <AdditionalFiltersRowItem>
              <Field
                component={AdditionalFiltersDropdownField}
                name={getMetricFieldName(currentContext)}
                options={metricsOptions}
                parse={normalizeSelectValue}
                caption={(
                  <AdditionalFiltersDropdownCaptureContainer>
                    <AdditionalFiltersDropdownCaptureLabel>
                      {metricLabel}
                    </AdditionalFiltersDropdownCaptureLabel>
                    <AdditionalFiltersDropdownCaptureValue>
                      {formValues[getMetricFieldName(currentContext)]}
                    </AdditionalFiltersDropdownCaptureValue>
                  </AdditionalFiltersDropdownCaptureContainer>
                )}
                disabled={uiDisabled.metric}
                data-tracking="filter-row-metric"
              />
            </AdditionalFiltersRowItem>

            <AdditionalFiltersSeparator $width={$separatorWidth} />
          </Fragment>
        )}

        {isSubAccountsFieldAvailable && !uiDisabled.customers && (
          <Fragment>
            <AdditionalFiltersRowItem>
              <Field
                name="customers"
                component={AdditionalFiltersMultiDropdownField}
                options={subAccountOptions}
                parse={normalizeMultiSelectValue}
                caption={(
                  <AdditionalFiltersMultiDropdownCaptureContainer>
                    <AdditionalFiltersMultiDropdownCaptureLabel>
                      {subAccountLabel}
                    </AdditionalFiltersMultiDropdownCaptureLabel>
                    <AdditionalFiltersMultiDropdownCaptureValue>
                      {formValues.customers?.[0] || 'None'}
                      {formValues.customers?.length > 1 && (
                        <AdditionalFiltersMultiDropdownShowMore>
                          +{formValues.customers?.length - 1} more
                        </AdditionalFiltersMultiDropdownShowMore>
                      )}
                    </AdditionalFiltersMultiDropdownCaptureValue>
                  </AdditionalFiltersMultiDropdownCaptureContainer>
                )}
                disabled={uiDisabled.customers}
                isEqual={isEqualSubAccounts}
                data-tracking="filter-row-sub-account"
              />
            </AdditionalFiltersRowItem>

            <AdditionalFiltersSeparator $width={$separatorWidth} />
          </Fragment>
        )}

        {additionalPageFilters?.map((item) => (
          <Fragment key={item.id}>{item.content}</Fragment>
        ))}
      </AdditionalFiltersRow>
    </FormContainer>
  );
};

FormBody.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    ipLabelContext: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    context: PropTypes.string,
    period: PropTypes.shape({
      type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.number,
    }),
  }).isRequired,
  $separatorWidth: PropTypes.number.isRequired,
};

export default FormBody;
