import PropTypes from '+prop-types';

import { Field } from '+components/form/FinalForm';
import TextField from '+components/form/TextField';
import { validateRequired } from '+components/form/Validators';

const RenderTrackBy = ({ fields, initialValues }) => {
  return fields.map((trackBy, i) => {
    const trackByKey = fields?.value?.[i]?.trackby;

    return (
      <Field
        key={trackByKey}
        component={TextField}
        name={`${trackBy}.value`}
        label={trackByKey}
        validate={validateRequired}
        required
        initialValue={initialValues?.[trackByKey]}
      />
    );
  });
};

RenderTrackBy.propTypes = {
  fields: PropTypes.shape().isRequired,
  initialValues: PropTypes.shape(),
};

RenderTrackBy.defaultProps = {
  initialValues: {},
};

export default RenderTrackBy;
