import PropTypes from '+prop-types';
import { Fragment } from 'react';

import styled from 'styled-components';

import Box from '@mui/material/Box';

import CopyTextOrigin from '+components/CopyText';
import Field from '+components/form/FinalForm/Field';
import RoleAuthFieldsSection from '+components/form/RoleAuthFieldSection';
import TextField from '+components/form/TextField';
import { validateRequired } from '+components/form/Validators';

const CopyText = styled(CopyTextOrigin)`
  .copy-to-clipboard__button {
    margin-left: 6px;
  }
`;

const GcpPubsubFields = ({ disabled, isDnsContext }) => (
  <Fragment>
    <Field
      name="projectid"
      label="Project ID"
      component={TextField}
      type="text"
      validate={validateRequired}
      helperText="Your Google Cloud project ID"
      disabled={disabled}
      required
    />

    <Field
      name="subid"
      label="Subscription ID"
      component={TextField}
      type="text"
      validate={validateRequired}
      helperText="Your Google Cloud pub/sub subscription ID"
      disabled={disabled}
      required
    />

    <Field
      name="samplepercent"
      label="Sample Percentage"
      component={TextField}
      type="number"
      min={0}
      max={100}
      style={{ width: '200px' }}
      validate={validateRequired}
      helperText={`Your Google Cloud ${
        isDnsContext ? 'query' : 'flow'
      } log sampling percentage`}
      disabled={disabled}
      required
    />
    <RoleAuthFieldsSection label="Authentication">
      <Box className="card__title" ml="140px">
        <h5 className="head">Netography Service Account</h5>
        <h5 className="subhead">
          <CopyText text="sa-cloud@netography.iam.gserviceaccount.com">
            sa-cloud@netography.iam.gserviceaccount.com
          </CopyText>
        </h5>
      </Box>
    </RoleAuthFieldsSection>
  </Fragment>
);

GcpPubsubFields.propTypes = {
  disabled: PropTypes.bool,
  isDnsContext: PropTypes.bool,
};

GcpPubsubFields.defaultProps = {
  disabled: false,
  isDnsContext: false,
};

export default GcpPubsubFields;
