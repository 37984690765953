import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectorsMap } from './sagasMap';

export const useGetContextRecord = (context, id) => {
  const selectors = selectorsMap[context];

  const selector = useMemo(
    () => selectors?.getRecord?.(id) || (() => null),
    [selectors, id],
  );

  return useSelector(selector);
};
