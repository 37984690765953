import styled from 'styled-components';

export default styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  visibility: ${({ $isOnScreen }) => ($isOnScreen ? 'visible' : 'hidden')};
  opacity: ${({ $isOnScreen }) => ($isOnScreen ? 1 : 0)};
  border-radius: 4px;
  overflow: hidden;
`;
