import * as PIXI from 'pixi.js-legacy';

import {
  circleTexture,
  particleTexture,
  createLabelsGraphic,
} from '+utils/pixijsUtils';

export const addNodeFactory = (app) => function (node) {
  if (app.isDestroyed) {
    return;
  }

  const graphic = new PIXI.Container();

  graphic.cursor = 'pointer';
  graphic.on('pointerover', app._onNodeOver(this));
  graphic.on('pointerout', app._onNodeOut(this));
  graphic.on('pointermove', app._onNodeMove(this));
  graphic.on('click', app._onNodeClick(this));
  graphic.on('rightclick', app._onNodeRightClick(this));
  graphic.on('pointerdown', app._onNodeDragStart);

  let circle = new PIXI.Sprite(circleTexture('#fff', 128));
  circle.anchor.set(0.5, 0.5);

  circle.name = 'circle';
  graphic.addChild(circle);

  circle = new PIXI.Sprite(circleTexture('#fff', 128));
  circle.anchor.set(0.5, 0.5);

  circle.name = 'point';
  graphic.addChild(circle);

  graphic.alpha = 0;

  graphic.interactive = true;

  graphic.name = node.id;

  this.graphic = graphic;

  app._nodesGroup.addChild(graphic);
};

class AnchoredContainer extends PIXI.Container {
  constructor() {
    super();
    this._anchor = new PIXI.ObservablePoint(() => {
      this.updateAnchor();
    }, this);
  }

  get anchor() {
    return this._anchor;
  }

  set anchor(value) {
    if (typeof value === 'number') {
      this._anchor.set(value);
    } else {
      this._anchor.copyFrom(value);
    }
  }

  set width(value) {
    super.width = value;
    this.updateAnchor();
  }

  get width() {
    return super.width;
  }

  set height(value) {
    super.height = value;
    this.updateAnchor();
  }

  get height() {
    return super.height;
  }

  updateAnchor() {
    this.pivot.x = (this.anchor.x * this.width) / this.scale.x;
    this.pivot.y = (this.anchor.y * this.height) / this.scale.y;
  }
}

export const addTitleFactory = (app) => function (node) {
  if (app.isDestroyed) {
    return;
  }

  const graphic = new AnchoredContainer();

  graphic.cursor = 'pointer';
  graphic.on('pointerover', app._onNodeOver(this));
  graphic.on('pointerout', app._onNodeOut(this));
  graphic.on('pointermove', app._onNodeMove(this));
  graphic.on('click', app._onNodeClick(this));
  graphic.on('rightclick', app._onNodeRightClick(this));
  graphic.on('pointerdown', app._onNodeDragStart);

  const labels = createLabelsGraphic(
    null, // getLabelContext(node),
    null, // getLabel(node),
    null, // getLabelCount(node),
    {
      ...app._textStyle,
      fill: '#fff',
    },
    30,
  );
  labels.name = 'labels';
  labels.visible = false; // getShowLabel(node);
  // labels.x = 2;

  const labelsCount = labels.getChildByName('labelCountContainer');

  labelsCount.on('pointerover', app._onLabelCountOver(this));
  labelsCount.on('pointerout', app._onLabelCountOut(this));
  labelsCount.on('click', app._onLabelCountClick(this));
  labelsCount.interactive = true;

  const title = createLabelsGraphic(
    null,
    null, // getText(node),
    null,
    {
      ...app._textStyle,
      fill: '#fff',
    },
    30,
  );
  title.name = 'title';
  title.visible = false; // !labels.visible;
  // title.x = 2;

  // const backgroundWidth = (labels.visible ? labels.width : title.width) + 4;
  // const background = new PIXI.Sprite(
  //   roundedRectTexture('#fff', backgroundWidth, 20, 2),
  // );
  // background.name = 'background';
  // background.anchor.set(0, 0.5);
  // background.alpha = 0;

  // graphic.addChild(background);
  graphic.addChild(title);
  graphic.addChild(labels);

  graphic.alpha = 0;
  graphic.interactive = true;
  graphic.name = node.id;
  graphic.anchor.set(0.5, 0);

  this.graphic = graphic;

  app._titlesGroup.addChild(graphic);
};

export const addLinkFactory = (app) => function () {
  if (app.isDestroyed) {
    return;
  }

  this.graphic = app._linksInactive;
};

export const addParticleFactory = (app) => function () {
  if (app.isDestroyed) {
    return;
  }

  const graphic = new PIXI.Sprite(particleTexture('#fff', 128));
  graphic.anchor.set(0.5, 0.5);
  graphic.blendMode = PIXI.BLEND_MODES.ADD;

  app._particlesGroup.addChild(graphic);

  this.graphic = graphic;
};

export const removeItemFactory = (collection) => function () {
  if (this.graphic) {
    const parent = this.graphic.parent || collection;
    parent?.removeChild(this.graphic);
  }
};
