import PropTypes from '+prop-types';
import { Fragment, useMemo } from 'react';

import get from 'lodash.get';

import { Col } from '+components/Layout';
import { UniversalCell } from '+components/Table/Cells';
import useGlobalFilters from '+hooks/useGlobalFilters';

import {
  BodyGroupCol,
  BodyGroupTitleRow,
  BodyItemRow,
  BodyNameCol,
  BodySeparator,
  BodyValueCol,
} from '../../BodyComponents';

const DnsOverview = ({ padding, record }) => {
  const [filters] = useGlobalFilters();

  const row = useMemo(
    () => ({ original: record }),
    [record],
  );

  const srcIpLabelFieldName = `label.ip.${filters.labelContext.ip}.src`;
  const srcPortLabelFieldName = `label.port.${filters.labelContext.port}.src`;

  return (
    <Col gap="14px">
      <BodyGroupCol paddingLeft={padding} paddingRight={padding}>
        <BodyItemRow>
          <BodyNameCol>Type:</BodyNameCol>
          <BodyValueCol>
            {UniversalCell('type')({
              row,
              value: record.type,
              options: { disabled: true },
            })}
          </BodyValueCol>
        </BodyItemRow>

        <BodyItemRow>
          <BodyNameCol>Protocol:</BodyNameCol>
          <BodyValueCol>
            {UniversalCell('protocol')({
              row,
              value: record.protocol,
              options: { disabled: true },
            })}
          </BodyValueCol>
        </BodyItemRow>

        <BodyItemRow>
          <BodyNameCol>Version:</BodyNameCol>
          <BodyValueCol>
            {UniversalCell('version')({
              row,
              value: record.version,
              options: { disabled: true },
            })}
          </BodyValueCol>
        </BodyItemRow>

        <BodyItemRow>
          <BodyNameCol>Data Src:</BodyNameCol>
          <BodyValueCol>
            {UniversalCell('datasrc')({
              row,
              value: record.datasrc,
              options: { disabled: true },
            })}
          </BodyValueCol>
        </BodyItemRow>

        {record.provider && (
          <BodyItemRow>
            <BodyNameCol>Provider:</BodyNameCol>
            <BodyValueCol>
              {UniversalCell('providername')({
                row,
                value: record.providername,
                options: { disabled: true },
              })}
            </BodyValueCol>
          </BodyItemRow>
        )}

        <BodyItemRow>
          <BodyNameCol>RCode:</BodyNameCol>
          <BodyValueCol>
            {UniversalCell('rcode')({
              row,
              value: record.rcode,
              options: { disabled: true },
            })}
          </BodyValueCol>
        </BodyItemRow>
      </BodyGroupCol>

      <BodySeparator />

      <BodyGroupCol paddingLeft={padding} paddingRight={padding}>
        <BodyGroupTitleRow>Time</BodyGroupTitleRow>

        <BodyItemRow>
          <BodyNameCol>Timestamp:</BodyNameCol>
          <BodyValueCol>
            {UniversalCell('timestamp')({
              row,
              value: record.timestamp,
              options: { disabled: true },
            })}
          </BodyValueCol>
        </BodyItemRow>

        <BodyItemRow>
          <BodyNameCol>RTime:</BodyNameCol>
          <BodyValueCol>
            {UniversalCell('rtime')({
              row,
              value: record.rtime,
              options: { disabled: true },
            })}
          </BodyValueCol>
        </BodyItemRow>
      </BodyGroupCol>

      <BodySeparator />

      <BodyGroupCol paddingLeft={padding} paddingRight={padding}>
        <BodyGroupTitleRow>Traffic</BodyGroupTitleRow>

        <BodyItemRow>
          <BodyNameCol>Source:</BodyNameCol>
          <BodyValueCol>
            {!filters.labelContext.show
            || !get(record, srcIpLabelFieldName)?.length
              ? UniversalCell('srcip')({
                row,
                value: record.srcip,
                options: { showAsLabel: true, disabled: true },
              })
              : UniversalCell(srcIpLabelFieldName)({
                row,
                value: get(record, srcIpLabelFieldName),
                options: {
                  useDataValueInPropertiesTray: true,
                  disabled: true,
                },
              })}
          </BodyValueCol>
        </BodyItemRow>

        <BodyItemRow>
          <BodyNameCol>SRC Port:</BodyNameCol>
          <BodyValueCol>
            {!filters.labelContext.show
            || !get(record, srcPortLabelFieldName)?.length
              ? UniversalCell('srcport')({
                row,
                value: record.srcport,
                options: { showAsLabel: true, disabled: true },
              })
              : UniversalCell(srcPortLabelFieldName)({
                row,
                value: get(record, srcPortLabelFieldName),
                options: {
                  useDataValueInPropertiesTray: true,
                  disabled: true,
                },
              })}
          </BodyValueCol>
        </BodyItemRow>

        <BodyItemRow>
          <BodyNameCol>Site:</BodyNameCol>
          <BodyValueCol>
            {UniversalCell('site')({
              row,
              value: record.site,
              options: { disabled: true },
            })}
          </BodyValueCol>
        </BodyItemRow>
      </BodyGroupCol>

      <BodySeparator />

      <BodyGroupCol paddingLeft={padding} paddingRight={padding}>
        <BodyGroupTitleRow>Query</BodyGroupTitleRow>

        <BodyItemRow>
          <BodyNameCol>Name:</BodyNameCol>
          <BodyValueCol>
            {UniversalCell('query.name')({
              row,
              value: record.query?.name,
              options: { disabled: true },
            })}
          </BodyValueCol>
        </BodyItemRow>

        <BodyItemRow>
          <BodyNameCol>Type:</BodyNameCol>
          <BodyValueCol>
            {UniversalCell('query.type')({
              row,
              value: record.query?.type,
              options: { disabled: true },
            })}
          </BodyValueCol>
        </BodyItemRow>

        <BodyItemRow>
          <BodyNameCol>Host:</BodyNameCol>
          <BodyValueCol>
            {UniversalCell('query.host')({
              row,
              value: record.query?.host,
              options: { disabled: true },
            })}
          </BodyValueCol>
        </BodyItemRow>

        <BodyItemRow>
          <BodyNameCol>Domain:</BodyNameCol>
          <BodyValueCol>
            {UniversalCell('query.domain')({
              row,
              value: record.query?.domain,
              options: { disabled: true },
            })}
          </BodyValueCol>
        </BodyItemRow>

        <BodyItemRow>
          <BodyNameCol>Depth:</BodyNameCol>
          <BodyValueCol>
            {UniversalCell('query.depth')({
              row,
              value: record.query?.depth,
              options: { disabled: true },
            })}
          </BodyValueCol>
        </BodyItemRow>

        <BodyItemRow>
          <BodyNameCol>Class:</BodyNameCol>
          <BodyValueCol>
            {UniversalCell('query.class')({
              row,
              value: record.query?.class,
              options: { disabled: true },
            })}
          </BodyValueCol>
        </BodyItemRow>
      </BodyGroupCol>

      <BodySeparator />

      <BodyGroupCol paddingLeft={padding} paddingRight={padding}>
        <BodyGroupTitleRow>Answers</BodyGroupTitleRow>

        <BodyItemRow>
          <BodyNameCol>Answer Count:</BodyNameCol>
          <BodyValueCol>
            {UniversalCell('answercount')({
              row,
              value: record.answercount,
              options: { disabled: true },
            })}
          </BodyValueCol>
        </BodyItemRow>

        {record.answers?.map((answer, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={index}>
            <BodySeparator />

            <BodyItemRow>
              <BodyNameCol>Type:</BodyNameCol>
              <BodyValueCol>
                {UniversalCell('answers.type')({
                  row,
                  value: answer.type,
                  options: { disabled: true },
                })}
              </BodyValueCol>
            </BodyItemRow>

            <BodyItemRow>
              <BodyNameCol>Class:</BodyNameCol>
              <BodyValueCol>
                {UniversalCell('answers.class')({
                  row,
                  value: answer.class,
                  options: { disabled: true },
                })}
              </BodyValueCol>
            </BodyItemRow>

            <BodyItemRow>
              <BodyNameCol>RData:</BodyNameCol>
              <BodyValueCol>
                {UniversalCell('answers.rdata')({
                  row,
                  value: answer.rdata,
                  options: { showAsLabel: true, disabled: true },
                })}
              </BodyValueCol>
            </BodyItemRow>
          </Fragment>
        ))}
      </BodyGroupCol>
    </Col>
  );
};

DnsOverview.propTypes = {
  padding: PropTypes.string.isRequired,
  record: PropTypes.shape().isRequired,
};

export default DnsOverview;
