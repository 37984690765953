import PropTypes from '+prop-types';
import { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  actions as dashboardsActions,
  selectors as dashboardsSelectors,
} from '@/redux/api/dashboards';

import { Field } from '+components/form/FinalForm';
import { normalizeSelectValue } from '+components/form/Normalizers';
import SelectField from '+components/form/SelectField';
import { validateRequired } from '+components/form/Validators';
import FormModal from '+components/FormModal';

const FormBody = () => {
  const dispatch = useDispatch();

  const isAllMetaFetched = useSelector(dashboardsSelectors.isAllMetaFetched);
  const dashboardsMeta = useSelector(dashboardsSelectors.getDashboardsMeta);

  const groupsOptions = useMemo(
    () => {
      const groups = Object.values(dashboardsMeta || {}).flatMap(
        (item) => item.groups || [],
      );
      return [...new Set(groups)].sort().map((item) => ({
        value: item,
        label: item,
      }));
    },
    [dashboardsMeta],
  );

  useEffect(
    () => {
      if (!isAllMetaFetched) {
        dispatch(dashboardsActions.fetchDashboardsMeta());
      }
    },
    [isAllMetaFetched],
  );

  return (
    <Field
      name="nextGroupName"
      label="Collection"
      component={SelectField}
      options={groupsOptions}
      parse={normalizeSelectValue}
      validate={validateRequired}
      freeSolo
      required
    />
  );
};

const RenameGroupForm = (props) => {
  const { initialValues, isOpen, onConfirm, onToggle } = props;

  return (
    <FormModal
      mode="edit"
      titleTemplate="Rename dashboard collection"
      confirmButtonText="Save"
      initialValues={initialValues}
      isOpen={isOpen}
      onSubmit={onConfirm}
      onToggle={onToggle}
      focusOnFields={false}
      labelOnTop
    >
      <FormBody />
    </FormModal>
  );
};

RenameGroupForm.propTypes = {
  initialValues: PropTypes.shape({}),
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
};

RenameGroupForm.defaultProps = {
  initialValues: {},
};

export default RenameGroupForm;
