import PropTypes from '+prop-types';
import { Fragment, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SettingCategories from '@/models/SettingCategories';

import {
  actions as dashboardsActions,
  selectors as dashboardsSelectors,
} from '@/redux/api/dashboards';

import {
  MessageAction,
  MessageContainer,
  MessageItem,
} from '+components/ConfirmModal';
import { Field } from '+components/form/FinalForm';
import SelectField from '+components/form/SelectField';
import { validateRequired } from '+components/form/Validators';
import FormModal from '+components/FormModal';
import usePortalSettingsValue from '+hooks/usePortalSettingsValue';

import {
  makeOptions,
  formatter,
  parser,
  groups,
} from '../utils/dashboardOptions';

const currentDashboardStr = 'Current Dashboard';

const WidgetCloneForm = (props) => {
  const {
    currentWidgetId,
    currentDashboardId,
    item,
    isOpen,
    isDefaultCustomer,
    onConfirm,
    onToggle,
  } = props;

  const dispatch = useDispatch();

  const isAllMetaFetched = useSelector(dashboardsSelectors.isAllMetaFetched);
  const dashboardsMeta = useSelector(dashboardsSelectors.getDashboardsMeta);

  const [favIds] = usePortalSettingsValue(
    SettingCategories.dashboard,
    'favorites',
    [],
  );
  const [recent] = usePortalSettingsValue(
    SettingCategories.dashboard,
    'recents',
    [],
  );

  const {
    recentDashboards,
    favoriteDashboards,
    customDashboards,
    systemDashboards,
  } = useMemo(
    () => {
      const favoriteSet = new Set(favIds);
      const recentSet = new Set(recent.map(({ id }) => id));
      return Object.values(dashboardsMeta || {}).reduce(
        (acc, el) => {
          if (recentSet.has(el.id)) {
            const lastseen = recent.find(({ id }) => id === el.id)?.timestamp;
            acc.recentDashboards.push({ ...el, lastseen });
          }

          if (favoriteSet.has(el.id)) {
            acc.favoriteDashboards.push(el);
          }

          if (el.system) {
            acc.systemDashboards.push(el);
          } else {
            acc.customDashboards.push(el);
          }

          return acc;
        },
        {
          recentDashboards: [],
          favoriteDashboards: [],
          systemDashboards: [],
          customDashboards: [],
        },
      );
    },
    [dashboardsMeta, favIds, recent],
  );

  const copyToOptions = useMemo(
    () => [
      ...makeOptions(
        recentDashboards,
        groups.recent,
        currentDashboardId,
        isDefaultCustomer,
      ),
      ...makeOptions(
        favoriteDashboards,
        groups.favorites,
        currentDashboardId,
        isDefaultCustomer,
      ),
      ...(!isDefaultCustomer
        ? []
        : makeOptions(
          systemDashboards,
          groups.system,
          currentDashboardId,
          isDefaultCustomer,
        )),
      ...makeOptions(
        customDashboards,
        groups.custom,
        currentDashboardId,
        isDefaultCustomer,
      ),
    ],
    [
      recentDashboards,
      favoriteDashboards,
      systemDashboards,
      customDashboards,
      currentDashboardId,
      isDefaultCustomer,
    ],
  );

  const copyToFormatter = useCallback(
    (value) => formatter(value, copyToOptions),
    [copyToOptions],
  );

  const copyToParser = useCallback(
    (value) => parser(value),
    [],
  );

  useEffect(
    () => {
      if (!isAllMetaFetched) {
        dispatch(dashboardsActions.fetchDashboardsMeta());
      }
    },
    [isAllMetaFetched],
  );

  const showCopyToList = copyToOptions.length > 1;

  return (
    <FormModal
      mode="copy"
      confirmButtonText="Copy"
      item={item}
      initialValues={{
        id: currentWidgetId,
        copyTo: currentDashboardId,
      }}
      isOpen={isOpen}
      onSubmit={onConfirm}
      onToggle={onToggle}
      focusOnFields={false}
      labelOnTop
    >
      <MessageContainer>
        <span>Do you really want to</span>
        <MessageAction>copy</MessageAction>
        <MessageItem>{item}</MessageItem>
        {showCopyToList ? (
          <span>?</span>
        ) : (
          <Fragment>
            <span>&nbsp;</span>
            <span>to {currentDashboardStr}?</span>
          </Fragment>
        )}
      </MessageContainer>

      {showCopyToList && (
        <Field
          name="copyTo"
          label="Copy to"
          component={SelectField}
          options={copyToOptions}
          groupBy={(option) => option.group}
          validate={validateRequired}
          format={copyToFormatter}
          parse={copyToParser}
          required
        />
      )}
    </FormModal>
  );
};

WidgetCloneForm.propTypes = {
  currentWidgetId: PropTypes.string.isRequired,
  currentDashboardId: PropTypes.string.isRequired,
  item: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isDefaultCustomer: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default WidgetCloneForm;
