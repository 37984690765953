import styled, { css } from 'styled-components';

const over = css`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
`;

const Error = styled.span.attrs(() => ({ className: 'form__form-group-error' }))`
  ${({ $errorOver }) => $errorOver && over};
`;

export default Error;
