import { useCallback, useMemo } from 'react';
import {
  useMatch,
  useNavigate,
  useResolvedPath,
  useSearchParams,
} from 'react-router-dom';

import { useFlag } from '@unleash/proxy-client-react';

import { ContextTypes, ContextTypesLabels } from '@/models/ContextTypes';
import { DateTimeModes } from '@/models/DateTimeModes';

import { Breadcrumb } from '+components/Breadcrumb';
import GlobalFiltersSetting from '+components/GlobalFilters/Setting';
import { TabsContainer, Tabs, Tab, TabContent } from '+components/Tabs';

import { BlocksSearch } from './Blocks';
import { DnsSearch } from './Dns';
import { EventsSearch } from './Events';
import { FlowSearch } from './Flow';
import { TrafficSearch } from './Traffic';

const defaultTabs = {
  [ContextTypes.flow]: {
    value: ContextTypes.flow,
    label: ContextTypesLabels[ContextTypes.flow],
    title: 'Search — Flow',
    context: ContextTypes.flow,
    Component: FlowSearch,
  },
  events: {
    value: 'events',
    label: ContextTypesLabels[ContextTypes.alerts],
    title: 'Search — Events',
    context: ContextTypes.alerts,
    Component: EventsSearch,
  },
  [ContextTypes.blocks]: {
    value: ContextTypes.blocks,
    label: ContextTypesLabels[ContextTypes.blocks],
    title: 'Search — Blocks',
    context: ContextTypes.blocks,
    Component: BlocksSearch,
  },
};

const defaultTab = defaultTabs.flow;

const dnsTabs = {
  [ContextTypes.dns]: {
    value: ContextTypes.dns,
    label: ContextTypesLabels[ContextTypes.dns],
    title: 'Search — DNS',
    context: ContextTypes.dns,
    Component: DnsSearch,
  },
  [ContextTypes.traffic]: {
    value: ContextTypes.traffic,
    label: ContextTypesLabels[ContextTypes.traffic],
    title: 'Traffic — Search',
    context: ContextTypes.traffic,
    Component: TrafficSearch,
  },
};

const Search = () => {
  const navigate = useNavigate();
  const { pathname: parentPath } = useResolvedPath('..');
  const { params: { id: tabId } = {} } = useMatch(`${parentPath}/:id`) || {};
  const [searchParams] = useSearchParams();
  const isDnsEnabled = useFlag('DNS');

  const [tabs, tabsOrdered] = useMemo(
    () => [
      {
        ...defaultTabs,
        ...(isDnsEnabled && dnsTabs),
      },
      [
        isDnsEnabled && dnsTabs.traffic,
        defaultTabs.flow,
        isDnsEnabled && dnsTabs.dns,
        defaultTabs.events,
        defaultTabs.blocks,
      ].filter(Boolean),
    ],
    [isDnsEnabled],
  );

  const currentTab = useMemo(
    () => tabs[tabId] || defaultTab,
    [tabs, tabId, defaultTab],
  );

  const onTabChange = useCallback(
    (_, value) => {
      navigate(`${parentPath}/${value}`);
    },
    [navigate],
  );

  const TabComponent = currentTab?.Component;

  const pageTitle = useMemo(
    () => {
      const arr = [currentTab.title];
      if (searchParams.size > 0) {
        const nqlSearch = searchParams.getAll('nql');
        arr.push(nqlSearch.length ? `${nqlSearch.join(' & ')}` : 'all');
      }
      return arr.join(' — ');
    },
    [currentTab.title, searchParams],
  );

  return (
    <TabsContainer>
      <GlobalFiltersSetting
        context={currentTab.context}
        dateTimeMode={DateTimeModes.now}
        range={false}
        from={false}
        to={false}
        nql={false}
        customers={false}
        metric={false}
        socketControl={false}
      />

      <Breadcrumb title={pageTitle} />

      <Tabs value={currentTab.value} onChange={onTabChange}>
        {tabsOrdered.map(({ value, label }) => (
          <Tab key={value} label={label} value={value} />
        ))}
      </Tabs>

      {TabComponent && (
        <TabContent>
          <TabComponent />
        </TabContent>
      )}
    </TabsContainer>
  );
};

export default Search;
