import PropTypes from '+prop-types';
import { useEffect, useRef } from 'react';

import Container from './components/Container';

const resizeEventDispatcher = () => window.dispatchEvent(new Event('resize'));

const SidebarRight = ({ className, children, $width, isOpen }) => {
  const containerRef = useRef(null);

  useEffect(
    () => {
      if (!containerRef.current) {
        return undefined;
      }
      let timer;
      containerRef.current.ontransitionend = () => {
        timer = setTimeout(resizeEventDispatcher, 100);
      };
      return () => {
        clearTimeout(timer);
      };
    },
    [],
  );

  return (
    <Container
      ref={containerRef}
      className={className}
      $isOpen={isOpen}
      $width={$width}
    >
      {children}
    </Container>
  );
};

SidebarRight.propTypes = {
  className: PropTypes.string,
  children: PropTypes.children,
  $width: PropTypes.number.isRequired,
  isOpen: PropTypes.bool,
};

SidebarRight.defaultProps = {
  className: null,
  children: null,
  isOpen: false,
};

export default SidebarRight;
