import PropTypes from '+prop-types';

import styled from 'styled-components';

import { Group, Label } from '+components/form/FormField';
import { Row } from '+components/Layout';

const ToggleFlexRow = styled(Row)`
  width: 100%;
  .form__form-group {
    width: unset;
  }
  gap: 14px;
`;
const ToggleRowLabel = styled(Label)`
  margin-right: 20px !important;
  width: 150px !important;
`;

const ToggleRowGroup = styled(Group)`
  flex-wrap: nowrap !important;
`;

const ToggleRow = ({ children, label }) => {
  return (
    <ToggleRowGroup>
      <ToggleRowLabel>{label}</ToggleRowLabel>
      <ToggleFlexRow>{children}</ToggleFlexRow>
    </ToggleRowGroup>
  );
};

ToggleRow.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
    .isRequired,
  label: PropTypes.string,
};

ToggleRow.defaultProps = {
  label: '',
};

export default ToggleRow;
