import { call, put } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import {
  createSlice,
  startFetching,
  stopFetching,
  defaultReducers,
} from '@/redux/util';

import backendClient from '@/middleware/backendClient';

import { validateIp } from '+components/form/Validators';

import { actions as toastActions } from '../../toast';

export const initialState = {
  isFetching: false,
  error: '',
  ips: {},
};

let api;

const initApi = () => {
  if (!api) {
    api = backendClient();
  }
};

const slice = createSlice({
  name: 'ipintel',
  initialState,

  reducers: {
    ...defaultReducers,
    fetchIpIntel: startFetching,

    success(state, { payload: { data: [ipintel] = [] } = {} }) {
      stopFetching(state);
      const { ip } = ipintel;
      if (ip) {
        state.ips[ip] = ipintel;
      }
    },
  },

  sagas: (actions) => ({
    [actions.fetchIpIntel]: {
      * saga({ payload: ip }) {
        let errorMsg = null;
        if (!ip) {
          errorMsg = 'You must provide an IP Address';
        } else {
          errorMsg = validateIp(ip);
        }

        if (errorMsg) {
          yield put(actions.fail(errorMsg));
          yield put(
            toastActions.error({
              message: 'Error fetching IP Intel',
              details: errorMsg,
            }),
          );
          return;
        }

        initApi();
        try {
          const { data } = yield call(api.get, `/intel/lookup/ip/${ip}`);
          yield put(actions.success(data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching IP Intel',
              details: error.message,
            }),
          );
        }
      },
    },
  }),
});

slice.selectors = {
  ...slice.selectors,

  getIpIntel: (ip) => createSelector(
    [slice.selector],
    (state) => state.ips[ip] || {},
  ),

  isFetching: createSelector(
    [slice.selector],
    (state) => state.isFetching,
  ),
};

// For convenience
export const { actions, selectors } = slice;

export default slice;
