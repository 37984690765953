import { Fragment, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import styled from 'styled-components';

import { selectors as rulesSelectors } from '@/redux/api/rules';
import {
  actions as thresholdActions,
  selectors as thresholdSelectors,
} from '@/redux/api/thresholder';

import TimeseriesChart from '+components/charts/TimeseriesChart';
import Select from '+components/form/Select';
import GlobalFiltersSetting from '+components/GlobalFilters/Setting';
import { ActionsContainer } from '+components/Layout';
import Table from '+components/Table';
import useGlobalFilters from '+hooks/useGlobalFilters';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import dayjs from '+utils/dayjs';

import {
  parseChartMetricFromOperation,
  strategyOptions,
} from '../shared/utils';
import { getColumns } from './components/columns';

const DEFAULT_GROUP = 'default';

const ThresholdValuesChart = styled(TimeseriesChart)`
  margin-bottom: 15px;
`;

const ToolbarItem = styled.div`
  display: flex;
  gap: 1em;
  align-items: center;
  min-width: 20em;
`;

const ToolbarItemTitle = styled.div`
  white-space: nowrap;
`;

const cxActionMenu = () => undefined;
const compare = (a, b) => a[0] - b[0];

const convertThresholdsToSeries = (data) => {
  const thresholdValues = { low: [], medium: [], high: [] };
  // we expect all threshold values to be of the same metric:
  const metric = parseChartMetricFromOperation(data?.[0]?.operation);
  data.forEach((obj) => {
    obj.threshold.forEach((threshold) => {
      thresholdValues[threshold.level].push([
        dayjs(obj.timestamp).valueOf(),
        threshold.value,
      ]);
    });
  });

  return [
    {
      name: 'low',
      metric,
      colorIndex: 3,
      data: thresholdValues.low.sort(compare),
    },
    {
      name: 'medium',
      metric,
      colorIndex: 4,
      data: thresholdValues.medium.sort(compare),
    },
    {
      name: 'high',
      metric,
      colorIndex: 5,
      data: thresholdValues.high.sort(compare),
    },
  ];
};

const AutoThresholds = () => {
  const dispatch = useDispatch();
  const { algorithmId } = useParams();
  const algorithm = useSelector(rulesSelectors.getAlgorithm(algorithmId));
  const thresholdValues = useSelector(
    thresholdSelectors.getThresholdValues(algorithm.name, DEFAULT_GROUP),
  );
  const isFetching = useSelector(thresholdSelectors.isFetching);
  useLoadingIndicator(isFetching);
  const [selectedOperation, setSelectedOperation] = useState(undefined);
  const [filters] = useGlobalFilters();

  useEffect(
    () => {
      if (algorithm?.name) {
        dispatch(
          thresholdActions.fetchValuesByGroup({
            modelName: algorithm.name,
            group: DEFAULT_GROUP,
            to: filters.to,
            from: filters.from,
          }),
        );
      }
    },
    [algorithm, filters],
  );

  const operationOptions = useMemo(
    () => {
      const operations = thresholdValues?.reduce((acc, curr) => {
        if (!acc.includes(curr?.operation)) {
          acc.push(curr.operation);
        }
        return acc;
      }, []);
      const values = operations?.map((opp) => ({
        value: opp?.toLowerCase(),
        label: opp?.toLowerCase(),
      }));
      if (!selectedOperation) {
        setSelectedOperation(values?.[0]);
      }
      return values;
    },
    [thresholdValues],
  );

  const strategyField = useMemo(
    () => {
      if (thresholdValues?.[0]) {
        return Object.keys(strategyOptions).reduce((acc, strategy) => {
          if (strategy in thresholdValues[0]) {
            acc = strategy;
          }
          return acc;
        }, undefined);
      }
      return undefined;
    },
    [thresholdValues],
  );

  const columns = useMemo(
    () => getColumns({ cxActionMenu, strategyField }),
    [cxActionMenu, strategyField],
  );

  const series = useMemo(
    () => (thresholdValues
      ? convertThresholdsToSeries(
        thresholdValues.filter(
          (item) => item?.operation?.toLowerCase() === selectedOperation?.value,
        ),
      )
      : undefined),
    [thresholdValues, selectedOperation, filters],
  );

  return (
    <Fragment>
      <GlobalFiltersSetting to from range />

      <ActionsContainer>
        {operationOptions?.length > 1 && (
          <ToolbarItem>
            <ToolbarItemTitle>Operation:</ToolbarItemTitle>
            <Select
              name="Operation"
              onChange={(value) => setSelectedOperation(value)}
              value={selectedOperation}
              options={operationOptions}
            />
          </ToolbarItem>
        )}
      </ActionsContainer>
      <ThresholdValuesChart
        title="Auto Threshold Values"
        subtitle={selectedOperation?.value}
        series={series}
        type="line"
        overrides={{
          xAxis: { labels: {} },
          yAxis: { labels: {} },
        }}
        loading={isFetching}
        context="flow"
      />
      <Table data={thresholdValues} columns={columns} />
    </Fragment>
  );
};

export default AutoThresholds;
