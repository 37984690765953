import dayjs from '+utils/dayjs';

const dayjsProto = Object.getPrototypeOf(dayjs());

const origin = dayjsProto.format;

const reg24 = /(HH?)(:m+(:s+(.S+)?)?)?/g;

const replace24to12 = (inputString) => {
  const matches = inputString ? [...inputString.matchAll(reg24)] : [];

  if (matches.length < 1) {
    return inputString;
  }

  const newString = [];
  let lastIndex = 0;
  matches.forEach((match, index) => {
    newString.push(inputString.slice(lastIndex, match.index));
    lastIndex = match.index;

    newString.push(match[0].replace(/H/g, 'h'));
    lastIndex += match[0].length;

    newString.push(' A');

    if (!matches[index + 1]) {
      newString.push(inputString.slice(lastIndex, inputString.length));
    }
  });

  return newString.join('');
};

const formatOverride = function (inputString) {
  return origin.call(this, replace24to12(inputString));
};

export default (isTimeFormat12h) => {
  dayjsProto.format = isTimeFormat12h ? formatOverride : origin;
};
