import styled from 'styled-components';

import { TabContent as TabContentOrigin } from '+components/Tabs';
import { ScrollBarMixin } from '+theme/mixins/scrollBarMixin';

const TabContent = styled(TabContentOrigin)`
  ${ScrollBarMixin};
  display: flex;
  min-height: 555px;
  overflow-y: auto;
  margin-top: ${(props) => props.$marginTop};
`;

export default TabContent;
