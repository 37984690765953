import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useToggle } from 'react-use';

import CogPlayOutlineIcon from 'mdi-react/CogPlayOutlineIcon';
import LeadPencilIcon from 'mdi-react/LeadPencilIcon';
import PlayIcon from 'mdi-react/PlayIcon';
import TextBoxIcon from 'mdi-react/TextBoxIcon';
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';

import PermissionModel from '@/models/Permission';
import RoutePaths from '@/models/RoutePaths';

import {
  actions as contextIntegrationsActions,
  selectors as contextIntegrationsSelectors,
} from '@/redux/api/integrations/context';

import Button, { ButtonVariants } from '+components/Button';
import ConfirmModal from '+components/ConfirmModal';
import { PluginLabel } from '+components/Labels/PluginLabel';
import { ActionsContainer, Row } from '+components/Layout';
import Table from '+components/Table';
import { MenuColumnContextMenu } from '+components/Table/Columns';
import useFocusOnRowId from '+hooks/useFocusOnRowId';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import usePermissions from '+hooks/usePermissions';

import useContextIntegrationModels from '../shared/useContextIntegrationModels';
import { Columns, getColumns } from './components/Columns';
import IntegrationTestModal from './components/IntegrationTestModal';

const preparedColumns = Object.values(Columns);

const tableId = 'ContextIntegrations_Table';

const sortBy = [
  {
    id: 'name',
    desc: false,
  },
];

const ContextIntegrations = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [, setRowId] = useFocusOnRowId(tableId);

  const permissions = usePermissions(PermissionModel.Resources.label.value);

  const error = useSelector(contextIntegrationsSelectors.getError);
  const isFetching = useSelector(contextIntegrationsSelectors.isFetching);
  const areAllFetched = useSelector(contextIntegrationsSelectors.areAllFetched);
  const integrations = useSelector(
    contextIntegrationsSelectors.getContextIntegrations,
  );
  const testResults = useSelector(contextIntegrationsSelectors.getTestResults);
  const [contextIntegrationModels, isIntegrationTypeHardcoded] = useContextIntegrationModels();

  const [integration, setIntegration] = useState(null);
  const [showDelete, toggleShowDelete] = useToggle(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isViewingTestResults, setIsViewingTestResults] = useState(false);

  const [selected, setSelected] = useState([]);
  const [showBulkDeleteModal, toggleBulkDeleteModal] = useToggle(false);
  useLoadingIndicator(isFetching);

  const tableData = useMemo(
    () => Object.values(integrations || {}),
    [integrations],
  );

  const onAddClick = useCallback(
    () => {
      navigate(`${RoutePaths.integrationsContext}/add`);
    },
    [],
  );

  const onEditClick = useCallback(
    (item) => {
      navigate(`${RoutePaths.integrationsContext}/edit/${item.id}`);
    },
    [],
  );

  const onLogsClick = useCallback(
    (item) => {
      navigate(`${RoutePaths.integrationsContext}/edit/${item.id}/logs`);
    },
    [],
  );

  const onDeleteConfirm = useCallback(
    () => {
      if (integration?.id) {
        dispatch(
          contextIntegrationsActions.removeContextIntegration({
            isDynamic: !isIntegrationTypeHardcoded(integration.type),
            ...integration,
          }),
        );
        setIsDeleting(true);
      }
    },
    [integration, isIntegrationTypeHardcoded],
  );

  const onToggleEnabled = useCallback(
    (values, enabled) => {
      dispatch(
        contextIntegrationsActions.updateContextIntegration({
          ...values,
          isDynamic: !isIntegrationTypeHardcoded(values.type),
          enabled,
        }),
      );
    },
    [isIntegrationTypeHardcoded],
  );

  const onTest = useCallback(
    (values) => {
      dispatch(
        contextIntegrationsActions.testContextIntegration({
          ...values,
          silent: false,
        }),
      );
    },
    [],
  );

  const onRun = useCallback(
    (values) => {
      dispatch(contextIntegrationsActions.runContextIntegration(values));
    },
    [],
  );

  const cxActionMenu = useCallback(
    (id, row) => {
      // TODO: when SQC-102 ('verify' functionality) is complete, update menu items.
      const items = [
        {
          onClick: () => {
            setRowId(id);
            onEditClick(row);
          },
          icon: <LeadPencilIcon />,
          text: 'Edit',
          disabled: !permissions?.update,
        },
        {
          onClick: () => {
            setRowId(id);
            onLogsClick(row);
          },
          icon: <TextBoxIcon />,
          text: 'View Logs',
        },
        {
          onClick: () => {
            setRowId(id);
            onTest(row);
          },
          icon: <CogPlayOutlineIcon />,
          text: 'Test',
          disabled:
            !contextIntegrationModels[row.type]?.component
            || !permissions?.create,
        },
        {
          onClick: () => {
            setRowId(id);
            onRun(row);
          },
          icon: <PlayIcon />,
          text: 'Run',
          disabled:
            !contextIntegrationModels[row.type]?.component || !row?.enabled,
        },
        {
          onClick: () => {
            setIntegration(row);
            toggleShowDelete(true);
          },
          icon: <TrashCanOutlineIcon />,
          text: 'Delete',
          disabled: !permissions?.delete,
        },
      ];

      return (
        <MenuColumnContextMenu
          title={row.name}
          items={items}
          dataTracking="context-integration"
        />
      );
    },
    [onEditClick, toggleShowDelete, setIntegration, onTest, onRun, permissions],
  );

  const onConfirmBulkDelete = useCallback(
    () => {
      toggleBulkDeleteModal();
      if (!selected.length) {
        return;
      }

      const data = selected.map((id) => ({
        id,
        isDynamic: !isIntegrationTypeHardcoded(integrations[id].type),
      }));

      dispatch(contextIntegrationsActions.bulkDeleteContextIntegrations(data));
    },
    [selected, isIntegrationTypeHardcoded],
  );

  const onTestResultsConfirm = useCallback(
    () => {
      dispatch(contextIntegrationsActions.testContextIntegrationClear());
      dispatch(contextIntegrationsActions.clearTestLabels());
      setIsViewingTestResults(false);
    },
    [],
  );

  const onSelectedRowsChange = useCallback(
    (selectedRowIds) => {
      setSelected((prev) => {
        const next = Object.entries(selectedRowIds || {})
          .map(([key, value]) => (value ? key : null))
          .filter(Boolean);

        if (!prev.length && !next.length) {
          return prev;
        }

        return next;
      });
    },
    [],
  );

  const getIsRowSelectorDisabled = useCallback(
    () => !permissions?.update,
    [permissions],
  );

  const columns = useMemo(
    () => getColumns(preparedColumns, {
      permissions,
      cxActionMenu,
      onToggleEnabled,
      contextIntegrationModels,
    }),
    [permissions, cxActionMenu, onToggleEnabled, contextIntegrationModels],
  );

  useEffect(
    () => {
      if (!areAllFetched) {
        dispatch(contextIntegrationsActions.fetchContextIntegrations());
      }
    },
    [areAllFetched],
  );

  useEffect(
    () => {
      if (testResults) {
        setIsViewingTestResults(true);
      }
    },
    [testResults],
  );

  useEffect(
    () => {
      if (isFetching || !isDeleting) {
        return;
      }

      setIsDeleting(false);

      if (!error) {
        toggleShowDelete(false);
      }
    },
    [isFetching, isDeleting, error, toggleShowDelete],
  );

  return (
    <Fragment>
      <ActionsContainer>
        <Button onClick={onAddClick} disabled={!permissions?.create}>
          Add Integration
        </Button>

        <Button
          variant={ButtonVariants.outlined}
          onClick={toggleBulkDeleteModal}
          disabled={!permissions?.delete || !selected.length}
        >
          Delete Selected
        </Button>
      </ActionsContainer>

      <Row sm={12} item container={false}>
        <Table
          id={tableId}
          columns={columns}
          data={tableData}
          sortBy={sortBy}
          getIsRowSelectorDisabled={getIsRowSelectorDisabled}
          onSelectedRowsChange={onSelectedRowsChange}
        />
      </Row>

      {isViewingTestResults && (
        <IntegrationTestModal
          onConfirm={onTestResultsConfirm}
          onClose={onTestResultsConfirm}
          titleText="Test Successful"
          data={testResults}
          isOpen
          summary="The following labels will be created upon a successful run"
        />
      )}

      {showDelete && (
        <ConfirmModal
          item={(
            <PluginLabel
              style={{ marginLeft: '5px' }}
              adapter={integration?.type}
              deleteName={integration?.name}
              title={contextIntegrationModels[integration?.type]?.title}
              size={14}
              logo={contextIntegrationModels[integration?.type]?.manifest?.logo}
            />
          )}
          onConfirm={onDeleteConfirm}
          onToggle={toggleShowDelete}
          toggleOnConfirm={false}
          isDisabled={isFetching}
          cancelButtonDisabled={isFetching}
          isOpen
        />
      )}
      {showBulkDeleteModal && (
        <ConfirmModal
          item={`${selected.length} Context Integration${
            selected.length > 1 ? 's' : ''
          }`}
          confirmButtonText="delete"
          whyAsking=""
          onToggle={toggleBulkDeleteModal}
          onConfirm={onConfirmBulkDelete}
          toggleOnConfirm={false}
          isOpen
        />
      )}
    </Fragment>
  );
};

export default ContextIntegrations;
