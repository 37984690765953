import { Menu, TriggerMenu, Item, Separator } from './components';
import {
  Provider,
  useMenuActions,
  useMenuState,
  ActionConsumer,
  StateConsumer,
} from './context';
import { useMenu } from './context/useMenu';
import { withMenu } from './withMenu';

const all = {
  useMenu,
  Provider,
  useMenuActions,
  useMenuState,
  ActionConsumer,
  StateConsumer,
  withMenu,
  Menu,
  TriggerMenu,
  Item,
  Separator,
};

export {
  useMenu,
  Provider,
  useMenuActions,
  useMenuState,
  ActionConsumer,
  StateConsumer,
  withMenu,
  Menu,
  TriggerMenu,
  Item,
  Separator,
};

export default all;
