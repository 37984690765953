import PropTypes from '+prop-types';
import { Fragment } from 'react';

import CheckBox from '+components/form/CheckBox';
import Field from '+components/form/FinalForm/Field';
import FieldContainer from '+components/form/FormField/components/FieldContainer';
import Group from '+components/form/FormField/components/Group';
import TextField from '+components/form/TextField';
import { validateRequired, validateUrl } from '+components/form/Validators';

const TeamsFields = ({ disabled }) => (
  <Fragment>
    <Field
      name="name"
      label="Name"
      component={TextField}
      type="text"
      maxLength={255}
      autoComplete="new-password"
      validate={validateRequired}
      style={{ width: '50%' }}
      disabled={disabled}
      required
    />

    <Field
      name="description"
      label="Description"
      component={TextField}
      type="text"
      maxLength={255}
      autoComplete="new-password"
      disabled={disabled}
    />

    <Field
      name="config.url"
      label="URL"
      component={TextField}
      type="text"
      autoComplete="new-password"
      validate={[validateRequired, validateUrl]}
      disabled={disabled}
      required
    />

    <Group>
      <FieldContainer>
        <Field
          name="config.short"
          label="Display messages in a shorter style"
          component={CheckBox}
          type="checkbox"
          disabled={disabled}
        />
      </FieldContainer>
    </Group>
  </Fragment>
);

TeamsFields.propTypes = {
  disabled: PropTypes.bool,
};

TeamsFields.defaultProps = {
  disabled: false,
};

export default TeamsFields;
